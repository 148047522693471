import { Box, Divider, Stack, Typography } from "@mui/material"
import { AccordianCardStyles } from "./style";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import GetSupportIcon from "../../assets/getSupportIcon";


export const AccordianCard = ({
    title = "",
    icons = "",
    onClick = () => false,
    bottomText = "",
    detailed = false,
    details = ""


}) => {
    const language = localStorage.getItem("i18nextLng")

    return (
        <>
            {detailed && detailed ?
                //detailed true
                <Box
                    sx={AccordianCardStyles?.boxStyles}
                >
                    <Stack direction={"row"} spacing={2} justifyContent={"space-between"} alignItems="center" >
                        <Stack direction={"row"} alignItems="center">
                            {/* icons  */}
                            {icons && icons ? icons : <GetSupportIcon />}
                            {/* texts */}
                            <Typography sx={AccordianCardStyles?.text}>
                                {title}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Typography sx={AccordianCardStyles?.bottomTextDetailed}>{bottomText}</Typography>

                    <Divider sx={AccordianCardStyles?.divider} />

                    <Typography sx={AccordianCardStyles?.contentText}>{details}</Typography>
                </Box>
                :

                //detailed false
                <Box
                    sx={AccordianCardStyles?.boxStyles}
                    onClick={onClick && onClick ? onClick : false}
                >
                    <Stack direction={"row"} spacing={2} justifyContent={"space-between"} alignItems="center" >
                        <Stack direction={"row"} alignItems="center">
                            {/* icons  */}
                            {icons && icons ? icons : <GetSupportIcon />}
                            {/* texts */}
                            <Typography sx={AccordianCardStyles?.text}>
                                {title}
                            </Typography>
                        </Stack>

                        <Stack>
                            {language === "ar" ?
                                <ArrowBackIosIcon sx={AccordianCardStyles?.arrowIcon} /> :
                                <ArrowForwardIosIcon sx={AccordianCardStyles?.arrowIcon} />
                            }
                        </Stack>
                    </Stack>

                    <Divider sx={AccordianCardStyles?.divider} />

                    <Typography sx={AccordianCardStyles?.bottomText}>{bottomText}</Typography>
                </Box>
            }

        </>
    )
}