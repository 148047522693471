import { Box, Grid, IconButton, InputAdornment, Stack, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Gridview, SerachIMG, SortView, ListView } from "../../assets";
import { DialogDrawer, LoderSimmer, TextBox, TopNavBar, UseDebounce, NoData } from "../../components";
import { useLazyGetPropertyAccessQuery } from "../../redux/services";
import { AppRoutes } from "../../router/routes";
import { FolderDrawer, FolderTab } from "./components";
import { FilemanagerStyle } from "./style";
import { errorRoute } from "../../utils";


export const Filemanager = ({ t = () => false }) => {
    const navigate = useNavigate()
    const debounce = UseDebounce()
    const [state, setState] = useState({
        bool: true,
        search: "",
        is_private: false,
        moreIcon: false,
        data: [],
        drawer: false,
        listType: 2,
        details: {},
        viewData: [],
        offset: 0,
        order_by: true
    })

    const [fileManagerAPI, { error }] = useLazyGetPropertyAccessQuery()

    const getData = async (data, is_order) => {
        const res = await fileManagerAPI(data)

        setState(
            {
                ...state,
                bool: false,
                search: data?.search,
                data: res?.data?.data,
                order_by: is_order ? !state?.order_by : state?.order_by
            }
        )
    }

    React.useEffect(() => {
        getData({
            "offset": state?.offset,
            "limit": 10,
            search: state?.search ?? ""
        })
        // eslint-disable-next-line
    }, [])

    const handleRoute = (data) => {
        navigate(AppRoutes?.fileManagerDocs, { state: data?.id })
    }

    const drawerClose = () => {
        setState({
            ...state, drawer: !state?.drawer
        });
    }

    const viewDetails = (data) => {
        setState({
            ...state,
            moreIcon: true,
            drawer: true,
            details: data,
            id: data?.id
        })
    }
    const onSearchChange = (e) => {
        setState({
            ...state, search: e?.target?.value
        })
        debounce(
            async () => {
                await getData({
                    "offset": state?.offset,
                    "limit": 10,
                    search: e?.target?.value ?? ""
                })
            },
            800
        );


    }

    const updateState = async (key, value) => {
        setState({ ...state, [key]: value });
    };

    const orderBy = async () => {
        await getData({
            "offset": state?.offset,
            "limit": 10,
            order_by: state?.order_by ? "desc" : "asc",
            search: state?.search ?? ""
        }, true)
    }

    React.useEffect(() => {
        if (error?.status === 423) return errorRoute()
    }, [error])
    return (
        <Box sx={FilemanagerStyle?.parentBox}>
            <Container maxWidth="sm" sx={{padding:"0px !important"}}>
            <Box sx={{
        paddingTop: '7.9rem', // Default style
        '@media screen and (min-width: 600px)': {
          paddingTop: '6.7rem', // Media query style for `md` breakpoint
        },
      }}>
                <Box sx={FilemanagerStyle?.Topnav}>
                    <Container maxWidth="sm" sx={{padding:"0px !important"}}>
                    <TopNavBar title={t("File Manager")} isDirect isBack t={t} position="absolute" />
                    <Box sx={FilemanagerStyle?.root}>
                    <Grid item xs={12}>
                        <TextBox
                            paddingRight
                            sx={FilemanagerStyle?.boxShadow}
                            placeholder={t("Search")}
                            value={state?.search ?? ""}
                            onChange={(e) => onSearchChange(e)}
                            startAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                        <SerachIMG />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                </Box>
                    </Container>
                </Box>
               
                <Box sx={FilemanagerStyle?.folderDiv}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6} sx={FilemanagerStyle?.girdOne}>
                                <Typography>{t("Folders")}</Typography>
                            </Grid>
                            <Grid item xs={6} sx={FilemanagerStyle?.girdTwo}>
                                <Stack direction="row">
                                    {[<SortView
                                        color={state?.order_by ? "#F15A29" : "#98A0AC"}
                                        onClick={() => orderBy()} />,
                                    <Box sx={{ marginInline: "16px" }}>
                                        <ListView color={state?.listType === 1 ? "#F15A29" : "#98A0AC"}
                                            onClick={() => updateState("listType", 1, state?.order_by)} />
                                    </Box>,
                                    <Gridview
                                        color={state?.listType === 2 ? "#F15A29" : "#98A0AC"}
                                        onClick={() => updateState("listType", 2, state?.order_by)} />]?.map((x) => {
                                            return (
                                                <Box onClick={x?.onClick}>{x}</Box>
                                            );
                                        })}
                                </Stack>
                            </Grid>
                        </Grid>

                        {state?.listType === 0 &&
                            <Grid container sx={FilemanagerStyle?.gridItems}>
                                {
                                    state?.bool ?
                                        <LoderSimmer card count={1} size={150} width={150}
                                            animation={"wave"} />
                                        : state?.data?.data?.length > 0 ?
                                            <>
                                                {
                                                    state?.data?.data?.map((val, index) => {
                                                        return <Grid item xs={6} sm={4} md={3} >

                                                            <FolderTab
                                                                key={index}
                                                                t={t}
                                                                state={val}
                                                                GridView
                                                                handleOpen={viewDetails}
                                                                handleRoute={handleRoute}

                                                            />

                                                        </Grid>
                                                    })
                                                }
                                            </> :
                                            <NoData style={{ display: "flex", alignItems: "center", height: "calc(100vh - 220px)" }} />
                                }
                            </Grid>
                        }

                        {
                            state?.listType === 1 &&
                            <Box sx={FilemanagerStyle?.listContainer}>
                                {
                                    state?.bool ?
                                        <LoderSimmer card count={1} size={150} width={150}
                                            animation={"wave"} />
                                        :
                                        state?.data?.data?.length > 0 ?
                                            state?.data?.data?.map((val, index) => {
                                                return state?.bool ? <LoderSimmer card count={1} size={120} animation={"wave"} />
                                                    : <Box sx={FilemanagerStyle?.child}>
                                                        <FolderTab
                                                            t={t}
                                                            state={val}
                                                            GridView={false}
                                                            handleOpen={viewDetails}
                                                            handleRoute={handleRoute}
                                                            isDivider={state?.data?.data?.length !== index + 1 ? true : false}
                                                        />
                                                    </Box>
                                            })
                                            :
                                            <NoData style={{ display: "flex", alignItems: "center", height: "calc(100vh - 220px)" }} />}
                            </Box>
                        }
                        <Grid container sx={FilemanagerStyle?.marginTop}>
                            {state?.listType === 2 &&
                                <Grid container sx={FilemanagerStyle?.gridItems}>
                                    {
                                        state?.bool ?
                                            <LoderSimmer card count={1} size={150} width={150}
                                                animation={"wave"} />
                                            :
                                            state?.data?.data?.length > 0 ?
                                                state?.data?.data?.map((val, index) => {
                                                    return <Grid item xs={6} sm={4} md={4} >
                                                        {
                                                            state?.bool ? <LoderSimmer card count={1} size={150} width={150}
                                                                animation={"wave"} />
                                                                :
                                                                <Box p={1}>
                                                                    <FolderTab
                                                                        key={index}
                                                                        t={t}
                                                                        state={val}
                                                                        GridView
                                                                        handleOpen={viewDetails}
                                                                        handleRoute={handleRoute}
                                                                        isDivider={state?.length === index + 1 ? true : false}
                                                                    />
                                                                </Box>
                                                        }
                                                    </Grid>
                                                })
                                                :
                                                <NoData style={{ display: "flex", alignItems: "center", height: "calc(100vh - 220px)" }} />
                                    }
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Box>
                </Box>
                <DialogDrawer
                    maxWidth="sm"
                    open={state?.drawer}
                    padding={"0"}
                    height_style={
                        { height: "270px !important" }
                    }
                    header={t("Folder Details")}
                    component={<FolderDrawer data={state?.details} t={t} />}
                    onClose={() => drawerClose()}
                />

            </Container >
        </Box>

    )
}