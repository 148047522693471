import * as React from "react";

export const TransparentBuilding = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 53600"
          fill="#ced3dd"
          stroke="#707070"
          d="M0 0h16v16H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 101440" clipPath="url(#a)">
      <path
        d="M4.754 1.778a.525.525 0 0 0-.525.525V8.43H2.303a.525.525 0 0 0-.525.526v5.6a.525.525 0 1 0 1.05 0V9.478h1.84a.525.525 0 0 0 .17 0h1.491v5.08a.525.525 0 1 0 1.05 0V9.042a.525.525 0 0 0 0-.17V7.378h3.94a.525.525 0 0 0 .17 0h1.842v7.18a.525.525 0 1 0 1.05 0v-7.7a.525.525 0 0 0-.525-.525h-1.924v-4.03a.525.525 0 0 0-.525-.525Zm.524 1.05h5.6v3.5H6.855a.525.525 0 0 0-.525.525v1.576H5.278Zm1.4 1.05a.35.35 0 0 0-.35.35v.7a.35.35 0 0 0 .35.35h.7a.35.35 0 0 0 .35-.35v-.7a.35.35 0 0 0-.35-.35Zm2.1 0a.35.35 0 0 0-.35.35v.7a.35.35 0 0 0 .35.35h.7a.35.35 0 0 0 .35-.35v-.7a.35.35 0 0 0-.35-.35Zm0 4.552a.35.35 0 0 0-.35.35v.7a.35.35 0 0 0 .35.35h.7a.35.35 0 0 0 .35-.35v-.702a.35.35 0 0 0-.35-.35Zm2.451 0a.35.35 0 0 0-.35.35v.7a.35.35 0 0 0 .35.35h.7a.35.35 0 0 0 .35-.35v-.702a.35.35 0 0 0-.35-.35Zm-7 2.1a.35.35 0 0 0-.35.35v.7a.35.35 0 0 0 .35.35h.7a.35.35 0 0 0 .35-.35v-.7a.35.35 0 0 0-.35-.35Zm4.552 0a.35.35 0 0 0-.35.35v.7a.35.35 0 0 0 .35.35h.7a.35.35 0 0 0 .35-.35v-.7a.35.35 0 0 0-.35-.35Zm2.451 0a.35.35 0 0 0-.35.35v.7a.35.35 0 0 0 .35.35h.7a.35.35 0 0 0 .35-.35v-.7a.35.35 0 0 0-.35-.35Zm-7 2.1a.35.35 0 0 0-.35.35v.7a.35.35 0 0 0 .35.35h.7a.35.35 0 0 0 .35-.35v-.7a.35.35 0 0 0-.35-.35Zm4.552 0a.35.35 0 0 0-.35.35v.7a.35.35 0 0 0 .35.35h.7a.35.35 0 0 0 .35-.35v-.7a.35.35 0 0 0-.35-.35Zm2.451 0a.35.35 0 0 0-.35.35v.7a.35.35 0 0 0 .35.35h.7a.35.35 0 0 0 .35-.35v-.7a.35.35 0 0 0-.35-.35Z"
        fill="#98a0ac"
      />
    </g>
  </svg>
);

