import React from "react";
import { withTranslation } from 'react-i18next';
import { FilemanagerDocs } from "./filemanagerDocs";

class FilemanagerDocsParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return <FilemanagerDocs {...this?.props} />;
    }
}

export default withTranslation('filemanager')(FilemanagerDocsParent);
