

import * as React from "react"

export const MeterDrawer = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="SQ FT">
            <path data-name="Rectangle 53049" fill="none" d="M0-.001h24v24H0z" />
            <g data-name="Group 96774">
                <path
                    data-name="Rectangle 53050"
                    fill="#ced3dd"
                    d="M12.239 10.009h7.411v2.117h-7.411z"
                />
                <path
                    data-name="Rectangle 53051"
                    fill="#ced3dd"
                    d="M14.356 7.892h5.293v2.117h-5.293z"
                />
                <path
                    data-name="Rectangle 53052"
                    fill="#ced3dd"
                    d="M17.532 5.775h2.117v3.176h-2.117z"
                />
                <path
                    data-name="Rectangle 53053"
                    fill="#ced3dd"
                    d="M4.828 16.361h7.411v2.117H4.828z"
                />
                <path
                    data-name="Rectangle 53054"
                    fill="#ced3dd"
                    d="M4.828 14.244h4.235v3.176H4.828z"
                />
                <path
                    data-name="Rectangle 53055"
                    fill="#ced3dd"
                    d="M4.828 12.127h2.117v6.352H4.828z"
                />
                <path
                    d="M4.148 4.716a.654.654 0 0 0-.643.663v13.069a1.1 1.1 0 0 0 1.089 1.09h6.97a1.1 1.1 0 0 0 1.089-1.089v-1.743a1.1 1.1 0 0 0-1.089-1.089h-1.525v-1.089a1.1 1.1 0 0 0-1.089-1.09H7.426v-1.089a1.1 1.1 0 0 0-1.089-1.089H4.812V5.379a.654.654 0 0 0-.664-.663Zm2.624.009a.653.653 0 1 0 .653.653.653.653 0 0 0-.653-.652Zm2.614 0a.653.653 0 1 0 .653.653.653.653 0 0 0-.653-.652Zm2.614 0a.653.653 0 1 0 .653.653.653.653 0 0 0-.653-.652Zm2.614 0a.653.653 0 1 0 .653.653.653.653 0 0 0-.653-.652Zm3.049 0a1.1 1.1 0 0 0-1.089 1.089v1.09h-1.525a1.1 1.1 0 0 0-1.089 1.089v1.089h-1.524a1.1 1.1 0 0 0-1.089 1.089v1.743a1.1 1.1 0 0 0 1.089 1.089h6.752v5.881a.653.653 0 1 0 1.307 0v-6.535a.653.653 0 0 0-.028-.2 1.055 1.055 0 0 0 .028-.237v-6.1a1.1 1.1 0 0 0-1.09-1.086Zm.218 1.307h1.307v5.663h-6.535v-1.306h1.96a.654.654 0 0 0 .654-.651V8.211h1.96a.654.654 0 0 0 .653-.653ZM4.812 12.567h1.307v1.525a.654.654 0 0 0 .653.653h1.96v1.525a.654.654 0 0 0 .653.653h1.96v1.307H4.812Zm9.8 5.663a.653.653 0 1 0 .653.653.653.653 0 0 0-.651-.652Zm2.614 0a.653.653 0 1 0 .653.653.653.653 0 0 0-.651-.652Z"
                    fill="#4e5a6b"
                />
            </g>
        </g>
    </svg>
)

