import { Box, Button, Container } from "@mui/material";
import React from "react";
import RestoreErrorImage from "../../assets/restoreErrorhandling";
import { withTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Bold } from "../../utils/constants";

const RestoreErrorHandling = ({t = () => false, state = {} }) => {
    const navigate = useNavigate();
    const goBack = () =>{
        navigate(-1);
    }

    const styles = {
        root: {
          height: "100vh",
          display: 'flex',
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#F6F6F6",
          padding:'0px !important'
  
        },
        link:{
          fontFamily:Bold,
          backgroundColor: 'primary.main',
          borderRadius: "8px",
          opacity: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color:"#fff",
          width:"fit-content",
          padding:"10px",
          margin:"0 auto",
          cursor:"pointer",
          textDecoration:"none"
  
  
        }
      }
    return (
        <Container maxWidth="sm" sx={styles?.root}>
        <Box display={'block'} textAlign={'center'}>
                            <RestoreErrorImage message={state?.msg?t(state?.msg):null}/>
                <Box mt={1}>
                    <Button variant="contained" onClick={goBack}>
                        {t("try Again")}
                    </Button>
                </Box>
            </Box>
      </Container>
    )
}
export default withTranslation("common")(RestoreErrorHandling)
