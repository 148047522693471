import {
    Typography,
    Stack,
    Grid,
    Box,
} from "@mui/material";
import { MonthlyInvoiceCardStyles } from "./style";
import { ConsumedIcon, HistoryBill, MeterIcon, UtilityCalendarIcon } from "../../assets";

export const MonthlyInvoiceCard = ({
    price = "",
    consumedUnits = "",
    fromDate = "",
    toDate = "",
    meterValue = "",
    previousMeter = "",
    t = () => false,
    currency=null
}) => {

    return (
        <Box sx={MonthlyInvoiceCardStyles?.header_div}>
            <Box sx={MonthlyInvoiceCardStyles?.invoice_card}>
                <Box sx={MonthlyInvoiceCardStyles?.bill_div}>
                    {/* Section 1 */}
                    <Grid container>
                        <Grid item xs={6}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <HistoryBill />
                                <Stack direction="column">
                                    <Typography sx={MonthlyInvoiceCardStyles?.label}>{"Price"}</Typography>
                                    <Typography sx={MonthlyInvoiceCardStyles?.text_large}>
                                        {currency ?? ""} {price}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>

                        <Grid item xs={6}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <ConsumedIcon />
                                <Stack direction="column">
                                    <Typography sx={MonthlyInvoiceCardStyles?.label}>
                                        {t("Consumed Units")}
                                    </Typography>
                                    <Typography sx={MonthlyInvoiceCardStyles?.text_large}>
                                        {consumedUnits}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                {/* Section 2 */}
                <Grid container mb={2} mt={1} spacing={2}>
                    <Grid item xs={6}>
                        <Box sx={MonthlyInvoiceCardStyles?.bill_div}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box>
                                    <Typography sx={MonthlyInvoiceCardStyles?.label} mb={1}>
                                        {t("From Date")}
                                    </Typography>
                                    <Typography sx={MonthlyInvoiceCardStyles?.text_small}>
                                        {fromDate}

                                    </Typography>
                                </Box>
                                <UtilityCalendarIcon
                                    // fill="primary.main"
                                    fill="#F15A29"
                                />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={MonthlyInvoiceCardStyles?.bill_div}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box>
                                    <Typography sx={MonthlyInvoiceCardStyles?.label} mb={1}>
                                        {t("To Date")}
                                    </Typography>
                                    <Typography sx={MonthlyInvoiceCardStyles?.text_small} >
                                        {toDate}

                                    </Typography>
                                </Box>
                                <UtilityCalendarIcon fill="#F15A29" />
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                {/* Section 3 */}
                <Box sx={MonthlyInvoiceCardStyles?.bill_div} mb={2} mt={1}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Stack>
                            <Typography sx={MonthlyInvoiceCardStyles?.label} mb={1}>
                            {t("Current Meter Reading Value")}
                            </Typography>
                            <Typography sx={MonthlyInvoiceCardStyles?.text_small}>
                                {currency ?? ""} {meterValue}
                            </Typography>
                        </Stack>
                        <MeterIcon fill="#F15A29" />
                    </Stack>
                </Box>
                {/* Section 4 */}
                <Box sx={MonthlyInvoiceCardStyles?.bill_div}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Stack>
                            <Typography sx={MonthlyInvoiceCardStyles?.label} mb={1}>
                                {t("Previous Meter Reading Value")}
                            </Typography>
                            <Typography sx={MonthlyInvoiceCardStyles?.text_small}>
                            {currency ?? ""} {previousMeter}

                            </Typography>
                        </Stack>
                        <UtilityCalendarIcon fill="#F15A29" />
                    </Stack>
                </Box>
            </Box>


        </Box>
    );
};


