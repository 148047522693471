import { Bold, ExtraBold, SemiBold } from "../../utils";

export const MonthlyInvoiceCardStyles = {
    invoice_card: {
        backgroundColor: "white",
        borderRadius: "4px",
        // marginBottom: "16px"
    },
    month: {
        color: "border.primary",
        fontSize: "0.875rem",
        fontFamily: Bold
    }, 
    due: {
        color: "#5D427F",
        backgroundColor: "#F1E6FE",
        borderRadius: "4px",
        fontSize: "0.75rem",
        padding: "3px 6px"
    }, 
    bill_div: {
        padding: "10px",
        backgroundColor: "#F5F7FE",
        borderRadius: "4px",
    },
    label: {
        fontSize: "0.75rem",
        color: "color.secondary",
        fontFamily: SemiBold
    },
    text_large: {
        color: "color.primary",
        fontSize: "0.78125",
        fontFamily: ExtraBold
    },
    text_small: {
        color: "color.primary",
        fontSize: "0.875rem",
        fontFamily: ExtraBold
    },
    invoice: {
        color: "#5078E1",
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        cursor: "pointer"
    },
    over_due: {
        color: "#FF4B4B",
        backgroundColor: "#FF4B4B29",
        padding: "3px 6px",
        fontSize: "0.75rem",
        borderRadius: "4px",
    },
    button: {
        backgroundColor: "#5078E1",
        padding: "15px 100px",
        fontSize: "0.875rem",
        marginTop: "40px",
        borderRadius: "8px"    
    },
    header_div: {
        // backgroundColor: "#F6F6F6",
        // padding: "16px",
    },
}
