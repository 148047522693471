import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ImageCardStyles } from './style'
import { FileViewers } from "../fileViewer";
import { generateImageUrl, img_size } from "../../utils";
import Rubbish from "../../assets/rubbish";


export const ImageCard = ({
    image = "",
    imageName = "",
    storage = "",
    type = "",
    onClick = () => false,
}) => {

    // const [showText, setShowtext] = useState(false);
    const imageFormat = ["jpg", "png", "webp", "jpeg"]
    return (
        <Box
            sx={ImageCardStyles.imgcard}
            // onMouseOver={() => setShowtext(true)}
            // onMouseLeave={() => setShowtext(false)}
        >
            <Stack direction={"row"} alignItems={"center"}>
                {type === "mp4" &&
                    <>
                        <video
                            style={ImageCardStyles.image}
                            controls
                        >
                            <source src={image} type="video/mp4" />
                        </video>
                    </>
                }
                {type === "pdf" &&

                    <div style={{ width: "40px", height: "40px", borderRadius: "6px", overflow: "auto" }}>
                        <FileViewers fileType={"pdf"} filePath={image} />
                    </div>

                }
                {imageFormat.includes(type) &&
                    <>
                        <img style={{ width: "40px", height: "40px", borderRadius: "6px" }}
                            src={generateImageUrl(image, img_size.small_square)} sx={ImageCardStyles.image} alt={"images"} />
                    </>

                }
                <Stack spacing={"4px"} marginInlineStart={1} flexGrow={1} direction={"column"}>
                    <Typography sx={ImageCardStyles.imgName}>
                        {imageName}.{type}
                    </Typography>
                    <Typography sx={ImageCardStyles.textName}>{storage}</Typography>
                </Stack>
                <Box onClick={onClick} sx={ImageCardStyles.deleteBox}>
                    <Rubbish />
                </Box>
            </Stack>
            {/* {showText === true && (
                <Box sx={ImageCardStyles.hoverCard}>
                    <Box>
                        <DeleteOutlineIcon
                            sx={ImageCardStyles.deleteicon}
                            onClick={onClick}
                        />
                    </Box>
                    <Box sx={ImageCardStyles.imgtext}>
                        <Typography sx={ImageCardStyles.imgName}>
                            {imageName}
                        </Typography>
                        <Box display={"flex"}>
                            <Typography sx={ImageCardStyles.textName}>
                                {storage}
                            </Typography>
                            <Box sx={ImageCardStyles.dot}></Box>
                            <Typography sx={ImageCardStyles.textName}>{type}</Typography>
                        </Box>
                    </Box>
                </Box>
            )} */}
        </Box>
    )
}