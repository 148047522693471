import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import CallerSupport from "../../assets/callerSupport";
import { AccordianCard, SimpleAccordion, TopNavBar } from "../../components";
import { getSupportAccordian } from "../../utils/getSupports";
import { GetSupportStyle } from './style'
import { AppRoutes } from "../../router/routes";
import { useNavigate } from 'react-router-dom';




export const GetSupport = ({
    t = () => false
}) => {
    const navigate = useNavigate();
    return (
        <Container
            sx={GetSupportStyle.container}
            maxWidth="sm"
        >
            {/* topNavbar */}
            <TopNavBar title={t("Get Support")} isDirect isBack t={t}/>

            {/* CreateInvestment */}
            <Box sx={GetSupportStyle.root}>
                <Box sx={GetSupportStyle?.topSection}>
                    <CallerSupport sx={GetSupportStyle?.headAvatar}/>
                    <Typography sx={GetSupportStyle?.headTitle}>{t("Hi, How can we help you?")}</Typography>
                </Box>
                {getSupportAccordian && getSupportAccordian(t)?.map((item, index) => {
                    return (
                        <Box key={index}>
                            <SimpleAccordion
                                headText={item?.accordianTitle}
                                defaultExpanded={index === 0 ? true : false}
                                content={
                                    <Grid container spacing={1.3}>
                                        {item?.accordianContent && item?.accordianContent?.map((content, index) => {
                                            return (

                                                <Grid item xs={12}>
                                                    <Box>
                                                        <AccordianCard
                                                            // detailed={true}
                                                            title={content?.contentTitle}
                                                            bottomText={content?.contentDescription}
                                                            // onClick={()=>history.push(AppRoutes.getSupportDetailed)}
                                                            onClick={ () => navigate(AppRoutes.getSupportDetailed)}
                                                        />
                                                    </Box>

                                                </Grid>




                                            )

                                        })}

                                    </Grid>

                                }

                            />
                        </Box>
                    )

                })}



            </Box>


        </Container>

    );
};
