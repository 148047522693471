import * as React from "react"

const ContactUsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      d="M14.526 3.502a2.035 2.035 0 0 0-2.022 2.024v5.849a.675.675 0 0 0 1.049.562l2.53-1.686h3.395a2.035 2.035 0 0 0 2.026-2.025v-2.7a2.035 2.035 0 0 0-2.029-2.024Zm-6.506.006a2.517 2.517 0 0 0-.9.1h0l-1.064.315a3.392 3.392 0 0 0-2.321 2.37c-.666 2.528.1 5.52 2.079 8.888s4.237 5.5 6.786 6.2a3.454 3.454 0 0 0 3.255-.794l.81-.751a2.454 2.454 0 0 0 .309-3.24l-1.17-1.594a2.519 2.519 0 0 0-2.751-.91l-1.831.548c-.077.022-.261-.082-.421-.221h0a6.33 6.33 0 0 1-1.1-1.466h0a6.539 6.539 0 0 1-.724-1.57.846.846 0 0 1-.047-.434.213.213 0 0 1 .07-.134h0l1.366-1.249a2.447 2.447 0 0 0 .577-2.82l-.824-1.8h0A2.5 2.5 0 0 0 8.02 3.508Zm6.506 1.344h4.95a.665.665 0 0 1 .675.675v2.7a.665.665 0 0 1-.675.675h-3.6a.675.675 0 0 0-.372.112l-1.651 1.1V5.526a.665.665 0 0 1 .673-.675Zm-6.6 0a1.159 1.159 0 0 1 .968.658l.823 1.8a1.079 1.079 0 0 1-.261 1.262L8.087 9.818a1.566 1.566 0 0 0-.5.979h0a2.681 2.681 0 0 0 .083.926 7.718 7.718 0 0 0 .866 1.915h0a7.227 7.227 0 0 0 1.381 1.8h0a1.78 1.78 0 0 0 1.684.5h0l1.839-.551a1.166 1.166 0 0 1 1.275.417l1.171 1.59a1.085 1.085 0 0 1-.141 1.45l-.81.752a2.126 2.126 0 0 1-1.981.48c-2.061-.564-4.1-2.39-5.979-5.581S4.504 8.657 5.04 6.637a2.059 2.059 0 0 1 1.4-1.423l1.064-.312a1.164 1.164 0 0 1 .418-.048Z"
      fill="#f15a29"
      stroke="#f15a29"
      strokeLinecap="round"
      strokeWidth={0.2}
      data-name="Group 109043"
    />
  </svg>
)

export default ContactUsIcon
