import { AmenitiesCard_style } from "./style";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { generateImageUrl, img_size } from "../../utils";
export const AmenitiesCard = ({
  image = "",
  title = "",
  is_image = false,
  router = () => false,
}) => {
  const language = localStorage.getItem("i18nextLng")
  return (
    <>
      <Box sx={AmenitiesCard_style?.root} onClick={router && router}>
        <Box sx={AmenitiesCard_style?.sx}>
          <Box sx={AmenitiesCard_style?.sub}>
            <Box sx={AmenitiesCard_style?.background}>
              {
                is_image ? <img src={generateImageUrl(image, img_size.small_square)} alt={title} /> : <Box sx={AmenitiesCard_style?.imageAlign}>{image}</Box>
              }
            </Box>
            <Typography sx={AmenitiesCard_style?.bottomText}>{title}</Typography>
          </Box>
          {language === "ar" ?
            <KeyboardArrowLeftIcon /> :
            <KeyboardArrowRightIcon />
          }
        </Box>
      </Box>
      <Box sx={language === "ar" ? AmenitiesCard_style.arabicDivider : AmenitiesCard_style.divider}></Box>
    </>
  );
};
