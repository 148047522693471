import { Box, Typography } from "@mui/material"
import { BottomInvoice_Style } from './style'
import React from 'react'

export const BottomInvoice = ({
  total = "",
  price = "",
  btnText = "",
  handlePaymentDrawerOpen = () => false,
  handlePaymentDrawerClose = () => false,
  handleChecked = () => null,
  handleDelete = () => null,
  handleAlreadyPaid = () => null,
  handlePayNow = () => null,
  drawer = false,
  selectedInvoice = [],
  t = () => false,
  currency=""
}) => {


  return (
    <Box>
      <Box sx={BottomInvoice_Style?.sx}>
        <Box sx={BottomInvoice_Style?.topline}>
          <Typography sx={BottomInvoice_Style?.price}>{currency ?? ""} {price}</Typography>
          <Typography sx={BottomInvoice_Style?.total} noWrap>
            {t('Total of')}  {total} {t('invoices')}</Typography>
        </Box>
        <Box sx={BottomInvoice_Style?.btnBg} onClick={handlePaymentDrawerOpen}>
          <Typography sx={BottomInvoice_Style?.btntxt}>{btnText}</Typography>
        </Box>
      </Box>



    </Box>
  )
}