import { Typography, Box, Stack } from "@mui/material"
import NavigationIcon from "../../assets/navigationIcon";
import { AddressBoxStyle } from "./style";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";


export const AddressBox = ({
    label = "",
    Edit = "",
    value = "",
    data,
}) => {
    const navigate = useNavigate();

    return (
        <>
            <Box>
                <Typography
                    sx={AddressBoxStyle?.title}
                >
                    {label}
                </Typography>

            </Box>

            <Box>
                <Box sx={AddressBoxStyle?.addressBoxStyle}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={1}>
                        <Typography sx={AddressBoxStyle?.addressText}>
                            {label}
                        </Typography>
                        <Typography sx={AddressBoxStyle?.editText} onClick={() => navigate(AppRoutes.addressEdit, { state: data })}>
                            {Edit}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={0.3} >
                        <NavigationIcon />
                        <Box width="16px" />
                        <Stack>
                            {/* <Box height={"4px"} /> */}
                            <Typography
                                sx={AddressBoxStyle?.addressBoxText}
                                noWrap
                                style={{display:"flex",flexFlow:"wrap"}}
                            >
                                 {value?.street_1 ?  <p style={{margin:"0px"}}>{value?.street_1} ,&nbsp;</p> : ""}
                                 {value?.street_2 ?  <p style={{margin:"0px"}}>{value?.street_2}, &nbsp;</p> : ""}
                                 {value?.street_3 ?  <p style={{margin:"0px"}}>{value?.street_3} ,&nbsp;</p> : ""}
                                 {value?.district ?  <p style={{margin:"0px"}}>{value?.district} ,&nbsp;</p> : ""}
                                 {value?.state ?  <p style={{margin:"0px"}}>{value?.state} ,&nbsp;</p> : ""}
                                 {value?.country ?  <p style={{margin:"0px"}}>{value?.country}, &nbsp;</p> : ""}
                                 {value?.zipcode ?  <p style={{margin:"0px"}}>{value?.zipcode}, &nbsp;</p> : ""}

                                               {/* {value?.street_2 ? ",": ""} {value?.street_2 ? value?.street_2 : ""} {value?.street_3 ? ",": ""} {value?.street_3 ? value?.street_3 : ""} {value?.district ? ",": ""} {value?.district ? value?.district : ""} {value?.state ? ",": ""} {value?.state ? value?.state : ""} {value?.country ? ",": ""} {value?.country ? value?.country : ""} {value?.zipcode ? ",": ""} {value?.zipcode ? value?.zipcode : ""} */}

                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </>

    )
}