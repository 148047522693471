import { Box, Container } from "@mui/material";
import React, { useState } from "react";
import { FooterComponent, FormGenerator, TopNavBar } from "../../components";
import { useLazyGeneralCategoryMasterQuery, useLazyGetRequestUnitQuery, useLazyGeneralSubCategoryMasterQuery, useLazyMaintainanceRequestCreationQuery } from "../../redux/services";
import { LocalStorageKeys, SemiBold, errorRoute } from "../../utils";
import { GeneralRequestStyle } from './style'
import { useSnackbar } from 'notistack';
import { AppRoutes } from "../../router/routes";
import { useNavigate } from "react-router";

const initial = {
    category: "",
    sub_Category: "",
    select_Unit: "",
    problem_Title: "",
    problem_Description: "",
    problem_Since: new Date(),
    property_id: "",
    error: {
        category: "",
        sub_Category: "",
        select_Unit: "",
        problem_Title: "",
        problem_Description: "",
        problem_Since: new Date(),

    }

}

export const GeneralRequest = ({
    t = () => false
}) => {



    const [data, setData] = useState({ ...initial })
    const navigate = useNavigate();
    const [loading1, setLoading1] = React.useState(null);
    const [isDisableBtn,setIsDisableBtn]=React.useState(false)
    const [GeneralCatergoryAPI , {error}] = useLazyGeneralCategoryMasterQuery()
    const [GeneralSubCategory , {error:isError}] = useLazyGeneralSubCategoryMasterQuery()
    const [GetOwnerUnit, {error:isError1}] = useLazyGetRequestUnitQuery()
    const [RequestCreation, { isLoading }] = useLazyMaintainanceRequestCreationQuery()
    const { enqueueSnackbar } = useSnackbar()

    const loadOptions = async (search = "", array, type) => {
        setLoading1(type);
        let result,
            offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        } else {
            offset = array?.length;
        }

        switch (type) {
            case "category":
                result = await GeneralCatergoryAPI({
                    offset,
                    limit: 10,
                    search
                })
                setLoading1(null);
                return {
                    options: [...result?.data?.data],
                    hasMore:
                        array?.length + result?.data?.data?.length <
                        result?.data?.count,
                };
            case "sub-category":
                result = await GeneralSubCategory({
                    offset,
                    limit: 10,
                    search,
                    category_id: data?.category?.value ? data?.category?.value : null
                })
                setLoading1(null);
                return {
                    options: [...result?.data?.data],
                    hasMore:
                        array?.length + result?.data?.data?.length <
                        result?.data?.count,
                };
            case "get-owner-unit":
                result = await GetOwnerUnit({
                    offset,
                    limit: 10, search
                })
                setLoading1(null);
                return {
                    options: [...result?.data?.data?.map((x) => {
                        return {
                            value: x?.unit_no,
                            label: x?.unit_name,
                            property_id: x?.property_id,
                            id: x?.id,
                            company_id: x?.company_id
                        }
                    })],
                    hasMore:
                        array?.length + result?.data?.data?.length <
                        result?.count,
                };





            default:
                return { options: [] };

        }
    };

    // updateState
    const updateState = (k, v) => {
        let error = data?.error;
        error[k] = "";
        setData({ ...data, [k]: v, error });
    };

    const GeneralRequestCreationAPI = async () => {
        if (validate()) {
            setIsDisableBtn(true)
            const payload = {
                category: data?.category?.value,
                companyId: data?.select_Unit?.company_id,
                description: data?.problem_Description,
                problem_since: data?.problem_Since,
                propertyId: data?.select_Unit?.property_id,
                sub_category: data?.sub_Category?.value,
                subject: data?.problem_Title,
                type: 1,
                units: [data?.select_Unit?.id],
                userProfileId: localStorage.getItem(LocalStorageKeys.userProfileId),
                is_active: true
            }
            const CreationRes = await RequestCreation(payload)
            if (CreationRes?.data?.type === "success") {
                setIsDisableBtn(false)
                enqueueSnackbar(t(`General request Created Successfully`), {
                    variant: "success",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
                navigate(AppRoutes?.request)
            } else {
                setIsDisableBtn(false)
                enqueueSnackbar(t(`General request Created Unsuccessfully`), {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
            }

            setData({ ...initial })
        }

    }
    // save btn
    const save = () => {
        GeneralRequestCreationAPI()
    }


    // validation
    const validate = () => {
        let isValid = true;
        let error = data.error


        if (data?.category.length === 0) {
            isValid = false
            error.category = t("Category is required")
        }
        if (data?.sub_Category?.length === 0) {
            isValid = false
            error.sub_Category = t("Sub Category is Required")
        }

        if (data?.select_Unit?.length === 0) {
            isValid = false
            error.select_Unit = t("Select Unit is Required")
        }
        if (data?.problem_Title?.length === 0) {
            isValid = false
            error.problem_Title = t("Problem Title is Required")
        }
        setData({ ...data, error })
        return isValid
    }

    React.useEffect(()=>{
        if(error?.status === 423|| isError?.status === 423 || isError1?.status === 423) return errorRoute() 
        // eslint-disable-next-line
     },[error , isError])
    return (
        <Container
            sx={GeneralRequestStyle.container}
            maxWidth="sm"
        >
            {/* topNavbar */}

            <TopNavBar title={t("General Request")} isDirect isBack t={t} />

            {/* CreateInvestment */}
            <Box sx={GeneralRequestStyle.root}>
                <FormGenerator
                    components={[
                        {
                            isActive: true,
                            component: "select",
                            label: t("Category"),
                            value: data?.category,
                            placeholder: t("Category"),
                            onChange: (value) => updateState("category", value),
                            error: data?.error?.category,
                            isRequired: true,
                            isPaginate: true,
                            loading: loading1 === "category",
                            loadOptions: (search, array, handleLoading) => {
                                return loadOptions(search, array, "category")
                            },
                            size: {
                                xs: 12,
                            },
                        },
                        {
                            isActive: true,
                            component: "select",
                            label: t("Sub-Category"),
                            value: data?.sub_Category,
                            placeholder: t("Sub-Category"),
                            onChange: (value) => updateState("sub_Category", value),
                            error: data?.error?.sub_Category,
                            isRequired: true,
                            isPaginate: true,
                            loading: loading1 === "sub-category",
                            loadOptions: (search, array, handleLoading) => {
                                return loadOptions(search, array, "sub-category")
                            },
                            size: {
                                xs: 12,
                            },
                        },
                        {
                            isActive: true,
                            component: "select",
                            label: t("Select Unit"),
                            value: data?.select_Unit,
                            placeholder: t("Select Unit"),
                            onChange: (value) => updateState("select_Unit", value),
                            error: data?.error?.select_Unit,
                            isRequired: true,
                            isPaginate: true,
                            loading: loading1 === "get-owner-unit",
                            loadOptions: (search, array, handleLoading) => {
                                return loadOptions(search, array, "get-owner-unit")
                            },
                            size: {
                                xs: 12,
                            },
                        },

                        {
                            isActive: true,
                            component: "text",
                            label: t("Problem Title"),
                            isRequired: true,
                            value: data?.problem_Title,
                            placeholder: t("Problem Title"),
                            onChange: (e) => updateState("problem_Title", e?.target?.value),
                            error: data?.error?.problem_Title,
                            size: {
                                xs: 12,
                            },
                        },
                        {
                            isActive: true,
                            component: "text",
                            label: t("Problem Description"),
                            value: data?.problem_Description,
                            placeholder: t("Problem Description"),
                            onChange: (e) => updateState("problem_Description", e?.target?.value),
                            error: data?.error?.problem_Description,
                            multiline: true,
                            size: {
                                xs: 12,
                            },
                        },

                        {
                            isActive: true,
                            component: "date",
                            label: t("Problem Since"),
                            value: data?.problem_Since,
                            placeholder: t("Problem Since"),
                            onChange: (value) => updateState("problem_Since", value),
                            error: data?.error?.problem_Since,
                            maxDate: new Date(),
                            isRequired: true,
                            size: {
                                xs: 12,
                            },
                        },

                    ]}

                />


            </Box>

            <Box sx={GeneralRequestStyle.footer}>
                {/* footer Component */}
                <FooterComponent
                    btnLoading={isLoading}
                    containedonClick={() => save()}
                    containedText={t("Submit")}
                    single={true}
                    isDisableBtn={isDisableBtn}

                />
            </Box>

        </Container>

    );
};