import { Bold, Regular, SemiBold } from "../../utils";



export const AccordianCardStyles = {
    boxStyles:{
        backgroundColor: 'primary.contrastText',
        padding:"20px",
        borderRadius:"12px",
        cursor:"pointer"
    },
    arrowIcon:{
        color:"text.secondary",
        fontSize: "1rem"
    },
    avatarIcon:{
        width:"16px",
        height:"16px",
    },
    text:{
        color:"text.main",
        fontSize: "0.875rem",
        fontFamily:Bold,
        marginInlineStart: "12px"
    },
    bottomText:{
        color:"text.secondary",
        fontSize: "0.875rem",
        fontFamily:Regular
    },
    bottomTextDetailed:{
        color:"text.secondary",
        fontSize: "0.75rem",
        fontFamily:Regular,
        marginTop:"6px"
    },
    divider:{
        margin:"14px 0px 14px",
        height:"6px"
    },
    contentText:{
        color:"text.main",
        fontSize: "0.875rem",
        fontFamily:SemiBold
    }





};
