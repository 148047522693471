import React from "react";
import { Box } from "@mui/material";
import animation from '../../assets/barchart.json'
import Lottie from "react-lottie";

export const LineChartLoader = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        // here is where we will declare lottie animation
        // "animation" is what we imported before animationData: animation,
        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    const styles = {
        height: "100%",
        display: 'flex',
        alignItems: "center",
        justifyContent: "space-around",
        backgroundColor: "#F6F6F6",
        marginLeft:'20px'
    }
    return (
        <Box sx={styles}>
            <Lottie options={defaultOptions} height={300} width={300} />
        </Box>

    )
}