import * as React from "react"

export const WorkerImage = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={32} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 55345"
          transform="translate(0 .5)"
          fill="#e77132"
          stroke="#707070"
          d="M0 0h20v16H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 105868">
      <g data-name="Group 103748">
        <path
          data-name="Rectangle 54006"
          d="M0 0h36v24a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V0Z"
          fill="#ebeffc"
        />
        <g
          data-name="Mask Group 102266"
          transform="translate(8 7.5)"
          clipPath="url(#a)"
        >
          <path
            d="M16.162 7.864a3.959 3.959 0 0 0-1.446.61c-.631.328-1.215.639-1.585.866a1.9 1.9 0 0 1-1.858 2.342H8.886a.477.477 0 1 1 0-.955h2.386a.951.951 0 0 0 .628-1.668 1.42 1.42 0 0 0-.786-.24H9.5a6.943 6.943 0 0 1-1.6-.18 4.647 4.647 0 0 0-1.137-.138c-2.333 0-3.665 2.864-3.72 2.986a.477.477 0 0 0 .05.481l2.227 3.021a.475.475 0 0 0 .383.193.479.479 0 0 0 .463-.356A1.2 1.2 0 0 1 7.3 13.91a15.5 15.5 0 0 1 1.885.157 15.694 15.694 0 0 0 1.933.161c.879 0 1.512-.674 3.341-2.545C16.778 9.306 17 9.098 17 8.627a.8.8 0 0 0-.838-.762M13.4 5.204a.453.453 0 0 1 0-.725l.2-.146a.3.3 0 0 0 .113-.317 3.014 3.014 0 0 0-.742-1.278.3.3 0 0 0-.33-.06l-.221.095a.453.453 0 0 1-.628-.363l-.029-.242a.3.3 0 0 0-.217-.256 3 3 0 0 0-1.478 0 .3.3 0 0 0-.217.256l-.029.242a.453.453 0 0 1-.628.363l-.221-.095a.3.3 0 0 0-.33.06 3.014 3.014 0 0 0-.749 1.281.3.3 0 0 0 .113.317l.2.146a.453.453 0 0 1 0 .725l-.2.146a.3.3 0 0 0-.113.317 3.014 3.014 0 0 0 .742 1.278.3.3 0 0 0 .33.06l.221-.095a.453.453 0 0 1 .628.363l.029.242a.3.3 0 0 0 .217.256 3.006 3.006 0 0 0 1.478 0 .3.3 0 0 0 .217-.256l.029-.242a.453.453 0 0 1 .628-.363l.221.095a.3.3 0 0 0 .33-.06 3.014 3.014 0 0 0 .742-1.278.3.3 0 0 0-.113-.317Zm-2.6.433a.8.8 0 1 1 .8-.8.8.8 0 0 1-.8.8Z"
            fill="#6c68ea"
          />
        </g>
      </g>
    </g>
  </svg>
)

