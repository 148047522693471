import {Bold} from '../../utils';

export const cardStyle = {
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    backgroundColor: "primary.contrastText",
    cursor: "pointer",
    padding: "12px",
    height: "100%",
    width: "100%",
    textAlign: "left",
    borderRadius: "12px",
  },
  topBlock: {
    display: "flex",
    justifyContent: "space-between",
  },

  title: {
    fontSize: "0.75rem",
    paddingTop: "9px",
    fontFamily: Bold,
    color: "border.primary",
    marginTop:'0px !important',
    textAlign: "-webkit-auto"
  },
  subtitle: {
    fontSize: "0.75rem",
    fontFamily: Bold,
    color: "border.primary",
    textAlign: "-webkit-auto"
  },
  content: {
    marginTop: "-3px",
  },
  count: {
    fontSize: "0.78125",
    textAlign: "left",
    fontFamily: Bold,
  },
  img:{
    marginTop:'-12px'
  }
};
