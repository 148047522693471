import * as React from "react"

export const Lanuage = (props) => (
  <svg
    data-name="Component 283 \u2013 1"
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    {...props}
  >
    <rect
      data-name="Rectangle 44485"
      width={30}
      height={30}
      rx={6}
      fill="none"
    />
    <path
      data-name="icons8-translation (1)"
      d="M8.056 5A3.068 3.068 0 0 0 5 8.056v11.111a3.068 3.068 0 0 0 3.056 3.056h.334a3.042 3.042 0 0 0 3 2.778h10.554A3.068 3.068 0 0 0 25 21.944V10.833a3.068 3.068 0 0 0-3.056-3.056h-.334a3.042 3.042 0 0 0-3-2.778Zm0 1.667h10.555A1.377 1.377 0 0 1 20 8.056v.556a.833.833 0 0 0 .833.833h1.111a1.377 1.377 0 0 1 1.389 1.389v11.11a1.377 1.377 0 0 1-1.389 1.389H11.389A1.377 1.377 0 0 1 10 21.944v-.556a.833.833 0 0 0-.833-.833H8.056a1.377 1.377 0 0 1-1.389-1.389V8.056a1.377 1.377 0 0 1 1.389-1.389Zm3.32 1.1a.833.833 0 0 0-.82.845v.278H8.611a.833.833 0 1 0 0 1.667h2.641a.833.833 0 0 0 .27 0h.6a3.584 3.584 0 0 1-.522 1.533 2.455 2.455 0 0 1-.726-.283 4.123 4.123 0 0 1-.568-.451.833.833 0 1 0-1.178 1.178 8.579 8.579 0 0 0 .741.595 4.652 4.652 0 0 1-1.263.205.833.833 0 1 0 0 1.667 5.394 5.394 0 0 0 3.42-1.114.833.833 0 0 0 .7-.514c.026-.031.076-.043.1-.075a5.037 5.037 0 0 0 .962-2.741h.375a.833.833 0 1 0 0-1.667h-.974a.833.833 0 0 0-.27 0h-.7v-.279a.833.833 0 0 0-.846-.845ZM18.056 10a.833.833 0 1 0 .833.833.833.833 0 0 0-.833-.833Zm-2.222 2.778a.833.833 0 1 0 .833.833.833.833 0 0 0-.834-.833Zm2.457 1.668a.833.833 0 0 0-.716.49l-2.5 5.556a.833.833 0 1 0 1.519.684l.216-.481h3.049l.216.481a.833.833 0 1 0 1.519-.684l-2.5-5.556a.833.833 0 0 0-.804-.49Zm-4.679 1.11a.833.833 0 1 0 .833.833.833.833 0 0 0-.834-.833Zm4.722 1.752.774 1.72H17.56Zm-6.944 1.025a.833.833 0 1 0 .833.833.833.833 0 0 0-.834-.833Z"
      fill="#091b29"
    />
  </svg>
)

