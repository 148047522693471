import { Typography, Box } from "@mui/material"
import { DeleteIcon } from "../../assets"
import { InvoiceComplaintCard } from "../invoicecomplaintcard"
import { PaymentModal_style } from "./style"

export const PaymentModal = ({
    t = () => false,
    price = "",
    selectedInvoice = [],
    handleChecked = () => null,
    handleDelete = () => null,
    isQuickPay,
    invoice_total_tax_amount=0,
    onClickPDF=()=>false,
    symbol=""
}) => {
    return (
        <Box>
            <Box>
                <Box >
                    {selectedInvoice?.map((x, index) => {
                        return <InvoiceComplaintCard
                            deleteDrawer={true}
                            data={x}
                            handleChecked={() => handleChecked(x, index)}
                            hidecomponent={""}
                            dropcomponent={isQuickPay && <DeleteIcon />}
                            handleDelete={() => handleDelete(x, index)}
                            InvoicecardStyle={{
                                padding: '8px 0px ! important',
                                borderBottom: "4px solid #E4E8EE"
                            }}
                            isStatus={false}
                            changecomponent={<Typography
                                sx={PaymentModal_style?.viewinvoice} onClick={()=>onClickPDF(x)}>
                                {t('View Invoice')}</Typography>}
                        />
                    })}

                </Box>

                <Box sx={{ padding: '8px 0px' }}>
                    {/* <Typography sx={PaymentModal_style?.summarytitle}>{t('Bill Summary')}</Typography>
                    <Box padding={'8px 0px'}>
                        <Box sx={PaymentModal_style?.amtAlign}>
                            <Typography sx={PaymentModal_style?.amt}>{t('Invoice Amount')}</Typography>
                            <Typography sx={PaymentModal_style?.amt}>{symbol ?? ""} {price}</Typography>
                        </Box>
                        <Box sx={PaymentModal_style?.amtAlign} paddingTop={'8px'}>
                            <Typography sx={PaymentModal_style?.amt}>{t('VAT Amount')}</Typography>
                            <Typography sx={PaymentModal_style?.amt}>{symbol ?? ""} {invoice_total_tax_amount}</Typography>
                        </Box>
                    </Box> */}
                    <Box sx={PaymentModal_style.totalamtSec}>
                        <Typography sx={PaymentModal_style?.Totalamt}>{t('Total Amount To Pay')}</Typography>
                        <Typography sx={PaymentModal_style?.Totalamt}>{symbol??""} {price}</Typography>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}