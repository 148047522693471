export const CalendarIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="Group_117624" data-name="Group 117624" transform="translate(-5723 4904)">
                <g id="Rectangle_58529" data-name="Rectangle 58529" transform="translate(5723 -4904)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="14" height="14" stroke="none" />
                    <rect x="0.5" y="0.5" width="13" height="13" fill="none" />
                </g>
                <path id="icons8-calendar_14_" data-name="icons8-calendar (14)" d="M19,8.889V8.347A2.35,2.35,0,0,0,16.653,6H8.347A2.35,2.35,0,0,0,6,8.347v.542ZM6,9.972v6.681A2.35,2.35,0,0,0,8.347,19h8.306A2.35,2.35,0,0,0,19,16.653V9.972Zm3.431,6.861a.9.9,0,1,1,.9-.9A.9.9,0,0,1,9.431,16.833Zm0-3.25a.9.9,0,1,1,.9-.9A.9.9,0,0,1,9.431,13.583Zm3.069,3.25a.9.9,0,1,1,.9-.9A.9.9,0,0,1,12.5,16.833Zm0-3.25a.9.9,0,1,1,.9-.9A.9.9,0,0,1,12.5,13.583Zm3.069,0a.9.9,0,1,1,.9-.9A.9.9,0,0,1,15.569,13.583Z" transform="translate(5717.5 -4909.5)" fill="#a1c56a" />
            </g>
        </svg>

    )
}