export const VisitIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="Group_117682" data-name="Group 117682" transform="translate(8439 5885)">
                <g id="Rectangle_58542" data-name="Rectangle 58542" transform="translate(-8439 -5885)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="14" height="14" stroke="none" />
                    <rect x="0.5" y="0.5" width="13" height="13" fill="none" />
                </g>
                <path id="icons8-person_2_" data-name="icons8-person (2)" d="M14,4a3.64,3.64,0,0,0-3.75,3.5A3.64,3.64,0,0,0,14,11a3.64,3.64,0,0,0,3.75-3.5A3.64,3.64,0,0,0,14,4ZM9.688,12.4A1.643,1.643,0,0,0,8,13.975v.42a3.16,3.16,0,0,0,1.766,2.587A8.33,8.33,0,0,0,14,18a8.33,8.33,0,0,0,4.234-1.018A3.16,3.16,0,0,0,20,14.395v-.42A1.643,1.643,0,0,0,18.313,12.4Z" transform="translate(-8446 -5889)" fill="#fff" />
            </g>
        </svg>


    )
}