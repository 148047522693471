import * as React from "react";

export const Order = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={32} {...props}>
    <g data-name="Group 109127">
      <g data-name="Group 105867">
        <g data-name="Group 103748">
          <path
            data-name="Rectangle 54006"
            d="M0 0h36v24a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V0Z"
            fill="#fbe8ff"
          />
        </g>
      </g>
      <path
        data-name="icons8-inspection (3)"
        d="M17 8a1.806 1.806 0 0 0-1.781 1.6H13.8a1.8 1.8 0 0 0-1.8 1.8v10.814a1.8 1.8 0 0 0 1.8 1.8h9.209a1.8 1.8 0 0 0 1.8-1.8V11.4a1.8 1.8 0 0 0-1.8-1.8h-1.42A1.806 1.806 0 0 0 19.808 8Zm0 1.2h2.8a.6.6 0 0 1 0 1.2H17a.6.6 0 0 1 0-1.2Zm4 5.605a.6.6 0 0 1 .425 1.025l-3.6 3.6a.6.6 0 0 1-.848 0l-1.6-1.6a.6.6 0 0 1 .849-.849l1.174 1.18 3.179-3.179a.6.6 0 0 1 .429-.175Z"
        fill="#c448d4"
      />
    </g>
  </svg>
);

