import { favCards_style } from "./style";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { generateImageUrl, img_size } from "../../utils";
export const FavCards = ({
  image = "",
  title = "",
  is_image = false,
  router = "",
  id = "",
  is_active = "",
  bgcolor = {},
  is_property=false,
  propFunc=()=>false,
  type="",
  root_style={}
}) => {
  const navigate = useNavigate();


  const clickFunction = () => {
    if (router) {
      navigate(router, { state: id })
    }
  }
  return (

    <Box sx={{...favCards_style?.root , ...root_style}} onClick={!is_property ? clickFunction : ()=>propFunc(router , type)} >
      <Box sx={{ ...favCards_style?.background, ...bgcolor }}>
        {
          is_image ? <img src={generateImageUrl(image, img_size.small_square)} alt={title} /> : <Box sx={favCards_style?.imageAlign}>{image}</Box>
        }
      </Box>
      <Typography sx={favCards_style?.bottomText} noWrap>{title}</Typography>
    </Box>

  );
}; 
