import { TownCards_Style } from "./style";
import { Box, Typography } from "@mui/material";
export const TownCards = ({ title = "", image = "", homeimage = "" , des="" }) => {
  return (
    <>
      <Box sx={TownCards_Style?.sx} style={{ backgroundImage: `url(${homeimage})`}}>
        <Box display={'block'} noWrap>
          <Typography sx={TownCards_Style?.title} noWrap>{title}</Typography>
          <Typography sx={TownCards_Style?.title} noWrap >{des}</Typography>
        </Box>
        <Box sx={TownCards_Style.img}>{image}</Box>
      </Box>
    </>
  );
};
