import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";
// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
    },
});
const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });;

export const InvoicesAPI = createApi({
    reducerPath: "InspectionAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["InspectionAPI"],
    endpoints: (builder) => ({
        // invoice overdue API
        invoicesOverdue: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `/owner/get_owner_invoice_overdue`,
                        method: "POST",
                        body: payload
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),
        // Get invoices  API
        invoices: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `/owner/get_owner_invoice`,
                        method: "POST",
                        body: payload
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

        // creation API for already paid
        alreadypaidCreation: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `/payment_history/already_paid`,
                        method: "POST",
                        body: payload
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),
                    // Get invoices  API
        invoicesPdf: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `/invoice/template`,
                        method: "POST",
                        body: payload
                    }
                },

                transformResponse: (response, meta, arg) => {
                        return { data: response };
                },
            }),
            inspectionPdf: builder.query(
                {
                    query: (payload) => {
                        return {
                            url: (payload?.request_type!=="move-in"&&payload?.request_type !=="move-out")?`${config.api_url}general_inspection/template`:`${config.api_url}move-in-out/template`,
                            method: "POST",
                            body: payload
                        }
                    },
    
                    transformResponse: (response, meta, arg) => {
                            return { data: response };
                    },
                }),

    }),
});

// Export hooks for usage in functional components
export const {
    useLazyInvoicesOverdueQuery,
    useLazyInvoicesQuery,
    useLazyAlreadypaidCreationQuery,
    useLazyInvoicesPdfQuery,
    useLazyInspectionPdfQuery
} = InvoicesAPI;
