import * as React from "react"

export const Maintenance = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.781}
    height={18.8}
    {...props}
  >
    <path
      data-name="icons8-maintenance (4)"
      d="M2.096.401a.744.744 0 0 0-.446.214l-1 1a.747.747 0 0 0-.1.93L2.472 5.56a1.732 1.732 0 0 0 1.281.809 1.811 1.811 0 0 0 .2.012 1.72 1.72 0 0 0 1.045-.359l3.661 3.661a2.565 2.565 0 0 0 .409 3.056l4.93 4.9a2.545 2.545 0 0 0 1.811.762h.01a2.562 2.562 0 0 0 1.82-4.366l-4.926-4.9a2.545 2.545 0 0 0-1.81-.76h-.012a2.539 2.539 0 0 0-1.143.28L6.055 4.962a1.729 1.729 0 0 0-.454-2.523L2.58.517a.75.75 0 0 0-.484-.116Zm11.358 0a5.368 5.368 0 0 0-1.443.112 5.225 5.225 0 0 0-4.03 4.243 5.438 5.438 0 0 0-.065.651l2.083 2.094a3.419 3.419 0 0 1 .892-.119 3.549 3.549 0 0 1 2.53 1.055l1.945 1.934a5.217 5.217 0 0 0 2.752-6.361.745.745 0 0 0-1.232-.271L14.664 5.96a1.357 1.357 0 0 1-1.907.032 1.293 1.293 0 0 1 .022-1.806l2.285-2.285a.746.746 0 0 0-.269-1.232 5.166 5.166 0 0 0-1.341-.26Zm-7.1 8.382-5.181 5.218a2.549 2.549 0 0 0 1.809 4.344h.01a2.53 2.53 0 0 0 1.8-.754l3.853-3.87-.278-.276a3.572 3.572 0 0 1-.874-3.522Z"
      fill="#139ea5"
      stroke="#dbf0f1"
      strokeWidth={0.8}
    />
  </svg>
)
