import { Bold, Regular } from "../../utils";

export const InspectionCard_style = {
    background: {
    },
    sx: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'primary.contrastText',
        padding: '12px 9px',
        borderRadius: "4px"
    },
    photoSec: {
        width: '100%',
        height: '100%',
        borderRadius: '4px',
        backgroundPosition:"center !important",
        backgroundSize:"cover !important",
        backgroundRepeat:"no-repeat !important"
    },
    date: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: 'backgroundcard.teritry',
        marginInlineStart: "8px"
    },
    name: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: 'backgroundcard.teritry',
        marginInlineStart: '8px'
    },
    title: {
        fontSize: "0.875rem",
        fontFamily: Bold,
        color: 'color?.primary',
    },
    status: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: 'primary.main',
        textTransform:"capitalize"
    },
    plotname: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        padding: '6px 0px',
        marginLeft: '5px'
    },
    topLine: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    bottomLine: {
        display: 'flex',
        alignItems: 'center',
    },
    bottomsLine: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '5px'
    },
    bg: {
        padding: '3px 6px',
        backgroundColor: 'backgroundcard.label'
    },
    divider: {
        borderBottom: '1px solid #E4E8EE',
        // padding: '12px 0px',
        background:"#fff"
    },
    topSide: {
        width: '100%'
    }

}