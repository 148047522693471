import { Bold, Regular, SemiBold } from "../../utils";



export const AddressBoxStyle = {
    title: {
        textAlign: "left",
        fontFamily: Bold,
        fontSize: "0.875rem",
    },
    subTitle: {
        fontFamily: SemiBold,
        fontSize: "0.875rem",
        color: "primary.main",
        cursor: "pointer",

    },
    addressBoxStyle: {
        borderRadius: "4px",
        padding: "12px",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "100%",
        backgroundPositionY: "100%",
        marginTop: "16px",
        backgroundColor: "primary.contrastText",
        border: "1.5px solid",
        borderColor:"border.secondary",
        "&:hover": {
            border: `1.5px solid`,
            borderColor:"primary.main"
          },
    },
    editText: {
        textAlign: "right",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        color: "primary.main",
        cursor: "pointer",
    },
    addressText: {
        fontSize: "0.75rem",
        color: "text.main",
        fontFamily: Bold,
        wordWrap: "break-word",
    },
    addressBoxText: {
        fontSize: "0.75rem",
        color: "text.secondary",
        wordWrap: "break-word",
        fontFamily: Regular,
    }


};
