import { Bold, Regular, SemiBold } from "../../utils";

export const landlordApprovalstyle = {
    container: {
        backgroundColor: "background.Teritary",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        padding: "70px 0px 0px",
    },
    root: {
        padding: "0px 16px 16px 16px",
        position: "relative",
        width: '100%',
        height: '100%',
        overflow: "auto",
        backgroundColor: "background.main",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
      detailsBox: {
        marginTop: "16px",
        padding: "16px",
        backgroundColor: "#FFFFFF",
        borderRadius: "12px"
      },
      detialsBoxHeader: {
        fontSize: "0.75rem",
        color: "text.secondary",
        fontFamily: SemiBold
      },
      contractValueBox: {
        marginTop: "10px",
        backgroundColor: "#EDF7F8",
        padding: "8px",
        borderRadius: "6px"
      },
      contractValueText: {
        color: "#6DAFB3",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        marginInlineStart: "6px"
      },
      contractValue: {
        color: "#6DAFB3",
        fontSize: "0.75rem",
        fontFamily: Bold
      },
      detailsText: {
        color: "text.main",
        fontFamily: SemiBold,
        fontSize: "0.875rem"
      },
      detailsSubtext: {
        color: "text.Teritary",
        fontFamily: Regular,
        fontSize: "0.75rem"
      },
      divider: {
        borderRadius: "50%",
        height: "8px",
        width: "8px",
        backgroundColor: "#E4E8EE",
        border: "none",
        marginInline: "8px"
      },
      lineDivider: {
        marginTop: "16px",
        marginBottom: "16px",
        color: "#E4E8EE"
      },
      avatar: {
        height: "48px",
        width: "48px"
      },
      drawerText: {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: Bold,
        marginBottom: "8px"
      },
      signRoot: {
        height: "400px",
        borderRadius: "8px",
        // width: "32.5rem"
        // [theme.breakpoints.up('sm')]: {
        //     width: "32.5rem",
        // },
        // [theme.breakpoints.down('sm')]: {
        //     width: "20.5rem",
        // },
    },
    clearBtn: {
        position: "absolute",
        bottom: "20px",
        textTransform: "capitalize"
    },
    signatureBox: {
        border: "1px solid #E4E8EE"
    }
}