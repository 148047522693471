import { Grid } from "@mui/material";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../utils";
import { DialogDrawer, Slider } from "../../components";

const filterStyles = makeStyles((theme) => ({
    root: {
        width: "500px"
    },
    titleBar: {
        borderBottom: "1px solid #E4E8EE"
    },
    title: {
        fontFamily: SemiBold,
        color: "#091B29",
        fontSize: "1rem",
        //textAlign: "center"
    },
    clear: {
        fontFamily: SemiBold,
        color: "#5078E1",
        fontSize: "0.875rem",
        cursor: "pointer"
    },
    filterTitle: {
        fontFamily: Bold,
        color: "#091B29",
        fontSize: "1rem",
    },
    apply: {
        padding: "8px 12px",
        borderRadius: "10px",
    },
    seeMoreLessTextStyle: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main,
    },
    applyRoot: {
        padding: "12px",
        borderTop: "1px solid #E4E8EE"
    },
    detailImg: {
        borderRadius: theme.palette.borderRadius,
        height: "176px",
        width: "100%",
        objectFit: "cover",
        cursor: "pointer"
    },
    requestServiceImg: {
        borderRadius: theme.palette.borderRadius,
        height: "185px",
        width: "100%",
        objectFit: "cover",
        cursor: "pointer"
    },
    requestServiceSingleImg: {
        borderRadius: theme.palette.borderRadius,
        height: "270px",
        width: "100%",
        objectFit: "cover",
        cursor: "pointer"
    },
    propertyBoardSingleImg: {
        borderRadius: theme.palette.borderRadius,
        height: "279px",
        width: "100%",
        objectFit: "cover",
        cursor: "pointer"
    },

    Detailroot: {
        padding: "16px",
        height: `100%`,
        overflow: "hidden"
    },
    Detailroot1: {
        padding: "12px",
        overflow: "hidden"
    },
    detailImg2: {
        borderRadius: theme.palette.borderRadius,
        height: "80px",
        width: "100%",
        objectFit: "cover"
    },
    propertyBoarddetailImg: {
        borderRadius: theme.palette.borderRadius,
        height: "130px",
        width: "100%",
        objectFit: "cover"
    },

    requestImg2: {
        borderRadius: theme.palette.borderRadius,
        height: "109px",
        width: "100%",
        objectFit: "cover"
    },
    requestImgOpacity: {
        opacity: "100%",
        borderRadius: theme.palette.borderRadius,
        height: "109px",
        width: "100%",
        objectFit: "cover"
    },
    detailTitle: {
        fontFamily: Bold,
        color: "#091B29",
        fontSize: "1rem",
        // marginTop: "4px"
    },
    detailsub: {
        fontFamily: Regular,
        color: "#98A0AC",
        fontSize: "0.875rem",

    },
    build: {
        fontSize: "0.75rem",
        color: "#4E5A6B",
        fontFamily: Bold
    },
    build1: {
        fontSize: "0.75rem",
        color: "#98A0AC",
        fontFamily: Regular,
        marginInlineStart: "4px"
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    availabe: {
        fontSize: "0.75rem",
        color: "#5AC782",
        fontFamily: Bold,
        backgroundColor: "#EEF9EE",
        padding: "2px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline-block",

    },
    unitTag: {
        fontSize: "0.75rem",
        color: "#98A0AC",
        fontFamily: Bold,
        backgroundColor: "#F5F7FA",
        padding: "2px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline-block",
        marginTop: "4px",
        marginInlineStart: "8px"
    },
    border: {
        borderBottom: "1px solid #E4E8EE"
    },
    loc: {
        fontSize: "0.875rem",
        color: "#4E5A6B",
        fontFamily: Regular,
        direction: 'ltr',
        marginInline: "8px"
    },
    viewall: {
        fontSize: "0.875rem",
        cursor: "pointer",
        color: "#5078E1",
        fontFamily: Bold,
    },
    unitSub: {
        fontSize: "0.875rem",
        color: "#4E5A6B",
        fontFamily: Regular,
        marginTop: "8px"
    },
    rental: {
        fontSize: "0.75rem",
        color: "#4E5A6B",
        fontFamily: Bold,
    },
    rental1: {
        fontSize: "0.75rem",
        color: "#4E5A6B",
        fontFamily: SemiBold,
        marginTop: "12px"
    },
    rental2: {
        fontSize: "0.75rem",
        color: "#4E5A6B",
        fontFamily: SemiBold,
        marginTop: "12px",
    },
    rental1bold: {
        fontSize: "0.875rem",
        color: "#091B29",
        fontFamily: Bold,

    },
    viewdetail: {
        fontSize: "0.75rem",
        cursor: "pointer",
        color: "#5078E1",
        fontFamily: Bold,
    },
    UnitCardroot: {
        padding: "12px",
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        backgroundColor: "white",
    },
    unitCardImg: {
        height: "120px",
        width: "100%",
        borderRadius: theme.palette.borderRadius,
        objectFit: "cover"

    },
    unitCardImgdiv: {
        position: "relative",
        borderRadius: theme.palette.borderRadius,
        overflow: "hidden"
    },
    unitName: {
        fontSize: "0.875rem",
        color: "#091B29",
        fontFamily: Bold,
    },
    shortlistBTN: {
        position: "absolute",
        top: "10px",
        right: "10px",
        backgroundColor: "white",
        padding: "8px",
    },
    shortlistBTNactive: {
        position: "absolute",
        top: "10px",
        right: "10px",
        backgroundColor: "#5078E1",
        padding: "8px",
        color: "white",
        '&:hover': {
            backgroundColor: "white",
        }
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
        },
    },
    shortlistbtnlong: {
        padding: "6px 16px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        marginTop: "12px",
        fontSize: "0.875rem"
    },
    cancelBtn: {
        backgroundColor: "#F1F7FF",
        boxShadow: 'none',
        fontSize: "0.75rem",
        color: "#4E5A6B",
        borderRadius: "6px",
        fontFamily: SemiBold

    },
    addImgService: {
        position: "relative",
        backgroundColor: "#504e4e3d",
        borderRadius: "10px",
        height: "109px",
        cursor: "pointer"
    },
    addImg: {
        position: "relative",
        backgroundColor: "#504e4e3d",
        borderRadius: "10px",
        height: "80px",
        cursor: "pointer"
    },
    plus: {
        position: "absolute",
        textAlign: "center",
        left: "0px",
        right: "0px",
        top: "30%",
        color: "white",
        fontFamily: SemiBold

    },
    list: {
        '&.MuiListItem-root': {
            width: '135px !important',
            padding: '0px!important',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            border: '1px solid #E4E8EE',
            cursor: 'pointer',
            marginTop: "-4px"
        }
    },
    tabname: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        cursor: 'pointer'
    },
    imagebox1: {
        padding: '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 16px #00000014',
        borderRadius: '12px',
        height: "400px",
        overflow: "auto"
    },
    imagebox: {
        textAlign: 'center',
        padding: '23px 16px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 16px #00000014',
        borderRadius: '12px',
    },
    listText: {
        backgroundColor: '#5078E1',
        width: '120px !important',
        color: '#fff',
        borderRadius: '6px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: "-4px"
    },
    tabtitle: {
        fontSize: "0.75rem",
        color: '#4E5A6B',
        fontFamily: Bold,
    },
    tabtitle1: {
        fontSize: "0.75rem",
        color: '#5078E1',
        fontFamily: Bold,
    },
    subtabtitle: {
        fontSize: "0.75rem",
        color: '#4E5A6B',
        fontFamily: Regular,
    },
    accordion: {
        boxShadow: "none"
    },
    accordionDetails: {
        // "& .css-15v22id-MuiAccordionDetails-root": {
        //     padding: "8px 0px"
        // }
    },
    dialogclose: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        "&:hover": {
            borderRadius: theme.palette.borderRadius,
            border: "1px solid #E4E8EE",
            backgroundColor: "white",
        }

    },
    discountValue: {
        fontSize: "0.875rem",
        color: '#FF9340',
        fontFamily: Bold,
    },
    discountAppliedRoot: {
        fontSize: "0.75rem",
        color: '#FF9340',
        fontFamily: Bold,
        backgroundColor: "#FFF4EB",
        borderRadius: theme.palette.borderRadius,
        padding: "2px 8px",
        position: "absolute",
        bottom: "6px",
        right: "0px"
    },
    type: {
        position: "absolute",
        bottom: "14px",
        left: "0px",
        textAlign: "center",
        backgroundColor: "#071741",
        color: "white",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        padding: "4px 8px",
        borderRadius: "0px 8px 8px 0px"
    },
    tax: {
        fontSize: "0.75rem",
        color: "#4E5A6B",
        fontFamily: SemiBold,
        marginTop: "2px"
    },
}));


export const AssetView = ({ asset, assetAll, title, isNormal = false, maxWidth = 'md' }) => {
    const classes = filterStyles();
    const [selected, setSelected] = React.useState({
        drawer: false,
        selected: {}
    });

    // assetDrawerOpen
    const assetDrawerOpen = (index) => {
        setSelected({
            ...selected,
            drawer: true,
            selected: index

        })
    }
    // assetDrawerClose
    const assetDrawerClose = () => {
        setSelected({
            ...selected,
            drawer: false
        })
    }
    return (
        <>
            {asset?.length === 1 && (
                <Grid container spacing={1}>
                    <Grid item xs={12} onClick={()=>assetDrawerOpen(0)}>
                        <img src={asset?.[0]?.url} alt="" className={classes.detailImg} />
                    </Grid>
                </Grid>
            )}
            {asset?.length > 1 && (
                <Grid container spacing={1} >
                    <Grid item xs={6.5}>
                        <img src={asset[0]?.url} alt="" className={classes.detailImg} onClick={()=>assetDrawerOpen(0)}/>
                    </Grid>
                    <Grid item xs={5.5}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} onClick={()=>assetDrawerOpen(1)}>
                                <img
                                    src={asset[1]?.url}
                                    alt=""
                                    className={classes.detailImg2}
                                />
                            </Grid>

                            <Grid item xs={6} onClick={()=>asset[2]?.url ? assetDrawerOpen(2) : false}>
                                <img
                                    src={asset[2]?.url ?? "/images/imagesproperty.svg"}
                                    alt=""
                                    className={classes.detailImg2}
                                />
                            </Grid>
                            <Grid item xs={6} onClick={()=>asset[3]?.url ? assetDrawerOpen(3) : false}>
                                <img
                                    src={asset[3]?.url ?? "/images/imagesproperty.svg"}
                                    alt=""
                                    className={classes.detailImg2}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {asset?.length > 5 ? (
                                    <div className={classes.addImg} onClick={()=>asset[4]?.url ? assetDrawerOpen(4) : false}>
                                        <span className={classes.plus}>
                                            +&nbsp;{asset?.length - 5}
                                        </span>
                                        <img
                                            src={asset[4]?.url ?? "/images/imagesproperty.svg"}
                                            alt=""
                                            className={classes.detailImg2}
                                        />
                                    </div>
                                ) : (
                                    <img
                                        src={asset[4]?.url ?? "/images/imagesproperty.svg"}
                                        alt=""
                                        className={classes.detailImg2}
                                        onClick={()=>asset[4]?.url ? assetDrawerOpen(4) : false}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <DialogDrawer
                maxWidth="sm"
                open={selected?.drawer}
                isnotTitle
                padding={"16px 16px 0px"}
                height_style={
                    { height: "auto !important", padding: "16px !important", minHeight: "100px" }
                }
                component={
                    <>
                        <Slider
                            height={"100% !important"}
                            borderRadius={"4px"}
                            imageHeigth={"300px !important"}
                            assets={assetAll?.map((x) => {
                                return {
                                    meta_data: {
                                        file_type: "image",
                                    },
                                    url: x?.url,
                                }
                            })}
                            onClose={() => assetDrawerClose()}
                            selected={selected?.selected ?? 0}
                        />
                    </>
                }

                onClose={() => assetDrawerClose()}
            />
        </>
    );
};
