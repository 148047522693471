import * as React from "react"

export const Checkin = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 107152" transform="translate(-28 -273)">
      <rect
        data-name="Rectangle 55704"
        width={32}
        height={32}
        rx={4}
        transform="translate(28 273)"
        fill="#f1f7ff"
      />
      <path
        data-name="icons8-user-clock (2)"
        d="M41.6 281a3.721 3.721 0 0 0 0 7.442h.046a3.721 3.721 0 0 0-.001-7.442H41.6Zm6.308 7.814A4.093 4.093 0 1 0 52 292.907a4.093 4.093 0 0 0-4.093-4.093Zm-10.523 1.116A1.437 1.437 0 0 0 36 291.41v.566a3.456 3.456 0 0 0 1.777 2.987 6.994 6.994 0 0 0 3.618.92 7.674 7.674 0 0 0 2.43-.387 4.815 4.815 0 0 1 .275-5.566Zm10.15.372a.372.372 0 0 1 .372.372v2.233h1.86a.372.372 0 0 1 0 .744h-2.232a.372.372 0 0 1-.372-.372v-2.6a.372.372 0 0 1 .372-.379Z"
        fill="#78b1fe"
      />
    </g>
  </svg>
)

