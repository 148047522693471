import { Container, Grid, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { WelcomePage_style } from "./style";
import jwt_decode from "jwt-decode";
import { useLazyInitialRenderQuery, useLazyVerifyUserQuery } from "../../redux/services";
import { LocalStorageKeys, errorRoute } from "../../utils";
import { Buttons, DialogDrawer } from "../../components";
import { TermsAndCondition } from "./components/termsAndCondition";
import { useLazyGetTermsConditionQuery } from "../../redux/services/termsAndCondition";
import { initital_screen_state } from "../../utils/login/utils";
import { useSnackbar } from "notistack";

export const WelcomePage = ({ t = () => false }) => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const [verifyUser, { isLoading, error }] = useLazyVerifyUserQuery();
    const [getTermsCondition] = useLazyGetTermsConditionQuery()
    const [initialRender] = useLazyInitialRenderQuery();
    const welcomePageAuthToken = new URLSearchParams(search).get("token");
    const [decoded, setDecoded] = React.useState(null);
    const [tcStatus, setTCStatus] = React.useState(false)
    const [termCondition, setTermCondition] = React.useState("")
    const [is_loading, set_is_loading] = useState(true);
    const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
    const [screen_state, set_screen_state] = React.useState(initital_screen_state);
    const { enqueueSnackbar } = useSnackbar()

    React.useEffect(() => {
        initialLoad();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const img = new Image();

        img.src = screen_state?.mob_welcome_background;

        img.onload = () => {
            setIsBackgroundImageLoading(true);
        };

        img.onerror = () => {
            set_screen_state({ ...screen_state, mob_welcome_background: initital_screen_state?.mob_welcome_background });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen_state?.mob_welcome_background]);

    const initialLoad = () => {
        getInitialRender();
    }

    const getInitialRender = () => {
        initialRender({}).then((res) => {
            let _ = res?.data?.data?.render_data?.[0]
            if (_) {
                let temp_screen_state = {
                    ...screen_state,
                    [_?.id && "id"]: _?.id,
                    [_?.region && "region"]: _?.region,
                    [_?.login_logo && "logo"]: _?.login_logo,
                    [_?.web_background && "web_background"]: _?.web_background,
                    [_?.mobile_background && "mob_background"]: _?.mobile_background,
                    [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
                    [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
                    [_?.flag && "flag"]: _?.flag,
                    [_?.domain && "domain"]: _?.domain,
                }
                set_screen_state(temp_screen_state);
            }
            set_is_loading(false);
        }).catch(() => {
            enqueueSnackbar(t('Some thing Went Wrong'), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" }

            })
            set_is_loading(false);
        });
    }

    const handleClick = () => {
        getStarted()
    }

    const getStarted = async () => {
        const welcome = await verifyUser({ userId: decoded.user_id })
        if (welcome?.data?.is_allowed === true) {
            navigate(AppRoutes?.setup + "?welcomeAuthToken=" + welcomePageAuthToken)
        }
        else {
            navigate(AppRoutes?.login)

        }

    };
    React.useEffect(() => {
        if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
            setDecoded(jwt_decode(welcomePageAuthToken));
            getTermsAndCondition(jwt_decode(welcomePageAuthToken))
            localStorage.clear()
            localStorage.setItem(LocalStorageKeys.welcomeToken, welcomePageAuthToken);

        }

        // eslint-disable-next-line
    }, []);


    React.useEffect(() => {
        if (error?.status === 423) return errorRoute()
    }, [error])

    const getTermsAndCondition = async (decode_data = decoded) => {
        const terms_condition = await getTermsCondition({ client: decode_data?.client })
        setTermCondition(terms_condition?.data?.data)
    }

    const handleClose = () => {
        setTCStatus(false)
    }

    const logIn = () => {
        navigate(
            AppRoutes?.login,
            {
                state: {
                    origin: AppRoutes?.welcomepage
                },
            }
        )
    }

    return (
        is_loading
            ? <></>
            : <Container maxWidth={'sm'} sx={{ position: 'relative', padding: '0px !important' }}>
                <Box sx={{
                    ...WelcomePage_style?.root,
                    backgroundImage: isBackgroundImageLoading
                        ? `url(${screen_state?.mob_welcome_background})`
                        : "none",
                    backgroundColor: '#757575',
                }}>
                    <Grid
                        item
                        xs={12}
                    >
                    </Grid>
                    <Box sx={WelcomePage_style?.btnGrid}>
                        <Container maxWidth='sm' style={{ padding: "0px !important" }}>
                            <img style={WelcomePage_style?.mob_logo} src={screen_state?.logo} alt="" />
                            <Box height={"20px"} />
                            <Box width={"100%"} display={""}>
                                <Typography sx={WelcomePage_style?.mob_description}>
                                    {t("Easy, secure & most reliable solution for property & facility management")}
                                </Typography>
                            </Box>
                            <Box height={"40px"} />
                            <Buttons
                                fullWidth
                                variant="contained"
                                sx={WelcomePage_style?.setup_button}
                                onClick={handleClick}
                                type="submit"
                                btnLoading={isLoading}
                                btnName={t("Set Up New Account")}
                                buttonStyle={{
                                    color: "#FFFFFF",
                                    "&:hover": {
                                        color: '#FFFFFF',
                                        backgroundColor: "#F15A29",
                                    },
                                }}
                            />
                            <Box height={"12px"} />
                            <Buttons
                                fullWidth
                                variant="contained"
                                sx={WelcomePage_style?.login_button}
                                onClick={logIn}
                                type="submit"
                                btnLoading={isLoading}
                                btnName={t("Log In")}
                                buttonStyle={{
                                    color: '#FFFFFF',
                                    "&:hover": {
                                        color: '#FFFFFF',
                                        backgroundColor: "#ffffff75",
                                    },
                                }}
                            />
                            <Box height={"20px"} />
                            <Typography sx={WelcomePage_style?.termsAndConditionText} onClick={() => setTCStatus(true)}>
                                {t(`By tapping "Set Up New Account", you agree to our `)} <span sx={WelcomePage_style?.termsAndConditionUnderLineText}><u>{t("Terms & Conditions")}</u></span>
                            </Typography>
                        </Container>
                    </Box>
                </Box>
                <DialogDrawer
                    height_style={
                        { height: "535px !important" }
                    }
                    padding={"0px"}
                    open={tcStatus ?? false}
                    header={""}
                    component={
                        <>
                            <Box sx={{ height: "460px", overflow: "auto" }} p={2}>
                                <TermsAndCondition termsCondition={termCondition} t={t} />
                            </Box>
                            <Box sx={WelcomePage_style?.btnBox}>
                                <Buttons
                                    fullWidth
                                    variant="contained"
                                    onClick={handleClick}
                                    type="submit"
                                    btnLoading={isLoading}
                                    btnName={t("Get Started")}
                                    buttonStyle={{
                                        color: "#fff",
                                        "&:hover": {
                                            color: '#F15A29',
                                            backgroundColor: "#FFFFFF",
                                        },
                                    }}
                                />
                            </Box>
                        </>
                    }
                    onClose={() => handleClose()}
                />
            </Container>
    );
};
