import * as React from "react"

export const Schedule = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} {...props}>
    <path
      data-name="icons8-schedule (1)"
      d="M4.107 0a4.107 4.107 0 1 0 4.107 4.107A4.115 4.115 0 0 0 4.107 0Zm0 1.071a3.036 3.036 0 1 1-3.036 3.036 3.028 3.028 0 0 1 3.036-3.036Zm-.007.707a.536.536 0 0 0-.527.543v1.786a.536.536 0 0 0 .157.379l.714.714a.536.536 0 1 0 .756-.757l-.557-.557V2.321a.536.536 0 0 0-.543-.543Zm4.408.365a4.771 4.771 0 0 1 .336 1.071h3.836a1.251 1.251 0 0 1 1.25 1.25V5H8.843a4.808 4.808 0 0 1-.336 1.071h5.421v6.607a1.251 1.251 0 0 1-1.25 1.25H4.464a1.251 1.251 0 0 1-1.25-1.25V8.843a4.808 4.808 0 0 1-1.071-.336v4.171A2.324 2.324 0 0 0 4.464 15h8.214A2.324 2.324 0 0 0 15 12.679V4.464a2.324 2.324 0 0 0-2.321-2.321ZM8.571 7.5a.893.893 0 1 0 .893.893.893.893 0 0 0-.893-.893Zm3.036 0a.893.893 0 1 0 .893.893.893.893 0 0 0-.893-.893Zm-6.071 3.214a.893.893 0 1 0 .893.893.893.893 0 0 0-.893-.893Zm3.036 0a.893.893 0 1 0 .893.893.893.893 0 0 0-.894-.893Z"
      fill="#071741"
    />
  </svg>
)
