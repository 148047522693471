import React from "react";
import { AuthContext } from "./contexts";


 const AppAuth = (props) => {
  const [auth, setAuth] = React.useState({
    language: "en",
  })
 

  const languageSwitcher = (value) => {
    setAuth({ auth: value })
  }



  return <>
    <AuthContext.Provider value={{auth , languageSwitcher}}>
      {props.children}
    </AuthContext.Provider>
  </>

}
export default AppAuth