import { Box , Typography} from "@mui/material";
import React from "react";
import { TickIcon } from "../../assets";
import { styles } from './style'
import {LanguageOptions} from '../../utils'

export const LanuageSwitcher = ({
    updateLanguage=()=>false,
    selectedLanguage="",
    t = () => false
}) => {
    return (
        <Box>
            <div sx={styles.root}>
                {
                   LanguageOptions(t)?.map((item) => {
                        return (
                            <>
                                <Box
                                    sx={styles.list2}
                                    onClick={() =>updateLanguage(item)}
                                >
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        {/* language name */}
                                        <Box>
                                            <div>
                                                <Typography sx={styles.Tittle}>
                                                    {item?.label}
                                                </Typography>
                                            </div>
                                        </Box>
                                    </Box>
                                    <Box>
                                        {item?.value ===
                                            selectedLanguage?.value && (
                                                <TickIcon/>
                                            )}
                                    </Box>
                                </Box>
                            </>
                        )
                    })


                }

            </div>
        </Box>
    )
}