import { Bold, Regular } from "../../utils";

export const InvoiceComplaintCard_style = {
  sx: {
    display: 'flex',
    alignItems: 'center'
  },
  check: {
    marginInlineEnd: '10px'
  },
  title: {
    textAlign: "-webkit-match-parent",
    fontFamily: Bold,
    fontSize: "0.875rem",
  },
  text: {
    textAlign: "left",
    fontFamily: Regular,
    fontSize: "0.75rem",
    color: 'text.Teritary',
    paddingLeft: '4px',
  },
  dot: {
    padding: "3px",
    backgroundColor: "backgroundcard.light",
    borderRadius: "50%",
    margin: '0px 4px'
  },
  price: {
    textAlign: "right",
    fontFamily: Bold,
    fontSize: "1rem",
    color: 'text.Teritary',
    marginBottom: '4px'
  },
  bg: {
    borderRadius: '4px',
    opacity: 1,
    padding: '1px 8px',
    backgroundColor: 'backgroundcard.label'
  },
  statusText: {
    textAlign: "right",
    fontFamily: Bold,
    fontSize: "0.75rem",
    color: 'primary.main',
  },
  singleline: {
    marginTop: '6px',
    display: 'flex',
    alignItems: 'center'
  },
  centeralignse: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  divider: {
    borderBottom: "1px solid #E4E8EE",
    margin: '14px',
    "& divider:last-child": {
      borderBottom: 'none !important'
    }
  },
  main: {
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: "1px solid #E4E8EE",
    cursor:"pointer"
  },
  amtOverall: {
    display: "flex",
    alignItems: 'center'
  }
}