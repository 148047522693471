import { Bold, FontSizeCalc, Regular, SemiBold } from "../../utils";



export const RequestViewStyle = {
  root: {
    padding: "16px 16px",
    backgroundColor: "primary.contrastText",
    borderRadius: "12px",
    marginBottom: "16px"
  },
  rootBottom: {
    padding: "16px 16px",
    backgroundColor: "primary.contrastText",
    borderRadius: "12px",
  },
  main: {
    padding: "16px 16px",
    backgroundColor: "background.main",
    overflow: "auto"

  },
  mainheight: {
    padding: "16px 16px",
    backgroundColor: "background.main",
    height: "calc(100vh - 140px)",
    overflow: "auto"

  },
  badgeStack: {
    marginBottom: "9px"
  },
  badge: {
    marginRight: "8px",
    fontFamily: SemiBold,
    fontSize: "0.75rem",
    color: "text.main",
    padding: "4px 10px",
    backgroundColor: "border.blue",
    borderRadius: "5px"
  },
  head: {
    color: "text.main",
    fontSize: "1rem",
    fontFamily: Bold
  },
  para: {
    color: "primary.Tertiary",
    fontSize: "0.75rem",
    // padding: "4px 0 14px 0",
    fontFamily: Regular
  },
  gridBox: {
    "& p": {
      color: "text.main",
      fontSize: {
        sm: "0.875rem",
        xs: "0.8125rem"
      },
      whiteSpace: {
        xs: "nowrap"
      },
      fontFamily: SemiBold
    },
    "& div": {
      marginTop: "6px",
      // marginBottom: "16px",
      "& p": {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: Regular,
        marginInlineStart: "6px",
      }
    }
  },
  statusCard: {
    backgroundColor: "backgroundcard.light1",
    borderRadius: "4px",
    padding: "16px",
    // display:"flex"
  },
  commonHead: {
    color: "text.main",
    fontSize: "0.875rem",
    fontFamily: SemiBold
  },
  dateTime: {
    color: "text.main",
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    marginLeft: "7px"
  },
  calendardiv: {
    paddingTop: "8px"
  },
  statusText: {
    color: "text.main",
    fontSize: "0.9375rem",
    fontFamily: Bold,
    marginTop: "7px"
  },
  status: {
    color: "primary.main",
    fontSize: "0.875rem",
    marginInlineStart: "3px",
    fontFamily: SemiBold
  },
  statusPoint: {
    color: "primary.Tertiary",
    fontSize: "0.875rem",
    fontFamily: SemiBold
  },
  svg: {
    backgroundColor: "border.secondary",
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  contactSec: {
    paddingLeft: "10px",
    "& div": {
      "& p": {
        color: "border.divider",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        whiteSpace: {
          xs: "nowrap"
        },
      },
      "& hr": {
        height: "13px",
        color: "border.divider",
        margin: "0 8px"
      }
    }
  },
  contact: {
    marginTop: "4px"
  },
  addItem: {
    "& div": {
      "& p": {
        fontSize: "1rem",
        color: "text.main",
        fontFamily: Bold,
        marginLeft: "12px"
      }
    }
  },
  addBtn: {
    fontSize: "0.875rem",
    color: "primary.main",
    fontFamily: Bold,
    marginLeft: "12px",
    cursor: "pointer"
  },

  container: {
    padding: "0 !important",
    position: "relative"
  },
  foot: {
    position: "sticky",
    width: "100%",
    bottom: "0"
  },
  top: {
    position: "sticky",
    width: "100%",
    top: "0",
    zIndex: 999
  },
  timeLineComp: {
    paddingTop: "8px"
  },
  notesStatus: {
    fontSize: "0.9375rem",
    fontFamily: SemiBold,
    marginTop: "10px"
  },
  imageStyle: {
    height: "75px",
    width: "75px",
    borderRadius: "4px",
    margin: "0px 16px 16px 0px",
    objectFit: "contain"
  },
  timeLine: {
    backgroundColor: "primary.contrastText",
    borderRadius: "12px",
    marginBottom: "16px"
  },
  text: {
    color: "text.main",
    fontSize: "1rem",
    fontFamily: Bold
  },
  divider: {
    backgroundColor: "border.secondary",
    height: "1px"
  },
  lineOne: {
    color: "primary.Tertiary",
    fontSize: "0.75rem",
    fontFamily: SemiBold
  },
  lineTwo: {
    color: "text.main",
    fontSize: "0.875rem",
    fontFamily: Bold
  },
  add: {
    color: "text.btn",
    fontSize: "0.875rem",
    fontFamily: Bold,
    cursor: "pointer"
  },
  update: {
    color: "text.btn",
    fontFamily: Bold,
    fontSize: "0.75rem",
    cursor: "pointer"
  },
  expenseBox: {
    backgroundColor: "background.secondary",
    width: "100%",
    padding: "11px 8px",
    borderRadius: "8px"
  },
  currency: {
    color: "border.divider",
    fontFamily: Regular,
    fontSize: "0.875rem",
    marginLeft: "6px"
  },
  cost: {
    color: "text.main",
    fontSize: "0.875rem",
    fontFamily: Bold
  },
  addSpending: {
    color: "text.Teritary",
    fontSize: "0.75rem",
    fontFamily: Regular
  },
  reject: {
    backgroundColor: "error.Teritary",
    borderRadius: "20px",
    color: "#fff",
    padding: "10px 47px",
    cursor: "pointer",
    height: "40px",
    fontFamily: Bold,
    "&:hover": {
      backgroundColor: "error.Teritary",
    }
  },
  accept: {
    backgroundColor: "status.success",
    borderRadius: "20px",
    color: "#fff",
    padding: "10px 45px",
    cursor: "pointer",
    height: "40px",
    fontFamily: Bold,
    "&:hover": {
      backgroundColor: "status.success",
    }
  },
  notesHeight: {
    maxHeight: "120px",
    overflow: "scroll"
  },
  label: {
    color: "text.Teritary",
    fontSize: "0.75rem",
    fontFamily: Bold
  },
  total: {
    color: "text.Teritary",
    fontSize: "0.75rem",
    fontFamily: SemiBold
  },
  costingBox: {
    backgroundColor: "background.secondary",
    borderRadius: "4px",
    padding: "9px 16px"
  },
  recentNote: {
    fontSize: "0.9375rem",
    fontFamily: Bold,
    color: "text.secondary",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  borderBottom: {
    borderBottom: "1px solid #E4E8EE",
    marginTop: "16px",
    // marginBottom: "16px"
  },
  borderRight: {
    backgroundColor: "#E4E8EE",
    height: "45px",
  },
  itemName: {
    fontSize: "0.875rem",
    color: "text.primary",
    fontFamily: Regular,
    textOverflow: "ellipsis",
    overflow: "hidden",
    // [theme.breakpoints.only("xs")]: {
    //   maxWidth:"90px"
    // },
    // [theme.breakpoints.up("xs")]: {
    //   maxWidth:"150px"
    // },
  },
  BOMstatusBox: {
    borderRadius: "4px",
    fontSize: '0.75rem',
    fontFamily: SemiBold,
    color: "#FFFFFF",
    padding: "5px"
  },
  requestNo: {
    fontSize: "0.875rem",
    color: "text.secondary",
    fontFamily: Regular
  },
  BillingAmountTxt: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: "text.Teritary"
  },
  finalamttext: {
    color: "text.primary",
    fontFamily: SemiBold,
    fontSize: "0.875rem",
  },
  viewDetailsTxt: {
    color: "#5078E1",
    fontFamily: SemiBold,
    fontSize: "0.875rem",
    cursor: "pointer"
  },
  addNote: {
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    color: "#5078E1",
    cursor: "pointer"
  },
  notesBox: {
    border: "1px solid #CED3DD",
    // padding:"16px",
    borderRadius: "4px"
  },
  notesLabel: {
    fontSize: "0.875rem",
    color: "text.primary",
    fontFamily: Bold
  },
  removeTxt: {
    fontSize: "0.875rem",
    color: "#FF4B4B",
    fontFamily: Bold,
    cursor: "pointer"
  },
  fixBottom: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "16px"
  },
  requestDetailsBox: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "8px"
  },
  rejectbtn: {
    backgroundColor: "#FFECEC",
    border: "1px solid #FF4B4B",
    borderRadius: "8px",
    color: "#FF4B4B",
    fontSize: "0.75rem",
    boxShadow: "none",
    fontFamily: Bold,
    "&:hover": {
      backgroundColor: "#FFECEC",
      border: "1px solid #FF4B4B",
    },
    textTransform: "capitalize"
  },
  reschedulebtn: {
    backgroundColor: "#F1F7FF",
    border: "1px solid #5078E1",
    borderRadius: "8px",
    color: "#5078E1",
    fontSize: "0.75rem",
    boxShadow: "none",
    fontFamily: Bold,
    "&:hover": {
      backgroundColor: "#F1F7FF",
      border: "1px solid #5078E1",
    },
    textTransform: "capitalize"
  },
  approvebtn: {
    backgroundColor: "#EEF9EE",
    border: "1px solid #5AC782",
    borderRadius: "8px",
    fontSize: "0.75rem",
    boxShadow: "none",
    fontFamily: Bold,
    color: "#5AC782",
    "&:hover": {
      backgroundColor: "#EEF9EE",
    },
    textTransform: "capitalize"
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: "4px",
      border: "0px solid #CED3DD",
      "& :hover": {
        border: "none",
      },
    },
  },
  requsetsubs: {
    fontSize: "0.875rem",
    fontFamily: SemiBold,
  },
  kpibox: {
    borderRadius: "25px",
    padding: "4px 8px",
    // opacity:"15%"
  },
  subLine: {
    fontSize: "0.75rem",
    fontFamily: Bold
  },
  totaltimeBox: {
    borderRadius: "12px",
    border: "1px solid #CED3DD"
  },
  timeTaken: {
    color: "#33691E",
    fontSize: "0.875rem",
    fontFamily: SemiBold
  },
  requestPendingBox: {
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 30px #4354721F"
  },
  subText: {
    color: "text.primary",
    fontFamily: Regular,
    fontSize: "0.75rem"
  },
  btnfix: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "16px",
    border: "1px solid #E4E8EE"
  },
  dotted: {
    border: "1px dashed #CED3DD"
  },
  reschedulebox: {
    borderRadius: "8px",
    backgroundColor: "#F5F7FA",
    padding: "16px"
  },
  applyBoxShadow: {
    boxShadow: "0px 8px 24px #0717411F"
  },
  costingStatusText: {
    color: "#FFFFFF",
    padding: "4px 12px",
    borderRadius: "25px",
    fontFamily: SemiBold,
    fontSize: "0.75rem"
  },
  verticalborder: {
    borderInlineStart: "2px dashed #CED3DD",
    margin: "-20px 12px",
    height: "55px"
  },
  productTitle: {
    fontSize: "0.875rem",
    fontFamily: Bold,
    color: "primary.text",
  },
  notesTitle: {
    color: "primary.text",
    fontSize: "0.875rem",
    fontFamily: SemiBold,
  },
  nudge_card: {
    cursor: "pointer",
    borderRadius: "12px",
    padding: "16px",
    boxshadow: "0px 3px 30px #4354721F",
    backgroundColor: "#FFFFFF",
    marginBottom: "16px"
  },
  invoice_total_amount: {
    fontFamily: SemiBold,
    fontSize: FontSizeCalc(14),
    color: "#091B29",
  },
  invoice_count: {
    fontFamily: Regular,
    fontSize: FontSizeCalc(12),
    color: "#4E5A6B",
  },
  pay_now_button: {
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#F1F7FF",
    "&:hover": {
      backgroundColor: "#F1F7FF",
    },
    fontFamily: Bold,
    fontSize: FontSizeCalc(12),
    color: "#5078E1",
    textTransform: "capitalize",
  },
  newReschedulebox: {
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E4E8EE",
    padding: "16px"
  },
  bottomfix: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "16px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    backgroundColor: "white"
  },
  slotTime: {
    fontSize: FontSizeCalc(12),
    fontFamily: SemiBold,
    color: "text.primary"
  },
  slotBox: {
    borderRadius: "8px",
    border: "1px solid #CED3DD",
    display: "flex",
    justifyContent: "center",
    padding: "12px 0px"
  },
  rescheduleText: {
    fontSize: FontSizeCalc(14),
    fontFamily: SemiBold,
    color: "text.primary",
    textWrap: "wrap"
  },
  selectedSlotBox: {
    borderRadius: "8px",
    border: "1px solid #5078E1",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#F1F7FF",
    padding: "12px 0px"
  },
  slotTitle: {
    fontSize: FontSizeCalc(12),
    fontFamily: Bold,
    color: "#98A0AC"
  },
}