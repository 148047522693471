import React from "react";
import { InspectionDetails } from "./inspectiondetails";
import { withTranslation } from 'react-i18next';

class InspectionDetailParent extends React.Component {
    constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <InspectionDetails  {...this?.props}/>;
  }
}
export default withTranslation('Inspection') (InspectionDetailParent);
