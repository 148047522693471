import * as React from "react"

export const InvestmentSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={18} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 52013"
          transform="translate(81.699 184.39)"
          fill="#fff"
          stroke="#707070"
          d="M0 0h21v18H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Group 6395"
      transform="translate(-81.699 -184.39)"
      clipPath="url(#a)"
    >
      <path
        data-name="icons8-investment (1)"
        d="M85.985 184.391a1.929 1.929 0 0 0-1.929 1.929v13.285a1.929 1.929 0 0 0 1.929 1.929h6.017a1.694 1.694 0 0 1-.231-.857v-5.143c0-1.688 2.372-2.571 4.714-2.571a9.121 9.121 0 0 1 1.286.09v-1.8h-4.929a1.929 1.929 0 0 1-1.929-1.929v-4.933Zm6.214.377v4.552a.644.644 0 0 0 .643.643h4.552Zm-4.071 6.073a.536.536 0 0 1 .536.536v.337a1.766 1.766 0 0 1 1.328.907.536.536 0 1 1-.941.513.7.7 0 0 0-.612-.363h-.683a.7.7 0 1 0 0 1.393h.964a1.768 1.768 0 1 1 0 3.535h-.054v.3a.536.536 0 1 1-1.071 0v-.3h-.2a1.768 1.768 0 0 1-1.553-.922.536.536 0 1 1 .941-.513.7.7 0 0 0 .612.364h1.328a.7.7 0 0 0 0-1.393h-.967a1.763 1.763 0 0 1-.161-3.52v-.337a.536.536 0 0 1 .532-.538Zm8.357 2.979c-2.13 0-3.857.768-3.857 1.714s1.727 1.714 3.857 1.714 3.857-.768 3.857-1.714-1.727-1.714-3.857-1.714Zm-3.857 3v1.286c0 .947 1.727 1.714 3.857 1.714s3.857-.767 3.857-1.714v-1.286c0 .947-1.727 1.714-3.857 1.714s-3.858-.767-3.858-1.714Zm0 2.571v1.286c0 .947 1.727 1.714 3.857 1.714s3.857-.767 3.857-1.714v-1.286c0 .947-1.727 1.714-3.857 1.714s-3.858-.767-3.858-1.714Z"
        fill="#20adf8"
      />
    </g>
  </svg>
)

export default InvestmentSvg
