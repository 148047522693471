import React from "react";
import { withTranslation } from 'react-i18next';
import { ChooseAppliance } from "./chooseAppliance";

class ChooseApplianceParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
}
    render() {
        return <ChooseAppliance  {...this?.props} />;
    }
}

export default withTranslation('applications')(ChooseApplianceParent);
