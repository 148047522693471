import { Typography , Box} from "@mui/material"
import { TransparentChat } from "../../assets"
import { MessageCard_style } from "./style"

export const MessageCard = ({t = () => false,date="",message=""}) => {
    return(
        <Box sx={MessageCard_style?.card}>
            <Box sx={MessageCard_style?.alignbottom}>
                <Box sx={MessageCard_style?.align}>
                    <TransparentChat />
                    <Typography sx={MessageCard_style?.reply}>{t('REPLY MESSAGE')}</Typography>
                </Box>
                <Typography sx={MessageCard_style?.date}>{t('Replied on ')}{date}</Typography>
            </Box>
            <Typography sx={MessageCard_style?.message}>{message}</Typography>
        </Box>
    )
}