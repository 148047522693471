import React from "react";
import { withTranslation } from 'react-i18next';
import { ContractHistory } from "./contractHistory";
  
class ContactHistoryParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
    
        }
      }
  render() {
    return <ContractHistory {...this?.props}/> ;
  }
}

export default withTranslation('chooseAppliance')(ContactHistoryParent);