import React from 'react'
import { Box, Grid, Button, Typography } from "@mui/material";
import { ToggleBlockStyle } from './style';
import { Label } from '../label'


export const ToggleBlockButton = ({
    options = [],
    spacing = "",
    divider = {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
    },
    onChange = () => false,
    value = "",
    padding = "15px 12px",
    borderRadius = null,
    border = null,
    marginRight = null,
    backgroundColor = null,
    label = null,
    isRequired = false,
    backgroundColorunparent = null,
    isReadonly=false,
    fontSize = "14px",
    toogle_style={},
    toogle_style_unselect={}
}) => {
    const { xs, sm, md, lg } = divider
    return (
        <div>
            {label && <Label label={label} isRequired={isRequired} />}
            <Box sx={ToggleBlockStyle({ backgroundColorunparent })?.parent}>
                <Grid container spacing={spacing}>
                    {
                        options?.map((val, index) => {
                            return (
                                <Grid item xs={xs} sm={sm} md={md} lg={lg} key={index} sx={{ paddingTop: '0px !important', marginLeft: marginRight, marginTop:'16px' }}>
                                    <Button onClick={isReadonly === true ? false : () => onChange(val?.value)} variant={"contained"}
                                        sx={value === val?.value ? ToggleBlockStyle({ padding, borderRadius, border, backgroundColor, fontSize, ...toogle_style })?.selected : ToggleBlockStyle({ padding, borderRadius, border, backgroundColor, fontSize , ...toogle_style_unselect })?.unselected} fullWidth>
                                        <Typography noWrap>{val?.label}</Typography>
                                    </Button>
                                </Grid>
                            )
                        })
                    }

                </Grid>
            </Box>
        </div>
    )
}
