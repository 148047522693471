export const INITIAL_DATA_STATE = {
    status: "submitted",
    details: {
        title: "",
        formNo: "",
    },
    form: [],
}

export const FORM_ERROR_MESSAGE = {
    boolean: "Required!!!. Please select any one option",
    check_box: "Required!!!. Please select atlease one option",
    rating: "Required!!!. Please give a rating",
    text: "Required!!!. Please do not leave the field blank",
}