import React from "react";
import { withTranslation } from 'react-i18next';
import { Announcement } from "./announcement";

class AnnouncementParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <Announcement {...this?.props}/>;
  }
}

export default withTranslation('announcement')(AnnouncementParent);
