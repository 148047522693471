import { Stack, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Bath, Bed, ContractBuilding } from "../../assets"
import { ContractDetailsStyle } from "./style"
import { withTranslation } from 'react-i18next';
import { generateImageUrl, img_size } from "../../utils";


const ContractUnitCard = ({
    img,
    unitId = "",
    sqMeters = "",
    handleDrawerOpen = () => null,
    total_baths = "",
    total_bed_rooms = "",
    furnishing = "",
    street_1 = "",
    street_2 = "",
    unit_category_name = "",
    t = () => false
}) => {
    return (
        <>
            <Box sx={ContractDetailsStyle?.unitCard} onClick={handleDrawerOpen}>
                <Stack direction="row" alignItems="center" sx={ContractDetailsStyle?.stackOne} >
                    <Box sx={ContractDetailsStyle?.unitImg}>
                        <img width="100%" alt="img"
                            src={generateImageUrl(img, img_size.small_square)}>
                        </img>
                        <Box textAlign='center'>
                            {unit_category_name}
                        </Box>
                    </Box>
                    <Box sx={ContractDetailsStyle?.unitAbt}>
                        <Typography noWrap sx={ContractDetailsStyle?.unitID}>{unitId}</Typography>
                        {
                            (!!street_1 || !!street_2) ? 
                            <Typography noWrap sx={ContractDetailsStyle?.unitAd}>
                            {`${street_1}, ${street_2}`}
                        </Typography> : 
                        ""
                        }
                       
                        <Typography noWrap sx={ContractDetailsStyle?.sqMeters}>
                            {`${sqMeters ? sqMeters : 0} Sq Feet`}
                        </Typography>
                    </Box>
                </Stack>
                <Stack direction="row" alignItems="center" sx={ContractDetailsStyle?.stackBotttom}>
                    <Stack direction="row" alignItems="center" justifyContent="center"
                        sx={ContractDetailsStyle?.buildingStack}>
                        <ContractBuilding />
                        <Typography noWrap sx={ContractDetailsStyle?.sqMeters}>{furnishing}</Typography>
                    </Stack >
                    <Stack direction="row" alignItems="center" justifyContent="center"
                        sx={ContractDetailsStyle?.bathStack}>
                        <Bath />
                        <Typography noWrap sx={ContractDetailsStyle?.sqMeters}>
                            {`${total_baths ? total_baths : 0} baths`}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="center"
                        sx={ContractDetailsStyle?.bedStack}>
                        <Bed />
                        <Typography noWrap sx={ContractDetailsStyle?.sqMeters}>
                            {`${total_bed_rooms ? total_bed_rooms : 0} baths`}</Typography>
                    </Stack>
                </Stack>
            </Box>
        </>
    )
}
export default withTranslation('contract')(ContractUnitCard);