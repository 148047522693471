import * as React from "react"

export const NoPlate = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={23.905} height={20} {...props}>
    <g data-name="Group 96506">
      <g data-name="Group 96505" fill="#F15A29">
        <path
          data-name="Path 9000"
          d="M11.983 0h9.865a1.9 1.9 0 0 1 1.894 1.151 1.994 1.994 0 0 1 .155.788q.014 7.995 0 15.989A1.966 1.966 0 0 1 21.818 20H2.088A1.97 1.97 0 0 1 0 17.928V2.066A1.972 1.972 0 0 1 2.118 0h9.865m9.926 2H2v16.008h19.909Z"
        />
        <path
          data-name="Path 9001"
          d="M13.13 8.916a1.059 1.059 0 0 1 .459.956 1.241 1.241 0 0 1-1.155 1.036c-.523.036-1.05.028-1.575.014a.435.435 0 0 1-.275-.2.6.6 0 0 1-.065-.317q-.007-1.347 0-2.694c0-.394.124-.515.518-.517q.648 0 1.3.006a1.032 1.032 0 0 1 .8 1.715m-1.645.471v.679c.356-.017.708-.022 1.057-.059.084-.009.218-.122.22-.191a.469.469 0 0 0-.141-.325.562.562 0 0 0-.329-.1c-.261-.014-.523 0-.807 0m-.012-.808c.27 0 .52.007.77 0 .2-.008.342-.092.352-.315a.3.3 0 0 0-.314-.324c-.264-.022-.531-.006-.808-.006Z"
        />
        <path
          data-name="Path 9002"
          d="M8.082 7.091a.5.5 0 0 1 .475.341c.405.956.811 1.912 1.207 2.873a.337.337 0 0 1-.152.457.4.4 0 0 1-.563-.1 2.414 2.414 0 0 1-.218-.483.241.241 0 0 0-.28-.182q-.482.016-.964 0a.227.227 0 0 0-.263.172 3.409 3.409 0 0 1-.209.488.38.38 0 0 1-.5.217.394.394 0 0 1-.221-.5c.116-.343.26-.677.4-1.013.257-.625.52-1.247.778-1.871a.551.551 0 0 1 .517-.4m.406 2.124-.363-.827-.306.827Z"
        />
        <path
          data-name="Path 9003"
          d="M17.208 15.309c-.364 0-.719-.007-1.072 0a.561.561 0 0 1-.57-.3.54.54 0 0 1 .135-.584c.459-.607.915-1.216 1.375-1.822a.559.559 0 0 1 1 .331v1.564c.331.053.5.194.513.417s-.1.32-.5.426c-.069.319.165.842-.434.838-.57 0-.36-.516-.447-.874m.009-1.635-.636.832h.636Z"
        />
        <path
          data-name="Path 9004"
          d="M10.029 15.361h.809c.093 0 .187 0 .279.008a.352.352 0 0 1 .341.373.313.313 0 0 1-.334.342q-.965.025-1.932 0a.4.4 0 0 1-.383-.452 1.15 1.15 0 0 1 .6-.969c.358-.24.719-.476 1.074-.719a.675.675 0 0 0 .175-.182c.153-.231.059-.525-.208-.538a3.765 3.765 0 0 0-.992.1c-.249.056-.43.041-.518-.15a.391.391 0 0 1 .22-.539 2.277 2.277 0 0 1 1.409-.2 1.125 1.125 0 0 1 .641 1.874 7.911 7.911 0 0 1-1 .854c-.062.052-.135.092-.2.138l.014.057"
        />
        <path
          data-name="Path 9005"
          d="M11.982 5.473H9.596a.519.519 0 0 1-.578-.533.468.468 0 0 1 .438-.5 1.285 1.285 0 0 1 .177-.01h4.645c.4 0 .6.161.609.488.014.351-.185.551-.57.554h-2.335"
        />
        <path
          data-name="Path 9006"
          d="M13.333 16.182c-.3-.064-.615-.107-.912-.2a.408.408 0 0 1-.318-.521.42.42 0 0 1 .578-.247 1.7 1.7 0 0 0 1.144.014c.093-.037.227-.153.22-.22a.427.427 0 0 0-.193-.313 1.926 1.926 0 0 0-.591-.115c-.294-.024-.451-.143-.446-.373s.155-.338.435-.356a1.674 1.674 0 0 0 .521-.078.4.4 0 0 0 .219-.291.358.358 0 0 0-.247-.254 3.884 3.884 0 0 0-.94.092c-.288.07-.435.06-.528-.158-.082-.191 0-.372.24-.484a2.137 2.137 0 0 1 1.676-.119.99.99 0 0 1 .46 1.54.354.354 0 0 0-.052.289c.414 1 .018 1.659-1.057 1.749-.067.006-.135 0-.2 0l-.008.042"
        />
        <path
          data-name="Path 9007"
          d="M16.105 10.879a1.835 1.835 0 0 1-1.8-2.433 2.415 2.415 0 0 1 .753-1.152 1.492 1.492 0 0 1 1.259-.226 3.456 3.456 0 0 1 .856.3.4.4 0 0 1 .236.544c-.079.186-.285.256-.549.15a1.913 1.913 0 0 0-.879-.2 1 1 0 0 0-.987 1.143 1.014 1.014 0 0 0 .985 1.044 2.31 2.31 0 0 0 .928-.208.506.506 0 0 1 .415.067c.083.07.09.255.091.389a.3.3 0 0 1-.136.2 1.993 1.993 0 0 1-1.175.383"
        />
        <path
          data-name="Path 9008"
          d="M7.245 15.34c.153.02.272.025.386.051a.336.336 0 0 1 .285.363.3.3 0 0 1-.294.321c-.531.02-1.065.019-1.6 0a.314.314 0 0 1-.291-.357.329.329 0 0 1 .308-.344c.125-.016.251-.018.4-.028v-1.884c-.076.032-.136.058-.2.082-.252.1-.412.048-.508-.162a.406.406 0 0 1 .172-.581c.194-.1.387-.21.589-.293.378-.157.754-.084.75.462-.005.693 0 1.387 0 2.08v.289"
        />
        <path
          data-name="Path 9009"
          d="M5.828 5.986A.974.974 0 0 1 4.851 5a.986.986 0 0 1 .977-.991 1 1 0 0 1 1 1 .986.986 0 0 1-1 .972"
        />
        <path
          data-name="Path 9010"
          d="M18.128 5.986a.986.986 0 0 1-.995-.974 1 1 0 0 1 1-1 .987.987 0 0 1 .976.992.975.975 0 0 1-.979.984"
        />
      </g>
    </g>
  </svg>
)
