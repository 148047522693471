import AboutApp from "../assets/aboutApp";
import AboutCompany from "../assets/aboutCompany";
import Caller from "../assets/caller";
import ChatIcon from "../assets/chatIcon";
import ContactUsIcon from "../assets/contactUs";
import PrivacyPolicy from "../assets/privacypolicy";
import SupportIcon from "../assets/supportIcon";
import { useNavigate } from "react-router-dom";
import {AppRoutes} from '../router/routes'

export const getSupportAccordian =(t=()=>false)=> [
    {
        accordianTitle:t("Properties"),
        accordianContent:[
            {
                contentTitle:t("What is Price Book?"),
                contentDescription:t("Price Book is collection of following pricing information used in Property Management Solutio...")
            },
            {
                contentTitle:t("What is Price Book?"),
                contentDescription:t("Price Book is collection of following pricing information used in Property Management Solutio..."),
            },
            {
                contentTitle:t("What is Price Book?"),
                contentDescription:t("Price Book is collection of following pricing information used in Property Management Solutio...")
            },
            {
                contentTitle:t("What is Price Book?"),
                contentDescription:t("Price Book is collection of following pricing information used in Property Management Solutio...")
            },

        ]

    },
    {
        accordianTitle:t("Lease and Sales"),
        accordianContent:[
            {
                contentTitle:t("What is Price Book?"),
                contentDescription:t("Price Book is collection of following pricing information used in Property Management Solutio...")
            }

        ]

    },
    {
        accordianTitle:t("Solutions"),
        accordianContent:[
            {
                contentTitle:t("What is Price Book?"),
                contentDescription:t("Price Book is collection of following pricing information used in Property Management Solutio...")
            }

        ]

    },
]


  // card data list
  export const data =(t , handleDrawerOpen , data)=> [
    {
        title: data?.company,
        icons: <AboutCompany />,
        onClick:()=>data?.onClick(),
        type:true

    },
    {
        title: t("About App"),
        icons: <AboutApp />,
        onClick:()=>data?.onClick2(),
        type:true
    },
    {
        title: t("Contact Us"),
        icons: <ContactUsIcon />,
        onClick:handleDrawerOpen,
        type:true,
    },
    {
        title: t("terms & policies"),
        icons: <PrivacyPolicy />,
        onClick:()=>data?.onClick1(),
        type:true
    },
    // {
    //     title: t("Terms of Service"),
    //     icons: <TermsofService />,
    //     onClick:()=>data?.onClick1(),
    //     type:true

    // },
]

// contactUs Drawer data
export const DrawerData = (t)=>{
    const navigate = useNavigate();
    return[
        {
            title: t("Call & Connect"),
            icons: <Caller />,
            subTitle: t("Our representative will attend your query"),
            number: "1800 18000 18000",
            btnText: t("Call"),
            call: true,
        },
        {
            title: t("Get Support"),
            icons: <SupportIcon />,
            subTitle: t("Q&A, blog articles"),
            btnText: t("View"),
            onClick: () => navigate(AppRoutes.getSupport)
    
        },
        {
            title: t("Live Chat"),
            icons: <ChatIcon />,
            subTitle: t("Get solutions instantly"),
            btnText: t("Chat"),
    
        },
    
    ]
}