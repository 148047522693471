import { useStyles } from "./styles";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SurveyListCard from "./components/surveyListCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { LocalStorageKeys, SemiBold, errorRoute } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimension";
import { useLazyGetSurveyListQuery } from "../../redux/services/survey";
import { AppRoutes } from "../../router/routes";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { TopNavBar } from "../../components";

export const SurveyList = ({
    t = () => false,
}) => {
    const [getSurveyListAPI, { error: getSurveyListError }] = useLazyGetSurveyListQuery();
    const { enqueueSnackbar } = useSnackbar();
    const listLimit = 10;
    const company_id = JSON?.parse?.(localStorage?.getItem?.(LocalStorageKeys?.accessType))?.[0]?.company_id ?? undefined;
    const size = useWindowDimensions();
    const classes = useStyles;

    const navigate = useNavigate();

    const [listState, setListState] = useState({ data: [], totalCount: 0 });
    const [listOffset, setListOffset] = useState(0);
    const [isListLoading, setIsListLoading] = useState(true);

    useEffect(() => {
        getSurveyList({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSurveyList = async ({ offset = listOffset, limit = listLimit, search = "", load_type = "initial" }) => {
        if (["initial"]?.includes(load_type)) {
            setIsListLoading(true);
        }

        const payload = {
            offset, limit, search, company_id,
            is_draft: false, is_active: [true],
        }

        const response = await getSurveyListAPI(payload);

        if (response?.data?.type === "success") {
            let temp_list = response?.data?.data?.data?.map?.(_ => {
                return {
                    id: _?.id,
                    title: _?.title,
                    form_no: _?.form_id ?? "",
                    published_date: _?.published_on ? - moment(new Date(_?.published_on)).tz(moment.tz.guess()).format("DD MMM YYYY") : "",
                    status: _?.form_status,
                    all_data: _,
                }
            })
            if (["initial"]?.includes(load_type)) {
                setListState({
                    data: temp_list,
                    totalCount: response?.data?.data?.count ?? 0,
                });
                setIsListLoading(false);
            } else {
                setListState({
                    data: [...listState?.data, ...temp_list],
                    totalCount: response?.data?.data?.count ?? 0,
                });
            }
        } else {
            console.log(getSurveyListError);
            enqueueSnackbar(t("Something went wrong"),
                {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
        }
    };

    const fetchMoreData = () => {
        const offset = listOffset + listLimit;
        setListOffset(offset);
        getSurveyList({ offset, load_type: "load_more" });
    }

    const handleSurveyCard = (data) => {
        navigate(AppRoutes?.surveyForm + "?id=" + data?.id);
    }

    const handleBack = () => {
        navigate?.(-1);
    }

    useEffect(() => {
        if (getSurveyListError?.status === 423) return errorRoute()
    }, [getSurveyListError])

    return (
        <Container style={classes.root} maxWidth="sm">
            <TopNavBar isDirect title={t("Survey List")} isBack={false} route={() => handleBack()} position="absolute" />
            <Box p={"16px"}>
                <Typography sx={classes.total_text}>
                    {`${t("Total Survey")} (${listState?.totalCount})`}
                </Typography>
            </Box>
            <Box >
                {isListLoading
                    ? <Stack direction={"column"} rowGap={"12px"} p={"16px"}>
                        {[1, 2, 3]?.map?.(_ => <SurveyListCard isloading size={size} />)}
                    </Stack>
                    : <InfiniteScroll
                        dataLength={listState?.data?.length ?? 0}
                        next={fetchMoreData}
                        hasMore={listState?.data?.length < listState?.totalCount}
                        loader={<SurveyListCard isloading size={size} />}
                        height={size?.height - 121}>
                        <Stack direction={"column"} rowGap={"12px"} p={"16px"}>
                            {listState?.data?.map?.(_ =>
                                <SurveyListCard t={t} size={size} data={_} handleSurveyCard={handleSurveyCard} />)}
                        </Stack>
                    </InfiniteScroll>}
            </Box>
        </Container>
    )
}