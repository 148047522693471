import { currencyFormatted } from "../../utils";
import { ActionCard_style } from "./style";
import { Box, Typography } from "@mui/material";

export const ActionCard = ({
  image = "",
  duetext = "",
  is_Overdue = false,
  type = "",
  amount = "",
  purpose = "",
  purposecode = "",
  code = "",
  t = () => false
}) => {
  return (
    <>
      <Box sx={ActionCard_style?.cardBody}>
        <Box sx={ActionCard_style?.topTab}>
          <Box sx={ActionCard_style?.imgBg}>{image}</Box>
          {
            is_Overdue && duetext &&
            <Box sx={is_Overdue ? ActionCard_style?.noteBg : ActionCard_style?.noteBg1}>
              {

                is_Overdue ?
                  duetext ? <Typography sx={ActionCard_style?.noteText} variant="span" noWrap>{`Over Due ${duetext} days`}</Typography> : "" :
                  duetext ? <Typography sx={ActionCard_style?.noteText} variant="span" noWrap>{`Opened on  ${duetext} days`}</Typography> : ""
              }
            </Box>
          }

        </Box>
        <Typography sx={ActionCard_style?.complaintType}>{t("Invoices")}</Typography>
        <Typography sx={ActionCard_style?.amtText}>{code} {currencyFormatted(amount)}</Typography>
        <Box sx={ActionCard_style?.bottomtab}>
          <Typography sx={ActionCard_style?.purpose}>{purpose}</Typography>
          <Typography sx={ActionCard_style?.purposeCode} noWrap>
            {purposecode}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
