import * as React from "react"

const PersonIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17.391} height={20} {...props}>
    <path
      data-name="icons8-person (1)"
      d="M8.7 0a5 5 0 0 0-4.747 5.217v.87A5 5 0 0 0 8.7 11.3a5 5 0 0 0 4.743-5.217v-.87A5 5 0 0 0 8.7 0ZM5.611 14.394c-2.32.667-4.509 1.923-5.316 3.077A1.594 1.594 0 0 0 1.445 20h14.5a1.6 1.6 0 0 0 1.15-2.531c-.807-1.155-3-2.409-5.315-3.076a4.41 4.41 0 0 1-6.17 0Z"
      fill="#7c8594"
    />
  </svg>
)

export default PersonIcon
