import { Bold, SemiBold, Regular } from "../../utils";


export const CurrentAgreementCard_Style = {

  unitname: {
    color: 'color?.primary',
    fontSize: "0.875rem",
    fontFamily: Bold,
  },
  agreementNumber: {
    fontFamily: Bold,
    fontSize: "0.75rem",
    color: "primary?.main",
    padding: "2px 6px",
    borderRadius: "4px",
    backgroundColor: 'background?.badge',
    maxWidth: "fit-content",
    width: "100%",
    marginLeft: "10px",
  },
  propertyName: {
    fontFamily: Regular,
    fontSize: "0.75rem",
    color: 'color?.third',
  },
  dot1: {
    backgroundColor: 'border?.primary',
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    margin: "0px 10px",
  },
  card: {
    padding: "16px",
    borderRadius: "4px",
    backgroundColor: "white",
    position: "absolute",
    top: "230px",
    left: 0,
    right: 0,
    width: "90%",
    margin: "0 auto",
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  image: {
    height: "304px",
    width: "100%",
    position: "relative",
    marginBottom: "40px",
  },
  root: {
    backgroundColor: 'background?.third',
    padding: "0px",
    textAlign: "center",
  },
  btn: {
    borderRadius: 'palette.borderRadius',
    boxShadow: "0px 6px 10px #00000014",
    color: "#FFFFFF",
    width: "100%",
    marginTop: "14px",
    backgroundColor: 'primary?.main',
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: 'primary?.main',
    },
  },
  agreementTitle: {
    color: 'secondary?.main',
    fontSize: "0.875rem",
    fontFamily: SemiBold,
  },
  agreementNo: {
    backgroundColor: 'info?.light',
    color: 'info?.primary',
    borderRadius: "4px",
    padding: "2px 6px",
    fontSize: "0.75rem",
    fontFamily: Bold,
    marginLeft: "10px",
  },
  lease: {
    backgroundColor: 'background?.unit_catagory',
    color: "#8A74A6",
    borderRadius: "4px",
    padding: "2px 6px",
    fontSize: "0.75rem",
    fontFamily: Bold,
  },
  agreementBox: {
    padding: "10px 0px",
    borderTop: `1px dashed #00000029`,
    marginTop: "10px",
    textAlign: "left",
  },
  dateTitle: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: 'primary.Tertiary',
    marginBottom: "2px",
    textAlign: "-webkit-auto"
  },
  date: {
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    color: 'color?.primary',
    marginInlineStart: "10px",
  },
  avatarGroup: {
    backgroundColor: 'info?.light',
    padding: "10px",
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
    // maxWidth: "132px",
    marginTop: "16px",
    justifyContent: "left",
    cursor: "pointer",
    width: "fit-content",
  },
  avatarGroups: {
    justifyContent: "left",
    "& .MuiAvatar-root": {
      height: "34px",
      width: "34px",
      fontSize: "0.75rem",
    },
  },
  tenantText: {
    fontFamily: SemiBold,
    fontSize: "0.75rem",
    color: 'primary.Tertiary',
    textAlign: "-webkit-auto"
  }


}