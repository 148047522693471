import { Bold, SemiBold } from "../../utils";

export const KycInformationStyle = {



  root: {
    padding: "16px",
    position: "relative",
    width: '100%',
    height: '100%',
    overflow: "auto",
    margin:"70px 0px 87px",
    backgroundColor: "background.main",
    "&::-webkit-scrollbar": {
      display: "none",
    },

  },
  container: {
    backgroundColor: "background.Teritary",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  profilePictureEditIcon: {
    marginBottom: "32px"

  },
  title: {
    color: "text.text",
    fontSize: "1rem",
    fontFamily: Bold,
    marginBottom: "16px"
  },
  cardBox: {
    backgroundColor: "primary.contrastText",
    padding: "16px",
    borderRadius: "12px",
    boxShadow: "0px 3px 30px #4354721F"
  },
  AddIcon: {
    position: "absolute",
    bottom: "24px",
    right: "16px",
    cursor: "pointer",
    zIndex: "300",
    backgroundColor: "primary.main",
    color: "primary.contrastText",
    "&:hover": {
      backgroundColor: "primary.main",
      color: "primary.contrastText",
    }
  },
  proofTypeText: {
    color: "border.primary",
    fontFamily: SemiBold,
    fontSize: "1rem",

  },
  chooseProofText: {
    color: "text.secondary",
    marginBottom: "16px",
    fontFamily: SemiBold,
    fontSize: "0.875rem"
  },
  arrowIcon: {
    color: "text.main",
    fontSize: "0.875rem"
  },
  cardStack: {
    marginBottom: "18px",
    cursor: "pointer",
  },
  detailedBox: {
    backgroundColor: "primary.contrastText",
    borderRadius: "12px 12px 0px 0px",
    padding: "16px",
  },
  detailedBox2: {
    backgroundColor: "primary.contrastText",
    borderRadius: "0px 0px 12px 12px",
    padding: "16px",
  }






}

