import { Box, Typography, Avatar } from "@mui/material"
import { InspectorProfie_style } from "./style"
import { Checkinout } from "../checkinout"
import { Checkindata } from "../../utils"
export const InspectorProfie = ({
    name = "",
    id = "",
    t = () => false,
    checkin,
    checkout,
    logo
}) => {
    return (
        <Box>
            <Box sx={InspectorProfie_style?.align}>
                <Avatar src={logo} />
                <Box sx={InspectorProfie_style.marginLeftsmall}>
                    <Typography sx={InspectorProfie_style?.inspectorname}>{name}</Typography>
                    <Typography sx={InspectorProfie_style?.inspectorId}>{id}</Typography>
                </Box>
            </Box>

            {Checkindata(t ,checkin,checkout).map((x, index) => {
                return (
                    <Box key={index}>
                        <Checkinout
                            // date={x?.date}
                            purpose={x?.purpose}
                            t={t}
                            image={x?.image}
                            line={x?.line}
                            show={x?.show}
                            checkin={checkin}
                            checkout={checkout}
                        />
                    </Box>
                )
            })}

        </Box>
    )
}