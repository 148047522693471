import React from "react";
import { Request } from './request';
import { withNavBars } from "../../HOCs";
import { withTranslation } from 'react-i18next';

class RequestParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <Request {...this?.props} />;
  }
}
const props = {
  is_dashboard: true,
  is_arrow:false

}

export default withNavBars(withTranslation('request')(RequestParent), props);

