import { Avatar, Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { generateImageUrl, img_size } from '../../utils';
import { AddOnsUnitCardStyles } from "./style"
import { UnitArrowIcon } from '../../assets';
import { UnitArrowIconLeft } from '../../assets/unitArrowIconLeft';

export const AddOnsUnitCard = ({
    title = "",
    subTitle = "",
    value = "",
    image = "",
    tagTitle = "",
    ChangeUnit = false,
    unit = false,
    valueOnclick = () => false,
    changeUnitOnclick = () => false,
    isSelected = '',
    id = '',
    locationItem = '',
    width = ""

}) => {
    const language = localStorage.getItem("i18nextLng")
    return (
        <>
            {unit &&
                <Box sx={AddOnsUnitCardStyles?.root} onClick={() => valueOnclick()}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                        <Stack direction={"row"} alignItems={"center"}>
                            <Avatar sx={AddOnsUnitCardStyles?.avatar} src={generateImageUrl(image, img_size.large_rectangle)} alt='No Image' />
                            <Stack sx={{ marginInlineStart: "8px" }}>
                                <Typography sx={AddOnsUnitCardStyles?.title}>{title}</Typography>
                                <Typography sx={AddOnsUnitCardStyles?.subTitle}>{subTitle}</Typography>
                            </Stack>
                        </Stack>
                        <Typography sx={AddOnsUnitCardStyles?.valueText} >
                            {value}
                        </Typography>
                    </Stack>
                </Box>
            }
            {ChangeUnit &&
                <Box sx={isSelected === id || locationItem === id ? AddOnsUnitCardStyles?.ChangeUnitrootSelected : AddOnsUnitCardStyles?.ChangeUnitroot} onClick={() => changeUnitOnclick()}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                        <Stack direction={"row"} alignItems={"center"}>
                            <Avatar sx={AddOnsUnitCardStyles?.avatar} src={generateImageUrl(image, img_size.large_rectangle)} alt='No Image' />
                            <Stack sx={{ marginInlineStart: "8px" }}>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography sx={{ ...AddOnsUnitCardStyles?.title, ...width }}>{title}</Typography>
                                    <Typography sx={AddOnsUnitCardStyles?.tagTitle}>{tagTitle}</Typography>
                                </Stack>
                                <Typography sx={AddOnsUnitCardStyles?.subTitle} noWrap>{subTitle}</Typography>
                            </Stack>
                        </Stack>
                        <Stack sx={AddOnsUnitCardStyles?.valueTxt}>
                            {language === "ar" ?
                                <UnitArrowIconLeft />
                                :
                                <UnitArrowIcon />
                            }
                        </Stack>
                    </Stack>
                </Box>
            }
        </>

    );
}