import { Box, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './styles';
import surveyIcon from '../assets/surveyIcon.svg';
import calendarIcon from '../assets/calendarIcon.svg';


const SurveyListCard = ({
  t = () => false,
  isloading = false,
  loadingAnimation = "wave",
  size = {},
  data = {},
  handleSurveyCard = () => false,
}) => {
  const classes = useStyles;
  return (
    isloading
      ? (
        <Stack sx={classes.root} direction={"row"} columnGap={"12px"}
          justifyContent={"space-between"} alignItems={"center"}>
          <Skeleton variant='circular' animation={loadingAnimation} height={"48px"} width={"48px"} />
          <Box width={size?.width >= 600 ? "474px" : (size?.width - 126)}>
            <Stack direction={"column"} rowGap={"8px"} justifyContent={"space-between"} alignItems={"start"}>
              <Stack width={"100%"} direction={"row"} columnGap={"4px"} justifyContent={"space-between"} alignItems={"center"}>
                <Skeleton variant='text' animation={loadingAnimation} height={"21px"} width={"50%"} />
                <Skeleton variant='text' animation={loadingAnimation} height={"24px"} width={"20%"} />
              </Stack>
              <Stack direction={"row"} columnGap={"8px"} alignItems={"center"}
                divider={<Skeleton variant='circular' animation={loadingAnimation} height={"6px"} width={"6px"} />}>
                <Skeleton variant='text' animation={loadingAnimation} height={"18px"} width={"50px"} />
                <Stack direction={"row"} columnGap={"4px"} justifyContent={"space-between"} alignItems={"center"}>
                  <Skeleton variant='rounded' animation={loadingAnimation} height={"14px"} width={"14px"} />
                  <Skeleton variant='text' animation={loadingAnimation} height={"18px"} width={"60px"} />
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      )
      : (
        <Stack sx={classes.root} direction={"row"} columnGap={"12px"}
          justifyContent={"space-between"} alignItems={"center"}
          onClick={() => handleSurveyCard(data)}>
          <img style={classes.surveyIcon} src={surveyIcon} alt="" />
          <Box width={size?.width >= 600 ? "474px" : (size?.width - 126)}>
            <Stack direction={"column"} rowGap={"8px"} justifyContent={"space-between"} alignItems={"start"}>
              {(data?.title?.length || data?.status?.length) &&
                <Stack width={"100%"} direction={"row"} columnGap={"4px"} justifyContent={"space-between"} alignItems={"center"}>
                  {data?.title?.length &&
                    <Typography sx={classes.title}>{data?.title}</Typography>}
                  {data?.status?.length &&
                    <Typography sx={data?.status === "Submitted" ? classes.submittedStatus : classes.openStatus}>
                      {t(data?.status)}
                    </Typography>}
                </Stack>}
              {(data?.form_no?.length || data?.published_date?.length) &&
                <Stack direction={"row"} columnGap={"8px"} alignItems={"center"}
                  divider={<Box sx={classes.dot} />}>
                  {data?.form_no?.length &&
                    <Typography sx={classes.form_no}>{data?.form_no}</Typography>}
                  {data?.published_date?.length &&
                    <Stack direction={"row"} columnGap={"4px"} justifyContent={"space-between"} alignItems={"center"}>
                      <img style={classes.calendarIcon} src={calendarIcon} alt="" />
                      <Typography sx={classes.publishedDate}> &#x202a;{data?.published_date}&#x202c;</Typography>
                    </Stack>}
                </Stack>}
            </Stack>
          </Box>
        </Stack>
      )
  )
}

export default SurveyListCard;