import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { LocalStorageKeys } from '../../../utils';


// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: config.api_auth_url,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem(LocalStorageKeys.authToken);
    if (token) {
        headers.set("Authorization", "Bearer " + token);
        headers.set("x-build-code", config?.xbuildCode);
    }
  
    return headers;
  },
});


const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });;


export const clientAPI = createApi({
  reducerPath: "clientAPI",
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Client'],
  endpoints: (builder) => ({
    //    get client details
    getAllClient: builder.query({
      query: (payload) => ({ url: `select_client?client_id=${payload?.client_id}`, method: "GET" }),
      transformResponse: (response, meta, arg) => {
        if (response?.status === 200) {
          localStorage.setItem(LocalStorageKeys.authToken, response?.token)
          return response
      }

      },
    }),
    getAllRole: builder.query({
      query: (payload) => ({ url: `select_role?role_id=${payload?.roleID}`, method: "GET" }),
      transformResponse: (response, meta, arg) => {
        if (response?.status === 200) {
          return response
      }
      return []
      },
    })
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetAllClientQuery,
  useLazyGetAllRoleQuery } = clientAPI
