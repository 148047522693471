
import { SemiBold } from "../../utils";



export const KycDrawerStyles = {
    proofTypeText: {
        color: "border.primary",
        fontFamily: SemiBold,
        fontSize: "1rem",
    
      },
      arrowIcon: {
        color: "text.main",
        fontSize: "0.875rem"
      },
      cardStack: {
        marginBottom: "18px",
        cursor: "pointer",
      },


    }