import React from "react";
import { GeneralRequest } from "./generalRequest";
import { withTranslation } from 'react-i18next';

class GeneralRequestParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <GeneralRequest {...this?.props}/>;
  }
}

export default withTranslation('request')(GeneralRequestParent);
