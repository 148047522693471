import React from "react";
import { makeStyles } from "@mui/styles";
import { TopNavBar, BottomNavbar } from '../components';
import { Container, Box } from "@mui/material";
import useWindowDimensions from "../utils/useWindowDimension";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%"
  },
  content: {
    width: '100%',
    overflow: "hidden",
    height:'100%',
    padding:"0px 0px",
    margin:"52px 0px 73px",
    backgroundColor: theme?.palette?.background?.main,
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  external_site_content: {    
    overflow: "overlay",
    // margin: "auto auto 72px auto",
    backgroundColor: theme?.palette?.background?.main,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    height: ({ size }) => size?.height,
  },
  topNavbar: {
    position: "sticky",
    top: '0',
    zIndex: 999
  },
  sideNavbar: {
    [theme.breakpoints.down('md')]: {
      display: "none"
    }
  },
  screen: {
    position: "relative",
    overflow:"hidden"
  },
  bottomNavbar: {
    position: "fixed",
    bottom: "0px",
    left: "0px",
    right: "0px",
    zIndex: 999,

  },
  topBar: {
    position: "fixed",
    top: "0px",
    left: "0px",
    right: "0px",
    zIndex: 999,
  }

}));


const withNavBars = (Component, params) => (props) => {
  const size = useWindowDimensions();

  const { is_external_site = false } = params;
  const classes = useStyles({ ...props, ...params, is_external_site, size });

  return (
    <div className={classes.root}>

      <Container maxWidth="sm" sx={{ padding: '0px!important' }} id="main_root">
      <Box className={classes.screen}>
        {/* Your nav bars here */}
          {is_external_site
            ? <></>
            : <Container maxWidth="sm" sx={{ padding: '0px!important' }} >
              <div className={classes.topBar}>
                <TopNavBar {...params} />
              </div>
            </Container>
          }

        {/* Content */}
        <div className={is_external_site ? classes.external_site_content : classes.content}>
          <Component {...props}>{props.children}</Component>
        </div>

        {/* bottom navbar */}
        <Box className={classes.bottomNavbar}>
          <BottomNavbar />
        </Box>
        </Box>
      </Container>
    </div>
  );
};

export default withNavBars;
