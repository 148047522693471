import { Fab } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useState, useEffect } from "react";
import { ContactNumber, FilterIcon } from "../../assets";
import Filter from "../../components/filter";
import { DialogDrawer, NewTabsComponent, TopNavBar } from "../../components"
import AddIcon from '@mui/icons-material/Add';
import { Mycontacts } from "./mycontact"
import { PublicContacts } from "./public"
import { Contacts_style, MyContact_style } from "./style"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "../../router/routes"
import { useLazyGetProfessionMasterQuery, useLazyGetContactListingPrivateQuery, useLazyGetContactListingQuery, useLazyGetContactsStatusQuery } from "../../redux/services";
import { errorRoute } from "../../utils";
import useWindowDimensions from "../../useDimension";





export const Contacts = ({ t = () => false }) => {
    const [selectedTab, setSelectedTab] = useState(1);
    const [profession, setProfession] = useState([]);
    const [drawer, setDrawer] = useState(false);
    const [offSet, setOffSet] = useState(0);
    const [GetProfessionMaster , {error}] = useLazyGetProfessionMasterQuery();
    const [GetContactListingPrivate , {error:isError}] = useLazyGetContactListingPrivateQuery();
    const [GetContactListing , {error:isError1}] = useLazyGetContactListingQuery();
    const [GetContactListingStatus , {error:isError2}] = useLazyGetContactsStatusQuery();
    const [selectedFilter, setSelectedFilter] = useState({
        select_Profession: []
    })
    const [datas, setDatas] = useState({
        contactListingPrivate: [],
        contactListing: [],
        bool: true,
    })

    const navigate = useNavigate();
    const open = () => {
        navigate(AppRoutes?.createContacts)
    }
    // filterDataJson
    const filterDataJson = [
        {
            id: "1",
            title: t("Select Profession"),
            key: "select_Profession",
            filterType: "CHECKBOX",
            values: profession,
        }
    ];

    // selectProfessionAPI Call
    const selectProfessionFunc = async () => {
        const professionMasterRes = await GetProfessionMaster({ offset: 0, limit: 10 });
        setProfession([...professionMasterRes?.data?.data])
        setSelectedFilter({
            select_Profession: professionMasterRes?.data?.data?.map(i => i?.value)
        })
        getContactListPrivate({
            offSet: 0, limit: 10, profession_id: professionMasterRes?.data?.data?.map(i => i?.value)
        })
    }

    // drawerOpen
    const filterdraweropen = async () => {
        setDrawer(true);
    };
    // drawerClose
    const filterdrawerclose = () => {
        setDrawer(false);
    };

    // filterApplication
    const applyFilter = async (data) => {

        if (selectedTab === 1) {
            getContactListPrivate({
                offSet: offSet + 10,
                limit: 10,
                profession_id: data?.select_Profession
            })

        } else {
            getContactList({
                offSet: offSet + 10,
                limit: 10,
                profession_id: data?.select_Profession
            })

        }
        setSelectedFilter(data)
        setDrawer(false);

    }
    // getContactListPrivate
    const getContactListPrivate = async (payload, isFilter) => {
        const data = await GetContactListingPrivate(payload)
        setDatas({
            ...datas,
            contactListingPrivate: isFilter ? datas?.contactListingPrivate.concat(data?.data?.data) : data?.data?.data,
            bool: false
        })
    }
    // getContactList
    const getContactList = async (payload, isFilter) => {
        const data = await GetContactListing(payload)
        setDatas({
            ...datas,
            contactListing: isFilter ? datas?.contactListing.concat(data?.data?.data) : data?.data?.data,
            bool: false
        })
    }
    // fetchMoreDataPrivate
    const fetchMoreDataPrivate = () => {
        getContactListPrivate({
            limit: 10,
            offset: offSet + 10,
            profession_id: selectedFilter?.selected_Profession
        }, true)
        setOffSet(offSet + 10);
    }
    // fetchMoreDataPublic
    const fetchMoreDataPublic = () => {
        getContactList({
            limit: 10,
            offset: offSet + 10,
            profession_id: selectedFilter?.selected_Profession

        }, true)
        setOffSet(offSet + 10);
    }

    // handleChangeStatusPrivate
    const handleChange = async (event, item) => {
        let response;
         try{
             response = await GetContactListingStatus({
                is_share: event?.target?.checked,
                contact_id: item?.id
            })
         }
         catch(err){
            console.log(err , "err")
         }
        if (response?.data?.type === "success") {
            if(selectedTab === 2){
                getContactList({
                    limit: 10,
                    offset: offSet,
                    profession_id: selectedFilter?.selected_Profession
    
                }, false)
            }
            else{
                getContactListPrivate({
                    offSet:0, limit: 10, profession_id: selectedFilter?.selected_Profession
                },false)  
            }
            
        }
    };

    useEffect(() => {
        selectProfessionFunc({})

        // eslint-disable-next-line
    }, [])
    const size = useWindowDimensions();


    useEffect(()=>{
        if(error?.status === 423 || isError?.status === 423 || isError1?.status === 423 || isError2?.status === 423) return errorRoute() 
     },[error , isError , isError1,  isError2])


    return (
        <>
            <Container maxWidth={'sm'} sx={{ position: 'relative', padding: '0px !important'}}>
                <Box padding={'103px 0px 0px'}>
                <Box sx={{ position: 'fixed', top: 0, zIndex: 999 , left:0 , right:0}}>
                    <Container maxWidth={'sm'} sx={{ position: 'relative', padding: '0px !important'}}>
                    <TopNavBar title={t("Contacts")}
                        isDirect route={() => navigate(AppRoutes.home)} t={t} position={"absolute"} />
                         <Box  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' , backgroundColor: "#F6F6F6" , padding:"0px 16px"}}>
                            <NewTabsComponent
                                is_notshow={true}
                                //tab1Label={t("Spending")}
                                tab2Label={t("My Contacts")}
                                tab3Label={t("Public Contacts")}
                                selectedTab={selectedTab}
                                onTab1Clicked={() => setSelectedTab(0)}
                                onTab2Clicked={() => {
                                    getContactListPrivate({
                                        offSet: 0, limit: 10, profession_id: selectedFilter?.select_Profession
                                    })
                                    setSelectedTab(1)
                                }}
                                onTab3Clicked={() => {
                                    getContactList({
                                        offSet: 0, limit: 10, profession_id: selectedFilter?.select_Profession
                                    })
                                    setSelectedTab(2)
                                }}
                                tabIcon1={<ContactNumber />}
                                tabIcon2={<ContactNumber />}
                                tabIcon3={<ContactNumber />}
                            />
                            <Box >
                                <FilterIcon style={{ cursor: "pointer" }} onClick={filterdraweropen} />
                            </Box>
                        </Box>
                        </Container>
                       
                </Box>
                   
                    <Box sx={Contacts_style.selectedTab} >
                        <Box >
                        {selectedTab === 1 && <Mycontacts
                            t={t}
                            datas={datas}
                            fetchMoreDataPrivate={fetchMoreDataPrivate}
                            handleChange={handleChange}
                            height={size?.height - 135}
                        />}
                        {selectedTab === 2 && <PublicContacts
                            t={t}
                            datas={datas}
                            fetchMoreDataPublic={fetchMoreDataPublic}
                            height={size?.height - 135}
                        />}
                        </Box>
                    </Box>



                <Box sx={MyContact_style?.AddIcon}>
                    <Container maxWidth="sm" sx={MyContact_style.AddIconContainer}>
                        <Fab sx={{ ...MyContact_style?.AddIconChild }} onClick={open}>
                            <AddIcon />
                        </Fab>
                    </Container>
                </Box>
                </Box>


            </Container>



            {/* filter Drawer */}
            <DialogDrawer
                maxWidth="sm"
                open={drawer}
                header={t("Contact Filter")}
                padding="0px"
                component={
                    <Box>
                        <Filter
                            t={t}
                            filters={{
                                select_Profession: selectedFilter?.select_Profession
                            }}
                            filterDataJson={filterDataJson}
                            onGoBack={filterdrawerclose}
                            selectedList={filterDataJson[0]}
                            onApply={applyFilter}
                            onClose={() => filterdrawerclose()}
                        />

                    </Box>
                }
                onClose={() => filterdrawerclose()}
            />

        </>
    )
}