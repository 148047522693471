import {
  Box,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { FooterComponent } from "..";
import { filterStyle } from "./style";
import { CheckboxesGroup } from "./checkBoxFilter";
import { withTranslation } from 'react-i18next';
import { wrapLabelsInT } from "../../utils";

const filterTypes = {
  CHECKBOX: "CHECKBOX",
  DATE: "DATE",
};

const Filter = ({
  filterDataJson = [],
  onGoBack = () => false,
  selectedList = {},
  filters = {},
  onApply = () => false,
  updateState = () => false,
  filterStartDate = {},
  onClose = () => false,
  allFilter = false,
  setUnitsfil = () => false,
  request = "",
  data = {},
  setSelectedFilter = () => false,
  t = () => false,
  disable = false,
  minDate = false,
  checkedState
}) => {
  const [state, setState] = React.useState({ selectedList, filters });

  const onListSelected = (event, filter) => {
    setState({ ...state, selectedList: filter });
  };

  const onApplyBtnClicked = () => {
    onApply(state.filters);
    setState({ ...state, filters });
  };

  const onFilterChange = (key, values, type) => {
    let filters = state.filters;
    if (type === filterTypes.CHECKBOX) {
      if (values.isChecked) {
        filters[key] = [...filters[key] , values.value];
      } else {
        filters[key] = filters[key]?.filter((x) => x !== values?.value)
        if (key === "agreement") {
          state.filters.units = [];
        }
      }
    }
    setState({ ...state, filters });
  };
  const onFilterAll = (e, array) => {
    var keys = array[0]?.key;
    let clearall = (state.filters[keys] = []);
    setState({ ...state, clearall });
    let filters = state.filters;

    if (e.target.checked) {
      array.forEach((item) => {
        filters[item.key].push(item.value);
      });
    } else {
      array.forEach((item) => {
        filters[item.key].splice(filters[item.key].indexOf(item.value), 1);
      });
    }

    setState({ ...state, filters });
  };

  const resetFilter = () => {
    if (filterDataJson.length) {
      let filters = state.filters;
      filterDataJson.forEach((filter) => {
        if (!filters?.[filter.key]?.length) {
          filters[filter.key] = [];
        }
      });
      setState({ ...state, filters });
    }
  };

  React.useEffect(() => {
    resetFilter();
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={filterStyle.root}>
      <Grid container sx={{ padding: "0px" }}>
        
        

        {/* Body */}
        <Grid item xs={12} container sx={filterStyle.roots}>
          <Grid
            item
            xs={6}
            sx={filterStyle.leftFilters}
          >
            <List component="nav">
              {filterDataJson?.map((filter, key) => {
                return (
                  <ListItemButton
                    key={key}
                    selected={state.selectedList.key === filter.key}
                    onClick={(event) => onListSelected(event, filter)}
                  >
                    <ListItemText >
                      <Stack direction="row" alignItems="center">
                        <Typography sx={filterStyle.filterDataJson}>{filter.title}&nbsp;</Typography>
                        <Typography sx={filterStyle.active}>
                          {data?.startDate &&
                            data?.endDate &&
                            filter.key === "date" ? (
                            <>0</>
                          ) : (
                            <>{state.filters?.[filter.key]?.length ?? 0}</>
                          )}
                        </Typography>
                      </Stack>
                    </ListItemText>
                  </ListItemButton>
                );
              })}
            </List>
          </Grid>

          {state.selectedList.key && (
            <Grid item xs={6}  sx={filterStyle.rightFilters}>
              <>
                {state.selectedList.filterType === filterTypes.CHECKBOX && (
                  <>
                    <CheckboxesGroup
                      selectedValues={

                        state.filters[state?.selectedList?.key]}
                      onSelect={(values) =>
                        onFilterChange(
                          state.selectedList.key,
                          values,
                          filterTypes.CHECKBOX
                        )
                      }
                      onChangeAll={onFilterAll}
                      filters={wrapLabelsInT(state?.selectedList?.values, t)}
                      state={state}
                    />
                  </>
                )}
              </>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Box sx={filterStyle.footer}>
        {/* footer Component */}
        <FooterComponent
          containedonClick={(e) => {
            e.persist();
            onApplyBtnClicked();
          }}
          outlinedonClick={onClose}
          outlinedText={t("Cancel")}
          containedText={t("Apply")}
        />
      </Box>
    </Box>
  );
};
export default withTranslation('common')(Filter)

