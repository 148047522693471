import { Activity_Style } from "./style";
import { Stack, Box, Typography, Grid, Avatar, } from "@mui/material";

export const Activity = (props) => {
  return (
    <>
      <Grid container sx={{
        ...Activity_Style?.roots, borderBottom: props?.hidden ?
          "1px solid #5c86cb2e" : '1px solid #fff'
      }} onClick={props?.route ?? null} spacing={1}>
        <Grid item xs={2} sm={1} display="flex" alignItems={'center'}>
          <Box sx={Activity_Style?.leftSection}>
          {
            props?.image ?
              <Avatar sx={Activity_Style?.Avatar}>
                {props?.image}
              </Avatar>
              : null
          }

          </Box>
       

        </Grid>
        <Grid item xs={9} sm={9}>
          <Stack direction="column" sx={Activity_Style?.rightContent} spacing={"5px"}>
            <Stack direction="row">
              <Box>
                <Typography sx={Activity_Style?.price}>{props?.price}</Typography>
              </Box>
              <Box sx={{ marginInlineStart: "16px" }}>
                <Typography sx={Activity_Style?.activity}>
                  {props?.activity}
                </Typography>
              </Box>
            </Stack>
            <Box
              spacing={1}
              noWrap
            >
              <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                {/* <Box xs={4} sm={2}> */}
                <Typography sx={Activity_Style?.work}>{props?.repairs}</Typography>
                {/* </Box>
                <Grid container>
                  <Grid item xs={6} sm={4}> */}
                {props?.business &&
                  <Stack direction="row" alignItems="center" sx={Activity_Style?.secondStack}>
                    <Box sx={Activity_Style?.dot}></Box>
                    <Typography sx={Activity_Style?.work}>{props?.business}</Typography>
                  </Stack>
                }
                {/* </Grid> */}

                {/* <Grid item xs={6} sm={4}> */}
                <Stack direction="row" alignItems="center" sx={Activity_Style?.secondStack}>
                  <Box sx={Activity_Style?.dot}></Box>
                  <Typography sx={Activity_Style?.work}>{props?.date}</Typography>
                </Stack>
                {/* </Grid> */}
                {/* </Grid> */}
              </Stack>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};