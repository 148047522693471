import * as React from "react"
const ServiceDetails = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 112333" transform="translate(-32 -78)">
      <rect
        width={48}
        height={48}
        fill="#ffebe4"
        data-name="Rectangle 57270"
        rx={8}
        transform="translate(32 78)"
      />
      <path
        fill="#ff926f"
        d="M47.001 90a3.3 3.3 0 0 0-3.3 3.3V108a3.6 3.6 0 0 0 3.6 3.6h6.963c.152-.575.372-1.345.674-2.4h-7.637a1.2 1.2 0 0 1-1.2-1.2V96h18v3.913a3.7 3.7 0 0 1 .787-.248 3.576 3.576 0 0 1 .713-.065 3.912 3.912 0 0 1 .9.108V93.3a3.3 3.3 0 0 0-3.3-3.3Zm1.8 8.4a.9.9 0 1 0 0 1.8h1.2a.9.9 0 1 0 0-1.8Zm4.8 0a.9.9 0 1 0 0 1.8h7.8a.9.9 0 1 0 0-1.8Zm12 2.4a2.822 2.822 0 0 0-.5.046 2.782 2.782 0 0 0-1.465.8l-7.079 7.079a1.8 1.8 0 0 0-.456.771l-.942 3.288a.911.911 0 0 0-.059.355.89.89 0 0 0 .858.86.914.914 0 0 0 .357-.059l3.286-.94a1.8 1.8 0 0 0 .77-.456l7.08-7.079a2.782 2.782 0 0 0 .8-1.465 2.877 2.877 0 0 0 .045-.5 2.7 2.7 0 0 0-2.7-2.7Zm-16.8 1.2a.9.9 0 1 0 0 1.8h1.2a.9.9 0 1 0 0-1.8Zm4.8 0a.9.9 0 0 0 0 1.8h6.185l1.782-1.784a.907.907 0 0 0-.167-.016Zm-4.8 3.6a.9.9 0 1 0 0 1.8h1.2a.9.9 0 1 0 0-1.8Zm4.8 0a.9.9 0 0 0 0 1.8h2.585l1.8-1.8Z"
        data-name="Group 113308"
      />
    </g>
  </svg>
)
export default ServiceDetails
