import { Container, Box } from "@mui/material"
import { InspectionOrders_style } from "./style"
import { DialogDrawer, InspectionCard, SearchBarFilter, TopNavBar } from "../../components"
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from "../../router/routes";
import { useLazyGetUnitEnumQuery, useLazyInspectionTenantQuery } from "../../redux/services";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { InspectSimmer } from "./componentSimmer/inspectSimmer";
//import { use } from "i18next";
import NoData from "../../components/noData";
import InfiniteScroll from "react-infinite-scroll-component";
import { errorRoute } from "../../utils";
import Filter from "../../components/filter";
import useWindowDimensions from "../../useDimension";
export const Inspection = ({ t = () => false }) => {
    const navigate = useNavigate();
    const [inspectionDetail, { error }] = useLazyInspectionTenantQuery()
    const [GetUnitAPI, { error: isError1 }] = useLazyGetUnitEnumQuery();
    const [drawer, setDrawer] = useState({
        addReq: false,
        filterReq: false
      })
    const location = useLocation()
    const [inspects, setInspects] = useState({
        listingDatas: [],
        bool: true,
        count: 0,
        offset: 0
    })
    const [enumValue, setEnumValue] = useState({
        type: [
            {
                label: "Move In",
                value: "move-in",
            },
            {
                label: "Move Out",
                value: "move-out",
            },
            {
                label: "Service",
                value: "service",
            },
            {
                label: "General",
                value: "general",
            },
            {
                label: "Unit Readiness",
                value: "unit_readiness",
            },
            {
                label: "Unit Handover",
                value: "unit_handover",
            },
            {
                label: "Site Visit",
                value: "site_visit",
            },
            {
                label: "Delivery Order",
                value: "delivery-order",
            },
        ],
        unit: []
    });
    const [filterData, setFilterData] = useState({
        type: [],
        units: []
    })
    const inspectionOrder = async (payload , initial) => {
        try {
            const data = await inspectionDetail(payload)
            setInspects({
                ...inspects,
                listingDatas: data?.data?.data?.data,
                count: data?.data?.data?.count,
                bool: false
            })
        }
        catch (err) {
            console.log(err, 'error')
        }
    }
    const handleClick = (data) => {
        navigate(AppRoutes?.inspectiondetails, { state: data })
    }
    const initialFun=async()=>{
        if(location?.state){
            inspectionOrder(
                {
                    units: [location?.state],
                    offset: 0,
                    limit: 10,
                } , true)
        }
        else{
            let unit = await GetUnitAPI()
            setFilterData({
                units: location?.state ? [location?.state] : unit?.data?.data?.map((x) => x?.id),
                type: enumValue?.type?.map((x) => x?.value)

            })
            setEnumValue({
                ...enumValue,
                units: unit?.data?.data?.map(val => {
                    return {
                        label: val?.unit_name,
                        value: val?.id,
                        profileId: val?.property_id
                    }
                })
            })
            const data = await inspectionDetail({
                units: unit?.data?.data?.map((x) => x?.id),
                offset: 0,
                limit: 10,
            })
            setInspects({
                ...inspects,
                listingDatas: data?.data?.data?.data,
                count: data?.data?.data?.count,
                bool: false
            })
        }
    }
    useEffect(() => {
        initialFun()
        // eslint-disable-next-line
    }, [location?.state])

    const fetchMoreData = () => {
        inspectionOrder({
            units: filterData?.units,
            offset: inspects?.offset + 10,
            limit: 10,
        })
        setInspects({
            ...inspects, offset: inspects?.offset + 10
        })
    }

    useEffect(() => {
        if (error?.status === 423 ||isError1?.status === 423 ) return errorRoute()
    }, [error , isError1])

    const filterDataJson = [
        {
          id: "1",
          title: t("Units"),
          key: "units",
          filterType: "CHECKBOX",
          values: enumValue?.units,
        },
        {
          id: "2",
          title: t("Type"),
          key: "type",
          filterType: "CHECKBOX",
          values: enumValue?.type,
        },
      ];
      const filterdraweropen = () => {
        setDrawer({
          ...drawer, filterReq: true
        });
      };
      const filterdrawerclose = () => {
        setDrawer({
          ...drawer, filterReq: false
        });
      };
      const applyFilter = async (data) => {
        setDrawer({ ...drawer, filterReq: false });
  
        inspectionOrder(
            {
                unit_ids: data?.units,
                offset: 0,
                limit: 10,
                type:data?.type
            } )
        setFilterData({
          ...filterData,
          units: data?.units,
          type: data?.type
        })
    
      }
      const size = useWindowDimensions();

    return (
        <Container padding={0} maxWidth="sm" sx={InspectionOrders_style?.container} >
            <Box sx={{
        paddingTop: '3.3rem', // Default style
        '@media screen and (min-width: 600px)': {
          paddingTop: '3.8rem', // Media query style for `md` breakpoint
        },
      }}>
                <Box sx={InspectionOrders_style?.navBar}>
                   <Container maxWidth="sm" sx={{padding:"0px !important"}}>
                   <TopNavBar title={t("Inspection Orders")} isDirect isBack t={t} />
                   </Container>
                </Box>
                <Container maxWidth="sm" sx={InspectionOrders_style?.sx} >
                    
                      
                      <>
                       <SearchBarFilter
                        text={`${t("Inspection")} (${inspects?.count})`}
                        filterIcon
                        handleFilterOpen={filterdraweropen}
                   
                    />
                    <Box height={'12px'} />
                    {
                        inspects?.bool ?
                            <InspectSimmer /> :
                            inspects?.listingDatas?.length > 0 ?
                            <Box sx={InspectionOrders_style?.card}>
                                { // eslint-disable-next-line
                                    inspects?.listingDatas?.length > 0 ?
                                        <InfiniteScroll
                                            dataLength={inspects?.listingDatas?.length ?? 0}
                                            next={fetchMoreData}
                                            hasMore={true}
                                            height={size?.height - 140}
                                        >
                                            <Box sx={InspectionOrders_style?.boxShadow}>
                                                {
                                                    inspects?.listingDatas?.map((x, index) => {
                                                        return (
                                                            // <Box key={index}>
                                                            <InspectionCard
                                                                title={x?.unit_name}
                                                                plotname={x?.property_name}
                                                                status={x?.request_type}
                                                                date={x?.execution ? moment(x?.execution).format(
                                                                    "DD-MMM-YY") : "-"}
                                                                name={t('Appartments')}
                                                                logo={x?.logo ? x?.logo : "/images/inspectionOrder.svg"}
                                                                onClick={() => handleClick(x)}
                                                                hidden={inspects?.listingDatas?.length === index + 1 ? false : true}
                                                            />
                                                            // </Box>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </InfiniteScroll>

                                        :
                                       <Box>
                                       
                                       </Box>
                                }
                            </Box>:   <NoData
                          style={{ display: "flex", alignItems: "center", height: "calc(100vh - 220px)" }} />
                    }
                      </> 
                    
                   

                </Container>
            </Box >

            <DialogDrawer
        maxWidth="sm"
        open={drawer?.filterReq}
        header={t("Filter By")}
        padding="0px"
        component={
          <Box>
            <Filter
              t={t}
              filters={{
                type: filterData?.type,
                units: filterData?.units
              }}
              filterDataJson={filterDataJson}
              onGoBack={filterdrawerclose}
              selectedList={filterDataJson[0]}
              onApply={applyFilter}
              onClose={filterdrawerclose}
            />

          </Box>
        }
        onClose={() => filterdrawerclose()}
      />
        </Container >
    )
}