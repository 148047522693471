import { Box, Button, Container, Divider, Grid, Stack, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { withTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { BOMIcon } from "../../../assets/bomIcon"
// import { AlertProps, NetWorkCallMethods } from "../../../utils"
import { TextBox, TopNavBar } from "../../../components"
import { useLazyCostingApprovalQuery, useLazyGetCostingQuery } from "../../../redux/services"
import { SemiBold } from "../../../utils"
import useWindowDimensions from "../../../utils/useWindowDimension"
import { RequestViewStyle } from "../style"


const BomDetails = ({ t }) => {
    const navigate = useNavigate()
    const size = useWindowDimensions()
    const { enqueueSnackbar } = useSnackbar();
    const { state } = useLocation()
    const [notesData, setNotesData] = React.useState("")
    const [showNote, setShowNote] = React.useState(false)
    const [itemDetails, setItemDetails] = React.useState([])
    const [UpdateCostingApprovalRequest] = useLazyCostingApprovalQuery();
    const [GetCostingData] = useLazyGetCostingQuery();
    //get Costing Item details
    //get Costing Item details
    const getCostingDetails = async () => {
        let response = await GetCostingData({
            "request_id": state?.id,
            "type": state?.request_type,
            "bom_type": "Projected"
        })
        setItemDetails({ list: response?.data?.data?.[0]?.costing?.[0], costing_status: response?.data?.data?.[0]?.costing_status })
    }
    React.useEffect(() => {
        if (state?.request_type === "Maintenance") {
            getCostingDetails()
        }
        //eslint-disable-next-line
    }, [])
    const updateRequest = async (costing_status) => {
        const payload = {
            "request_id": state?.id,
            "type": state?.request_type,
            "comments": notesData,
            "costing_status": costing_status,
            "bom_type": "Projected"
        }
        await UpdateCostingApprovalRequest(payload).then((data) => {
            if (state?.request_type === "Maintenance") {
                getCostingDetails()
            }
            enqueueSnackbar(`Costing Request is ${costing_status} Successfully`, {
                variant: "success",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })

        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : "Something went wrong", {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
                // Request made and server responded

            }
            else {
                enqueueSnackbar("Something went wrong", {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
            }
        })
    }
    const handleChangeNotes = (val) => {
        setNotesData(val)
    }
    return (
        <Container sx={RequestViewStyle.root} maxWidth="sm" style={{ padding: "0px" }}>
            <TopNavBar
                title={t("Projected BOM")}
                isBack={() => navigate(-1)}
                isDirect={true}
            />
            <Box p={2} height={size?.height-55} position={"relative"}>
                <Box sx={RequestViewStyle.requestDetailsBox}>
                    <Box>
                        <Box display="flex" justifyContent="space-between" alignItems={"center"}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <BOMIcon />
                                <Box>
                                    <Typography sx={RequestViewStyle.requestTitle}>{itemDetails?.list?.symbol} {itemDetails?.list?.total_amount_after_tax ?? 0} </Typography>
                                    <Typography sx={RequestViewStyle.requestNo}>{t("Final Amount")}</Typography>
                                </Box>
                            </Stack>
                            <Typography sx={RequestViewStyle.BOMstatusBox} style={{ backgroundColor: itemDetails?.costing_status === "Pending" ? "#78B1FE" : itemDetails?.costing_status === "Approved" ? "#5AC782" : "#FF4B4B" }}>{itemDetails?.costing_status}</Typography>
                        </Box>
                    </Box>
                    <Box mt={1} mb={1}>
                        <Divider />
                    </Box>
                    <Box mt={2} maxHeight={size?.height - 470} overflow={"scroll"}>
                        {itemDetails?.list?.items?.map((val) => {
                            return (
                                <Grid container spacing={2} alignItems="center" mb={1}>
                                    <Grid item xs={6}>
                                        <Typography sx={RequestViewStyle.itemName} noWrap>{val?.inspection_item_name}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography sx={RequestViewStyle.requestNo}>{`${val?.quatity} ${t("QTY")}`}</Typography>
                                    </Grid>
                                    <Grid item xs={3} justifyContent={"end"} display={"flex"}>
                                        <Typography sx={RequestViewStyle.itemName}>{itemDetails?.list?.symbol} {val?.line_amount}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}

                        {itemDetails?.list?.deduction?.length > 0 &&

                            <>
                                <Box mt={1} mb={1}>
                                    <Divider />
                                </Box>
                                <Box>
                                    <Typography sx={RequestViewStyle.BillingAmountTxt}>{t("Deduction")}</Typography>
                                </Box>
                                {itemDetails?.list?.deduction?.map((x) => {
                                    return (
                                        <Grid container spacing={2} alignItems="center" mb={1}>
                                            <Grid item xs={6}>
                                                <Typography sx={RequestViewStyle.itemName} noWrap>{x?.description}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography sx={RequestViewStyle.requestNo}>-</Typography>
                                            </Grid>
                                            <Grid item xs={4} justifyContent={"end"} display={"flex"}>
                                                <Typography sx={RequestViewStyle.itemName}>{" - "} {itemDetails?.list?.symbol} {x?.amount}</Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                                <Box mt={1} mb={1}>
                                    <Divider />
                                </Box>
                            </>
                        }
                    </Box>

                    <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                        <Typography sx={RequestViewStyle.finalamttext}>{t("Final Amount")}</Typography>
                        <Typography sx={RequestViewStyle.finalamttext}>{itemDetails?.list?.symbol} {itemDetails?.list?.total_amount_after_tax ?? 0}</Typography>
                    </Box>

                </Box>
                {itemDetails?.costing_status === "Pending" && showNote ?
                    <Box>
                        <Box mt={2} sx={RequestViewStyle.notesBox} mb={2}>
                            <Box p={2} display={"flex"} justifyContent={"space-between"}>
                                <Typography sx={RequestViewStyle.notesLabel}>{t("Notes")}</Typography>
                                <Box onClick={() => setShowNote(false)}><Typography sx={RequestViewStyle.removeTxt}>{t("Remove")}</Typography></Box>
                            </Box>
                            <Divider />
                            <Box p={1}>
                                <TextBox
                                    label=""
                                    placeholder={t("Type Notes here")}
                                    value={notesData}
                                    onChange={(e) => handleChangeNotes(e?.target?.value)}
                                    border={0}
                                    multiline
                                />
                            </Box>
                        </Box>
                    </Box>
                    : itemDetails?.costing_status === "Pending" &&
                    <Box mt={2} onClick={() => setShowNote(true)} mb={2}>
                        <Typography sx={RequestViewStyle.addNote}>+ {t("Add Note")}</Typography>
                    </Box>
                }
                <Container  sx={RequestViewStyle.root} maxWidth="sm" style={{ padding: "0px" }}>
                {itemDetails?.costing_status === "Pending" &&
                    <Box sx={RequestViewStyle.btnfix}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button variant="contained" fullWidth sx={RequestViewStyle.rejectbtn} onClick={() => updateRequest("Rejected")}>{t("Reject")}</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" fullWidth sx={RequestViewStyle.approvebtn} onClick={() => updateRequest("Approved")}>{t("Approve")}</Button>
                            </Grid>
                        </Grid>
                    </Box>
                }
                </Container>
            </Box>
        </Container>
    )
}
export default withTranslation("request")(BomDetails)