import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const CorrespondenceSlice = createSlice({
    name: 'Correspondence',
    initialState,
    reducers: {
        setCorrespondence(state, { payload }) { state = payload; }
    },
})

export const { setCorrespondence } = CorrespondenceSlice.actions;
export default CorrespondenceSlice.reducer;