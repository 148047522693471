import { viewImageStyle } from './style'
import { Box, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { DialogDrawer, DocumentViewer, Slider, TopNavBar } from '../../components';
import { useLocation } from 'react-router-dom';
import { useLazyGetAssetsQuery, useLazyGetUnitAssetsQuery, useLazyImageMutationQuery, useLazyUpdateUnitAssetsQuery } from '../../redux/services';
import { errorRoute, generateImageUrl, img_size, SemiBold } from '../../utils';
import { useSnackbar } from 'notistack';
import useWindowDimensions from '../../useDimension';
import NoData from '../../components/noData';



export const ViewImage = ({ t = () => false }) => {
    const location = useLocation()
    const [getAssets, { error }] = useLazyGetAssetsQuery();
    const [getUnitAssets, { error: isError }] = useLazyGetUnitAssetsQuery();
    const [uploadAsset, { error: isError1 }] = useLazyUpdateUnitAssetsQuery()
    // const [loading1, setLoading1] = React.useState(null);
    const { enqueueSnackbar , closeSnackbar} = useSnackbar()
    const [state, setState] = useState({})

    const [assetView, setAssetView] = useState({
        assetListing: [],
        bool: true,
        drawer: false,
        selected: 0
    })

    const getUnitAssetFunc = async (payload) => {
        const response = await getUnitAssets(payload)
        setAssetView({
            ...assetView,
            assetCategory: response?.data,
            bool: false
        })


    }
    // assetDrawerOpen
    const assetDrawerOpen = (index) => {
        setAssetView({
            ...assetView,
            drawer: true,
            selected: index

        })
    }
    // assetDrawerClose
    const assetDrawerClose = () => {
        setAssetView({
            ...assetView,
            drawer: false
        })
    }
    const getAssetsAPI = async (initial) => {
        await getAssets({
            search: ""
        }).then((res) => {
            if (initial) {
                getUnitAssetFunc({
                    asset_type: location?.state?.data?.id,
                    id: location?.state?.id
                })
            }
            setState(res?.data?.[0])
        })
            .catch((err) => console.log(err, "errr"))


    }

    // const loadOptions = async (search = "", array, type) => {

    //     setLoading1(type);
    //     let result
    //     switch (type) {
    //         case "categorySelect":
    //             result = await getAssets({
    //                 search
    //             })
    //             setLoading1(null);
    //             return {
    //                 options: [...result?.data],
    //                 hasMore:
    //                     array?.length + result?.data?.length <
    //                     result?.data?.count,
    //             };
    //         default:
    //             return { options: [] };
    //     }
    // };

    const upload = async (payload) => {
        const imageUpload = await uploadAsset(payload)
        if (imageUpload?.isSuccess) {
            enqueueSnackbar(t('Uploaded Successfully'), {
                variant: "success",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })

            setTimeout(()=>{
                closeSnackbar()
            },2000)
        }
        else {
            enqueueSnackbar(t('Some Thing Went Wrong'), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
            setTimeout(()=>{
                closeSnackbar()
            },2000)
        }

    }
    const [imageMutation] = useLazyImageMutationQuery()
    // const handleChange = (e) => {
    //     setState(e)
    //     getUnitAssetFunc({
    //         asset_type: location?.state?.data?.id,
    //         id: location?.state?.id
    //     })
    // }
    // updateState Function
    const assetUpdateState = async (e) => {
        if (e?.target?.files) {
            let files = e.target.files;
            const images = []
            for (let i = 0; i < files.length; i++) {
                const formData = new FormData();
                formData.append("files", e?.target?.files[i]);
                const image = await imageMutation(formData)
                images.push(image?.data)

            }
            await upload({
                unit_id: location?.state?.id,
                assetsData: images?.map((x) => {
                    return {
                        url: x,
                        asset_type: location?.state?.data?.id,
                        priority: 1
                    }
                }),
                deleteAssets: []


            })
            await getUnitAssetFunc({
                asset_type: location?.state?.data?.id,
                id: location?.state?.id
            })

        }

    }
    const size = useWindowDimensions();

    React.useEffect(() => {
        getAssetsAPI(true)

        // eslint-disable-next-line
    }, [location?.state])

    React.useEffect(() => {
        if (error?.status === 423 || isError?.status === 423 || isError1?.status === 423) return errorRoute()
    }, [error, isError, isError1])

    return (
        <Container
            maxWidth="sm"
            sx={viewImageStyle?.containerSm}
        >
            <Box sx={viewImageStyle?.sticky}>
               <Container maxWidth="sm" sx={{ padding: '0px!important' }}>
               <TopNavBar isDirect title={location?.state?.data?.name} isBack />
               </Container>
            </Box>
            <Box sx={viewImageStyle?.root} style={{height:size?.height}}>
                {/* <SelectBox
                    onChange={handleChange}
                    loading={loading1 === "categorySelect"}
                    isPaginate={true}
                    loadOptions={(search, array, handleLoading) =>
                        loadOptions(search, array, "categorySelect")}
                    value={state}
                /> */}
                <Box sx={viewImageStyle?.content}>
                    <Grid container spacing={1}>
                        {
                            assetView?.assetCategory?.length > 0 ? 
                            assetView?.assetCategory?.map((e, i) => {
                                return (
                                    e?.asset_type === 4 ?
                                        <Grid item xs={12} onClick={() => assetDrawerOpen(i)}>
                                            <Box sx={viewImageStyle?.imgSec}>
                                                <DocumentViewer url={e?.url} />
                                            </Box>
                                        </Grid>
                                        :
    
                                        <>
                                            {e?.asset_type === 3 ?
                                                <video
                                                    style={{
                                                        objectFit: "cover",
                                                        height: "150px",
                                                        width: "100%",
                                                        borderRadius: "12px",
                                                    }}
                                                    onClick={() => assetDrawerOpen(i)}
                                                    controls
                                                >
                                                    <source src={e?.url} type="video/mp4" />
                                                </video>
    
                                                :
    
                                                // <Grid container>
                                                <Grid item xs={4} onClick={() => assetDrawerOpen(i)}>
                                                    <Box sx={viewImageStyle?.imgBox} >
                                                        <img width="100%" height="100%" style={{ objectFit: 'cover' }} alt='' src={generateImageUrl(e?.url, img_size.small_square)}></img>
                                                    </Box>
                                                </Grid>
    
                                                //  </Grid>
                                            }
    
                                        </>
    
    
    
    
                                )
                            })
                            :
                            <NoData style={{ display: "flex", alignItems: "center" }} />

                        }
                        
                    </Grid>

                </Box>
            </Box>
            {state?.value?.length === 0 ? "" :
                <Box padding={'16px'} sx={viewImageStyle?.stickyBottom} >
                                   <Container maxWidth="sm" sx={{ padding: '0px!important' }}>

                    <Grid item xs={12}>
                        <label for="fileInput" class="custom-file-upload">
                            <span style={{ fontFamily: SemiBold }}>{t("Upload Assets")}</span>
                            <input
                                type="file"
                                name="myImage"
                                onChange={(e) => {
                                    assetUpdateState(e);
                                }}
                                onClick={(event) => {
                                    event.target.value = null
                                }}
                                style={{ display: "none" }}
                                id="fileInput"
                                multiple
                            />
                        </label>


                     
                    </Grid>

                    </Container>


                </Box>
            }
            {/* assetUpload */}
            <DialogDrawer
                maxWidth="sm"
                open={assetView?.drawer}
                isnotTitle
                padding={"16px 16px 0px"}
                height_style={
                    { height: "auto !important", padding: "16px !important", minHeight: "100px" }
                }
                component={
                    <>
                        <Slider
                            title={t("Images")}
                            height={"100% !important"}
                            borderRadius={"4px"}
                            imageHeigth={"300px !important"}
                            assets={assetView?.assetCategory?.map((x) => {
                                return {
                                    meta_data: {
                                        file_type: "image",
                                    },
                                    url: x?.url,
                                }
                            })}
                            onClose={() => assetDrawerClose()}
                            selected={assetView?.selected ?? 0}
                        />
                    </>
                }

                onClose={() => assetDrawerClose()}
            />

        </Container>
    )
}

