import { Regular, Bold, SemiBold } from "../../utils";

export const ProfileCard_style = {
  avatarGroup: {
    backgroundColor: 'info?.light',
    padding: "10px",
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
    // maxWidth: "132px",
    marginTop: "16px",
    justifyContent: "left",
    cursor: "pointer",
    width: "fit-content",
  },
  avatarGroups: {
    justifyContent: "left",
    "& .MuiAvatar-root": {
      height: "34px",
      width: "34px",
      fontSize: "0.75rem",
    },
  },
  contactName: {
    fontFamily: SemiBold,
    fontSize: "0.875rem",
    color: 'text?.Teritary',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

  },
  contactName1: {
    fontFamily: Regular,
    fontSize: "0.75rem",
    color: 'text?.Teritary',
    marginBottom: '0px',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "-webkit-auto",
    direction: "ltr"
  },
  contactEmail: {
    fontFamily: Regular,
    fontSize: "0.75rem",
    color: 'text?.Teritary',
    marginBottom: '0px',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "-webkit-auto",
  },
  avatar: {
    backgroundColor: 'backgroundcard.teritary',
  },
  flexBox: {
    width: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  assetsBox: {
    minHeight: "123px",
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    padding: "10px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  arrowIcon: {
    fontSize: "0.75rem",
  },
  marginTop: {
    marginTop: "260px",
  },
  parentBox: {
    padding: "10px",
    backgroundColor: "white",
  },
  catagoryTitle: {
    fontSize: "1rem",
    fontFamily: Bold,
  },
  dot1: {
    backgroundColor: 'backgroundcard.light',
    padding: '3px',
    borderRadius: '50%',
    margin: '5px',

  },
  main: {
    display: "flex",
    alignItems: "center",

  },
  mailBox: {
    marginTop: "8px"
  },
  avatarimg: {
    height: "48px",
    width: "48px"
  }
}