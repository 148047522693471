import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setClient(state, { payload }) { state = payload; }
    },
})

export const { setClient } = clientSlice.actions;
export default clientSlice.reducer;