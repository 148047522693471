import React from "react";
import { GetSupport } from "./getSupport";
import { withTranslation } from 'react-i18next';

class GetSupportParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <GetSupport {...this?.props}/>;
  }
}

export default withTranslation('getSupport')(GetSupportParent)
