import { Buttons_Style } from "./style";
import { Typography , Button} from "@mui/material"
import  animation  from '../../assets/buttonlader.json'
import Lottie from "react-lottie";

export const Buttons = ({ btnName = "View Assets", button_Style = {}, children,
  variant = 'contained',
  disabled = false,
  startIcon = '',
  endIcon = '',
  component = '',
  btnLoading = false,
  size = 'medium',
  fullWidth = true,
  onClick = () => { },
  className = '',
  buttonStyle={},
  ...rest }) => {

    const defaultOptions = {
      loop: true,
      autoplay: true,
      // here is where we will declare lottie animation
      // "animation" is what we imported before animationData: animation,
      animationData: animation,
      rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
      },
  };

  return (
    <Button
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      size={size}
      component={component}
      sx={{ ...Buttons_Style.rootSx, ...button_Style }}
      fullWidth={fullWidth}
      onClick={onClick}
      className={`${className}`}
      {...rest}
    >
      {
        btnLoading ? <Lottie options={defaultOptions} height={30} width={90} /> :  <Typography sx={{ ...Buttons_Style.title, ...buttonStyle }}>{btnName}</Typography>
      }
     
    </Button>
  );
};
