import React from "react";
import { KycInformation } from "./kycInformation";
import { withTranslation } from 'react-i18next';
  
class KycInformationParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
    
        }
      }
  render() {
    return <KycInformation {...this?.props}/> ;
  }
}

export default withTranslation('kyc')(KycInformationParent);
