import * as React from "react";

export const BuildingIcon = (props) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={36} height={32} {...props}>
      <defs>
        <clipPath id="a">
          <path
            data-name="Rectangle 55345"
            transform="translate(0 .5)"
            fill={props?.clipPathfill ?? "#2ca47e"}
            stroke={props?.clipPathstroke ?? "#707070"}
            d="M0 0h20v16H0z"
          />
        </clipPath>
      </defs>
      <g data-name="Group 103801">
        <g data-name="Group 103748">
          <path
            data-name="Rectangle 54006"
            d={props?.d ?? "M0 0h36v24a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V0Z"}
            fill={props?.fill ?? "#ebeffc"}
          />
          <g
            data-name="Mask Group 102266"
            transform="translate(8 7.5)"
            clipPath="url(#a)"
          >
            <path
              data-name="icons8-company (2)"
              d="M3.799 2.369a.487.487 0 1 0 0 .973h.162v6.5a1.137 1.137 0 0 0-.973 1.118v3.242a.486.486 0 0 0 .486.486h3.08a.324.324 0 0 0 .324-.319v-2.274a.324.324 0 0 1 .324-.324h.973a.324.324 0 0 1 .324.324v2.274a.324.324 0 0 0 .324.324h3.08a.486.486 0 0 0 .486-.486V10.96a1.137 1.137 0 0 0-.973-1.123v-6.5h.162a.487.487 0 1 0 0-.973ZM6.23 4.638h.648a.324.324 0 0 1 .324.324v.648a.324.324 0 0 1-.324.324H6.23a.324.324 0 0 1-.324-.324v-.647a.324.324 0 0 1 .324-.325Zm2.269 0h.649a.324.324 0 0 1 .324.324v.648a.324.324 0 0 1-.324.324h-.649a.324.324 0 0 1-.324-.324v-.647a.324.324 0 0 1 .324-.325Zm3.566 0v4.731a1.794 1.794 0 0 1 .973 1.587v3.242a1.123 1.123 0 0 1-.114.486h3.842a.486.486 0 0 0 .486-.486V6.421a1.786 1.786 0 0 0-1.783-1.783ZM6.23 6.908h.648a.324.324 0 0 1 .324.324v.648a.324.324 0 0 1-.324.324H6.23a.324.324 0 0 1-.324-.324v-.648a.324.324 0 0 1 .324-.324Zm2.269 0h.649a.324.324 0 0 1 .324.324v.648a.324.324 0 0 1-.324.324h-.649a.324.324 0 0 1-.324-.324v-.648a.324.324 0 0 1 .324-.324Zm5.836 0h.653a.324.324 0 0 1 .324.324v.648a.324.324 0 0 1-.324.324h-.648a.324.324 0 0 1-.324-.324v-.648a.324.324 0 0 1 .319-.324ZM6.23 9.177h.648a.324.324 0 0 1 .324.324v.648a.324.324 0 0 1-.324.324H6.23a.324.324 0 0 1-.324-.324v-.648a.324.324 0 0 1 .324-.324Zm2.269 0h.649a.324.324 0 0 1 .324.324v.648a.324.324 0 0 1-.324.324h-.649a.324.324 0 0 1-.324-.324v-.648a.324.324 0 0 1 .324-.324Zm5.836 0h.653a.324.324 0 0 1 .324.324v.648a.324.324 0 0 1-.324.32h-.648a.324.324 0 0 1-.324-.324v-.648a.324.324 0 0 1 .319-.32Zm0 2.269h.653a.324.324 0 0 1 .324.324v.648a.324.324 0 0 1-.324.324h-.648a.324.324 0 0 1-.324-.324v-.649a.324.324 0 0 1 .319-.322Zm-9.726.323h.648a.324.324 0 0 1 .324.324v.648a.324.324 0 0 1-.324.324h-.648a.324.324 0 0 1-.324-.324v-.648a.324.324 0 0 1 .324-.324Zm5.511 0h.648a.324.324 0 0 1 .324.324v.648a.324.324 0 0 1-.324.324h-.648a.324.324 0 0 1-.324-.324v-.648a.324.324 0 0 1 .324-.324Z"
              fill={props?.fill1 ?? "#6c68ea"}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
