import { Bold } from "../../utils";


export const viewImageStyle = {
    root: {
        padding: "75px 16px",
        overflow: "auto",
        backgroundColor: 'background.Teritary',
        height: '100%',
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    containerSm: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        position: "relative",
        overflow: "hidden"
    },
    sticky: {
        position: 'fixed',
        top: 0,
        zIndex: 999,
        left:0,
        right:0
    },
    stickyBottom: {
        position: 'fixed',
        bottom: 0,
        zIndex: 999,
        left:0,
        right:0
    },
    imgBox: {
        width:"100%",
        height: "106px",
        "& img": {
            borderRadius: "12px",

        }
    },
    imgSec: {
        width: '100%',
        height: '473px',
        borderRadius: '4px',

    },
    General: {
        color: "border.primary",
        margin: "16px 0 10px",
        fontFamily: Bold,
        fontSize: "0.875rem"
    },
    btn: {
        borderRadius: "4px",
        boxShadow: "0px 6px 10px #00000014",
        padding: "16px",
        "&:hover": {
            color: "#fff",
            backgroundColor: "primary.main",
        },
    },
    btnContainer: {
        padding: "0 !important",
        position: "fixed",
        width: "100%",
        bottom: "0px",
        left: "0",
        right: "0"
    },
    content: {
        position: "relative",
        marginTop:"16px"
    }

}