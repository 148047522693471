import { ServiceCard_Style } from "./style"
import { Typography, Box } from "@mui/material";


export const ServiceCard = ({ image = "", homeimage = "", title = "", t = () => false, onClick = () => false }) => {
  return (
    <>
      <Box sx={ServiceCard_Style?.rootSx} onClick={() => onClick()}>
        <Box sx={ServiceCard_Style?.bottomtab}>
          <Typography sx={ServiceCard_Style?.purpose} noWrap>{t("Services")}</Typography>
          <Box sx={ServiceCard_Style?.dot}></Box>
          <Typography sx={ServiceCard_Style?.purpose} noWrap>{t("Repair")}</Typography>
          <Box sx={ServiceCard_Style?.dot}></Box>
          <Typography sx={ServiceCard_Style?.purpose} noWrap>{t("Cleaning")}</Typography>
        </Box>
        <Typography sx={ServiceCard_Style?.title}>
          {title}
        </Typography>
        <Box sx={ServiceCard_Style?.imgSection}>
          <Box sx={ServiceCard_Style?.img}>{image}</Box>
          <Box>{homeimage}</Box>
        </Box>
      </Box>

    </>
  );
}