import { Box, Grid, Stack, Typography } from "@mui/material"
import { Communication, Maintenance } from "../../assets"
import { ReqTypeCardStyle } from "./style"


export const ReqTypeCard = ({ t = () => false, reqdata }) => {
    return (
        <Stack direction="row" alignItems="center" sx={ReqTypeCardStyle?.main}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box>
                        <Grid container>
                            <Grid item xs={4}>
                                <Box sx={ReqTypeCardStyle?.svgItem}>
                                    <Communication />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <Box sx={ReqTypeCardStyle?.svgDetails}>
                                    <Typography noWrap>{reqdata?.reqCount?.generalReq}</Typography>
                                    <Typography noWrap sx={ReqTypeCardStyle.subtext}>{t("General")}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box>
                        <Grid container>
                            <Grid item xs={4}>
                                <Box sx={ReqTypeCardStyle?.svgItem1}>
                                    <Maintenance />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <Box sx={ReqTypeCardStyle?.svgDetails}>
                                    <Typography noWrap>{reqdata?.reqCount?.MaintanenceReq}</Typography>
                                    <Typography noWrap sx={ReqTypeCardStyle.subtext}>{t("Maintenance")}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Stack>
    )
}