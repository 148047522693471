import { Box ,Typography } from "@mui/material"
import { UnitCard_style } from "./style"

export const UnitCard = ({
    title = "",
    status = "",
    address ="",
    logo=""
}) => {
    return(

            <Box sx={UnitCard_style?.sxMain}>
            <Box sx={UnitCard_style.photoSec} style={{background:`url(${logo})`}}></Box>
                <Box sx={UnitCard_style?.secTwo}>
                    <Box sx={UnitCard_style?.sx}>
                        <Typography sx={UnitCard_style?.title}>{title}</Typography>
                        <Typography sx={UnitCard_style?.status}>{status}</Typography>
                    </Box>
                    <Typography sx={UnitCard_style?.propAd}>{address}</Typography>
                </Box>
            </Box>
            
    )
}