import * as React from "react"

 export const ParkingHome = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 50308"
          transform="translate(46 112)"
          fill="#4e5a6b"
          stroke="#707070"
          d="M0 0h15v15H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 6342"
      transform="translate(-46 -112)"
      clipPath="url(#a)"
    >
      <path
        data-name="Path 9116"
        d="M49.75 121.375h7.5v2.5h-7.5Z"
        fill="#ced3dd"
      />
      <path
        d="M53.498 112a2.069 2.069 0 0 0-1.28.443l-4.7 3.7a2.964 2.964 0 0 0-1.13 2.327v7.146a1.392 1.392 0 0 0 1.383 1.383h11.457a1.392 1.392 0 0 0 1.383-1.383v-7.144a2.964 2.964 0 0 0-1.13-2.327l-4.7-3.7a2.069 2.069 0 0 0-1.283-.445Zm0 1.182a.886.886 0 0 1 .549.192l4.7 3.7a1.775 1.775 0 0 1 .677 1.4v7.146a.189.189 0 0 1-.2.2h-1.773a.4.4 0 0 0 .4-.4v-3.357a1.756 1.756 0 0 0-.4-1.107q0-.022-.009-.044l-.379-1.623a1.782 1.782 0 0 0-1.731-1.373h-3.656a1.783 1.783 0 0 0-1.731 1.373l-.379 1.623q0 .021-.008.042a1.757 1.757 0 0 0-.4 1.109v3.36a.4.4 0 0 0 .4.4h-1.785a.189.189 0 0 1-.2-.2v-7.151a1.775 1.775 0 0 1 .677-1.4l4.7-3.7a.886.886 0 0 1 .548-.19Zm-1.822 5.918h3.649a.586.586 0 0 1 .576.458l.17.728h-5.142l.17-.728a.587.587 0 0 1 .577-.458Zm-.743 2.37h5.135a.584.584 0 0 1 .593.593v1.778H50.34v-1.778a.584.584 0 0 1 .593-.593Zm.79.79a.593.593 0 1 0 .593.593.593.593 0 0 0-.593-.593Zm3.555 0a.593.593 0 1 0 .593.593.593.593 0 0 0-.593-.593Zm-4.543 2.763h5.53v.4a.4.4 0 0 0 .4.4H50.34a.4.4 0 0 0 .4-.4Z"
        fill="#4e5a6b"
      />
    </g>
  </svg>
)

