
import { Box, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import { NoData, TopNavBar, UtilityCard } from "../../components";
import { AppRoutes } from "../../router/routes";
import { UtilitiesStyle } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGetUtilitiesListingQuery } from "../../redux/services";
import InfiniteScroll from "react-infinite-scroll-component";
import { UtilitiesCardSimmer } from "./component/utilitiesSimmer";
import useWindowDimensions from "../../useDimension";


export const Utilities = ({ t = () => false }) => {
    const navigate = useNavigate();
    let location = useLocation();
    const [GetUtilities] = useLazyGetUtilitiesListingQuery();
    const [offSet, setOffSet] = useState(0);
    const [datas, setDatas] = useState({
        utilitiesListing: [],
        bool: true
    })
    // get utilities List
    const getutilitiesList = async (payload, isFilter) => {
        const data = await GetUtilities(payload)
        setDatas({
            ...datas,
            utilitiesListing: isFilter ? data?.utilitiesListing.concat(data?.data?.data) : data?.data?.data,
            bool: false
        })
    }
    // offset data
    const fetchMoreData = () => {
        getutilitiesList({
            offset: offSet + 10,
            limit: 10,
        }, true)
        setOffSet(offSet + 10);
    }

    useEffect(() => {
        if (location?.state) {
            console.log(location?.state, 'location?.state')
            getutilitiesList({ unit_id: location?.state, limit: 10, offset: 0 })
        }
        // eslint-disable-next-line
    }, [])

    const size = useWindowDimensions();


    return (
        <Box sx={UtilitiesStyle.parentBox}>
            <Container
                sx={{padding:"0px !important"}}
                maxWidth="sm"
            >
                <Box sx={{
                    paddingTop: '58px', // Default style
                    '@media screen and (min-width: 600px)': {
                        paddingTop: '66px', // Media query style for `md` breakpoint
                    },
                }}>
                    <Box sx={UtilitiesStyle?.Topnav}>
                        <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
                            <TopNavBar title={t("Utilities")} isDirect isBack t={t} position={'absolute'} />
                        </Container>
                    </Box>
                    <Box sx={UtilitiesStyle.root}>

                        {datas?.bool ?
                            <Box px={2}>
                                <UtilitiesCardSimmer />
                            </Box> : datas?.utilitiesListing?.length > 0 ?
                                <Box >
                                    <InfiniteScroll
                                        dataLength={datas?.utilitiesListing?.length ?? 0}
                                        next={fetchMoreData}
                                        hasMore={true} 
                                        height={size?.height - 90}>
                                        {datas?.utilitiesListing?.map((item, index) => {
                                            return (
                                                <Box key={index} sx={UtilitiesStyle?.utilityListBox}>
                                                    <UtilityCard
                                                        // hidden={(utilitiesBox?.length - 1) === index ? true : false}
                                                        hidden={datas?.utilitiesListing?.length === index + 1 ? false : true}
                                                        title={item?.utility_name}
                                                        subTitle={`${item?.readings} ${item?.meter_serial_no}`}
                                                        status={item?.status}
                                                        router={() =>
                                                            navigate(AppRoutes.utilitiesList, { state: { unit_utlities_id: item?.utility_id, unit_id: location?.state } })}
                                                        is_card_show={false}
                                                    />
                                                </Box>
                                            )

                                        })}
                                    </InfiniteScroll>
                                </Box>
                                :
                                <NoData style={{
                                    display: "flex", alignItems: "center",
                                    height: "calc(100vh - 220px)", backgroundColor: "#F6F6F6"
                                }} />
                        }
                    </Box>
                </Box>



            </Container>
        </Box>


    );
};
