import * as React from "react"

export const BedDrawer = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 96765">
      <g data-name="Group 96764" transform="translate(.259 .259)">
        <rect
          data-name="Rectangle 53043"
          width={17}
          height={3}
          rx={1}
          transform="translate(3.741 13.742)"
          fill="#ced3dd"
        />
        <path
          data-name="icons8-empty-bed (1)"
          d="M3.008 4.696a.671.671 0 0 0-.66.681v12.971a.671.671 0 1 0 1.342 0v-.671h16.1v.671a.671.671 0 1 0 1.342 0V14.77a1.576 1.576 0 0 0-1.565-1.565H9.504v-.447a2.694 2.694 0 0 0-2.684-2.684H5.032a2.6 2.6 0 0 0-1.342.415V5.377a.671.671 0 0 0-.681-.681Zm2.024 6.722h1.789a1.332 1.332 0 0 1 1.342 1.342v.447H3.69v-.447a1.332 1.332 0 0 1 1.342-1.342ZM3.69 14.546h5.033a.671.671 0 0 0 .218 0h10.627a.214.214 0 0 1 .224.224v1.565H3.69Z"
          fill="#4e5a6b"
          stroke="#4e5a6b"
          strokeWidth={0.25}
        />
      </g>
    </g>
  </svg>
)

export default BedDrawer
