import { Bold, Regular } from "../../utils";
import useTheme from "@mui/material/styles/useTheme";
export const PropertyCard_Style = (logo) => {
  const theme = useTheme();
  return {
    sx: {
      height: "246px",
      backgroundColor: theme?.palette?.primary?.contrastText,
      boxShadow: " 0px 0px 20px #0000001A",
      border: " 1px solid #E4E8EE",
      borderRadius: "12px",
      opacity: 1,
      padding: "16px",
      cursor: 'pointer',
    },
    image: {
      height: "162px",
      background: `transparent url(${logo}) 0%`,
      opacity: 1,
      borderRadius: "12px",
      paddingTop: "13px",
      backgroundSize: "cover",
      width: "100%",
      backgroundRepeat: "no-repeat"
    },
    tag: {
      width: "90px",
      height: "23px",
      backgroundColor: theme?.palette?.primary?.main,
      borderRadius: " 0px 12px 12px 0px",
      opacity: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    arabicTag: {
      width: "90px",
      height: "23px",
      backgroundColor: theme?.palette?.primary?.main,
      borderRadius: " 12px 0px 0px 12px",
      opacity: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    tagTitle: {
      textAlign: "left",
      fontFamily: Bold,
      fontSize: "0.875rem",
      color: theme?.palette?.primary?.contrastText,
      padding: "5px",
    },
    title: {
      textAlign: "left",
      fontFamily: Bold,
      fontSize: "0.875rem",
    },
    idBg: {
      width: "110px",
      height: "20px",
      backgroundColor: theme?.palette?.backgroundcard?.dark,
      borderRadius: "2px",
      opacity: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginInlineStart: "6px",
      padding: "2px"
    },
    id: {
      textAlign: "left",
      fontFamily: Bold,
      fontSize: "0.75rem",
      color: theme?.palette?.primary?.main,
    },
    plotName: {
      textAlign: "left",
      fontFamily: Regular,
      fontSize: "0.75rem",
      color: theme?.palette?.backgroundcard?.text,
      marginInlineStart: '5px'
    },
    truncateText: {
      maxWidth: {
        sm: "auto",
        xs: "50%"
      }
    },
    dot: {
      padding: "3px",
      //backgroundColor: theme?.palette?.background?.light,
      borderRadius: "50%",
      margin: '5px'
    },
    address: {
      textAlign: "left",
      fontFamily: Regular,
      fontSize: "0.75rem",
      color: theme?.palette?.backgroundcard?.text,
    },
    topLine: {
      display: "flex",
      marginTop: "8px",
    },
    bottomLine: {
      display: "flex",
      marginTop: "3px",
      alignItems: "center",
    },
  };
};
