import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { ProfileViewStyle } from './style'



export const ContactUs = ({
    icon = "",
    title = "",
    subTitle = "",
    btnText = "",
    onClick = () => false,
    number = "",
    call = false
}) => {


    return (


        <Box sx={ProfileViewStyle?.drawerCompBox}>
            {call && call ?
                // with call
                <Stack>
                    <Stack direction={"row"} alignItems={"center"}>
                        {icon}
                        <Stack sx={{ marginInlineStart: "16px" }}>
                            <Typography sx={ProfileViewStyle?.drawerCompTitle}>{title}</Typography>
                            <Typography sx={ProfileViewStyle?.drawerCompsubTitle}>{subTitle}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} marginTop={"18px"}>
                        <Typography sx={ProfileViewStyle?.numberText}>{number}</Typography>
                        <Button sx={ProfileViewStyle?.containedBtn} variant="contained" onClick={onClick ? onClick : false}>{btnText}</Button>
                    </Stack>
                </Stack>
                :
                // without call
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"}>
                        {icon}
                        <Stack sx={{ marginInlineStart: "16px" }}>
                            <Typography sx={ProfileViewStyle?.drawerCompTitle}>{title}</Typography>
                            <Typography sx={ProfileViewStyle?.drawerCompsubTitle}>{subTitle}</Typography>
                        </Stack>

                    </Stack>
                    <Button sx={ProfileViewStyle?.containedBtn} variant="contained" onClick={onClick ? onClick : false}>{btnText}</Button>

                </Stack>
            }

        </Box>

    );
};
