import { useStyles } from "./styles";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import surveyIcon from './assets/surveyIcon.svg';
import { FORM_ERROR_MESSAGE, INITIAL_DATA_STATE } from "./utils/formUtils";
import { Form } from "./components/form";
import { useSnackbar } from "notistack";
import { useLazyGetSurveyDetailsQuery, useLazyUpsertSurveyValuesQuery } from "../../redux/services/survey";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../utils/useWindowDimension";
import { SemiBold, errorRoute } from "../../utils";
import { TopNavBar } from "../../components";

export const SurveyForm = ({
    t = () => false,
}) => {
    const [getSurveyDetailsAPI, { error: getSurveyDetailsError }] = useLazyGetSurveyDetailsQuery();
    const [upsertSurveyValuesAPI, { error: upsertSurveyValuesError }] = useLazyUpsertSurveyValuesQuery();
    const { enqueueSnackbar } = useSnackbar();
    const search = useLocation().search;
    const navigate = useNavigate();
    const surveyFormId = new URLSearchParams(search).get("id");
    const size = useWindowDimensions();
    const classes = useStyles;

    const [isLoading, setIsLoading] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [data, setData] = useState(INITIAL_DATA_STATE);
    const [formState, setFormState] = useState([]);

    useEffect(() => {
        getSurveyFormData({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSurveyFormData = async () => {
        const payload = { id: surveyFormId };

        const response = await getSurveyDetailsAPI(payload);

        if (response?.data?.type === "success") {
            const data = response?.data?.data;

            const status = data?.form_status;

            const survey_details = data?.survey_details;
            const details = {
                id: survey_details?.id,
                title: survey_details?.title ?? "",
                formNo: survey_details?.reference_no ?? "",
            };

            const formElement = survey_details?.survey_element?.length > 0
                ? survey_details?.survey_element
                : [];

            const tempFormState = formElement?.map?.(_ => {
                return {
                    id: _?.id,
                    order: _?.order,
                    type: _?.type,
                    isRequired: Boolean(_?.is_mandatory),
                    label: _?.title ?? "",
                    options: _?.data_type_options?.length > 0 ? _?.data_type_options : [],
                    value: _?.survey_value?.length > 0
                        ? (_?.type === "rating"
                            ? parseInt(_?.survey_value?.[0]?.answer)
                            : _?.survey_value?.[0]?.answer)
                        : "",
                    error: "",
                };
            })?.sort?.((a, b) => a?.order - b?.order);

            setData({ status, details });
            setFormState(tempFormState);

            if (status !== "Submitted") {
                setIsDisabled(false);
            }

            setIsLoading(false);
        } else {
            console.log(getSurveyDetailsError);
            enqueueSnackbar(t("Some Thing Went Wrong"),
                {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
        }
    };

    const updateForm = ({ index = 0, type = "", value = "", checked = false }) => {
        if (type === "check_box") {
            if (checked) {
                formState[index].value = [...formState[index].value, value];
            } else {
                formState[index].value = formState[index].value?.filter?.(_ => _ !== value);
            }
        } else {
            formState[index].value = value;
        }
        formState[index].error = "";
        setFormState([...formState]);
    }

    const validate = () => {
        let isValid = true;

        for (let i = 0; i < formState?.length; i++) {
            if (formState?.[i]?.isRequired && formState?.[i]?.value?.length === 0) {
                formState[i].error = t(`${FORM_ERROR_MESSAGE?.[formState[i].type]}`);
                isValid = false;
            }
        }

        if (!isValid) {
            enqueueSnackbar(t("Please Fill All Mandatory Fields"),
                {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold }
                })
        }

        setFormState([...formState]);
        setIsDisabled(false);

        return isValid;
    };

    const submitForm = async () => {
        setIsDisabled(true);
        if (validate()) {
            const payload = {
                insert_param: formState?.map?.(_ => {
                    return {
                        survey_form_id: surveyFormId,
                        survey_element_id: _?.id,
                        answer: _?.value,
                    }
                }),
            }

            const response = await upsertSurveyValuesAPI(payload);

            if (response?.data?.type === "success") {
                enqueueSnackbar(t("Survey Submitted Successfully"),
                    {
                        variant: "success",
                        anchorOrigin: { horizontal: "center", vertical: "top" },
                        className: { fontFamily: SemiBold }
                    })
                navigate?.(-1);
            } else {
                console.log(upsertSurveyValuesError);
                enqueueSnackbar(t("Something went wrong"),
                    {
                        variant: "error",
                        anchorOrigin: { horizontal: "center", vertical: "top" },
                        className: { fontFamily: SemiBold }
                    })
                setIsDisabled(false);
            }
        } else {
            return false;
        }
    }

    const handleBack = () => {
        navigate?.(-1);
    }

    useEffect(() => {
        if (getSurveyDetailsError?.status === 423 || upsertSurveyValuesError?.status === 423) return errorRoute()
    }, [getSurveyDetailsError, upsertSurveyValuesError])

    return (
        isLoading
            ? <></>
            : <Container style={classes.root} maxWidth="sm">
                <TopNavBar isDirect title={t("Survey Form")} isBack={false} route={() => handleBack()} position="absolute" />
                <Box sx={{ ...classes.formBox, height: size?.height - (65 + (data?.status !== "Submitted" && 77)) }}>
                    <Stack sx={classes.form}
                        direction={"column"} rowGap={"16px"}>
                        <Stack sx={classes.formMiniCard} direction={"row"} columnGap={"16px"} alignItems={"center"}>
                            <img style={classes.surveyIcon} src={surveyIcon} alt="" />
                            <Stack direction={"column"} rowGap={"4px"}>
                                <Typography sx={classes.formMiniCardTitle}>
                                    {data?.details?.title}
                                </Typography>
                                <Typography sx={classes.formMiniCardFormNo}>
                                    {data?.details?.title}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Form t={t} isDisabled={isDisabled} status={data?.status} list={formState} update={updateForm} />
                    </Stack>
                </Box>
                {data?.status !== "Submitted" &&
                    <Box sx={classes.submitButtonBox}>
                        <Button sx={classes.submitButton}
                            disabled={isDisabled} fullWidth onClick={submitForm}>
                            <Typography sx={classes.submitButtonText}>
                                {t("Submit")}
                            </Typography>
                        </Button>
                    </Box>}
            </Container>
    )
}