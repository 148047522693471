import * as React from "react"

const NotificationLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={17.306} {...props}>
    <g data-name="Group 95308">
      <path
        data-name="Path 6985"
        d="m1.346 6.209-.244 4.167s17.992 6.131 18.821 5.188 0-5.188 0-5.188V.94s-2.367.472-4.853 1.18S1.346 6.209 1.346 6.209Z"
        fill="#78b1fe"
      />
      <path
        d="M18.524.002a2.319 2.319 0 0 0-.559.094L1.689 4.934A2.37 2.37 0 0 0 0 7.199v2.376a2.37 2.37 0 0 0 1.689 2.265l2.623.779a4.1 4.1 0 0 0 7.782 2.313l5.87 1.745A2.382 2.382 0 0 0 21 14.413V2.361A2.386 2.386 0 0 0 19.069.044a2.3 2.3 0 0 0-.545-.042Zm.083 1.573a.766.766 0 0 1 .818.79v12.048a.769.769 0 0 1-1.012.755L2.138 10.329a.783.783 0 0 1-.563-.755V7.199a.783.783 0 0 1 .563-.755l16.275-4.838a.868.868 0 0 1 .194-.031ZM5.819 13.067l4.767 1.417a2.566 2.566 0 0 1-4.767-1.417Z"
        fill="#4e5a6b"
      />
    </g>
  </svg>
)

export default NotificationLogo
