
import { LoderSimmer } from "../../../components"
import { Box } from "@mui/material"


export const InspectDetailSimmer = () => {
    return (
        <Box>
            <Box sx={{ backgroundColor: '#fff', width: 'auto', height: '280px', padding: '16px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        <Box><LoderSimmer card count={1} width={'auto'} size={10} variant={"rounded"} animation={"wave"} /></Box>
                        <Box><LoderSimmer card count={1} width={'auto'} size={10} variant={"rounded"} animation={"wave"} /></Box>
                    </Box>
                    <Box><LoderSimmer card count={1} width={40} size={40} variant={"circular"} animation={"wave"} /></Box>
                </Box>
                <Box sx={{ marginTop: '20px' }}><LoderSimmer card count={1} width={'auto'} size={50} variant={"rectangle"} animation={"wave"} /></Box>
                <Box sx={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
                    <Box><LoderSimmer card count={1} width={70} size={70} variant={"rounded"} animation={"wave"} /></Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box><LoderSimmer card count={1} width={100} size={10} variant={"rounded"} animation={"wave"} /></Box>
                            <Box><LoderSimmer card count={1} width={100} size={10} variant={"rounded"} animation={"wave"} /></Box>
                        </Box>
                        <Box><LoderSimmer card count={1} width={'auto'} size={10} variant={"rounded"} animation={"wave"} /></Box>
                            < Box > <LoderSimmer card count={1} width={'auto'} size={10} variant={"rounded"} animation={"wave"} /></Box>

                    </Box>
                </Box>
            </Box>

        </Box>
    )
}
export const InspectProfileSimmer = () => {
    const count = 3
    return (
        <>
            {Array.apply(null, Array(count)).map((value, index) => (
                <Box>
                    <Box sx={{ backgroundColor: '#fff', width: 'auto', height: 'auto', padding: '16px' }}>
                        <Box sx={{ display: 'flex', marginTop: '20px', alignItems:'center' }}>
                            <Box><LoderSimmer card count={1} width={40} size={40} variant={"circular"} animation={"wave"} /></Box>
                            <Box sx={{ marginLeft: '20px' }}>
                                <Box><LoderSimmer card count={1} width={'auto'} size={10} variant={"rounded"} animation={"wave"} /></Box>
                                <Box><LoderSimmer card count={1} width={'auto'} size={10} variant={"rounded"} animation={"wave"} /></Box>

                            </Box>
                        </Box>
                    </Box>

                </Box>
            ))}
        </>
    )
}