import { LoderSimmer } from "../../../components"
import { Box } from "@mui/material"

export const KycIdentificationsimmer = () => {
    return(
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',paddingLeft:'10px'}}>
                <Box>
                    <LoderSimmer card count={3} width={160} size={10} variant={"rectangular"} animation={"wave"} />
                </Box>
                <Box>
                    <LoderSimmer card count={3} width={60} size={10} variant={"rectangular"} animation={"wave"} />
                </Box>
            </Box>
        
    )
}