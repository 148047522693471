import * as React from "react"

export const Dashboard = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={20.048} {...props}>
        <g fill={props?.color ?? "none"} fillRule="evenodd">
            <g data-name="Path 3339">
                <path d="M8.467 1.815A1.815 1.815 0 0 0 6.653 0H1.815A1.815 1.815 0 0 0 0 1.815v9.258a1.815 1.815 0 0 0 1.815 1.815h4.838a1.815 1.815 0 0 0 1.815-1.815Z" />
                <path
                    d="M1.815 1.5a.315.315 0 0 0-.315.315v9.258c0 .173.141.314.315.314h4.838a.315.315 0 0 0 .314-.314V1.815a.315.315 0 0 0-.314-.315H1.815m0-1.5h4.838c1.001 0 1.814.813 1.814 1.815v9.258a1.815 1.815 0 0 1-1.814 1.814H1.815A1.815 1.815 0 0 1 0 11.073V1.815C0 .813.813 0 1.815 0Z"
                    fill={props?.colors ?? "none"}
                />
            </g>
            <g data-name="Path 3340">
                <path d="M8.467 15.815A1.815 1.815 0 0 0 6.652 14H1.815A1.815 1.815 0 0 0 0 15.815v2.419a1.815 1.815 0 0 0 1.815 1.815h4.838a1.815 1.815 0 0 0 1.815-1.815Z" />
                <path
                    d="M1.815 15.5a.315.315 0 0 0-.315.315v2.419c0 .173.141.314.315.314h4.838a.315.315 0 0 0 .314-.314v-2.42a.315.315 0 0 0-.314-.314H1.815m0-1.5h4.838c1.001 0 1.814.813 1.814 1.815v2.419a1.815 1.815 0 0 1-1.814 1.814H1.815A1.815 1.815 0 0 1 0 18.234v-2.42C0 14.814.813 14 1.815 14Z"
                    fill={props?.colors ?? "none"}
                />
            </g>
            <g data-name="Path 3341">
                <path d="M18 1.799a1.808 1.808 0 0 0-1.815-1.8h-4.838a1.808 1.808 0 0 0-1.815 1.8v16.4a1.808 1.808 0 0 0 1.815 1.8h4.838a1.808 1.808 0 0 0 1.815-1.8Z" />
                <path
                    d="M11.348 1.5a.308.308 0 0 0-.315.3v16.4c0 .165.141.3.315.3h4.838a.307.307 0 0 0 .314-.3V1.8c0-.166-.141-.3-.314-.3h-4.838m0-1.5h4.838C17.187 0 18 .806 18 1.8v16.4c0 .993-.813 1.8-1.814 1.8h-4.838a1.808 1.808 0 0 1-1.815-1.8V1.8c0-.994.813-1.8 1.815-1.8Z"
                    fill={props?.colors ?? "none"}
                />
            </g>
        </g>
    </svg>
)


