import { ContractInvoiceCardStyle } from "./style";
import { Stack, Box, Avatar, Typography, Divider, } from "@mui/material";
import { generateImageUrl, img_size } from "../../utils";
import moment from "moment-timezone";
import { InvoiceComplaintCard_style } from "../invoicecomplaintcard/style";
import { CheckIcon } from "../../assets";


export const ContractInvoiceCard = (props) => {
  return (
    <Box sx={ContractInvoiceCardStyle?.contractSepBox} onClick={()=>props?.handleChecked()}>
      <Stack direction="row" sx={ContractInvoiceCardStyle?.parentStack} spacing={2} mt={1} mb={0}>
        <Stack direction="row"  width="70%" alignItems='center'>
                            {props?.is_checked && (
                                <Box sx={InvoiceComplaintCard_style?.check} >
                                    <CheckIcon />
                                </Box>
                            )}
          {
            props?.isImage && <Avatar variant="rounded" sx={{ backgroundColor: "#E6F9FF" }}>
              {generateImageUrl(props.image, img_size.small_square)}
            </Avatar>
          }
          <Stack direction="column" sx={ContractInvoiceCardStyle?.childStack} spacing={"5px"}>
            <Stack direction="row" sx={ContractInvoiceCardStyle?.width} spacing={2}>
              <Box>
                <Typography sx={ContractInvoiceCardStyle?.price}>{props.description}</Typography>
              </Box>
              {
                props?.isActivity && <Box>
                  <Typography sx={ContractInvoiceCardStyle?.activity}>
                    {props.activity}
                  </Typography>
                </Box>
              }
            </Stack>
            <Box
              sx={ContractInvoiceCardStyle?.secondWidth}
              spacing={1}>
              <Typography sx={ContractInvoiceCardStyle?.work}>
                {props.business}</Typography>
              <Box sx={ContractInvoiceCardStyle?.dotTwo}></Box>
              <Typography sx={ContractInvoiceCardStyle?.work}>
                {`Due ${props?.date ? moment(props.date).format(
                  "DD MMM"
                ) : "-"}`}
              </Typography>
            </Box>
          </Stack>
        </Stack>
        {
          props?.rightSideContent && <Box sx={ContractInvoiceCardStyle?.rightSection}>
            <Box>
              <Typography sx={{ color: props?.Inflationcolor }}>
                {props?.symbol} {props?.inflation}
              </Typography>
            </Box>
            <Typography sx={ContractInvoiceCardStyle?.status}>
              {props?.currValue}</Typography>
          </Box>
        }
      </Stack>
      {
        props?.hidden && <Divider sx={ContractInvoiceCardStyle?.dividerBottom} />
      }
    </Box>
  );
};
