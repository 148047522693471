import { Stack, Typography, Box, Divider } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { investmentActivityModalStyle } from "./style";
import { useLazySpendingDrawerQuery } from "../../redux/services";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { FooterComponent } from "../footerComponent";
import { InvestmentDrawerSimmer } from "../../screens/statistics/component/investmentdrawerSimmer";
import moment from "moment-timezone";
export const InvestmentActivityModal = ({
    inflation = "",
    t = () => false,
    id = "",
    handleClose = () => false,
    gainLoss = ""
}) => {
    const [drawerdata, setDrawerdata] = useState({
        listingDatas: [],
        bool: true,
        viewDrawerDetails: {},
        // gainOrLoss:""
    });
    const navigate = useNavigate();
    const [spendingDrawer] = useLazySpendingDrawerQuery();

    const getSpendingList = async (payload) => {
        const data = await spendingDrawer(payload)
        const drawerdataListing = data?.data?.data[0]
        setDrawerdata({
            ...drawerdata,
            listingDatas: data?.data?.data,
            bool: false,
            viewDrawerDetails: {
                ...drawerdataListing, assets: drawerdataListing?.assets?.map((x) => {
                    return {
                        ...x,
                        file_meta: JSON.parse(x?.file_meta)
                    }
                })
            }
        })
    }
    const handlefooter = () => {
        navigate(AppRoutes.createInvestment, { state: drawerdata?.viewDrawerDetails })
    }
    useLayoutEffect(() => {
        getSpendingList({ id: id })
        // eslint-disable-next-line
    }, [])
    return (
        <>
            {
                drawerdata?.bool ?
                    <InvestmentDrawerSimmer />
                    :
                    <>
                        {drawerdata?.listingDatas?.map((val, index) => {
                            return (
                                <Box sx={investmentActivityModalStyle?.main}>
                                    <Stack spacing={0.5} mb={2}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            mt={2}
                                        >
                                            <Box>
                                                <Typography sx={investmentActivityModalStyle?.totalAmount}>
                                                    {t("Current Value")}
                                                </Typography>
                                                <Stack direction="row" alignItems="center">
                                                    <Typography sx={investmentActivityModalStyle?.money}>{val?.current_value}</Typography>
                                                    <Stack direction="row" alignItems="center"
                                                        sx={investmentActivityModalStyle?.inflationDiv}>
                                                        <Typography sx={{ color: "e?.Inflationcolor" }}>{inflation}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                            <Typography sx={investmentActivityModalStyle?.drawerActivity}>
                                                {val?.category_name}
                                            </Typography>
                                        </Stack>
                                        <Typography sx={investmentActivityModalStyle?.descirption}>
                                            {val?.description}
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ color: "#F2F4F7", borderBottomWidth: "4px" }} />
                                    {/* CONTACT NAME */}
                                    <Stack mt={2} justifyContent="space-between" direction="row">
                                        <Typography sx={investmentActivityModalStyle?.information}>{t("Contact Name")}</Typography>
                                        <Typography sx={investmentActivityModalStyle?.assist}>{val?.contact_name}</Typography>
                                    </Stack>
                                    {/* DATE */}
                                    <Stack mt={2} justifyContent="space-between" direction="row">
                                        <Typography sx={investmentActivityModalStyle?.information}>{t("Date")}</Typography>
                                        <Typography sx={investmentActivityModalStyle?.assist}>
                                            {val?.created_at ? moment(val?.created_at).format(
                                                "DD-MMM-YY"
                                            ) : "-"}
                                        </Typography>
                                    </Stack>
                                    {/* UNIT NUMBER */}
                                    <Stack mt={2} justifyContent="space-between" direction="row">
                                        <Typography sx={investmentActivityModalStyle?.information}>{t("Unit Name")}</Typography>
                                        <Typography sx={investmentActivityModalStyle?.assist}>{val?.unit_name}</Typography>

                                    </Stack>
                                    {/* CATEGORY */}
                                    <Stack mt={2} justifyContent="space-between" direction="row">
                                        <Typography sx={investmentActivityModalStyle?.information}>{t("Invested Amount")}</Typography>
                                        <Typography sx={investmentActivityModalStyle?.assist}>{val?.amount}</Typography>
                                    </Stack>

                                    {/* USE */}
                                    <Stack mt={2} mb={2} justifyContent="space-between" direction="row">
                                        <Typography sx={investmentActivityModalStyle?.information}>
                                            {t("Gain/Loss")}
                                        </Typography>
                                        <Typography sx={investmentActivityModalStyle?.assist}>
                                            {
                                                gainLoss ? "Gain" : "Loss"
                                            }
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ color: "#F2F4F7", borderBottomWidth: "4px" }} />
                                    <Box mt={2} mb={2} >
                                        <Typography sx={investmentActivityModalStyle?.information}>
                                            {t("Notes")}
                                        </Typography>
                                        <Typography sx={investmentActivityModalStyle?.assistNote}>
                                            {val?.notes}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ color: "#F2F4F7", borderBottomWidth: "4px" }} />
                                </Box >
                            )
                        })
                        }
                    </>
            }
            <Box sx={investmentActivityModalStyle?.footer}>
                <FooterComponent
                    outlinedText={t("Cancel")}
                    containedText={t("Edit")}
                    outlinedonClick={() => handleClose()}
                    containedonClick={() => handlefooter()}
                />
            </Box>
        </>
    )


};

