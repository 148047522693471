import { Bold, FontSizeCalc } from "../../utils";

export const useStyles = {
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  total_text: {
    fontSize: FontSizeCalc(16),
    fontFamily: Bold,
    color: "#071741",
  },
}