import { Bold, SemiBold } from "../../utils";

export const ActionCard_style = {
  noteBg: {
    width: "77px",
    height: "fit-content",
    backgroundColor: "error.contrastText",
    borderRadius: "11px",
    padding: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    "& span": {
      fontSize: "0.75rem",
      fontFamily: SemiBold
    }
  },
  cardBody: {
    backgroundColor: "background.primary",
    borderRadius: "12px",
    padding: "12px",
    marginTop: "10px",
    marginRight: '10px'
  },
  imgBg: {
    width: "35px",
    height: "35px",
    backgroundColor: "error.primary",
    borderRadius: "7px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noteText: {
    fontSize: "0.75rem",
    width: "90px",
    fontFamily: Bold,
  },
  complaintType: {
    width: "40px",
    textAlign: "left",
    color: "text.Teritary",
    fontSize: "0.75rem",
    paddingTop: "10px",
    fontFamily: SemiBold,
    whiteSpace: "normal !important",
    textOverflow: "clip !important"
  },
  amtText: {
    width: "125px",
    textAlign: "-webkit-match-parent",
    color: "text.primary",
    fontSize: "1rem",
    paddingTop: "4px",
    fontFamily: Bold,
  },
  purpose: {
    height: "14px",
    textAlign: "-webkit-match-parent",
    color: "text.Teritary",
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    mb: "4px"
  },
  purposeCode: {
    height: "14px",
    color: "text.Teritary",
    fontSize: "0.75rem",
    fontFamily: SemiBold,
  },
  dot: {
    padding: "2px",
    backgroundColor: "text.Teritary",
    borderRadius: "50%",
    color: "text.Teritary",

  },
  bottomtab: {
    paddingTop: "4px",
    alignItems: "center",
    justifyContent: "space-around",
  },
  topTab: {
    display: "flex",
    justifyContent: "space-between",
  },
};
