import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from 'react';
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Bold, SemiBold } from '../../utils';
import './index.css';

const useStyles = makeStyles((theme) => ({
      Label: {
            color: theme.typography.color.secondary,
            fontSize: "0.875rem",
            paddingBottom: "8px"
      },
      text: {},

      textbox: {
            height: (props) => (props.multiline ? "unset" : 50),
            width: '100%',
            borderRadius: "4px",
            border: '1.5px solid #E2E2E2',
            cursor: 'pointer',
            color: "#091B29",
            fontSize: "0.875rem",
            fontFamily:SemiBold,
            [`& fieldset`]: {
                  borderRadius: "4px",
                  backgroundColor:(props)=>props?.backgroundColor??"#e2e2e2",
                  height: (props) => (props.multiline ? "unset" : 50),
                  border: "1px solid #CED3DD",
                  "& :hover": {
                        border: "1px solid #5078E1",
                  },
            },
            "& .MuiOutlinedInput-input": {
                  padding: (props) => (props.multiline ? "0px" : "11px 14px"),
            },
            ":-webkit-autofill": {
                  WebkitBoxShadow: "none !important",
                  backgroundColor: "red !important",
            },
            '&:focusVisible': {
                  border: '1.5px solid #E2E2E2',
            },
            padding: '10px'
      },
      calander: {
            position: 'absolute',
            top: "55%",
            right: '15px',
      },
}));

export const DatePickerTimeNew = (props) => {
      const language = localStorage.getItem("i18nextLng");
      const classes = useStyles(props);
      const getLabel = (props) => {
            return (
                  <div style={{ display: "flex" }}>
                        {
                              <Typography
                                    variant="body1"
                                    color="textsecondary"
                                    className={classes.Label}
                                    align="left"
                                    sx={{ color: props?.labelColor ? props?.labelColor : "#4E5A6B",fontFamily:Bold }}
                              >
                                    {props?.label}
                              </Typography>
                        }
                        {props?.isRequired && (
                              <Typography color="error" variant="caption">
                                    &nbsp;*
                              </Typography>
                        )}
                  </div>
            );
      };
      return (
            <>
                  <div style={{ position: 'relative' }}>
                        {getLabel(props)}

                        <Datepicker
                              timeInputLabel="Time:"
                              dateFormat={props?.format?props?.format:"MM/dd/yy h:mm aa"}
                              showTimeInput
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              // selected={this.state.dob.value}
                              selected={props?.value}
                              className={props?.borderNone ? classes.textbox2 : classes.textbox}
                              // onChange={date => props?.onChange(moment(date).format('DD-MM-YYYY,hh:mm A'))}
                              onChange={date => props?.onChange(date)}
                              minDate={props?.minDate ?? false}
                              maxDate={props?.maxDate ?? false}
                              onKeyDown={(e) => {
                                    e.preventDefault();
                              }}
                              onFocus={(e) => e.target.readOnly = true}
                              popperPlacement={props?.placement ?? 'auto-start'}
                              popperProps={{ strategy: 'fixed' }}
                              inline={props?.inline ?? false}
                              disabled={props?.isReadOnly}
                        />
                        {language === "ar" ?
                              (!Boolean(props?.inline) &&
                              <div class={"dateTimePicker"}>
                                    <img src={'/images/icons8-calendar (5).svg'} alt="" />
                              </div> ):
                              (!Boolean(props?.inline) &&
                              <div className={classes.calander}>
                                    <img src={'/images/icons8-calendar (5).svg'} alt="" />
                              </div>)
                        }


                  </div>
                  {props?.isError && (
                        <Typography variant={"caption"} color={"error"}>
                              {props?.errorMessage ?? ""}
                        </Typography>
                  )}
            </>
      )
}