import { Bold, Regular } from "../../utils";


export const AgreementCard_style ={

    agreementnumber:{
        fontFamily:Bold,
        fontSize:"1rem",
        paddingRight:'14px'
    },
    leasedue:{
        fontFamily:Regular,
        fontSize:"0.75rem",
        color:'text.teritary'
    },
    bgBox:{
        backgroundColor: '#F15A291A',
        borderRadius: '4px',
        padding:'1px 6px'
    },
    bgtxt:{
        fontFamily:Bold,
        fontSize:"0.75rem",
        color:'primary.main'
    },
    dot:{
        borderRadius:'50%',
        backgroundColor:'#CED3DD',
        padding:'4px',
        margin:'5px'
    },
    docsBg:{
        padding:'10px',
        backgroundColor: '#F15A291A',
        borderRadius:'8px',
        marginRight:'10px'
        
    },
    divider:{
      borderBottom: "1px solid #E4E8EE",
      margin:'14px 0px'
    },
    check:{
        marginLeft:'5px',
        marginRight:'10px'
    },
    leaseSec:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    codeSec:{
        display:'flex',
        alignItems:'center', 
        paddingBottom:'4px'
    },
    sx:{
        display:'flex',
        alignItems:'center'
    },
    mainSx:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
    }


}