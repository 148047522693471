import React from "react";
import { Invoices } from "./invoices";
import { withTranslation } from 'react-i18next';

class InvoicesParent extends React.Component {
    constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <Invoices  {...this?.props}/>;
  }
}

export default withTranslation('invoice') (InvoicesParent);
