import * as React from "react"

const CalendarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <path
      data-name="icons8-calendar (5)"
      d="M2.528 0A2.537 2.537 0 0 0 0 2.528v8.944A2.537 2.537 0 0 0 2.528 14h8.944A2.537 2.537 0 0 0 14 11.472V2.528A2.537 2.537 0 0 0 11.472 0Zm0 1.167h8.944a1.353 1.353 0 0 1 1.361 1.361v.583H1.167v-.583a1.353 1.353 0 0 1 1.361-1.361ZM1.167 4.278h11.666v7.194a1.353 1.353 0 0 1-1.361 1.361H2.528a1.353 1.353 0 0 1-1.361-1.361Zm2.528 1.556a.972.972 0 1 0 .972.972.972.972 0 0 0-.973-.973Zm3.306 0a.972.972 0 1 0 .972.972A.972.972 0 0 0 7 5.833Zm3.306 0a.972.972 0 1 0 .972.972.972.972 0 0 0-.973-.973Zm-6.611 3.5a.972.972 0 1 0 .972.972.972.972 0 0 0-.974-.973Zm3.306 0a.972.972 0 1 0 .972.972A.972.972 0 0 0 7 9.333Z"
      fill="#4e5a6b"
    />
  </svg>
)

export default CalendarIcon
