import { Box , Typography} from "@mui/material";
import React from "react";
import { TickIcon } from "../../assets";
import { CurrencySwitchwer_styles } from './style'
export const CurrencySwitcher = ({
    selectedCurrency=false,
    updateCurrency="",
    Currency,
    t = () => false,
}) => {
    return (
        <Box>
            <div sx={CurrencySwitchwer_styles?.root}>
                {
                   Currency?.map((item) => {
                        return (
                            <>
                                <Box
                                    sx={CurrencySwitchwer_styles?.list2}
                                    onClick={() =>updateCurrency(item)}
                                >
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        {/* language name */}
                                        <Box>
                                            <div>
                                                <Typography sx={CurrencySwitchwer_styles?.Tittle}>
                                                    {item?.symbol}
                                                </Typography>
                                            </div>
                                        </Box>
                                    </Box>
                                    <Box>
                                        {item?.code ===
                                            selectedCurrency?.symbol && (
                                                <TickIcon/>
                                            )}
                                    </Box>
                                </Box>
                            </>
                        )
                    })


                } 

            </div>
        </Box>
    )
}
 