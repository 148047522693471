import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
// import { ArrowRight } from "../../../assets/dashboard/ArrowRight";
import { Bold, SemiBold } from "../../utils";
import { DialogDrawer } from "../../components";
import ExploreProperty from "./exploreProperty";

const useStyles = makeStyles((theme) => ({
  expoloreBlock: {
    cursor:"pointer",
    padding: "14px",
    minWidth: "187px",
    maxWidth: "190px",
    height: "161px",
    borderRadius: "12px",
    marginRight: "12px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  name: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    marginBottom: "13px",
    
  },
  searchText: {
    fontSize: "0.9375rem",
    fontFamily: Bold,
    color: "#ffffff",
    lineHeight: "21px",
    whiteSpace: "normal !important"
  },
  checkNowText: {
    marginTop: "22px",
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: "#ffffff",
  },
  smCard: {
    display: "flex",
    gap: "12px",
    flexDirection: "column",
    marginRight: "12px",
  },
  cardTitle: {
    width: "80%",
    fontSize: "0.75rem",
    fontFamily: Bold,
    color: theme.palette.secondary.main,
    marginBottom: "4px",
    lineHeight: "14px",
  },
  favParent: {
    display: "flex",
    alignItems: "center",
    overflowY: "none",
    overflowX: "auto",
    gap:"16px",
    marginTop:"12px",
    padding: "0px 16px",
    borderRadius: "9px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));
const ExploreCard = (props) => {
  const { t = () => false , setFun=()=>false } = props;
  const classes = useStyles();
  const [listingPortal, setListingPortal] = React.useState(false);

  const handlelstingDrawerClose = () => {
    setListingPortal(false)
  }
  return (
    <Box>
      <Box className={classes.favParent}>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex">
              <CheckNowCard
                onClick={() => setListingPortal(true)}
                t={t}
                colors="#0974F1, #9FCCFA"
                color="#3568A1"
                title={
                  <>
                    {t("Buy")} &#8226; {t("Rent")} &#8226; {t("Sale")}
                  </>
                }
                subTitle={t("Search, Explore, Get your favourite property")}
              />
              <CheckNowCard
                t={t}
                color="#ffa3cb"
                colors="#a01256, #a01256"
                title={t("Township")}
                subTitle={t("Explore Your Township")}
                onClick={setFun}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/*  LISTING PORTAL */}
      <DialogDrawer
        maxWidth="sm"
        open={listingPortal}
        onClick={handlelstingDrawerClose}
        header={t("Property Listings Portal")}
        height_style={
          { height: "auto !important" }
        }
        component={
          <ExploreProperty />
        }
        onClose={() => setListingPortal(false)}
      />
    </Box>
  );
};

 

const CheckNowCard = ({ title, color, colors, subTitle, t, onClick }) => {
  const classes = useStyles();
  return (
    <Box
    onClick={onClick}
      className={classes.expoloreBlock}
      sx={{
        background: "linear-gradient(291deg," + colors + ")",
        justifyContent: "space-between",
      }}
    >
      <Box className={classes.container}>
        <Box>
          <Typography className={classes.name} sx={{ color: color }}>
            {title}
          </Typography>
          <Typography className={classes.searchText}>{subTitle}</Typography>
        </Box>
        <Typography className={classes.checkNowText}>
          {t("check now")} &#8594;
        </Typography>
      </Box>
    </Box>
  );
};

export default ExploreCard;
