import { Bold, ExtraBold, SemiBold } from "../../utils";



export const ProfileViewStyle = {
    root: {
        
        width: '100%',
        overflow: "auto",
        backgroundColor: 'background.main',
        height: '100% !important',
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    container: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
    },
    ownerIdTag: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: "text.light",
        padding:"4px 8px",
        border:"1px solid",
        borderColor:'text.light',
        borderRadius:"4px",
        backgroundColor:'primary.contrastText',
        marginInlineStart: "16px"
    },
    ownerTag: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: 'text.dark',
        padding:"4px 8px",
        border:"1px solid",
        borderColor:'text.dark',
        borderRadius:"4px",
        backgroundColor:'primary.contrastText',
    },
    title:{
        color:'text.main',
        fontSize: "1.5rem",
        fontFamily: ExtraBold,
        marginBottom:"8px",
        // width:{
        //     sm:"auto",
        //     xs:"62%"
        // }
        // whiteSpace: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
    },
    topSection:{
        backgroundColor:'primary.contrastText',
        padding:"28px 16px 37px",
        backgroundImage:"url('/images/illustration.svg')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",        
    },
    secondSection:{
        padding: "16px",
    },
    cardList:{
        margin:"14px 0px 26px",
        borderRadius:"12px",
        backgroundColor:'primary.contrastText'
    },
    drawerHeader:{
        position:"sticky",
        width:"100%",
        top:"0px !important",
        backgroundColor:'primary.contrastText'

    },
    drawerHeaderBox:{
        position:"relative"
    },
    drawerHeadCloseIcon:{
        position:"absolute",
        top:"4px",
        right:"6px",
        cursor:"pointer"
    },
    drawerTitle:{
        color:"text.main",
        fontSize: "1rem",
        fontFamily:ExtraBold,
        marginBottom:"4px",
        textAlign:"center",
    },
    subTitle:{
        color:"text.Teritary",
        fontSize: "0.75rem",
        fontFamily:SemiBold,
        marginBottom:"20px",
        textAlign:"center",
    },
    drawerCompTitle:{
        color:"text.main",
        fontSize: "0.875rem",
        fontFamily:Bold,
        marginBottom:"5px",
    },
    drawerCompsubTitle:{
        color:"text.Teritary",
        fontSize: "0.75rem",
        fontFamily:SemiBold,
    },
    containedBtn: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color:"primary.main",
        borderRadius: "8px", 
        textTransform: "capitalize",
        backgroundColor:"text.light1",
        boxShadow:"none",
        "&:hover": {
            backgroundColor:"text.light1",
            color:"primary.main",
            boxShadow:"none",
        },
        
    
    },
    numberText:{
        color:"text.main",
        fontSize: "1rem",
        fontFamily:Bold,
    },
    drawerCompBox:{
        border:"1px solid",
        borderColor:"border.secondary",
        padding:"16px",
        borderRadius:"12px",
        marginBottom:"16px"

    },
    logOutConfirmation:{
        color:"text.main",
        fontSize: "1.125rem",
        fontFamily:SemiBold,
        marginBottom:"30px",
        textAlign:"center"
    },
    itle:{
        color:"text.Teritary",
        fontSize: "0.75rem",
        fontFamily:SemiBold,
        textAlign:"center",
        marginBottom:"12px"
    }



};
