import { FontSizeCalc, Regular, SemiBold } from "../../utils"

export const PayRequestInvoiceListStyle = ({
  size = {},
}) => {
  return {
    root: {
      padding: "0 !important",
    },
    header: {
      display: "flex",
      width: "100%",
    },
    body: {
      height: size?.height - (65 + 80.5),
      padding: "16px 16px",
      backgroundColor: "#F2F4F7",
      overflow: "overlay",
    },
    list_header: {
      fontFamily: Regular,
      fontSize: FontSizeCalc(12),
      color: "#4E5A6B",
      marginBottom: "12px",
    },
    list_section: {
      border: "1px solid #E4E8EE",
      borderRadius: "4px",
      padding: "0px 16px",
      backgroundColor: "#FFFFFF",
    },
    infinite_scroll: {
      backgroundColor: "none !important",
      "&::-webkit-scrollbar": {
        display: "none !important",
      },
    },
    invoice_list_divider: {
      backgroundColor: "#E4E8EE",
    },
    invoice_card: {
      padding: "16px 0px",
      cursor: "pointer",
    },
    checked_box: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      borderRadius: "4px",
      height: "16px",
      width: "16px",
      backgroundColor: "#5078E1",
      border: "1.5px solid #5078E1",
    },
    unchecked_box: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      borderRadius: "4px",
      height: "16px",
      width: "16px",
      backgroundColor: "#F5F7FA",
      border: "1.5px solid #CED3DD",
    },
    amount_text: {
      fontFamily: SemiBold,
      fontSize: FontSizeCalc(14),
      color: "#091B29",
    },
    other_details_text: {
      fontFamily: Regular,
      fontSize: FontSizeCalc(12),
      color: "#4E5A6B",
    },
    other_details_text_divider: {
      height: "12px",
      width: "2px",
      backgroundColor: "#E4E8EE",
    },
    footer: {
      display: "flex",
      width: "100%",
      padding: "12px 16px",
      boxShadow: "0px -1px 6px #00000021",
    },
    pay_now_button: {
      padding: "16px",
      border: "none",
      borderRadius: "8px",
      backgroundColor: "#5078E1",
      "&:hover": {
        backgroundColor: "#5078E1",
      },
      fontFamily: SemiBold,
      fontSize: FontSizeCalc(14),
      color: "#FFFFFF",
      textTransform: "capitalize",
    },
  }
}