import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { generateImageUrl, img_size } from '../../utils';
import { AddOnsPriceCardStyles } from "./style"
import { NoImage } from '../../assets';

export const AddOnsPriceCard = ({
    title = "",
    subTitle = "",
    image = "",
    service = false,
    price = "",
    available = "",
    status = "",
    statusUnit = false,
    serviceOnclick = () => false,
    hidden = false,
    subTitle1 = "",
    subTitle2 = "",
    item_condition = "",
    t = () => false,
    oty
}) => {
    const item_condition_class = {
        default: AddOnsPriceCardStyles.default,
        New: AddOnsPriceCardStyles.new,
        Refurbished: AddOnsPriceCardStyles.used,
        Used: AddOnsPriceCardStyles.used,
        Repaired: AddOnsPriceCardStyles.repaired,
        Obselete: AddOnsPriceCardStyles.repaired,
        Damaged: AddOnsPriceCardStyles.damaged,
        Poor: AddOnsPriceCardStyles.damaged,
        EndOfLife: AddOnsPriceCardStyles.damaged
    }
    const language = localStorage.getItem("i18nextLng")

    return (

        <>
            {service &&
                <>
                    <Box sx={AddOnsPriceCardStyles?.root} onClick={() => serviceOnclick()}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                            <Stack direction={"row"} alignItems={"center"}>
                                {image ? <Avatar sx={AddOnsPriceCardStyles?.avatar} src={generateImageUrl(image, img_size.large_rectangle)} alt='No Img' />
                                    : <NoImage />
                                }
                                <Stack sx={{ marginInlineStart: "16px" }}>
                                    <Typography sx={AddOnsPriceCardStyles?.title}>{title}</Typography>
                                    <Typography sx={AddOnsPriceCardStyles?.subTitle}>{subTitle } {oty} </Typography>
                                </Stack>
                            </Stack>
                            <Stack sx={AddOnsPriceCardStyles?.valueText}>
                                <Typography sx={AddOnsPriceCardStyles?.price}>{price} </Typography>
                                <Typography sx={AddOnsPriceCardStyles?.available}>{available} </Typography>
                            </Stack>
                        </Stack>

                    </Box>
                    {hidden &&
                        <Divider sx={AddOnsPriceCardStyles?.divider} />
                    }
                </>
            }
            {statusUnit &&
                <>
                    <Box sx={AddOnsPriceCardStyles?.root}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                            <Stack direction={"row"} alignItems={"center"}>
                                {image ? <Avatar sx={AddOnsPriceCardStyles?.avatar} src={generateImageUrl(image, img_size.large_rectangle)} alt='No Image' />
                                    : <NoImage />
                                }
                                <Stack sx={{ marginInlineStart: "16px"}}>
                                    <Typography sx={AddOnsPriceCardStyles?.title}>{title}</Typography>
                                    <Stack direction={"row"} alignItems={"center"} spacing={1} mt="6px">
                                        <Typography sx={language === "ar" ? AddOnsPriceCardStyles?.subTitle2 : AddOnsPriceCardStyles?.subTitle1}>{t("Model")} : {subTitle1} </Typography>
                                        <Divider sx={{ marginInline: "16px", height: "15px" }} orientation="vertical" variant="middle" flexItem />
                                        <Typography sx={AddOnsPriceCardStyles?.subTitle1}>{t("Serial")} : {subTitle2} </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack sx={AddOnsPriceCardStyles?.valueText}>
                                {/* <Typography sx={status === "Good" ? AddOnsPriceCardStyles?.statusGood : AddOnsPriceCardStyles?.statusDamage}>{status} </Typography> */}
                                <Typography sx={item_condition_class[item_condition] ?? item_condition_class?.default}>{item_condition}</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    {hidden &&
                        <Divider sx={AddOnsPriceCardStyles?.divider} />
                    }
                </>

            }
        </>

    );
}

