import { Bold, Regular, SemiBold } from "../../utils";

export const chooseApplianceStyle = {
    container: {
        backgroundColor: "background.Teritary",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        padding: "134px 0px 0px",
    },
    root: {
        position: "relative",
        width: '100%',
        height: '100%',
        overflow: "auto",
        backgroundColor: "background.main",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    unitChangerBox: {
        backgroundColor: "#FFFFFF",
        padding: "12px",
        border: "1px solid #E4E8EE",
        borderRadius: "6px",
    },
    changerBox: {
        padding: "0px 12px 12px 12px",
        backgroundColor: "#FFFFFF"
    },
    unitChangerText: {
        fontFamily: Bold,
        fontSize: "0.875rem",
        color: "text.Teritary"
    },
    unitChangersubtext: {
        fontFamily: Regular,
        color: "text.secondary",
        fontSize: "0.75rem",
    },
    change: {
        color: "#F15A29",
        fontSize: "0.75rem",
        fontFamily: Bold,
        cursor: "pointer"
    },
    boxShadow: {
        boxShadow: "none !important",
    },
    search: {
        padding: "16px",
        backgroundColor: "#F8F8F8",
    },
    switchDrawerBox: {
        border: "1px solid #CED3DD",
        borderRadius: "12px",
        padding: "16px"
    },
    divider: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        marginInline: "6px"
    },
    applianceText: {
        fontSize: "0.875rem",
        color: "text.main",
        fontFamily: Bold
    },
    image: {
        backgroundColor: "#F2F4F7",
        border: "1px solid #E4E8EE",
        padding: "42px",
        borderRadius: "12px",
        display: "inline-block"
    },
    realImage: {
        border: "1px solid #E4E8EE",
        borderRadius: "12px",
        display: "inline-block",
        height: "127px",
        width: "127px",
        '& .MuiAvatar-img': {
            objectFit: "contain"
        }
    },
    detailsDrawerText: {
        fontSize: "1rem",
        fontFamily: Bold,
        color: "text.main",
        marginTop: "20px"
    },
    detailsDrawersubtext: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "text.main",
        marginTop: "8px",
        marginBottom: "25px"
    },
    example: {
        backgroundColor: "#EEF9EE",
        borderRadius: "6px",
        padding: "8px 16px",
        position: "relative",
    },
    condition: {
        backgroundColor: "#EEF9EE",
        borderRadius: "6px",
        padding: "8px 16px",
        position: "relative",
        marginTop: "20px",
        display: "inline-block"
    },
    exampleText: {
        color: "#5AC782",
        fontFamily: SemiBold,
        fontSize: "0.75rem"
    },
    assetText: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "text.Teritary"
    },
    drawerDivider: {
        marginTop: "16px",
        marginBottom: "16px"
    },
    assetSubtext: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "text.main"
    },
    infoIcon: {
        position: "absolute",
        top: "-21px",
        right: "182px"
    },
    horizontalDivider: {
        marginTop: "12px",
        marginBottom: "12px"
    }
}