import { Typography, Box, Stack } from "@mui/material"
import { ParkingCard_style } from "./style"
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ParkingHome } from "../../assets";
import React from 'react'
import { generateImageUrl, img_size } from "../../utils";
export const ParkingCard = ({
    unit_no = "",
    title = "",
    apartment = "",
    doorno = "",
    imagecomponent = "",
    image = "",
    handleDrawerOpen = () => false,
    t = () => false
}) => {
    const language = localStorage.getItem("i18nextLng")
    return (
        <Box>
            <Box sx={ParkingCard_style?.card} onClick={() => handleDrawerOpen()}>
                <Box sx={ParkingCard_style?.sx}>
                    <Box sx={{ ...ParkingCard_style?.bgImg, ...imagecomponent }}>
                        <img width="100%" height="100%"
                            src={generateImageUrl(image, img_size.small_square)} alt=""></img>
                    </Box>
                    <Box sx={ParkingCard_style?.align}>
                        <Box>
                            <Stack direction="row" alignItems="baseline">
                                <Typography sx={ParkingCard_style?.title}>{title}</Typography>
                                <Box sx={ParkingCard_style?.dot}></Box>
                                <Typography sx={ParkingCard_style?.appartmentName}>{unit_no}</Typography>
                            </Stack>

                            <Typography sx={ParkingCard_style?.appartmentName}>{apartment}</Typography>
                        </Box>
                        <Box sx={ParkingCard_style?.roundBg}>
                            {language === "ar" ?
                                <ChevronLeftIcon /> :
                                <ChevronRightIcon />
                            }
                        </Box>
                    </Box>
                </Box>
                {
                    doorno && <Box>
                        <Box sx={ParkingCard_style?.divider}>
                        </Box><Box sx={ParkingCard_style?.doorNoalign}>
                            <ParkingHome />
                            <Typography sx={ParkingCard_style?.doorno}>{doorno}</Typography>
                        </Box>
                    </Box>


                }

            </Box>
        </Box>
    )
}