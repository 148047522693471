import { Regular, Bold } from "../../utils";

export const ParkingDetails_style = {

    image: {
        boxShadow: '0px 4px 8px #00000029',
        borderRadius: '12px',
        background: 'transparent url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAqgr6CUzmtM3LcJYVyl_5QqelkdXoB-z6DA&usqp=CAU) 0% 0% no-repeat padding-box',
        height: '178px',
        width: '100%',
        marginBottom: '16px'
    },
    divider: {
        borderBottom: '1px solid #F2F4F7',
        margin: '16px 0px'
    },
    doorno: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: 'text.Teritary',
        marginInlineStart: '6px',
    },
    doorNoalign: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontSize: "1rem",
        fontFamily: Bold,
        paddingBottom: '6px'
    },
    appartmentName: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: 'text.Teritary'
    },
    Description: {
        fontSize: "0.875rem",
        fontFamily: Regular,
        marginBottom: '6px'
    },
    locationAlign: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '12px',
        marginTop: '16px'
    },
    address: {
        fontSize: "0.875rem",
        fontFamily: Regular,
        marginInlineStart: '8px'
    },
    map: {
        borderRadius: '12px',
        height: '300px',
        width: '100%'
    }
}