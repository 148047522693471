import { Box, Divider, Grid, IconButton, Popover, Typography } from "@mui/material"
import { FileSvg, ListFile } from "../../../assets"
import { Bold, Regular, SemiBold } from "../../../utils"
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import moment from "moment-timezone";


export const folderStyle = {
    fileImg: {

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "26px 21px",
        backgroundColor: "primary.contrastText",
        borderRadius: "4px"
    },
    gridImg: {
        padding: "10px 0 10px 0px",
        backgroundColor: "primary.contrastText",
        borderRadius: "4px",
        justifyContent: "space-between",
        // borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
    },
    fileCreatedGrid: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        marginTop: "3px"
    },
    fileCreated: {
        fontSize: "0.75rem",
        fontFamily: Regular
    },
    fileName: {
        fontSize: "0.75rem",
        margin: "6px 0 2px 0",
        fontFamily: Bold
    },
    GridfileName: {
        fontFamily: Bold,
        fontSize: "0.75rem"
    },
    gridFile: {
        borderRadius: "8px",
        backgroundColor: ""
    },
    moreBtn: {
        position: "absolute",
        top: "3px",
        right: "0px",
    },
    menuItem: {
        borderBottom: `1px solid `,
        margin: "0px 4px",
        fontSize: "0.875rem",
        color: "black",
        fontFamily: SemiBold,
        "&:last-child": {
            border: 0,
        },
    },
    more: {
        fontSize: "0.78125",
        color: "gray",
        cursor: "pointer",
    },
    menuList: {
        "& .MuiPopover-paper": {
            boxShadow: "0px 0px 6px #0717411F",
            borderRadius: "4px",
            backgroundColor: "white",
        },
    },
    fileDiv: {
        paddingLeft: {
            sm: "14px",
            xs: "8px"
        }
    },
    detailText: {
        padding: "10px 16px",
        fontFamily: SemiBold,
        cursor: "pointer"
    },
    popOver: {
        "& div": {
            boxShadow: "none !important"
        }
    },
    cursor: {
        cursor: "pointer",
    },
    divider: {
        color: "rgba(0, 0, 0, 0.12)",
        height: "1px",
        width: "100%"
    }
}

export const FolderTab = ({
    GridView,
    state = "",
    t = () => false,
    handleRoute = () => false,
    handleOpen = () => false,
    isDivider = false
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleIconClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <>

            {
                GridView === true && <Box sx={folderStyle?.cursor}
                    onClick={() => handleRoute(state)}>
                    <Grid item style={{ position: "relative" }}>
                        <Box sx={folderStyle?.fileImg}>
                            <FileSvg />
                        </Box>
                        <IconButton
                            sx={folderStyle?.moreBtn}
                            onClick={(e) => {
                                e.stopPropagation()
                                setAnchorEl(e.currentTarget);
                            }}
                        >
                            <MoreVertIcon sx={folderStyle?.more} />
                        </IconButton>
                        <Typography sx={folderStyle?.fileName}>{state?.name}</Typography>
                        <Typography sx={folderStyle?.fileCreated}>
                            {moment(state?.created_at)
                                .tz(moment.tz.guess())
                                .format("DD MMM YY")}</Typography>
                    </Grid>
                </Box >
            }
            {
                GridView === false && <Box>
                    <Box sx={folderStyle?.cursor}
                        onClick={() => handleRoute(state)}>
                        <Grid container sx={folderStyle?.gridImg} alignItems="center">
                            <Grid item md={1} sm={1} xs={2}>
                                <Box sx={folderStyle?.gridFile}>
                                    <ListFile />
                                </Box>
                            </Grid>
                            <Grid item md={10} sm={10} xs={8} sx={folderStyle?.fileDiv}>
                                <Typography sx={folderStyle?.GridfileName}>{state?.name}</Typography>
                                <Typography sx={folderStyle?.fileCreatedGrid}>
                                    {moment(state?.created_at)
                                        .tz(moment.tz.guess())
                                        .format("DD MMM YY")}
                                </Typography>
                            </Grid>
                            <Grid item md={1} sm={1} xs={2}>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setAnchorEl(e.currentTarget);
                                    }}
                                >
                                    <MoreVertIcon sx={folderStyle?.more} />
                                </IconButton>

                            </Grid>

                        </Grid>

                    </Box >
                    {
                        isDivider && <Divider sx={folderStyle?.divider} />
                    }
                </Box>
            }
            <Popover
                id='simple-popover'
                open={open}
                anchorEl={anchorEl}
                sx={folderStyle?.popOver}
                onClose={handleIconClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={folderStyle?.detailText} onClick={() => handleOpen((state))}>
                    {t("Details")}</Typography>

            </Popover>

        </>
    )
}