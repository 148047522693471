import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const assetsAPI = createApi({
    reducerPath: "assetsAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["Assets"],
    endpoints: (builder) => ({
        // Assets Listing units
        getAssets: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `assets/get_assests`, method: "POST", body: payload,
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return response.data
                    }
                    return { data: [], totalCount: 0 };
                },
            }),
        getUnitAssets: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `assets/get_unit_assets`, method: "POST", body: payload,
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return response.data
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

        uploadUnitAssets: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `assets/upload_unit_assets`, method: "POST", body: payload,
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return response.data
                    }
                    return { data: [], totalCount: 0 };
                },
            }),
        updateUnitAssets: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `unit/update`, method: "POST", body: payload,
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return response.data
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

    }),
});

// Export hooks for usage in functional components
export const {
    useLazyGetAssetsQuery,
    useLazyGetUnitAssetsQuery,
    useLazyUploadUnitAssetsQuery,
    useLazyUpdateUnitAssetsQuery
} = assetsAPI;
