import { Bold, Regular } from "../../utils";

export const InspectionOrders_style = {
    sx: {
        padding: '16px',
        overflow: "auto",
        height: '100%'
    },
    title: {
        fontSize: "1rem",
        fontFamily: Bold,
        // letterSpacing: '-0.13px',
        padding: '10px 0px'
    },
    container: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        height: '100%',
        overflow: "hidden"
    },
    card: {
        width: '100%',
        height: 'auto',
        borderRadius: '4px',
        boxShadow: '0px 3px 30px #5C86CB2E',

    },
    // boxShadow: {
    //     boxShadow: '0px 3px 30px #5C86CB2E',
    //     borderRadius: '4px',
    // },
    noRecords: {
        color: 'primary.Tertiary',
        fontFamily: Regular,
        fontSize: "0.75rem",
        display: "flex",
        justifyContent: "center",
        marginTop: "20px"
    },
    navBar: {
        position: "fixed",
        top: "0",
        width: "100%",
        left: 0,
        zIndex: 999
    }
}