import { SemiBold } from "../../utils";



export const ProfileDetailsCardStyles = {
    text: {
        color: 'text.Teritary',
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        marginBottom: "6px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        position: "relative",
        zIndex: 99,
        direction: "ltr",
        textAlign: "-webkit-match-parent"
    },
    avatarStyle: {
        height: "28px",
        width: "28px",
        marginBottom: "12px"
    },
    boxStyles: {
        backgroundColor: 'primary.contrastText',
        borderRadius: "12px",
        padding: "14px 16px",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "100%",
        backgroundPositionY: "100%",
        height: "100%",
        position: "relative"
    },
    divider: {
        margin: "16px 0px 16px",
        height: "6px"

    },
    bgImage: {
        position: "absolute",
        right: "0",
        bottom: "0"
    }
};
