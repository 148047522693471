import * as React from "react"

export const InflationDown = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={14.601} height={8} {...props}>
        <path
            d="M7.286 0a.7.7 0 0 0-.481.2L.2 6.8a.695.695 0 1 0 .983.983l6.11-6.11L12.22 6.6h-1.8a.695.695 0 1 0 0 1.39h3.38a.695.695 0 0 0 .8-.8V3.822a.695.695 0 1 0-1.39 0v1.8L7.787.2a.7.7 0 0 0-.501-.2Z"
            fill="#f60000"
        />
    </svg>
)

export default InflationDown
