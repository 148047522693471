
import { SemiBold } from "../../utils";

export const BarChart_Style ={

  root: {
    paddingLeft:0,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-20px",
    marginLeft:"30px",
    "& svg": {
      width: "100%",
      minWidth:  "165px !important",
      height:  "165px !important",
      position: "relative",
      cursor: "pointer",
      padding:'10px',
      "& .recharts-wrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft:"105px"
      },
    },
  },
  main:{
    margin:"0 auto"
  },


  name: {
    color: "backgroundcard.primary",
    fontSize: "0.75rem",
    fontFamily: SemiBold,
  },
  value: {  
    color: "text.Teritary",
    fontSize: "1rem",
    fontFamily: SemiBold,
    marginRight: "8px",
  },
 }