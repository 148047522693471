import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";


export const useStyles = makeStyles((theme) => ({
  label: {
    color: theme?.palette?.text?.secondary,
    fontFamily: SemiBold,
    fontSize: theme?.palette?.labelFontSize,
    marginBottom: "8px",
    textAlign: "-webkit-match-parent",
  },
  required: {
    color: "red !important",
    fontFamily: SemiBold,
    fontSize: theme?.palette?.labelFontSize,
    marginBottom: "5px",
  },
}));
