import React from "react";
import {Property} from "./property";
import { withNavBars } from "./../../HOCs";
import { withTranslation } from 'react-i18next';

class PropertyParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <Property {...this?.props}/>;
  }
}
const props = {
  title: "Properties",
  is_arrow:false,
  isDirect: true
}
export default withNavBars(withTranslation('property')(PropertyParent), props);
