import React, { useState } from "react";
import {
    Box,
    Button,
} from "@mui/material";
import {
    FormGenerator,
    ContactDetails,
    DialogDrawer,
    FooterComponent,
} from "../../../components";
import { MaintanceRequestStyle } from '../style';
import { contactDetails } from "../common";


export const Step2 = (props) => {
    const { data, updateState, t = () => false } = props;

    // -------------------------------state---------------------------------------
    const [showDrawer, setShowDrawer] = useState(false);

    //   drawer function
    const onDrawerOpen = () => {
        setShowDrawer(!showDrawer);
    };
    const ContactSubmit = (e) => {
        setShowDrawer(false)
    }

    return (
        <div>
            {/* Heading */}
            {/* < Stack
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                direction={"row"}
            >
                <Typography sx={MaintanceRequestStyle?.contact} noWrap>{t("Contact Text")}</Typography>
                <Typography sx={MaintanceRequestStyle?.change} onClick={onDrawerOpen} noWrap>
                    {t("Change")}
                </Typography>
            </Stack > */}

            {/* Contact Details Box */}
            < Box sx={MaintanceRequestStyle?.contactDetailsBox} >
                <ContactDetails data={contactDetails(data)} />
                <Button sx={MaintanceRequestStyle.button} fullWidth onClick={onDrawerOpen} variant="outlined">{t("Change")}</Button>
            </Box >

            {/* Contact Details Drawer */}
            < DialogDrawer
                isTitle
                open={showDrawer}
                fullWidth={true}
                maxWidth="sm"
                header={t("Contact Details")}
                height_style={
                    { height: "300px !important" }
                }
                onClose={() => setShowDrawer(false)}
                component={
                    < Box sx={MaintanceRequestStyle?.dialog} >

                        <FormGenerator
                            components={[
                                {
                                    isActive: true,
                                    component: "text",
                                    label: t("Name"),
                                    value: data?.name,
                                    placeholder: t("Name"),
                                    onChange: (value) =>
                                        updateState("name", value?.target?.value),
                                    error: data?.error?.name,
                                    isRequired: true,
                                    size: {
                                        xs: 12,
                                    },
                                },
                                {
                                    isActive: true,
                                    component: "mobileNumber",
                                    label: t("Primary Contact Number"),
                                    value: data?.mobile ?? "-",
                                    placeholder: t("Mobile number"),
                                    onChange: (value) =>
                                        updateState("mobile", value),
                                    errorMessage: data?.error?.mobile,
                                    isRequired: true,
                                    size: {
                                        xs: 12,
                                    },
                                    isPaginate: true,
                                },
                                {
                                    isActive: true,
                                    component: "mobileNumber",
                                    label: t("Alternate Contact Number"),
                                    value: data?.alternative_mobile ?? "-",
                                    placeholder: t("Alternate Contact Number"),
                                    onChange: (value) => updateState("alternative_mobile", value),
                                    errorMessage: data?.error?.alternative_mobile,
                                    isRequired: true,
                                    size: {
                                        xs: 12,
                                    },
                                    isPaginate: true,
                                },
                            ]}
                        />
                    </Box >

                }
                footer={
                    < Box sx={MaintanceRequestStyle.footer1} >
                        {/* footer Component */}
                        < FooterComponent
                            containedonClick={(e) => ContactSubmit(e)}
                            containedText={t("Submit")}
                            single={true}

                        />
                    </Box >
                }


            />
        </div >
    );
};
