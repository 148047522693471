import { Bold, Regular, SemiBold } from "../../../utils";

export const applianceStyle = {
    applianceBox: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #CED3DD"
    },
    greenText: {
        borderRadius: "0px 0px 6px 6px",
        backgroundColor: "#EEF9EE",
        color: "#5AC782",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        display: "inline-block",
        padding: "6px 16px"
    },
    padding: {
        padding: "12px"
    },
    applianceText: {
        fontSize: "0.875rem",
        color: "text.main",
        fontFamily: Bold
    },
    applianceSubtext: {
        fontSize: "0.75rem",
        color: "text.Teritary",
        fontFamily: Regular
    },
    text: {
        color: "text.main",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        width: "100%"
    },
    number: {
        color: "text.Teritary",
        fontFamily: Regular,
        fontSize: "0.75rem",
    },
    padding2: {
        padding: "12px 0px 16px 16px"
    },
    favParent: {
        display: "flex",
        alignItems: "center",
        overflowY: "none",
        overflowX: "auto",
        gap: "16px",
        backgroundColor: "#FFFFFF",
        padding: "16px 0px",
        marginLeft: "16px",
        marginRight: "16px",
        borderRadius: "9px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    condition: {
        borderRadius: "6px",
        backgroundColor: "#EEF9EE",
        color: "#5AC782",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        display: "inline-block",
        padding: "6px 8px"
    },
    img: {
        '& .MuiAvatar-img': {
            objectFit: "contain"
        }
    }
}