import { Bold, Regular } from "../../utils";


export const AddOnsServiceStyle = {

    topNav: {
        position: "fixed",
        top: "0",
        width: "100%",
        zIndex: 999,
        right: "0"
    },
    root: {
        width: '100%',
        padding: "16px",
        backgroundColor: 'background.main',
        height: '100% !important',
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    container: {
        backgroundColor: 'background.Teritary',
        padding: "0px ",
        position: "relative"
    },
    avatar: {
        borderRadius: "4px",
        width: "48px",
        height: "48px",
    },
    title: {
        color: "text.main",
        fontSize: "0.875rem",
        fontFamily: Bold,
        marginBottom: "6px"

    },
    subTitle: {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: Regular,
    },
    slotTitle: {
        color: "text.Teritary",
        fontFamily: Bold,
        fontSize: "0.75rem",
        marginBottom: "12px",
    },
    amenityBox: {
        backgroundColor: "primary.contrastText",
        padding: "16px"
    },
    amenityListBox: {
        padding: "16px"
    },
    serviceBox: {
        marginTop: "16px",
        height: '100% !important',
        overflow: "auto",
        

    }
}