import { Bold, Italic, SemiBold } from "../../utils";
// eslint-disable-next-line

export const KycInformationStyle = {
  root: {
    padding: "16px",
    position: "relative",
    width: '100%',
    height: '100% !important',
    backgroundColor: "background.main",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  Topnav: {
    position: 'fixed',
    top: 0,
    zIndex: 999,
    left:0,
    right:0
  },
  parentBox: {
    position: "fixed",
    right: "0",
    left: "0",
  },

  container: {
    backgroundColor: "background.Teritary",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    position: "relative",
    height: "100vh",
  },
  profilePictureEditIcon: {
    marginBottom: "32px"

  },
  title: {
    color: "text.main",
    fontSize: "1rem",
    fontFamily: Bold,
    marginBottom: "16px"
  },
  AddIcon: {
    position: "fixed",
    bottom: "0",
    cursor: "pointer",
    zIndex: "300",
    right: 0,
    width: "100%",
    display: "flex"
  },
  AddIconContainer: {
    float: "right",
  },
  AddIconChild: {
    float: "right",
    bottom: "14px",
    right: "0",
    cursor: "pointer",
    zIndex: "300",
    backgroundColor: "primary.main",
    color: "primary.contrastText",
    "&:hover": {
      backgroundColor: "primary.main",
      color: "primary.contrastText",
    }
  },
  groupAvatar: {
    fontFamily: SemiBold
  },

  proofTypeText: {
    color: "border.primary",
    fontFamily: SemiBold,
    fontSize: "1rem",

  },
  chooseProofText: {
    color: "text.secondary",
    marginBottom: "16px",
    fontFamily: Italic,
    fontSize: "0.875rem"
  },
  arrowIcon: {
    color: "text.main",
    fontSize: "0.875rem"
  },
  cardStack: {
    marginBottom: "18px",
    cursor: "pointer",
  },
  subText: {
    color: "text.Teritary",
    fontSize: "0.875rem",
    fontFamily: Italic,
  },
  valid: {
    color: "status.success",
    backgroundColor: "status.light",
    padding: "2px 4px",
    fontFamily: Bold,
    borderRadius: "4px",
    fontSize: "0.75rem",
  },
  expired: {
    color: "text.secondary",
    backgroundColor: "border.secondary",
    padding: "2px 4px",
    fontFamily: Bold,
    borderRadius: "4px",
    fontSize: "0.75rem",

  },
  heading: {
    color: "text.main",
    fontFamily: Bold,
    fontSize: "1rem",
    marginBottom: "6px",
  },
  superSubText: {
    color: "text.secondary",
    fontSize: "0.75rem",
    fontFamily: SemiBold
  },
  superSubTextBox: {
    marginTop: "8px"
  },
  infiniteScroll: {
    padding: "16px",
    boxShadow: "0px 3px 30px #4354721F !important",
    background: "white",
    borderRadius: "16px",
    overflow:"auto"
  },
  NoDataRecords: {
    display: "flex",
    alignItems: "center",
    height: "calc(100vh - 170px)",
  }





}

