import * as React from "react"

export const BicycleAvatar = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Group 96576"
        width={23.08}
        height={15.05}
        {...props}
    >
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 53025"
                    fill="#F15A29"
                    d="M0 0h23.08v15.05H0z"
                />
            </clipPath>
            <clipPath id="b">
                <path
                    data-name="Rectangle 53026"
                    fill="#F15A29"
                    d="M0 0h20.879v14.543H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 96575" clipPath="url(#a)">
            <g data-name="Group 96578">
                <g
                    data-name="Group 96577"
                    transform="translate(1.101 .254)"
                    clipPath="url(#b)"
                >
                    <path
                        data-name="Path 9167"
                        d="M20.866 10.359a3.875 3.875 0 0 0-3.832-3.53c-.237-.006-.4 0-.5-.3-.519-1.551-1.071-3.091-1.615-4.643.072-.026.123-.046.176-.062.593-.182.875-.557.791-1.054C15.8.299 15.434.032 14.84.02c-.245 0-.494.022-.736 0-1.241-.14-1.655.7-1.2 1.645a17.824 17.824 0 0 1 .696 1.983c-1.514 0-2.97-.044-4.42.019-.712.031-1.088-.2-1.2-.9a2.381 2.381 0 0 0 .369-.043.864.864 0 0 0 .73-.937.821.821 0 0 0-.825-.845 31.915 31.915 0 0 0-2.436 0 .825.825 0 0 0-.783.636.724.724 0 0 0 .313.941c.716.251.832.891 1.135 1.425a.636.636 0 0 1-.027.729c-.4.64-.785 1.288-1.147 1.948a.523.523 0 0 1-.667.3 3.827 3.827 0 0 0-4.592 3.2 3.882 3.882 0 0 0 3.325 4.381A3.714 3.714 0 0 0 7.3 12.259a7.2 7.2 0 0 0-2.876.341 1.822 1.822 0 0 1-2.164-.666 2.032 2.032 0 0 1-.154-2.284 1.872 1.872 0 0 1 1.95-.935c-.213.366-.423.721-.627 1.079-.515.906-.133 1.563.915 1.565 1.643 0 3.287.01 4.93 0 2.086-.014 1.679.272 2.794-1.615l2.3-3.893a5.987 5.987 0 0 0 .313.869c.273.494.155.8-.275 1.175a3.717 3.717 0 0 0-.8 4.546 3.834 3.834 0 0 0 4.065 2.033 3.873 3.873 0 0 0 3.2-4.114M7.782 9.552c-.224.01-.274-.12-.348-.279A2.432 2.432 0 0 0 7 8.491c-.439-.4-.323-.749-.034-1.154.216-.3.387-.637.621-1.029l1.544 3.238c-.5 0-.925-.014-1.349.006m2.886-1L9.217 5.485h3.256l-1.8 3.063m7.169 4a2.035 2.035 0 0 1-2.276-3.285c.2.57.371 1.063.55 1.553.229.624.693.9 1.2.727a.933.933 0 0 0 .515-1.3c-.162-.5-.335-.989-.508-1.5a1.873 1.873 0 0 1 1.723 1.591 2.057 2.057 0 0 1-1.2 2.211"
                        fill="#F15A29"
                    />
                </g>
            </g>
        </g>
    </svg>
)
