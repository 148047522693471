import { withTranslation } from "react-i18next";
import { PasswordInfoStyles } from "./style"

const PasswordInfo = ({
    t = () => false,

}) => {

    return (
        <div>
            <p className="password_strength"
                style={{
                    ...PasswordInfoStyles?.password_strength,
                    margin: "0px 0px 6px 2px"
                }}>
                {t("For strong password")}
            </p>
            <ul className="unordered_list" style={PasswordInfoStyles?.unordered_list}>
                <li className="password_strength" style={PasswordInfoStyles?.password_strength}>
                    {t("Password should contain atleast 1 capital letter.")}
                </li>
                <li className="password_strength" style={PasswordInfoStyles?.password_strength}>
                    {t("Password should contain atleast 1 special character.")}
                </li>
                <li className="password_strength" style={PasswordInfoStyles?.password_strength}>
                    {t("Password should contain atleast 1 digit.")}
                </li>
            </ul>
        </div>
    )
}

export default withTranslation('WelcomePage')(PasswordInfo)
