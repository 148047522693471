import { Bold, Regular } from "../../utils";

export const PaymentModal_style ={
    summarytitle:{
        fontSize: "1rem",
        fontFamily:Bold,
    },
    amt:{
         fontSize: "0.75rem",
        fontFamily:Regular,
        color:'#4E5A6B',
        textAlign:'right'
    },
    Totalamt:{
        fontSize: "0.875rem",
        fontFamily:Bold
    },
    totalamtSec:{
        // borderTop: `1px dashed #00000029`,
        borderBottom:`1px dashed #00000029`,
        padding: '0px 0px 8px  0px',
        display:'flex',
        justifyContent:'space-between'
    },
    amtAlign:{
        display:'flex',
        justifyContent:'space-between'
    },
    viewinvoice:{
        fontSize: "0.75rem",
        fontFamily:Regular,
        color:'primary.main'
    }
}