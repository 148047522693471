

export const CorrespondenceType_style = {
    container: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        height:'100vh',
        "&::-webkit-scrollbar": {
            display: "none",
        },
        position:"relative",
    },
     Topnav:{
        position: 'sticky',
        top: 0,
        zIndex: 999 
    },
    imgSec:{
        width:'100%',
        height:'473px',
        borderRadius:'4px',

    },
    root:{
        padding:'16px',
        position:'relative',
    },
    footer: {
        position: 'sticky',
        width: "100%",
        bottom: "0",
      },

}