import React from "react";
import { ProfileEdit } from "./profileEdit";
import { withTranslation } from 'react-i18next';

class ProfileEditParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <ProfileEdit {...this?.props}/>;
  }
}
export default withTranslation('profile')(ProfileEditParent);
