import { Bold, FontSizeCalc, Regular, SemiBold } from "../../utils";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";


export const SetUp_style = {
    backgroundImage: {
        backgroundColor: "#F2F4F7",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100%"
    },
    mob_background: {
          height: "235px",
          borderRadius: "4px",
    },
    mob_background_image: {
        height: "235px",
        width: "100%",
        borderRadius: "4px",
    },
    content: {
        height: "calc(100vh - 54px)",
        overflow: "overlay",
        padding: "16px",
        width: "inherit",
    },
    region_flag: {
        borderRadius: "50%",
        objectFit: "contain",
        height: "18px",
        width: "18px",
    },
    flag_dropdown: {
        cursor: "pointer",
        height: "48px",
        padding: "14px",
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
    },
    region_text: {
        fontSize: "0.875rem",
    },
    text: {
        fontSize: "1.5rem",
        fontFamily: Bold,
        //color: 'primary.main',
    },
    img: {
        height: "20px",
        width: "80px",
    },
    textbox: {
        [`& fieldset`]: {
            borderRadius: "4px",
            height: (props) => (props.multiline ? "unset" : 50),
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #F15A29",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: (props) => (props.multiline ? "0px" : "11px 14px"),
        },
        ":-webkit-autofill": {
            WebkitBoxShadow: "none !important",
            backgroundColor: "red !important",
        },
    },
    heightBox: {
        height: "16px"
    },
    buttonTextStyle: {
        fontSize: "0.875rem",
        color: "primary.contrastText",
        fontFamily: Bold,
        textTransform: "capitalize"
    },
    btn: {
        borderRadius: "4px",
        boxShadow: "0px 6px 10px #00000014",
        padding: "16px",
        marginBottom: '16px',
        "&:hover": {
            color: "#fff",
            backgroundColor: "primary.main",
        },
    },
    btnGrid: {
        backgroundColor: "primary.contrastText",
        position: "sticky",
        width: "100%",
        bottom: "0px",
    },
    btnParent: {
        backgroundColor: "primary.contrastText",
        position: "fixed",
        width: "100%",
        bottom: "0px",
        left: "0px",
        right: "0px"
    },
    version_no: {
          fontSize: FontSizeCalc(12),
          fontFamily: Regular,
          color: "#98A0AC",
    },
    powered_by_logo: {
          height: "10px",
          objectFit: "contain",
    },
}



export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props}
          classes={{
                popper: className,
                arrow: {
                      backgroundColor: "#525252",
                      cursor: "pointer",
                },
          }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
          borderRadius: "4px",
          padding: "6px",
          backgroundColor: "#525252",
          color: theme?.typography?.color?.white,
          fontSize: "0.875rem",
          fontFamily: SemiBold,
          cursor: "pointer",
    },
}));