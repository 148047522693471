import * as React from "react"
const UploadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27.672}
    height={27.672}
    {...props}
  >
    <path
      fill="#f15a29"
      d="M26.707 19.306a.948.948 0 0 0-.965.965v5.47H1.931v-5.47a.966.966 0 1 0-1.931 0v6.435a.948.948 0 0 0 .965.965h25.742a.948.948 0 0 0 .965-.965v-6.435a.948.948 0 0 0-.965-.965ZM14.512.29a.933.933 0 0 0-1.351 0L8.334 5.117a.956.956 0 0 0 1.351 1.351l3.186-3.186v13.772a.966.966 0 1 0 1.931 0V3.282l3.186 3.186a.933.933 0 0 0 1.351 0 .933.933 0 0 0 0-1.351Z"
      data-name="icons8-upload (1)"
    />
  </svg>
)
export default UploadIcon
