import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const refreshSlice = createSlice({
    name: 'Refresh',
    initialState,
    reducers: {
        setRefresh(state, { payload }) { state = payload; }
    },
})

export const { setRefresh } = refreshSlice.actions;
export default refreshSlice.reducer;