import { Box, Divider, Typography } from "@mui/material"
import { generateImageUrl, img_size } from "../../utils";
import { ProfileDetailsCardStyles } from "./style";

export const ProfileDetailsCard = ({
  title = "",
  icons = "",
  subTitle = "",
  divider=false,  
  box_style={},
  imageUrl=null
}) => {
  
  return (
    <Box
      sx={{...ProfileDetailsCardStyles?.boxStyles,...box_style}}                
    >
        {icons}
      {
        imageUrl && <Box sx={ProfileDetailsCardStyles.bgImage}>
          <img src={generateImageUrl(imageUrl, img_size.small_square)} alt="images" />
        </Box>
      }


      <Typography sx={ProfileDetailsCardStyles?.text}>
        {title}
      </Typography>

      {/* divider */}      
      {divider && <Divider sx={ProfileDetailsCardStyles?.divider} />}

      <Typography sx={ProfileDetailsCardStyles?.text}>
        {subTitle}
      </Typography>


    </Box>
  )
}