import * as React from "react"

const AboutCompany = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 109042">
      <path
        data-name="icons8-male-user (2)"
        d="M12.5 3.5a9 9 0 1 0 9 9 9.01 9.01 0 0 0-9-9Zm0 1.35a7.65 7.65 0 1 1-7.65 7.65 7.64 7.64 0 0 1 7.65-7.65Zm0 2.25a2.68 2.68 0 0 0-2.023.86 2.841 2.841 0 0 0 0 3.681 2.811 2.811 0 0 0 4.046 0 2.841 2.841 0 0 0 0-3.681A2.68 2.68 0 0 0 12.5 7.1Zm0 1.35a1.244 1.244 0 0 1 1.014.406 1.495 1.495 0 0 1 0 1.888 1.244 1.244 0 0 1-1.014.406 1.244 1.244 0 0 1-1.014-.406 1.495 1.495 0 0 1 0-1.888A1.244 1.244 0 0 1 12.5 8.45ZM9.467 13.4A1.478 1.478 0 0 0 8 14.867v.487a2.672 2.672 0 0 0 1.371 2.186 5.815 5.815 0 0 0 3.129.811 5.815 5.815 0 0 0 3.129-.811A2.672 2.672 0 0 0 17 15.354v-.487a1.478 1.478 0 0 0-1.467-1.467Zm0 1.35h6.066a.108.108 0 0 1 .117.117v.487a1.336 1.336 0 0 1-.75 1.046 4.551 4.551 0 0 1-2.4.6 4.551 4.551 0 0 1-2.4-.6 1.336 1.336 0 0 1-.747-1.048v-.487a.108.108 0 0 1 .114-.115Z"
        fill="#f15a29"
        stroke="#f15a29"
        strokeLinecap="round"
        strokeWidth={0.2}
      />
    </g>
  </svg>
)

export default AboutCompany
