import * as React from "react"

export const Gridview = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        color={props?.color}
        {...props}
    >
        <path
            data-name="Path 96613"
            d="M4.472 7.778a1.75 1.75 0 0 1 1.75 1.75v2.722A1.75 1.75 0 0 1 4.472 14H1.75A1.75 1.75 0 0 1 0 12.25V9.528a1.75 1.75 0 0 1 1.75-1.75Zm7.778 0A1.75 1.75 0 0 1 14 9.528v2.722A1.75 1.75 0 0 1 12.25 14H9.528a1.75 1.75 0 0 1-1.75-1.75V9.528a1.75 1.75 0 0 1 1.75-1.75ZM4.472 8.944H1.75a.583.583 0 0 0-.583.583v2.723a.584.584 0 0 0 .583.583h2.722a.583.583 0 0 0 .583-.583V9.528a.583.583 0 0 0-.583-.584Zm7.778 0H9.528a.583.583 0 0 0-.583.583v2.723a.584.584 0 0 0 .583.583h2.722a.583.583 0 0 0 .583-.583V9.528a.583.583 0 0 0-.583-.584ZM4.472 0a1.75 1.75 0 0 1 1.75 1.75v2.722a1.75 1.75 0 0 1-1.75 1.75H1.75A1.75 1.75 0 0 1 0 4.472V1.75A1.75 1.75 0 0 1 1.75 0Zm7.778 0A1.75 1.75 0 0 1 14 1.75v2.722a1.75 1.75 0 0 1-1.75 1.75H9.528a1.75 1.75 0 0 1-1.75-1.75V1.75A1.75 1.75 0 0 1 9.528 0ZM4.472 1.167H1.75a.583.583 0 0 0-.583.583v2.722a.584.584 0 0 0 .583.583h2.722a.583.583 0 0 0 .583-.583V1.75a.583.583 0 0 0-.583-.583Zm7.778 0H9.528a.583.583 0 0 0-.583.583v2.722a.584.584 0 0 0 .583.583h2.722a.583.583 0 0 0 .583-.583V1.75a.583.583 0 0 0-.583-.583Z"
            fill={props?.color}
        />
    </svg>
)
