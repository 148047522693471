import * as React from "react"

export const Bed = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Group 96257"
    width={20}
    height={20}
    {...props}
  >
    <g data-name="Group 96235">
      <path
        data-name="icons8-empty-bed (1)"
        d="M2.562 3.999a.571.571 0 0 0-.562.58v11.048a.572.572 0 1 0 1.143 0v-.571h13.714v.571a.572.572 0 1 0 1.143 0v-3.048a1.342 1.342 0 0 0-1.333-1.333H8.1v-.381a2.294 2.294 0 0 0-2.286-2.286H4.286a2.214 2.214 0 0 0-1.143.353V4.579a.571.571 0 0 0-.58-.58Zm1.724 5.722h1.523a1.135 1.135 0 0 1 1.143 1.143v.381H3.143v-.381a1.135 1.135 0 0 1 1.143-1.143Zm-1.143 2.667H7.43a.572.572 0 0 0 .185 0h9.051a.182.182 0 0 1 .19.19v1.333H3.143Z"
        fill="#4e5a6b"
      />
    </g>
  </svg>
)

export default Bed
