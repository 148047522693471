import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Avatar, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useLazyGetListingPortalQuery } from "../../redux/services";
import { LoderSimmer } from "../../components";
import { LocalStorageKeys } from "../../utils";
import { AppRoutes } from "../../router/routes";
import { exploreProperty } from "./style";

const ExploreProperty = (props) => {
  const navigate = useNavigate();

  const goTo = ({ route, url }) => {
    switch (route) {
      case AppRoutes.explore:
        navigate(route, {
          state: url,
        });
        break;

      default:
        navigate(route);
        break;
    }
  }

  const client = localStorage.getItem(LocalStorageKeys.client);
  const [property, setProperty] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [getListing] = useLazyGetListingPortalQuery();

  const verifyClient = () => {
    setLoading(true)
    const payload = {
      client_id: client
    };
    getListing(payload)
      .then((response) => {
        setProperty(response?.data)
        setLoading(false)
      })
  };

  React.useEffect(() => {
    verifyClient();
    // eslint-disable-next-line
  }, []);


  return (
    <Box>
      {
        loading
          ?
          <LoderSimmer card count={3} size={40} />
          :
          <>
            {property?.response?.custom_name?.length > 0 &&
              <CardsNew title={property?.response?.custom_name} subTitle="You can find all their properties here"
                open={() => { goTo({ route: AppRoutes.explore, url: property?.response?.custom_url }) }}
                image={property?.response?.client_logo} avatar={property?.response?.client_logo?.length > 0 ? false : true} />
            }

            <CardsNew
              open={() => { goTo({ route: AppRoutes.explore, url: property?.response?.amenity_url }) }}
              image={"/images/AmenitiesGOTO.svg"} original={true} />
            <CardsNew
              open={() => { goTo({ route: AppRoutes.explore, url: property?.response?.realty_url }) }}
              image={"/images/RealtyGOTO.svg"} original={true} />

          </>
      }
    </Box>
  );
};
const CardsNew = ({ title = "", subTitle = "", open, image = "image.png", avatar = false, original = false }) => {
  const stringAvatarNew = (name) => {
    let capName = name ? name.toUpperCase() : undefined
    return {
      sx: {
        bgcolor: "#ffffff",
      },
      children: capName,
    };
  }
  return (
    <Box sx={exploreProperty.cardContainer} onClick={open}>
      <Box>
        {avatar ?
          <>
            <Avatar {...stringAvatarNew(title ?? "A")} sx={exploreProperty.avatar} />
          </>
          :
          <Avatar sx={original ? exploreProperty.img2 : exploreProperty.img3} src={`${image}`} alt="" />}
      </Box>


      <Box>
        <KeyboardArrowRight />
      </Box>
    </Box>
  );
};
export default ExploreProperty;
