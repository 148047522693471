import { SemiBold } from "../../utils";

export const AssetsStyle = {
    container: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
    },
    sticky: {
        position: 'fixed',
        top: 0,
        zIndex: 999,
        left:0,
        right:0
    },
    root: {
        padding: "75px 16px",
        overflow: "auto",
        backgroundColor: 'background.Teritary',
        height: '100%',
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    assetsBox: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        padding: "12px",
        cursor: "pointer"
    },
    assetstext: {
        color: "text.main",
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        marginInlineStart: "12px"
    }
};