import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 });

export const utilitiesAPI = createApi({
    reducerPath: "utilitiesAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["Utilities"],
    endpoints: (builder) => ({

        // get Utilities Listing
        getUtilitiesListing: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `unit-utils`, method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

        // get Utilities View
        getUtilitiesView: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `unit-utils/getUnitUtiltiesReadings`, method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

               // get Utilities View
        getUtilities: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `unit-utils/get`, method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),
    }),
});

// Export hooks for usage in functional components
export const {
    useLazyGetUtilitiesListingQuery,
    useLazyGetUtilitiesViewQuery,
    useLazyGetUtilitiesQuery,
} = utilitiesAPI;
