import { Bold, FontSizeCalc, Regular, SemiBold } from "../../utils/constants";


export const companySelectStyle = {
    container: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
    },
    btnGrid: {
        backgroundColor: "primary.contrastText",
        position: "sticky",
        width: "100%",
        bottom: "0px",
    },
    version_no: {
          fontSize: FontSizeCalc(12),
          fontFamily: Regular,
          color: "#98A0AC",
    },
    powered_by_logo: {
          height: "10px",
          objectFit: "contain",
    },
    mob_background: {
          height: "235px",
          borderRadius: "4px",
    },
    mob_background_image: {
        height: "235px",
        width: "100%",
        borderRadius: "4px",
    },
    backgroundImage: {
        backgroundColor: "background.secondary",
        height: "213px",
        backgroundImage: `url("/images/Select house-cuate.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100%"
    },
    content: {
        height: "calc(100vh - 54px)",
        overflow: "overlay",
        padding: "16px",
        width: "inherit",
    },
    img: {
        height: "8%",
        width: "28%",
    },
    version: {
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        color: "#091B29"
    },
    text: {
        fontSize: "1.5rem",
        fontFamily: Bold,
    },
    textbox: {
        [`& fieldset`]: {
            borderRadius: "4px",
            height: (props) => (props.multiline ? "unset" : 50),
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: (props) => (props.multiline ? "0px" : "11px 14px"),
        },
        ":-webkit-autofill": {
            WebkitBoxShadow: "none !important",
            backgroundColor: "red !important",
        },
    },
    forgetPasswordTextStyle: {
        textAlign: "right",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        color: "text.badgeText2",
        cursor: "pointer",
    },
    goBackStyle: {
        textAlign: 'center',
        textDecoration: 'underline',
        cursor: 'pointer',
        color: "primary.main",
        fontSize: "0.875rem",
        fontFamily: Bold,
        paddingTop: '14px',
        backgroundColor:"#fff"
    },
    companyName: {
        color: "text.Teritary",
        fontFamily: Bold,
        fontSize: "0.875rem",
        marginInlineStart: '12px'
    },
    companySelect: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    comapnyImage: {
        width: 50,
        height: 50,
        borderRadius: '0px',
        fontFamily:Bold
    },
    selectCompany: {
        padding: '14px',
        boxShadow: '0px 3px 30px #5C86CB2E',
        margin: '8px 0px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer'
    },
    selectCompanyBorder: {
        padding: '14px',
        boxShadow: '0px 3px 30px #5C86CB2E',
        margin: '8px 0px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: `1px solid #F15A29`,
        cursor: 'pointer'
    },
    buttonTextStyle: {
        fontSize: "0.875rem",
        color: "primary.contrastText",
        fontFamily: Bold,
        textTransform: "capitalize"
    },
    btn: {
        borderRadius: "4px",
        boxShadow: "0px 6px 10px #00000014",
        padding: "16px",
        "&:hover": {
            color: "#F15A29",
            backgroundColor: "primary.main",
        },
    },
    heightBox: {
        height: "16px"
    },
    loginForm: {
        padding: "0px 16px 0px 16px",
        background:"#fff",
    },
    companySelectDiv: {
        padding: "0px !important",
        position:"relative"
    },
    companySelectDiv1: {
        position: "fixed",
        width: "100%",
        bottom: "0px",
        left: "0px",
        right: "0px"
    },
    containerSm: {
        padding: "0px !important",
        position: "relative",
        height:'100%',
        background:"#fff",

    },
    list: {
        overflow: "auto",
        padding: "16px",
    },
    clientBox: {
        overflow: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        padding: "16px 16px 127px"
    }
}