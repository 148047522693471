import { Bold } from "../../utils";

export const Invoices_style = {
    card: {
        position: "fixed",
        bottom: "24px",
        width: '100%',
        left: "0px",
        right: "0px"
    },
    cardBack: {
        border: "1px solid",
        borderColor: "error.contrastText",
        backgroundColor: "primary.contrastText",
        borderRadius: "12px",
        height: "auto",
        boxShadow: "0px 3px 30px #5C86CB2E",
        padding: '16px',
    },
    card2: {
        border: "1px solid",
        borderColor: "error.contrastText",
        borderRadius: "12px",
        height: "auto",
        boxShadow: "0px 3px 30px #5C86CB2E",
        backgroundColor: "primary.contrastText",
        marginBottom: '90px'
    },
    card1: {
        border: "1px solid",
        borderColor: "error.contrastText",
        borderRadius: "12px",
        height: "auto",
        boxShadow: "0px 3px 30px #5C86CB2E",
        backgroundColor: "primary.contrastText",
    },
    title: {
        fontFamily: Bold,
        fontSize: "1rem",
        textALign: 'right',
        padding: '16px'
    },
    container: {
        backgroundColor: 'background.Teritary',
        position: "relative",
        padding: "0px !important",
        height:"100%",
    },
    root: {
        width: '100%',
        overflow: "auto",
        backgroundColor: 'background.main',
        height: '100%',
        position: "relative",
        padding:"16px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    Topnav: {
        position: 'fixed',
        top: 0,
        zIndex: 999,
        left:0,
        right:0
    },
}