import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import Build from "../../../assets/build";
import CalendarIcon from "../../../assets/calendarIcons";
import CallerIcon from "../../../assets/callerIcon";
import { MaintanceRequestStyle } from '../style';
import { Slider, DialogDrawer, FileViewers } from '../../../components'
import { generateImageUrl, img_size } from "../../../utils";
import LocationStep from "../../../assets/locationStep4";



export const Step4 = (props) => {

    const { request, uploadedImages, t = () => false } = props;

    const [drawer, setDrawer] = React.useState({
        bool: false,
        data: {}

    })

    const closeDrawer = () => {
        setDrawer({
            ...drawer,
            bool: false
        })
    }

    const imageFormat = ["jpg", "png", "webp", "jpeg"]

    return (
        <div>
            <Box sx={MaintanceRequestStyle?.screenPadding}>
                {/* Label */}
                {/* <Box >
                    <Stack direction={"row"} alignItems={"center"}>
                        <Stack sx={MaintanceRequestStyle?.requsettop}>
                            {request?.category?.label}
                        </Stack>
                        <Stack sx={MaintanceRequestStyle?.requsettopsub}>
                            {request?.sub_Category?.label}
                        </Stack>
                    </Stack>
                </Box> */}
                {/* Title Box */}
                <Box sx={MaintanceRequestStyle?.requsettitleBox}>
                    <Typography sx={MaintanceRequestStyle?.requsettitle} noWrap>
                        {request?.problem_Title}
                    </Typography>
                    <Typography sx={MaintanceRequestStyle?.requsetsub} noWrap>
                        {request?.problem_Description}
                    </Typography>
                </Box>

                {/* Time Line  */}
                <Box>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Stack>
                            <Typography sx={MaintanceRequestStyle?.requsetsubs} noWrap>
                                {t("Since")}
                            </Typography>
                            <Typography sx={MaintanceRequestStyle?.requsetsubs2} noWrap>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <CalendarIcon />
                                    <Box sx={MaintanceRequestStyle?.marginInline}>
                                        &#x202a;{moment(request?.problem_Since)
                                            .tz(moment.tz.guess())
                                            .format("DD MMM YY")}&#x202c;
                                    </Box>

                                </Stack>

                            </Typography>
                        </Stack>
                        <Stack marginInlineStart={3}>
                            <Typography sx={MaintanceRequestStyle?.requsetsubs} noWrap>
                                {t("Unit")}
                            </Typography>
                            <Stack direction={"row"} alignItems={"center"} sx={MaintanceRequestStyle?.requsetsubs2}>
                                <Box height={"14px"}><Build /></Box>
                                <Box sx={MaintanceRequestStyle?.marginInline}>{request?.select_Unit?.label ?? "-"}</Box>
                            </Stack>
                        </Stack>
                        <Stack marginInlineStart={3}>
                            <Typography sx={MaintanceRequestStyle?.requsetsubs} noWrap>
                                {t("Raised On")}
                            </Typography>
                            <Typography sx={MaintanceRequestStyle?.requsetsubs2} noWrap>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <CalendarIcon sx={MaintanceRequestStyle?.marginInline} />
                                    <Box sx={MaintanceRequestStyle?.marginInline}>
                                        &#x202a;{moment(Date()).tz(moment.tz.guess()).format("DD MMM YY ")}&#x202c;
                                    </Box>

                                </Stack>
                            </Typography>
                        </Stack>
                    </Stack>
                    <Divider sx={MaintanceRequestStyle?.divider} />
                    {uploadedImages?.length > 0 && (
                        <>
                            <Grid
                                container
                            >
                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginBottom: "8px" }}
                                >
                                    <Typography sx={MaintanceRequestStyle?.requsetsubs} noWrap>
                                        {t("Images")}
                                    </Typography>
                                </Grid>
                                {uploadedImages?.map((val) => {
                                    return (
                                        <Grid item xs={4} sm={3} onClick={() => setDrawer({
                                            bool: true,
                                            data: val
                                        })}>
                                            <Box>
                                                {val?.file_meta?.type === "mp4" &&
                                                    <>
                                                        <video
                                                            style={{ borderRadius: "10px !important", objectFit: "contain", width: "75px" }}
                                                            height={75}
                                                            controls>
                                                            <source src={val?.url} type="video/mp4" />
                                                        </video>
                                                    </>
                                                }
                                                {
                                                    imageFormat.includes(val?.file_meta?.type) &&
                                                    <>
                                                        <img
                                                            height={75}
                                                            width={'75px'}
                                                            style={{ borderRadius: "10px" }}
                                                            src={generateImageUrl(val?.url, img_size.small_square)}
                                                            alt="img"
                                                        />
                                                    </>
                                                }
                                                {val?.file_meta?.type === "pdf" &&
                                                    <div style={{ height: '75px', width: "75px", overflow: "auto", borderRadius: "6px" }}>
                                                        <FileViewers fileType={"pdf"} filePath={val?.url} />
                                                    </div>

                                                }

                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </>
                    )}
                    <Typography sx={MaintanceRequestStyle?.requsetsubs} noWrap>
                        {t("Location")}
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"}>
                        <LocationStep />
                        <Typography marginInlineStart={1} sx={MaintanceRequestStyle?.requsetsubs2} noWrap>
                            {request?.location?.label}
                        </Typography>
                    </Stack>
                    <Divider sx={MaintanceRequestStyle?.divider} />
                    <Stack>
                        <Typography sx={MaintanceRequestStyle?.requsetsubs} noWrap>
                            {t("Preferred Visit Date & Time")}
                        </Typography>
                        <Typography sx={MaintanceRequestStyle?.requsetsubs4} noWrap>
                            <Stack direction={"row"} alignItems={"center"}>
                                <CalendarIcon />
                                <Box sx={MaintanceRequestStyle?.marginInline}>
                                    &#x202a;{moment(request?.preferred_Date)
                                        .tz(moment.tz.guess())
                                        .format("DD MMM YY")}&#x202c;
                                </Box>
                                <Typography sx={MaintanceRequestStyle?.requsetsubs4}>{` , ${request?.selectedSlot?.label}`}</Typography>
                            </Stack>
                        </Typography>

                    </Stack>
                </Box>
                <Divider sx={MaintanceRequestStyle?.divider} />

                {/* Contact Details */}
                <Typography sx={MaintanceRequestStyle?.requsetsubs} noWrap>
                    {t("Contact Detail")}
                </Typography>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                >
                    <Stack>
                        <CallerIcon />
                    </Stack>
                    <Stack sx={{ marginInlineStart: "16px" }}>
                        <Typography sx={MaintanceRequestStyle?.requsetsubs} noWrap>
                            {request?.name}
                        </Typography>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            divider={(request?.mobile?.mobile && request?.alternative_mobile?.mobile) && <Divider sx={{ marginInline: "16px" }} orientation="vertical" flexItem />}
                        >
                            <Typography sx={MaintanceRequestStyle?.requsetsubs2} noWrap>
                                {request?.mobile?.mobile ? `${request?.mobile?.mobile_code} ${request?.mobile?.mobile}` : ""}
                            </Typography>
                            <Typography sx={MaintanceRequestStyle?.requsetsubs2} noWrap>
                                {request?.alternative_mobile?.mobile ? `${request?.alternative_mobile?.mobile_code} ${request?.alternative_mobile?.mobile}` : ""}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Box >
            <DialogDrawer
                maxWidth="sm"
                open={drawer?.bool}
                isnotTitle
                padding={"16px 16px 0px"}
                height_style={
                    { height: "350px !important" }
                }
                component={
                    <>
                        <Slider
                            title={t("Images")}
                            assets={uploadedImages}
                            onClose={() => closeDrawer()}
                        />
                    </>
                }
                onClose={() => closeDrawer()}
            />
        </div >
    );
};
