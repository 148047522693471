import { Regular, Bold, SemiBold, ExtraBold } from "../../utils/constants";


export const ContractInvoiceCardStyle = {
    parentStack: {
        justifyContent: "space-between",
        paddingBottom: "16px",
    },
    dividerBottom: {
        height: "1px",
        borderBottom: "1px solid #E4E8EE",

    },
    contractSepBox: {
        cursor: "pointer",
    },
    childStack: {
        width: "75%"
    },
    secondWidth: {
        display: "flex",
        alignItems: 'center'
    },
    heading: {
        fontFamily: Bold,
        fontSize: "0.875rem",
        color: 'color.primary'
    },
    year: {
        fontFamily: SemiBold,
        backgroundColor: 'primary.main',
        color: "white",
        padding: "7px 11px",
        fontSize: "0.75rem",
        borderRadius: "15px"
    },
    month: {
        fontSize: "0.75rem",
        padding: "7px 5px 7px 0",
    },
    percentage: {
        backgroundColor: "#EE5188",
        color: "white",
        fontSize: "0.75rem",
        padding: "2px 9px",
        borderRadius: "4px"
    },
    tenantId: {
        fontFamily: SemiBold,
        color: "color.secondary",
        fontSize: "0.875rem"
    },
    price: {
        fontFamily: Bold,
        fontSize: "0.875rem",
        color: 'color.primary'
    },
    divider: {
        borderColor: "#5C86CB2E",
        height: "1px",
        margin: 0,
    },
    activity: {
        backgroundColor: "#FFE5DC",
        color: "#F15A29",
        padding: "2px 8px",
        fontSize: "0.75rem",
        borderRadius: "4px",
        fontFamily: SemiBold
    },
    work: {
        color: 'text.Teritary',
        fontFamily: Regular,
        fontSize: "0.75rem"
    },
    totalAmount: {
        fontFamily: Regular,
        color: 'color.secondary',
        fontSize: "0.875rem"
    },
    money: {
        color: 'color.primary',
        fontSize: "0.78125",
        fontFamily: ExtraBold
    },
    descirption: {
        color: 'color.tertiary',
        fontFamily: Regular,
        fontSize: "0.75rem"
    },
    drawerActivity: {
        backgroundColor: "#FFE5DC",
        color: "#F15A29",
        padding: "8px 12px",
        fontSize: "0.75rem",
        borderRadius: "4px",
        fontFamily: ExtraBold
    },
    information: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: 'color.tertiary'
    },
    assist: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        color: 'color.tertiary'
    },
    label: {
        color: "#98A0AC",
        fontSize: "0.75rem",
        fontFamily: Bold,
    },
    textArea: {
        width: "100%",
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
    },
    primaryButton: {
        padding: "15px 60px",
        border: "1px solid #CED3DD",
        color: 'color.primary',
        fontFamily: Bold,
        borderRadius: "8px"
    },
    secondaryButton: {
        padding: "15px 60px",
        backgroundColor: "#5078e1",
        border: "none",
        color: "white",
        fontFamily: Bold,
        borderRadius: "8px"
    },
    uploadButton: {
        border: "2px dashed #E4E8EE",
        borderRadius: "4px",
        padding: "25px",
        backgroundColor: "white",
        color: 'color.primary'
    },
    field: {
        [`& fieldset`]: {
            borderRadius: "4px",
            border: "1.5px solid  #E4E8EE",
            boxShadow: "none",
            backgroundColor: "white"
        },
    },
    dotTwo: {
        padding: '3px',
        borderRadius: '50%',
        margin: "0px 5px",
        backgroundColor: 'background.light'
    },
    rightSection: {
        width: "25%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "end",
        "& div": {
            display: "flex",
            alignItems: "center",
            marginBottom: "9px",
            "& p": {
                marginRight: "5px",
                fontFamily: SemiBold,
                fontSize: "1rem",
                color: 'text.Teritary',
            }
        },
        "& p": {
            display: "flex",
            alignItems: "baseline",
            fontSize: "0.75rem",
            fontFamily: Regular,
            "& p": {
                color: 'color.primary',
                fontFamily: Bold,
                fontSize: "0.75rem",
                marginLeft: "2px"
            }
        }
    },
    status: {
        padding: "1px 8px",
        backgroundColor: "background.primary1",
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: "text.primary1"
    }



}