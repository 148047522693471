export const InvoiceIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <g data-name="Group 117534" transform="translate(-76 -121.5)">
            <rect
                width={40}
                height={40}
                fill="#e6efff"
                data-name="Rectangle 58388"
                rx={6}
                transform="translate(76 121.5)"
            />
            <g data-name="icons8-bill (12)">
                <path
                    fill="#fff"
                    d="M106.581 127.5h-.744a.744.744 0 0 1-1.488 0h-1.489a.744.744 0 0 1-1.488 0h-1.488a.744.744 0 0 1-1.484 0h-1.493a.744.744 0 0 1-1.488 0H93.93a.744.744 0 1 1-1.488 0h-1.489a.744.744 0 0 1-1.488 0h-1.488a.744.744 0 1 1-1.488 0h-.745a.744.744 0 0 0-.744.744v26.791a.744.744 0 0 0 .744.744h.744a.744.744 0 1 1 1.488 0h1.488a.744.744 0 0 1 1.488 0h1.488a.744.744 0 1 1 1.488 0h1.488a.744.744 0 1 1 1.488 0H98.4a.744.744 0 1 1 1.488 0h1.488a.744.744 0 0 1 1.488 0h1.488a.744.744 0 1 1 1.488 0h.744a.744.744 0 0 0 .744-.744v-26.791a.744.744 0 0 0-.747-.744Z"
                    data-name="Path 101868"
                />
                <path
                    fill="#78a2e9"
                    d="M103.977 134.942H88.349a.372.372 0 0 1-.372-.373v-1.488a.372.372 0 0 1 .372-.372h15.628a.372.372 0 0 1 .372.372v1.488a.372.372 0 0 1-.372.373Z"
                    data-name="Path 101869"
                />
                <path
                    fill="#78a2e9"
                    d="M103.976 140.151h-1.488a.372.372 0 0 1-.372-.372v-.744a.372.372 0 0 1 .372-.372h1.488a.372.372 0 0 1 .372.372v.744a.372.372 0 0 1-.372.372Z"
                    data-name="Path 101870"
                />
                <path
                    fill="#78a2e9"
                    d="M98.024 140.151h-9.675a.372.372 0 0 1-.372-.372v-.744a.372.372 0 0 1 .372-.372h9.674a.372.372 0 0 1 .372.372v.744a.372.372 0 0 1-.371.372Z"
                    data-name="Path 101871"
                />
                <path
                    fill="#78a2e9"
                    d="M103.976 143.128h-1.488a.372.372 0 0 1-.372-.372v-.744a.372.372 0 0 1 .372-.372h1.488a.372.372 0 0 1 .372.372v.744a.372.372 0 0 1-.372.372Z"
                    data-name="Path 101872"
                />
                <path
                    fill="#78a2e9"
                    d="M95.791 143.128h-7.442a.372.372 0 0 1-.372-.372v-.744a.372.372 0 0 1 .372-.372h7.442a.372.372 0 0 1 .372.372v.744a.372.372 0 0 1-.372.372Z"
                    data-name="Path 101873"
                />
                <path
                    fill="#78a2e9"
                    d="M103.976 146.104h-1.488a.372.372 0 0 1-.372-.372v-.744a.372.372 0 0 1 .372-.372h1.488a.372.372 0 0 1 .372.372v.744a.372.372 0 0 1-.372.372Z"
                    data-name="Path 101874"
                />
                <path
                    fill="#78a2e9"
                    d="M98.024 146.104h-9.675a.372.372 0 0 1-.372-.372v-.744a.372.372 0 0 1 .372-.372h9.674a.372.372 0 0 1 .372.372v.744a.372.372 0 0 1-.371.372Z"
                    data-name="Path 101875"
                />
                <path
                    fill="#78a2e9"
                    d="M103.977 150.569H101a.372.372 0 0 1-.372-.372v-.744a.372.372 0 0 1 .372-.372h2.977a.372.372 0 0 1 .372.372v.744a.372.372 0 0 1-.372.372Z"
                    data-name="Path 101876"
                />
                <path
                    fill="#78a2e9"
                    d="M98.023 150.569h-6.7a.372.372 0 0 1-.37-.372v-.744a.372.372 0 0 1 .372-.372h6.7a.372.372 0 0 1 .372.372v.744a.372.372 0 0 1-.374.372Z"
                    data-name="Path 101877"
                />
            </g>
        </g>
    </svg>
)