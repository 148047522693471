import { Bold, Regular } from "../../utils";


export const InspectionDetails_style = {
    sx: {
        padding: '16px',
        height: "calc(100vh - 100px)",
        overflow: "auto"

    },
    container: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        position: "relative",
        height: "100vh",
        overflow: "hidden",
    },
    card: {
        padding: '12px',
        backgroundColor: 'primary.contrastText',
        width: '100%',
        height: 'auto',
        boxShadow: '0px 3px 30px #5C86CB2E',
        borderRadius: '4px'
    },
    topcard: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    complete: {
        fontFamily: Bold,
        fontSize: "1rem",
        color: 'color.primary'
    },
    date: {
        fontFamily: Regular,
        fontSize: "0.75rem",
        color: 'text.Teritary'
    },
    bg: {
        width: '100%',
        height: '44px',
        backgroundColor: 'backgroundcard.dark',
        padding: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    report: {
        fontFamily: Regular,
        color: 'primary.main',
        fontSize: "0.875rem",
        marginInlineStart: '8px'
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    divider: {
        borderBottom: '1px solid #E4E8EE',
        padding: '8px'
    },
    inspectortitle: {
        fontFamily: Bold,
        color: '#98A0AC',
        fontSize: "0.75rem",
        paddingBottom: '12px'
    },
    position: {
        position: "fixed",
        top: "0%"
    },
    Topnav: {
        position: 'sticky',
        top: 0,
        zIndex: 999
    },
    parentBox: {
        position: "fixed",
        right: "0",
        left: "0",
        top: "0",
        bottom: "0"
    },
}