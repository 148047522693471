import { Box, Divider, Stack, Typography } from "@mui/material"
import { Track_style } from "./style";
import moment from "moment-timezone";


export const Track = ({
    image = "",
    date = "",
    purpose = "",
    line = false,
    progressDetails = [],
    titleBold = false,
    show = false,
    created_at,
    closed_by = null
    // checkin
}) => {
    return (
        
        <Box sx={Track_style?.align}>
            {
                show === true ? show && line ? <Box sx={Track_style?.lineBlue} /> :
                    <Box sx={Track_style?.line} /> : null
            }
            <Box sx={Track_style?.img}>{image}</Box>
            <Box sx={Track_style?.marginLeft}>
                {
                    titleBold === true ? <Typography sx={Track_style?.inspectedDateBold}>{date}</Typography> :
                        <Typography sx={Track_style?.inspectedDate}>{date}</Typography>
                }
                {
                    progressDetails?.length > 0 ?
                    progressDetails?.slice()?.sort((a, b) => { return new Date(b?.created_at) - new Date(a?.created_at) })?.map(e => {
                            return (
                                <>
                                    <Typography sx={Track_style?.progressDetails}>
                                        {e?.notes}
                                    </Typography>
                                    <Stack direction="row" spacing={1} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                                        <Typography sx={Track_style?.inspectorId}>
                                            &#x202a;{
                                                moment(e?.created_at).format(
                                                    "yyyy-MM-DD hh:mm A"
                                                )}&#x202c;
                                        </Typography>
                                        {e?.created_by !== null &&
                                            <Typography sx={Track_style?.created_at}>
                                                {e?.created_by}
                                            </Typography>
                                        }
                                    </Stack>
                                </>
                            )
                        })
                        :
                        <Stack direction="row" spacing={1} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                            <Typography sx={Track_style?.created_at}>
                                &#x202a;{
                                    moment(created_at).format(
                                        "yyyy-MM-DD hh:mm A"
                                    )}&#x202c;
                            </Typography>
                            {closed_by !== null &&
                                <Typography sx={Track_style?.created_at}>
                                    {closed_by}
                                </Typography>
                            }
                        </Stack>
                }
                <Typography sx={Track_style?.inspectorId}>{purpose}</Typography>
            </Box>
        </Box>

    )
}