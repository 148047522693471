import React from "react";
import { withTranslation } from 'react-i18next';
import { MaintanceRequest } from "./maintanceRequest";

class MaintanceRequestParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <MaintanceRequest {...this?.props} />;
  }
}

export default withTranslation('request')(MaintanceRequestParent);
