import React from "react";
import { withTranslation } from "react-i18next";
import { CompanySelect } from "./companySelect";
class CompanySelectParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return <CompanySelect {...this?.props} />;
    }
}

export default withTranslation('login')(CompanySelectParent);