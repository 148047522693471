import { Typography, Box } from "@mui/material"
import { LocationMap, ParkingHome } from "../../assets"
import SimpleMap from "../simpleMap"
import { ParkingDetails_style } from "./style"


export const ParkingDetails = ({
    t = () => false,
    data = {}
}) => {
    return (
        <Box>
            <Typography sx={ParkingDetails_style?.title}>{data?.name}</Typography>
            <Typography sx={ParkingDetails_style?.appartmentName}>{data?.property?.name}</Typography>
            <Box sx={ParkingDetails_style?.divider} />
            <Box sx={ParkingDetails_style?.doorNoalign}>
                <ParkingHome />
                <Typography sx={ParkingDetails_style?.doorno}>{data?.parking?.parking_no}</Typography>
            </Box>
            {
                data?.parking?.description && 
                <> <Box sx={ParkingDetails_style?.divider} />
                <Box sx={ParkingDetails_style?.marginBottom}>
                    <Typography sx={ParkingDetails_style?.Description}>{t('Description')}</Typography>
                    <Typography sx={ParkingDetails_style?.appartmentName}>{data?.parking?.description}</Typography>
                </Box></>
            }
           
            <Box sx={ParkingDetails_style?.divider} />
            <Box sx={ParkingDetails_style?.locationAlign}>
                <LocationMap />
                <Typography sx={ParkingDetails_style?.address}>
                    {data?.address?.address_street1}{data?.address?.street_2 ? "," : ""}{data?.address?.street_2}{data?.address?.country ? ",":""}{data?.address?.country}
                </Typography>
            </Box>
            <Box sx={ParkingDetails_style?.map}>
                <SimpleMap
                    lat={parseFloat(data?.address?.latitude)}
                    lng={parseFloat(data?.address?.longitude)}
                    center={{
                        lat: parseFloat(data?.address?.latitude),
                        lng: parseFloat(data?.address?.longitude)
                    }}
                    zoom={15}
                />
            </Box>
        </Box>
    )
}