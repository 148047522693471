import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";
// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });
export const VehicleAPI = createApi({
    reducerPath: "VehicleAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ['get_parking_unit_list'],
    endpoints: (builder) => ({
        // Get all owner API
        GetAllOwner: builder.query({
            query: (payload) => ({
                url: `owner_parking_area/get_parking_unit_list`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),
        GetOwner: builder.query({
            query: (payload) => ({
                url: `owner_parking_area/get_parking_units`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),
        GetParkingArea: builder.query({
            query: (payload) => ({
                url: `parking-area/get_vehicle_parking_area`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),
        ParkingCreation: builder.query({
            query: (payload) => ({
                url: `owner_parking_area/create_parking`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },

        }),


    }),
})

// Export hooks for usage in functional components
export const { useLazyGetAllOwnerQuery, useLazyGetOwnerQuery,
    useLazyGetParkingAreaQuery, useLazyParkingCreationQuery } = VehicleAPI;
