import React from "react";
import { Box } from "@mui/material";
import { Bold } from "../../utils";
import { withTranslation } from "react-i18next";
import NoDataShow from "../../assets/noData";
import { NoDataChart } from "../../assets";

const NoData = ({
    t = () => false,
    chart = false,
    height = 300,
    width = 300,
    style,
    chartData


}) => {
    const styles = {
        // height:"100vh",
        display: 'block',
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor:"#F6F6F6",
        textAlign: "center",
        '& p': {
            fontFamily: `${Bold} !important`
        },
        margin:"0 auto"
    }
    return (
        <Box sx={{...styles , ...style}}>
            {chartData ? <NoDataChart /> : <NoDataShow />}                       
        </Box>
    )
}
export default withTranslation('login')(NoData)