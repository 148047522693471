import { Bold, FontSizeCalc, Regular, SemiBold } from "../../utils/constants";

export const welcomePageStyle = {
    container: {
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        position: "relative",
        backgroundColor:"#fff",
        height: "calc(100vh - 54px)",
        overflow: "overlay",
    },
    mob_background: {
          height: "235px",
          borderRadius: "4px",
    },
    mob_background_image: {
        height: "235px",
        width: "100%",
        borderRadius: "4px",
    },
    backgroundImage: {
        backgroundColor: "background.secondary",
        height: "213px",
        backgroundImage: `url("/images/Select house-cuate.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100%"
    },
    content: {
        height: "calc(100vh - 54px)",
        overflow: "overlay",
        padding: "16px",
        width: "inherit",
    },
    img: {
        height: "8%",
        width: "28%",
    },
    version: {
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        color: "text.main"
    },
    text: {
        fontSize: "1.5rem",
        fontFamily: Bold,
    },
    textbox: {
        [`& fieldset`]: {
            borderRadius: "4px",
            height: (props) => (props.multiline ? "unset" : 50),
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #F15A29",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: (props) => (props.multiline ? "0px" : "11px 14px"),
        },
        ":-webkit-autofill": {
            WebkitBoxShadow: "none !important",
            backgroundColor: "red !important",
        },
    },
    forgetPasswordTextStyle: {
        textAlign: "right",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        color: "primary.main",
        cursor: "pointer",
    },
    buttonTextStyle: {
        fontSize: "0.875rem",
        color: "primary.contrastText",
        fontFamily: Bold,
        textTransform: "capitalize"
    },
    loginForm: {
        padding: "0px 16px 0px 16px"
    },
    btn: {
        borderRadius: "4px",
        boxShadow: "0px 6px 10px #00000014",
        padding: "16px",
        "&:hover": {
            color: "#fff",
            backgroundColor: "primary.main",
        },
    },
    heightBox: {
        height: "16px"
    },
    btnGrid: {
        padding: "16px",
        position: "sticky",
        width: "100%",
        bottom: "0px",
    },
    overflowItem: {
        padding: "12px 0",
        // overflow: "auto",
        // height:"calc(100vh - 425px)"
    },
    root: {
        position: "relative",
    },
    btnContainer: {
        backgroundColor: "primary.contrastText",
        padding: "0 !important",
        position: "fixed",
        width: "100%",
        bottom: "0px",
        left: "0",
        right: "0"
    },
    region_box: {
          cursor: "pointer",
          height: "40px",
          padding: "7px 4px 7px 8px",
          display: "flex",
          alignItems: "center",
          border: "1px solid #E4E8EE",
          borderRadius: "20px",
          columnGap: "2px",
    },
    region_flag: {
          borderRadius: "50%",
          objectFit: "contain",
          height: "18px",
          width: "18px",
    },
    region_menu_list: {
          "& .MuiPopover-paper": {
                maxHeight: "250px",
                minWidth: "200px",
                marginTop: "10px",
                padding: "8px 16px",
                boxShadow: "0 0px 8px -4px #f2f3f5",
                border: "1px solid #f2f3f5",
                borderRadius: "5px",
          },
    },
    selected_region_menu_item: {
          border: "1px solid #5078E1",
          borderRadius: "4px",
          padding: "12px 5px",
          backgroundColor: "#F1F7FF",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
    },
    unselected_region_menu_item: {
          borderRadius: "4px",
          padding: "12px 5px",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
    },
    selected_region_name: {
          fontSize: FontSizeCalc(14),
          fontFamily: SemiBold,
          color: "#5078E1"
    },
    unselected_region_name: {
          fontSize: FontSizeCalc(14),
          fontFamily: Regular,
    },
    version_no: {
          fontSize: FontSizeCalc(12),
          fontFamily: Regular,
          color: "#98A0AC",
    },
    powered_by_logo: {
          height: "10px",
          objectFit: "contain",
    },

}