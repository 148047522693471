import { Box, Container, Grid, Stack, Typography } from "@mui/material"
import { TopNavBar } from "../../components"
import { AssetsStyle } from './style'
import AssetsIcon from "../../assets/assetsPage"
import { useLocation, useNavigate } from "react-router-dom"
import { AppRoutes } from "../../router/routes"

export const Assests = ({
    t = () => false
}) => {

    const navigate = useNavigate();
    const location = useLocation();


    const data = [
        {
            name: t("General"),
            id: 1
        },
        {
            name: t("Amenities"),
            id:2
        },
        {
            name: t("Videos"),
            id:3
        },
        {
            name: t("PDF Docs"),
            id: 4
        },
        {
            name: t("Floor Plan"),
            id: 5
        },
        {
            name: t("360 VR View"),
            id: 6
        },
        {
            name: t("Thumbnails"),
            id: 7
        }
    ]


    const viewImagePage = (data) => {
        navigate(AppRoutes.viewImage, {
            state: {
                data: data,
                id: location?.state,
            }
        })
    }
    return (
        <Container 
        maxWidth="sm"
        sx={AssetsStyle.container}>
            <Box sx={AssetsStyle.sticky}>
               <Container maxWidth="sm" sx={{ padding: '0px!important' }}>
               <TopNavBar isDirect title={t("Assets")} isBack />
               </Container>
            </Box>
            <Box sx={AssetsStyle.root}>
                <Grid spacing={1} container>
                    {data.map((item) => {
                        return (
                            <>
                                <Grid item xs={6}>
                                    <Box onClick={() => viewImagePage(item)} sx={AssetsStyle.assetsBox}>
                                        <Stack alignItems={"center"} direction={"row"}>
                                            <AssetsIcon />
                                            <Typography sx={AssetsStyle.assetstext}>{t(item?.name)}</Typography>
                                        </Stack>
                                    </Box>
                                </Grid></>
                        )
                    })}
                </Grid>
            </Box>
        </Container>
    )
}