import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordianStyles } from "./style"
import { Stack } from '@mui/material';

export const SimpleAccordion = ({
  headText = "",
  content = "",
  tag = "",
  defaultExpanded = false,
  root_style = {},
  tag_style = {},
}) => {
  return (
    <div>
      <Accordion sx={{ ...AccordianStyles?.root, ...root_style }} defaultExpanded={defaultExpanded}>
        {/* Accordian Heading */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <Typography sx={AccordianStyles?.headText}>{headText}</Typography>
        </Stack>

      </AccordionSummary>
      {/* Accordian Content */}
      <AccordionDetails>
        <Typography>
          {content}
        </Typography>
      </AccordionDetails>
    </Accordion>

    </div >
  );
}