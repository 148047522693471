import { Box, Container, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FooterComponent, FormGenerator, TopNavBar } from "../../components";
import { AppRoutes } from "../../router/routes";
import { AddressEditStyle } from './style'

const initial = {
    street_1: "",
    street_2: "",
    street_3: "",
    district: "",
    state: "",
    country: "",
    zipcode: "",
    error: {
        street_1: "",
        street_2: "",
        street_3: "",
        district: "",
        state: "",
        country: "",
        zipcode: ""
    }


}
export const AddressEdit = ({
    t = () => false
}) => {


    let location = useLocation();
    let navigate = useNavigate();

    const [data, setData] = useState({ ...initial })

    // updateState
    const updateState = (k, v) => {
        let error = data?.error;
        error[k] = "";
        setData({ ...data, [k]: v, error });
    };


    // save btn
    const save = () => {
        setData({ ...initial })
        navigate(AppRoutes.profileEdit, { state: data })
    }

    const editFunctions = () => {
        if (location?.state) {
            const editData = location?.state
            setData({
                ...editData,
                id: editData?.id,
                street_1: editData?.street_1,
                street_2: editData?.street_2,
                street_3: editData?.street_3,
                district: editData?.district,
                state: editData?.state,
                country: editData?.country,
                zipcode: editData?.zipcode,
                is_address: true,
                error: {
                    street_1: "",
                    street_2: "",
                    street_3: "",
                    district: "",
                    state: "",
                    country: "",
                    zipcode: "",
                }
            })

        }

    }

    useEffect(() => {
        editFunctions()
        // eslint-disable-next-line
    }, [location])



    return (
        <Container
            sx={AddressEditStyle.container}
            maxWidth="sm"
        >

            <Box sx={{
                padding: '3.5rem 0rem 5.3rem', // Default style
                '@media screen and (min-width: 600px)': {
                    paddingTop: '4rem 0rem 5.3rem', // Media query style for `md` breakpoint
                },
            }}>
                <Box sx={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 999 }}>
                    <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
                        <TopNavBar title={t("Map")} isDirect isBack t={t} />            </Container>
                </Box>
                <Box sx={AddressEditStyle.root}>
                <Typography sx={AddressEditStyle.title}>{t("Address")}</Typography>

                <FormGenerator
                    components={[
                        {
                            isActive: true,
                            component: "text",
                            label: t("Address Line 1"),
                            value: data?.street_1,
                            placeholder: t("Address Line 1"),
                            onChange: (e) => updateState("street_1", e?.target?.value),
                            error: data?.error?.street_1,
                            size: {
                                xs: 12,
                            },
                        },
                        {
                            isActive: true,
                            component: "text",
                            label: t("Address Line 2"),
                            value: data?.street_2,
                            placeholder: t("Address Line 2"),
                            onChange: (e) => updateState("street_2", e?.target?.value),
                            error: data?.error?.street_2,
                            size: {
                                xs: 12,
                            },
                        },
                        {
                            isActive: true,
                            component: "text",
                            label: t("Address Line 3"),
                            value: data?.street_3,
                            placeholder: t("Address Line 3"),
                            onChange: (e) => updateState("street_3", e?.target?.value),
                            error: data?.error?.street_3,
                            size: {
                                xs: 12,
                            },
                        },
                        {
                            isActive: true,
                            component: "text",
                            label: t("City"),
                            value: data?.district,
                            placeholder: t("City"),
                            onChange: (e) => updateState("district", e?.target?.value),
                            error: data?.error?.district,
                            size: {
                                xs: 6,
                            },
                        },
                        {
                            isActive: true,
                            component: "text",
                            label: t("State"),
                            value: data?.state,
                            placeholder: t("State"),
                            onChange: (e) => updateState("state", e?.target?.value),
                            error: data?.error?.state,
                            size: {
                                xs: 6,
                            },
                        },

                        {
                            isActive: true,
                            component: "text",
                            label: t("Country"),
                            value: data?.country,
                            placeholder: t("Country"),
                            onChange: (e) => updateState("country", e?.target?.value),
                            error: data?.error?.country,
                            size: {
                                xs: 6,
                            },
                        },
                        {
                            isActive: true,
                            component: "text",
                            label: t("Pincode"),
                            value: data?.zipcode,
                            placeholder: t("Pincode"),
                            onChange: (e) => updateState("zipcode", e?.target?.value),
                            error: data?.error?.zipcode,
                            size: {
                                xs: 6,
                            },
                        },
                    ]}

                />

            </Box>
          
            <Box sx={{position:"fixed" , bottom:0 , left:0 , right:0 ,zIndex:999}}>
            <Container maxWidth="sm" sx={{padding:"0px !important"}}>
            <FooterComponent
                    containedonClick={() => save()}
                    containedText={t("Save & Update")}
                    single={true}
                    btn_styles={{
                        marginInlineStart:"0px !important"
                    }}

                />
            </Container>
         </Box>
            </Box>
          

        </Container>

    );
};
