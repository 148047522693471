import React from "react"
import { Box } from "@mui/material"
import { Container } from "@mui/system"
import { useLocation, useNavigate } from "react-router"
import { TopNavBar } from "../../components"
import {
    useLazyGetContractDetailsQuery,
    useLazyGetContractInvoicesQuery, useLazyGetContractUnitsQuery
} from "../../redux/services"
import { ContractDetailsStyle } from "../contractDetails/style"
import Details from "./details"
import { UnitTabs } from "./unitTabs"
import { AppRoutes } from "../../router/routes"
import { ContractSimmer } from "./contractSimmer"
import { errorRoute } from "../../utils"

export const ContractDetails = ({ t = () => false }) => {

    const navigate = useNavigate()
    const location = useLocation()

    const [viewData, setViewData] = React.useState({
        details: {},
        simmer: true
    })

    const [contractUnitsDetails, setContractUnitsDetails] = React.useState({
        simmer: true,
        data: {}
    })
    const [contractInvoicesDetails, setContractInvoicesDetails] = React.useState({
        simmer: true,
        data: {}
    })


    const [contractDetailsAPI , {error}] = useLazyGetContractDetailsQuery()
    const [contractUnitsAPI , {error:isError}] = useLazyGetContractUnitsQuery()
    const [contractInvoicesAPI , {error:isError1}] = useLazyGetContractInvoicesQuery()

    const id = location?.state

    const goBack = () => {
        navigate(AppRoutes?.contractDetails)
    }
    const contractDetails = async (payload) => {
        const res = await contractDetailsAPI(payload)
        setViewData({
            details: res?.data?.data,
            simmer: false
        })
    }

    const contractUnits = async (payload) => {
        const unitRes = await contractUnitsAPI(payload)
        setContractUnitsDetails({
            ...contractUnitsDetails,
            data: unitRes?.data?.data ?? [],
            simmer: false
        })
    }

    const contractInvoices = async (payload) => {
        const InovicesRes = await contractInvoicesAPI(payload)
        setContractInvoicesDetails({
            ...contractInvoicesDetails, data: InovicesRes?.data?.data ?? [],
            simmer: false

        })
    }

    React.useEffect(() => {
        contractDetails({
            id: id
        })
        contractInvoices({
            id: id
        })
        contractUnits({
            id: id
        })

        // eslint-disable-next-line
    }, [id])


    React.useEffect(()=>{
        if(error?.status === 423|| isError?.status === 423 || isError1?.status === 423) return errorRoute() 
     },[error , isError , isError1])

    return (
        <Box sx={{
            paddingTop: '3.5rem', // Default style
            '@media screen and (min-width: 600px)': {
              paddingTop: '4rem', // Media query style for `md` breakpoint
            },
          }}>
            <Container
                maxWidth="sm" sx={{padding:"0px !important"}}>
               <Box  sx={ContractDetailsStyle?.sticky}>
                <Container maxWidth="sm" sx={{padding:"0px !important"}}>
                    <TopNavBar isDirect title="CNT-123456" isBack backFuction={goBack} />
                </Container>
               </Box>
                <Box sx={ContractDetailsStyle?.main}>
                    {
                        viewData?.simmer ?
                            <Box sx={ContractDetailsStyle?.simmer}>
                                <ContractSimmer />
                            </Box>
                            :
                            <Details
                                contractor={viewData?.details?.name ?? "-"}
                                account_no={viewData?.details?.account_no ?? "-"}
                                start_date={viewData?.details?.start_date}
                                end_date={viewData?.details?.end_date}
                                contract_no={viewData?.details?.contract_no ?? "-"}
                                property_count={viewData?.details?.property_count ??"-"}
                                unit_count={viewData?.details?.unit_count??"-"}
                                logo={viewData?.details?.logo ? viewData?.details?.logo : "/images/contractAvatar.svg"}
                                commission={viewData?.details?.commission}
                                currency_code={viewData?.currency_code}
                            />
                    }

                    <UnitTabs
                        contractUnits={contractUnitsDetails}
                        contractInvoices={contractInvoicesDetails}
                        invoiceSimmer={contractInvoicesDetails?.simmer}
                        unitSimmer={contractUnitsDetails?.simmer}
                        t={t} />

                </Box>
            </Container >
        </Box>

    )
}