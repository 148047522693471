import { Bold, SemiBold } from "../../utils";


export const LeaseCard_Style = {
    docs: {
        width: '44px',
        height: '44px',
        backgroundColor: 'backgroundcard.avatarbg',
        border: '1px solid #E4EDFF',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        fontFamily: SemiBold,
        fontSize: "0.875rem",
        textTransform: 'captialize',
        color: "border.primary",
        textAlign: "-webkit-auto"
    },
    sale: {
        fontFamily: Bold,
        fontSize: "0.75rem",
        textTransform: 'uppercase',
        color: 'text.light2'
    },
    code: {
        fontFamily: Bold,
        fontSize: "0.75rem",
        letterSpacing: '-0.1px'
    },
    saleBg: {
        backgroundColor: '#F1E6FE',
        borderRadius: '4px',
        padding: '2px 6px',
    },
    codebg: {
        backgroundColor: '#F1F7FF',
        borderRadius: '4px',
        marginInlineStart: '15px',
        padding: '2px 8px'
    }
}