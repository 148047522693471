import * as React from "react"

const GetSupportIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 109113">
      <path data-name="Rectangle 56374" fill="none" d="M0 0h24v24H0z" />
      <path
        d="M6.79 2a2.907 2.907 0 0 0-2.895 2.895v11.579a2.907 2.907 0 0 0 2.895 2.895h8.421a2.907 2.907 0 0 0 2.895-2.895V4.895A2.907 2.907 0 0 0 15.211 2Zm0 1.579h8.421a1.3 1.3 0 0 1 1.316 1.316v11.579a1.3 1.3 0 0 1-1.316 1.316H6.79a1.3 1.3 0 0 1-1.316-1.316V4.895A1.3 1.3 0 0 1 6.79 3.579Zm12.368 1.053V17a3.421 3.421 0 0 1-3.421 3.421h-9.21l.428.641A2.1 2.1 0 0 0 8.706 22h7.031a5 5 0 0 0 5-5V6.811a2.105 2.105 0 0 0-.942-1.752ZM11 5.158a2.907 2.907 0 0 0-2.895 2.895v.233a.79.79 0 1 0 1.579 0v-.233a1.316 1.316 0 1 1 2.632 0 1.636 1.636 0 0 1-.206.949 2.13 2.13 0 0 1-.537.507 3.447 3.447 0 0 0-.915.931 3.406 3.406 0 0 0-.448 1.824.79.79 0 1 0 1.579 0 1.751 1.751 0 0 1 .21-.989 2.033 2.033 0 0 1 .533-.511 3.483 3.483 0 0 0 .911-.913 3.3 3.3 0 0 0 .452-1.8A2.907 2.907 0 0 0 11 5.158Zm0 8.947a1.053 1.053 0 1 0 1.053 1.053A1.053 1.053 0 0 0 11 14.105Z"
        fill="#f15a29"
      />
    </g>
  </svg>
)

export default GetSupportIcon
