import React from "react";
import { withTranslation } from 'react-i18next';
import { CreateContact } from "./createcontact";

class CreateContactParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return <CreateContact  {...this?.props} />;
    }
}

export default withTranslation('contacts')(CreateContactParent);
