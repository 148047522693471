import * as React from "react"

export const Completed = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <g data-name="Group 112106" transform="translate(0 -89)">
            <circle
                data-name="Ellipse 39517"
                cx={20}
                cy={20}
                r={20}
                transform="translate(0 89)"
                fill="#f2f4f7"
            />
            <g data-name="Group 108779">
                <circle
                    data-name="Ellipse 39511"
                    cx={12}
                    cy={12}
                    r={12}
                    transform="translate(8 97)"
                    fill="#F15A29"
                />
                <path
                    data-name="Path 953"
                    d="m14.503 108.848 3.712 3.711 7.056-7.059"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.75}
                />
            </g>
        </g>
    </svg>
)
