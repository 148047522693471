import { SemiBold } from "../../utils";

export const FilemanagerStyle = {
    root: {
        margin: "16px"
    },
    boxShadow: {
        boxShadow: "none !important"
    },
    girdTwo: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",

    },
    girdOne: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        "& p": {
            fontFamily: SemiBold,
            color: "text.Teritary"
        }
    },
    folderDiv: {
        margin: "16px",
        alignItems: "center"
    },
    gridItems: {
        marginTop: "10px"
    },
    listContainer: {
        marginTop: "16px",
        overflow: "auto",
        height: {
            sm: "calc(100vh - 180px)",
            xs: "calc(100vh - 180px)"
        },
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    child: {
        backgroundColor: "primary.contrastText",
        padding: " 0 16px",
    },
    container: {
        backgroundColor: "background.Teritary",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        position: "relative",
        height: "100%",
    },
    Topnav: {
        position: 'fixed',
        top: 0,
        zIndex: 999,
        left:0,
        right:0,
        backgroundColor:"#F8F8F8"
    },
}