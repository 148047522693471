import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: config.api_url,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const kycAPI = createApi({
  reducerPath: "kycAPI",
  baseQuery: baseQueryWithRetry,
  tagTypes: ["Kyc"],
  endpoints: (builder) => ({
    // Get Kyc Listing API
    getKycListing: builder.query(
      {
        query: (payload) => {
          return {
            url: `kyc/list_resident_kyc`, method: "POST", body: payload
          }
        },

        transformResponse: (response, meta, arg) => {
          if (response.type === "success") {
            return { data: response.data, totalCount: response.totalRecords };
          }
          return { data: [], totalCount: 0 };
        },
      }),

    // get Kyc Creation
    getKycCreation: builder.query({
      query: (payload) => ({
        url: `kyc/create_resident_kyc`,
        method: "POST",

        body: payload
      }),
      transformResponse: (response, meta, arg) => {
        if (response?.type === "success") {
          return response;
        }
        return [];
      },

    }),
    // get Kyc Country

    getKycCountry: builder.query({
      query: (payload) => ({

        url: `country/listing_country`,
        method: "POST",
        body: payload
      }),
      transformResponse: (response, meta, arg) => {
        if (response?.type === "success") {
          return response?.data;
        }
        return [];
      },

    }),

    // kyc identiication API
    getKycIdentification: builder.query(
      {
        query: (payload) => {
          return {
            url: `identification/identification`, method: "POST", body: payload
          }
        },

        transformResponse: (response, meta, arg) => {
          if (response.type === "success") {
            return { data: response.data, totalCount: response.totalRecords };
          }
          return { data: [], totalCount: 0 };
        },
      }),


  }),
});

// Export hooks for usage in functional components
export const {
  useLazyGetKycListingQuery,
  useLazyGetKycCreationQuery,
  useLazyGetKycIdentificationQuery,
  useLazyGetKycCountryQuery,

} = kycAPI;
