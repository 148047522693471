import * as React from "react"
const Statistics = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 7567">
      <path
        fill="none"
        d="M12.986 4a.614.614 0 0 0-.385.131L6.774 8.726a3.059 3.059 0 0 0-1.173 2.417v7.832A1.027 1.027 0 0 0 6.627 20h12.718a1.027 1.027 0 0 0 1.026-1.026v-7.831a3.059 3.059 0 0 0-1.17-2.417l-5.835-4.595a.614.614 0 0 0-.38-.131Zm-.371 4.923h.686a1.917 1.917 0 0 1 1.882 1.51l.359 1.685a3.489 3.489 0 1 1-5.168 0l.356-1.675a1.922 1.922 0 0 1 1.885-1.52Zm0 .821a1.113 1.113 0 0 0-1.081.868l-.4 1.9c0 .015-.007.016 0 .01a2.663 2.663 0 0 0 1.83 4.6 2.663 2.663 0 0 0 1.831-4.6l-.405-1.906a1.114 1.114 0 0 0-1.089-.872h-.687ZM8.678 11.8a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm3.862 0h.837l.209.984a1.245 1.245 0 0 0 .357.635 1.436 1.436 0 1 1-1.97 0 1.248 1.248 0 0 0 .357-.636Zm4.753 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm-9.436 2.047a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm10.256 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .616-.615ZM8.678 15.9a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm8.615 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Z"
        data-name="icons8-submit-idea (1)"
      />
      <path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 49471" />
      <path
        fill={props?.color ?? "#98a0ac"}
        d="M19.181 2a2.558 2.558 0 0 0-1.921.819 2.612 2.612 0 0 0-.643 1.745 2.746 2.746 0 0 0 .155.913L13.429 8.1a2.487 2.487 0 0 0-3.164.226l-2.969-1.7a2.811 2.811 0 0 0 .09-.529 2.612 2.612 0 0 0-.641-1.74 2.558 2.558 0 0 0-1.921-.819 2.558 2.558 0 0 0-1.921.819 2.688 2.688 0 0 0 0 3.489 2.6 2.6 0 0 0 3.656.133l2.969 1.7a2.81 2.81 0 0 0-.09.529 2.612 2.612 0 0 0 .643 1.745 2.661 2.661 0 0 0 3.842 0 2.612 2.612 0 0 0 .642-1.753 2.746 2.746 0 0 0-.155-.913l3.343-2.628a2.559 2.559 0 0 0 3.348-.355 2.688 2.688 0 0 0 0-3.489A2.558 2.558 0 0 0 19.181 2Zm0 1.538a.923.923 0 0 1 .771.3 1.155 1.155 0 0 1 0 1.446.923.923 0 0 1-.771.3.9.9 0 0 1-.724-.283.769.769 0 0 0-.086-.13 1.093 1.093 0 0 1-.215-.612 1.107 1.107 0 0 1 .254-.723.923.923 0 0 1 .771-.298ZM4.824 5.076a.923.923 0 0 1 .771.3 1.155 1.155 0 0 1 0 1.446.923.923 0 0 1-.771.3.923.923 0 0 1-.771-.3 1.155 1.155 0 0 1 0-1.446.923.923 0 0 1 .771-.3Zm7.178 4.1a.9.9 0 0 1 .724.283.769.769 0 0 0 .086.13 1.093 1.093 0 0 1 .215.612 1.107 1.107 0 0 1-.254.723.923.923 0 0 1-.771.3.923.923 0 0 1-.771-.3 1.155 1.155 0 0 1 0-1.446.923.923 0 0 1 .771-.3Zm7.175 0a2.578 2.578 0 0 0-2.566 2.567v7.688a2.566 2.566 0 1 0 5.132 0v-7.686a2.579 2.579 0 0 0-2.565-2.567Zm0 1.538a1.017 1.017 0 0 1 1.029 1.029v7.688a1.028 1.028 0 1 1-2.056 0v-7.686a1.016 1.016 0 0 1 1.028-1.028Zm-14.357 0a2.577 2.577 0 0 0-2.565 2.571v6.148a2.566 2.566 0 1 0 5.132 0v-6.148a2.578 2.578 0 0 0-2.566-2.565Zm0 1.538a1.016 1.016 0 0 1 1.028 1.027v6.148a1.028 1.028 0 1 1-2.056 0v-6.142a1.015 1.015 0 0 1 1.029-1.027Zm7.181 2.561a2.579 2.579 0 0 0-2.567 2.567v2.048a2.567 2.567 0 1 0 5.133 0v-2.043a2.579 2.579 0 0 0-2.565-2.566Zm0 1.538a1.017 1.017 0 0 1 1.029 1.029v2.048a1.029 1.029 0 1 1-2.057 0v-2.043a1.017 1.017 0 0 1 1.029-1.028Z"
        data-name="icons8-combo-chart (1)"
      />
    </g>
  </svg>
)
export default Statistics
