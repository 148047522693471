
import { VehicleStyle } from './style'
import { Box, Container, InputAdornment, IconButton, Fab } from "@mui/material";
import React from "react";
import { LoderSimmer, TextBox, TopNavBar, UseDebounce } from '../../components';
import { SerachIMG } from '../../assets';
import { VehicleCard } from './vehicleCard';
import { useLazyGetAllOwnerQuery, useLazyGetOwnerQuery } from '../../redux/services';
import AddIcon from '@mui/icons-material/Add';
import { AppRoutes } from '../../router/routes';
import { useNavigate } from 'react-router';
import { errorRoute } from '../../utils';
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../useDimension";


export const Vehicle = ({ t = () => false }) => {
    const navigate = useNavigate()
    const debounce = UseDebounce()
    const size = useWindowDimensions();
    const [offSet, setOffSet] = React.useState(0);

    const [GetAllOwnerUnit , {error}] = useLazyGetAllOwnerQuery();
    const [GetOwnerUnit , {error:isError}] = useLazyGetOwnerQuery();

    const [state, setState] = React.useState({
        data: [],
        viewData: {},
        isEdit: false,
        offset: 0,
        boolean: true,
        search: ""
    })

    const GetAllOwnerUnitAPI = async (data , isLoad) => {
        if(!isLoad){
            setState({
                ...state,
                boolean: true
            })
        }
        const Res = await GetAllOwnerUnit(data)
        setState({
            ...state,
            search: data?.search,
            data: Res?.data?.data?.data,
            boolean: false
        })
    }
    const GetOwnerUnitAPI = async (data) => {
        const viewRes = await GetOwnerUnit(data)
        const response = viewRes?.data?.data
        navigate(AppRoutes?.vehicleView, { state: response })
    }

    React.useEffect(() => {
        GetAllOwnerUnitAPI({
            offset: 0,
            limit: 10,
            search: state?.search ?? ""
        })
        // eslint-disable-next-line
    }, [])

    const handleRoute = (e) => {
        GetOwnerUnitAPI({ id: e?.id })
    }

    const createIcon = () => {
        navigate(AppRoutes?.addVehicle)
    }

    const updateState = (key, value) => {
        if (key === "search") {
            setState({ ...state, [key]: value });
            debounce(
                async () => {
                    await GetAllOwnerUnitAPI({
                        offset: offSet,
                        limit: 10,
                        search: value ?? ""
                    })
                },
                800
            );
        } else {
            setState({ ...state, [key]: value });
        }
    }

    const handleBack = () => {
        navigate(AppRoutes?.home)
    }

    const fetchMoreData = () => {
         GetAllOwnerUnitAPI({
            offset: offSet + 10,
            limit: 10,
            search: state?.search ?? ""
        }, true)
        setOffSet(offSet + 10);
      }
    

    React.useEffect(()=>{
        if(error?.status === 423|| isError?.status === 423) return errorRoute() 
     },[error , isError])

    return (
        <Box sx={VehicleStyle?.parentBox}>
            <Container
                maxWidth="sm"
                sx={{padding:"0px !important"}}>
              <Box sx={{
                    paddingTop: '6.8rem', // Default style
                    '@media screen and (min-width: 600px)': {
                        paddingTop: '7.4rem', // Media query style for `md` breakpoint
                    },
                }}>
              <Box sx={VehicleStyle?.Topnav}>
                    <Container maxWidth="sm"
                sx={{padding:"0px !important"}}>
                    <TopNavBar isDirect title={t("Vehicles")} isBack={false} route={() => handleBack()} position="absolute" />
                    <Box sx={VehicleStyle?.search}>
                        <TextBox
                            paddingRight
                            sx={VehicleStyle?.boxShadow}
                            placeholder={t("Search")}
                            onChange={(e) =>
                                updateState("search", e?.target?.value)}
                            value={state?.search ?? ""}
                            startAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                        <SerachIMG />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Box>
                    </Container>
                </Box>
                <Box >
                   
                    <Box sx={VehicleStyle?.subRoot}>
                    <InfiniteScroll
                dataLength={state?.data?.length ?? 0}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - 160}
                
              >
                <Box>
                {
                            state?.data?.map((e, i) => {
                                return <Box>
                                    {
                                        state?.boolean ?
                                            <LoderSimmer card count={10} size={100} variant={"circled"} animation={"wave"} /> :
                                            <VehicleCard handleRoute={() => handleRoute(e)} data={e} key={i} />
                                    }
                                </Box>
                            })
                        }
                </Box>
                </InfiniteScroll>

                       
                    </Box>
                    <Box sx={VehicleStyle?.AddIcon}>
                        <Container maxWidth="sm" style={{ padding: "0px !important" }}>
                            <Fab onClick={createIcon} sx={VehicleStyle?.AddIconChild}>
                                <AddIcon />
                            </Fab>
                        </Container>
                    </Box>
                </Box>
              </Box>
            </Container>
        </Box>

    )
}

