

export const Contacts_style = {
    sx: {
        backgroundColor: "#F6F6F6",
        left:0,
        right:0
    },
    selectedTab: {
        backgroundColor: "#F6F6F6",
        padding: "16px",
        height: "100%",
        overflow: "auto"
    }
}
export const MyContact_style = {
    sx:{
        padding:'12px',
        width:'auto',
        backgroundColor:'#fff',
        borderRadius:"4px"
    },
    AddIcon: {
        position: "fixed",
        bottom: "0",
        cursor: "pointer",
        zIndex: "300",
        right: 0,
        width: "100%",
        display: "flex"
    },
    AddIconContainer: {
        float: "right",
    },
    AddIconChild: {
        float: "right",
        bottom: "60px",
        right: "40px",
        cursor: "pointer",
        zIndex: "300",
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        "&:hover": {
            backgroundColor: "primary.main",
            color: "primary.contrastText",
        }
    },
    reqTop: {
        position: "sticky"
    },
    reqCard: {
        overflow: "auto",
        height: "calc(100vh - 271px)",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    infiniteScroll: {
        backgroundColor: "none !important",
        "&::-webkit-scrollbar": {
            display: "none !important",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "4px",
            padding: "0px",
        },
    },
    filterDrawer: {
        "& .MuiDrawer-paper": {
            width: "-webkit-fill-available",
        },
    },
    filterIcon: {
        cursor: "pointer"
    },
    infiniteScroll1: {
        backgroundColor: "none !important",
        "&::-webkit-scrollbar": {
            display: "none !important",
        },
    },
};
