import React from "react";
import { ProfileView } from "./profileView";
import { withTranslation } from 'react-i18next';

class ProfileViewParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <ProfileView {...this?.props}/>;
  }
}

export default withTranslation('profile')(ProfileViewParent);
