import { Bold, FontSizeCalc, Regular } from "../../utils";

export const useStyles = {
  root: {
    padding: "0px",
  },
  formBox: {
    padding: "16px",
    overflow: "overlay",
  },
  form: {
    padding: "12px",
    borderRadius: "12px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    backgroundColor: "#FFFFFF",
  },
  formMiniCard: {
    backgroundColor: "#F5F7FA",
    borderRadius: "8px",
    padding: "12px",
  },
  surveyIcon: {
    width: "48px",
    height: "48px",
  },
  formMiniCardTitle: {
    fontSize: FontSizeCalc(14),
    fontFamily: Bold,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#091B29"
  },
  formMiniCardFormNo: {
    fontSize: FontSizeCalc(12),
    fontFamily: Regular,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#4E5A6B"
  },
  submitButtonBox: {
    padding: "16px",
    backgroundColor: "#FFFFFF",
  },
  submitButton: {
    padding: "12px 16px",
    borderRadius: "8px",
    boxShadow: "none",
    backgroundColor: "#F15A29",
    "&:hover": {
      backgroundColor: "#F15A29",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  submitButtonText: {
    color: "#FFFFFF",
    fontFamily: Bold,
    fontSize: FontSizeCalc(14),
    textTransform: "capitalize",
  },
}