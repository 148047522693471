import useTheme from "@mui/material/styles/useTheme";
import { SemiBold } from "../../utils";

export const ToggleBlockStyle = (props) => {
    const theme = useTheme();
    return {
        selected: {
            backgroundColor: props?.backgroundColor ?? theme?.palette?.primary?.main,
            fontFamily: props?.fontFamily ?? SemiBold,
            fontSize: props?.fontSize ?? "0.875rem",
            borderRadius: props?.borderRadius ?? theme?.palette?.borderRadius,
            border: props?.border ?? `1px solid ${theme?.palette?.primary.main}`,
            padding: props?.padding ?? "15px 12px",
            color: props?.color ?? theme?.palette?.error?.contrastText,
            boxShadow: "none !important",
            textTransform: 'capitalize',
            '& p': {
                color: props?.color ?? theme?.palette?.error?.contrastText,
                fontFamily: props?.fontFamily ?? SemiBold,
                fontSize: props?.fontSize ?? "0.875rem",
            },
            "&:hover": {
                color: props?.color ?? theme?.palette?.error?.contrastText,
                border: `1px solid ${theme?.palette?.primary?.main}`,
                backgroundColor: theme?.palette?.primary?.main,
                padding: props?.padding ?? "15px 12px",

            },
        },
        unselected: {
            backgroundColor: props?.backgroundColor ?? theme?.palette?.error?.contrastText,
            fontFamily: props?.fontFamily ?? SemiBold,
            fontSize: props?.fontSize ?? "0.875rem",
            borderRadius: props?.borderRadius ?? theme?.palette?.borderRadius,
            border: props?.border ?? `1px solid ${theme?.palette?.border?.secondary}`,
            padding: props?.padding ?? "15px 12px",
            color: props?.color ?? theme?.palette?.text?.main,
            boxShadow: "none !important",
            textTransform: 'capitalize',
            '& p': {
                color: props?.color ?? theme?.palette?.text?.main,
                fontFamily: props?.fontFamily ?? SemiBold,
                fontSize: props?.fontSize ?? "0.875rem",
            },
            "&:hover": {
                color: props?.color ?? theme?.palette?.text?.main,
                backgroundColor: props?.backgroundColor ?? theme?.palette?.error?.contrastText,

            },
        },
        parent: {
            backgroundColor: props?.backgroundColorunparent ?? theme?.palette?.error?.contrastText,
            borderRadius: props?.borderRadius ?? theme?.palette?.borderRadius,
        }
    }
};
