import { Avatar, Box, Container, Stack, Typography } from "@mui/material"
import { contractHistoryStyle } from "./style";
import { TopNavBar } from "../../components";

export const ContractHistory = ({
    approved = "",
    declined = ""
}) => {
    return (
        <Container
            maxWidth="sm"
            sx={contractHistoryStyle.container}
        >
            <Box sx={{ position: "fixed", top: 0, right: 0, left: 0, zIndex: 999 }}>
                <Container maxWidth="sm"
                    sx={{ padding: '0px !important' }}>
                    <TopNavBar isDirect title={"Contract History"} isBack />
                </Container>
            </Box>
            <Box sx={contractHistoryStyle.contractBox}>
                <Stack direction={"row"}>
                    <Avatar variant="rounded">H</Avatar>
                    <Stack spacing={"4px"} marginInlineStart={"12px"} flexGrow={1}>
                        <Typography sx={contractHistoryStyle.contractText}>Unit Name</Typography>
                        <Typography sx={contractHistoryStyle.contractSubtext}>Unit Address</Typography>
                    </Stack>
                    <Typography sx={contractHistoryStyle.waiting}>Waiting for Approval</Typography>
                    {approved && <Typography sx={contractHistoryStyle.approved}>Approved</Typography>}
                    {declined && <Typography sx={contractHistoryStyle.declined}>Declined</Typography>}
                </Stack>
            </Box>
        </Container>
    )
};