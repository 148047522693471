import React from "react";
import { withTranslation } from 'react-i18next';
import { AddOnsDetailedService } from "./addOnsDetailedService";

class AddOnsDetailedParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <AddOnsDetailedService {...this?.props}/>;
  }
}

export default withTranslation('addons')(AddOnsDetailedParent);
