import { Box, Container } from "@mui/material"
import { TopNavBar } from "../../components"
import { Investment } from "../statistics/investment"
import { Investment_style } from './style'
export const InvestmentScreen = ({ t = () => false }) => {
    return (
        <Box sx={Investment_style?.parentBox}>
            <Container sx={Investment_style?.container} maxWidth="sm">
                <Box sx={Investment_style?.Topnav}>
                    <TopNavBar title={t("Investments")} isDirect isBack t={t} position={'absolute'} />
                </Box>
                <Box sx={Investment_style?.paddings}>
                    <Investment t={t} bottomSx={{ bottom: "24px" }} />
                </Box>

            </Container>
        </Box>
    )
}