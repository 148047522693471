import { Bicycle, FourWheel, TwoWheel } from "../../assets";

export const VehicleTypeList =(t=()=>false)=>[
    {
        type: "Four Wheelers",
        value: "FOUR-WHEELER",
        label:t("Four Wheeler"),
        icon: (<FourWheel />),
        selected_icon: (<FourWheel color="#F15A29" />)
    },
    {
        type: "Two Wheelers",
        value: "TWO-WHEELER",
        label:t("Two Wheeler"),

        icon: (<TwoWheel />),
        selected_icon: (<TwoWheel color="#F15A29" />)
    },
    {
        type: "Bicycle",
        value: "BI-CYCLE",
        label:t("Bi Cycle"),
        icon: (<Bicycle />),
        selected_icon: (<Bicycle color="#F15A29" />)
    },
]