import * as React from "react"

export const Inprogress = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Group 112105"
        width={40}
        height={40}
        {...props}
    >
        <circle data-name="Ellipse 39519" cx={20} cy={20} r={20} fill="#f2f4f7" />
        <g data-name="Group 112104">
            <circle
                data-name="Ellipse 39518"
                cx={12}
                cy={12}
                r={12}
                transform="translate(8 8)"
                fill="#F15A29"
            />
            <path
                d="M20.5 13.5a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7Zm0 1.05a5.95 5.95 0 1 1-5.95 5.95 5.942 5.942 0 0 1 5.95-5.95Zm-.533 1.742a.525.525 0 0 0-.517.533v3.85a.525.525 0 0 0 .154.371l1.75 1.75a.525.525 0 1 0 .742-.742l-1.6-1.6v-3.629a.525.525 0 0 0-.533-.533Z"
                fill="#fff"
                stroke="#fff"
                strokeWidth={0.5}
            />
        </g>
    </svg>
)
