import { Typography } from "@mui/material"
import { Docs } from "../../assets"
import { LeaseCard_Style } from "./style"
import { Box } from "@mui/material"
export const LeaseCard = ({
    title = "",
    t = () => false,
    tag = "",
    sub_tag = ""
}) => {
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Box sx={LeaseCard_Style.docs}>
                    <Docs />
                </Box>
                <Box sx={{ marginInlineStart: '8px' }}>
                    <Typography sx={LeaseCard_Style?.title}>{title}</Typography>
                    <Box sx={{ display: 'flex', marginTop: "3px" }}>
                        <Box sx={LeaseCard_Style?.saleBg}><Typography sx={LeaseCard_Style?.sale}>{tag}</Typography></Box>
                        <Box sx={LeaseCard_Style?.codebg}><Typography sx={LeaseCard_Style?.code}>{sub_tag}</Typography></Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}