import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Pie, PieChart, ResponsiveContainer } from "recharts";
import { Bold, SemiBold } from "../../utils";
import NoData from "../../components/noData";

const useStyles = makeStyles((theme, props) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& svg": {
      borderRadius: "50%",
      width: "100%",
      minWidth: "120px !important",
      height: "158px !important",
      position: "relative",
      [theme.breakpoints.up("sm")]: {
        width: "100% !important",
        margin: "0 auto",
      },
      "& .recharts-wrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "158px !important",
      },
    },

  },
  total: {
    color: "#637381",
    fontSize: "0.75rem",
    FontFamily: SemiBold
  },
  des: {
    fontWeight: 600,
    color: "#091B29",
    fontSize: "1rem",
    FontFamily: Bold,
    marginBottom:"0.95"

  },
  center: {
    textAlign: "center",
    position: "absolute",
    left: "0px",
    right: "0px",
    top: "41%",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      right: "0px",
      top: "41%",
    },
  },
  chart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export const RevenuePieCharts = (props) => {
  const classes = useStyles(props);
  return (
    <>
    {
        props?.data?.length ? 
        <div style={{ position: "relative" }} className={classes.root}>
        <ResponsiveContainer
          width={props?.width}
          height={220}
          style={{ margin: "0 auto" }}
        >
          <PieChart width={props?.width} height={180} className={classes.chart}>
            <Pie
              data={props?.data}
              cx="50%"
              cy="50%"
              outerRadius={100}
              innerRadius={75}
              dataKey="value"
              paddingAngle={4}
              isAnimationActive={false}
            />
            {/* <Tooltip/> */}
          </PieChart>
        </ResponsiveContainer>
        <div className={classes.center}>
          <Typography className={classes.des}>
            {props?.total}
          </Typography>
          <Typography className={classes.total}>{props?.totalText}</Typography>
        </div>
      </div>
        : <Box mt={2} style={{margin:"0 auto"}}><NoData chart={true} width={200} style={{ backgroundColor: "#fff", }} /></Box>

    }
    </>
 
  );
};