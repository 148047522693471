import { Bold, Regular } from '../../utils';

export const CurrencySwitchwer_styles = {
    list: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #E4E8EE",
        padding: '12px',
        cursor: "pointer"
    },
    list2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #E4E8EE",
        cursor: "pointer",
        padding: '12px',

    },
    Tittle: {
        fontSize: "1rem",
        fontFamily: Regular,
        color: "#071741",
    },
    contant: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: "#4E5A6B",
    },
    expires: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: "#4E5A6B",
        backgroundColor: "#F2F4F7",
        padding: "5px",
        marginLeft: "10px",
        borderRadius: 4
    },
    lease: {
        fontSize: "0.75rem",
        color: 'color.secondary',
        fontFamily: Regular,
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    Appartment: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: "#4E5A6B",
    },
    box: {
        display: "flex", marginTop: "5px"
    },
    agreementImg: {
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        padding: "6px 6px 0px 6px"
    }
}