

import * as React from "react"

export const SortView = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.307" height="14" color={props?.color} {...props}>
        <path d="M9.073 0a.568.568 0 0 0-.393.166L6.032 2.815a.568.568 0 1 0 .8.8l1.68-1.68v11.497a.568.568 0 1 0 1.135 0V1.938l1.68 1.68a.568.568 0 1 0 .8-.8L9.483.166A.568.568 0 0 0 9.073 0ZM.946.757a.568.568 0 1 0 0 1.135h1.615L.468 5.182a.567.567 0 0 0 .479.872h2.649a.568.568 0 0 0 0-1.135H1.98l2.094-3.29a.567.567 0 0 0-.479-.872ZM2.27 7.945a.567.567 0 0 0-.525.353l-1.7 4.162a.568.568 0 0 0 1.051.43l.166-.4h2.023l.166.4a.568.568 0 0 0 .526.352.557.557 0 0 0 .214-.042.568.568 0 0 0 .31-.74l-1.7-4.16a.567.567 0 0 0-.53-.355Zm0 2.067.548 1.338h-1.1Z" fill={props?.color}>
        </path>
    </svg>
)
