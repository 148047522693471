import * as React from "react"
const ContractDetails = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 112355" transform="translate(-32 -78)">
      <rect
        width={48}
        height={48}
        fill="#e6f9ff"
        data-name="Rectangle 57270"
        rx={8}
        transform="translate(32 78)"
      />
      <path
        fill="#20adf8"
        d="M49.5 90.943a3.3 3.3 0 0 0-3.3 3.3v1.375a.825.825 0 0 0 .825.825h2.2a3.563 3.563 0 0 1 2.575 1.1h10.075a.825.825 0 1 1 0 1.65H52.7a3.548 3.548 0 0 1 .1.825v.825h3.575a.825.825 0 0 1 0 1.65H52.8v6.32a3.036 3.036 0 0 1-.37 1.448l-1.462 2.682h12.557A2.478 2.478 0 0 0 66 110.468V94.243a3.3 3.3 0 0 0-3.3-3.3Zm0 1.65h10.344a3.275 3.275 0 0 0-.444 1.65v.55H47.85v-.55a1.652 1.652 0 0 1 1.65-1.65Zm-.275 4.95a2.475 2.475 0 0 0-2.475 2.475v8.795a1.93 1.93 0 0 0 .235.922l1.515 2.778a.825.825 0 0 0 1.448 0l1.516-2.78a1.923 1.923 0 0 0 .236-.92v-8.795a2.475 2.475 0 0 0-2.475-2.475Zm11.825 5.5a2.2 2.2 0 1 1-2.2 2.2 2.188 2.188 0 0 1 2.2-2.2Zm-2.2 5.352a3.815 3.815 0 0 0 4.4 0v2.073a.825.825 0 0 1-1.234.716l-.966-.552-.966.552a.825.825 0 0 1-1.234-.716Z"
        data-name="Group 113305"
      />
    </g>
  </svg>
)
export default ContractDetails
