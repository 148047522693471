import { Bold, FontSizeCalc, SemiBold } from "../../../utils";

export const useStyles = {
    label: {
        fontSize: FontSizeCalc(14),
        fontFamily: Bold,
        color: "#404E61",
    },
    radioButtonLabel: {
        fontSize: FontSizeCalc(14),
        fontFamily: SemiBold,
        color: "#404E61",
    },
    radioButton: {
        color: "#404E61",
        [`&.Mui-checked`]: { color: "#F15A29" },
        [`&.Mui-disabled`]: { color: "#A4B2C1" },
    },
    checkBoxLabel: {
        fontSize: FontSizeCalc(14),
        fontFamily: SemiBold,
        color: "#404E61",
    },
    checkBox: {
        color: "#404E61",
        [`&.Mui-checked`]: { color: "#F15A29" },
        [`&.Mui-disabled`]: { color: "#A4B2C1" },
    },
}