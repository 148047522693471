import { Grid, Box, Tab, Tabs } from "@mui/material";
import * as React from "react";
import { Bold, SemiBold } from "../../utils"

const Tabs_Style = {
    root: {},
    tabroot: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center !important",
        color: "primary.contrastText"
    },
    tabItem: {
        width: "48%",
        textTransform: "capitalize",
        fontFamily: Bold,
        fontSize: "0.75rem",
        color: "primary.main",
        padding: "0px",
        minHeight: "auto",
        minWidth: "auto",
        borderRadius: "4px",
        justifyContent:"center !important"

    },
    tabItemSelect: {
        width: "48%",
        textTransform: "capitalize",
        fontFamily: Bold,
        fontSize: "0.75rem",
        color: "primary.contrastText",
        borderRadius: "4px",
        backgroundColor: "primary.main",
        minHeight: "auto",
        padding: '0px !important',
        "& .MuiButtonBase-root-MuiTab-root": {
            minHeight: "0px",

        },
        justifyContent:"center !important",
        minWidth: "auto",
    },
    title: {
        padding: "8px 12px",
        borderRadius: "4px",
        color: "#4E5A6B",
        fontFamily: SemiBold

    },
    titleselect: {
        padding: "8px",
        borderRadius: "4px",
        backgroundColor: "primary.main",
        color: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "space-between",
        fontFamily: SemiBold
    },
    more: {
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        backgroundColor: "background.main",
    },
    parentRoot: {
        backgroundColor: "#fff",
        borderRadius: "8px",
    },
    tab:{
        '& .MuiTabs-flexContainer':{
        justifyContent:"center"
        }
    }
}

export const TabComp = ({
    selectedTab = 0,
    tab1Label = "",
    tab2Label = "",
    onTab1Clicked = () => false,
    onTab2Clicked = () => false,
    tabIcon1 = "",
    tabIcon2 = "",
}) => {

    return (
        <Box sx={Tabs_Style?.parentRoot}>
            <Box sx={Tabs_Style?.root}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {/* Tab Section */}
                    <Grid item xs={12}>
                        <Tabs
                            value={selectedTab}
                            indicatorColor="none"
                            textColor="#fff"
                            sx={Tabs_Style?.tabroot}
                            variant="scrollable"
                            visibleScrollbar={false}
                            scrollButtons={false}
                        >
                            <Tab
                            className={Tabs_Style?.tab}
                                sx={
                                    selectedTab === 0 ? Tabs_Style?.tabItemSelect : Tabs_Style?.tabItem
                                }
                                label={
                                    <span
                                        style={
                                            selectedTab === 0 ? Tabs_Style?.titleselect : Tabs_Style?.title
                                        }
                                    >
                                        {selectedTab === 0 && (
                                            tabIcon1
                                        )}
                                        &nbsp;&nbsp;{tab1Label}
                                    </span>
                                }
                                onClick={onTab1Clicked}
                                style={{justifyContent:"center !important"}}
                            />
                            <Tab
                                sx={
                                    selectedTab === 1 ? Tabs_Style?.tabItemSelect : Tabs_Style?.tabItem
                                }
                                label={
                                    <span
                                        style={
                                            selectedTab === 1 ? Tabs_Style?.titleselect : Tabs_Style?.title
                                        }
                                    >
                                        {selectedTab === 1 && (
                                            tabIcon2
                                        )}
                                        &nbsp;&nbsp;{tab2Label}
                                    </span>
                                }
                                onClick={onTab2Clicked}
                            />
                        </Tabs>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
