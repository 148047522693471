import * as React from "react"

const PrivacyPolicy = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      d="M12.521 2.5a.632.632 0 0 0-.442.162 11.947 11.947 0 0 1-6.947 2.785.632.632 0 0 0-.632.631v5.162c0 2.812 1.3 7.439 7.755 10.157a.632.632 0 0 0 .49 0C19.2 18.679 20.5 14.052 20.5 11.241V6.079a.632.632 0 0 0-.632-.632 11.947 11.947 0 0 1-6.947-2.785.632.632 0 0 0-.4-.162ZM12.5 3.943a12.651 12.651 0 0 0 6.737 2.715v4.583c0 2.418-.929 6.318-6.737 8.87-5.808-2.552-6.737-6.452-6.737-8.87V6.657A12.651 12.651 0 0 0 12.5 3.943Zm0 3.609a4.211 4.211 0 1 0 4.211 4.211A4.212 4.212 0 0 0 12.5 7.552Zm0 1.263a.631.631 0 0 1 .632.632v1.684h1.263a.632.632 0 1 1 0 1.263H12.5a.631.631 0 0 1-.632-.632V9.447a.631.631 0 0 1 .632-.632Z"
      fill="#f15a29"
      stroke="#f15a29"
      strokeLinecap="round"
      strokeWidth={0.2}
      data-name="Group 109044"
    />
  </svg>
)

export default PrivacyPolicy
