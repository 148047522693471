import { Box } from "@mui/material"
import { ContactCard } from "../../components"
import { MyContact_style } from "./style"
import InfiniteScroll from "react-infinite-scroll-component";
import { ActivityCardsimmer } from "../statistics/component/activityCardsimmer";
import NoData from "../../components/noData";
import { AppRoutes } from "../../router/routes";
import { useNavigate } from "react-router-dom";

export const Mycontacts = ({
    // bottomSx = {},
    t = () => false,
    datas=[],
    fetchMoreDataPrivate=()=>false,
    handleChange=()=>false,
    height
}) => {
    const navigate = useNavigate()

    const onEditClickFnc = (value)=>{
        navigate(AppRoutes.createContacts, {state:{value,page:"view"}})

    }

    
    return (
        <Box>
            {datas.bool ?
                <ActivityCardsimmer count={10} />
                :
                datas?.contactListingPrivate?.length > 0 ?
                    <InfiniteScroll
                        dataLength={datas?.contactListingPrivate?.length ?? 0}
                        next={fetchMoreDataPrivate}
                        hasMore={true}
                        style={MyContact_style?.infiniteScroll}
                        height={height}
                    >
                        {datas?.contactListingPrivate?.map((item, index) => {
                            return (
                                <Box sx={MyContact_style?.sx}>
                                    <ContactCard
                                        name={item?.name}
                                        tag={item?.profession}
                                        sub_text={item?.property_name}
                                        sub_text2={item?.unit_name}
                                        contact_name={`${item?.country_code} ${item?.contact_number}`}
                                        hidden={datas?.contactListingPrivate?.length === index + 1 ? false : true}
                                        isShare={item?.is_share}
                                        handleChange={handleChange}
                                        value={datas}
                                        index={index}
                                        item={item}
                                        onEditClick={onEditClickFnc}
                                        t={t}

                                    />
                                </Box>
                            )
                        })}

                    </InfiniteScroll>
                    :
                    <NoData />
            }
        </Box>
    )
}