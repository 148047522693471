import React from "react";
import {Home} from './home';
import { withNavBars } from "./../../HOCs";
import { withTranslation } from 'react-i18next';

class HomeParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <Home {...this?.props}/>;
  }
}
const props = {
  is_dashboard: true
}

export default withNavBars(withTranslation('home')(HomeParent), props);

