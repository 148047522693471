import { Grid, Box, Tab, Tabs, Typography } from "@mui/material";
import { NewTabsComponent_Style } from "./style";
import * as React from "react";

export const NewTabsComponent = ({
  selectedTab = 0,
  tab1Label = "Home",
  tab2Label = "Invoice",
  tab3Label = "Payment",
  onTab1Clicked = () => false,
  onTab2Clicked = () => false,
  onTab3Clicked = () => false,
  tabIcon1 = "",
  tabIcon2 = "",
  tabIcon3 = "",
  is_notshow = false
}) => {

  return (
    <Box sx={NewTabsComponent_Style?.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Tab Section */}
        <Grid item xs={12}>
          <Tabs
            value={selectedTab}
            indicatorColor="none"
            sx={NewTabsComponent_Style?.tabroot}
            variant="scrollable"
            visibleScrollbar={false}
            scrollButtons={false}
          >
            {
              !is_notshow &&
              <Tab
                sx={
                  selectedTab === 0 ? NewTabsComponent_Style?.tabItemSelect : NewTabsComponent_Style?.tabItem
                }
                label={
                  <span
                    style={
                      selectedTab === 0 ? NewTabsComponent_Style?.titleselect : NewTabsComponent_Style?.title
                    }
                  >
                    {selectedTab === 0 && (
                      tabIcon1
                    )}
                    <Typography sx={NewTabsComponent_Style?.label}>{tab1Label}</Typography>
                  </span>
                }
                onClick={onTab1Clicked}
              />
            }
            <Tab
              sx={
                selectedTab === 1 ? NewTabsComponent_Style?.tabItemSelect : NewTabsComponent_Style?.tabItem
              }
              label={
                <span
                  style={
                    selectedTab === 1 ? NewTabsComponent_Style?.titleselect : NewTabsComponent_Style?.title
                  }
                >
                  {selectedTab === 1 && (
                    tabIcon2
                  )}
                  <Typography sx={NewTabsComponent_Style?.label}>{tab2Label}</Typography>
                </span>
              }
              onClick={onTab2Clicked}
            />
            <Tab
              sx={
                selectedTab === 2 ? NewTabsComponent_Style?.tabItemSelect : NewTabsComponent_Style?.tabItem
              }
              label={
                <span
                  style={
                    selectedTab === 2 ? NewTabsComponent_Style?.titleselect : NewTabsComponent_Style?.title
                  }
                >
                  {selectedTab === 2 && (
                    tabIcon3
                  )}
                  <Typography sx={NewTabsComponent_Style?.label}>{tab3Label}</Typography>
                </span>
              }
              onClick={onTab3Clicked}
            />
          </Tabs>
        </Grid>
      </Grid>
    </Box>
  );
};
