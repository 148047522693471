import { RightArrow } from "../assets";
export const Towncard = (t)=>[
  {
    title: t("My"),
    image: <RightArrow />,
    homeImage: '/images/township.png',
    des:t("Township")
  },
  {
    title: t("Buy or Rent"),
    image: <RightArrow />,
    homeImage:'/images/shop.png',
    des:t("Properties")
  },
];
