export const GeneralRequestStyle ={
    root: {
        padding: "16px",
        width: '100%',
        overflow: "auto",
        backgroundColor:'#F8F8F8',
        height: {
          xs:'calc(100vh - 147px)',
          sm:'calc(100vh - 155px)'
        },
        "&::-webkit-scrollbar": {
          display: "none",
        },      
      },
      container:{
        paddingLeft:"0px !important",
        paddingRight:"0px !important",
        position:"relative",
        height:"100vh"
      },
      footer:{
        position:'sticky',
        width:"100%",
        bottom:"0"
  
      }
  
  }
  
  