import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Container, IconButton, Stack, Typography } from "@mui/material";
import { style } from "./style";
import useWindowDimensions from "../../utils/useWindowDimension";

export const TitleBarNew = (props) => {
  const size = useWindowDimensions();

  return (
    <Container style={{ padding: 0}} maxWidth="sm">
      <Stack sx={style({ size, is_back_button: props?.is_back_button }).content} direction={"row"}
        justifyContent={props?.is_back_button ? "space-between" : "center"} alignItems={"center"}>
        {props?.is_back_button &&
          <IconButton size="small" onClick={props?.goBack}>
            <ArrowBackIosIcon sx={style({ size, is_back_button: props?.is_back_button }).arrow} fontSize="small" />
          </IconButton>}
        <Typography sx={style({ size, is_back_button: props?.is_back_button }).title} noWrap>
          {props?.title}
        </Typography>
        {props?.is_back_button && <Box height={"30px"} width={"30px"} />}
      </Stack>
    </Container>
  );
};
