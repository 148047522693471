import * as React from "react"
const ModelNumber = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <defs>
      <linearGradient
        id="a"
        x2={1}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#912fbd" />
        <stop offset={1} stopColor="#9332bf" />
      </linearGradient>
      <linearGradient
        id="b"
        x2={1}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ae4cd5" />
        <stop offset={1} stopColor="#ac4ad5" />
      </linearGradient>
      <linearGradient
        id="c"
        x2={1}
        y1={-12.269}
        y2={-12.269}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fede00" />
        <stop offset={1} stopColor="#ffd000" />
      </linearGradient>
    </defs>
    <g data-name="Group 116870" transform="translate(-49 -896)">
      <rect
        width={24}
        height={24}
        fill="#fff1cf"
        data-name="Rectangle 58383"
        rx={4}
        transform="translate(49 896)"
      />
      <g data-name="icons8-best-seller (1)" transform="translate(46.882 895)">
        <path
          fill="url(#a)"
          d="M27.983 37.186h-2.232l-1.223 1.524a.39.39 0 0 1-.663-.09L21.443 33h5.22l1.675 3.632a.39.39 0 0 1-.355.554Z"
          data-name="Path 101487"
          transform="translate(-8.396 -16.86)"
        />
        <path
          fill="url(#b)"
          d="m17.973 33-2.462 5.626a.39.39 0 0 1-.662.088l-1.225-1.527h-2.233a.39.39 0 0 1-.355-.554L12.711 33Z"
          data-name="Path 101488"
          transform="translate(-2.325 -16.86)"
        />
        <path
          fill="#fdb700"
          d="m14.947 4.217.886.5 1.016.013a1.694 1.694 0 0 1 1.437.83l.519.873.873.519a1.7 1.7 0 0 1 .83 1.437l.013 1.011.5.886a1.7 1.7 0 0 1 0 1.659l-.5.886-.013 1.016a1.694 1.694 0 0 1-.83 1.437l-.878.516-.519.873a1.7 1.7 0 0 1-1.437.83l-1.016.013-.886.5a1.7 1.7 0 0 1-1.659 0l-.886-.5-1.016-.013a1.694 1.694 0 0 1-1.437-.83l-.513-.873-.873-.519a1.7 1.7 0 0 1-.83-1.437l-.013-1.016-.5-.886a1.7 1.7 0 0 1 0-1.659l.5-.886.013-1.016a1.694 1.694 0 0 1 .83-1.437l.873-.519.519-.873a1.7 1.7 0 0 1 1.437-.83l1.013-.008.886-.5a1.694 1.694 0 0 1 1.661.003Z"
          data-name="Path 101489"
        />
        <circle
          cx={5.442}
          cy={5.442}
          r={5.442}
          fill="url(#c)"
          data-name="Ellipse 130926"
          transform="translate(8.675 5.674)"
        />
      </g>
    </g>
  </svg>
)
export default ModelNumber
