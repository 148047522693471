import * as React from "react"
const UnitAssetInfo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 116908">
      <g
        fill="#5ac782"
        stroke="#fff"
        strokeWidth={4}
        data-name="Rectangle 57719"
        transform="translate(4 4)"
      >
        <rect width={24} height={24} stroke="none" rx={12} />
        <rect width={28} height={28} x={-2} y={-2} fill="none" rx={14} />
      </g>
      <path
        fill="#fff"
        d="M16 8a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 1.2A6.8 6.8 0 1 1 9.2 16 6.791 6.791 0 0 1 16 9.2Zm0 2.8a.8.8 0 1 0 .8.8.8.8 0 0 0-.8-.8Zm-.009 2.791a.6.6 0 0 0-.591.609v4.4a.6.6 0 1 0 1.2 0v-4.4a.6.6 0 0 0-.609-.609Z"
        data-name="icons8-info (7)"
      />
    </g>
  </svg>
)
export default UnitAssetInfo
