import { PropertyCard_Style } from "./style"
import { Box, Stack, Typography } from "@mui/material"

export const PropertyCard = ({
  plotname = "", image = "", title = "", tagline = "", id = "", address = "", logo = "", hidden
}) => {
  const language = localStorage.getItem("i18nextLng")
  return (
    <>
      <Box sx={PropertyCard_Style()?.sx}>
        <Box sx={PropertyCard_Style(logo)?.image}>

          <Box sx={language === "ar" ? PropertyCard_Style()?.arabicTag : PropertyCard_Style()?.tag}>
            <Typography sx={PropertyCard_Style()?.tagTitle} noWrap>
              {tagline}
            </Typography>
          </Box>
        </Box>
        <Box sx={PropertyCard_Style()?.topLine}>
          <Typography sx={PropertyCard_Style()?.title} noWrap>{title}</Typography>
          <Box sx={PropertyCard_Style()?.idBg}>
            <Typography sx={PropertyCard_Style()?.id} noWrap>{id}</Typography>
          </Box>
        </Box>
        <Box sx={PropertyCard_Style()?.bottomLine}>
          <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
            <Box sx={PropertyCard_Style()?.truncateText}>
              <Stack direction="row" alignItems="center">
                <Box>{image}</Box>
                <Typography sx={PropertyCard_Style()?.plotName} noWrap>
                  {plotname}
                </Typography>
              </Stack>
            </Box>
            <Box sx={PropertyCard_Style()?.truncateText}>
              <Stack direction="row" alignItems="center">
                <Box sx={{
                  ...PropertyCard_Style()?.dot, backgroundColor: "#CED3DD"
                }} ></Box>
                <Typography sx={PropertyCard_Style()?.address} noWrap>
                  {address}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Box >
      </Box >
    </>
  );
}