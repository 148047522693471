import React from "react";
import { PayRequestInvoiceList } from './payRequestInvoiceList';
import { withTranslation } from 'react-i18next';

class PayRequestInvoiceListParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <PayRequestInvoiceList {...this?.props} />;
  }
}

export default withTranslation('payRequestInvoiceList')(PayRequestInvoiceListParent);

