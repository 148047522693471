import { cardStyle } from "./style";
import { Box, Typography } from "@mui/material";
import { generateImageUrl, img_size } from "../../utils";
export const DashCards = ({
  title = "-",
  description = "-",
  image = "",
  count = 0,
  is_image = false,
  router = () => false
}) => {
  return (
    <Box sx={cardStyle?.card}  onClick={router && router} >
      {/* count section of card */}
      <Box sx={cardStyle?.topBlock}>
        <Typography variant="body1" sx={cardStyle.count}>
          {(parseInt(count) > 10 || parseInt(count) === 0 )? count : `0${count}`}
        </Typography>
        {/* image section of card */}
        {
          is_image ? <img style={{marginTop:'-22px'}} src={generateImageUrl(image, img_size.small_square)} alt={title} /> : <Box sx={cardStyle?.img}>{image}</Box>
        }
      </Box>
      {/* title section */}
      <Box sx={cardStyle?.content}>
        <Typography sx={cardStyle?.title} >
          {title}
        </Typography>
        {/* title description */}
        <Typography sx={cardStyle?.subtitle} >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};
