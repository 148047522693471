import * as React from "react"
const AssetsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.5}
        x2={0.5}
        y1={-0.009}
        y2={0.235}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#eba600" />
        <stop offset={1} stopColor="#c28200" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.5}
        x2={0.5}
        y1={-0.005}
        y2={0.999}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffd869" />
        <stop offset={1} stopColor="#fec52b" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0.5}
        x2={0.5}
        y1={-0.002}
        y2={1.017}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c26715" />
        <stop offset={0.508} stopColor="#b85515" />
        <stop offset={1} stopColor="#ad3f16" />
      </linearGradient>
    </defs>
    <g data-name="Group 116972">
      <g data-name="Group 116460" transform="translate(-23 -1895)">
        <rect
          width={32}
          height={32}
          fill="#fff1cf"
          data-name="Rectangle 58278"
          rx={6}
          transform="translate(23 1895)"
        />
      </g>
      <g transform="translate(3.072 .964)">
        <path
          fill="url(#a)"
          d="m13.123 8.614-1.2-1.2a1.418 1.418 0 0 0-1-.415H3.945A.945.945 0 0 0 3 7.945v14.183a.945.945 0 0 0 .945.945H21.91a.945.945 0 0 0 .946-.945V9.836a.945.945 0 0 0-.946-.945h-8.118a.946.946 0 0 1-.669-.277Z"
          data-name="Path 101564"
        />
        <path
          fill="url(#b)"
          d="m11.787 12.614 1.545-1.545A.236.236 0 0 1 13.5 11h8.41a.945.945 0 0 1 .946.945v12.292a.945.945 0 0 1-.946.945H3.945A.945.945 0 0 1 3 24.237v-11.11a.236.236 0 0 1 .236-.236h7.882a.946.946 0 0 0 .669-.277Z"
          data-name="Path 101565"
          transform="translate(0 -2.109)"
        />
        <path
          fill="#d6742d"
          d="m18.6 18.346-3.042-3.038a.852.852 0 0 0-1.188-.016l-2.55 2.416v2.057h6.3a.473.473 0 0 0 .473-.473Z"
          data-name="Path 101566"
        />
        <circle
          cx={0.924}
          cy={0.924}
          r={0.924}
          fill="#fff"
          data-name="Ellipse 130930"
          transform="translate(15.7 12.264)"
        />
        <path
          fill="url(#c)"
          d="m21.245 27.673-5.361-5.361a1.063 1.063 0 0 0-1.483-.02L12 24.566V27.2a.473.473 0 0 0 .473.473Z"
          data-name="Path 101567"
          transform="translate(-4.745 -7.909)"
        />
      </g>
    </g>
  </svg>
)
export default AssetsIcon
