import { useNavigate } from "react-router";
import { InvestmentSvg, InflationUp, InflationDown, ContractFile, Checkout, Checkin, Completed, Inprogress } from "../assets";
import { AppRoutes } from "../router/routes";
import React from "react";
import moment from "moment-timezone";
import GeneralRequestIcon from "../assets/generalRequestIcon";


export const barData = [
    {
        name: 'Page A',
        value: 100,
    },
    {
        name: 'Page B',
        value: 150,
    },
    {
        name: 'Page C',
        value: 200,
    },
    {
        name: 'Page D',
        value: 250,
    },
    {
        name: 'Page E',
        value: 300,
    },
    {
        name: 'Page F',
        value: 350,
    },
    {
        name: 'Page G',
        value: 400,
    },
];


export const lineData = [
    {
        name: 'Jan',
        amount: 100,
        "Stocks & Equities": 100,
        Bonds: 100,
        uv: 100
    },
    {
        name: 'Feb',
        amount: 100,
        "Stocks & Equities": 150,
        Bonds: 110,
        uv: 100
    },
    {
        name: 'Mar',
        amount: 200,
        "Stocks & Equities": 140,
        Bonds: 130,
        amt: 2290,
        uv: 100
    },
    {
        name: 'Apr',
        amount: 250,
        "Stocks & Equities": 140,
        Bonds: 160,
        uv: 100
    },
    {
        name: 'May',
        amount: 300,
        "Stocks & Equities": 120,
        Bonds: 200,
        uv: 120
    },
    {
        name: 'Jun',
        amount: 350,
        "Stocks & Equities": 240,
        Bonds: 200,
        uv: 140
    },
    {
        name: 'Jul',
        amount: 400,
        "Stocks & Equities": 250,
        Bonds: 200,
        uv: 150
    },
    {
        name: 'Aug',
        "Stocks & Equities": 240,
        Bonds: 200,
        uv: 120
    },
    {
        name: 'Sep',
        "Stocks & Equities": 260,
        Bonds: 220,
        uv: 150
    },
    {
        name: 'Oct',
        "Stocks & Equities": 300,
        Bonds: 250,
        uv: 180
    },
];

export const inspect = [

    {
        title: "Unit Name Here",
        plotname: "Casagrand 3245",
        date: "12-02-2022",
        name: 'Appartments',
        status: 'Move In'
    },
    {
        title: "Unit Name Here",
        plotname: "Casagrand 3245",
        date: "12-02-2022",
        name: 'Appartments',
        status: 'Move Out'
    },
    {
        title: "Unit Name Here",
        plotname: "Casagrand 3245",
        date: "12-02-2022",
        name: 'Appartments',
        status: 'Move In'
    }
]

export const amenitiesBooking = [
    {
        title: "Amenity Name Here",
        subTitle: "Available today",
        value: "$2.00",
        image: "https://akm-img-a-in.tosshub.com/businesstoday/images/story/201908/house_prop_660_080719010504.jpg"
    },
    {
        title: "Amenity Name Here",
        subTitle: "Available Tomorrow",
        value: "$2.00",
        image: "https://akm-img-a-in.tosshub.com/businesstoday/images/story/201908/house_prop_660_080719010504.jpg"
    },
    {
        title: "Amenity Name Here",
        subTitle: "Not Available today",
        value: "$2.00",
        image: "https://akm-img-a-in.tosshub.com/businesstoday/images/story/201908/house_prop_660_080719010504.jpg"
    },
]

export const utilitiesBox = (t) => [
    {
        title: "Electricity",
        subTitle: "3 Meters",
        status: "OVER DUE",

    },
    {
        title: "Electricity",
        subTitle: "3 Meters",
        status: "2 DAYS TO DUE",

    }
]


export const value = [
    {
        price: "$300",
        consumedUnits: "254",
        fromDate: "22 Jan, 2022",
        toDate: "25 Jan, 2022",
        meterValue: "124 kwh",
        previousMeter: "03 Mar, 2022",
        price_head: "Total Bill",
        consumedUnits_head: "Consumed Units",
        fromDate_head: "From Date",
        toDate_head: "To Date",
        meterValue_head: "Current Meter Reading Value",
        previousMeter_head: "Previous Meter Reading Date",
    }

]

export const radioButton = [
    {
        isActive: true,
        label: "082 2454 234",
    },
    {
        isActive: false,
        label: "123 456 789",
    },
    {
        isActive: false,
        label: "987 654 321",
    },
]

export const Agree = [
    {
        code: "AG-123-21",
        expire: "02 Dec 22",
        leasestart: "02 jan 22",
        leaseend: "03 Mar 24",
        view: "View Expired Agreements"
    },
    {
        code: "AG-123-21",
        expire: "02 Dec 22",
        leasestart: "02 jan 22",
        leaseend: "03 Mar 24",
        view: "View Expired Agreements"
    },
    {
        code: "AG-123-21",
        expire: "02 Dec 22",
        leasestart: "02 jan 22",
        leaseend: "03 Mar 24",
        view: "View Expired Agreements"
    },
    {
        code: "AG-123-21",
        expire: "02 Dec 22",
        leasestart: "02 jan 22",
        leaseend: "03 Mar 24",
        view: "View Expired Agreements"
    },
    {
        code: "AG-123-21",
        expire: "02 Dec 22",
        leasestart: "02 jan 22",
        leaseend: "03 Mar 24",
        view: "View Expired Agreements"
    },
]

export const ActiveInvestmentValue = [
    {
        price: "$ 32.00",
        activity: "Stocks And Equities",
        business: "Gain",
        Date: "22 Nov,21",
        image: <InvestmentSvg />,
        Inflationcolor: "#00B542",
        InflationIcon: <InflationUp />,
        inflation: "12%",
        currValue: "$ 33.00",
        unitNum: "PSG-155234",
    },
    {
        price: "$ 234.65",
        activity: "Fixed Deposits",
        business: "Loss",
        Date: "21 Nov,21",
        image: <InvestmentSvg />,
        Inflationcolor: "#F60000",
        InflationIcon: <InflationDown />,
        inflation: "0.3%",
        currValue: "$ 33.00",
        unitNum: "PSG-155234"

    },
    {
        price: "$ 23.00",
        activity: "Bonds",
        business: "Gain",
        Date: "03 Nov,21",
        image: <InvestmentSvg />,
        Inflationcolor: "#00B542",
        InflationIcon: <InflationUp />,
        inflation: "0.5%",
        currValue: "$ 33.00",
        unitNum: "PSG-155234"

    },
    {
        price: "$ 344.00",
        activity: "Utilities",
        business: "Loss",
        Date: "02 Nov,21",
        image: <InvestmentSvg />,
        Inflationcolor: "#00B542",
        InflationIcon: <InflationUp />,
        inflation: "0.5%",
        currValue: "$ 33.00",
        unitNum: "PSG-155234"

    },
    {
        price: "$ 344.00",
        activity: "Clothing",
        business: "Gain",
        Date: "02 Nov,21",
        image: <InvestmentSvg />,
        Inflationcolor: "#00B542",
        InflationIcon: <InflationUp />,
        inflation: "0.5%",
        currValue: "$ 33.00",
        unitNum: "PSG-155234"

    },
];

export const kycViewData = [
    {
        text: "Vengadesh",
        subText1: "Aadhar",
        subText2: "ID 00124",
        status: "Valid",
        isValid: true,
        divider: true
    },
    {
        text: "Prasath",
        subText1: "Driving Licence",
        subText2: "ID 00124",
        status: "Expired",
        isValid: false,
    },
]

export const kycDrawerData = [
    {
        text: "ID Proof Type"
    },
    {
        text: "ID Proof Type"
    },
    {
        text: "ID Proof Type"
    },
    {
        text: "ID Proof Type"
    },
]

export const accessType = [{ "id": "3d68bc6a-219c-44a7-9bf1-81665e8ea730", "name": "Owner", "role_id": "b59e82e8-ad42-4ad4-9969-9597cddbb27d", "module_id": null, "company_id": 1, "company_name": "Uzumaki Naruto test", "company_logo": "https://protomate.objectstore.e2enetworks.net/rayDcode/1664704278538-rafal-real-estate-development-co-logo-113A834639-seeklogo.com.png", "company_uom": { "id": 2, "name": "Sq. Meter" }, "currency_id": 1, "currency_code": "AFN", "currency_symbol": "AFN", "currency_type": "Afghan afghani", "timezone": "Asia/Kabul" }]

export const ReqCardData = (t) => [
    {
        name: t("Water leakage repair"),
        reqId: "MNT-112",
        reqType: t("Maintenance"),
        status: t("In Progress"),
        date: "22 Jan 21",
        unitId: "K-F01-U227",
    },
    {
        name: t("General Problem"),
        reqId: "MNT-112",
        reqType: t("Maintenance"),
        status: t("New"),
        date: "22 Jan 21",
        unitId: "K-F01-U228"
    }
]

export const ReqViewData = (t) => [
    {
        title: t("Water leakage"),
        date: "22 Jan 21",
        unit: "S05",
        raisedOn: "26 Jan 21",
        status: t("In Progress"),
        statusText: t("We are working on this items.")
    }
]

export const AssetsData = [
    {
        img: "https://t4.ftcdn.net/jpg/02/81/89/73/360_F_281897358_3rj9ZBSZHo5s0L1ug7uuIHadSxh9Cc75.jpg"
    },
    {
        img: "https://t4.ftcdn.net/jpg/02/81/89/73/360_F_281897358_3rj9ZBSZHo5s0L1ug7uuIHadSxh9Cc75.jpg"
    },
    {
        img: "https://t4.ftcdn.net/jpg/02/81/89/73/360_F_281897358_3rj9ZBSZHo5s0L1ug7uuIHadSxh9Cc75.jpg"
    },
    {
        img: "https://t4.ftcdn.net/jpg/02/81/89/73/360_F_281897358_3rj9ZBSZHo5s0L1ug7uuIHadSxh9Cc75.jpg"
    },
    {
        img: "https://t4.ftcdn.net/jpg/02/81/89/73/360_F_281897358_3rj9ZBSZHo5s0L1ug7uuIHadSxh9Cc75.jpg"
    },
    {
        img: "https://t4.ftcdn.net/jpg/02/81/89/73/360_F_281897358_3rj9ZBSZHo5s0L1ug7uuIHadSxh9Cc75.jpg"
    }

]

export const ContractsData = [
    {
        contractId: "CNT-123456",
        contractor: "Shivani Chacha",
        unitId: "ACC-12412412",
        contractDate: "22 Jan 22 - 22 Jan 23",
        unitIdColor: "#4E5A6B",
        image: <ContractFile />,
        invoices: [
            {
                invoicetitle: "Water Leakage Repair",
                invoiceId: "K-F01-U25",
                dueDate: "Due 22 Nov",
                invoiceAmount: "$ 32.00",
                status: "Pending",
            },
            {
                invoicetitle: "Water Leakage Repair",
                invoiceId: "K-F01-U25",
                dueDate: "Due 22 Nov",
                invoiceAmount: "$ 32.00",
                status: "Pending",
            },
            {
                invoicetitle: "Water Leakage Repair",
                invoiceId: "K-F01-U25",
                dueDate: "Due 22 Nov",
                invoiceAmount: "$ 32.00",
                status: "Pending",
                borderline: "none",

            }
        ],
        Units: [
            {
                img: "images/map.png",
                unitId: "P-F02-U15",
                unitAd: "Floor 3, P Tower, Rubix Apartment",
                sqMeters: "230 Sq Feet",
            }, {
                img: "images/map.png",
                unitId: "P-F02-U15",
                unitAd: "Floor 3, P Tower, Rubix Apartment",
                sqMeters: "230 Sq Feet",
            },
            {
                img: "images/map.png",
                unitId: "P-F02-U15",
                unitAd: "Floor 3, P Tower, Rubix Apartment",
                sqMeters: "230 Sq Feet",
            },

        ]
    },
    // {
    //     contractId: "CNT-1230045",
    //     contractor: "Bala Vignesh ",
    //     unitId: "ACC-12412412",
    //     contractDate: "22 Jan 22 - 22 Jan 23",
    //     image: <ContractFile />,
    //     invoices: [
    //         {
    //             invoicetitle: "Water Leakage Repair",
    //             invoiceId: "K-F01-U25",
    //             dueDate: "Due 22 Nov",
    //             invoiceAmount: "$ 32.00",
    //             status: "Pending"
    //         }
    //     ],
    //     Units: [
    //         {
    //             img: "",
    //             unitId: "P-F02-U15",
    //             unitAd: "Floor 3, P Tower, Rubix Apartment",
    //             sqMeters: "230 Sq Feet"
    //         },
    //     ]
    // },
    // {
    //     contractId: "CNT-457893",
    //     contractor: "Chacha ",
    //     unitId: "ACC-12412412",
    //     contractDate: "22 Jan 22 - 22 Jan 23",
    //     image: <ContractFile />,
    //     invoices: [
    //         {
    //             invoicetitle: "Water Leakage Repair",
    //             invoiceId: "K-F01-U25",
    //             dueDate: "Due 22 Nov",
    //             invoiceAmount: "$ 32.00",
    //             status: "Pending"
    //         }
    //     ],
    //     Units: [
    //         {
    //             img: "",
    //             unitId: "P-F02-U15",
    //             unitAd: "Floor 3, P Tower, Rubix Apartment",
    //             sqMeters: "230 Sq Feet"
    //         },
    //     ]
    // }
]

export const Checkindata = (t,checkin,checkout) => [
    {
        date: checkin ? moment(checkin).format("DD MMM YY HH:mm a") : "",
        purpose: t("Checked In"),
        image: <Checkin />,
        line: false,
        show: true,
        titleBold: false
    },
    {
        date: checkout ? moment(checkout).format("DD MMM YY HH:mm a") : "",
        purpose: t('Checked Out'),
        image: <Checkout />,
        line: false,
        titleBold: false,
        show: true
    }
]


export const reqCheckindata = (t, data) => {
    const result = data?.map((x) => {
        return {
            date: x?.type,
            purpose: x?.date,
            image: x?.type === "Open" ? <Completed /> : <Inprogress />,
            line: true,
            progressDetails: x?.notes,
            titleBold: true,
            show: true,
            created_at: x?.created_at,
            closed_by:x?.closed_by
        }
    })
    return result
}


export const RequestData = (t) => {
    const navigate = useNavigate()
    return [

        // {
        //     title: t("Maintenance Request"),
        //     subTitle: t("Service/Repair request for your unit"),
        //     exampleTitle: t("Eg: Plumbing, Electrical etc."),
        //     icon: <MaintanceRequestIcon />,
        //     onClick: () => navigate(AppRoutes.maintanceRequest)
        // },
        {
            title: t("General Request"),
            // subTitle: t("Raise any other complaints"),
            // exampleTitle: t("Eg: Related to Invoice, Agreement etc"),
            icon: <GeneralRequestIcon />,
            onClick: () => navigate(AppRoutes.generalRequest)
        },
    ]
}
export const CasemanageData = (t) => [
    {
        type: 'Correspondence Type Here',
        status: 'Urgent',
        content: 'Case management letter title will display here in two lines max lines',
        postedDate: '22 Jan 22',
        Deadline: '26 Jan 22',
        color: 'notebg.primary'
    },
    {
        type: 'Correspondence Type Here',
        status: 'medium',
        content: 'Case management letter title will display here in two lines max lines',
        postedDate: '22 Jan 22',
        Deadline: '26 Jan 22',
        color: 'notebg.secondary'
    },
    {
        type: 'Correspondence Type Here',
        status: 'High',
        content: 'Case management letter title will display here in two lines max lines',
        postedDate: '22 Jan 22',
        Deadline: '26 Jan 22',
        color: 'notebg.secondary'
    },
    {
        type: 'Correspondence Type Here',
        status: 'Low',
        content: 'Case management letter title will display here in two lines max lines',
        postedDate: '22 Jan 22',
        Deadline: '26 Jan 22',
        color: 'notebg.light'
    },
]
export const ParkingData = [
    {
        title:'Parking Name',
        appartment:'Rubix Apartment',
        image:'transparent url(https://teja12.kuikr.com/is/a/c/880x425/public/images/apartments/original_img/k9yqlp.gif) 0% 0% no-repeat padding-box',
        doorno:'10B'
    },
    {
        title:'Parking Name',
        appartment:'Rubix Apartment',
        image:'transparent url(http://www.apartments.com/images/default-source/2019-naa/parkline-apartment-in-miami-fla2dc2731-e6f2-4dca-89c5-38245ccacea1.tmb-featuredim.jpg?sfvrsn=55bc41ed_1) 0% 0% no-repeat padding-box',
        doorno:'10B'
    }
]


export const years = [2010, 2020, 2021, 2022, 2023, 2024]
