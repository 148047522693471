
export const AnnouncementStyle = {
    root: {
        padding: "0px 16px 16px",
        position: "relative",
        width: '100%',
        height: '100%',
        overflow: "auto",
        backgroundColor: "background.main",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        "& .infinite-scroll-component": {
            backgroundColor: "#F6F6F6 !important",
        }
    },
    container: {
        backgroundColor: "background.Teritary",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
    },
    switcherComponent: {
        backgroundColor: "backgroundcard.teritary",
        padding: "0px 12px",
        // boxShadow: "0px 0px 30px #0000001A",
    },
    AnnouncementCardBox: {
        marginBottom: "12px"
    },
    infiniteScroll: {
        backgroundColor: "none"
    },
    TopNavBar: {
        position: "fixed",
        top: "0",
        left: '0',
        zIndex: 999,
        width: '100%'
    }
}

