import React from "react";
import { SpendingsScreen } from "./spending";
import { withTranslation } from 'react-i18next';
// eslint-disable-next-line

class PropertyParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return <SpendingsScreen {...this?.props} />;
    }
}

export default (withTranslation('spending')(PropertyParent));


