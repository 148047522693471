import * as React from "react"

export const LocationMap = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13.3} height={14} {...props}>
    <path
      data-name="Path 973"
      d="M6.93.305c2.826 0 4.261 1.83 4.261 3.635a8.532 8.532 0 0 1-4.121 6.94c-3.213-1.175-4.4-4.694-4.4-6.5A4.143 4.143 0 0 1 6.93.305Z"
      fill="#ced3dd"
    />
    <path
      d="M6.65 0a4.9 4.9 0 0 0-3.724 8.08s2.026 2.373 2.778 3.091a1.377 1.377 0 0 0 1.89 0c.85-.811 2.778-3.09 2.779-3.091A4.9 4.9 0 0 0 6.65 0Zm0 1.05A3.847 3.847 0 0 1 9.576 7.4s-2 2.344-2.7 3.013a.308.308 0 0 1-.441 0c-.579-.552-2.7-3.012-2.706-3.013A3.847 3.847 0 0 1 6.65 1.05Zm0 1.75a2.084 2.084 0 0 0-1.574.669 2.21 2.21 0 0 0 0 2.863 2.186 2.186 0 0 0 3.147 0 2.21 2.21 0 0 0 0-2.863A2.084 2.084 0 0 0 6.65 2.8Zm0 1.05a.968.968 0 0 1 .789.316 1.163 1.163 0 0 1 0 1.468.968.968 0 0 1-.789.316.968.968 0 0 1-.789-.316 1.163 1.163 0 0 1 0-1.468.968.968 0 0 1 .789-.316ZM2.72 8.9C1.129 9.364 0 10.134 0 11.2 0 13.038 3.345 14 6.65 14s6.65-.962 6.65-2.8c0-1.066-1.129-1.835-2.719-2.295-.2.23-.479.543-.784.882 1.549.364 2.453.954 2.453 1.412 0 .714-2.182 1.75-5.6 1.75s-5.6-1.036-5.6-1.75c0-.458.905-1.049 2.457-1.414-.307-.336-.583-.65-.787-.885Z"
      fill="#4e5a6b"
    />
  </svg>
)

