import { Box, Divider, Grid, Typography } from "@mui/material"
import moment from "moment-timezone"
import { FileSvg, PdfIcon } from "../../../assets"
import { Bold, generateImageUrl, img_size, Regular, SemiBold } from "../../../utils"

export const folderDrawerStyle = {
    fileName: {
        fontSize: "0.875rem",
        margin: "6px 0 2px 0",
        color: "primary.contrastText",
        fontFamily: Bold
    },
    fileCreated: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: "primary.contrastText"
    },
    title: {
        fontSize: "0.75rem",
        color: "text.secondary",
        marginBottom: "10px",
        fontFamily: SemiBold
    },
    subtitle: {
        fontSize: "0.875rem",
        color: "text.main",
        fontFamily: SemiBold
    },
    gridItem: {
        padding: "16px"
    },
    fileDiv: {
        padding: "16px 16px",
        background: "#f39779",
        alignItems: "center",
        cursor: "pointer"
    },
    fileDetails: {
        paddingLeft: "16px"
    },
    img: {
        height: "100%",
        overflow: "hidden"
    }
}


export const FolderDrawer = ({
    data = {},
    pdfViewType = false,
    t = () => false }) => {
    return (

        <>
            {
                pdfViewType ? (
                    <Box sx={folderDrawerStyle?.img}>
                        {
                            data && <img alt="img" src={generateImageUrl(data, img_size.small_square)} width="100%" height="100%" />
                        }
                    </Box>
                ) :
                    <>
                        <Grid container sx={folderDrawerStyle?.fileDiv} >
                            <Grid item xs={2} sm={1}>
                                {
                                    data?.is_dir ? <FileSvg /> : <PdfIcon />
                                }
                            </Grid>
                            <Grid item xs={10} sm={11} sx={folderDrawerStyle?.fileDetails}>
                                <Typography sx={folderDrawerStyle?.fileName}>{data?.name}</Typography>
                                <Typography sx={folderDrawerStyle?.fileCreated}>{t("Last Modified on")} {moment(data?.updated_at)
                                    .tz(moment.tz.guess())
                                    .format("YYYY/MM/DD")}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid item xs={4} sx={folderDrawerStyle?.gridItem}>
                                <Typography sx={folderDrawerStyle?.title}>{t("DATE CREATED ON")}</Typography>
                                <Typography sx={folderDrawerStyle?.subtitle}>
                                    {moment(data?.created_at)
                                        .tz(moment.tz.guess())
                                        .format("DD MMM YY")}</Typography>
                            </Grid>
                            <Grid item xs={4} sx={folderDrawerStyle?.gridItem}>
                                <Typography sx={folderDrawerStyle?.title}>{t("CREATED BY")}</Typography>
                                <Typography sx={folderDrawerStyle?.subtitle}>{data?.created_by}</Typography>
                            </Grid>
                            <Grid item xs={4} sx={folderDrawerStyle?.gridItem}>
                                <Typography sx={folderDrawerStyle?.title}>{("NUMBER OF FILES")}</Typography>
                                <Typography sx={folderDrawerStyle?.subtitle}>{data?.no_of_files}</Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                    </>
            }
        </>
    )
}