import * as React from "react"

export const ConsumedIcon = (props) => (
  <svg
    data-name="Group 96571"
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 53023" fill="none" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 96570" clipPath="url(#a)">
      <path
        data-name="Path 9135"
        d="M31.996 2.971v26.153a3.213 3.213 0 0 1-1.942 2.674 4.057 4.057 0 0 1-.992.2H16.02a.432.432 0 0 1-.133-.366q-.007-1.555 0-3.11a.522.522 0 0 1 .081-.326.577.577 0 0 1 .491-.138h3.053c.791 0 1.051-.261 1.053-1.044v-3.3c0-.723-.277-1-1-1-1.028-.006-2.056 0-3.084 0a.613.613 0 0 1-.5-.124.468.468 0 0 1-.091-.288c-.009-.441-.01-.882 0-1.323a.467.467 0 0 1 .1-.286c.173-.162.391-.117.594-.118q4.055-.006 8.111 0h2.371c.709-.006.991-.28.991-.984q.006-7.3 0-14.6c0-.721-.279-.994-1.011-1q-5.209-.007-10.419 0a.888.888 0 0 1-.63-.109.533.533 0 0 1-.107-.38q-.009-1.541 0-3.081a.49.49 0 0 1 .088-.353c.1-.083.219-.047.33-.047 4.159 0 8.318.006 12.477-.006a3.239 3.239 0 0 1 3.028 1.968 4.068 4.068 0 0 1 .19 1"
        fill="#0a617d"
      />
      <path
        data-name="Path 9136"
        d="M16.013 28.2V32H3.283c-.121-.064-.251-.017-.377-.031a3.115 3.115 0 0 1-2.878-2.77c.008-.361-.022-.726-.022-1.089Q.005 15.72 0 3.335a3.277 3.277 0 0 1 1.961-3.1A2.773 2.773 0 0 1 3.27.011Q9.51 0 15.749.008c.09 0 .188-.034.268.04v3.785l-.032.052c-.182.146-.4.092-.6.092q-5.056.006-10.112 0c-.156 0-.312-.005-.468.007a.832.832 0 0 0-.814.826c-.009.125-.006.25-.006.374v14.169c0 .949.254 1.206 1.195 1.206h10.174a1.363 1.363 0 0 1 .585.052.213.213 0 0 1 .079.093v1.849l-.029.05a.583.583 0 0 1-.445.094h-3.025c-.741 0-1.043.3-1.045 1.039q-.005 1.637 0 3.274c0 .735.305 1.032 1.049 1.035h2.962a.908.908 0 0 1 .459.055.2.2 0 0 1 .073.089"
        fill="#0a617d"
      />
      <path
        data-name="Path 9137"
        d="M29.062 31.996c.241-.072.49-.125.722-.219a3.407 3.407 0 0 0 2.147-2.525c.011-.049 0-.1.064-.127v2.715c0 .134-.026.161-.16.16-.926-.008-1.852 0-2.778 0"
        fill="#fefefe"
      />
      <path
        data-name="Path 9138"
        d="m2.97 31.996-.011-.053a.391.391 0 0 1 .323.053Z"
        fill="#5c95a8"
      />
      <path
        data-name="Path 9139"
        d="M16.013 3.833c.124 0 .249.011.373.011H27.15a.927.927 0 0 1 1.038 1.037V19.67a.928.928 0 0 1-1.037 1.033H16.387c-.124 0-.249.007-.373.011l.006-.023a.309.309 0 0 1-.128-.237 18.782 18.782 0 0 1 0-1.424.381.381 0 0 1 .1-.224.727.727 0 0 1 .534-.11c1.368 0 2.736-.014 4.1.008 1.621-.022 3.242 0 4.863-.01h.343a.294.294 0 0 0 .343-.34V6.194a.286.286 0 0 0-.319-.331h-9.26a.872.872 0 0 1-.6-.1.383.383 0 0 1-.106-.253 19.5 19.5 0 0 1 0-1.368.33.33 0 0 1 .131-.269Z"
        fill="#fef4f4"
      />
      <path
        data-name="Path 9140"
        d="m16.013 22.559 3.679.012a.92.92 0 0 1 1.006 1v3.617a.921.921 0 0 1-1.006 1l-3.679.012.006-.023a.4.4 0 0 1-.136-.364q-.009-2.434 0-4.867a.4.4 0 0 1 .136-.364l-.006-.023"
        fill="#fef4f4"
      />
      <path
        data-name="Path 9141"
        d="M25.39 22.572a2.808 2.808 0 1 1-2.822 2.778 2.814 2.814 0 0 1 2.822-2.778"
        fill="#00dcc1"
      />
      <path
        data-name="Path 9142"
        d="M16.017 18.825v1.861c-.561 0-1.122.011-1.684.011H4.977c-.79 0-1.133-.346-1.133-1.143V4.986c0-.8.342-1.142 1.133-1.142h10.732c.1 0 .207.007.311.011v1.854l-.034.051a.836.836 0 0 1-.572.089q-4.431.006-8.863 0c-.692 0-.693 0-.693.682v11.703c.008.4.05.449.453.45h4.556c.2 0 .4 0 .593.017 1.41-.035 2.821-.012 4.231-.012a.407.407 0 0 1 .293.08l.037.05"
        fill="#fef4f4"
      />
      <path
        data-name="Path 9143"
        d="M16.02 22.578v5.6c-1.257 0-2.514.015-3.77.007a.91.91 0 0 1-.91-.919q-.015-1.886 0-3.771a.909.909 0 0 1 .91-.918c1.257-.008 2.514 0 3.77.007"
        fill="#fef4f4"
      />
      <path
        data-name="Path 9144"
        d="M9.461 25.375a2.808 2.808 0 1 1-2.8-2.8 2.815 2.815 0 0 1 2.8 2.8"
        fill="#fe5e8f"
      />
      <path
        data-name="Path 9145"
        d="M16.014 5.711q4.929 0 9.859.008c.5 0 .441-.061.441.453q0 6.146.006 12.292c0 .273-.055.371-.354.369-1.778-.016-3.557-.009-5.335-.01-.209-.1-.223-.312-.287-.493a4.5 4.5 0 0 0-3.379-3.03 3.408 3.408 0 0 0-.644-.1.4.4 0 0 1-.395-.2.908.908 0 0 1-.046-.4V9.925a.91.91 0 0 1 .045-.4c.115-.218.321-.2.518-.19a10.652 10.652 0 0 1 5.2 1.661 13.515 13.515 0 0 1 1.294.975.8.8 0 0 0 1.356-.438.821.821 0 0 0-.308-.806 12.04 12.04 0 0 0-6.12-2.887 9.419 9.419 0 0 0-1.488-.135.443.443 0 0 1-.439-.178 7.448 7.448 0 0 1-.042-1.6.282.282 0 0 1 .124-.207Z"
        fill="#fee377"
      />
      <path
        data-name="Path 9146"
        d="M16.018 15.069a4.706 4.706 0 0 1 4.589 3.7.294.294 0 0 0 .026.056h-4.616a.38.38 0 0 1-.13-.3c-.007-1.073-.014-2.146 0-3.219a.3.3 0 0 1 .041-.148.222.222 0 0 1 .085-.087"
        fill="#e0eaef"
      />
      <path
        data-name="Path 9147"
        d="M16.019 5.727V7.58l-.023.043a2.173 2.173 0 0 1-.972.141 11.863 11.863 0 0 0-3.786.957 12.168 12.168 0 0 0-3.168 2.013.815.815 0 0 0-.145 1.187.79.79 0 0 0 1.145.066 13.614 13.614 0 0 1 1.587-1.152 9.965 9.965 0 0 1 4.43-1.445c.305-.022.613-.142.909.038l.022.044v5.624a.86.86 0 0 1-.655.118c-.176-.069-.225-.231-.282-.387-.267-.731-.537-1.461-.8-2.194a1.023 1.023 0 0 0-.46-.605.694.694 0 0 0-.816.057.752.752 0 0 0-.317.845c.2.676.473 1.329.707 1.995a2.611 2.611 0 0 1 .238.833.581.581 0 0 1-.256.3 4.617 4.617 0 0 0-1.75 2.491.372.372 0 0 1-.184.273c-1.82 0-3.64 0-5.459.013-.31 0-.265-.18-.265-.364V8.051c0-.686.005-1.373 0-2.059 0-.2.049-.275.261-.275q5.023.012 10.045.013"
        fill="#fee377"
      />
      <path
        data-name="Path 9148"
        d="M15.325 15.126a2.71 2.71 0 0 1 .692-.032v3.732l-4.576-.007a4.577 4.577 0 0 1 1.8-2.823c.106-.083.219-.158.328-.237a.782.782 0 0 1 .038-.084 2.242 2.242 0 0 1 1.715-.548"
        fill="#e0eaef"
      />
      <path
        data-name="Path 9149"
        d="M16.017 7.58a12.063 12.063 0 0 1 6.159 1.686 12.616 12.616 0 0 1 1.889 1.368.948.948 0 1 1-1.227 1.438 10.344 10.344 0 0 0-6.822-2.6v-.02a.364.364 0 0 1-.13-.3q-.012-.624 0-1.247a.364.364 0 0 1 .13-.3Z"
        fill="#0a617d"
      />
      <path
        data-name="Path 9150"
        d="M16.017 7.599v1.853a10.416 10.416 0 0 0-5.248 1.46 12.307 12.307 0 0 0-1.608 1.176.925.925 0 0 1-1.536-.457.878.878 0 0 1 .289-.93 12.068 12.068 0 0 1 6.983-3.054 6.681 6.681 0 0 1 1.12-.047"
        fill="#0a617d"
      />
      <path
        data-name="Path 9151"
        d="M15.325 15.126a5.288 5.288 0 0 0-1.754.632c-.329-.9-.665-1.79-.984-2.69a.933.933 0 1 1 1.754-.633c.335.894.657 1.793.984 2.691"
        fill="#0a617d"
      />
    </g>
  </svg>
)


