import * as React from "react"

export const BellIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18.447} height={20} {...props}>
    <path
      d="M8.853.008a7.824 7.824 0 0 0-7.322 7.879v3.727L.172 14.349l-.007.015a1.818 1.818 0 0 0 1.62 2.559h4.358a3.077 3.077 0 0 0 6.154 0h4.357a1.818 1.818 0 0 0 1.631-2.559l-.007-.015-1.359-2.734V7.692A7.706 7.706 0 0 0 8.853.008Zm6.525 7.683c-.001 4.222-.001-3.506-.001.001Zm-7.693 9.232h3.077a1.538 1.538 0 1 1-3.077 0Z"
      fill="#000"
    />
  </svg>
)

