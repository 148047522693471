import { Bold, Regular } from "../../utils";


export const MessageCard_style ={
    message:{
        fontFamily: Regular,
        fontSize: "0.875rem"
    },
    reply:{
        fontFamily:Bold,
        fontSize: "0.75rem",
        color:'primary.Tertiary',
        marginInlineStart:'4px'
    },
    date:{
        fontFamily:Regular,
        fontSize: "0.75rem",
        color:'primary.Tertiary',
        fontStyle:'italic'
    },
    align:{
        display:'flex',
        alignItems:'center'
    },
    alignbottom:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        marginBottom:'8px'
    },
    card:{
        boxShadow: '0px 3px 30px #5C86CB2E',
        borderRadius: '4px',
        padding:'12px',
        backgroundColor:'primary.contrastText'
    }
}