import * as React from "react"
export const UnitArrowIconLeft = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8.045}
    height={14.522}
    {...props}
  >
    <path
      fill="#4e5a6b"
      stroke="#4e5a6b"
      strokeWidth={0.5}
      d="M7.795.772a.5.5 0 0 1-.154.349l-6.14 6.14 6.14 6.142a.5.5 0 1 1-.706.707L.441 7.615a.5.5 0 0 1 0-.706L6.935.415a.5.5 0 0 1 .86.358Z"
      data-name="icons8-expand-arrow (2)"
    />
  </svg>
)
