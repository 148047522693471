import * as React from "react"

export const CheckIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <g data-name="Group 7092">
      <g data-name="Group 7097" transform="translate(-16 -206)">
        <rect
          data-name="Rectangle 45755"
          width={18}
          height={18}
          rx={9}
          transform="translate(16 206)"
          fill="#f15a29"
        />
        <path
          d="M28.487 212.5a.4.4 0 0 0-.275.121l-4.912 4.914-2.118-2.115a.4.4 0 1 0-.565.565l2.4 2.4a.4.4 0 0 0 .565 0l5.2-5.2a.4.4 0 0 0-.29-.686Z"
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </g>
    </g>
  </svg>
)

