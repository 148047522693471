import { Box, Container, Typography } from "@mui/material";
import { AddOnsPriceCard, AddOnsUnitCard, DialogDrawer, LoderSimmer, TopNavBar } from "../../components";
import { AddOnsServiceStyle } from "./style";
import { useState } from "react";
import { AppRoutes } from "../../router/routes";
import { useLocation, useNavigate } from "react-router";
import { useLazyGetAllInventoryQuery, useLazyGetAllProjectsQuery } from "../../redux/services";
import { useEffect } from "react";
import { AddOnsServiceSimmer } from "./simmerComponent/addOnsServiceSimmer";
import { errorRoute } from "../../utils";
import NoData from "../../components/noData";

export const AddOnsService = ({
    t = () => false,
}) => {
    const [GetAllUnitCategory , {error}] = useLazyGetAllProjectsQuery();

    const [GetAllInventory , {error:isError}] = useLazyGetAllInventoryQuery();
    const navigate = useNavigate()
    let location = useLocation();
     const [bool , setBool]=useState(false)
    const [unit, setUnit] = useState({
        changeData: {
            property_name: location?.state?.unit_name,
            logo: location?.state?.logo,
            area: location?.state?.area,
            id: location?.state?.id
        }

    })
    const [datas, setDatas] = useState(
        {
            changeUnitDrawer: false,
            unitdata: [],
            serviceData: [],
            bool: false
        }

    )
    const changeUnitDrawerOpen = async () => {
        await setDatas({
            ...datas,
            changeUnitDrawer: true,
        })
        await setBool(true)
        const dataunit = await GetAllUnitCategory()
        await setDatas({
            ...datas,
            changeUnitDrawer: true,
            unitdata: dataunit?.data?.data

        })
        await setBool(false)

    }

    const changeUnitDrawerClose = async (e) => {
        // setIsSelected({
        //     id: e?.id,
        //     locationId: ''
        // });
        setDatas({
            ...datas,
            bool:true

        })
        const payload = {
            unit_id: e?.id,
        }
        const data = await GetAllInventory(payload)
        setUnit(
            { changeData: {
                ...e,
                property_name:e?.unit_name
            } }
        )
        setDatas({
            ...datas,
            changeUnitDrawer: false,
            serviceData: constructData(data?.data?.data),
            bool:false


        })


    }
    const changeUnitDrawer = () => {
        setDatas({
            ...datas,
            changeUnitDrawer: false,
        })
    }

    const constructData = (data = {}) => {
        const results = Object.keys(data)?.map((val) => {
            return {
                name: val,
                childItems: data[val]
            }
        })
        return results

    }

    const getAllInventoryList = async () => {
        const payload = {
            unit_id: location?.state?.id,
        }
        const data = await GetAllInventory(payload)

        setDatas({
            ...datas,
            serviceData: constructData(data?.data?.data),
            bool: false

        })
    }
    useEffect(() => {
        getAllInventoryList();

        // eslint-disable-next-line 
    }, [location?.state])

    const routeFunc = (values) => {
        const result = values?.childItems?.find(({ inspection_item }) => inspection_item !== null)

        navigate(AppRoutes?.addonsDetailed, {

            state: {
                inspection: result?.inspection_item,
                id: unit?.changeData?.id,
                parentName: values?.name,
                childArray: values?.childItems,
            }
        })

        console.log(values?.inspection_item , 'slslsls')

    }

    useEffect(()=>{
        if(error?.status === 423|| isError?.status === 423) return errorRoute() 
     },[error , isError])

    return (
        <Container maxWidth="sm"
        sx={{ padding: "0px !important"}}
        >
            {/* topNavbar */}

            <Container maxWidth="sm" sx={{ padding: "0px !important", minHeight: "60px" }}>
                <Box sx={AddOnsServiceStyle?.topNav}>
                    <TopNavBar title={t("Add On Items & Services")} isDirect isBack t={t} />
                </Box >
            </Container>
            {datas?.bool ?
                <AddOnsServiceSimmer />
                :
                <Box sx={AddOnsServiceStyle?.root}>
                    <>
                        <Box>
                            <AddOnsUnitCard
                                unit
                                title={unit?.changeData?.property_name}
                                subTitle={unit?.changeData?.area ?? ''}
                                value={t("Change")}
                                image={unit?.changeData?.logo ?? ""}
                                valueOnclick={() => changeUnitDrawerOpen()}
                            />
                        </Box>
                        <Box >
                            <Typography sx={AddOnsServiceStyle?.slotTitle}> {t("SERVICES AVAILABLE")}</Typography>
                            <Box sx={AddOnsServiceStyle?.serviceBox}>
                                {datas?.serviceData?.length > 0 && datas?.serviceData?.map((service, index) => {
                                    return (
                                        <Box>
                                            <AddOnsPriceCard
                                                t={t}
                                                service
                                                title={service?.name ?? ""}
                                                subTitle={service?.childItems?.length ?? ""}
                                                price={service?.price ?? ""}
                                                available={""}
                                                serviceOnclick={() => routeFunc(service)}
                                                image={service?.logo ?? ""}
                                                hidden={datas?.serviceData?.length - 1 === index ? false : true}
                                                item_condition={service?.item_condition}
                                            />
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                    </>
                    <DialogDrawer
                        maxWidth="sm"
                        open={datas?.changeUnitDrawer}
                        header={t("Change Unit")}
                        padding={"16px 16px 0px"}
                        height_style={
                            // { height: "350px !important" }
                            { height: "auto !important", padding: "16px !important", minHeight: "100px", gap: "8px !important", display: "grid !important", overflow: "auto" }
                        }
                        component={
                            <>
                                {
                                    bool ? 
                                    <>
                                            <LoderSimmer card count={5} size={80} variant={"rectangular"} animation={"wave"} />

                                    </>:
                                    datas?.unitdata?.length ?  datas?.unitdata?.map((val) => {
                                        return (
                                            <AddOnsUnitCard
                                                ChangeUnit
                                                title={val?.unit_name ?? ""}
                                                image={val?.logo ?? ""}
                                                subTitle={val?.area ?? ""}
                                                tagTitle={val?.unit_no ?? ""}
                                                changeUnitOnclick={() => changeUnitDrawerClose(val)}
                                                isSelected={unit?.changeData?.id}
                                                id={val?.id}
                                                width={AddOnsServiceStyle.serviceWidth}
                                            />
                                        )
                                    }) : <NoData/>
                                }
                            </>
                        }

                        onClose={() => changeUnitDrawer()}
                    />

                </Box>
            }
        </Container >


    );
};
