import { Bold, Regular } from "../../utils";

export const UnitCard_style ={
    sx:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        paddingBottom:'6px'
    },
    sxMain:{
        display:'flex',
        alignItems:'center'
    },

    photoSec:{
        width: '40px',
        height: '40px',
        borderRadius:'4px',
        backgroundPosition:"center !important",
        backgroundSize:"cover !important",
        backgroundRepeat:"no-repeat !important"
    },
    title:{
        fontFamily: Bold,
        fontSize: "0.875rem",
    },
    propAd:{
        fontFamily: Regular,
        fontSize: "0.75rem",
        color:'color.teritary'
    },
    status:{
         fontSize: "0.75rem",
        fontFamily: Bold,
        color:'primary.main',
        padding:'3px 6px',
        backgroundColor:'backgroundcard.label',
        textTransform:"capitalize"
    },
    secTwo:{
        marginInlineStart:'8px',
        width:'100%'
    }

}