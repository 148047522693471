export const CreateInvestmentStyle ={
  root: {
      padding: "83px 16px 101px",
      width: '100%',
      overflow: "auto",
      backgroundColor:'#F8F8F8',
      // height: {
      //   xs:'calc(100vh - 147px)',
      //   sm:'calc(100vh - 155px)'
      // },
      "&::-webkit-scrollbar": {
        display: "none",
      },      
    },
    container:{
      paddingLeft:"0px !important",
      paddingRight:"0px !important",
      position:"relative",
      height:"100vh"
    },
    footer:{
      position:"fixed",
      left:0,
      right:0,
      zIndex:999,
      width:"100%",
      bottom:"0"
    },
    FormGeneratorBox:{
      backgroundColor:"primary.contrastText",
      borderRadius:"12px",
      padding:"24px 16px",
      boxShadow:"#4354721F 0px 3px 30px"
    },
    labelStyle:{
      color:"#F15A29 !important",
    },
    investmentTab:{
      position:"fixed",
      top:0,
      left:0,
      right:0,
      zIndex:999
    }

}

