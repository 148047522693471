import React from "react";
import { withTranslation } from 'react-i18next';
import { CaseManagement } from "./caseManagement";

class CaseManagementParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <CaseManagement  {...this?.props} />;
  }
}

export default withTranslation('correspondence')(CaseManagementParent);
