import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";
// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
    },
});


const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const statisticsAPI = createApi({
    reducerPath: "statisticsAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["Statistics"],
    endpoints: (builder) => ({
        // Get All User API
        getAllUsers: builder.query({
            query: (payload) => ({ url: `user`, method: "GET" }),
            transformResponse: (response, meta, arg) => {
                if (response.code === 200 && response.is_success) {
                    return response.data;
                }
                return [];
            },
        }),

        // spending Listing units
        spendingListing: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `statistics_listing/listing`, method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

        // spending Graph units
        spendingGraph: builder.query(

            {
                query: (payload) => {
                    return {
                        url: `statistics_spending/spending`, method: "POST", body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

        // spending Drawer units
        spendingDrawer: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `statistics_unit_listing/unit_listing`, method: "POST",  body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {
                    
                    if (response.type === "success") {
                        return response
                    }
                    
                },
            }),






    }),
});

// Export hooks for usage in functional components
export const {
    useLazySpendingListingQuery,
    useLazySpendingGraphQuery,
    useLazySpendingDrawerQuery,
} = statisticsAPI;
