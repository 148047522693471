
import { ContractDetailsStyle } from "./style"
import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Download } from "../../assets";
import { withTranslation } from 'react-i18next';
import moment from "moment-timezone";

const Details = (
    {
        contractor = "",
        account_no = "",
        start_date = "",
        end_date = "",
        contract_no = "",
        logo = "",
        unit_count = "",
        property_count = "",
        commission = [],
        currency_code = "",
        t = () => false
    }
) => {
    return (
        <Box sx={ContractDetailsStyle?.rootDetails}>
            <Box sx={ContractDetailsStyle?.entireBox}>
                <Stack sx={ContractDetailsStyle?.firstStack}
                    direction="row" alignItems="center" justifyContent="space-between">
                    <Grid item xs={3}>
                        <Stack direction="row" alignItems="center">
                            <Box sx={ContractDetailsStyle?.imgContainer}>
                                <img src={logo} alt="img" width="100%" height="100%" />
                            </Box>
                            <Box sx={ContractDetailsStyle?.marginItem}>
                                <Typography sx={ContractDetailsStyle?.contractor}>{contractor}</Typography>
                                <Typography sx={ContractDetailsStyle?.unitId}>{account_no}</Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={ContractDetailsStyle?.downloadBtn}>
                            <Download />
                        </Box>
                    </Grid>

                </Stack>
                <Stack sx={ContractDetailsStyle?.secondStack}
                    direction="row" alignItems="center" justifyContent="space-between">
                    <Box sx={ContractDetailsStyle?.stackItems}>
                        <Typography sx={ContractDetailsStyle?.dataHead}>{t("Contract ID")}</Typography>
                        <Typography sx={ContractDetailsStyle?.dataValue} >{contract_no}</Typography>
                    </Box>
                    <Divider orientation="vertical" />
                    <Box sx={ContractDetailsStyle?.stackItems}>
                        <Typography sx={ContractDetailsStyle?.dataHead}>{t("Contract Start & End Date")}</Typography>
                        <Typography sx={ContractDetailsStyle?.dataValue}>
                            {`${moment(start_date).format("DD MMM YY")} - ${moment(end_date).format("DD MMM YY"
                            )}`}
                        </Typography>
                    </Box>
                </Stack>
                <Box sx={ContractDetailsStyle?.categoryBox} style={{  borderBottom: commission?.length ? "1px solid #E4E8EE":"none",
}}>
                    <Typography sx={ContractDetailsStyle?.categoryHead}> {t("Scope Of Work")}</Typography>
                    <Stack sx={ContractDetailsStyle?.commonStack}
                        direction="row" alignItems="center" justifyContent="space-between">
                        <Typography>{t("Property")}</Typography>
                        <Typography>{property_count}</Typography>
                    </Stack>
                    <Stack sx={ContractDetailsStyle?.commonStack}
                        direction="row" alignItems="center" justifyContent="space-between">
                        <Typography>{t("Unit")}</Typography>
                        <Typography>{unit_count}</Typography>
                    </Stack>
                </Box>
                {
                    commission?.length ? <Box>
                        <Typography sx={ContractDetailsStyle?.categoryHead}>{t("Commission Rate Configuration")}</Typography>
                        <Typography sx={ContractDetailsStyle?.dataHead}>
                            {commission?.[0]?.commission_rate_type}</Typography>
                        {
                            commission?.map((e) => {
                                return (
                                    <Stack sx={ContractDetailsStyle?.commonStack}
                                        direction="row" alignItems="center"
                                        justifyContent="space-between">
                                        <Typography>{t(e?.name)}</Typography>
                                        <Typography>{e?.value} {e?.currency_code}</Typography>
                                    </Stack>

                                )
                            })
                        }
                    </Box> :
                        ""
                }
                
            </Box >
        </Box >
    )
}
export default withTranslation('contract')(Details);