import * as React from "react"
const Arrow = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 4470">
      <g fill="#f2f4f7" stroke="#e4e8ee" data-name="Ellipse 39585">
        <circle cx={16} cy={16} r={16} stroke="none" />
        <circle cx={16} cy={16} r={15.5} fill="none" />
      </g>
      <path
        fill="#071741"
        stroke="#071741"
        strokeWidth={0.5}
        d="m13.653 11.475 4.588 5.025-4.588 5.024a.61.61 0 0 0 0 .808.49.49 0 0 0 .738 0l4.956-5.432a.61.61 0 0 0 0-.808l-4.957-5.429a.49.49 0 0 0-.738 0 .61.61 0 0 0 .001.812Z"
      />
    </g>
  </svg>
)
export default Arrow
