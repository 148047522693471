import { Bold, Regular } from "../../utils";

export const contactDetailStyles ={
    root: {
        padding: "0px 0px 8px 0px",
        backgroundColor: "primary.contrastText"
    },
    iconBox: {
        padding: "12px",
        border: "1px solid #CED3DD",
        borderRadius: "8px"
    },
    divider: {
        marginInline: "8px"
    },
    text: {
        color: "text.main",
        fontSize: "0.875rem",
        fontFamily: Bold
    },
    subText: {
        color: "text.Teritary",
        fontFamily: Regular,
        fontSize: "0.75rem"
    }
}