import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const inspectionSlice = createSlice({
    name: 'inspection',
    initialState,
    reducers: {
        setInspection(state, { payload }) { state = payload; }
    },
})

export const { setInspection } = inspectionSlice.actions;
export default inspectionSlice.reducer;