import { Bold } from "../../utils";



export const ProfilePictureEditStyle = {
    title: {
        textAlign: "left",
        fontFamily: Bold,
        fontSize: "0.875rem",
        letterSpacing: "0px",
    },
    avatarOuter: {
        height: "128px",
        width: "128px",
        border: "2px solid",
        borderColor: 'background.main',
        // margin:"0 auto"
    },
    avatarInner: {
        border: "3px solid",
        borderColor: 'primary.contrastText',
        backgroundColor: "border.secondary",
        marginTop: "90px",
        marginLeft: "-25px",
        height: "30px",
        width: "30px",
        cursor:"pointer"
    },
    image: {
        height: "167px",
        width: "167px", 
    },
    profilePicturePadding:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center"

    }
    
    
};
