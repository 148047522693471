import { TopDrawerTab_style } from "./style"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Typography } from '@mui/material'
import React from 'react'
import { DialogDrawer } from "../dialogdrawer"
export const TopDrawerTab = ({
  title = "",
  t = () => false,
  header = null,
  component = null,
  data = [],
  drawer = false,
  selectedAccount = {},
  updateAccounts = () => false,
  handleDrawerClose = () => false,
  handleDrawerOpen = () => false
}) => {
  return (
    <Box>
      <Box sx={TopDrawerTab_style?.sx} onClick={handleDrawerOpen}>
        <Typography sx={TopDrawerTab_style?.text}>{selectedAccount?.account_no}</Typography>
        <KeyboardArrowDownIcon />
      </Box>
      <DialogDrawer
        maxWidth="sm"
        open={drawer}
        onClick={handleDrawerClose}
        header={header ?? t("Accounts")}
        height_style={
          { height: "350px !important" }
        }
        component={component ?? component}
        onClose={() => handleDrawerClose(false)}
      />
    </Box>
  )
}