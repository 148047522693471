import { Typography, Box } from "@mui/material"
import { CheckIcon } from "../../assets"
import { InvoiceComplaintCard_style } from "./style"
import moment from "moment-timezone";
import { currencyFormatted } from "../../utils";


export const InvoiceComplaintCard = ({
    handleChecked = () => null,
    deleteDrawer = false,
    dropcomponent = null,
    InvoicecardStyle = {},
    changecomponent = null,
    isStatus = false,
    handleDelete = () => null,
    data = {},
    is_checked = false
}) => {
    return <>
        {
            deleteDrawer ?
                (<Box sx={{ ...InvoiceComplaintCard_style?.main, ...InvoicecardStyle }}>
                    {/* invoicedata */}
                    <Box sx={InvoiceComplaintCard_style?.sx}>
                        <Box>
                            <Typography sx={InvoiceComplaintCard_style?.title} noWrap>{data?.description}</Typography>
                            <Box sx={InvoiceComplaintCard_style?.singleline}>
                                <Typography sx={InvoiceComplaintCard_style?.text} noWrap>{data?.invoice_no}</Typography>
                                <Box sx={InvoiceComplaintCard_style?.dot}></Box>
                                {changecomponent ?? (
                                    <Typography sx={InvoiceComplaintCard_style?.text} noWrap>
                                        {moment(data?.payment_due_date ?? data?.payment_due_date).format(
                                            "DD-MM-yy"
                                        )}</Typography>
                                )}
                            </Box>
                        </Box>

                    </Box>
                    {/* amount */}
                    <Box sx={InvoiceComplaintCard_style?.amtOverall}>
                        <Box sx={InvoiceComplaintCard_style?.check}>
                            <Typography sx={InvoiceComplaintCard_style?.price} noWrap>
                                {data?.symbol} {currencyFormatted(data?.invoice_due_amount)}</Typography>
                            <Box sx={InvoiceComplaintCard_style?.bg}>
                                {isStatus && <Typography sx={InvoiceComplaintCard_style?.statusText}>
                                    {data?.status}</Typography>}
                            </Box>
                        </Box>
                        <Box>

                        </Box>

                        <Box onClick={handleDelete}>{dropcomponent}</Box>

                    </Box>


                </Box>)
                : <Box sx={{ ...InvoiceComplaintCard_style?.main, ...InvoicecardStyle }}
                    onClick={() => { handleChecked() }}>
                    {/* invoicedata */}
                    <Box sx={InvoiceComplaintCard_style?.sx}>
                        <Box>
                            {is_checked && (
                                <Box sx={InvoiceComplaintCard_style?.check} >
                                    <CheckIcon />
                                </Box>
                            )}
                        </Box>
                        <Box>
                            <Typography sx={InvoiceComplaintCard_style?.title} noWrap>{data?.description}</Typography>
                            <Box sx={InvoiceComplaintCard_style.singleline}>
                                <Typography sx={InvoiceComplaintCard_style.text} noWrap>{data?.invoice_no}</Typography>
                                <Box sx={InvoiceComplaintCard_style.dot}></Box>
                                {changecomponent ?? (
                                    <Typography sx={InvoiceComplaintCard_style.text} noWrap>
                                        {moment(data?.payment_due_date ?? data?.payment_due_date).format(
                                            "DD-MM-yy"
                                        )}</Typography>
                                )}
                            </Box>
                        </Box>

                    </Box>
                    {/* amount */}
                    <Box sx={InvoiceComplaintCard_style?.amtOverall}>
                        <Box sx={InvoiceComplaintCard_style?.check}>
                            <Typography sx={InvoiceComplaintCard_style?.price} noWrap>
                                {data?.symbol} {data?.invoice_due_amount}</Typography>
                            <Box sx={InvoiceComplaintCard_style?.bg}>
                                {isStatus && <Typography sx={InvoiceComplaintCard_style?.statusText}>
                                    {data?.status}</Typography>}
                            </Box>
                        </Box>
                        <Box>

                        </Box>

                        <Box onClick={handleDelete}>{dropcomponent}</Box>

                    </Box>


                </Box>
        }
    </>


}