import * as React from "react"

const CallerIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <g data-name="Group 4690" transform="translate(-1977.811 16211.189)">
      <circle
        data-name="Ellipse 39546"
        cx={14}
        cy={14}
        r={14}
        transform="translate(1977.811 -16211.189)"
        fill="#46b9da"
      />
      <path
        data-name="icons8-ringing-phone (1)"
        d="M1996.785-16203.19a.464.464 0 0 0-.184.893 5.537 5.537 0 0 1 .956.515.464.464 0 1 0 .524-.766 6.447 6.447 0 0 0-1.116-.6.464.464 0 0 0-.18-.04Zm-4.794.869c-2.44 0-4.5.526-5.507 1.406a1.91 1.91 0 0 0-.647 1.784l.1.353a1.48 1.48 0 0 0 1.6 1.087l.785-.1a1.446 1.446 0 0 0 1.282-1.061l.216-.959a.257.257 0 0 1 .119-.162 8.5 8.5 0 0 1 2.052-.184 8.495 8.495 0 0 1 2.052.184.282.282 0 0 1 .127.185l.2.909a1.453 1.453 0 0 0 1.282 1.088l.8.1c.05 0 .1.007.15.007a1.467 1.467 0 0 0 1.436-1.083l.1-.334.007-.03a1.911 1.911 0 0 0-.647-1.785c-1.008-.878-3.066-1.404-5.506-1.404Zm-1.089 3.4a.464.464 0 0 0-.456.47v.8l-1.1.551a4.69 4.69 0 0 0-2.607 4.218 1.7 1.7 0 0 0 1.7 1.7h7.11a1.7 1.7 0 0 0 1.7-1.7 4.69 4.69 0 0 0-2.607-4.218l-1.1-.551v-.8a.464.464 0 1 0-.927 0v.464h-1.242v-.468a.464.464 0 0 0-.471-.47Zm-5.123 1.765a.464.464 0 0 0-.244.842 3.327 3.327 0 0 0 1.258.566.464.464 0 1 0 .2-.906 2.4 2.4 0 0 1-.906-.409.464.464 0 0 0-.258-.093h-.05Zm4.513 1.638a.464.464 0 1 1-.464.464.464.464 0 0 1 .463-.464Zm1.7 0a.464.464 0 1 1-.464.464.464.464 0 0 1 .464-.464Zm1.7 0a.464.464 0 1 1-.464.464.464.464 0 0 1 .464-.464Zm-3.401 1.545a.464.464 0 1 1-.464.464.464.464 0 0 1 .464-.464Zm1.7 0a.464.464 0 1 1-.464.464.464.464 0 0 1 .465-.464Zm1.7 0a.464.464 0 1 1-.464.464.464.464 0 0 1 .465-.464Z"
        fill="none"
      />
      <path
        data-name="icons8-phone (4)"
        d="m1991.386-16202.401.516 1.213a1.39 1.39 0 0 1-.308 1.541l-.964.914a.455.455 0 0 0-.141.4 4.9 4.9 0 0 0 .618 1.67 5.259 5.259 0 0 0 1.164 1.4.459.459 0 0 0 .44.078l1.124-.375a1.22 1.22 0 0 1 1.4.492l.74 1.086a1.393 1.393 0 0 1-.155 1.749l-.491.493a1.812 1.812 0 0 1-1.831.467q-2.285-.713-4.2-4.232t-1.359-5.983a1.956 1.956 0 0 1 1.289-1.442l.646-.206a1.238 1.238 0 0 1 1.512.735Z"
        fill="#fff"
      />
    </g>
  </svg>
)

export default CallerIcon
