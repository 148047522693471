import { Bold, Regular, SemiBold } from "../../utils"
export const Checkinout_style = {

    inspectorId: {
        fontFamily: Regular,
        color: 'text.Teritary',
        fontSize: "0.75rem"
    },
    img: {
        position: 'absolute',
        top: "0px",
        left: "0"
    },
    imgArabic: {
        position: 'absolute',
        top: "0px",
        right: "0"
    },
    inspectedDate: {
        fontSize: "0.875rem",
        fontFamily: Bold,
        color: 'text.Teritary',
        marginBottom: '4px',

    },
    align: {
        display: 'flex',
        alignItems: 'center',
        position: "relative"
    },
    marginLeft: {
        marginInlineStart: '48px',
        marginBottom: '10px'
    },
    line: {
        borderLeft: '2px dashed #ccc',
        position: 'relative',
        height: '60px',
        left: '15px',
    },
    lineArabic: {
        borderLeft: '2px dashed #ccc',
        position: 'relative',
        height: '60px',
        right: '15px',
    },
    lineBlue: {
        borderLeft: '2px dashed #F15A29',
        position: 'relative',
        height: 'calc(100vh - 663px)',
        left: '18px',
        bottom: "-20px"
    },
    progressDetails: {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        paddingBottom: "5px"
    },
    inspectedDateBold: {
        fontSize: "1rem",
        fontFamily: Bold,
        color: 'text.main',
        marginBottom: '4px',

    },
    created_at: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: "backgroundcard.primary"
    }
}