import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { LocalStorageKeys } from '../../../utils';

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_auth_base_url,
    prepareHeaders: (headers, { getState }) => {
        headers.set("x-build-code", config?.xbuildCode);
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            headers.set("Authorization", "Bearer " + token);
        }

        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const initialRendererAPI = createApi({
    reducerPath: "initialRendererAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ['InitialRenderer'],
    endpoints: (builder) => ({
        initialRender: builder.query({
            query: (payload) => ({ url: `login_domain/initial_render`, method: "POST", body: { ...payload } }),
            transformResponse: (response, meta, arg) => {
                if (response?.status === 200) {
                    return response
                }
                return []
            },
        }),
        domainList: builder.query({
            query: (payload) => ({ url: `login_domain/domain_list`, method: "POST", body: { ...payload } }),
            transformResponse: (response, meta, arg) => {
                if (response?.status === 200) {
                    return response
                }
                return []
            },
        }),
    }),
})

// Export hooks for usage in functional components
export const { useLazyInitialRenderQuery, useLazyDomainListQuery } = initialRendererAPI
