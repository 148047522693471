import * as React from "react"
const Rubbish = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14.737} {...props}>
    <path
      fill="#ff4b4b"
      d="M7 0a2.587 2.587 0 0 0-2.55 2.211H1.93a.5.5 0 0 0-.177 0h-1.2a.553.553 0 1 0 0 1.105h.787l.928 9.59a2.033 2.033 0 0 0 2.017 1.831h5.429a2.033 2.033 0 0 0 2.017-1.831l.928-9.59h.788a.553.553 0 1 0 0-1.105h-1.2a.553.553 0 0 0-.177 0H9.549A2.587 2.587 0 0 0 7 0Zm0 1.105A1.46 1.46 0 0 1 8.423 2.21H5.577A1.46 1.46 0 0 1 7 1.105ZM2.45 3.316h9.1l-.919 9.484a.914.914 0 0 1-.917.833H4.285a.915.915 0 0 1-.917-.833ZM5.7 5.15a.553.553 0 0 0-.544.561v5.526a.553.553 0 1 0 1.105 0V5.71a.553.553 0 0 0-.561-.56Zm2.579 0a.553.553 0 0 0-.544.561v5.526a.553.553 0 1 0 1.105 0V5.71a.553.553 0 0 0-.561-.561Z"
      data-name="icons8-trash (2)"
    />
  </svg>
)
export default Rubbish
