import React from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { Grid } from "@mui/material";

export const ExternalSite = () => {
    const navigate = useNavigate();
    const { state } = useLocation()

    React.useEffect(() => {
        if (!state) {
            navigate(AppRoutes.home, { replace: true });
        }
        // eslint-disable-next-line
    }, [])


    return (
        <Grid maxWidth={"599px"} height={"100%"}>
            {state
                ? <iframe
                    allow={`geolocation 'self' ${state}`}
                    id="childIframe"
                    src={state}
                    title={state} width="100%" height="100%" style={{ border: "none" }} />
                : <div />}
        </Grid>
    )
}