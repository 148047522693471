import { Box } from "@mui/system"
import { ContactCard } from "../../components"
import { ActivityCardsimmer } from "../statistics/component/activityCardsimmer";
import { MyContact_style } from "./style"
import InfiniteScroll from "react-infinite-scroll-component";
import NoData from "../../components/noData";


export const PublicContacts = ({
    // bottomSx = {},
    datas=[],
    fetchMoreDataPublic=()=>false,
    t = () => false,
    height
}) => {
    return (
        <Box>
            {datas.bool ?
                <ActivityCardsimmer count={10} />
                :
                datas?.contactListing?.length > 0 ?
                    <InfiniteScroll
                        dataLength={datas?.contactListing?.length ?? 0}
                        next={fetchMoreDataPublic}
                        hasMore={true}
                        style={MyContact_style?.infiniteScroll}
                        height={height}
                    >
                        {datas?.contactListing?.map((item, index) => {
                            return (
                                <Box sx={MyContact_style?.sx}>
                                    <ContactCard
                                        name={item?.name}
                                        tag={item?.profession}
                                        sub_text={item?.property_name}
                                        sub_text2={item?.unit_name}
                                        contact_name={`${item?.country_code} ${item?.contact_number}`}
                                        hidden={datas?.contactListing?.length === index + 1 ? false : true}
                                        hide={true}
                                        t={t}
                                        call={true}
                                    />
                                </Box>
                            )
                        })}

                    </InfiniteScroll>
                    :
                    <NoData />
            }

        </Box>
    )
}