import React from 'react';
import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar, IconButton, Badge, Stack, Box, Typography, Container, Avatar } from '@mui/material';
import { LocalStorageKeys, topNabBarData, Bold, lanuages, topNavBarTittle, img_size, generateImageUrl } from '../../../utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { DialogDrawer } from '../../dialogdrawer'
import { LanuageSwitcher } from '../../lanuageSwitcher/index';
import { withTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../../../contexts';
import { AppRoutes } from '../../../router/routes';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        zIndex: theme.zIndex.drawer + 1,
        padding: 0
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "white",
        borderBottom: (props) => props?.noBorder ? "none" : '1px solid #E4E8EE',
        boxShadow: "none !important",
        "& .MuiToolbar-root": {
            padding: "0px !important",
        }

    },
    titleContainer: {
        marginInlineStart: theme.spacing(2)
    },
    menuIcon: {
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    navbarimg: {
        height: "36px",
        marginTop: "4px",
    },
    title: {
        color: theme?.palette?.text?.main,
        fontSize: "1rem",
        fontFamily: Bold
    },
    back: {
        position: 'absolute',
        left: "8px",
        // top: "-24%",
        '& .MuiSvgIcon-root': {
            color: theme?.palette?.text?.main,
            fontSize: "1.1875rem",
        }
    },
    backArabic: {
        position: 'absolute',
        right: "8px",
        transform: "rotate(180deg)",
        // top: "-24%",
        '& .MuiSvgIcon-root': {
            color: theme?.palette?.text?.main,
            fontSize: "1.1875rem",
        }
    },
    avatar: {
        marginInlineEnd: "12px",
        height: "30px",
        width: "30px",
        cursor: "pointer"
    }
}));

const TopNavBar = ({
    title = "",
    isBack = false,
    route = () => false,
    is_dashboard = false,
    backFuction = () => false,
    t = () => false,
    isDirect = false,
    is_arrow = true,
    ...props
}) => {

    const classes = useStyles(props);
    const navigate = useNavigate();
    const language = localStorage.getItem("i18nextLng")
    const {languageSwitcher} = React.useContext(AuthContext)
    const image = localStorage.getItem("image_url");


    const [openLanguage, setOpenLanguage] = React.useState(false)
    const [selectedLanguage, setSelectedLanguage] = React.useState(lanuages?.[0])

    const updateLanguage = (data) => {
        setSelectedLanguage(data)
        saveLanguage(data)
        window.location.reload()
    }

    const openLanguageSwitcher = () => {
        let getselectedlag = props?.i18n?.language
        setSelectedLanguage(lanuages.find((val) => val?.value === getselectedlag) ?? lanuages[0])
        setOpenLanguage(true)
    }
    const saveLanguage = (language) => {
        props?.i18n.changeLanguage(language?.value);
        setOpenLanguage(false)
        languageSwitcher(language?.value)
    };

    const logo = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))
        ? JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))
            .filter((val) => val.name === "Owner")
            .find((item) => item?.company_logo !== null)
        : null;


    return (
        <Container maxWidth="sm" style={{ padding: '0px !important' }} className={classes.grow}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    {
                        is_dashboard ?
                            <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                <div className={classes.titleContainer}>
                                    {(logo?.company_logo) && (
                                        <img
                                            src={generateImageUrl(logo?.company_logo, img_size.small_square)}
                                            alt="logo"
                                            className={classes.navbarimg}
                                        />
                                    )}
                                </div>

                                <div className={classes.grow} />
                                <Box>
                                    <Stack
                                        direction="row"
                                        alignItems={"center"}
                                        // spacing={-1}
                                    >
                                        {
                                            topNabBarData()?.map((x, index) => {
                                                return (
                                                    <IconButton
                                                        aria-label="logout button"
                                                        aria-controls={"logout_button"}
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                        size="large"
                                                        onClick={() => x?.is_click ? openLanguageSwitcher() : navigate(x?.onclick)}
                                                        key={index}
                                                    >
                                                        {
                                                            x?.is_badge ? <Badge color="warning" badgeContent=" " variant="dot">
                                                                {x?.image}
                                                            </Badge> : x?.image
                                                        }

                                                    </IconButton>
                                                )
                                            })
                                        }
                                        <Avatar onClick={() => navigate(AppRoutes.profileView)} className={classes.avatar} src={image}></Avatar>

                                    </Stack>
                                </Box>
                            </Box>
                            :
                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'100%'} position={'relative'} >
                                <Typography className={classes.title}>{isDirect ? title : topNavBarTittle(t)[title]}</Typography>
                                {
                                    is_arrow && <>
                                        {
                                            isBack ?
                                                <IconButton className={language === "ar" ? classes.backArabic : classes.back} onClick={() => {
                                                    navigate(-1)
                                                }}><ArrowBackIosIcon /></IconButton>
                                                :
                                                <IconButton className={language === "ar" ? classes.backArabic : classes.back} onClick={route}>
                                                    <ArrowBackIosIcon />
                                                    </IconButton>
                                        }
                                    </>
                                }
                            </Box>
                    }

                    <DialogDrawer
                        height_style={
                            { height: "350px !important" }
                        }
                        open={openLanguage}
                        header={t("Choose Language")}
                        padding={"0px 16px 16px 16px"}
                        component={
                            <LanuageSwitcher
                                selectedLanguage={selectedLanguage}
                                updateLanguage={updateLanguage}
                                t={t}
                            />
                        }
                        onClose={() => setOpenLanguage(false)}
                    />
                </Toolbar>
            </AppBar>
        </Container>
    );
}
export default withTranslation('home')(TopNavBar);
