import * as React from "react"

 export const DeleteIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13.3} height={14} {...props}>
    <path
      data-name="icons8-trash (1)"
      d="M6.65 0a2.458 2.458 0 0 0-2.422 2.1H1.833a.472.472 0 0 0-.168 0H.525a.525.525 0 1 0 0 1.05h.749l.881 9.11A1.932 1.932 0 0 0 4.071 14h5.158a1.931 1.931 0 0 0 1.916-1.74l.882-9.11h.749a.525.525 0 1 0 0-1.05h-1.14a.525.525 0 0 0-.168 0h-2.4A2.458 2.458 0 0 0 6.65 0Zm0 1.05A1.387 1.387 0 0 1 8 2.1H5.3a1.387 1.387 0 0 1 1.35-1.05Zm-4.322 2.1h8.644l-.872 9.009a.868.868 0 0 1-.871.791H4.071a.869.869 0 0 1-.871-.791Zm3.089 1.742a.525.525 0 0 0-.517.533v5.25a.525.525 0 1 0 1.05 0v-5.25a.525.525 0 0 0-.533-.533Zm2.45 0a.525.525 0 0 0-.517.533v5.25a.525.525 0 1 0 1.05 0v-5.25a.525.525 0 0 0-.533-.533Z"
      fill="#ff4b4b"
    />
  </svg>
)
