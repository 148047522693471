import { SemiBold } from "../../utils";

export const UtilitiesStyle = {
    root: {
        padding: "16px",
        position: "relative",
        width: '100%',
        height: '100% !important',
        overflow: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },


    },
    root2: {
        padding: "16px",
        width: '100%',
        overflow: "auto",
        backgroundColor: '#F8F8F8',
        height: '100%',
        "&::-webkit-scrollbar": {
            display: "none",
        }

    },
    container: {
        backgroundColor: "background.Teritary",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
    },
    utilityListBox: {
        borderRadius: "8px",
        boxShadow: "0px 3px 30px #5C86CB2E",
        backgroundColor: "primary.contrastText",
        padding: "0px 12px",
    },
    switcherBox: {
        marginBottom: "14px"
    },
    accordianBox: {
        marginBottom: "16px"
    },
    radioLabel: {
        color: "border.primary",
        fontFamily: SemiBold,
        fontSize: "1rem"
    },
    footer: {
        position: 'sticky',
        width: "100%",
        bottom: "0"
    },
    Topnav: {
        position: 'fixed',
        top: 0,
        zIndex: 999,
        left:0,
        right:0
    },
}

