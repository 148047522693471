import { Box, InputAdornment, IconButton, Stack, Typography } from "@mui/material";
import { SerachIMG } from "../../assets";
import { TextBox } from "../textField";
import { Bold } from "../../utils";

const searchBarStyle = {

    // searchSection: {
    //     "& .MuiInputBase-root": {
    //         padding: 0,
    //         "& fieldset": {
    //             height: "50px",
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             borderColor: "",
    //         },
    //         "&.Mui-focused fieldset": {
    //             borderColor: "red",
    //         },
    //     },
    //     "& .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input": {
    //         paddingLeft: "18px",
    //         fontFamily: Regular,
    //         fontSize: "0.875rem",
    //         backgroundColor: "transparent !important",
    //         "& fieldset": {
    //             border: "none !important"
    //         }
    //     },
    //     "& .MuiOutlinedInput-root": {
    //         height: "45px",
    //     },
    //     "& .makeStyles-textbox-20 .MuiOutlinedInput-root": {
    //         // backgroundColor: useTheme?.palette?.primary?.contrastText,
    //     },
    //     "& .makeStyles-textbox-20 .MuiOutlinedInput-input": {
    //         height: "12px",
    //         // backgroundColor: useTheme?.palette?.primary?.contrastText,
    //     },
    // },
    filterIcon: {
        cursor: "pointer",
        padding:"13px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        height:"42px",
        borderRadius:"4px",
        backgroundColor:"#fff",
        border: "1px solid #5C86CB2E",

  
      },
      username:{
                fontFamily: Bold,
            fontSize: "1rem", 
      }
}

export const SearchBarFilter = ({
    placeholder = "",
    filterIcon,
    handleFilterOpen = () => false,
    handleChange = () => false,
    value = "",
    text="",
}) => {
    return (

            <Stack direction={"row"} spacing={1} alignItems="center" justifyContent={'space-between'} width={'100%'}>
                <Box width={'100%'}>
                    {
                        text ? 
                        <>
                                      <Typography sx={searchBarStyle?.username} width={'100%'}> {text}</Typography>

                        </> : <TextBox
                        onChange={handleChange}
                        value={value}
                        placeholder={placeholder}
                        fullWidth
                        startAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                >
                                    <SerachIMG />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    }
                
                </Box>
                <Box>
                {
                        filterIcon &&
                        <Box>
                         <Box onClick={() => handleFilterOpen()} sx={searchBarStyle?.filterIcon}>
                         <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14}>
    <path
      d="M.972 0A.981.981 0 0 0 0 .972v1.2a2.918 2.918 0 0 0 1.112 2.291l3.941 3.075v5.875a.583.583 0 0 0 .922.475L8.7 11.947a.583.583 0 0 0 .245-.475v-3.93l3.941-3.075A2.918 2.918 0 0 0 14 2.174v-1.2A.981.981 0 0 0 13.028 0Zm.194 1.167h11.667v1.007a1.748 1.748 0 0 1-.667 1.374L8 6.8a.583.583 0 0 0-.225.46v3.915l-1.556 1.111V7.257A.583.583 0 0 0 6 6.8L1.834 3.548a1.748 1.748 0 0 1-.667-1.374Z"
      fill={'#091b29'}
    />
  </svg>
                        </Box>
                        </Box>
                    }
                </Box>

            </Stack>
    )
}