import * as React from "react"
const AgreementDetails = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 112355" transform="translate(-32 -78)">
      <rect
        width={48}
        height={48}
        fill="#f6f1fe"
        data-name="Rectangle 57270"
        rx={8}
        transform="translate(32 78)"
      />
      <g data-name="Group 113305">
        <path
          fill="#896db3"
          d="M48.7 90a2.7 2.7 0 0 0-2.7 2.7v18.6a2.7 2.7 0 0 0 2.7 2.7h13.8a2.7 2.7 0 0 0 2.7-2.7V92.7a2.7 2.7 0 0 0-2.7-2.7h-.9v9.9a.9.9 0 0 1-1.331.79L57.4 99.125l-2.869 1.565a.9.9 0 0 1-1.331-.79V90Zm6.3 0v8.385l1.969-1.075a.9.9 0 0 1 .863 0l1.968 1.075V90Zm-3.3 12.6h7.8a.9.9 0 1 1 0 1.8h-7.8a.9.9 0 0 1 0-1.8Zm0 4.2h5.4a.9.9 0 0 1 0 1.8h-5.4a.9.9 0 0 1 0-1.8Z"
          data-name="icons8-document (1)"
        />
      </g>
    </g>
  </svg>
)
export default AgreementDetails
