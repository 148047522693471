export const RequestPlaceHolder = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={70} height={70} {...props}>
    <g data-name="Group 117579" transform="translate(-28 -591)">
      <rect
        width={70}
        height={70}
        fill="#fff8e7"
        data-name="Rectangle 45496"
        rx={8}
        transform="translate(28 591)"
      />
      <g data-name="Group 117550">
        <g data-name="Group 98409">
          <path
            fill="#91bb51"
            d="m51.3 609.679-2.934-.746-.699-2.933H47.6l-.666 2.934-2.934.745v.092l2.934.6.656 2.929h.083l.695-2.934 2.934-.59ZM82.94 642.982l-2.444-.621-.584-2.445h-.056l-.555 2.445-2.445.621v.076l2.445.5.547 2.445h.069l.579-2.445 2.445-.492Z"
            data-name="icons8-plus-math (1)"
          />
          <path
            fill="#c92b03"
            d="M57.156 634.911h-1.8a.3.3 0 0 1-.293-.388l1.043-3.651a.3.3 0 0 1 .292-.22h1.8a.3.3 0 0 1 .293.388l-1.043 3.651a.3.3 0 0 1-.292.22Z"
            data-name="Path 93484"
          />
          <path
            fill="#c92b03"
            d="M69.784 634.911h1.8a.3.3 0 0 0 .293-.388l-1.043-3.651a.3.3 0 0 0-.292-.22h-1.8a.3.3 0 0 0-.293.388l1.043 3.651a.3.3 0 0 0 .292.22Z"
            data-name="Path 93485"
          />
          <path
            fill="#fcde5a"
            d="M72.597 618.196a2.006 2.006 0 0 0-1.827-2.147h-5.475a2.006 2.006 0 0 0-1.825 2.147v10.022h9.127Z"
            data-name="Path 93486"
          />
          <path
            fill="#fcde5a"
            d="M63.47 618.196a2.006 2.006 0 0 0-1.827-2.147h-5.475a2.006 2.006 0 0 0-1.825 2.147v10.022h9.127Z"
            data-name="Path 93487"
          />
          <path
            fill="#f6a802"
            d="M72.596 628.218H54.343v-2.434a1.217 1.217 0 0 1 1.217-1.217h15.819a1.217 1.217 0 0 1 1.217 1.217Z"
            data-name="Path 93488"
          />
          <path
            d="M70.835 630.873a.3.3 0 0 0-.293-.221h-1.8a.3.3 0 0 0-.293.388l.411 1.438h2.434Z"
            data-name="Path 93490"
            opacity={0.05}
          />
          <path
            d="M70.835 630.873a.3.3 0 0 0-.293-.221h-1.8a.3.3 0 0 0-.293.388l.324 1.134h2.434Z"
            data-name="Path 93491"
            opacity={0.07}
          />
          <path
            d="M56.117 630.873a.3.3 0 0 1 .293-.221h1.8a.3.3 0 0 1 .293.388l-.407 1.437h-2.437Z"
            data-name="Path 93492"
            opacity={0.05}
          />
          <path
            d="M56.118 630.873a.3.3 0 0 1 .292-.221h1.8a.3.3 0 0 1 .293.388l-.324 1.134h-2.433Z"
            data-name="Path 93493"
            opacity={0.07}
          />
          <g fill="#f8db59" data-name="Group 117621">
            <path
              d="M54.343 631.869h-.608a2.434 2.434 0 0 1-2.434-2.434v-7.91a.609.609 0 0 1 .608-.608h1.826a.609.609 0 0 1 .608.608Z"
              data-name="Path 93489"
            />
            <path
              d="M75.03 620.917h-1.829a.609.609 0 0 0-.608.608v6.692H51.301v1.217a2.434 2.434 0 0 0 2.434 2.434h19.466a2.434 2.434 0 0 0 2.434-2.434v-7.91a.609.609 0 0 0-.605-.607Z"
              data-name="Path 93494"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)