import { Typography, Box } from "@mui/material"
import { CheckIcon, Docs } from "../../assets"
import { AgreementCard_style } from "./style"
export const AgreementCard = ({
    checked = false,
    updateAccounts = () => false,
    data = [],
    selectedAccount={},
    t = () => false
}) => {

    return data?.map((x, index) => {
        return <Box key={index} onClick={() => updateAccounts(x)}>
            <Box sx={AgreementCard_style?.mainSx} flexWrap>
                <Box sx={AgreementCard_style?.sx}>
                    <Box sx={AgreementCard_style?.docsBg}>
                        <Docs />
                    </Box>
                    <Box>
                        <Box sx={AgreementCard_style?.codeSec}>
                            <Typography sx={AgreementCard_style?.agreementnumber} noWrap>
                                {x?.account_no}</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={AgreementCard_style?.check}>
                    {x?.account_no === selectedAccount?.account_no ? (
                        <CheckIcon />
                    ) : (
                        <img src="/images/arrownew.svg" alt="agreement_img" />
                    )}
                </Box>
            </Box>
            <Box sx={AgreementCard_style?.divider}></Box>
        </Box>
    })
}

