import React from "react";
import { InvestmentScreen } from "./investment";
import { withTranslation } from 'react-i18next';

class InspectionParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return <InvestmentScreen  {...this?.props} />;
    }
}
export default withTranslation('investment')(InspectionParent);
