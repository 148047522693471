import * as React from "react"
const LastService = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 116870" transform="translate(-49 -896)">
      <rect
        width={24}
        height={24}
        fill="#fef4f4"
        data-name="Rectangle 58383"
        rx={4}
        transform="translate(49 896)"
      />
      <g data-name="icons8-service (2)">
        <path
          fill="#607d8b"
          d="M55.801 915.444c.044-.044.577-.621 1.42-1.509 1.243-1.287 6.657-7.057 6.746-7.145.4-.4 2.308 1.065 3.906-.533a3.683 3.683 0 0 0 .928-3.861l-2.042 2.042a2.462 2.462 0 0 1-2.219-2.219l2.042-2a3.8 3.8 0 0 0-3.906.932c-1.554 1.556-.175 3.506-.575 3.906-.089.044-7.678 7.279-7.767 7.367-.754.71-.8.754-.843.754a1.718 1.718 0 0 0-.044 2.352 1.678 1.678 0 0 0 2.354-.086Zm-1.025-1.908a.666.666 0 1 1-.666.666.654.654 0 0 1 .666-.666Z"
          data-name="Path 101490"
        />
        <path
          fill="#b0bec5"
          d="M68.09 913.758 56.772 902.53l-1.109-1.642-2.042-.888-.621.621.888 2.042 1.642 1.109L66.847 915Z"
          data-name="Path 101491"
        />
        <path
          fill="#ffc107"
          d="M62.098 906.568c-.932-2.219-1.465-1.021-2.53 0-1.021 1.021-2.219 1.553 0 2.53 2.175.932 4.571 5.193 5.5 6.169a2.06 2.06 0 0 0 3.151 0 2.061 2.061 0 0 0 0-3.151c-.928-.932-5.189-3.328-6.121-5.548Z"
          data-name="Path 101492"
        />
        <path
          fill="#ff9800"
          d="M59.569 906.568c1.021-1.021 1.553-2.219 2.53 0l-2.53 2.53c-2.175-.932-1.021-1.464 0-2.53Z"
          data-name="Path 101493"
        />
      </g>
    </g>
  </svg>
)
export default LastService
