import * as React from "react"

export const ListFile = (props) => (

    <svg xmlns="http://www.w3.org/2000/svg" width={42} height={42} {...props}>
        <g data-name="Group 105879" transform="translate(-14 -218)">
            <rect
                data-name="Rectangle 45496"
                width={42}
                height={42}
                rx={8}
                transform="translate(14 218)"
                fill="#f5f7fa"
            />
            <g data-name="Group 104990">
                <path
                    data-name="Path 97708"
                    d="M45.4 231.51H33.7L31.1 229h-6.5a2.564 2.564 0 0 0-2.6 2.51v5.021h26v-2.51a2.564 2.564 0 0 0-2.6-2.511Z"
                    fill="#ffb73f"
                />
                <path
                    data-name="Path 97709"
                    d="M45.4 231.51H24.6a2.558 2.558 0 0 0-2.6 2.5v12.491a2.558 2.558 0 0 0 2.6 2.5h20.8a2.558 2.558 0 0 0 2.6-2.5V234.01a2.558 2.558 0 0 0-2.6-2.5Z"
                    fill="#ffe9a6"
                />
            </g>
        </g>
    </svg>
)