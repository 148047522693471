import { Avatar, Box, Container, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { AmmenityBookingCard, TopNavBar } from "../../components";
import { useLazyGetAmenitiesListingQuery } from "../../redux/services/amenities";
import { AmenityCardsimmer } from "./components/amenitiesListing";
import { AmenitiesBookingStyle } from "./style";
import { useLocation } from "react-router-dom";
import NoData from "../../components/noData";
import { errorRoute } from "../../utils";



export const Amenities = ({
    t = () => false,
}) => {
    const location = useLocation();
    const [GetAmenitiesListing , {error}] = useLazyGetAmenitiesListingQuery();
    const [datas, setDatas] = useState({
        amenitiesListing: [],
        bool: true
    })

    const getAmenitiesList = async (payload, isFilter) => {
        const data = await GetAmenitiesListing(payload)
        setDatas({
            ...datas,
            amenitiesListing: isFilter ? datas?.amenitiesListing.concat(data?.data?.data) : data?.data?.data,
            bool: false
        })
    }
    useEffect(() => {
        getAmenitiesList({ property_id: location?.state })
        // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        if(error?.status === 423) return errorRoute() 
     },[error])
    return (
        <Container maxWidth="sm"
            sx={{padding:"0px !important"}}
        >
            {/* topNavbar */}

         <Box  sx={{
        paddingTop: '56px', // Default style
        '@media screen and (min-width: 600px)': {
          paddingTop: '67px', // Media query style for `md` breakpoint
        },
      }}>
         <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
                <Box sx={AmenitiesBookingStyle?.topNav}>
                    <TopNavBar title={t("Amenities")} isDirect isBack t={t} />
                </Box >
            </Container>


            <Box sx={AmenitiesBookingStyle?.root}>

                {/* Top Section */}
                {datas?.bool ?
                    <AmenityCardsimmer amount />
                    :
                    <Box>

                        {datas?.amenitiesListing?.length > 0 ?
                            datas?.amenitiesListing?.map((item, index) => {
                                return (
                                    <>
                                        <Box sx={AmenitiesBookingStyle?.amenityBox}>
                                            <Stack direction={"row"} alignItems={"center"} spacing={2}>
                                                <Avatar sx={AmenitiesBookingStyle?.avatar} src={item?.property_logo} alt='No Image' />
                                                <Stack>
                                                    <Typography sx={AmenitiesBookingStyle?.title}>{item?.property_name}</Typography>
                                                    <Typography sx={AmenitiesBookingStyle?.subTitle}>{item?.address?.area}, {item?.address?.district}</Typography>
                                                </Stack>
                                            </Stack>

                                        </Box>
                                        {/* Amenity Title */}
                                        {item?.facilities?.length > 0 ?
                                            <Box sx={AmenitiesBookingStyle?.amenityListBox}>
                                                <Typography sx={AmenitiesBookingStyle?.slotTitle}> {t("AVAILABLE AMENITIES")}</Typography>
                                                <Box sx={AmenitiesBookingStyle?.availableAmentities}>
                                                    {item?.facilities?.map((x, index) => {
                                                        return (
                                                            <AmmenityBookingCard
                                                                title={x?.facility_type?.amenities_name}
                                                                subTitle={x?.period}
                                                                value={`${x?.rate === 0 ? "Free" : `${x?.currency?.symbol ? x?.currency?.symbol : ""} ${x?.rate}/hr`}`}
                                                                image={x?.facility_type?.assets?.[0]?.url}
                                                            />
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                            :
                                            <NoData style={{ marginTop: "24% !important" }} />
                                        }



                                    </>
                                )
                            })
                            :
                            <NoData style={{ display: "flex", alignItems: "center", height: "calc(100vh - 220px)" }} />
                        }

                    </Box>
                }
            </Box>
         </Box>
        </Container >

    );
};
