import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    backdrop: {
        
    },
    sideNavBar: {
        open: false
    },
    refreshApi: false,
}

const utilsSlice = createSlice({
    name: 'utils',
    initialState,
    reducers: {
        toogleNavBar(state, { payload }) {
            state.sideNavBar.open = !state.sideNavBar.open;
        },
        refreshApiAll(state,{payload}){
state.refreshApi= !state.refreshApiAll
        }
    },
})

export const { toogleNavBar,refreshApiAll } = utilsSlice.actions;
export default utilsSlice.reducer;