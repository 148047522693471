import * as React from "react"

export const BathDrawer = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 96768">
            <g data-name="Group 96767">
                <path
                    data-name="Rectangle 53045"
                    d="M4.408 12.001h16.817v3.207a4 4 0 0 1-4 4H7.408a3 3 0 0 1-3-3v-4.207Z"
                    fill="#ced3dd"
                />
                <path
                    data-name="icons8-bathtub (1)"
                    d="M8.235 3.604a3.065 3.065 0 0 0-3.056 3.055v4.365H3.651a.655.655 0 0 0-.156 1.292l.946 5.516a2.846 2.846 0 0 0 2.056 2.258.655.655 0 0 0 1.265.1h9.674a.655.655 0 0 0 1.265-.1 2.847 2.847 0 0 0 2.057-2.259l.946-5.516a.655.655 0 0 0-.156-1.292H6.489V6.659a1.742 1.742 0 0 1 3.482-.1 1.969 1.969 0 0 0-1.25 1.41.37.37 0 0 0 .37.439h3.09a.37.37 0 0 0 .37-.439 1.968 1.968 0 0 0-1.266-1.414 3.063 3.063 0 0 0-3.05-2.951Zm-3.408 8.73h15.545l-.9 5.278a1.518 1.518 0 0 1-1.408 1.261h-.014a.655.655 0 0 0-.1.009H7.251a.655.655 0 0 0-.117-.009 1.517 1.517 0 0 1-1.4-1.26Z"
                    fill="#4e5a6b"
                    stroke="#4e5a6b"
                    strokeWidth={0.25}
                />
            </g>
        </g>
    </svg>
)

export default BathDrawer
