import * as React from "react";

export const ManChat = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19.979} height={18} {...props}>
    <path
      d="M11.654 0a1.577 1.577 0 0 0-1.575 1.575v6.75a.675.675 0 0 0 1.152.477l2.052-2.052h5.12a1.577 1.577 0 0 0 1.575-1.575v-3.6A1.577 1.577 0 0 0 18.403 0ZM4.977 5.062a3.122 3.122 0 1 0 3.122 3.122 3.125 3.125 0 0 0-3.122-3.122Zm-3.415 7.087a1.521 1.521 0 0 0-1.566 1.467V15c0 1.708 2.14 3 4.978 3s4.978-1.289 4.978-3v-1.384a1.52 1.52 0 0 0-1.565-1.467Z"
      fill="#fff"
    />
  </svg>
);

