import React from "react";
import { withTranslation } from "react-i18next";
import { Assests } from "./assets";

class AssetsParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return <Assests {...this?.props} />;
    }
}

export default withTranslation('asset')(AssetsParent);