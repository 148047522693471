import React from "react";
import { SurveyList } from "./surveyList";
import { withTranslation } from "react-i18next";

class SurveyListParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return <SurveyList {...this?.props} />;
    }
}
export default withTranslation('survey')(SurveyListParent);