import * as React from "react"

export const Phone = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19.596}
    height={19.595}
    {...props}
  >
    <path
      d="M13.038 13.108a.894.894 0 0 0-.88-.236l-1.594.45a.891.891 0 0 1-.96-.32 23.16 23.16 0 0 1-1.5-2.23 23.16 23.16 0 0 1-1.18-2.413.891.891 0 0 1 .203-.99l1.188-1.156a.9.9 0 0 0 .236-.882L7.46 1.35a.9.9 0 0 0-.85-.662 2.963 2.963 0 0 0-1.551.445c-1.972 1.138-3.502 3.54.779 10.955s7.124 7.294 9.096 6.155a2.963 2.963 0 0 0 1.162-1.123.9.9 0 0 0-.147-1.063Z"
      fill="#7c8594"
    />
  </svg>
)
