import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";

const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });


export const ParkingArea = createApi({
    reducerPath: "ParkingAreaAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["ParkingArea"],
    endpoints: (builder) => ({
        // Get Parking Slot Listing API
        GetParkingListing: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `owner_parking_area/get_units`, method: "POST", body: payload
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return [];
                },
            }),
        GetOwnerListing: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `resident/access_unit`, method: "POST", body: payload
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return [];
                },
            }),


    }),
});

// Export hooks for usage in functional components
export const {
    useLazyGetParkingListingQuery,
    useLazyGetOwnerListingQuery
} = ParkingArea;