import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme?.palette?.text?.secondary,
    fontSize: (props) => props?.labelSize ?? theme?.palette?.labelFontSize,
    fontFamily: (props) => (props?.fontFamily === "bold" ? Bold : SemiBold),
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
  calander: {
    position: 'absolute',
    top: "51%",
    right: (props) => props?.right ?? '15px',
  },
  calendarArabic: {
    position: 'absolute',
    top: "51%",
    left: (props) => props?.right ?? '15px',
  },
  textbox: {
    backgroundColor: theme?.palette?.error?.contrastText,
    borderRadius: theme?.palette?.borderRadius ?? 4,
    border: `1px solid ${theme?.palette?.border?.main}`,
    [`& fieldset`]: {
      borderRadius: theme?.palette?.borderRadius ?? 4,
      height: (props) => (props.multiline ? "unset" : 48),
      border: `1px solid ${theme?.palette?.border?.main}`,
      "&:hover": {
        border: `1px solid ${theme?.palette?.primary?.main}}`,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => props?.padding ?? "11px 14px",
      backgroundColor: theme?.palette?.error?.contrastText,
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme?.palette?.error?.contrastText,
      fontFamily: SemiBold,
      '&:hover fieldset': {
        borderColor: theme?.palette?.border?.main,
      },

    },
    "&:hover": {
      border: `1px solid ${theme?.palette?.primary?.main}`,
    },
  },
  calendarText: {
    cursor: "pointer"
  }
}));