import { InvoiceCard_Style } from "./style";
import { Box, Typography } from "@mui/material";
import { ActionCard } from "../actionCards";
import { ManChat } from "../../assets";
export const InvoiceCard = ({
  title = " ",
  amt = "",
  overdueamount = "",
  paytype = "",
  amttype = "",
  btnText = "",
  handlePayNow = () => false,
  invoicecomponent = null,
  data = [],
  selectedInvoices = () => null,
  handlePaymentDrawerClose = () => null,
  t = () => false
}) => {
  return (
    <>
      <Box sx={InvoiceCard_Style?.sx} >
        {invoicecomponent ?? (
          <Box sx={InvoiceCard_Style?.header}>
            <Typography sx={InvoiceCard_Style?.title} noWrap>{title}</Typography>
          </Box>
        )}
        <Box sx={InvoiceCard_Style?.body}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={InvoiceCard_Style?.amt}>{amt}</Typography>
            {/* <Button
              sx={InvoiceCard_Style?.btn}
              variant="contained"
              onClick={() => handlePayNow()}
              endIcon={<ChevronRightIcon />}
            >
              <Typography>{btnText}</Typography>
            </Button> */}
          </Box>
          <Typography sx={InvoiceCard_Style?.outstandText}>
            {amttype}
          </Typography>
          <Box sx={InvoiceCard_Style?.noteBg}>
            <Typography sx={InvoiceCard_Style?.overdue} noWrap>
              {overdueamount}
            </Typography>
          </Box>
          <Typography sx={InvoiceCard_Style?.quickpay} noWrap>{paytype}</Typography>
          <Box sx={InvoiceCard_Style.actionBox} >
            {data?.map((x, index) => {
              return (
                <Box key={index}
                  onClick={() => selectedInvoices(x)}>
                  <ActionCard
                    t={t}
                    image={<ManChat />}
                    duetext={x?.over_due_days ? x?.over_due_days : null}
                    is_Overdue={x?.is_Overdue}
                    type={x?.type}
                    amount={x?.invoice_total_amount}
                    code={x?.code}
                    purpose={x?.invoice_type}
                    purposecode={x?.invoice_no}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};
