import React, { useEffect, useState } from "react";
import { Stack, Typography, Box, Divider } from "@mui/material";
import { useLazySpendingDrawerQuery } from "../../redux/services";
import { Slider } from "../slider";
import { ActivityModal_style } from "./style";
import moment from "moment-timezone";
import { LoderSimmer } from "../simmer";
import { FooterComponent } from "../footerComponent";
import { AppRoutes } from "../../router/routes";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

const ActivityModal = ({
  id,
  t = () => false,
  onClose
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    viewDrawerDetails: {},
    bool: true,
    drawer: false

  })
  const [GetSpendingDrawer] = useLazySpendingDrawerQuery();

  // Get Spendding Drawer
  const getSpendingDrawer = async (payload) => {
    const drawerRes = await GetSpendingDrawer(payload)
    const drawerdataListing = drawerRes?.data?.data[0]
    setData({
      ...data,
      bool: false,
      viewDrawerDetails: {
        ...drawerdataListing, assets: drawerdataListing?.assets?.map((x) => {
          return {
            ...x,
            file_meta: JSON.parse(x?.file_meta)
          }
        })

      }
    })
  }



  // handle Drawer Edit
  const handleDrawerEdit = () => {
    navigate(AppRoutes.createSpending, { state: data?.viewDrawerDetails })
  }

  useEffect(() => {
    getSpendingDrawer({ id: id })
    // eslint-disable-next-line
  }, [])


  return (
    <>
      <Box sx={ActivityModal_style?.root}>
        <Box sx={ActivityModal_style?.main} style={{ padding: "16px" , height:"500px",overflow:"auto"}}>
          <Stack spacing={0.5}>
            {data?.bool ?
              <LoderSimmer card count={1} size={100} variant={"rectangular"} animation={"wave"} />
              :
              <>
                {data?.viewDrawerDetails?.assets &&
                  <Box sx={ActivityModal_style?.imageSc} >
                    <Slider
                      assets={data?.viewDrawerDetails?.assets}
                    />

                  </Box>

                }
              </>
            }
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack>

                <Typography sx={ActivityModal_style?.totalAmount}>
                  {t("Total Amount")}
                </Typography>
                {data?.bool ?
                  <LoderSimmer card count={1} size={20} variant={"rectangular"} animation={"wave"} />
                  :
                  <>
                    <Typography sx={ActivityModal_style?.money}>{data?.viewDrawerDetails?.currency_id} {data?.viewDrawerDetails?.amount}</Typography>
                  </>
                }
              </Stack>
              <Stack>
                {data?.bool ?
                  <LoderSimmer card count={1} size={20} variant={"rectangular"} animation={"wave"} />
                  :
                  <>
                    <Typography sx={ActivityModal_style?.drawerActivity}>
                      {data?.viewDrawerDetails?.sub_category_name}
                    </Typography>
                  </>
                }
              </Stack>
            </Stack>
            <Typography sx={ActivityModal_style?.descirption}>
              {data?.viewDrawerDetails?.description}
            </Typography>
          </Stack>
          <Divider sx={ActivityModal_style?.dividerSec} />
          {/* CONTACT NAME */}
          <Stack mt={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography sx={ActivityModal_style?.information}>{t("Contact Name")}</Typography>
            {data?.bool ?
              <LoderSimmer card count={1} size={20} variant={"rectangular"} animation={"wave"} />
              :
              <>
                <Typography sx={ActivityModal_style?.assist}>{data?.viewDrawerDetails?.contact_name}</Typography>
              </>
            }
          </Stack>
          {/* UNIT NUMBER */}
          <Stack mt={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography sx={ActivityModal_style?.information}>{t("Unit Name")}</Typography>
            {data?.bool ?
              <LoderSimmer card count={1} size={20} variant={"rectangular"} animation={"wave"} />
              :
              <Typography sx={ActivityModal_style?.assist}>{data?.viewDrawerDetails?.unit_name}</Typography>
            }

          </Stack>
          {/* CATEGORY */}
          <Stack mt={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography sx={ActivityModal_style?.information}>{t("Category")}</Typography>
            {data?.bool ?
              <LoderSimmer card count={1} size={20} variant={"rectangular"} animation={"wave"} />
              :
              <>
                <Typography sx={ActivityModal_style?.assist}>{data?.viewDrawerDetails?.category_name}</Typography>
              </>
            }
          </Stack>
          {/* DATE */}
          <Stack mt={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography sx={ActivityModal_style?.information}>{t("Date")}</Typography>
            {data?.bool ?
              <LoderSimmer card count={1} size={20} variant={"rectangular"} animation={"wave"} />
              :
              <>
                <Typography sx={ActivityModal_style?.assist}>{moment(data?.viewDrawerDetails?.created_at).format(
                  "DD-MMM-YY"
                )}</Typography>
              </>
            }

          </Stack>
          {/* USE */}
          <Stack mt={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography sx={ActivityModal_style?.information}>
              {t("Personal/Business")}
            </Typography>
            {data?.bool ?
              <LoderSimmer card count={1} size={20} variant={"rectangular"} animation={"wave"} />
              :
              <>
                <Typography sx={ActivityModal_style?.assist}>
                  {data?.viewDrawerDetails?.usage_type}</Typography>
              </>
            }
          </Stack>
          <Divider sx={ActivityModal_style?.dividerSec} />
        </Box>
        <Box sx={ActivityModal_style?.footer}>
          <FooterComponent
            outlinedText={t("Cancel")}
            containedText={t("Edit")}
            outlinedonClick={onClose}
            containedonClick={() => handleDrawerEdit()}
            padding='16px 0px 0px 0px !important'
          />
        </Box>
      </Box>

    </>
  );
};
export default withTranslation('statistic')(ActivityModal)