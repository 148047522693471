import * as React from "react"

export const RevenueImage = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15.994} height={16} {...props}>
    <path
      d="M12.825 0a.632.632 0 0 0-.067.006h-2.025a.631.631 0 1 0 0 1.263h.58L9.049 3.532 6.97 1.453a.631.631 0 0 0-.893 0L3.552 3.979a.631.631 0 1 0 .893.893l2.079-2.079L8.6 4.872a.631.631 0 0 0 .893 0l2.71-2.71v.58a.631.631 0 1 0 1.263 0V.714a.631.631 0 0 0-.644-.71ZM1.473 5.9A1.483 1.483 0 0 0 0 7.371v7.155a1.483 1.483 0 0 0 1.473 1.473h13.048a1.483 1.483 0 0 0 1.473-1.473V7.371a1.483 1.483 0 0 0-1.473-1.473Zm1.052 1.263h10.944a1.261 1.261 0 0 0 1.263 1.263v5.048a1.261 1.261 0 0 0-1.263 1.263H2.525a1.261 1.261 0 0 0-1.262-1.263v-5.05a1.261 1.261 0 0 0 1.262-1.263ZM8 8.003a2.763 2.763 0 0 0-2.525 2.946A2.763 2.763 0 0 0 8 13.895a2.763 2.763 0 0 0 2.525-2.946A2.763 2.763 0 0 0 8 8.003Zm-5.051 2.1a.842.842 0 1 0 .842.842.842.842 0 0 0-.845-.838Zm10.1 0a.842.842 0 1 0 .842.842.842.842 0 0 0-.843-.838Z"
      fill="#fff"
    />
  </svg>
)
