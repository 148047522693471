import { Bold, Regular } from "../../utils";

export const ContactCardStyle = {
    bg: {
        height: '42px',
        width: '42px',
        borderRadius: '50%',
        backgroundColor: '#e9e2e0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: "7px"
    },
    tag: {
        width: 'fit-content',
        padding: '2px 6px',
        backgroundColor: '#FFE5DC',
        borderRadius: '4px',
        marginInlineStart: '10px'
    },
    align: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        marginInlineStart: {
            xs: "14px",
            sm: "0px",
            md: "0px",
            lg: "0px",
            xl: "0px"
        }
    },
    flexItem: {
        display: 'flex',
    },
    sx: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        cursor: "pointer"
    },
    name: {
        fontFamily: Bold,
        fontSize: "0.875rem",
        color: 'text.Teritary'
    },
    text: {
        fontFamily: Regular,
        fontSize: "0.75rem",
        color: 'text.Teritary',
        direction: "ltr",
        textAlign: "-webkit-match-parent"
    },
    dot: {
        width: "6px",
        height: "6px",
        backgroundColor: "text.secondary",
        borderRadius: "50%",
        margin: '0px 8px'
    },
    alignfirst: {
        display: 'flex',
        alignItems: 'center',
        // flexFlow:"wrap"
        //justifyContent: 'space-between',

    },
    alignTwo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    divider: {
        margin: "6px 0px 0px",
        borderBottom: "1px solid #5C86CB2E",
    },
}