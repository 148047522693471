import * as React from "react"

export const Services = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 7570">
      <path data-name="Rectangle 49473" fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="icons8-wrench (1)"
        d="M15.919 2.999a6.066 6.066 0 0 0-5.941 7.691L3.89 16.778a3.062 3.062 0 0 0 4.33 4.33l6.087-6.087a6.083 6.083 0 0 0 7.157-8.383.75.75 0 0 0-1.216-.225l-3.28 3.28a1.177 1.177 0 0 1-1.664-1.664l3.286-3.28a.75.75 0 0 0-.226-1.215 6.2 6.2 0 0 0-2.445-.535Zm-.013 1.5a4.684 4.684 0 0 1 .514.035c.069.009.133.045.2.057l-2.375 2.377a2.677 2.677 0 0 0 3.786 3.785l2.379-2.379a4.577 4.577 0 0 1-1.259 4.042 4.635 4.635 0 0 1-4.841 1.086.75.75 0 0 0-.781.176l-6.368 6.369a1.562 1.562 0 0 1-2.209-2.208l6.366-6.366a.75.75 0 0 0 .177-.781 4.627 4.627 0 0 1 4.412-6.193Z"
        fill={props?.color ?? "#99a0ac"}
      />
    </g>
  </svg>
)

