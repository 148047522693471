import { Regular, Bold, SemiBold } from "../../utils";
export const Spending_style = {
    root: {
        position: "relative",
        height: "100%"
    },
    paper: {
        // backgroundColor: "#fff !important",
        // position: "relative",
        cursor: "pointer"
    },
    heading: {
        fontFamily: Bold,
        fontSize: "0.875rem",
        color: "color.primary"
    },
    year: {
        fontFamily: SemiBold,
        backgroundColor: " primary.main",
        color: "white",
        padding: "7px 11px",
        fontSize: "0.75rem",
        borderRadius: "15px"
    },
    month: {
        fontSize: "0.75rem",
        padding: "7px 5px 7px 0",
    },
    percentage: {
        color: "white",
        fontSize: "0.75rem",
        padding: "2px 9px",
        borderRadius: "4px"
    },
    tenantId: {
        fontFamily: SemiBold,
        color: "color.secondary",
        fontSize: "0.875rem"
    },
    price: {
        fontFamily: Bold,
        fontSize: "0.875rem",
        color: "color.primary"
    },
    activity: {
        backgroundColor: "#FFE5DC",
        color: "#F15A29",
        padding: "2px 8px",
        fontSize: "0.75rem",
        borderRadius: "4px"
    },
    work: {
        color: "color.secondary",
        fontFamily: Regular,
        fontSize: "0.75rem"
    },
    fab: {
        textTransform: "capitalize",
        "& .MuiButton-root": {
            color: "background.paper",
        },
        sm: {
            position: "fixed",
            bottom: "30px",
            right: "10%",
        },
        position: "fixed",
        bottom: "35px",
        left: "65%",

    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "4px",
            padding: "0px",
        },
    },
    entireBox: {


    },
    headingSec: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "10px 0"
    },
    paperBox: {
        padding: "16px 16px 0 16px",
        boxShadow: "0px 3px 30px #5C86CB2E",
    },
    bgCard: {
        borderBottom: "1px solid #E4E8EE",
        marginBottom: "12px",
        cursor: "pointer",
    },
    investmentCard: {
        cursor: "pointer",
    },
    AddIcon: {
        position: "fixed",
        bottom: "0",
        cursor: "pointer",
        zIndex: "300",
        right: 0,
        width: "100%",
        display: "flex"
    },
    AddIconContainer: {
        float: "right",
    },
    AddIconChild: {
        float: "right",
        bottom: "84px",
        right: "0",
        cursor: "pointer",
        zIndex: "300",
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        "&:hover": {
            backgroundColor: "primary.main",
            color: "primary.contrastText",
        },
        width: "50px",
        height: "50px"
    },
    infiniteScroll: {
        height: "100%",
    },
}

export const Revenur_style = {
    overflow: {
        // overflow: "auto"
    },
    summarybg: {
        backgroundColor: "primary.contrastText",
        boxShadow: '0px 3px 30px #5C86CB2E',
        borderRadius: '12px',
        opacity: 1,
        // padding: '0px 10px'
    },
    summaryText: {
        fontFamily: Bold,
        fontSize: '0.875rem',
        marginTop: '16px',
        marginBottom: '12px'
    }
}
export const Statistcs_style = {
    sx: {
        backgroundColor: "#F8FAFC",
        boxShadow: "0px 0px 30px #0000001A",
    },
    selectedTab: {
        backgroundColor: "#F6F6F6",
        padding: "16px",
        overflow: "auto",
        paddingTop:"65px"
    }
}
