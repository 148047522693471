import { Box, Stack, Typography } from "@mui/material"
import { otherFeaturesCardStyles } from "./style"
import { useNavigate } from "react-router-dom";

export const OtherFeatures = ({
    icon = "",
    title = "",
    onClick = () => false,
    router = "",
    is_not=false
}) => {

    const navigate = useNavigate();

    const handleClick = () => {
        if(is_not){
           return router()
        }
        if (router) {
            navigate(router);
        }
    };
    return (
        <Box onClick={handleClick} sx={otherFeaturesCardStyles?.featureCard}>
            <Stack alignItems={"center"} direction={"row"}>
                <img src={icon} alt="Other Features" />
                <Typography sx={otherFeaturesCardStyles?.title}>{title}</Typography>
            </Stack>
        </Box>
    )
}