import { Box, Container } from "@mui/material";
import { AddOnsPriceCard, AddOnsUnitCard, DialogDrawer, TopNavBar } from "../../components";
import { AddOnsServiceStyle } from "./style";
import { useState } from "react";
import { useLocation } from "react-router";
import NoData from "../../components/noData";
import { useLazyGetAllInventoryQuery } from "../../redux/services";
import { useEffect } from "react";
import { AddOnsDetailedServiceSimmer } from "./simmerComponent/addOnsDetailedServiceSimmer";
import { errorRoute } from "../../utils";



export const AddOnsDetailedService = ({
    t = () => false,
}) => {
    // const navigate = useNavigate()
    let location = useLocation();

    const [datas, setDatas] = useState({
        changeUnitDrawer: false,
        bool: true
    })
    const [GetAllInventory , {error}] = useLazyGetAllInventoryQuery();




    const changeUnitDrawerClose = () => {
        setDatas({
            ...datas,
            changeUnitDrawer: false,
            bool: false

        })
    }
   


    const getAllInventoryList = async () => {
        const payload = {
            unit_id: location?.state?.id,
            inspection_item: location?.state?.inspection
        }
        const data = await GetAllInventory(payload)
        console.log(data?.data?.data , 'slsl')

        setDatas({
            ...datas,
            arrayData: data?.data?.data,
            bool: false

        })
    }
    useEffect(() => {
        getAllInventoryList();
        // eslint-disable-next-line 
    }, [])

   useEffect(()=>{
        if(error?.status === 423) return errorRoute() 
     },[error])

    console.log(datas?.arrayData , 'datas?.arrayData')

    return (
        <Container maxWidth="sm"
        sx={{ padding: "0px !important"}}
        >
            {/* topNavbar */}

            <Container maxWidth="sm" sx={{ padding: "0px !important", minHeight: "58px" }}>
                <Box sx={AddOnsServiceStyle?.topNav}>
                    <TopNavBar title={t("Add On Items & Services")} isDirect isBack t={t} />
                </Box >
            </Container>
            {
                datas?.bool ?
                    <AddOnsDetailedServiceSimmer />
                    :
                    <Box sx={AddOnsServiceStyle?.root}>

                        <>
                            <Box>
                                <AddOnsPriceCard
                                    service
                                    t={t}
                                    title={location?.state?.parentName ?? ""}
                                    price={""}
                                    oty={t("Qty")}

                                    subTitle={datas?.arrayData?.length ?? ""}
                                // hidden={kyc?.kycListing?.length === index + 1 ? false : true}
                                />
                            </Box>
                            <Box >
                                <Box sx={AddOnsServiceStyle?.serviceBox}>
                                    {datas?.arrayData?.length > 0 ? datas?.arrayData?.map((service, index) => {
                                        return (
                                                <Box>
                                                    <AddOnsPriceCard
                                                        statusUnit={(service?.manufaturer_name || service?.model_number || service?.serial_number) ? true : false}
                                                        t={t}
                                                        title={service?.manufaturer_name ?? "-"}
                                                        subTitle1={service?.model_number ?? "-"}
                                                        subTitle2={service?.serial_number ?? "-"}
                                                        status={service?.item_condition ?? null}
                                                        hidden={datas?.arrayData?.length - 1 === index ? false : true}
                                                        item_condition={service?.item_condition ?? ""}
                                                        oty={t("Qty")}
                                                    />
                                                </Box>
                                                
                                        )

                                    })
                                        :
                                        <NoData style={{ display: "flex", alignItems: "center", height: "calc(100vh - 220px)" }} />
                                    }
                                </Box>
                            </Box>
                        </>

                        <DialogDrawer
                            maxWidth="sm"
                            open={datas?.changeUnitDrawer}
                            header={t("Change Unit")}
                            padding={"16px 16px 0px"}
                            height_style={
                                // { height: "350px !important" }
                                { height: "auto !important", padding: "16px !important", minHeight: "100px" }
                            }
                            component={
                                <>
                                    <AddOnsUnitCard
                                        ChangeUnit
                                        title={t("Unit Name")}
                                        subTitle={"Unit location"}
                                        tagTitle={"UNIT ID HERE"}
                                        changeUnitOnclick={() => changeUnitDrawerClose()}
                                    />

                                </>
                            }

                            onClose={() => changeUnitDrawerClose()}
                        />

                    </Box>
            }
        </Container >


    );
};
