import { Box, Container, Divider, Grid, Radio, Stack, Typography } from "@mui/material";
import { useStyles } from "./style";
import { DialogDrawer, FooterComponent, TopNavBar } from "../../components";
import QrReader from "react-qr-reader";
import moment from "moment-timezone"
import useWindowDimensions from "../../useDimension";
import UnitChanger from "../../assets/unit";
import PurpleUnit from "../../assets/purpleUnits";
import React from "react";
import { useLazyGetUnitEnumQuery, useLazyScanQrCodeQuery } from "../../redux/services";
import GreyUnit from "../../assets/greyUnit";
import { useNavigate } from "react-router";
import { AppRoutes } from "../../router/routes";
import { useSnackbar } from "notistack";
import { SemiBold } from "../../utils";

export const QrCode = ({ t = () => false}) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const [getUnit] = useLazyGetUnitEnumQuery();
    const size = useWindowDimensions()
    const [unitSwitcherDrawer, setUnitSwticherDrawer] = React.useState(false);
    const [selectedUnit, setSelectedUnit] = React.useState(null)
    const [unit, setUnit] = React.useState([]);
    const [scanQrCode] = useLazyScanQrCodeQuery()
    const [getDetails, setGetDetails] = React.useState([])
    const [assetDetailsDrawer, setAssetDetailsDrawer] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar()

    React.useEffect(() => {
        getAllUnits()
        // eslint-disable-next-line
    }, []);

    // SWITCHER DRAWER
    const unitSwitcher = () => {
        setUnitSwticherDrawer(true)
    }

    const handleUnitSwitcherDrawerClose = () => {
        setUnitSwticherDrawer(false)
    }

    const clickedUnit = (data) => {
        setUnitSwticherDrawer(false)
        setSelectedUnit(data)
    }

    const getAllUnits = () => {
        getUnit()
            .then((res) => {
                setUnit(res?.data?.data)
                setSelectedUnit(res?.data?.data?.[0])
            })
    }

    // SCAN QR CODE FOR DETAILS API
    const getItemDetails = (data) => {
        scanQrCode({
            "mapping_id": data,
            "unit_id": selectedUnit?.id
        })
            .then((res) => {
                if (res?.data?.type === "success") {
                    setGetDetails(res?.data?.data)
                    setAssetDetailsDrawer(true)
                }
                else {
                    return enqueueSnackbar(res?.error?.data?.error?.message?.msg, {
                        variant: "error",
                        anchorOrigin: { horizontal: "center", vertical: "top" },
                        className: { fontFamily: SemiBold }
                    })
                }
            })
    }

    const handleAssetDetailsDrawerClose = () => {
        setAssetDetailsDrawer(false)
    }

    const handleError = (data) => {
        console.log("error", data)
    }

    const handleScan = (data) => {
        if (data) {
            getItemDetails(data)
        }
    }

    return (
        <Container
            maxWidth="sm"
            className={classes.container}>
            <Box className={{ position: "fixed", top: "0px", left: 0, right: 0, zIndex: 999 }}>
                <TopNavBar isDirect title={"Scan"} isBack />
            </Box>
            <Box className={classes.scanQrBox} style={{ height: size?.height - 55 }}>
                <Box className={classes.qrSection}>
                    <center>
                        <Grid item xs={12} style={{ overflow: "auto" }}>
                            <QrReader
                                delay={500}
                                onError={handleError}
                                onScan={handleScan}
                                className={"qrcode"}
                            />
                            <Box mt={2}>
                                <Typography className={classes.serviceTitle}>{("Scan QR Code")}</Typography>
                            </Box>
                        </Grid>
                    </center>
                </Box>
                <Box className={classes.unitDetailsBox}>
                    <Stack alignItems={"center"} direction={"row"} className={classes.unitChangerBox}>
                        <UnitChanger />
                        <Stack flexGrow={1} marginInlineStart={"12px"} direction={"column"}>
                            <Typography className={classes.unitChangerText}>{selectedUnit?.unit_name}</Typography>
                            <Typography className={classes.unitChangersubtext}>{selectedUnit?.unit_no}</Typography>
                        </Stack>
                        <Typography onClick={() => unitSwitcher()} className={classes.change}>Change</Typography>
                    </Stack>
                </Box>
            </Box>
            {/* SWITCHER DRAWER */}
            <DialogDrawer
                maxWidth="sm"
                open={unitSwitcherDrawer}
                onClick={handleUnitSwitcherDrawerClose}
                header={"Unit Swithcer"}
                height_style={
                    { height: "auto !important" }
                }
                component={
                    <>
                        <Box className={classes.switchDrawerBox}>
                            {unit?.map((item, index) => {
                                return (
                                    <>
                                        <Stack onClick={() => clickedUnit(item)} direction={"row"}>
                                            <PurpleUnit />
                                            <Stack flexGrow={1} marginInlineStart={"12px"} direction={"column"}>
                                                <Typography className={classes.applianceText}>{item?.unit_name}</Typography>
                                                <Stack width={"80%"} alignItems={"center"} divider={<Divider className={classes.divider} />} direction={"row"}>
                                                    <Typography className={classes.unitChangersubtext}>{item?.unit_no}</Typography>
                                                    <Typography noWrap className={classes.unitChangersubtext}>{item?.property_name}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Radio
                                                checked={selectedUnit?.id === item?.id} />
                                        </Stack>
                                        {index !== unit.length - 1 && <Divider className={classes.horizontalDivider} />}
                                    </>
                                )
                            })}
                        </Box>
                    </>
                }
                onClose={() => handleUnitSwitcherDrawerClose()}
            />
            {/* DETAILS DRAWER */}
            <DialogDrawer
                headerStyle={{ border: "none !important", padding: "16px 24px 0px 24px !important" }}
                maxWidth="sm"
                open={assetDetailsDrawer}
                onClick={handleAssetDetailsDrawerClose}
                header={t("Assets Details")}
                height_style={
                    { height: "auto !important" }
                }
                component={
                    <>
                        <Box textAlign={"center"} mb={"20px"}>
                            <Box className={classes.image}>
                                <GreyUnit />
                            </Box>
                            <br />
                            {getDetails?.item_condtion &&
                                <Box className={classes.conition}>
                                    <Typography className={classes.exampleText}>
                                        Condition
                                    </Typography>
                                </Box>
                            }
                            <Typography className={classes.detailsDrawerText}>{getDetails?.name}</Typography>
                        </Box>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography className={classes.assetText}>{("Manufacturer")}</Typography>
                            <Typography className={classes.assetSubtext}>{getDetails?.manufaturer_name ?? "-"}</Typography>
                        </Stack>
                        <Divider className={classes.drawerDivider} />
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography className={classes.assetText}>{("Model Number")}</Typography>
                            <Typography className={classes.assetSubtext}>{getDetails?.model_number ?? "-"}</Typography>
                        </Stack>
                        <Divider className={classes.drawerDivider} />
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography className={classes.assetText}>{("Serial Number")}</Typography>
                            <Typography className={classes.assetSubtext}>{getDetails?.serial_number ?? "-"}</Typography>
                        </Stack>
                        <Divider className={classes.drawerDivider} />
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography className={classes.assetText}>{("Last Service")}</Typography>
                            <Typography className={classes.assetSubtext}>{getDetails?.last_service_date === null ? "-" : moment(getDetails?.last_service_date).format("DD-MMM-YY")}</Typography>
                        </Stack>
                    </>
                }
                footer={
                    <>
                        < FooterComponent
                            single={true}
                            containedText={"Raise Request"}
                            containedonClick={() => navigate(AppRoutes.maintanceRequest,
                                {
                                    state: {
                                        product: getDetails,
                                        selected: selectedUnit?.company_id
                                    }
                                })}
                        />

                    </>
                }
                onClose={() => handleAssetDetailsDrawerClose()}
            />
        </Container>
    )
}