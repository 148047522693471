import { Box } from "@mui/material"
import { LoderSimmer } from "../../components"
import { ActivityCardsimmer } from "../statistics/component/activityCardsimmer"

export const ContractSimmer = () => {
    return (
        <Box sx={{ background: "#fff" }}>
            <ActivityCardsimmer count={1} />
            <Box sx={{ padding: "0 12px" }}>
                <LoderSimmer card count={1} width={160} size={10} variant={"rectangular"} animation={"wave"} />
            </Box>
            {/* <Stack direction="row" aliginItems="center" alignItems="center"
                sx={{ padding: "0 12px" }}>
                <LoderSimmer card count={1} width={100} size={10} variant={"rectangular"} animation={"wave"} />
                <LoderSimmer card count={1} width={30} size={10} variant={"rectangular"} animation={"wave"} />
            </Stack> */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 13px' }}>
                <Box>
                    <LoderSimmer card count={1} width={160} size={10} variant={"rectangular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={160} size={10} variant={"rectangular"} animation={"wave"} />
                </Box>
                <Box>
                    <LoderSimmer card count={1} width={160} size={10} variant={"rectangular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={160} size={10} variant={"rectangular"} animation={"wave"} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 13px' }}>
                <Box>
                    <LoderSimmer card count={1} width={160} size={10} variant={"rectangular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={160} size={10} variant={"rectangular"} animation={"wave"} />
                </Box>
                <Box>
                    <LoderSimmer card count={1} width={60} size={10} variant={"rectangular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={60} size={10} variant={"rectangular"} animation={"wave"} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 13px' }}>
                <Box>
                    <LoderSimmer card count={1} width={160} size={10} variant={"rectangular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={160} size={10} variant={"rectangular"} animation={"wave"} />
                </Box>
                <Box>
                    <LoderSimmer card count={1} width={60} size={10} variant={"rectangular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={60} size={10} variant={"rectangular"} animation={"wave"} />
                </Box>
            </Box>
        </Box >
    )
}