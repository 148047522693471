import * as React from "react";

export const HomeFogg = (props) => (
  <svg
    data-name="Group 109009"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={65.281}
    height={54.001}
    {...props}
  >
    <defs>
      <clipPath id="f">
        <path
          data-name="Rectangle 56045"
          fill="none"
          d="M0 0h65.281v54.001H0z"
        />
      </clipPath>
      <clipPath id="g">
        <path
          data-name="Path 99422"
          d="M91.7 53.653a2.227 2.227 0 0 0-2.227 2.227v6.308h28.45V55.88a2.227 2.227 0 0 0-2.227-2.227Z"
          transform="translate(-89.473 -53.653)"
          fill="none"
        />
      </clipPath>
      <clipPath id="h">
        <path
          data-name="Rectangle 56011"
          fill="none"
          d="M0 0h38.407v24.538H0z"
        />
      </clipPath>
      <clipPath id="i">
        <path
          data-name="Path 99423"
          d="M170.186 168.1v10.813h11.328V168.1a5.664 5.664 0 1 0-11.328 0"
          transform="translate(-170.186 -162.439)"
          fill="none"
        />
      </clipPath>
      <clipPath id="k">
        <path
          data-name="Path 99424"
          d="M58.173 88.946a2.377 2.377 0 0 0-2.373 2.377v7.225h7.112v-9.6Z"
          transform="translate(-55.796 -88.946)"
          fill="none"
        />
      </clipPath>
      <clipPath id="l">
        <path data-name="Rectangle 56015" fill="none" d="M0 0h7.112v9.602H0z" />
      </clipPath>
      <clipPath id="p">
        <path
          data-name="Path 99425"
          d="M224.1 88.946v9.6h7.112v-7.223a2.377 2.377 0 0 0-2.377-2.377Z"
          transform="translate(-224.096 -88.946)"
          fill="none"
        />
      </clipPath>
      <clipPath id="r">
        <path
          data-name="Path 99432"
          d="M57.4 240.416a1.6 1.6 0 0 0 0 3.2h39.47a1.6 1.6 0 0 0 0-3.2Z"
          transform="translate(-55.796 -240.416)"
          fill="none"
        />
      </clipPath>
      <clipPath id="t">
        <path
          data-name="Path 99433"
          d="M107.8 66.776a1.495 1.495 0 1 0 0 2.989h18.348a1.495 1.495 0 0 0 0-2.989Z"
          transform="translate(-106.303 -66.776)"
          fill="none"
        />
      </clipPath>
      <clipPath id="v">
        <rect
          data-name="Rectangle 56027"
          width={14.974}
          height={13.183}
          rx={4}
          fill="none"
        />
      </clipPath>
      <clipPath id="x">
        <path
          data-name="Path 99434"
          d="M202.75 202.115a1.544 1.544 0 1 0 1.544-1.544 1.544 1.544 0 0 0-1.544 1.544"
          transform="translate(-202.75 -200.571)"
          fill="none"
        />
      </clipPath>
      <clipPath id="z">
        <path
          data-name="Path 99439"
          d="M6.59 74.612a4.545 4.545 0 1 0 1.21 7.255 5.589 5.589 0 0 0 7.1.165 3.281 3.281 0 1 0 2.2-4.96 5.659 5.659 0 0 0-10.51-2.46"
          transform="translate(0 -71.848)"
          fill="none"
        />
      </clipPath>
      <clipPath id="B">
        <path
          data-name="Rectangle 56039"
          fill="none"
          d="M0 0h13.052v11.648H0z"
        />
      </clipPath>
      <clipPath id="C">
        <path
          data-name="Path 99440"
          d="M46.763 77.019a3.22 3.22 0 0 0-.52.052 5.672 5.672 0 0 0-5.643-5.223 5.6 5.6 0 0 0-3.643 1.352 5.611 5.611 0 0 1 1.237-.148 5.672 5.672 0 0 1 5.645 5.224 3.221 3.221 0 0 1 3.032 5.224 3.239 3.239 0 0 0-.108-6.476"
          transform="translate(-36.957 -71.848)"
          fill="none"
        />
      </clipPath>
      <clipPath id="D">
        <path
          data-name="Path 99441"
          d="M202.077 0a6 6 0 0 0-5.139 2.923 4.806 4.806 0 1 0 1.279 7.671 5.909 5.909 0 0 0 7.507.175 3.468 3.468 0 1 0 2.322-5.245A6 6 0 0 0 202.077 0Z"
          transform="translate(-189.969)"
          fill="none"
        />
      </clipPath>
      <clipPath id="E">
        <path
          data-name="Rectangle 56043"
          fill="none"
          d="M0 0h13.802v12.317H0z"
        />
      </clipPath>
      <clipPath id="F">
        <path
          data-name="Path 99442"
          d="M239.419 5.468a3.414 3.414 0 0 0-.55.055A6 6 0 0 0 232.9 0a5.925 5.925 0 0 0-3.85 1.426 5.928 5.928 0 0 1 1.308-.156 6 6 0 0 1 5.97 5.524 3.372 3.372 0 0 1 3.983 3.374 3.4 3.4 0 0 1-.777 2.149 3.425 3.425 0 0 0-.114-6.848"
          transform="translate(-229.049)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="c"
        x1={-0.665}
        y1={4.999}
        x2={-0.657}
        y2={4.999}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#7f6c7f" />
        <stop offset={1} stopColor="#5a465b" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={-0.363}
        y1={1}
        x2={-0.357}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffeaca" />
        <stop offset={1} stopColor="#ffd9b1" />
      </linearGradient>
      <linearGradient
        id="j"
        x1={-3.175}
        y1={1.194}
        x2={-3.156}
        y2={1.194}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#27b2ff" />
        <stop offset={1} stopColor="#197bff" />
      </linearGradient>
      <linearGradient
        id="a"
        x1={-1.658}
        y1={3.667}
        x2={-1.628}
        y2={3.667}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ff7044" />
        <stop offset={1} stopColor="#f92814" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={-2.658}
        y1={3.667}
        x2={-2.628}
        y2={3.667}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffc200" />
        <stop offset={1} stopColor="#ff9102" />
      </linearGradient>
      <linearGradient
        id="m"
        x1={-3.658}
        y1={3.667}
        x2={-3.628}
        y2={3.667}
        xlinkHref="#a"
      />
      <linearGradient
        id="n"
        x1={-4.658}
        y1={3.667}
        x2={-4.628}
        y2={3.667}
        xlinkHref="#b"
      />
      <linearGradient
        id="o"
        x1={-5.658}
        y1={3.667}
        x2={-5.628}
        y2={3.667}
        xlinkHref="#a"
      />
      <linearGradient
        id="q"
        x1={-6.658}
        y1={3.667}
        x2={-6.628}
        y2={3.667}
        xlinkHref="#b"
      />
      <linearGradient
        id="s"
        x1={-0.276}
        y1={1}
        x2={-0.271}
        y2={1}
        xlinkHref="#c"
      />
      <linearGradient
        id="u"
        x1={-1.053}
        y1={13.346}
        x2={-1.043}
        y2={13.346}
        xlinkHref="#d"
      />
      <linearGradient
        id="w"
        x1={-1.154}
        y1={1.46}
        x2={-1.14}
        y2={1.46}
        xlinkHref="#c"
      />
      <linearGradient
        id="y"
        x1={-13.87}
        y1={3.762}
        x2={-13.801}
        y2={3.762}
        xlinkHref="#c"
      />
      <linearGradient
        id="e"
        x1={-0.598}
        y1={3.333}
        x2={-0.582}
        y2={3.333}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#eef4ff" />
        <stop offset={1} stopColor="#7bacdf" />
      </linearGradient>
      <linearGradient
        id="G"
        x1={-3.507}
        y1={4.384}
        x2={-3.491}
        y2={4.384}
        xlinkHref="#e"
      />
      <radialGradient
        id="A"
        cx={0.638}
        cy={0.338}
        r={0.481}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#c0e4ff" />
      </radialGradient>
    </defs>
    <g data-name="Group 108984" clipPath="url(#f)">
      <g data-name="Group 108921">
        <g
          data-name="Group 108920"
          clipPath="url(#g)"
          transform="translate(18.906 11.337)"
        >
          <path
            data-name="Rectangle 56009"
            fill="url(#c)"
            d="M0 0h28.45v8.535H0z"
          />
        </g>
      </g>
      <g data-name="Group 108923">
        <g
          data-name="Group 108922"
          clipPath="url(#h)"
          transform="translate(13.923 29.463)"
        >
          <path
            data-name="Rectangle 56010"
            fill="url(#d)"
            d="M0 0h38.407v24.538H0z"
          />
        </g>
      </g>
      <g data-name="Group 108925">
        <g
          data-name="Group 108924"
          clipPath="url(#i)"
          transform="translate(35.961 34.324)"
        >
          <path
            data-name="Rectangle 56012"
            fill="url(#j)"
            d="M0 0h11.328v16.477H0z"
          />
        </g>
      </g>
      <g data-name="Group 108927">
        <g
          data-name="Group 108926"
          clipPath="url(#k)"
          transform="translate(11.79 18.794)"
        >
          <path
            data-name="Rectangle 56013"
            fill="url(#a)"
            d="M0 0h7.112v9.602H0z"
          />
        </g>
      </g>
      <g data-name="Group 108929">
        <g
          data-name="Group 108928"
          clipPath="url(#l)"
          transform="translate(18.902 18.794)"
        >
          <path
            data-name="Rectangle 56014"
            fill="url(#b)"
            d="M0 0h7.112v9.602H0z"
          />
        </g>
      </g>
      <g data-name="Group 108931">
        <g
          data-name="Group 108930"
          clipPath="url(#l)"
          transform="translate(26.015 18.794)"
        >
          <path
            data-name="Rectangle 56016"
            fill="url(#m)"
            d="M0 0h7.112v9.602H0z"
          />
        </g>
      </g>
      <g data-name="Group 108933">
        <g
          data-name="Group 108932"
          clipPath="url(#l)"
          transform="translate(33.127 18.794)"
        >
          <path
            data-name="Rectangle 56018"
            fill="url(#n)"
            d="M0 0h7.112v9.602H0z"
          />
        </g>
      </g>
      <g data-name="Group 108935">
        <g
          data-name="Group 108934"
          clipPath="url(#l)"
          transform="translate(40.239 18.794)"
        >
          <path
            data-name="Rectangle 56020"
            fill="url(#o)"
            d="M0 0h7.112v9.602H0z"
          />
        </g>
      </g>
      <g data-name="Group 108937">
        <g
          data-name="Group 108936"
          clipPath="url(#p)"
          transform="translate(47.352 18.794)"
        >
          <path
            data-name="Rectangle 56022"
            fill="url(#q)"
            d="M0 0h7.112v9.602H0z"
          />
        </g>
      </g>
      <g data-name="Group 108939">
        <g data-name="Group 108938" clipPath="url(#f)">
          <path
            data-name="Path 99426"
            d="M11.795 28.396v.711a3.556 3.556 0 1 0 7.112 0v-.711Z"
            fill="#ce2424"
          />
          <path
            data-name="Path 99427"
            d="M18.902 28.396v.711a3.556 3.556 0 1 0 7.112 0v-.711Z"
            fill="#ed5c14"
          />
          <path
            data-name="Path 99428"
            d="M26.014 28.396v.711a3.556 3.556 0 1 0 7.112 0v-.711Z"
            fill="#ce2424"
          />
          <path
            data-name="Path 99429"
            d="M33.127 28.396v.711a3.556 3.556 0 1 0 7.112 0v-.711Z"
            fill="#ed5c14"
          />
          <path
            data-name="Path 99430"
            d="M40.239 28.396v.711a3.556 3.556 0 1 0 7.112 0v-.711Z"
            fill="#ce2424"
          />
          <path
            data-name="Path 99431"
            d="M47.356 28.396v.711a3.556 3.556 0 1 0 7.112 0v-.711Z"
            fill="#ed5c14"
          />
        </g>
      </g>
      <g data-name="Group 108941">
        <g
          data-name="Group 108940"
          clipPath="url(#r)"
          transform="translate(11.79 50.8)"
        >
          <path
            data-name="Rectangle 56024"
            fill="url(#s)"
            d="M0 0h42.674v3.201H0z"
          />
        </g>
      </g>
      <g data-name="Group 108943">
        <g
          data-name="Group 108942"
          clipPath="url(#t)"
          transform="translate(22.462 14.11)"
        >
          <path
            data-name="Rectangle 56025"
            fill="url(#u)"
            d="M0 0h21.337v2.989H0z"
          />
        </g>
      </g>
      <g data-name="Group 108945">
        <g
          data-name="Group 108944"
          clipPath="url(#v)"
          transform="translate(17.287 34.748)"
        >
          <path
            data-name="Rectangle 56026"
            fill="url(#w)"
            d="M0 0h14.974v13.183H0z"
          />
        </g>
      </g>
      <g data-name="Group 108947">
        <g
          data-name="Group 108946"
          clipPath="url(#x)"
          transform="translate(42.841 42.381)"
        >
          <path
            data-name="Rectangle 56028"
            fill="url(#y)"
            d="M0 0h3.089v3.089H0z"
          />
        </g>
      </g>
      <g data-name="Group 108967">
        <g
          data-name="Group 108966"
          clipPath="url(#z)"
          transform="translate(0 15.182)"
        >
          <path
            data-name="Rectangle 56037"
            transform="rotate(-37.9 3.579 10.434)"
            fill="url(#A)"
            d="M0 0h23.623v22.014H0z"
          />
        </g>
      </g>
      <g data-name="Group 108974">
        <g data-name="Group 108973" clipPath="url(#f)">
          <g
            data-name="Group 108972"
            opacity={0.2}
            style={{
              mixBlendMode: "multiply",
              isolation: "isolate",
            }}
          >
            <g data-name="Group 108971">
              <g
                data-name="Group 108970"
                clipPath="url(#B)"
                transform="translate(7.809 15.182)"
              >
                <g data-name="Group 108969">
                  <g data-name="Group 108968" clipPath="url(#C)">
                    <path
                      data-name="Rectangle 56038"
                      fill="url(#e)"
                      d="M0 0h13.053v11.648H0z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 108976">
        <g
          data-name="Group 108975"
          clipPath="url(#D)"
          transform="translate(40.141)"
        >
          <path
            data-name="Rectangle 56041"
            transform="rotate(-37.9 3.783 11.033)"
            fill="url(#A)"
            d="M0 0h24.98v23.279H0z"
          />
        </g>
      </g>
      <g data-name="Group 108983">
        <g data-name="Group 108982" clipPath="url(#f)">
          <g
            data-name="Group 108981"
            opacity={0.2}
            style={{
              mixBlendMode: "multiply",
              isolation: "isolate",
            }}
          >
            <g data-name="Group 108980">
              <g
                data-name="Group 108979"
                clipPath="url(#E)"
                transform="translate(48.398)"
              >
                <g data-name="Group 108978">
                  <g data-name="Group 108977" clipPath="url(#F)">
                    <path
                      data-name="Rectangle 56042"
                      fill="url(#G)"
                      d="M0 0h13.802v12.317H0z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

