import { TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Bold, SemiBold } from "../../utils";

const useStyles = {
    Label: {

        fontSize: "0.75rem",
        paddingBottom: "8px",
        fontFamily: Bold
    },


    textbox: {
        [`& fieldset`]: {
            borderRadius: "4px",
            height: (props) => (props.multiline ? "unset" : 50),
            border: "1px solid #E4E8EE",
            "& :hover": {
                border: "1px solid #5078E131",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: (props) => (props.multiline ? "10px" : "11px 14px"),
            fontSize: "0.875rem",
            fontFamily: SemiBold
        },
        ":-webkit-autofill": {
            WebkitBoxShadow: "none !important",
            backgroundColor: "red !important",
        },
    },
};

export const TextBox = (props) => {

    const getLabel = (props) => {
        return (
            <div style={{ display: "flex" }}>
                {
                    <Typography
                        variant="body1"
                        style={{ color: props?.labelColor ? props?.labelColor : "#4E5A6B", }}
                        color="textsecondary"
                        sx={useStyles?.Label}
                        align="left"
                    >
                        {props?.label}
                    </Typography>
                }
                {props?.isRequired && (
                    <Typography color="error" variant="caption">
                        &nbsp;*
                    </Typography>
                )}
            </div>
        );
    };

    return (
        <div sx={useStyles?.root}>
            <>
                {props?.label?.length > 0 && getLabel(props)}
                <div sx={useStyles?.text}>
                    <TextField
                        onKeyDown={props?.handleKeypress}
                        sx={useStyles?.textbox}
                        type={props.type}
                        id={props.id}
                        placeholder={props.placeholder}
                        variant={props.variant ?? "outlined"}
                        fullWidth
                        InputLabelProps={{
                            shrink: false,
                        }}
                        style={{
                            backgroundColor: props.color ? "white" : "auto",
                            borderRadius: "6px",
                        }}
                        inputProps={{
                            readOnly: props?.isReadonly ?? false,
                            autoCapitalize: "none",
                        }}
                        InputProps={{
                            endAdornment: props?.endAdornment,
                            startAdornment: props?.startAdornment,
                            style: {
                                fontSize: props?.fontSize ?? "0.875rem",
                                padding: props.multiline ? 0 : "none",
                            },
                        }}
                        onKeyPress={props?.onKeyPress}
                        disabled={props?.disabled ?? false}
                        size="small"
                        multiline={props.multiline}
                        rows={props?.rows ? props.rows : 5}
                        rowsMax={10}
                        onChange={props.onChange}
                        value={props.value}
                    />
                </div>
            </>
            {props.isError && (
                <Typography variant={"caption"} color={"error"}>
                    {props.errorMessage}
                </Typography>
            )}
        </div>
    );
};

TextBox.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    multiline: PropTypes.bool,
    type: PropTypes.string,
    isReadonly: PropTypes.bool,
    onChange: PropTypes.func,
};
TextBox.defaultProps = {
    label: "",
    multiline: false,
    type: "text",
    placeholder: "Type Here...",
};
