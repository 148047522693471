import React from "react";
import { WelcomePage } from "./welcome";
import { withTranslation } from 'react-i18next';

class WelcomePageParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return <WelcomePage  {...this?.props} />;
    }
}

export default withTranslation('WelcomePage')(WelcomePageParent);