import { Avatar, Box, Typography } from "@mui/material";
import React from 'react';
import { TickImage } from "../../assets";
import { generateImageUrl, img_size, stringAvatar } from "../../utils";
import { companySelectStyle } from "./style";

export const CompanyList = (props) => {

    return (
        <Box sx={props?.data?.id === props?.selected ? companySelectStyle?.selectCompanyBorder : companySelectStyle?.selectCompany} onClick={() => props?.companySelect(props?.data)}>
            <Box sx={companySelectStyle?.companySelect}>
                {/* company logo */}
                <Avatar src={generateImageUrl(props?.data?.company_logo ?? "", img_size.small_square)} alt='comapany_img' sx={companySelectStyle?.comapnyImage} {...stringAvatar(props?.data?.name)} />
                {/* company name */}
                <Typography sx={companySelectStyle?.companyName} noWrap>{props?.data?.name}</Typography>
            </Box>
            {/* selected icon */}
            <Box>
                {
                    props?.data?.id === props?.selected &&  <TickImage />
                }
               
            </Box>
        </Box>
    )
}