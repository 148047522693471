import { LoderSimmer } from "../../../components"
import { Box } from "@mui/material"

export const InvestmentDrawerSimmer = () => {
    return (
        <Box >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                    <LoderSimmer card count={1} width={84} size={19} variant={"rounded"} animation={"wave"} />
                    <LoderSimmer card count={1} width={70} size={27} variant={"rounded"} animation={"wave"} />
                    <LoderSimmer card count={1} width={190} size={16} variant={"rounded"} animation={"wave"} />
                </Box>
                <Box>
                    <LoderSimmer card count={1} width={135} size={32} variant={"rounded"} animation={"wave"} />
                </Box>
            </Box>
            <LoderSimmer card count={1} width={376} size={4} variant={"rounded"} animation={"wave"} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                    <LoderSimmer card count={1} width={75} size={16} variant={"rounded"} animation={"wave"} />
                    <LoderSimmer card count={1} width={85} size={16} variant={"rounded"} animation={"wave"} />
                    <LoderSimmer card count={1} width={92} size={16} variant={"rounded"} animation={"wave"} />
                    <LoderSimmer card count={1} width={85} size={16} variant={"rounded"} animation={"wave"} />
                    <LoderSimmer card count={1} width={75} size={16} variant={"rounded"} animation={"wave"} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'right', flexDirection: 'column' }}>
                    <LoderSimmer card count={1} width={75} size={16} variant={"rounded"} animation={"wave"} />
                    <LoderSimmer card count={1} width={85} size={16} variant={"rounded"} animation={"wave"} />
                    <LoderSimmer card count={1} width={92} size={16} variant={"rounded"} animation={"wave"} />
                    <LoderSimmer card count={1} width={85} size={16} variant={"rounded"} animation={"wave"} />
                    <LoderSimmer card count={1} width={75} size={16} variant={"rounded"} animation={"wave"} />
                </Box>
            </Box>
            <LoderSimmer card count={1} width={376} size={4} variant={"rounded"} animation={"wave"} />
            <Box>
                <LoderSimmer card count={1} width={32} size={16} variant={"rounded"} animation={"wave"} />
                <LoderSimmer card count={1} width={320} size={32} variant={"rounded"} animation={"wave"} />
            </Box>
            <LoderSimmer card count={1} width={376} size={4} variant={"rounded"} animation={"wave"} />
        </Box>


    )
}