import * as React from "react";

export const FilterIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 97059">
      <g data-name="Rectangle 44485" fill="#fff" stroke="#e4e8ee">
        <rect width={32} height={32} rx={4} stroke="none" />
        <rect x={0.5} y={0.5} width={31} height={31} rx={3.5} fill="none" />
      </g>
      <path
        d="M9.972 10a.981.981 0 0 0-.972.972v1.2a2.918 2.918 0 0 0 1.112 2.291l3.941 3.075v5.875a.583.583 0 0 0 .922.475l2.725-1.941a.583.583 0 0 0 .245-.475v-3.93l3.941-3.075A2.918 2.918 0 0 0 23 12.174v-1.2a.981.981 0 0 0-.972-.974Zm.194 1.167h11.667v1.007a1.748 1.748 0 0 1-.667 1.374L17 16.8a.583.583 0 0 0-.225.46v3.915l-1.556 1.111v-5.029A.583.583 0 0 0 15 16.8l-4.166-3.252a1.748 1.748 0 0 1-.667-1.374Z"
        fill="#091b29"
      />
      <g
        data-name="Ellipse 39513"
        transform="translate(19 8)"
        fill="#f15a29"
        stroke="#fff"
        strokeWidth={1.5}
      >
        <circle cx={3} cy={3} r={3} stroke="none" />
        <circle cx={3} cy={3} r={3.75} fill="none" />
      </g>
    </g>
  </svg>
);
