
import { Box } from "@mui/material"
import { LoderSimmer } from "../../../components"
export const GraphSimmer_style = {
    favicon: {
        display: 'flex',
        alignItems: 'center',
    },
    align: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    bg: {
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '12px',
    },
    headBg:{
        backgroundColor: '#F6F9FF',
        height:'50px',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding:'10px'
    }
}

export const RevenueSimmer = () => {
    return (
          <Box>
            <Box sx={GraphSimmer_style?.bg}>
                        <Box sx={GraphSimmer_style?.headBg}>
                            <Box><LoderSimmer card count={1} width={81} size={12} variant={"rectangular"} animation={"wave"} /></Box>
                        </Box>
           
                        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'40px'}}>
                            <LoderSimmer card count={1} width={150} size={150} variant={"circular"} animation={"wave"} />
                        </Box>

                    </Box>

                </Box>
    )
}

export const RevenueCard = () => {
    const count = 6
    return(
        <Box>
            <Box sx={GraphSimmer_style?.bg}>
                {Array.apply(null, Array(count)).map((value, index) => (
                <Box>
                <Box sx={GraphSimmer_style?.align}>
                    <Box sx={GraphSimmer_style?.favicon}>
                        <Box><LoderSimmer card count={1} width={40} size={20} variant={"rounded"} animation={"wave"} /></Box>
                        <Box sx={{paddingLeft:'10px'}}><LoderSimmer card count={1} width={60} size={18} variant={"rectangular"} animation={"wave"} /></Box>
                    </Box>
                    <Box>
                        <LoderSimmer card count={1} width={51} size={18} variant={"rectangular"} animation={"wave"} />
                    </Box>
                </Box>
                <LoderSimmer card count={1} width={'auto'} size={3} variant={"rectangular"} animation={"wave"} />
                    </Box>
                ))}
            </Box>
        </Box>
    )
} 