import React from "react";
import { Container } from "@mui/material";
import loader from "./../../assets/loader.gif"
import { generateImageUrl, img_size } from "../../utils";
export const Loader = () => {
    const styles = {
        height: "100vh",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F6F6F6"
    }
    return (
        <Container maxWidth="sm" sx={styles}>
            <img src={generateImageUrl(loader, img_size.small_square)} alt="loader" />
        </Container>
    )
}