import { Bold, Regular, SemiBold } from "../../utils";


export const VehicleStyle = {
    boxShadow: {
        boxShadow: "none !important",
    },
    search: {
        padding: "16px",
        backgroundColor: "#F8F8F8",

    },
    subRoot: {
        padding: "16px",
        overflow: "auto",
        height: "100%"
    },
    card: {
        boxShadow: "0px 0px 4px #00000029",
        marginTop: "12px",
        borderRadius: "4px",
        backgroundColor: "white",
        alignItems: "center",
        cursor: "pointer",
    },
    parkingNo: {
        fontSize: "0.875rem",
        fontFamily: Bold
    },
    type: {
        padding: "3px 6px",
        color: "text.light2",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        borderRadius: "4px",
        backgroundColor: "background.badge",
        marginInlineStart: "7px"
    },
    commonText: {
        fontSize: "0.75rem",
        marginRight: "6px",
        color: "text.Teritary",
        fontFamily: Regular
    },
    bottomStack: {
        marginTop: "7px",
        "& div": {
            height: "6px",
            width: "6px",
            borderRadius: "50%",
            backgroundColor: "rgb(206, 211, 221)",
            marginRight: "6px"
        }
    },
    img: {
        width: {
            sm: "110px",
            xs: "85px"
        },
        height: {
            sm: "110px",
            xs: "85px"
        }
    },
    AddIcon: {
        position: "absolute",
        bottom: "0",
        cursor: "pointer",
        zIndex: "300",
        right: 0,
        left:0
        },
    AddIconChild: {
        float: "right",
        bottom: "24px",
        right: "16px",
        cursor: "pointer",
        zIndex: "300",
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        "&:hover": {
            backgroundColor: "primary.main",
            color: "primary.contrastText",
        }
    },
    avatar: {
        borderRadius: "4px",
        backgroundColor: "rgb(241 90 41 / 8%)",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    Topnav: {
        position: 'fixed',
        top: 0,
        zIndex: 999,
        left:0,
        right:0
    },
    parentBox:{
        position:"relative"
    }
}