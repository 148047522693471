import * as React from "react"

 export const ContactNumber = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={12} {...props}>
        <path
            data-name="icons8-contact-details (1)"
            d="M12.5 1.667H7.348L5.857.425A1.835 1.835 0 0 0 4.684 0H1.5A1.5 1.5 0 0 0 0 1.5v9A1.5 1.5 0 0 0 1.5 12h.833v-1.167a.5.5 0 0 1 1 0V12h7.333v-1.167a.5.5 0 0 1 1 0V12h.834a1.5 1.5 0 0 0 1.5-1.5V3.167a1.5 1.5 0 0 0-1.5-1.5Zm-5.5 2a1.5 1.5 0 1 1-1.5 1.5 1.5 1.5 0 0 1 1.5-1.5ZM9.333 8.5C9.333 9.328 8.288 10 7 10s-2.333-.672-2.333-1.5v-.333a.833.833 0 0 1 .833-.833h3a.833.833 0 0 1 .833.833Z"
            fill="#fff"
        />
    </svg>
)
