import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import TermsAndConditionSvg from '../../../assets/termsAndCondition'
import { WelcomePage_style } from '../style'
import { DialogDrawer, Slider } from '../../../components'
import { DocumentTC } from '../../../assets/document'

export const TermsAndCondition = ({ termsCondition = "", t = () => false }) => {
    const style = `style="white-space:normal !important"`
    const [open, setOpen] = React.useState(false)
    const [selected, setSelected] = React.useState("")

    const handleClick = (val) => {
        setOpen(!open)
        setSelected(val)
    }
    return (
        <Box>
            <Stack alignItems={"center"} justifyContent={"center"} spacing={2}>
                <TermsAndConditionSvg />
                <Typography sx={WelcomePage_style.tcText}>{t("Terms & Conditions")}</Typography>
            </Stack>
            <div dangerouslySetInnerHTML={{
                __html: termsCondition?.terms_conditions?.replace(/<p\b/g, `<p ${style}`),
            }} ></div>

            <Box mt={2}>
                <Grid container>
                    {termsCondition?.docs?.map((val) => {
                        return (

                            <Grid item xs={3.7} sx={WelcomePage_style.imgContainer}>

                                {
                                    val?.asset_type === 4 || val?.file_meta?.type === "pdf" ?
                                        <div style={{ height: "125px", overflow: "auto" }} onClick={() => handleClick(val)}>
                                            {/* <DocumentViewerPDF url={val?.url} /> */}

                                            <Stack>
                                                <Stack rowGap={1} onClick={() => handleClick(val)} style={WelcomePage_style.detailsBox}>
                                                    <DocumentTC />
                                                    <Typography sx={WelcomePage_style.pdfText}>PDF</Typography>
                                                </Stack>
                                                <Box sx={{ width: "100%", display: 'flex', justifyContent: "center", marginTop: "6px" }}>
                                                    <Typography sx={WelcomePage_style.pdfName}>{val?.file_meta?.name ?? "-"}</Typography>
                                                </Box>
                                            </Stack>
                                        </div>
                                        :
                                        <img
                                            onClick={() => handleClick(val)}
                                            alt="not found"
                                            width={"100%"}
                                            src={val?.url}
                                            style={WelcomePage_style.img}
                                        />
                                }

                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <DialogDrawer
                height_style={
                    { height: "535px !important" }
                }
                open={open}
                header={t("View Document")}
                component={
                    <Box>
                        <Slider assets={[selected]} uploadImage height={"500px"} />
                    </Box>
                }
                onClose={() => setOpen(!open)}
            />
        </Box>
    )
}