import useTheme from "@mui/material/styles/useTheme";
import { SemiBold } from "../../utils";

export const ToggleStyle = ({ backgroundColorunparent,backgroundColorunselected, width, ...props }) => {
   const theme = useTheme();
   return {
      selected: {
         margin: "0 auto",
         backgroundColor: theme?.palette?.primary?.main,
         fontFamily: props?.fontFamily ?? SemiBold,
         fontSize: props?.fontSize ?? "0.875rem",
         borderRadius: props?.borderRadius ?? theme?.palette?.borderRadius,
         border: props?.border ?? `1px solid ${theme?.palette?.primary.main}`,
         padding: "7px 11px",
         color: props?.color ?? theme?.palette?.error?.contrastText,
         boxShadow: "none !important",
         textTransform: 'capitalize',
         "& .css-1u5bdyy-MuiButtonBase-root-MuiButton-root": {
            width: props?.width ?? "76px",
         },
         '& p': {
            color: props?.color ?? theme?.palette?.error?.contrastText,
            fontFamily: props?.fontFamily ?? SemiBold,
            fontSize: props?.fontSize ?? "0.875rem",
         },
         "&:hover": {
            color: props?.color ?? theme?.palette?.error?.contrastText,
            // border: `1px solid ${theme?.palette?.primary?.main}`,
            backgroundColor: theme?.palette?.primary?.main,
            zIndex: "9999!important",


         },
      },
      unselected: {
         backgroundColor:backgroundColorunselected ?? '#F8FAFC',
         //  marginLeft: "5px",
         margin: "0 auto",
         fontFamily: props?.fontFamily ?? SemiBold,
         fontSize: props?.fontSize ?? "0.875rem",
         borderRadius: props?.borderRadius ?? theme?.palette?.borderRadius,
         border: props?.border ?? `1px solid ${theme?.palette?.border?.secondary}`,
         padding: "7px 11px",
         color: props?.color ?? theme?.palette?.text?.main,
         boxShadow: "none !important",
         textTransform: 'capitalize',
         "& .css-1u5bdyy-MuiButtonBase-root-MuiButton-root": {
            width: props?.width ?? "76px",
         },
         '& p': {
            color: props?.color ?? theme?.palette?.text?.Teritary,
            fontFamily: props?.fontFamily ?? SemiBold,
            fontSize: props?.fontSize ?? "0.875rem",
         },
         "&:hover": {
            color: props?.color ?? theme?.palette?.text?.main,
            backgroundColor: backgroundColorunselected ?? '#F8FAFC',
            zIndex: "9999!important",

         },
      },
      parent: {
         backgroundColor: backgroundColorunparent ?? "#F8FAFC",
         padding: "5px",
         borderRadius: "18px",
         width: '152px',
         overflow: "auto",
         float: "right"
      },
      parentArabic: {
         backgroundColor: backgroundColorunparent ?? "#F8FAFC",
         padding: "5px",
         borderRadius: "18px",
         width: '152px',
         overflow: "auto",
         float: "left"
      },
      grid: {
         background: "#F8FAFC",
         borderRadius: props?.borderRadius ?? theme?.palette?.borderRadius,
         justifyContent: "space-between"
      }
   }
};
