
import { Avatar, Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { contactDetailStyles } from './style'
import PersonIcon from '../../assets/person';

export const ContactDetails = ({
    data = [],
}) => {



    return (<>
        {data?.map((item) => {
            return (
                <Box sx={contactDetailStyles?.root}>
                    <Stack sx={contactDetailStyles.iconBox} direction={"row"} alignItems={"center"}>
                        <Avatar src={<PersonIcon />}></Avatar>
                        <Stack spacing={"5px"} marginInlineStart={1} direction={"column"}>
                            <Typography sx={contactDetailStyles.text}>{item.name ?? '-'}</Typography>
                            <Stack direction={"row"} divider={(item?.primary && item?.secondary) && <Divider sx={contactDetailStyles.divider} orientation="vertical" flexItem />}>
                                <Typography sx={contactDetailStyles.subText}>{item.primary}</Typography>
                                <Typography sx={contactDetailStyles.subText}>{item.secondary}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            );
        })}</>
    );
}
