import { Bold, Regular } from "../../utils";

export const CorrespondenceCard_style ={
    card:{
        padding:'12px',
        height:'auto',
        backroundColor:'primary.contrastText',
        boxShadow: '0px 3px 30px #4250682E',
        marginBottom:'12px',
        borderRadius:'12px',
        cursor:"pointer"
    },
    statusBg:{
        padding:' 3px 6px',
        backgroundColor:'backgroundcard.label',
        borderRadius:'4px',
    },
    typeBg:{
        padding:' 4px 8px',
        backgroundColor:'background.secondary'
    },
    statusTxt:{
        fontFamily:Bold,
        fontSize:"0.75rem",
        color:'primary.contrastText'
    },
    typeTxt:{
        fontFamily:Bold,
        fontSize:"0.75rem",
        color:'primary.Tertiary',
    },
    content:{
        fontFamily:Bold,
        fontSize:"0.875rem",
        marginBottom:'8px'
    },
    belowTxt:{
        fontFamily:Regular,
        fontSize:"0.75rem",
        color:'primary.Tertiary'
    },
    dot:{
        backgroundColor:'border.secondary',
        padding:'3px',
        borderRadius:'50%',
        margin:'8px'
    },
    Replied:{
        fontFamily:Bold,
        fontSize:"0.75rem",
        fontStyle: 'italic',
        color:'text.Teritary'
    },
    topSec:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        marginBottom:'6px'
    },
    bottomSec:{
        display:'flex',
        alignItems:'center',
    }
}