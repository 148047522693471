import { Bold, Regular } from "../../utils"

export const CaseManagement_style = {

    container: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        position: 'relative',
        height:'100%'
    },
    Topnav: {
        position: 'fixed',
        top: 0,
        zIndex: 999,
        left:0,
        right:0
    },
    sx: {
        padding: '16px',
        position: "relative",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },

    topbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '11px'
    },
    title: {
        fontFamily: Bold,
        fontSize: "1rem",
    },
    noRecords: {
        color: 'primary.Tertiary',
        fontFamily: Regular,
        fontSize: "0.75rem",
        display: "flex",
        justifyContent: "center",
        marginTop: "20px"
    },

}