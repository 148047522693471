import { Box, Container } from "@mui/material"
import React from "react";
import { TabComp } from "./tabComp";
import { BottomInvoice, ContractInvoiceCard, DialogDrawer, DocumentViewer, FooterComponent, LoderSimmer, PaymentModal } from "../../components";
import { ContractDetailsStyle } from "./style";
import ContractUnitCard from "./contractUnitCard";
import UnitDrawerDetails from "./unitDrawerDetails";
import NoData from "../../components/noData";
import { ActivityCardsimmer } from "../statistics/component/activityCardsimmer";
import { currencyFormatted, errorRoute } from "../../utils";
import { Invoices_style } from "../invoices/style";
import { useLazyAlreadypaidCreationQuery, useLazyGetRequestStatusEnumQuery, useLazyInvoicesPdfQuery } from "../../redux/services";
import { AlreadyPaidDrawer } from "../invoices/alreadyPaidDrawer";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { useSnackbar } from "notistack";


export const UnitTabs = ({
    contractUnits = {},
    contractInvoices = {},
    invoiceSimmer = false,
    unitSimmer = false,
    t = () => false
}) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()

    const initialData = {
        amount_paid: "",
        type: "",
        date: "",
        bank_location: "",
        transaction_id: "",
        notes: "",
        error: {
            amount_paid: "",
            date: "",
            type: "",
            bank_location: "",
            transaction_id: "",
            notes: "",

        }
    }
    const [selectedTab, setSelectedTab] = React.useState(1)
    const [drawer, setDrawer] = React.useState(false);
    const [state, setState] = React.useState({});
    const [selectedInvoice, setSelectedInvoice] = React.useState({
        id: "",
        is_checked: false,
        items: []
    }); const [drawers, setDrawers] = React.useState({
        paymentDrawer: false,
        alreadyPaidDrawer: false
    });
    const [pdf , setPdf]=React.useState({
        pdf:"",
        bool:false,
        open:false
    })

    const [isQuickPay, setIsQuickPay] = React.useState(false);
    const [PaymentEnumAPI, { error }] = useLazyGetRequestStatusEnumQuery()
    const [paymentMode, setPaymentMode] = React.useState([]);
    const [paymentForm, setPaymentForm] = React.useState({...initialData});
    const [AlreadypaidCreation , {error:isError3}] = useLazyAlreadypaidCreationQuery()
    const [InvoicePdf , {error:isError4}] = useLazyInvoicesPdfQuery()


    const handleDrawerOpen = (e) => {
        setState(e)
        setDrawer(!drawer);
    };
    const handleDrawerClose = () => {
        setDrawer(false);
    }
    const handlePaymentDrawerClose = () => {
        setDrawers({
            ...drawers, paymentDrawer: false
        });
    };

    const handlePaymentDrawerOpen = (x) => {
        setDrawers({
            ...drawers, paymentDrawer: true
        });
    };
    const handleDelete = (e, index) => {
        handleChecked(e)
    }
    const handleChecked = (e) => {
        if (selectedInvoice?.items?.map((v) => v?.id).includes(e?.id)) {
            setSelectedInvoice({
                ...selectedInvoice,
                id: e?.id,
                is_checked: true,
                items: selectedInvoice?.items?.filter((x) => x?.id !== e?.id)
            })
            setIsQuickPay(false)
        }
        else {
            setSelectedInvoice({
                ...selectedInvoice,
                items: [...selectedInvoice?.items, e]
            })
            setIsQuickPay(false)
        }
    }
    const paymentEnum = async () => {
        const paymentEnumRes = await PaymentEnumAPI(["payment_mode"])
        setPaymentMode(paymentEnumRes?.data?.data)
    }
    const handleAlreadyPaid = () => {
        paymentEnum()
        setDrawers({
            ...drawers, alreadyPaidDrawer: true
        })
    }
    const handleAlreadyPaidClose = () => {
        setDrawers({
            ...drawers, alreadyPaidDrawer: false
        })
    }
    const updateState = (key, value) => {
        let error = paymentForm?.error
        error[key] = "";
        setPaymentForm({
            ...paymentForm, [key]: value, error
        })
    }
    const validate = () => {
        let isValid = true;
        let error = paymentForm?.error
        if (paymentForm?.amount_paid?.length === 0) {
            isValid = false
            error.amount_paid = t("Paid amount is Required")
        }
        if (paymentForm?.type?.length === 0) {
            isValid = false
            error.type = t("Type is Required")
        }
        if (paymentForm?.date?.length === 0) {
            isValid = false
            error.date = "Date is Required"
        }
        if (paymentForm?.bank_location?.length === 0) {
            isValid = false
            error.bank_location = t("Bank name/Location is Required")
        }
        if (paymentForm?.transaction_id?.length === 0) {
            isValid = false
            error.transaction_id = t("Transaction Id is Required")
        }
        if (paymentForm?.notes?.length === 0) {
            isValid = false
            error.notes = t("Notes is Required")
        }

        setPaymentForm({ ...paymentForm, error })
        return isValid
    }

    const SaveReconcile = async () => {
        if (validate()) {
            const payload = {
                amount_paid: paymentForm?.amount_paid,
                bank: paymentForm?.bank_location,
                invoice_id: selectedInvoice?.id,
                notes: paymentForm?.notes,
                paid_on: paymentForm?.date,
                payment_mode: paymentForm?.type,
                trx_no: paymentForm?.transaction_id
            }
            const payed = await AlreadypaidCreation({ payload })

            if (payed?.isSuccess) {
                handleAlreadyPaidClose()
                setPaymentForm(initialData)
                handlePaymentDrawerClose()
                navigate(AppRoutes?.payment, { state: true })

            }
            else {
                navigate(AppRoutes?.payment, { state: false })

            }

        }
        else{
            enqueueSnackbar(t(`Please Fill Mandatory Fields`), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
              })
        }
    }
    const onClickPDF=async(x)=>{
        await setPdf({
            pdf:"",
            bool:true,
            open:true
        })
        const data = await InvoicePdf({
            invoice_id:x?.id
        })
        if(data?.error?.originalStatus === 200){
            await setPdf({
                pdf:data?.error?.data ?? "",
                bool:false,
                open:true
            })
        }
        else{
            enqueueSnackbar(t(`Some Thing Went Wrong`), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
              })
        }
    }
    const handlePdfDrawerClose =()=>{
        setPdf({
           ...pdf,
           open:false
       })
   }
   React.useEffect(()=>{
    if(error?.status === 423|| isError3?.status === 423 || isError4?.status === 423 ) return errorRoute() 
 },[error , isError3 , isError4 ])

    return (
        <>
            <Container maxWidth="sm">
                <TabComp
                    tab1Label={`${t("Invoices")} (${contractInvoices?.data?.count ?? 0})`}
                    tab2Label={`${t("Unit")} (${contractUnits?.data?.count ?? 0})`}
                    selectedTab={selectedTab}
                    onTab1Clicked={() => setSelectedTab(0)}
                    onTab2Clicked={() => setSelectedTab(1)}
                />
                {
                    selectedTab === 0 && <>
                        {invoiceSimmer ?
                            <ActivityCardsimmer /> :
                            contractInvoices?.data?.data?.length > 0 ?
                                <Box sx={ContractDetailsStyle?.entire}>
                                    {contractInvoices?.data?.data?.map((val, i) => {
                                        return <ContractInvoiceCard
                                        handleChecked={() => handleChecked(val, i)}

                                            description={val?.description ?? "-"}
                                            business={val?.invoice_no}
                                            date={val?.payment_due_date}
                                            isImage={false}
                                            rightSideContent={true}
                                            inflation={currencyFormatted(val?.invoice_due_amount)}
                                            currValue={val?.status}
                                            symbol={val?.symbol}
                                            hidden={val?.length === i + 1 ? false : true}
                                            is_checked=
                                            {selectedInvoice?.items?.map((v) => v?.id).includes(val?.id)}
                                        />
                                    })}
                                </Box> :
                                <NoData
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        height: "calc(100vh - 220px)"
                                    }} />
                        }
                    </>
                }
                {selectedTab === 1 && <>
                    {
                        unitSimmer ?
                            <ActivityCardsimmer /> :
                            contractUnits?.data?.data?.length > 0 ?
                                <Box>
                                    {contractUnits?.data?.data?.map(e => {
                                        return <ContractUnitCard
                                            handleDrawerOpen={() => handleDrawerOpen(e)}
                                            img={e?.unit?.logo ? e?.unit?.logo : "/images/contractAvatar.svg"}
                                            unitId={e?.unit?.unit_no}
                                            sqMeters={e?.unit?.total_area}
                                            total_baths={e?.unit?.total_baths}
                                            total_bed_rooms={e?.unit?.total_bed_rooms}
                                            furnishing={e?.unit?.furnishing}
                                            street_1={e?.unit?.address?.street_1}
                                            street_2={e?.unit?.address?.street_2}
                                            unit_category_name={e?.unit?.category?.name}
                                        />
                                    })}
                                </Box>
                                : <NoData
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        height: "calc(100vh - 220px)"
                                    }} />
                    }

                </>}
                {(selectedTab === 0 && selectedInvoice?.items?.length > 0) &&
                    <Box sx={Invoices_style?.card}>
                        <Container maxWidth="sm">
                            <Box sx={Invoices_style?.cardBack}>
                                <BottomInvoice
                                    price={currencyFormatted(selectedInvoice?.items?.map((x) => x?.invoice_due_amount).reduce((a, b) => a + b, 0))}
                                    total={selectedInvoice?.items?.length}
                                    btnText={t('Proceed Payment')}
                                    handlePaymentDrawerOpen={handlePaymentDrawerOpen}
                                    handlePaymentDrawerClose={handlePaymentDrawerClose}
                                    drawer={drawer?.paymentDrawer}
                                    selectedInvoice={selectedInvoice?.items}
                                    handleDelete={handleDelete}
                                    handleChecked={handleChecked}
                                    handleAlreadyPaid={handleAlreadyPaid}
                                    currency={selectedInvoice?.items?.find((x) => x?.symbol)?.symbol}
                                    t={t} />
                            </Box>
                        </Container>
                    </Box>}
            </Container>

            <DialogDrawer
                maxWidth="sm"
                open={drawer}
                height_style={
                    { height: "500px !important" }
                }
                onClick={handleDrawerClose}
                header={t("Unit")}
                component={<UnitDrawerDetails data={state} />}
                onClose={() => {
                    setDrawer(false)
                }}
            />
               <DialogDrawer
                    maxWidth="sm"
                    open={drawers?.alreadyPaidDrawer}
                    onClick={handleAlreadyPaidClose}
                    header={t("Already Paid ?")}
                    height_style={
                        { height: "429px !important" }
                    }
                    header_style={{
                        padding:"8px !important"
                    }}
                    footer_style={{
                        padding:"8px !important"
                    }}
                    component={<AlreadyPaidDrawer
                        t={t}
                        updateState={updateState}
                        paymentMode={paymentMode}
                        data={paymentForm} />}
                    footer={
                        <>
                            {/* footer Component */}
                            < FooterComponent
                                single
                                containedText={t("Reconcile")}
                                containedonClick={SaveReconcile}
                            />
                        </>
                    }

                    onClose={() => handleAlreadyPaidClose()}
                />

                <DialogDrawer
                    maxWidth="sm"
                    open={drawers?.paymentDrawer}
                    onClick={handlePaymentDrawerClose}
                    header={t("Payment")}
                    height_style={
                        { height: "auto !important" }
                    }
                    header_style={{
                        padding:"8px !important"
                    }}
                    footer_style={{
                        padding:"8px !important"
                    }}
                    component={
                        <>
                            <PaymentModal
                            symbol={selectedInvoice?.items?.find((x)=>x?.symbol)?.symbol}
                                t={t}
                                price={
                                currencyFormatted(selectedInvoice?.items?.map((x) => x?.invoice_due_amount).reduce((a, b) => a + b, 0))}
                                invoice_total_tax_amount={
                                    currencyFormatted(selectedInvoice?.items?.map((x) => x?.invoice_total_tax_amount).reduce((a, b) => a + b, 0))}
                                selectedInvoice={selectedInvoice?.items}
                                handleDelete={handleDelete}
                                isQuickPay={isQuickPay}
                                onClickPDF={onClickPDF}
                                handleChecked={handleChecked}
                                />
                        </>
                    }
                    footer={
                        <>
                            {/* footer Component */}
                            < FooterComponent
                                outlinedDisabled={selectedInvoice?.items?.length !== 1 ?? true}
                                outlinedText={t("Already Paid?")}
                                containedText={t("Pay now")}
                                containedonClick={() => null}
                                outlinedonClick={() => {
                                    handleAlreadyPaid()
                                }}
                                containedDisabled={true}
                            />

                        </>

                    }

                    onClose={() => handlePaymentDrawerClose()}
                />

<DialogDrawer
                    maxWidth="sm"
                    open={pdf?.open}
                    onClick={handlePdfDrawerClose}
                    header={t("Invoice")}
                    height_style={
                        { height: "500px !important" }
                    }
                    header_style={{
                        padding:"8px !important"
                    }}
                    footer_style={{
                        padding:"8px !important"
                    }}
                    component={
                        <>
                            {
                                pdf?.bool ?
                                    <>
                                     <LoderSimmer card count={1} size={500} variant={"rectangular"} animation={"wave"} /></>
                                    :
                                    !!pdf?.pdf ?
                                        <DocumentViewer url={`data:application/pdf;base64,${pdf?.pdf}`}/> : <NoData />
                            }
                        </>
                    }
                    onClose={() => handlePdfDrawerClose()}
                />
        </>
    )
}