import { Box, Typography } from "@mui/material"
import { LocalStorageKeys, currencyFormatted } from "../../utils"
import { ToolTipCustom_style } from "./style"
export const ToolTipCustom = ({ active, payload, label }) => {

    const currency = localStorage.getItem(LocalStorageKeys?.currency) ? JSON.parse(localStorage.getItem(LocalStorageKeys?.currency))?.symbol : ''

    return (
        <Box sx={ToolTipCustom_style?.sx}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={ToolTipCustom_style?.dot} style={{backgroundColor:payload?.[0]?.payload?.fill}}></Box>
                <Box sx={{ paddingLeft: '12px' }}>
                    <Typography sx={ToolTipCustom_style?.amt}>{currency} {payload?.[0]?.payload?.value ? currencyFormatted(payload?.[0]?.payload?.value) : ""} {payload?.[0]?.payload?.amount ? currencyFormatted(payload?.[0]?.payload?.amount):""}</Typography>
                </Box>
            </Box>
        </Box>
    )
}


export const ToolTipCustomBar = ({ active, payload, label , state , category}) => {
    const currency = localStorage.getItem(LocalStorageKeys?.currency) ? JSON.parse(localStorage.getItem(LocalStorageKeys?.currency))?.symbol : ''

    return (
        <Box sx={ToolTipCustom_style?.sx}>
        {
            state === 1 ? 
                <Box sx={{display:"block"}}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={ToolTipCustom_style?.dot} style={{ backgroundColor: payload?.[0]?.stroke }}></Box>
                        <Box sx={{ paddingLeft: '7.5px' }}>
                            <Typography sx={ToolTipCustom_style?.amt}>{currency} {currencyFormatted(payload?.[0]?.payload?.amount)} </Typography>
                        </Box>

                    </Box>
                    <Box>
<Typography sx={ToolTipCustom_style?.category}> {!!category ? payload?.[0]?.payload?.sub_category : payload?.[0]?.payload?.category} </Typography>

</Box>
                </Box>
        :

        <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={ToolTipCustom_style?.dot} style={{backgroundColor:payload?.[1]?.stroke}}></Box>
            <Box sx={{ paddingLeft: '7.5px' }}>
                <Typography sx={ToolTipCustom_style?.amt}>{currency} {currencyFormatted(payload?.[0]?.payload?.current_value)}</Typography>
            </Box>
            <Box>

</Box>
        </Box>
<Typography sx={ToolTipCustom_style?.category}> {!!category ? payload?.[0]?.payload?.sub_category : payload?.[0]?.payload?.category} </Typography>

        </Box>
        }
        
        
    </Box>
    )
}


export const ToolPieTipCustom = ({ active, payload, label }) => {

    return (
        <Box sx={ToolTipCustom_style?.sx}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={ToolTipCustom_style?.dot} style={{backgroundColor:payload?.[0]?.payload?.fill}}></Box>
                <Box sx={{ paddingLeft: '12px' }}>
                    <Typography sx={ToolTipCustom_style?.pieName}><Typography sx={ToolTipCustom_style?.amount} variant="span">{payload?.[0]?.payload?.symbol} {payload?.[0]?.payload?.value}</Typography> {payload?.[0]?.payload?.invoice_no}</Typography>
                </Box>
            </Box>
        </Box>
    )
}