
import { LoderSimmer } from "../../../components"
import { Box } from "@mui/material"


export const InspectSimmer = () => {
    const count = 5
    return (
        <Box>
            {Array.apply(null, Array(count)).map((value, index) => (
                <Box sx={{ backgroundColor: '#fff', width: 'auto', height: '120px', padding: '16px', display: 'flex', alignItems: 'center' }}>
                    <Box><LoderSimmer card count={1} width={70} size={70} variant={"rounded"} animation={"wave"} /></Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box><LoderSimmer card count={1} width={40} size={10} variant={"rounded"} animation={"wave"} /></Box>
                            <Box><LoderSimmer card count={1} width={40} size={10} variant={"rounded"} animation={"wave"} /></Box>
                        </Box>
                        <Box><LoderSimmer card count={1} width={140} size={10} variant={"rounded"} animation={"wave"} /></Box>
                        <Box><LoderSimmer card count={1} width={140} size={10} variant={"rounded"} animation={"wave"} /></Box>
                    </Box>
                </Box>

            ))}

        </Box>
    )
}
