import React from "react";
import { Statistic } from "./statistics";
import { withNavBars } from "./../../HOCs";
import { withTranslation } from 'react-i18next';

class StatisticParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <Statistic  {...this?.props} />;
  }
}
const props = {
  title: 'Statistics',
  is_arrow:false
}

export default withNavBars(withTranslation('statistic')(StatisticParent), props);
