import { Box, Stack, Typography } from "@mui/material"
import { ProfileListStyles } from "./style";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const ProfileListCard = ({
    title = "",
    icons = "",
    onClick = () => false,
    box_styles = {},
    currency = "",
    type = false
}) => {
    const language = localStorage.getItem("i18nextLng")

    return (
        <>

            {type === true ?
                <Box
                    sx={{ ...ProfileListStyles?.boxStyles, ...box_styles }}
                    onClick={onClick}
                >
                    <Stack direction={"row"}  justifyContent={"space-between"} alignItems="center" >
                        <Stack direction={"row"} alignItems="center">
                            {/* icons  */}
                            {icons}
                            {/* texts */}
                            <Typography sx={ProfileListStyles?.text}>
                                {title} {currency}

                            </Typography>
                        </Stack>

                        <Stack>
                            {language === "ar" ?
                                <ArrowBackIosIcon sx={ProfileListStyles?.arrowIcon} /> :
                                <ArrowForwardIosIcon sx={ProfileListStyles?.arrowIcon} />
                            }
                        </Stack>
                    </Stack>
                </Box>
                :
                <a href={onClick} style={{ textDecoration: "none" }}>
                    <Box
                        sx={{ ...ProfileListStyles?.boxStyles, ...box_styles }}
                    >
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" >
                            <Stack direction={"row"} alignItems="center">
                                {/* icons  */}
                                {icons}
                                {/* texts */}
                                <Typography sx={ProfileListStyles?.text}>
                                    {title} {currency}

                                </Typography>
                            </Stack>

                            <Stack>
                                {language === "ar" ?
                                    <ArrowBackIosIcon sx={ProfileListStyles?.arrowIcon} /> :
                                    <ArrowForwardIosIcon sx={ProfileListStyles?.arrowIcon} />
                                }
                            </Stack>
                        </Stack>



                    </Box>
                </a>

            }
        </>
    )
}