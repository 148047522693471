import { SemiBold } from "../../utils";

export const favCards_style = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 60px",
  },
  background: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor:"pointer",
  },
  bottomText: {
    fontFamily: SemiBold,
    fontSize: "0.75rem",
    color: "text.main",
    textTransform: "capitalize",
    marginTop:"8px",
    textAlign:"center"
  },
  imageAlign: {
    marginTop: "5px",
    backgroundColor: "red"
  },
};
