import * as React from "react"
const ArabicArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32.554}
    height={32.554}
    {...props}
  >
    <g data-name="Group 117184">
      <g
        fill="#f2f4f7"
        stroke="#e4e8ee"
        data-name="Ellipse 39585"
        transform="rotate(-180 16.078 16.2)"
      >
        <circle cx={16} cy={16} r={16} stroke="none" />
        <circle cx={16} cy={16} r={15.5} fill="none" />
      </g>
      <path
        fill="#071741"
        stroke="#071741"
        strokeWidth={0.50007}
        d="m18.422 20.966-4.503-5.103 4.674-4.946a.61.61 0 0 0 .014-.808.49.49 0 0 0-.738-.013l-5.049 5.348a.61.61 0 0 0-.013.808l4.864 5.513a.49.49 0 0 0 .738.013.61.61 0 0 0 .013-.812Z"
      />
    </g>
  </svg>
)
export default ArabicArrow
