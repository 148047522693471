import * as React from "react"

const Build = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.796}
    height={11.922}
    {...props}
  >
    <g data-name="Group 619">
      <path
        d="M1.4 0a.47.47 0 0 0-.068.007H.784a.47.47 0 1 0 0 .941h.157v5.989A1.092 1.092 0 0 0 0 8.003v3.449a.47.47 0 0 0 .47.47h8.78a.47.47 0 0 0 .47-.47V8.003a1.092 1.092 0 0 0-.941-1.066V.948h.157a.47.47 0 1 0 0-.941h-.55a.47.47 0 0 0-.152 0H1.488A.47.47 0 0 0 1.4 0Zm.478.947h5.961v6.428a.47.47 0 0 0 .47.47h.314a.15.15 0 0 1 .157.157v2.98H5.644V9.1a.314.314 0 0 0-.314-.314h-.94a.314.314 0 0 0-.314.314v1.882H.941V8.003a.15.15 0 0 1 .157-.157h.314a.47.47 0 0 0 .47-.47Zm1.571 1.255a.314.314 0 0 0-.314.314v.627a.314.314 0 0 0 .314.314h.627a.314.314 0 0 0 .314-.314v-.627a.314.314 0 0 0-.314-.314Zm2.195 0a.314.314 0 0 0-.314.314v.627a.314.314 0 0 0 .314.314h.627a.314.314 0 0 0 .314-.314v-.627a.314.314 0 0 0-.314-.314Zm3.763 0v.941h2.665a.784.784 0 0 1 .784.784v7.055h-2.509v.627a.929.929 0 0 1-.058.314h3.036a.47.47 0 0 0 .47-.47V3.922a1.728 1.728 0 0 0-1.723-1.72ZM3.449 4.397a.314.314 0 0 0-.314.314v.627a.314.314 0 0 0 .314.314h.627a.314.314 0 0 0 .314-.314V4.71a.314.314 0 0 0-.314-.314Zm2.195 0a.314.314 0 0 0-.314.314v.627a.314.314 0 0 0 .314.314h.627a.314.314 0 0 0 .314-.314V4.71a.314.314 0 0 0-.314-.314Zm5.33 0a.314.314 0 0 0-.314.314v.627a.314.314 0 0 0 .314.314h.626a.314.314 0 0 0 .314-.314V4.71a.314.314 0 0 0-.314-.314ZM3.449 6.592a.314.314 0 0 0-.314.314v.627a.314.314 0 0 0 .314.314h.627a.314.314 0 0 0 .314-.314v-.627a.314.314 0 0 0-.314-.314Zm2.195 0a.314.314 0 0 0-.314.314v.627a.314.314 0 0 0 .314.314h.627a.314.314 0 0 0 .314-.314v-.627a.314.314 0 0 0-.314-.314Zm5.33 0a.314.314 0 0 0-.314.314v.627a.314.314 0 0 0 .314.314h.626a.314.314 0 0 0 .314-.314v-.627a.314.314 0 0 0-.314-.314ZM2.195 8.787a.314.314 0 0 0-.314.314v.627a.314.314 0 0 0 .314.314h.627a.314.314 0 0 0 .314-.314v-.627a.314.314 0 0 0-.314-.314Zm4.7 0a.314.314 0 0 0-.314.314v.627a.314.314 0 0 0 .314.314h.627a.314.314 0 0 0 .314-.314v-.627a.314.314 0 0 0-.314-.314Zm4.076 0a.314.314 0 0 0-.314.314v.627a.314.314 0 0 0 .314.314h.629a.314.314 0 0 0 .314-.314v-.627a.314.314 0 0 0-.314-.314Z"
        fill="#071741"
        data-name="Group 82"
      />
    </g>
  </svg>
)

export default Build
