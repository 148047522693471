import { Bold, ExtraBold, Regular, SemiBold } from "../../../utils";

export const RequestCardStyle = ({
    size = "",
}) => {
    return {
        header: {
            color: "#091B29",
            fontSize: "0.875rem",
            fontFamily: ExtraBold,
            maxWidth: size?.width - 155
        },
        subHeader: {
            color: "#4E5A6B",
            fontSize: "0.75rem",
            fontFamily: Regular,
        },
        subdetails: {
            color: "#091B29",
            fontSize: "0.75rem",
            fontFamily: Regular,
        },
        actionText: {
            color: "#FFFFFF",
            fontSize: "0.75rem",
            fontFamily: SemiBold,
        },
        address: {
            color: "#4E5A6B",
            fontSize: "0.75rem",
            fontFamily: SemiBold,
        },
        status: {
            color: "#091B29",
            fontSize: "0.75rem",
            backgroundColor: "#5078E11E",
            display: "inline-block",
            padding: "0 7px 2px 7px",
            fontFamily: SemiBold,
            borderRadius: "4px",
        },
        date: {
            color: "#4E5A6B",
            fontSize: "0.75rem",
            fontFamily: Regular
        },
        rightIcon: {
            fontSize: "1.5625rem",
            borderRadius: "50%",
            backgroundColor: "#F2F4F7",
            cursor: "pointer",
            padding: "10px",
        },
        image: {
            objectFit: "contain",
            width: "70px",
            height: "70px",
            borderRadius: "6px",
            border: "1px solid #E4E8EE"
        },
        imageBox: {
            margin: 0,
            textAlign: "left",
            marginInlineEnd: "12px"
        },
        icon: {
            backgroundColor: "#F2F4F7",
            border: "1px solid #E4E8EE",
            width: "32px",
            height: "32px"
        },
        line2: {
            flexDirection: { xs: "row", sm: "row" },
            columnGap: "6px",
            marginBottom: "4px",
        },
        bottomText1: {
            position: "absolute",
            bottom: 0,
            color: "white",
            textAlign: "center",
            right: "0px",
            left: "0px"
        },
        progress: {
            fontSize: "0.75rem",
            color: "#FFFFFF",
            fontFamily: Bold,
            borderRadius: "25px",
            padding: "2px 8px",
            textAlign: "center",
        },
    }
};


