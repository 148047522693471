import { Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import { SimmerCardStyles } from './style'

export const LoderSimmer = ({
    count,
    size,
    variant,
    width
}) => {

    return (
        <div>
            {Array.apply(null, Array(count)).map((value, index) => (
                <Stack spacing={2} key={index} sx={SimmerCardStyles?.cardHead}>
                    <Skeleton
                        variant={variant}
                        height={size}
                        //style={{ borderRadius: "12px" }}
                        width={width}
                    />
                </Stack>
            ))}
        </div>
    );
};
