import React from "react";
import { withTranslation } from 'react-i18next';
import { QrCode } from "./qrCode";
  
class ChooseQrCodeParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
    
        }
      }
  render() {
    return <QrCode {...this?.props}/> ;
  }
}

export default withTranslation('chooseAppliance')(ChooseQrCodeParent);