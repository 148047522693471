import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { SliderStyles } from "./styles";
import { findFileFormat, generateImageUrl, img_size } from '../../utils'
import { DocumentViewer } from "../documentViewer";

export const Slider = ({ onClick = () => false, ...props }) => {
    const [selected, setSelected] = React.useState(props?.selected ? props?.selected : 0)

    const next = (e) => {
        e.stopPropagation();
        if ((props?.assets?.length - 1) > selected) {
            setSelected(selected + 1)
        }
    }

    const previous = (e) => {
        e.stopPropagation();

        if (selected !== 0) {
            setSelected(selected - 1)
        }
    }

    React.useEffect(() => {
        setSelected(props?.selected ? props?.selected : 0)
        // eslint-disable-next-line
    }, [props?.assets])
    const type = findFileFormat(props?.assets?.[selected]?.url)
    return (
        <>
            {(props?.assets?.[selected]?.url || props?.assets?.[selected]?.src) &&
                <Box sx={SliderStyles?.caroselRoot}>
                    {/* carosel */}
                    <Grid container alignItems="center" justifyContent={"space-between"} style={{ borderRadius: "4px" }}>
                        <Grid >
                            {props?.assets?.length > 1 &&
                            selected !== 0  ? 

                                <IconButton disabled={selected === 0} onClick={previous} size="small"
                                    sx={SliderStyles?.arrowBtn}>
                                    <ArrowBackIosIcon style={{ fontSize: "1rem" }} />
                                </IconButton>
                                :""
                            }
                        </Grid>

                        <Grid item xs={12} onClick={() => onClick(props?.assets?.[selected])}>
                            {props?.assets?.[selected] &&
                                < Box >
                                    {type === "pdf" ?
                                        <Box height={props?.height ? props?.height : "220px"}>
                                            {(props?.assets?.[selected]?.url || props?.assets?.[selected]?.src) &&
                                                <DocumentViewer url={props?.assets?.[selected]?.url || props?.assets?.[selected]?.src} />
                                            }
                                        </Box>
                                        :
                                        <Box height={props?.imageHeigth}>
                                            {type === "mp4" ?

                                                <video
                                                    style={{ borderRadius: props?.borderRadius ? props?.borderRadius : "4px", objectFit: props?.objectFit ? props?.objectFit : "contain", width: "100%" }}
                                                    height={props?.height ? props?.height : "250px"}
                                                    controls>
                                                    <source src={props?.assets?.[selected]?.url} type="video/mp4" />
                                                </video>
                                                :
                                                <img
                                                    src={generateImageUrl(props?.assets?.[selected]?.url || props?.assets?.[selected]?.src, img_size.small_square)}
                                                    alt="No Img"
                                                    height={props?.height ? props?.height : "250px"}
                                                    width="100%"
                                                    style={{ borderRadius: props?.borderRadius ? props?.borderRadius : "4px", objectFit: props?.objectFit ? props?.objectFit : "contain" }}
                                                />
                                            }

                                        </Box>
                                    }
                                </Box>
                            }
                            {/* {
                        !props?.pageNumber &&
                        <Typography textAlign="center" sx={SliderStyles?.title}>{selected + 1}/{props?.assets?.length}</Typography>
                    } */}

                        </Grid>

                        <Grid>


                            {props?.assets?.length > 1 &&
                            (props?.assets?.length - 1) !== selected  ? 
                                <IconButton  onClick={next} size="small" sx={SliderStyles?.arrowBtnRight}>
                                    <ArrowForwardIosIcon style={{ fontSize: "1rem" }} />
                                </IconButton> : ""
                            }


                        </Grid>
                    </Grid>
                </Box>
            }
        </>
    );
};
