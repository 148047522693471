import * as React from "react"

const AboutApp = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 109042">
      <path
        data-name="icons8-smartphone (1)"
        d="M8.525 3A2.035 2.035 0 0 0 6.5 5.025v13.95A2.035 2.035 0 0 0 8.525 21h6.75a2.035 2.035 0 0 0 2.025-2.025V5.025A2.035 2.035 0 0 0 15.275 3Zm0 1.35h6.75a.665.665 0 0 1 .675.675v13.95a.665.665 0 0 1-.675.675h-6.75a.665.665 0 0 1-.675-.675V5.025a.665.665 0 0 1 .675-.675ZM11.9 5.7a.675.675 0 1 0 .675.675.675.675 0 0 0-.675-.675Zm-1.125 11.25a.675.675 0 1 0 0 1.35h2.25a.675.675 0 1 0 0-1.35Z"
        fill="#f15a29"
        stroke="#f15a29"
        strokeLinecap="round"
        strokeWidth={0.2}
      />
    </g>
  </svg>
)

export default AboutApp
