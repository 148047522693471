

export const ContractsStyle = {
    root: {
        padding: "16px",
        width: '100%',
        overflow: "auto",
        backgroundColor: '#F8F8F8',

        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    container: {
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
    },
    containerSm: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        height: "100%",
    },
    contractSepBox: {
        borderBottom: "1px solid #E4E8EE",
        marginBottom: "12px",
        cursor: "pointer",

    },
    entireBox: {
        padding: "0px 12px 0 12px",
        boxShadow: "0px 3px 30px #5C86CB2E",
        backgroundColor: "primary.contrastText",
        borderRadius: "12px",
        overflow: "auto",
        marginTop: "10px"

    },
    rootSection: {
        height: '100%',
    },
    Topnav: {
        position: 'fixed',
        top: 0,
        zIndex: 999,
        left:0,
        right:0
    },
}