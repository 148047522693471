
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";
// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
    },
});
const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });


export const enum_value = createApi({
    reducerPath: "enum",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["Enum"],
    endpoints: (builder) => ({
        // enum API 
        getRequestStatusEnum: builder.query(
            {
                query: (enumTypes) => {
                    return {
                        url: '/enum', method: "POST", body: { enumName: enumTypes },
                    }
                },
                transformResponse: (emnum, meta, arg) => {
                    return emnum
                },
            }
        ),

        getUnitEnum: builder.query({
            query: (payload) => ({ url: '/owner/get_all_owner_units', method: "POST", body: payload, }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response;
                }
                return [];
            },
        }),

        imageMutation: builder.query({
            query: (payload) => ({ url: 'file-upload', method: "POST", body: payload }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response?.fileUrls[0].url
                }
                return ''
            },
        }),
        getPolicy: builder.query({
            query: (payload) => ({ url: 'client/get_app_configuration', method: "GET" }),
            transformResponse: (response, meta, arg) => {
                if (response?.type === "success") {
                    return response
                }
                return ''
            },
        }),
    }),
});

// Export hooks for usage in functional components
export const {
    useLazyGetUnitEnumQuery,
    useLazyImageMutationQuery,
    useLazyGetRequestStatusEnumQuery,
    useLazyGetPolicyQuery
} = enum_value;
