import React from "react";
import { withNavBars } from "../../HOCs";
import { withTranslation } from 'react-i18next';
import { ExternalSite } from "./externalSite";

class ExternalSiteParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <ExternalSite {...this?.props}/>;
  }
}
const props = {
  is_dashboard: true,
  is_external_site: true,
}

export default withNavBars(withTranslation('home')(ExternalSiteParent), props);

