import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useMediaQuery } from "@mui/material";
import { ToolTipCustomBar } from '../customTooltip';
import NoData from '../noData';


export const LineCharts = ({
  data = [],
  category={}
}) => {
  const mediaQuery = useMediaQuery('(max-width:522px)')


  const [state , setState]=React.useState(null)
  
const maxValue = data.reduce((max, obj) => (obj.current_value > max ? obj.current_value : max), -Infinity)

  return (
  <div style={{ width: '100%', height: mediaQuery ? 250 : 300, position: "relative" }}>

    {
                (data?.some((x) => x?.current_value > 0)|| data?.some((x) => x?.amount > 0)) ?
                <ResponsiveContainer >
                <LineChart
                  // height={300}
                  data={data}
                  margin={{ top: 0, right: 10, left: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="0" horizontal={true} vertical={false} />
                  <XAxis dataKey="name" axisLine={false} />
                  <YAxis allowDecimals={true} axisLine={false} dataKey="amount" tickLine={false} type="number" domain={[0,  maxValue]}  />
                  <Tooltip content={<ToolTipCustomBar data={data} state={state} category={category}/>} cursor={{ fill: "transparent" }} />
                  <Line type="monotone" dataKey="amount" stroke="#FF6D65" activeDot={{ r: 5 , 
                    onMouseOver: ()=>setState(1),
                    onClick: ()=>setState(1),
                    onMouseLeave: ()=>setState(1),
                    }} 
                    // dot={false}
                    isAnimationActive={false} />
          
                  <Line type="monotone" dataKey="current_value" stroke="#686AF3" 
                  activeDot={{ r: 5 , 
                   onMouseOver: ()=>setState(2),
                   onClick: ()=>setState(2),
                   onMouseLeave: ()=>setState(2)
                  }} isAnimationActive={false} />
                </LineChart>
              </ResponsiveContainer>
                    : <NoData chart={true} height={mediaQuery ? 250 : 280} width={200} style={{ backgroundColor: "#fff", }} />
            }
  </div>
  );
}
