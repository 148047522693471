import * as React from "react"

export const Download = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} {...props}>
        <g data-name="Group 109104" transform="translate(-309 -77)">
            <rect
                data-name="Rectangle 52955"
                width={34}
                height={34}
                rx={4}
                transform="translate(309 77)"
                fill="rgba(241,90,41,0.08)"
            />
            <path
                d="M325.991 86.454a.629.629 0 0 0-.619.637v8.958l-1.441-1.441a.629.629 0 1 0-.889.889l2.517 2.514a.629.629 0 0 0 .889 0l2.511-2.514a.629.629 0 1 0-.889-.889l-1.441 1.441v-8.957a.629.629 0 0 0-.638-.637Zm-6.913 10.475a.629.629 0 0 0-.619.638v1.676a2.314 2.314 0 0 0 2.3 2.3h10.479a2.314 2.314 0 0 0 2.3-2.3v-1.676a.629.629 0 1 0-1.257 0v1.676a1.038 1.038 0 0 1-1.047 1.047h-10.475a1.038 1.038 0 0 1-1.047-1.047v-1.676a.629.629 0 0 0-.638-.637Z"
                fill="#f15a29"
            />
        </g>
    </svg>
)

export default Download
