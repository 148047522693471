import * as React from "react"

const LocationIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <g data-name="Group 4500" transform="translate(-3208.744 17520.256)">
      <circle
        data-name="Ellipse 39545"
        cx={14}
        cy={14}
        r={14}
        transform="translate(3208.744 -17520.256)"
        fill="#ff4b4b"
      />
      <path
        d="M3226.065-17512.256a3.313 3.313 0 0 0-3.329 3.289 3.267 3.267 0 0 0 .808 2.141c.056.062 1.363 1.526 1.8 1.941a1.051 1.051 0 0 0 1.438 0c.513-.482 1.754-1.883 1.81-1.947a3.26 3.26 0 0 0 .8-2.137 3.313 3.313 0 0 0-3.327-3.287Zm-4.66 1.579v8.221l2.651 1.937v-4.75c-.5-.541-1-1.1-1.01-1.113a3.888 3.888 0 0 1-.828-3.633Zm-1 .013-3.456 2.513a.5.5 0 0 0-.205.405v6.99a.5.5 0 0 0 .793.4l2.869-2.086Zm5.659.738a1 1 0 1 1-1 1 1 1 0 0 1 1.001-1Zm2.535 4.085c-.428.475-1.029 1.13-1.36 1.441a1.706 1.706 0 0 1-2.289.047v3.952l3.443-2.513a.5.5 0 0 0 .205-.4Z"
        fill="#fff"
      />
    </g>
  </svg>
)

export default LocationIcon
