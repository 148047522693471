import { LoderSimmer } from "../../../components"
import { Box, Typography } from "@mui/material"

export const ProfileCardsimmer = ({
    amount = false
}) => {
    return (
        <Box
        // sx={{ ...ProfileDetailsCardStyles?.boxStyles, ...box_style }}
        >
            <LoderSimmer card count={1} width={30} size={30} variant={"circular"} animation={"wave"} />
            <Typography 
            // sx={ProfileDetailsCardStyles?.text}
            >
                <LoderSimmer card count={1} width={80} size={10} variant={"rounded"} animation={"wave"} />
            </Typography>
            <Typography 
            // sx={ProfileDetailsCardStyles?.text}
            >
                <LoderSimmer card count={1} width={80} size={10} variant={"rounded"} animation={"wave"} />
            </Typography>
        </Box>
    )
}