import { Box, Typography } from "@mui/material"
import { SemiBold } from "../../utils"


const SelectPopoverStyle = {
    dropdown: {
        display: "flex",
        width: {
            sm: "180px",
            xs: "150px"
        },
        height: "220px",
        overflow: "hidden",
        justifyContent: "space-around",
        borderRadius:"8px",
        "& div": {
            overflow: "auto",
            marginBottom: "12px",
            width: "46%",
            margin: "auto",
            height: "100%"
        }
    },
    selectedtext: {
        cursor: "pointer",
        color: "primary.main",
        padding: "3px 0",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        textAlign: "center",
        display:"block"
    },
    unselectedtext: {
        cursor: "pointer",
        padding: "4px 0",
        color: "text.primary",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        textAlign: "center",
        display:"block"

    }
}

export const SelectPopover = ({
    year = [],
    month = [],
    yearValue = "",
    monthValue = "",
    handleChangePeriod = () => false,
    handleIconClose=()=>false,
    values={}
}) => {
    return (
        <Box sx={SelectPopoverStyle?.dropdown}>
            <Box style={{borderRight:"1px solid #E4E8EE"}}>
                {
                    month?.map((e, i) => {
                        return <Typography variant="span" sx={e?.value === monthValue ? SelectPopoverStyle?.selectedtext : SelectPopoverStyle?.unselectedtext}
                            onClick={() => 
                            {
                                handleIconClose()
                                handleChangePeriod(e , values?.year)
                            }
                            }>
                            {e?.value}</Typography>
                    })
                }
            </Box>
            <Box>
                {
                    year?.map((e, i) => {
                        return <Typography variant="span" sx={e?.value === yearValue ? SelectPopoverStyle?.selectedtext : SelectPopoverStyle?.unselectedtext}
                            onClick={() => {
                                handleIconClose()
                                handleChangePeriod(values?.month, e)
                            }}>
                            {e?.value}</Typography>
                    })
                }
            </Box>

        </Box >
    )
}