import { Box, Grid } from "@mui/material"
import { LoderSimmer } from "../../../components"
import { InvoiceSimmmer } from "../../invoices/simmerComponent/invoiceSimmer"
import { RevenueSimmer } from "../../statistics/component/graphs"

export const DashboardSimmer_style = {
    favicon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    align: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    bg: {
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // eslint-disable-next-line
    }
}


export const DashboardSimmer = () => {
    return (
        <Box sx={{ padding: '16px' }}>
            <Box sx={DashboardSimmer_style?.align}>
                <Box>
                    <LoderSimmer card count={1} width={91} size={24} variant={"rectangular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={191} size={19} variant={"rectangular"} animation={"wave"} />
                </Box>
                <Box><LoderSimmer card count={1} width={48} size={51} variant={"circular"} animation={"wave"} />
                </Box>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={4} ><LoderSimmer card count={1} width={'100%'} size={92} variant={"rounded"} animation={"wave"} /></Grid>
                <Grid item xs={4} ><LoderSimmer card count={1} width={'100%'} size={92} variant={"rounded"} animation={"wave"} /></Grid>
                <Grid item xs={4} ><LoderSimmer card count={1} width={'100%'} size={92} variant={"rounded"} animation={"wave"} /></Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4} ><LoderSimmer card count={1} width={'100%'} size={92} variant={"rounded"} animation={"wave"} /></Grid>
                <Grid item xs={4} ><LoderSimmer card count={1} width={'100%'} size={92} variant={"rounded"} animation={"wave"} /></Grid>
                <Grid item xs={4} ><LoderSimmer card count={1} width={'100%'} size={92} variant={"rounded"} animation={"wave"} /></Grid>
            </Grid>

            <LoderSimmer card count={1} width={'auto'} size={110} variant={"rounded"} animation={"wave"} />
            <LoderSimmer card count={1} width={90} size={19} variant={"rounded"} animation={"wave"} />
            <Box sx={DashboardSimmer_style?.align}>
                <Box sx={DashboardSimmer_style?.favicon}>
                    <LoderSimmer card count={1} width={48} size={48} variant={"circular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={64} size={10} variant={"rounded"} animation={"wave"} />
                </Box>
                <Box sx={DashboardSimmer_style?.favicon}>
                    <LoderSimmer card count={1} width={48} size={48} variant={"circular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={64} size={10} variant={"rounded"} animation={"wave"} />
                </Box>
                <Box sx={DashboardSimmer_style?.favicon}>
                    <LoderSimmer card count={1} width={48} size={48} variant={"circular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={64} size={10} variant={"rounded"} animation={"wave"} />
                </Box>
                <Box sx={DashboardSimmer_style?.favicon}>
                    <LoderSimmer card count={1} width={48} size={48} variant={"circular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={64} size={10} variant={"rounded"} animation={"wave"} />
                </Box>
            </Box>

            <Box sx={{
                backgroundColor: '#fff', height: 'auto', width: 'auto', padding: '12px',
                display: 'flex', justifyContent: 'space-between', alignItems: 'center'
            }}>
                <Box sx={DashboardSimmer_style?.favicon}>
                    <LoderSimmer card count={1} width={48} size={48} variant={"circular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={64} size={10} variant={"rounded"} animation={"wave"} />
                </Box >
                <Box sx={DashboardSimmer_style?.favicon}>
                    <LoderSimmer card count={1} width={48} size={48} variant={"circular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={64} size={10} variant={"rounded"} animation={"wave"} />
                </Box>
                <Box sx={DashboardSimmer_style?.favicon}>
                    <LoderSimmer card count={1} width={48} size={48} variant={"circular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={64} size={10} variant={"rounded"} animation={"wave"} />
                </Box>
            </Box>
            <Box sx={{ padding: '8px' }} />
            <RevenueSimmer />
            <Box sx={{ padding: '8px' }} />
            <InvoiceSimmmer />
            <Box sx={{ padding: '8px' }} />
            <Box sx={DashboardSimmer_style?.bg}>
                <LoderSimmer card count={1} width={140} size={160} variant={"rounded"} animation={"wave"} />
                <LoderSimmer card count={1} width={140} size={160} variant={"rounded"} animation={"wave"} />
            </Box>
            <Box sx={{ padding: '8px' }} />
            <Box sx={DashboardSimmer_style?.align}>
                <LoderSimmer card count={1} width={140} size={140} variant={"rounded"} animation={"wave"} />
                <Box>
                    <LoderSimmer card count={1} width={90} size={60} variant={"rounded"} animation={"wave"} />
                    <LoderSimmer card count={1} width={90} size={60} variant={"rounded"} animation={"wave"} />
                </Box>
            </Box>
        </Box>
    )
}