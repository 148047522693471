import { Bold } from "../../utils";

export const AccordianStyles = {
    root: {
        backgroundColor: "transparent",
        boxShadow: "none",
        borderBottom: "1px solid",
        borderColor: "border.secondary",
    },
    headText: {
        color: "text.main",
        fontSize: "1rem",
        fontFamily: Bold
    },
    tag: {
        
        fontSize: "0.75rem",
        fontFamily: Bold,
        padding: "6px 12px",
        borderRadius: "4px",
        textTransform: "capitalize !important",
        
    }

}