import * as React from "react";
export const Docs = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_53216"
          data-name="Rectangle 53216"
          width={22}
          height={22}
          fill="#4e5a6b"
          stroke="#707070"
          strokeWidth={1}
        />
      </clipPath>
    </defs>
    <g id="Group_6416" data-name="Group 6416" clipPath="url(#clip-path)">
      <g
        id="Group_97037"
        data-name="Group 97037"
        transform="translate(3 -0.045)"
      >
        <path
          id="Path_9297"
          data-name="Path 9297"
          d="M-20689.252-1091.384v-4.133l3.584-3.37,3.912,3.677v3.827Z"
          transform="translate(20694.486 1108.932)"
          fill="none"
        />
        <path
          id="icons8-rental-house-contract"
          d="M10.48,4A2.493,2.493,0,0,0,8,6.48V23.564a2.493,2.493,0,0,0,2.48,2.48H23.156a2.493,2.493,0,0,0,2.48-2.48V11.991a.827.827,0,0,0-.242-.584l-.009-.009L18.229,4.242A.827.827,0,0,0,17.644,4Zm0,1.653h6.338v4.684a2.493,2.493,0,0,0,2.48,2.48h4.684V23.564a.814.814,0,0,1-.827.827H10.48a.814.814,0,0,1-.827-.827V6.48A.814.814,0,0,1,10.48,5.653Zm7.991,1.169,4.342,4.342H19.3a.814.814,0,0,1-.827-.827Zm-1.653,6.547a2.38,2.38,0,0,0-1.47.509h0l-2.583,2.035a2.379,2.379,0,0,0-.906,1.868v3.08a1.873,1.873,0,0,0,1.86,1.86h6.2a1.873,1.873,0,0,0,1.86-1.86v-3.08a2.375,2.375,0,0,0-.9-1.866h0l-2.583-2.035h0A2.377,2.377,0,0,0,16.818,13.369Zm0,1.653a.714.714,0,0,1,.447.155h0l2.583,2.035a.719.719,0,0,1,.274.567h0v3.08a.2.2,0,0,1-.207.207h-6.2a.2.2,0,0,1-.207-.207v-3.08a.721.721,0,0,1,.276-.568h0l2.582-2.035A.719.719,0,0,1,16.818,15.022Zm-.551,2.756a.551.551,0,0,0-.551.551v1.1a.551.551,0,0,0,.551.551h1.1a.551.551,0,0,0,.551-.551v-1.1a.551.551,0,0,0-.551-.551Z"
          transform="translate(-8 -4)"
          fill="#4e5a6b"
        />
      </g>
    </g>
  </svg>
);