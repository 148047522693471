import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const StatisticSlice = createSlice({
    name: 'Statistic',
    initialState,
    reducers: {
        setStatistic(state, { payload }) { state = payload; }
    },
})

export const { setStatistic } = StatisticSlice.actions;
export default StatisticSlice.reducer;