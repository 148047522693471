import * as React from "react"

export const MeterIcon = (props) => (
  <svg
    data-name="Group 96526"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={18.729}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 53017"
          fill={props.fill}
          d="M0 0h24v18.729H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 96525" clipPath="url(#a)" fill={props.fill}>
      <path
        data-name="Path 9105"
        d="M0 11.237c.061-.44.105-.882.185-1.318A11.81 11.81 0 0 1 4.9 2.337 11.643 11.643 0 0 1 12 0a12 12 0 0 1 11.77 9.7 11.838 11.838 0 0 1-1.322 8.19.718.718 0 0 1-1.116.307q-.892-.514-1.783-1.03a.707.707 0 1 1 .694-1.222c.414.235.825.475 1.236.712a9.434 9.434 0 0 0 1.052-3.957h-1.49a.689.689 0 0 1-.7-.737.706.706 0 0 1 .738-.665c.483-.007.966 0 1.483 0A10.393 10.393 0 0 0 21.5 7.326l-1.017.585c-.108.062-.214.129-.326.185a.7.7 0 0 1-.939-.261.692.692 0 0 1 .25-.958c.426-.259.862-.5 1.292-.751a9.418 9.418 0 0 0-2.884-2.9c-.249.43-.494.864-.75 1.291a.7.7 0 0 1-.782.338.665.665 0 0 1-.519-.619 1.07 1.07 0 0 1 .129-.485c.222-.419.469-.825.7-1.235a9.692 9.692 0 0 0-3.954-1.06v1.491a.7.7 0 1 1-1.4 0c-.007-.422 0-.844 0-1.265v-.226a9.684 9.684 0 0 0-3.966 1.056c.236.409.483.814.7 1.233a1.08 1.08 0 0 1 .13.485.665.665 0 0 1-.515.622.689.689 0 0 1-.77-.315c-.209-.339-.4-.687-.6-1.031l-.176-.3a10.615 10.615 0 0 0-2.9 2.9l1.282.744A.702.702 0 1 1 3.8 8.075c-.219-.119-.433-.249-.649-.374L2.5 7.326a10.4 10.4 0 0 0-1.064 3.967h.4c.367 0 .734-.006 1.1 0A.7.7 0 0 1 3.66 12a.692.692 0 0 1-.724.7c-.414.007-.828 0-1.242 0h-.256a10.469 10.469 0 0 0 1.057 3.973c.442-.254.869-.506 1.3-.748a.7.7 0 0 1 1 .277.68.68 0 0 1-.252.9q-.993.6-2.006 1.159a.679.679 0 0 1-.934-.271 11.815 11.815 0 0 1-1.461-4.167C.087 13.462.047 13.1 0 12.737v-1.5"
      />
      <path
        data-name="Path 9106"
        d="M14.242 12.031c-.066.263-.117.531-.2.787a.227.227 0 0 0 .057.285c.21.2.413.4.614.612a.704.704 0 1 1-.99 1c-.207-.2-.413-.4-.612-.614a.223.223 0 0 0-.284-.06 2.2 2.2 0 0 1-2.873-2.856.253.253 0 0 0-.077-.318q-1.57-1.561-3.134-3.129a.7.7 0 0 1-.2-.727.659.659 0 0 1 .52-.486.736.736 0 0 1 .714.253c1.02 1.024 2.044 2.043 3.062 3.068a.308.308 0 0 0 .395.093 2.238 2.238 0 0 1 3.014 2.1m-1.445-.04a.8.8 0 1 0-1.6.019.8.8 0 0 0 1.6-.019"
      />
      <path
        data-name="Path 9107"
        d="M12.021 17.324c.749 0 1.5-.01 2.248 0a.689.689 0 0 1 .6 1.04.677.677 0 0 1-.617.359q-2.248.006-4.5 0a.7.7 0 1 1 .021-1.4c.749-.005 1.5 0 2.248 0Z"
      />
    </g>
  </svg>
)
