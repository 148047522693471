import React from "react";
import { withTranslation } from "react-i18next";
import { ContractDetails } from "./contractDetails";

class ContractDetailsParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return <ContractDetails {...this?.props} />;
    }
}

export default withTranslation('contract')(ContractDetailsParent);