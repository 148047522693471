import { Bold, Regular } from "../../utils";


export const VacantUnit_style = {

    root:{
    backgroundColor: 'background.main',
    overflow: 'auto',
    height: '100%',
    position:"relative",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    },

    img:{
         opacity:1,
         position:'relative'
    },
    sx:{
        backgroundColor: 'white',
        // position: 'absolute',
        top: '236px',
        width: '94%',
        left: '0',
        padding: '0px',
        right: '0',
        margin: 'auto',
        borderRadius: '12px',
    },
    correspndenceCard:{
        padding: '16.5px 12.5px',
        backgroundColor: 'primary.contrastText',
        boxShadow: '0px 3px 30px #5C86CB2E',
        borderRadius: '12px',
        opacity: 1,
        display:'flex',
        justifyContent : "space-evenly",
        // marginTop:'94%',
        // marginTop:{
        //     sm:"53.4%",
        //     xs:"55%"
        // }
    },
    amenitiesCard:{
        padding: '16.5 12.5',
        backgroundColor: 'primary.contrastText',
        boxShadow: '0px 3px 30px #5C86CB2E',
        borderRadius: '12px',
        opacity: 1,
    },
    endImg:{
        display:'flex',
        flexDirection:'column',
        jutifyContent:'center',
        alignItems:'center',
        marginTop:'40px'
    },
    vacanttext:{
        fontFamily:Regular,
        fontSize: "1rem",
        color:'text.secondary',
        padding:'14px'
    },
    rootMain:{
        position:"absolute",
        top:"235px",
        width:"100%"
    },
    noneStyle:{
        display:"none",
        width:"100%"
    },
    main: {
        padding: "16px 16px 0px",
    },
    favTitle: {
        textAlign: "-webkit-match-parent",
        fontFamily: Bold,
        fontSize: "0.875rem",
        color: "border.primary",
        opacity: 1,
    },
    lanlordParent: {
        display: "flex",
        alignItems: "center",
        overflowY: "none",
        overflowX: "auto",
        gap: "12px",
        borderRadius: "9px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
}