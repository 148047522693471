import { Regular } from "../../utils";

export const ParkingSlot_style = {
  Topnav: {
    position: 'fixed',
    top: 0,
    zIndex: 999,
    left:0,
    right:0
  },
  container: {
    backgroundColor: 'background.Teritary',
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    height: '100vh'
  },
  searchSection: {
    "& .MuiInputBase-root": {
      padding: 0,
      "& fieldset": {
        height: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderColor: "",
      },
      "&.Mui-focused fieldset": {
        borderColor: "red",
      },
    },
    "& .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input": {
      paddingLeft: "18px",
      fontFamily: Regular,
      fontSize: "0.875rem",
    },
    "& .MuiOutlinedInput-root": {
      height: "30px",
    },
    "& .makeStyles-textbox-20 .MuiOutlinedInput-root": {
      backgroundColor: 'primary?.contrastText',
    },
    "& .makeStyles-textbox-20 .MuiOutlinedInput-input": {
      height: "12px",
      backgroundColor: 'primary?.contrastText',
    },
    "& .makeStyles-textbox-36 .MuiOutlinedInput-input": {
      height: "12px",
    }
  },
  sx: {
    padding: '16px'
  }
}