import { AppartmentCard_Style } from "./style";
import { Box, Typography } from "@mui/material";
import { Buttons } from "../button";
import moment from "moment-timezone";
import { CurrentAgreementCard } from "../../components"
import React from "react";

export const AppartmentCard = ({
  plotname = "",
  name = "",
  id = "",
  address = "",
  image = "",
  t = () => false,
  handleClick = () => null,
  status = "",
  details = {},
  lease_start_date,
  lease_end_date,
  agreement
}) => {

  return (
    <>
      {agreement ?
        <Box sx={AppartmentCard_Style?.sx}>
          <Box sx={AppartmentCard_Style?.topLine}>
            <Typography sx={AppartmentCard_Style?.title}>{name ?? name}</Typography>
            <Box sx={AppartmentCard_Style?.idBg}>
              <Typography sx={AppartmentCard_Style?.id}>{id ?? id}</Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={AppartmentCard_Style?.bottomLine}>
              <Box>{image}</Box>
              <Typography sx={AppartmentCard_Style?.plotName} noWrap>
                {plotname ?? plotname}
              </Typography>
              <Box sx={AppartmentCard_Style?.dot}></Box>
              <Typography sx={AppartmentCard_Style?.address} noWrap>
                {address ?? address}
              </Typography>
            </Box>
          </Box>
          <Box sx={AppartmentCard_Style?.btn} onClick={handleClick}>
            <Buttons buttonStyle={AppartmentCard_Style.viewBtn} btnName={t("View Assets")} />
          </Box>
          <CurrentAgreementCard
            t={t}
            details={details}
            date={[
              {
                title: details?.agreement?.[0]?.unit_type === "Lease" ? t("Start Date") : t("Contract Date"),
                date: !!lease_start_date ? moment(lease_start_date).format(
                  "DD-MM-yyyy"
                ) : "-",
                padding: "16px 16px 16px 0px",
                is_active:details?.agreement?.[0]?.unit_type === "Lease" ? false : true
              },
              {
                title: details?.agreement?.[0]?.unit_type === "Lease" ? t("End Date") : t("Handover Date"),
                date: !!lease_end_date ? moment(lease_end_date).format(
                  "DD-MM-yyyy"
                ) : "",
                padding: "16px 16px 16px 0px"
              },
            ]}
          />
        </Box>
        :
        <Box sx={AppartmentCard_Style?.sx}>
          <Box sx={AppartmentCard_Style?.topLine}>
            <Typography sx={AppartmentCard_Style?.title}>{name ?? name}</Typography>
            <Box sx={AppartmentCard_Style?.idBg}>
              <Typography sx={AppartmentCard_Style?.id}>{id}</Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={AppartmentCard_Style?.bottomLine}>
              <Box>{image}</Box>
              <Typography sx={AppartmentCard_Style?.plotName} noWrap>
                {plotname}
              </Typography>
              <Box sx={AppartmentCard_Style?.dot}></Box>
              <Typography sx={AppartmentCard_Style?.address} noWrap>
                {address}
              </Typography>
            </Box>
          </Box>
          <Box sx={AppartmentCard_Style?.btn} onClick={handleClick}>
            <Buttons buttonStyle={AppartmentCard_Style.viewBtn} btnName={t("View Assets")} />
          </Box>
        </Box>
      }

    </>
  );
};
