import { Box, Container } from "@mui/material"
import { TopNavBar } from "../../components"
import { Spending } from "../statistics/spending"
import { Spendings_style } from './style'
export const SpendingsScreen = ({ t = () => false }) => {
    return (
        <Box sx={Spendings_style?.parentBox}>
            <Container sx={Spendings_style?.container} maxWidth="sm">
                <Box sx={Spendings_style?.Topnav}>
                    <TopNavBar title={t("Spendings")} isDirect isBack t={t} position={'absolute'} />
                </Box>
                <Box sx={Spendings_style?.padding}>
                    <Spending t={t} bottomSx={{ bottom: "24px" }} />
                </Box>
            </Container>
        </Box>
    )
} 