import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: config.api_url,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem(LocalStorageKeys.authToken);
    let client = jwt_decode(token)?.selected_client
    let slug = client?.tenants?.slug
    if (token) {
      headers.set("Content-Type", `application/json`);
      headers.set("Authorization", "Bearer " + token);
      headers.set("Slug", slug);
      headers.set("x-build-code", config?.xbuildCode);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const announcementAPI = createApi({
  reducerPath: "announcementAPI",
  baseQuery: baseQueryWithRetry,
  tagTypes: ["Announcement"],
  endpoints: (builder) => ({

    
    // Get Announcement Listing API
    getAnnouncementListing: builder.query(
      {
        query: (payload) => {
          return {
            url: `notifications/list`, 
            method: "POST", 
            body: payload
          }
        },

        transformResponse: (response, meta, arg) => {
          if (response.type === "success") {
            return { data: response.data, totalCount: response.totalRecords };
          }
          return { data: [], totalCount: 0 };
        },
      }),

      // Get Announcement View API
    getAnnouncementView: builder.query(
      {
        query: (payload) => {
          return {
            url: `notifications/view`, 
            method: "POST",
            body: payload
          }
        },

        transformResponse: (response, meta, arg) => {
          if (response.type === "success") {
            return { data: response.data, totalCount: response.totalRecords };
          }
          return { data: [], totalCount: 0 };
        },
      }),

    


  }),
});

// Export hooks for usage in functional components
export const {
  useLazyGetAnnouncementListingQuery,
  useLazyGetAnnouncementViewQuery,
  
  
} = announcementAPI;
