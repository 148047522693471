import { Bold, Regular, SemiBold } from "../../utils";

export const landlordApprovalStyle = {
    landlordBox: {
        backgroundColor: "#FFFFFF",
        padding: "16px",
        borderRadius: "12px",
    },
    image: {
        height: "48px",
        width: "48px",
        borderRadius: "6px"
    },
    waiting: {
        color: "#78B1FE",
        padding: "4px 8px",
        backgroundColor: "#f1f7ff",
        fontSize: "0.5rem",
        fontFamily: Bold,
        display: "inline-block",
        height: "19px",
        borderRadius: "11px"
    },
    approved: {
        color: "#5AC782",
        padding: "4px 8px",
        backgroundColor: "#EEF9EE",
        fontSize: "0.5rem",
        fontFamily: Bold,
        display: "inline-block",
        height: "19px",
        borderRadius: "11px"
    },
    declined: {
        color: "#FF4B4B",
        padding: "4px 8px",
        backgroundColor: "#FFECEC",
        fontSize: "0.5rem",
        fontFamily: Bold,
        display: "inline-block",
        height: "19px",
        borderRadius: "11px"
    },
    approval: {
        marginTop: "16px",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        color: "text.secondary"
    },
    unitName: {
        marginTop: "5px",
        fontFamily: Bold,
        fontSize: "1.125rem",
        color: "text.primary",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    address: {
        marginTop: "6px",
        fontFamily: Regular,
        fontSize: "0.75rem",
        color: "text.secondary",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    button: {
        marginTop: "12px",
        padding: "12px 79px",
        border: "1px solid #F15A29",
        fontSize: "0.75rem",
        color: "#F15A29",
        borderRadius: "8px",
        fontFamily: Bold
    },
    approvalDate: {
        color: "#5AC782",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        marginTop: "10px"
    },
    declinedDate: {
        color: "#FF4B4B",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        marginTop: "10px",
        marginBottom: "8px"
    },
    remarksBox: {
        padding: "12px",
        backgroundColor: "#F5F7FA",
        borderRadius: "4px"
    },
    remarksText: {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: Bold
    },
    remarksSubtext: {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: Regular
    }
}