import * as React from "react"
const GreyUnit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40.5}
    height={41.553}
    {...props}
  >
    <path
      fill="#98a0ac"
      stroke="#98a0ac"
      strokeWidth={0.5}
      d="M22.355.25a1.576 1.576 0 0 0-.966.331L7.522 11.315a7.949 7.949 0 0 0-3.061 6.244v3.5l3.158-2.5v-1a4.767 4.767 0 0 1 1.831-3.746l12.9-9.988 12.9 9.988a4.769 4.769 0 0 1 1.836 3.746v20.586H24.461a4.61 4.61 0 0 1-1.053 3.158h14.21a2.635 2.635 0 0 0 2.632-2.632V17.559a7.949 7.949 0 0 0-3.061-6.244L23.322.581a1.576 1.576 0 0 0-.967-.331Zm-11.63 19.426a1.579 1.579 0 0 0-.925.339l-7.745 6.1A4.743 4.743 0 0 0 .25 29.837v8.834a2.655 2.655 0 0 0 2.632 2.632h15.789a2.655 2.655 0 0 0 2.632-2.632v-8.834a4.737 4.737 0 0 0-1.805-3.721l-7.745-6.1a1.579 1.579 0 0 0-1.028-.339Zm.051 3.59 6.768 5.331a1.575 1.575 0 0 1 .6 1.24v8.308H3.408v-8.308a1.574 1.574 0 0 1 .6-1.24Z"
      data-name="icons8-real-estate (4)"
    />
  </svg>
)
export default GreyUnit
