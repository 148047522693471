import { LoderSimmer } from "../../../components"
import { Stack, Typography } from "@mui/material"

export const ProfileNamesimmer = () => {
    return (
        <Stack>
            <Typography>
                <LoderSimmer card count={1} width={140} size={10} variant={"rounded"} animation={"wave"} />
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography>
                    <LoderSimmer card count={1} width={80} size={10} variant={"rounded"} animation={"wave"} />
                </Typography>
                <Typography >
                    <LoderSimmer card count={1} width={80} size={10} variant={"rounded"} animation={"wave"} />
                </Typography>
            </Stack>
        </Stack>
    )
}