import { Container, Box, Typography } from "@mui/material"
import { Greentick, Report } from "../../assets"
import { DialogDrawer, DocumentViewer, LoderSimmer, TopNavBar, UnitCard } from "../../components"
import { InspectionDetails_style } from "./style"
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { InspectorProfie } from "../../components/inspectorProfile";
import { useLocation } from "react-router-dom";
import { useLazyInspectionPdfQuery, useLazyMoveInQuery } from "../../redux/services";
import { useLayoutEffect, useState } from "react";
import moment from "moment-timezone";
import React from "react";
import { InspectDetailSimmer, InspectProfileSimmer } from "./componentSimmer";
import NoData from "../../components/noData";
import { errorRoute } from "../../utils";
export const InspectionDetails = ({ t = () => false }) => {
    const [getDetail, { error }] = useLazyMoveInQuery()
    const [InspectionPdf , {error:isError}] = useLazyInspectionPdfQuery()
    const language = localStorage.getItem("i18nextLng")
    const [details, setDetails] = useState({
        listingDatas: [],
        bool: true
    })

    const location = useLocation()
    const datas = location?.state

    const getPreviewDetails = async (payload) => {
        const detailList = await getDetail(payload)
        setDetails({
            ...details,
            listingDatas: detailList?.data?.data,
            bool: false
        })
    }

    const [pdf , setPdf]=React.useState({
        pdf:"",
        bool:false
    })
    const filterdraweropen = async () => {
        await setPdf({
            ...pdf, bool: true  , open:true
        });
        const pdfFile = await InspectionPdf({
            "agreement_inspection_id": datas?.id
        })
        if(pdfFile?.error?.originalStatus === 200){
            await setPdf({
                ...pdf, bool: false ,pdf:pdfFile?.error?.data , open:true
            });
        }
        else{
            await setPdf({
                ...pdf, bool: false ,pdf:""
            });
        }
    };
    const filterdrawerclose = () => {
        setPdf({
            ...pdf, open: false
        });
    };


    useLayoutEffect(() => {
        getPreviewDetails({
            agreement_id: datas?.agreement_id,
            agreement_inspection_id: datas?.id,
            resource_id: datas?.resource_id,
            unit_id: datas?.unit_id
        })
        // eslint-disable-next-line
    }, [location?.state])


    React.useEffect(() => {
        if (error?.status === 423 || isError?.status === 423) return errorRoute()
    }, [error, isError])


    return (
        <Box sx={InspectionDetails_style?.parentBox}>
            <Container padding={0} sx={InspectionDetails_style?.container} maxWidth="sm">
                <Box sx={InspectionDetails_style?.Topnav}>
                    <TopNavBar title={datas?.unit_name} isDirect isBack t={t} />
                </Box>
                <Box>
                    <Box sx={InspectionDetails_style?.sx}>
                        {details.bool ?
                            <InspectDetailSimmer />
                            :
                            <Box sx={InspectionDetails_style?.card}>
                                <Box sx={InspectionDetails_style?.topcard}>
                                    <Box>
                                        <Typography sx={InspectionDetails_style?.complete}>{t('Inspection Completed')}</Typography>
                                        <Typography sx={InspectionDetails_style?.date}>{t("on ")}{datas?.execution ? moment(datas?.execution).format(
                                            "DD MMM YY, h:mm") : "-"}</Typography>
                                    </Box>
                                    <Greentick />
                                </Box>
                                <Box sx={{ paddingBottom: '16px' }} />
                                <Box sx={InspectionDetails_style?.bg} onClick={filterdraweropen}>
                                    <Box sx={InspectionDetails_style?.flex}>
                                        <Report />
                                        <Typography sx={InspectionDetails_style?.report}>{t("Inspection Report")}</Typography>
                                    </Box>
                                    {language === "ar" ?
                                        <ChevronLeftIcon sx={{ color: 'primary.main' }} /> :
                                        <ChevronRightIcon sx={{ color: 'primary.main' }} />
                                    }
                                </Box>
                                <Box sx={InspectionDetails_style?.divider}></Box>
                                <Box sx={{ paddingTop: '16px' }}>
                                    <UnitCard title={details?.listingDatas?.property_unit_details?.unit_name} address={details?.listingDatas?.property_unit_details?.property_name} status={datas?.request_type} logo={details?.listingDatas?.property_unit_details?.logo ? details?.listingDatas?.property_unit_details?.logo : "/images/inspectionOrder.svg"}/>
                                </Box>
                            </Box>
                        }
                        <Box sx={{ padding: '8px' }} />
                        {details.bool ?
                            <InspectProfileSimmer />
                            :
                            <>
                                {details?.listingDatas?.resource?.[0]?.first_name.length > 0 ?
                                    <Box sx={InspectionDetails_style?.card}>
                                        <Typography sx={InspectionDetails_style?.inspectortitle}>{t('INSPECTOR DETAILS')}</Typography>
                                        <InspectorProfie
                                        logo={details?.listingDatas?.resource?.[0]?.image_url}
                                            name={details?.listingDatas?.resource?.[0]?.first_name}
                                            id={details?.listingDatas?.resource?.[0]?.email_id}
                                            t={t}
                                            checkout={details?.listingDatas?.track?.[0]?.end_date_time}
                                            checkin={details?.listingDatas?.track?.[0]?.pause_date_time}
                                        />
                                    </Box>
                                    :
                                    <></>
                                }
                            </>
                        }
                    </Box>

                </Box>
                <DialogDrawer
                    maxWidth="sm"
                    open={pdf?.open}
                    header={t("Inspection Report")}
                    padding="0px"
                    height_style={
                        { height: "500px !important" }
                    }
                    header_style={{
                        padding:"8px !important"
                    }}
                    footer_style={{
                        padding:"8px !important"
                    }}
                    component={
                        <Box sx={{ padding: '16px' }}>
                            {pdf?.bool ? <>   <>
                                        <LoderSimmer card count={1} size={500} variant={"rectangular"} animation={"wave"} />
                                    </></> : pdf?.pdf ? <DocumentViewer url={`data:application/pdf;base64,${pdf?.pdf}`} /> : <Box style={{ marginTop: '30px' }}><NoData /></Box>}
                        </Box>
                    }
                    onClose={() => filterdrawerclose()}
                />

            </Container>
        </Box>

    )
}