// import { SemiBold } from "../../utils";

import { SemiBold } from "../../utils";



export const ProfileListStyles = {
    boxStyles:{
        backgroundColor: 'primary.contrastText',
        padding:"20px",
        borderRadius:"12px",
        cursor:"pointer"
    },
    arrowIcon:{
        color:"text.secondary",
        fontSize: "1rem"
    },
    avatarIcon:{
        width:"16px",
        height:"16px",
    },
    text:{
        color:"text.main",
        fontSize: "0.875rem",
        fontFamily:SemiBold,
        marginInlineStart: "16px",
        textTransform:"capitalize"
    }





};
