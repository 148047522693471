import { Bold, FontSizeCalc, Regular } from "../../../utils";

export const useStyles = {
  root: {
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    padding: "12px 16px",
    cursor: "pointer"
  },
  surveyIcon: {
    width: "48px",
    height: "48px",
  },
  title: {
    fontSize: FontSizeCalc(14),
    fontFamily: Bold,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#091B29"
  },
  openStatus: {
    borderRadius: "4px",
    padding: "3px 6px",
    fontSize: FontSizeCalc(12),
    fontFamily: Bold,
    textTransform: "uppercase",
    color: "#5AC782",
    backgroundColor: "#EEF9EE"
  },
  submittedStatus: {
    borderRadius: "4px",
    padding: "3px 6px",
    fontSize: FontSizeCalc(12),
    fontFamily: Bold,
    textTransform: "uppercase",
    color: "#5D427F",
    backgroundColor: "#F1E6FE"
  },
  dot: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
  },
  form_no: {
    fontSize: FontSizeCalc(12),
    fontFamily: Regular,
    color: "#4E5A6B"
  },
  calendarIcon: {
    width: "14px",
    height: "14px",
  },
  publishedDate: {
    fontSize: FontSizeCalc(12),
    fontFamily: Regular,
    color: "#4E5A6B"
  },
}