import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { BarChart_Style } from './style';
import { useMediaQuery } from "@mui/material";
import NoData from '../noData/index'
import { Box } from "@mui/material";
import { ToolTipCustom } from '../customTooltip';

export const BarCharts = ({
    data = []
}) => {
    const mediaQuery = useMediaQuery('(max-width:522px)')
    return (
        <Box width={'100%'} height={'100%'} >
            {
                data?.some((x) => x?.value > 0) ?
                    <ResponsiveContainer width={'100%'} height={mediaQuery ? 280 : 300}   >
                        <BarChart data={data ?? []} margin={{ top: 0, right: 5, left: 0, bottom: 0 }}>
                            
                            <CartesianGrid strokeDasharray="0" horizontal={true} vertical={false} />
                            <XAxis dataKey="name" />
                            <YAxis allowDecimals={true} axisLine={false} dataKey="value" tickLine={false} type="number" />
                            <Tooltip content={<ToolTipCustom data={data} />} cursor={{ fill: "transparent" }} />
                            <Bar sx={BarChart_Style?.root} dataKey="value" fill="url(#colorUv)" radius={[25, 25, 0, 0]} isAnimationActive={false} />
                        </BarChart>
                    </ResponsiveContainer>
                    : <NoData chart={true} height={mediaQuery ? 250 : 280} width={200} style={{ backgroundColor: "#fff", }} />
            }

        </Box>
    );
};