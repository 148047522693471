import React from "react";
import { withTranslation } from "react-i18next";
import { Login } from "./login";
class LoginParent extends React.Component {

//     constructor(props) {
//     super(props);
//     this.buttonPress = this.buttonPress.bind(this);
//   }
    // componentDidMount() {
    //     if (localStorage.getItem(LocalStorageKeys.authToken)) {
    //         this.props.history.push(LoginSuccess(JSON.parse(localStorage.getItem(LocalStorageKeys.authToken))))

    //     }
    // }
  
//      buttonPress() {
//     console.log('called');
//     this.props.navigation.navigate(LoginSuccess(JSON.parse(localStorage.getItem(LocalStorageKeys.authToken))));
//     }

//     componentDidMount() {
         
//         if (localStorage.getItem(LocalStorageKeys.authToken)) {
//                 this.buttonPress()
//         }
//     }

        
    render() {
        return <Login {...this?.props} />;
    }
}

export default withTranslation('login')(LoginParent);