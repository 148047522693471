import * as React from "react"

export const UploadImg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <path
      data-name="icons8-upload (1)"
      d="M28.953 20.93a1.028 1.028 0 0 0-1.047 1.047v5.93H2.093v-5.93a1.047 1.047 0 1 0-2.093 0v6.977A1.028 1.028 0 0 0 1.047 30h27.906A1.028 1.028 0 0 0 30 28.953v-6.976a1.028 1.028 0 0 0-1.047-1.047ZM15.733.314a1.011 1.011 0 0 0-1.465 0L9.035 5.547A1.036 1.036 0 1 0 10.5 7.012l3.453-3.453v14.93a1.047 1.047 0 0 0 2.093 0V3.558L19.5 7.012a1.012 1.012 0 0 0 1.465 0 1.011 1.011 0 0 0 0-1.465Z"
      fill="#F15A29"
    />
  </svg>
)
