import { TextField, Typography } from "@mui/material";
import React from "react";
import { Regular } from "../../utils";
import { Label } from "../label";
import { useStyles } from "./style";

export const TextBox = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <>
        {props?.label && <Label labelStyle={props?.labelStyle} label={props?.label} isRequired={props?.isRequired} />}
       
        <div className={classes.text}>
          <TextField
            className={classes.textbox}
            type={props.type}
            onKeyPress={(e) => {
              if (props?.type === "number") {
                if (e.key === "e") {
                  e.preventDefault();
                }
              }
            }}
            id={props?.id}
            placeholder={props?.placeholder}
            variant={props?.variant ?? "outlined"}
            fullWidth
            InputLabelProps={{
              shrink: false,
            }}
            inputProps={{
              readOnly: props?.isReadonly ?? false,
            }}
            InputProps={{
              endAdornment: props?.endAdornment,
              startAdornment: props?.startAdornment,
              style: {
                fontSize: props?.fontSize ?? "0.875rem",
                padding: props?.multiline ? 0 : "none",
              },

            }}
            disabled={props?.isReadonly ?? false}
            size="small"
            multiline={props?.multiline}
            rows={props?.rowheight ?? 3}
            autoFocus={false}
            rowsmax={10}
            onChange={props?.onChange}
            value={props?.value ?? ""}
            error={props?.error}
            helperText={props?.helperText}
            onBlur={props?.onBlur}
            onWheel={(e) => e.target.blur()}
            sx={props?.sx}
          />
        </div>
      </>
      {props?.isError && (
        <Typography variant={"caption"} color={"error"} fontFamily={Regular}>
          {props?.errorMessage}
        </Typography>
      )}
    </div>
  );
};

