import { Bold, Regular } from "../../utils";


export const UtilityCardstyle = {

    status: {
        color: "text.badgeText1",
        fontSize: "0.75rem",
        fontFamily: Bold,
        padding: "6px 12px",
        backgroundColor: "background.badge",
        borderRadius: "4px",
    },
    overDueStatus: {
        color: "error.Teritary",
        fontSize: "0.75rem",
        fontFamily: Bold,
        padding: "6px 12px",
        backgroundColor: "background.background",
        borderRadius: "4px",
    },
    title: {
        color: "text.main",
        fontSize: "0.875rem",
        fontFamily: Bold,
        marginBottom: "6px"
    },
    subTitle: {
        color: "text.secondary",
        fontSize: "0.75rem",
        fontFamily: Regular
    },
    divider: {
        height: "6px",
        borderBottom: "1px solid #E4E8EE",
        
    },
    root: {
        backgroundColor:"primary.contrastText",
        padding:"12px 0px",
        cursor:"pointer"
    }


}