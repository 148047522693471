import { Box, Divider, Grid, Stack, Typography } from "@mui/material"
import { TransparentBuilding } from "../../assets"
import { InspectionCard_style } from "./style"
export const InspectionCard = ({
    t = () => false,
    name = "",
    plotname = "",
    title = "",
    status = "",
    date = "",
    onClick = () => false,
    hidden = false,
    logo=""
}) => {
    return (
        <Box sx={InspectionCard_style.background}>
            <Box sx={InspectionCard_style.sx} onClick={onClick}>
                <Grid container spacing={1}>
                    <Grid item xs={3} sm={1.8}><Box sx={InspectionCard_style.photoSec} style={{background:`url(${logo})`}}></Box></Grid>
                    <Grid item xs={9} sm={10}>
                        <Box sx={InspectionCard_style?.topSide}>
                            <Box sx={InspectionCard_style?.topLine}>
                                <Typography sx={InspectionCard_style?.title} noWrap>{title}</Typography>
                                <Box sx={InspectionCard_style?.bg}>
                                    <Typography sx={InspectionCard_style?.status}>{status}</Typography>
                                </Box>
                            </Box>
                            <Typography sx={InspectionCard_style?.plotname}>
                                {plotname}
                            </Typography>
                            <Stack divider={<Divider sx={{ marginInline: "8px" }} orientation="vertical" />} alignItems={"center"} direction={"row"}>
                                <Stack direction={"row"}>
                                    <img src="/images/calender.png" alt="calander" />
                                    <Typography sx={InspectionCard_style?.date} noWrap> &#x202a;{date}&#x202c;</Typography>
                                </Stack>
                                <Stack direction={"row"}>
                                    <TransparentBuilding />
                                    <Typography sx={InspectionCard_style?.name} noWrap> {name}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>


            </Box>
            {
                hidden && <Box sx={InspectionCard_style?.divider}></Box>
            }
        </Box>
    )
}