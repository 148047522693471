import * as React from "react"

export const GreenCheck = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
    <path
      data-name="icons8-ok 1"
      d="M6 0a6 6 0 1 0 6 6 6.007 6.007 0 0 0-6-6Zm2.568 4.968-3 3a.45.45 0 0 1-.637 0l-1.5-1.5a.45.45 0 0 1 .636-.636L5.25 7.014l2.682-2.682a.45.45 0 0 1 .637.636Z"
      fill="#5ac782"
    />
  </svg>
)
