import { Bold } from "../../utils";
export const Buttons_Style = {
  title: {
    textAlign: "left",
    fontFamily: Bold,
    color: 'primary.contrastText',
    fontSize: "0.875rem",
    textTransform:"capitalize",
  },
  rootSx: {
    borderRadius: '8px',
    backgroundColor:"primary.main",
    '&.MuiLoadingButton-loading': {
      backgroundColor: 'primary.main',
    },
    "&:hover": {
      backgroundColor: 'primary.main',
    },
    padding: "16px 0px",
        '& p':{
      fontFamily: Bold,
      color: 'primary.contrastText',
      fontSize: "0.75rem",
    },
  },
}