import React from "react";
import { GetSupportDetailed } from "./getSupportDetailed";
import { withTranslation } from 'react-i18next';

class GetSupportDetailedParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <GetSupportDetailed {...this?.props} />;
  }
}

export default withTranslation('getSupport')(GetSupportDetailedParent)
