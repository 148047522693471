import React from 'react';
import FileViewer from 'react-file-viewer';

export const FileViewers = ({
    fileType,
    filePath,
    height
}) => {

    return (
        
        <FileViewer
            fileType={fileType ?? "pdf"}
            filePath={filePath}            
        />
        

    )
}