import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import { DatePickerNew, FormGenerator } from "../../../components";
import { useLazyGetAllLocationsQuery, useLazyGetRequestUnitQuery, useLazyGetSlotMasterQuery, useLazyMaintainanceCategoryMasterQuery, useLazyMaintainanceSubCategoryMasterQuery } from "../../../redux/services";
import { MaintanceRequestStyle } from '../style';
import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import { SemiBold } from "../../../utils";
export const Step1 = (props) => {
    const { data, updateState, t = () => false } = props;

    const [loading1, setLoading1] = React.useState(null);
    const [CategoryAPI] = useLazyMaintainanceCategoryMasterQuery()
    const [SubCategory] = useLazyMaintainanceSubCategoryMasterQuery()
    const [GetOwnerUnit] = useLazyGetRequestUnitQuery()
    const [getLocation] = useLazyGetAllLocationsQuery();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar()
    const [slotsData, setSlotsData] = React.useState([])
    const [GetSlotMasterAPI] = useLazyGetSlotMasterQuery();
  const TimeIn24HrFormat = [null, null, { start: "08", end: "10" }, { start: "10", end: "12" }, { start: "12", end: "14" }, { start: "14", end: "16" }, { start: "16", end: "18" }, { start: "18", end: "20" }]
    const loadOptions = async (search = "", array, type) => {
        setLoading1(type);
        let result,
            offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        } else {
            offset = array?.length;
        }
        switch (type) {
            case "category":
                result = await CategoryAPI({
                    offset,
                    limit: 10,
                    search
                })
                setLoading1(null);
                return {
                    options: [...result?.data?.data],
                    hasMore:
                        array?.length + result?.data?.data?.length <
                        result?.data?.count,
                };
            case "sub-category":
                if (!data?.category?.label?.length > 0) {
                    setLoading1(null);
                    return {
                        options: [],
                    };
                }
                result = await SubCategory({
                    offset,
                    limit: 10,
                    search,
                    category_id: data?.category?.value ? data?.category?.value : null
                })
                setLoading1(null);
                return {
                    options: [...result?.data?.data],
                    hasMore:
                        array?.length + result?.data?.data?.length <
                        result?.data?.count,
                };

            case "get-owner-unit":
                result = await GetOwnerUnit({
                    offset,
                    limit: 10, search
                })
                setLoading1(null);
                return {
                    options: [...result?.data?.data?.map((x) => {
                        return {
                            value: x?.unit_id,
                            label: x?.unit_name,
                            property_id: x?.property_id,
                            company_id: x?.company_id

                        }
                    })],
                    hasMore:
                        array?.length + result?.data?.data?.length <
                        result?.count,
                };
            case "location":
                result = await getLocation({
                    length: 10,
                    start: offset,
                    search
                })
                setLoading1(null);
                return {
                    options: [...result?.data?.list?.map((x) => {
                        return {
                            value: x?.value,
                            label: x?.label,

                        }
                    })],
                    hasMore:
                        array?.length + result?.data?.list?.length <
                        result?.data?.count?.[0]?.count,
                };

            default:
                return { options: [] };

        }
    };
    const getSlotMaster = async (offset = 0, limit = 10, search = "") => {
        let response = await GetSlotMasterAPI({
            offset: offset,
            limit: limit,
            search: search

        })
        const result = response?.data?.data?.map((x, i) => {
            return {
              ...x,
              time: TimeIn24HrFormat[i]
            }
          })
          setSlotsData(result)
    };
    React.useEffect(() => {
        getSlotMaster()
        //eslint-disable-next-line
    }, [])
    const handleChangeSlot = (val) => {
        if (val?.time !== null && moment(data?.preferred_Date).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) {
          const currentTime = moment(new Date())
          let fromTime = new Date(currentTime).setHours(val?.time?.start, 0, 0, 0);
          let toTime = (new Date(currentTime)).setHours(val?.time?.end, 0, 0, 0);
          if ((fromTime <= currentTime && currentTime <= toTime) || fromTime >= currentTime) {
            updateState("selectedSlot", val)
          } else {
            enqueueSnackbar(t(`Please Choose time Slot greater than current time`), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
          }
        }
        else{
            updateState("selectedSlot", val)
        }
      }
    return (
        // Creation 
        <Box>
            <FormGenerator
                components={[
                    // {
                    //     isActive: true,
                    //     component: "date",
                    //     isTime: false,
                    //     label: t("Date"),
                    //     value: data?.date,
                    //     placeholder: t("Date"),
                    //     onChange: (value) => updateState("date", value),
                    //     error: data?.error?.date,
                    //     minDate: new Date(),
                    //     isRequired: true,
                    //     size: {
                    //         xs: 12,
                    //     },
                    //     sx: {
                    //         border: "1.5px solid #e2e2e2",
                    //         borderRadius: "4px",
                    //         backgroundColor:"#fff"
                    //       }
                    // },
                    {
                        isActive: true,
                        isReadonly: location?.state?.product?.location_name?.length > 0 ? true : false,
                        component: "select",
                        label: t("Location"),
                        value: data?.location,
                        isRequired: true,
                        placeholder: t("Search Location"),
                        onChange: (value) => updateState("location", value),
                        isPaginate: true,
                        error: data?.error?.location,
                        loading: loading1 === "location",
                        size: {
                            xs: 12,
                        },
                        loadOptions: (search, array, handleLoading) => {
                            return loadOptions(search, array, "location")
                        }
                    },
                    {
                        isActive: true,
                        component: "select",
                        label: t("Category"),
                        value: data?.category,
                        placeholder: t("Category"),
                        onChange: (value) => updateState("category", value),
                        error: data?.error?.category,
                        isRequired: true,
                        loading: loading1 === "category",
                        isPaginate: true,
                        size: {
                            xs: 12,
                        },
                        loadOptions: (search, array, handleLoading) => {
                            return loadOptions(search, array, "category")
                        }

                    },
                    {
                        isActive: true,
                        component: "select",
                        label: t("Sub-Category"),
                        value: data?.sub_Category,
                        key: JSON.stringify(data?.category),
                        placeholder: t("Sub-Category"),
                        onChange: (value) => updateState("sub_Category", value),
                        error: data?.error?.sub_Category,
                        loading: loading1 === "sub-category",
                        isPaginate: true,
                        isRequired: true,
                        loadOptions: (search, array, handleLoading) => {
                            return loadOptions(search, array, "sub-category")
                        },
                        size: {
                            xs: 12,
                        },
                    },
                    {
                        isActive: true,
                        component: "text",
                        label: t("Title"),
                        isRequired: true,
                        value: data?.problem_Title,
                        placeholder: t("Enter Problem Title"),
                        onChange: (e) => updateState("problem_Title", e?.target?.value),
                        error: data?.error?.problem_Title,
                        size: {
                            xs: 12,
                        },
                    },

                    // {
                    //     isActive: true,
                    //     component: "select",
                    //     label: t("Select Unit"),
                    //     value: data?.select_Unit,
                    //     placeholder: t("Select Unit"),
                    //     onChange: (value) => updateState("select_Unit", value),
                    //     error: data?.error?.select_Unit,
                    //     isRequired: true,
                    //     isPaginate: true,
                    //     loading: loading1 === "get-owner-unit",
                    //     loadOptions: (search, array, handleLoading) => {
                    //         return loadOptions(search, array, "get-owner-unit")
                    //     },
                    //     size: {
                    //         xs: 12,
                    //     },
                    // },
                    {
                        isActive: true,
                        component: "text",
                        label: t("Problem Description"),
                        value: data?.problem_Description,
                        isRequired: false,
                        placeholder: t("Problem Description"),
                        onChange: (e) => updateState("problem_Description", e?.target?.value),
                        error: data?.error?.problem_Description,
                        multiline: true,
                        size: {
                            xs: 12,
                        },
                    },

                    {
                        isActive: true,
                        component: "date",
                        label: t("Problem Since"),
                        value: data?.problem_Since,
                        placeholder: t("Problem Since"),
                        onChange: (value) => updateState("problem_Since", value),
                        error: data?.error?.problem_Since,
                        isReadonly: false,
                        maxDate: new Date(),
                        isRequired: true,
                        size: {
                            xs: 12,
                        },
                        sx: {
                            border: "1.5px solid #e2e2e2",
                            borderRadius: "4px",
                            backgroundColor: "#fff"
                        }
                    },
                    // {
                    //     isActive: true,
                    //     component: "date",
                    //     label: t("Preferred Visit Date & Time"),
                    //     // isTime: true,
                    //     value: data?.preferred_Date,
                    //     isReadonly: false,
                    //     placeholder: t("Preferred Visit Date & Time"),
                    //     onChange: (value) => updateState("preferred_Date", value),
                    //     error: data?.error?.preferred_Date,
                    //     minDate: new Date(),
                    //     isRequired: true,
                    //     size: {
                    //         xs: 12,
                    //     },
                    //     sx: {
                    //         border: "1.5px solid #e2e2e2",
                    //         borderRadius: "4px",
                    //         backgroundColor:"#fff"
                    //       }
                    // },
                ]}

            />
            <Box mt={1}>
                <Typography sx={MaintanceRequestStyle?.preferTitle}>{t("Preferred Date and Time")}<Typography variant="caption" color={"error"}>*</Typography></Typography>
            </Box>
            <Box mt={0.5}>
                <DatePickerNew
                    isRequired={true}
                    minDate={new Date()}
                    label={t("Date")}
                    placeholder={t("Choose preferred date")}
                    value={data?.preferred_Date}
                    onChange={(value) => updateState("preferred_Date", value,"selectedSlot","")}
                    size={{
                        xs: 12,
                    }}
                    sx={{
                        border: "1.5px solid #e2e2e2",
                        borderRadius: "4px",
                        backgroundColor: "#fff"
                    }}
                />
            </Box>
            <Box mt={1}>
                <Typography sx={MaintanceRequestStyle?.preferTitle}>{t("Time Slots")}<Typography variant="caption" color="error">*</Typography></Typography>
            </Box>
            <Box mt={1}>
                <Grid container spacing={1} alignItems={"center"}>
                    {slotsData?.map((x) => {
                        return (
                            <Grid item xs={6} sm={4} md={3} lg={3} onClick={() => handleChangeSlot(x)}>
                                <Box sx={data?.selectedSlot?.value === x?.value ? MaintanceRequestStyle?.selectedSlotBox : MaintanceRequestStyle?.slotBox}>
                                    <Typography sx={MaintanceRequestStyle?.slotTime} style={{ color: data?.selectedSlot?.value === x?.value ? "#5078E1" : "#091B29" }}>{x?.label}</Typography>
                                </Box>
                            </Grid>
                        )
                    })}

                </Grid>
            </Box>
        </Box>
    );
};