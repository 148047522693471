import { Typography,Box } from "@mui/material"
import {SummaryCard_style} from "./style"

export const SummaryCard = ({
    bgcolor="",
    percentage="",
    price="",
    titlecode=""
}) => {
    return(
        <>

        <Box sx={SummaryCard_style?.sx}>
            <Box sx={SummaryCard_style?.tittlesection}>
            <Box sx={{ backgroundColor:bgcolor,height:"20px",borderRadius:"4px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Typography sx={SummaryCard_style?.percentage}>{percentage}</Typography>
            </Box>
            <Typography sx={SummaryCard_style?.titlecode}>{titlecode}</Typography>
            </Box>
            <Typography sx={SummaryCard_style?.price}>{price}</Typography>
        </Box>
        </>
    )
}