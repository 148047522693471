import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Typography, Box } from "@mui/material";
import { Bold, SemiBold } from "../../utils";
import { ToolPieTipCustom } from "../customTooltip";
import NoData from "../noData";
import { PieStyle } from "./style";

const useStyles = makeStyles((theme, props) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: (props) => props?.justifyContent ?? "center",
    alignItems: "center",
    marginTop: "-20px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center !important",
    },
    "& svg": {
      // borderRadius: "50%",
      width: "100%",
      // minWidth: (props) => props?.height ?? "165px !important",
      // height: (props) => props?.height ?? "165px !important",
      position: "relative",
      cursor: "pointer",
      [theme.breakpoints.up("sm")]: {
        width: "100% !important",
        margin: "0 auto",
      },
      "& .recharts-wrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  center: {
    textAlign: "center",
    position: "absolute",
    left: "0px",
    right: "0px",
    top: (props) => props?.centerTop ? props?.centerTop : "36%",
    zIndex: 1,
    padding: "12px",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      right: "0px",
      top: "36%",
    },
  },
  chart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: SemiBold,
  },
  count: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: Bold,
  },
  tooltip: {
    backgroundColor: "#F2F4F7",
    border: "1px solid #E4E8EE",
    display: "flex",
    alignItems: "center",
    padding: "4px 7px",
    zIndex: 999,
    borderRadius: "4px",
    position: "relative",
  },
  name: {
    color: "#7C8594",
    fontSize: "0.75rem",
    fontFamily: SemiBold,
  },
  value: {
    color: "#4E5A6B",
    fontSize: "1rem",
    fontFamily: SemiBold,
    marginRight: "8px",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginRight: "8px",
  },

  chartsm: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '322px'
    },
    [theme.breakpoints.up('sm')]: {
      height: '322px'
    },
    position: "relative"
  }
}));


export const Piechart = ({ data = "", width = 200, isTotal=false ,total=0, totalText="",isFull=false,center={} , ...props}) => {
  const classes = useStyles(props);
  return (
    <>
    {
              data?.length > 0 ? 
              <Box className={classes.chartsm}>
            <ResponsiveContainer >
          
                <PieChart
                >
                  <Tooltip content={<ToolPieTipCustom data={data}/>} cursor={{ fill: "transparent" }} />
                  <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    paddingAngle={props?.paddingAngle ?? 0}
                    // outerRadius={100}
                    innerRadius={'50%'}
                    // innerRadius={props?.radius}
                    dataKey="value"
                    isAnimationActive={false}
                  />
       
                </PieChart>
          
            </ResponsiveContainer>
            {isTotal && (
        <Box sx={{...PieStyle.center , ...center}}>
          <Typography sx={PieStyle.count}>{total ?? 0}</Typography>
          <Typography sx={PieStyle.title}>{totalText}</Typography>
        </Box>
      )}
          </Box>
                  : <NoData chart={true} height={280} width={200} style={{ backgroundColor: "#fff", }} />
    }
          
   
    </>
  );
};
