export const config = {
    api_auth_url: `${process.env.REACT_APP_AUTH_API}/auth/`,
    api_weather: `https://api.openweathermap.org/data/2.5/weather`,
    api_weather_api_key: `88e1bb8e61b0e4dd18eab9f3b2c7298a`,
    api_url: `${process.env.REACT_APP_BACKEND_API}/`,
    xbuildCode: `${process.env.REACT_APP_X_BUILD}`,
    googleMapApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
    api_auth_base_url: `${process.env.REACT_APP_AUTH_API}/`,
    public_listing_api_url: `${process.env.REACT_APP_PUBLIC_LISTING_API}`,
    public_listing_api_key: `${process.env.REACT_APP_PUBLIC_LISTING_API_KEY}`,
    public_listing_site_url: `${process.env.REACT_APP_PUBLIC_LISTING_SITE}`
}
