import { AppRoutes } from "../router/routes";

export const Correspondance = (t, is_active) => [

    {
        title: t("Invoices"),
        image: "/images/inspection.png",
        is_image: true,
        onClick: AppRoutes?.invoices,
        is_active: is_active,
        type:"Invoices"
    },
    {
        title: t("Parking Slots"),
        image: "/images/parkingSlots.png",
        is_image: true,
        onClick: AppRoutes?.parkingSlot,
        is_active: is_active,
        type:"Parkings"


    },
    {
        title: t("Request"),
        image: "/images/request.png",
        is_image: true,
        onClick: AppRoutes?.request,
        is_active: false,
        type:"Requests"


    },
    {
        title: t("Correspondance"),
        image: "/images/correspondence.png",
        is_image: true,
        onClick: AppRoutes?.caseManagement,
        is_active: false,
        type:"Letters"

    },
    {
        title: t("Inspections"),
        image: "/images/inspection.png",
        is_image: true,
        onClick: AppRoutes?.inspection,
        is_active: false,
        type:"Inspections"

    },
    {
        title: t("Bills"),
        image: "/images/request.png",
        is_image: true,
        is_active: is_active,
        type:"Bills"

    },

];
export const Amenities = (t) => [
    {
        title: t("Amenities"),
        image: "/images/amenities.png",
        is_image: true,
        router: AppRoutes.amenities,
        isType: "amenities",
        isId: false,
    },
    {
        title: t("Utilities"),
        image: "/images/utitlitylight.png",
        is_image: true,
        router: AppRoutes.utilities,
        isType: "utilities",
        isId: false,

    },
    {
        title: t("Inventory"),
        image: "/images/iventhome.png",
        is_image: true,
        router: AppRoutes.addons,
        isId: true,
    },
    // {
    //     title: t("Contract History"),
    //     image: "/images/conractHistory.png",
    //     is_image: true,
    //     router: AppRoutes.contractHistory,
    //     isId: true,
    // },
]