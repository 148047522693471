import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material"
import moment from "moment-timezone"
import Slider from "react-slick"
import ArabicArrow from "../../../assets/arabicArrow"
import Arrow from "../../../assets/arrow"
import Edit from "../../../assets/edit"
import GreenUnit from "../../../assets/greenUnit"
import LastService from "../../../assets/lastService"
import ModelNumber from "../../../assets/modelNumber"
import ProductImage from "../../../assets/productImage"
import PurpleLocation from "../../../assets/purpleLocation"
import SerialNumber from "../../../assets/serialNumber"
import { applianceStyle } from "./style"
export const Appliance = ({
    unit = false,
    product = false,
    onClick = () => false,
    request = false,
    unit_name = "",
    unit_no = "",
    serial_number = "",
    model_number = "",
    greenText = false,
    goBack = () => false,
    last_service = "",
    condition = "",
    t = () => false,
    asset = "",
    location_name = "",
    chooseRequest = false,
    unit_type = null
}) => {

    const language = localStorage.getItem("i18nextLng");
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow:2,
        slidesToScroll: 2,
        autoplay: false,
        speed: 1000,
        // autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
    };
    return (
        <Box sx={applianceStyle.applianceBox}>
            <Box textAlign={"center"}>
                {greenText && <Typography sx={applianceStyle.greenText}>{t("For non-asset item maintenance request")}</Typography>}
            </Box>
            <Stack alignItems={"center"} sx={applianceStyle.padding} direction={"row"}>
                {unit ?
                    <GreenUnit /> : asset ? <Avatar sx={applianceStyle.img} variant={"rounded"} src={asset} /> : <ProductImage />
                }
                <Stack flexGrow={1} marginInlineStart={"12px"} direction={"column"}>
                    <Typography sx={applianceStyle.applianceText}>{unit_name}</Typography>
                    <Stack direction="row" spacing={1} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                        <Typography sx={applianceStyle.applianceSubtext}>{unit_no}</Typography>
                        {unit_type !== null &&
                            <Typography sx={applianceStyle.applianceSubtext}>{unit_type}</Typography>
                        }
                    </Stack>
                </Stack>
                {request &&
                    <Box onClick={() => goBack()}>
                        <Edit />
                    </Box>}
                {chooseRequest &&
                    <Box onClick={() => onClick()}>
                        {language === "ar" ?
                            <ArabicArrow />
                            :
                            <Arrow />
                        }
                    </Box>
                }
            </Stack>
            {product &&
                <>
                    <Divider />
                    <Box p={2}>
                    <Slider {...settings}>
                      {location_name !== null &&
                        <Grid item xs={6} sm={6} md={6}>
                          <Stack direction="row" alignItems="center">
                            <Box style={{ marginInlineEnd: "8px" }}><PurpleLocation /></Box>
                            <Box>
                              <Typography sx={applianceStyle.text} noWrap>{location_name ?? "-"}</Typography>
                              <Typography sx={applianceStyle.number} noWrap>{t("Location")}</Typography>
                            </Box>
                          </Stack>
                        </Grid>
                      }

                      <Grid item xs={6} sm={6} md={6}>
                        <Stack direction="row" alignItems="center">
                          <Box style={{ marginInlineEnd: "8px" }}><SerialNumber/></Box>
                          <Box>
                            <Typography sx={applianceStyle.text} noWrap>{serial_number ?? "-"}</Typography>
                            <Typography sx={applianceStyle.number}>{t("Serial Number")}</Typography>
                          </Box>
                        </Stack>
                      </Grid>


                      <Grid item xs={6} sm={6} md={6}>
                        <Stack direction="row" alignItems="center">
                          <Box style={{ marginInlineEnd: "8px" }}> <ModelNumber /></Box>
                          <Box>
                            <Typography sx={applianceStyle.text} noWrap>{model_number ?? "-"}</Typography>
                            <Typography sx={applianceStyle.number}>{t("Model Number")}</Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      {last_service !== null &&
                        <Grid item xs={6} sm={6} md={6}>
                          <Stack direction="row" alignItems="center">
                            <Box style={{ marginInlineEnd: "8px" }}><LastService /></Box>
                            <Box>
                              <Typography sx={applianceStyle.text} noWrap>{last_service ? moment(last_service).format("DD-MM-YY") : "-"}</Typography>
                              <Typography sx={applianceStyle.number} noWrap>{t("Last Service")}</Typography>
                            </Box>
                          </Stack>
                        </Grid>
                      }
                      {condition &&
                        <Grid item xs={6} sm={6} md={6}>
                          {/* <Box className={classes.itemBox}> */}
                            <Typography sx={applianceStyle.condition}>{condition}</Typography>
                          {/* </Box> */}
                        </Grid>
                      }
                    </Slider>
                  </Box>
                </>
            }
        </Box>
    )
}