import { Regular, SemiBold } from "../../utils";

export const AddVehicleStyle = {
    containerSm: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        position: "relative",
        height: "100%",
    },
    root: {
        overflow: "auto",
        backgroundColor: 'primary.contrastText',
        margin: "65px 0px 84px",
    },
    subRoot: {
        overflow: "auto",
        backgroundColor: 'primary.contrastText',
        height: "calc(100vh - 182px)",
        padding: "16px",
        margin: " 16px ",
        boxShadow: "rgb(92 134 203 / 18%) 0px 3px 30px"
    },
    form: {
        padding: "16px",
        backgroundColor: "#F8F8F8"
    },
    type: {
        textAlign: "center",
        fontSize: "0.875rem",
        fontFamily: SemiBold
    },
    textbox: {
        [`& fieldset`]: {
            borderRadius: "4px",
            height: (props) => (props.multiline ? "unset" : 50),
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #F15A29",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: (props) => (props.multiline ? "0px" : "11px 14px"),
        },
        ":-webkit-autofill": {
            WebkitBoxShadow: "none !important",
            backgroundColor: "red !important",
        },
    },
    input: {
        marginTop: "16px",
    },
    heading1: {
        color: "#404E61",
        fontFamily: SemiBold,
        fontSize: "1rem",
        paddingBottom: "12px"
    },
    Label1: {
        color: "primary.Tertiary",
        paddingBottom: "8px",
        fontSize: "0.75rem",
        fontFamily: SemiBold
    },
    Label2: {
        color: "primary.Tertiary",
        paddingBottom: "8px",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        marginTop: "8px"
    },
    text2: {
        color: "#404E61",
        fontFamily: SemiBold,
        fontSize: "0.875rem",
        marginInlineStart: "12px"
    },
    drap2: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        height: "70px",
        borderRadius: "4px",
        textAlign: "center",
        background: "#FFFFFF",
        border: "2px dashed #E4E8EE",
    },
    upload: {
        width: "19px",
        height: "19px"
    },
    selectVehicle: {
        display: "flex",
        backgroundColor: "primary.contrastText",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "4px",
        padding: "12px",
        width: "33.33%",
        alignItems: "center",
        cursor: "pointer",
        marginInlineStart: "8px"
    },
    vehicleTypeText: {
        textAlign: "center",
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        marginTop: "10px",
        width: "100%"
    },
    parkingSpaces: {
        display: "flex",
        columnGap: "8px",
        rowGap: "8px",
        // height: {
        //     xs: "140px",
        //     sm: "300px"
        // },
        maxHeight:"300px",
        overflow: "auto"
    },
    slot: {
        borderRadius: "4px",
        padding: "16px 10px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        minWidth: "55px",
        minHeight: "55px",
        maxHeight: "110px",
        display: "flex",
        height: "55px"
    },
    slotName: {
        textAlign: "center",
        fontSize: "0.875rem",
        fontFamily: SemiBold
    },
    noParkingSpaces: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        textAlign: "center"
    },
    imgDiv: {
        backgroundColor: "primary.contrastText",
        borderRadius: "8px",
        display: "flex",
        padding: "12px",
        height: "59px",
        width: "100%",
        boxShadow: "0px 3px 6px #00000014",
        justifyContent: "space-between",
    },
    height: {
        height: "100%"
    },
    imgText: {
        color: "text.main",
        fontSize: "0.875rem",
        fontFamily: SemiBold
    },
    imgGrid: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    required: {
        color: "red",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        marginBottom: "5px",
    },

}