import { Bold, SemiBold } from "../../utils";

export const BottomInvoice_Style ={
    price:{
      textAlign: "-webkit-match-parent",
      fontFamily: Bold,
      fontSize: "1rem",
      marginBottom:'4px'
    },
    total:{
      textAlign: "left",
      fontFamily: SemiBold,
      fontSize: "0.75rem",
      color: 'text.Teritary',
    },
    btntxt:{
        color:'primary.contrastText',
        fontFamily:Bold,
        fontSize:"0.875rem",
    },
    topline:{
        display:'flex',
        flexDirection:'column'
    },
    sx:{
        display:'flex',
        justifyContent:'space-between'
    },
    btnBg:{
        backgroundColor:'primary.main',
        borderRadius: '4px',
        padding:'12px 15px',
        cursor:'pointer'
    },

}