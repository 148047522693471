import { Bold, SemiBold } from "../../utils";



export const ProfilePictureCard = {
    title: {
        textAlign: "left",
        fontFamily: Bold,
        fontSize: "0.875rem",
        letterSpacing: "0px",
    },
    avatarOuter: {
        height: "128px",
        width: "128px",
        border: "2px solid",
        borderColor: 'primary.main',
        position: "relative"
    },
    avatarInner: {
        height: "100%",
        width: "100%",
        border: "3px solid",
        borderColor: 'primary.contrastText'
    },
    image: {
        height: "165px",
        width: "165px",

    },
    editBtn: {
        padding: "4px 7px 4px 7px",
        // marginTop: "-15px",
        // marginLeft: "45px",
        width: "auto",
        height: "24px",
        backgroundColor: "primary.main",
        borderRadius: "8px",
        border: "2px solid",
        borderColor: 'primary.contrastText',
        "&:hover": {
            backgroundColor: "primary.main",
            borderColor: 'primary.contrastText',
        },

    },
    editBtnText: {
        fontSize: "0.75rem",
        color: "primary.contrastText",
        fontFamily: SemiBold,
        textTransform: "capitalize",

    },
    broot: {
        position: "absolute !important",
        bottom: "-8px",
        left: "3px",
        width: "100%",
        textAlign: "center"
    },
    root: {
        position: "relative !important",
        display: "flex",
        justifyContent: "center"
    }
};
