import * as React from "react"

export const Calander = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <path
      data-name="icons8-schedule (1)"
      d="M5.476 0a5.476 5.476 0 1 0 5.476 5.476A5.487 5.487 0 0 0 5.476 0Zm0 1.429a4.048 4.048 0 1 1-4.047 4.047 4.037 4.037 0 0 1 4.047-4.047Zm-.011.942a.714.714 0 0 0-.7.725v2.38a.714.714 0 0 0 .209.505l.952.952a.714.714 0 1 0 1.01-1.01L6.19 5.18V3.1a.714.714 0 0 0-.725-.725Zm5.878.486a6.361 6.361 0 0 1 .447 1.429h5.11a1.668 1.668 0 0 1 1.667 1.667v.714H11.79a6.41 6.41 0 0 1-.447 1.433h7.228v8.8a1.669 1.669 0 0 1-1.671 1.671H5.952A1.668 1.668 0 0 1 4.286 16.9v-5.11a6.41 6.41 0 0 1-1.429-.447V16.9a3.1 3.1 0 0 0 3.1 3.1H16.9a3.1 3.1 0 0 0 3.1-3.1V5.952a3.1 3.1 0 0 0-3.1-3.1ZM11.429 10a1.19 1.19 0 1 0 1.19 1.19 1.19 1.19 0 0 0-1.19-1.19Zm4.048 0a1.19 1.19 0 1 0 1.19 1.19A1.19 1.19 0 0 0 15.476 10Zm-8.1 4.286a1.19 1.19 0 1 0 1.19 1.19 1.19 1.19 0 0 0-1.186-1.19Zm4.048 0a1.19 1.19 0 1 0 1.19 1.19 1.19 1.19 0 0 0-1.186-1.19Z"
      fill="#a4b2c1"
    />
  </svg>
)

