import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import moment from "moment-timezone";
import { AnnouncementCardStyles } from "./style";

export const findFileFormat = (file_url = "") => {
    const extension = file_url.split('.').pop();
    let file_formets = ["pdf", "mp4", "xlxs"];
    return file_formets.includes(extension)
}

export const AnnouncementCard = ({
    icons,
    tag,
    title,
    subTitle,
    route = () => false,
    type,
    url
}) => {
    return (
        <Box sx={AnnouncementCardStyles?.root} onClick={route}>
            <Stack direction={"row"} alignItems={"center"}>
                {
                    type === "Announcement" ?
                        findFileFormat(url) ?
                            <Box sx={AnnouncementCardStyles?.avatarIcons}>
                                <img alt="avatarImg" src={icons ?? icons}
                                    width="100%" height="100%" />
                            </Box> :
                            <Box sx={AnnouncementCardStyles?.avatarIcons}>
                                <img src="/images/announsmentnot.svg" alt="announsment" />
                            </Box>
                        :
                        typeof icons === "string" ?
                            <Box sx={AnnouncementCardStyles?.avatarIcons}>
                                <img alt="avatarImg" src={icons ?? icons}
                                    width="100%" height="100%" />
                            </Box>
                            :
                            <Box sx={AnnouncementCardStyles?.avatarIcons}>
                                <img src="/images/announsmentnot.svg" alt="announsment" />
                            </Box>
                }
                <Stack sx={{ marginInlineStart: "32px" }}>
                    <Stack><Typography sx={AnnouncementCardStyles?.tagText}>{tag}</Typography></Stack>
                    <Stack><Typography sx={AnnouncementCardStyles?.title}>{title}</Typography></Stack>
                    <Stack><Typography sx={AnnouncementCardStyles?.subTitle}>Posted at {moment(new Date(subTitle)).fromNow()}</Typography></Stack>
                </Stack>
            </Stack >
        </Box >
    );
};
