import * as React from "react"

export const InflationUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.608}
    height={8.001}
    {...props}
  >
    <path
      d="M13.897 0a.7.7 0 0 0-.1.01h-3.368a.695.695 0 1 0 0 1.39h1.8L7.305 6.324 1.195.214a.695.695 0 1 0-.983.983l6.6 6.6a.7.7 0 0 0 .983 0l5.415-5.415v1.8a.695.695 0 1 0 1.39 0V.807A.695.695 0 0 0 13.9 0Z"
      fill="#00b542"
    />
  </svg>
)

export default InflationUp
