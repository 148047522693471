import * as React from "react"

export const CarSvg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Group 96501"
        width={23}
        height={20}
        {...props}
    >
        <defs>
            <clipPath id="a">
                <path data-name="Rectangle 52993" fill="#F15A29" d="M0 0h23v20H0z" />
            </clipPath>
        </defs>
        <g data-name="Group 96500" clipPath="url(#a)" fill="#F15A29">
            <path
                data-name="Path 8994"
                d="M17.6 16.905H5.381v.4a2.693 2.693 0 0 1-5.351.438 2.319 2.319 0 0 1-.029-.4v-5.921a5.2 5.2 0 0 1 .877-2.87q.509-.785 1.015-1.571c.045-.07.086-.144.15-.252H.015V4.954h.25c.735 0 1.469-.009 2.2.006a.3.3 0 0 0 .346-.251c.4-1.152.813-2.3 1.214-3.448A.368.368 0 0 1 4.295 1 21.916 21.916 0 0 1 8.321.192 27.335 27.335 0 0 1 13.03.041a22.763 22.763 0 0 1 5.621.946.461.461 0 0 1 .329.326c.4 1.15.82 2.3 1.223 3.445a.25.25 0 0 0 .287.2c.822-.009 1.644 0 2.486 0v1.767h-2.025c.07.116.111.188.156.258.365.572.736 1.141 1.1 1.716a5.17 5.17 0 0 1 .792 2.778c.007 1.95.01 3.9 0 5.85a2.7 2.7 0 0 1-5.393.019v-.44m3.578-1.782c.006-.068.014-.114.014-.16v-3.561a3.381 3.381 0 0 0-.547-1.829c-.4-.633-.8-1.265-1.22-1.889a.394.394 0 0 0-.28-.153Q11.5 7.52 3.866 7.522a.319.319 0 0 0-.306.17q-.569.9-1.155 1.79a3.693 3.693 0 0 0-.618 2.095v3.3c0 .076.007.153.012.237h5.438v-4.39h8.536v4.4Zm-2.541-9.382c-.025-.08-.043-.147-.066-.213-.339-.948-.681-1.894-1.014-2.843a.339.339 0 0 0-.272-.25 22.646 22.646 0 0 0-3.466-.562 25.788 25.788 0 0 0-8.13.572.335.335 0 0 0-.273.248c-.328.943-.664 1.883-1 2.824-.025.07-.042.143-.066.224Zm-9.609 9.375h4.909V12.5H9.034ZM1.8 16.906c-.046.489-.024.927.442 1.2a.9.9 0 0 0 .988-.065 1.118 1.118 0 0 0 .342-1.135Zm17.627 0a1.041 1.041 0 0 0 .4 1.184.916.916 0 0 0 .992-.032 1.1 1.1 0 0 0 .356-1.152Z"
            />
            <path
                data-name="Path 8995"
                d="M18.504 10.709c.167 0 .335-.009.5 0a.884.884 0 0 1 .838.843.855.855 0 0 1-.786.905 7.382 7.382 0 0 1-1.122 0 .853.853 0 0 1-.779-.888.881.881 0 0 1 .821-.858c.174-.012.351 0 .526 0"
            />
            <path
                data-name="Path 8996"
                d="M4.495 10.708c.159 0 .319-.006.478 0a.891.891 0 0 1 .861.848.864.864 0 0 1-.794.9 7.329 7.329 0 0 1-1.121 0 .863.863 0 0 1-.779-.891.89.89 0 0 1 .83-.854c.175-.012.351 0 .526 0"
            />
        </g>
    </svg>
)
