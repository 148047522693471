import * as React from "react"
export const UnitArrowIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8.046}
    height={14.522}
    {...props}
  >
    <path
      fill="#4e5a6b"
      stroke="#4e5a6b"
      strokeWidth={0.5}
      d="M.25.773a.5.5 0 0 0 .154.349l6.141 6.14-6.141 6.142a.5.5 0 1 0 .706.706l6.494-6.494a.5.5 0 0 0 0-.706L1.11.416a.5.5 0 0 0-.86.358Z"
      data-name="icons8-expand-arrow (2)"
    />
  </svg>
)
