import * as React from "react"

export const BuildingDrawer = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 96771">
            <g data-name="Group 96770">
                <path
                    data-name="Union 14"
                    d="M14.64 19.201v-4.255h-4.8v4.255h-4.8v-6h.913V9.274L12.6 3.606l7.006 5.45v5.89h-.176v4.255Z"
                    fill="#ced3dd"
                />
                <path
                    data-name="icons8-home (7)"
                    d="M12.532 3.603a.646.646 0 0 0-.378.139L6.033 8.563a3.232 3.232 0 0 0-1.231 2.54v8.22A1.086 1.086 0 0 0 5.879 20.4h4.306a1.086 1.086 0 0 0 1.077-1.077v-4.309a.206.206 0 0 1 .215-.215h2.153a.206.206 0 0 1 .215.215v4.306a1.086 1.086 0 0 0 1.077 1.077h4.306a1.086 1.086 0 0 0 1.074-1.077v-8.217a3.232 3.232 0 0 0-1.229-2.54l-6.12-4.822a.646.646 0 0 0-.421-.138Zm.021 1.468 5.721 4.507a1.935 1.935 0 0 1 .738 1.525v8h-3.875v-4.089a1.517 1.517 0 0 0-1.507-1.511h-2.153A1.517 1.517 0 0 0 9.97 15.01v4.093H6.094v-8a1.935 1.935 0 0 1 .738-1.525Z"
                    fill="#4e5a6b"
                    stroke="#4e5a6b"
                    strokeWidth={0.25}
                />
            </g>
        </g>
    </svg>
)
