import * as React from "react"

const KycBadgeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <g data-name="Group 108361" transform="translate(-66 -146)">
      <circle
        data-name="Ellipse 129556"
        cx={8}
        cy={8}
        r={8}
        transform="translate(66 146)"
        fill="#fff"
      />
      <path
        data-name="icons8-approval (4)"
        d="m80.626 153.335-.489-.293a.935.935 0 0 1-.413-.565.117.117 0 0 0 0-.013.934.934 0 0 1 .073-.7l.277-.5a.773.773 0 0 0-.663-1.149l-.581-.017a.936.936 0 0 1-.642-.285l-.005-.005a.931.931 0 0 1-.285-.642l-.01-.576a.774.774 0 0 0-1.149-.664l-.5.277a.933.933 0 0 1-.7.073h-.013a.937.937 0 0 1-.565-.413l-.293-.489a.773.773 0 0 0-1.326 0l-.292.487a.945.945 0 0 1-.57.416h-.008a.94.94 0 0 1-.709-.074l-.5-.276a.773.773 0 0 0-1.149.663l-.01.576a.936.936 0 0 1-.285.642l-.005.005a.931.931 0 0 1-.642.285l-.576.01a.774.774 0 0 0-.663 1.149l.277.5a.933.933 0 0 1 .073.7.117.117 0 0 1 0 .013.937.937 0 0 1-.413.565l-.489.293a.773.773 0 0 0 0 1.326l.489.294a.935.935 0 0 1 .413.565.117.117 0 0 0 0 .013.933.933 0 0 1-.073.7l-.277.5a.773.773 0 0 0 .667 1.159l.576.01a.936.936 0 0 1 .642.285l.005.005a.931.931 0 0 1 .285.642l.01.576a.773.773 0 0 0 1.149.663l.5-.277a.933.933 0 0 1 .7-.073h.013a.937.937 0 0 1 .565.413l.293.489a.773.773 0 0 0 1.326 0l.293-.489a.935.935 0 0 1 .565-.413h.013a.934.934 0 0 1 .7.073l.5.277a.773.773 0 0 0 1.149-.663l.01-.576a.936.936 0 0 1 .285-.642l.005-.005a.931.931 0 0 1 .642-.285l.576-.01a.773.773 0 0 0 .663-1.149l-.277-.5a.933.933 0 0 1-.073-.7.117.117 0 0 1 0-.013.937.937 0 0 1 .413-.565l.489-.294a.773.773 0 0 0 .009-1.329Zm-3.552-.571-3.533 3.534a.538.538 0 0 1-.761 0l-1.86-1.861a.538.538 0 0 1 .761-.761l1.479 1.479 3.152-3.157a.538.538 0 1 1 .761.761Z"
        fill="#f15a29"
      />
    </g>
  </svg>
)

export default KycBadgeIcon
