import makeStyles from "@mui/styles/makeStyles";
import { Regular, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme?.palette?.text?.secondary,
    fontFamily: SemiBold,
    fontSize: (props) => props?.labelSize ?? theme?.palette?.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
  textbox: {
    fontFamily: SemiBold,
    backgroundColor: theme?.palette?.error?.contrastText,
    borderRadius: theme?.palette?.borderRadius ?? 4,
    border: "none",
    [`& fieldset`]: {
      borderRadius: theme?.palette?.borderRadius ?? 4,
      color: theme?.palette?.text?.secondary,
      height: (props) => (props.multiline ? "unset" : 50),
      border: (props)=>`${props?.border ?? '1.5px'}solid ${theme?.palette?.border?.main}`,
      "&:hover": {
        border: (props)=>`${props?.border ?? '1px'} solid ${theme?.palette?.border?.main}`,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px !important",
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: (props) => props?.paddingRight ? "28px !important" : "",
      backgroundColor: theme?.palette?.error?.contrastText,
      fontFamily: SemiBold,
      '&:hover fieldset': {
        borderColor: theme?.palette?.primary?.main,
      },

    },
    "& .css-gf036l-MuiInputBase-root-MuiInput-root:before": {
      borderBottom: "0px !important"
    },
    "& .css-odxm5i-MuiInputBase-root-MuiInput-root:before": {
      borderBottom: "0px !important"

    }
  },
  errMsg: {
    fontFamily: Regular
  }


}));