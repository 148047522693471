import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        setClient(state, { payload }) { state = payload; }
    },
})

export const { setClient } = contactSlice.actions;
export default contactSlice.reducer;