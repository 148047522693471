import { LoderSimmer } from "../../../components"
import { Box } from "@mui/material"

export const KycCardsimmer = () => {
    return(
        <Box sx={{display:'flex',alignItems:'center',paddingTop:'8px'}}>
                <Box>
                <LoderSimmer card count={1} width={42} size={42} variant={"rounded"} animation={"wave"} />
                </Box>
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',paddingLeft:'10px'}}>
                <Box>
                    <LoderSimmer card count={1} width={160} size={10} variant={"rectangular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={160} size={10} variant={"rectangular"} animation={"wave"} />
                </Box>
                <Box>
                    <LoderSimmer card count={1} width={60} size={10} variant={"rectangular"} animation={"wave"} />
                </Box>
            </Box>
        </Box>
    )
}