import { Box, Button, Container, Divider, Stack, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { DialogDrawer, DocumentViewer, LoderSimmer, TopNavBar } from "../../components"
import { PayRequestInvoiceListStyle } from "./style"
import useWindowDimensions from "../../utils/useWindowDimension"
import InfiniteScroll from "react-infinite-scroll-component"
import { useLazyGetRequestInvoiceListQuery, useLazyInvoicesPdfQuery } from "../../redux/services"
import { CheckIcon } from "./assets/checkIcon"
import { LocalStorageKeys, concat_string } from "../../utils"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import NoData from "../../components/noData";

export const PayRequestInvoiceList = ({
    t = () => false
}) => {
    const limit = 40;
    const size = useWindowDimensions();
    const currency = localStorage?.getItem(LocalStorageKeys?.currency) ? JSON?.parse(localStorage?.getItem(LocalStorageKeys?.currency)) : "";
    const classes = PayRequestInvoiceListStyle?.({ size });
    const navigate = useNavigate();
    const { state } = useLocation();
    // eslint-disable-next-line no-unused-vars
    const id = state?.id;

    const [invoice_state, set_invoice_state] = useState({
        total_invoice_count: 0,
        total_invoice_amount: 0,
        invoice_list: [],
    });
    const [offSet, set_offSet] = useState(0);
    const [pdf, setPdf] = React.useState({
        pdf: "",
        bool: false,
        open: false
    })

    const [getRequestInvoiceList] = useLazyGetRequestInvoiceListQuery();
    const [InvoicePdf] = useLazyInvoicesPdfQuery();

    useEffect(() => {
        getInvoiceDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const goBack = () => {
        navigate(-1);
    }

    const getInvoiceDetails = async (offset = offSet, is_fetch_more = false) => {
        let payload = { id, offset, limit };

        let response = await getRequestInvoiceList(payload);

        if (response?.data?.invoice?.length > 0) {
            let temp_invoice_state = {
                total_invoice_count: response?.data?.count?.[0]?.count ?? 0,
                total_invoice_amount: response?.data?.total_invoice_amount ?? 0,
            }

            if (is_fetch_more) {
                set_invoice_state({
                    ...temp_invoice_state,
                    invoice_list: [
                        ...invoice_state?.invoice_list,
                        ...response?.data?.invoice?.map?.(_ => {
                            return {
                                ..._,
                                is_checked: true,
                            }
                        })
                    ],
                })
            } else {
                set_invoice_state({
                    ...temp_invoice_state,
                    invoice_list: response?.data?.invoice?.map?.(_ => {
                        return {
                            ..._,
                            is_checked: true,
                        }
                    }),
                })
            }
        }
    }

    const handlePayNow = () => {
    }

    const handleInfiniteScrollNext = () => {
        let new_offset = offSet + limit;
        getInvoiceDetails(new_offset, true);
        set_offSet(new_offset);
    }

    const handleInvoiceCheckBox = (e, _) => {
        e.stopPropagation();
        let temp_invoice_list = invoice_state?.invoice_list?.map?.((i) => {
            return {
                ...i,
                is_checked: i?.id === _?.id
                    ? !i?.is_checked
                    : i?.is_checked,
            }
        });
        set_invoice_state({
            ...invoice_state,
            invoice_list: temp_invoice_list,
        })
    }

    const handleInvoiceCard = async (x) => {
        await setPdf({
            pdf: "",
            bool: true,
            open: true
        })
        const data = await InvoicePdf({
            invoice_id: x?.id
        })
        await setPdf({
            pdf: data?.error?.data ?? "",
            bool: false,
            open: true
        })
    }

    const handlePdfDrawerClose = () => {
        setPdf({
            ...pdf,
            open: false
        })
    }

    return (
        <Container maxWidth={"sm"} sx={classes?.root}>
            <Box sx={classes?.header}>
                <TopNavBar isDirect title={t("Invoices")} isBack={goBack} />
            </Box>
            <Box sx={classes?.body}>
                <Typography sx={classes?.list_header}>{t("SUMMARY")}</Typography>
                <Box sx={classes?.list_section}>
                    <InfiniteScroll
                        dataLength={invoice_state?.invoice_list?.length ?? null}
                        next={handleInfiniteScrollNext}
                        hasMore={true}
                        height={size?.height - 241}
                        style={classes?.infinite_scroll}>
                        <Stack direction={"column"} divider={<Divider sx={classes.invoice_list_divider} />}>
                            {
                                invoice_state?.invoice_list?.map?.((_) => {
                                    return (
                                        <Stack sx={classes?.invoice_card} direction={"row"} columnGap={"12px"}
                                            justifyContent={"space-between"} alignItems={"center"}
                                            onClick={() => handleInvoiceCard(_)}>
                                            <Stack direction={"row"} alignItems={"center"}
                                                spacing={"12px"}>
                                                <Box sx={_?.is_checked
                                                    ? classes.checked_box
                                                    : classes.unchecked_box}
                                                    onClick={(e) => handleInvoiceCheckBox(e, _)}>
                                                    {_?.is_checked && <CheckIcon />}
                                                </Box>
                                                <Stack direction={"column"} spacing={"4px"}>
                                                    {_?.invoice_total_amount?.toString() &&
                                                        <Typography sx={classes.amount_text}>
                                                            {
                                                                concat_string(
                                                                    {
                                                                        amount: _?.invoice_total_amount?.toString(),
                                                                        currency_code: currency?.code,
                                                                    },
                                                                    ["amount", "currency_code"],
                                                                    " ",
                                                                )
                                                            }
                                                        </Typography>
                                                    }
                                                    {(_?.quotaion_classification || _?.invoice_no) &&
                                                        <Stack direction={"row"} alignItems={"center"} columnGap={"2px"}
                                                            divider={<Typography sx={classes.other_details_text}>{"|"}</Typography>}>
                                                            {_?.quotaion_classification && <Typography sx={classes.other_details_text}>
                                                                {_?.quotaion_classification}
                                                            </Typography>
                                                            }
                                                            {_?.invoice_no &&
                                                                <Typography sx={classes.other_details_text}>
                                                                    {_?.invoice_no}
                                                                </Typography>
                                                            }
                                                        </Stack>
                                                    }
                                                </Stack>
                                            </Stack>
                                            <KeyboardArrowRightIcon htmlColor="#98A0AC" />
                                        </Stack>
                                    )
                                })
                            }
                        </Stack>
                    </InfiniteScroll>
                </Box>
            </Box>
            <Box sx={classes?.footer}>
                <Button fullWidth sx={classes.pay_now_button}
                    onClick={handlePayNow}>
                    {t("Pay Now")}
                </Button>
            </Box>
            <DialogDrawer
                maxWidth="sm"
                open={pdf?.open}
                onClick={handlePdfDrawerClose}
                header={t("Invoice")}
                height_style={
                    { height: "500px !important" }
                }
                header_style={{
                    padding: "8px !important"
                }}
                footer_style={{
                    padding: "8px !important"
                }}
                component={
                    <>
                        {
                            pdf?.bool ?
                                <>
                                    <LoderSimmer card count={1} size={500} variant={"rectangular"} animation={"wave"} /></>
                                :
                                !!pdf?.pdf ?
                                    <DocumentViewer url={`data:application/pdf;base64,${pdf?.pdf}`} /> : <NoData />
                        }
                    </>
                }
                onClose={() => handlePdfDrawerClose()}
            />
        </Container >
    )
}
