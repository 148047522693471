import React, { useState } from "react";
import { VacantUnit_style } from "./style";
import { Box, Container, Grid, Typography } from "@mui/material"
import { AppartmentCard, LoderSimmer } from "../../components"
import { TransparentBuilding } from "../../assets";
import { Amenities, Bold, Correspondance, errorRoute, landLord } from "../../utils";
import { FavCards, AmenitiesCard, TopNavBar } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { useEffect } from "react";
import { useLazyGetOwnerUnitQuery } from "../../redux/services";
import { useSnackbar } from 'notistack';
import useWindowDimensions from "../../useDimension";
import { LandlordApprovalRequest } from "../../components/landlordApproval";


export const VacantUnit = ({ t = () => false }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()
    let location = useLocation();

    const [getOwnerUnit , {error}] = useLazyGetOwnerUnitQuery();
    const [ownerView, setOwnerView] = useState({
        bool: true
    })
    // idChecked
    const idChecked = location?.state?.id
    // status checked
    const statusChecked = location?.state?.status
    // property name
    const propName = location?.state?.property_name
    // logo
    const logo = location?.state?.logo
    // location
    const unitLocation = location?.state?.area
    // routes to assests
    const handleAmenitiesRoute = (x, key) => {
        if(x?.isType === 'amenities') return navigate(x?.router, { state: ownerView?.agreement?.[0]?.property_id })
        if(x?.isType === 'utilities') return navigate(x?.router, { state: location?.state?.id })


        if (key === false) {
            navigate(x?.router, { state: idChecked })
        } else {
            navigate(x?.router, {
                state: {
                    id: idChecked,
                    property_name: propName,
                    logo: logo,
                    area: unitLocation,
                    unit_name:ownerView?.agreement?.[0]?.unit_name
                }
            })

        }
    }
    const handleRoute = () => {
        navigate(AppRoutes?.assets,{
            state: location?.state?.id
        })
    }
    const propFunc =(value ,title)=>{
        console.log(value ,title , 'value ,title')
        if(title === 'Requests') return navigate(value, { state: location?.state?.id })
        if(title === 'Letters') return navigate(value, { state: location?.state?.id })
        if(title === 'Inspections') return navigate(value, { state: location?.state?.id })
        if(title === 'Invoices') return navigate(value, { state: ownerView?.agreement_details?.[0]?.agreement_id })
        if(title === 'Parkings') return navigate(value, { state: ownerView?.agreement_details?.[0]?.agreement_id})


        navigate(value, { state: location?.state?.id })

    }
    // get detailed unit 
    const getUnitsFunc = async () => {
        const response = await getOwnerUnit({
            unit_id: location?.state?.id,
            is_purchased:location?.state?.is_purchased
        })
        setOwnerView(response?.data?.data)
        if (!response?.isSuccess) {
            enqueueSnackbar(t(`Some Thing Went Wrong`), {
                
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: Bold }

            });
        }
    }
    // get detailed unit use effect    
    useEffect(() => {
        if (location?.state?.id) {
            getUnitsFunc(location?.state?.id)
        }
        // eslint-disable-next-line
    }, [location?.state?.id])


    React.useEffect(()=>{
        if(error?.status === 423) return errorRoute() 
     },[error ])

     const size = useWindowDimensions();

    return (
        <Container maxWidth={'sm'} sx={{ position: 'relative', padding:"0px !important" }}>
            <Box sx={{
        paddingTop: '59px', // Default style
        '@media screen and (min-width: 600px)': {
          paddingTop: '67px', // Media query style for `md` breakpoint
        },
      }}>
 <Box sx={{ position: 'fixed', top: 0, zIndex: 999 , left:0 , right:0}}>
               <Container maxWidth="sm" sx={{padding:"0px !important"}}>
               <TopNavBar title={`${location?.state?.is_purchased ? t("Purchased") : t(statusChecked)} ${t("Unit")}`}
                    isDirect isBack t={t} />
               </Container>
            </Box>


            <Box sx={VacantUnit_style?.root}>
                <Box  sx={{height:size?.height - 70 , overflow:"auto"}}>
                    {ownerView?.bool ?
                        <LoderSimmer card count={1} size={220} animation={"wave"} variant={"rectangular"} /> :
                        <img
                            src={ownerView?.agreement?.[0]?.logo ?
                                ownerView?.agreement?.[0]?.logo : "/images/propertyAvatar.svg"}
                            alt="images" height={'304px'} style={{ objectFit: "cover" }} width={'100%'} />}
                    {/*  */}
                    <Box sx={VacantUnit_style.rootMain}>

                        {ownerView?.bool ?
                            <LoderSimmer card count={1} size={100} variant={"rectangular"} animation={"wave"} /> :
                            <Box sx={VacantUnit_style?.sx}>
                                <AppartmentCard
                                    plotname={ownerView?.agreement?.[0]?.property_name}
                                    address={`${ownerView?.agreement?.[0]?.area}, ${ownerView?.agreement?.[0]?.city}`}
                                    name={ownerView?.agreement?.[0]?.unit_name}
                                    id={`${ownerView?.agreement?.[0]?.unit_no} | ${ownerView?.agreement?.[0]?.unit_type}`}
                                    image={<TransparentBuilding />}
                                    t={t}
                                    lease_start_date={ownerView?.agreement_details?.[0]?.lease_start_date}
                                    lease_end_date={ownerView?.agreement_details?.[0]?.lease_end_date}
                                    handleClick={handleRoute}
                                    details={ownerView}
                                    status={statusChecked}
                                    agreement={!!ownerView?.agreement_details?.[0]?.agreement_id}
                                />
                            </Box>
                        }
                            {/* LANDLORD APPROVAL REQUIRED */}
                            {(location?.state?.status !== "Occupied" && location?.state?.is_purchased === false)  &&
                                <Box sx={VacantUnit_style?.main} style={{display:"none"}}>
                                    <Typography mb={2} sx={VacantUnit_style?.favTitle}>{t("Landlord Approval Required")}</Typography>
                                    <Box sx={VacantUnit_style?.lanlordParent}>
                                        {landLord.map((item) => {
                                            return (
                                                <LandlordApprovalRequest
                                                    t = {t}
                                                    button
                                                    icon={item?.icon}
                                                    unitName={item?.unitName}
                                                    address={item?.address}
                                                // onClick={() => approvalPageRedirect(item?.id)} 
                                                />
                                            )
                                        })}
                                    </Box>
                                </Box>
                            }
                            
                        <Box sx={{ padding: '16px' }}>
                            <Box sx={VacantUnit_style?.correspndenceCard}>
                                <Grid container spacing={2}>

                                    {Correspondance(t, (!!ownerView?.agreement_details?.[0]?.agreement_id ? false : true))?.map((x, index) => {
                                        return (
                                            <Grid item xs={4} sx={x?.is_active ?
                                                VacantUnit_style?.noneStyle : null}>
                                                <Box key={index}>
                                                    <FavCards
                                                        id={!!ownerView?.agreement_details?.[0]?.agreement_id ? ownerView?.agreement_details?.[0]?.agreement_id : null}
                                                        title={x?.title}
                                                        image={x?.image}
                                                        is_image={x?.is_image}
                                                        router={x?.onClick}
                                                        is_active={x?.is_active}
                                                        unit_id={location?.state?.id}
                                                        is_property
                                                        propFunc={propFunc}
                                                        type={x?.type}
                                                    />
                                                </Box>
                                            </Grid>
                                        )
                                    })}
                                </Grid>


                            </Box>
                            <Box sx={{ paddingTop: '16px' }} />
                            <Box sx={VacantUnit_style?.amenitiesCard}>
                                {Amenities(t).map((x, index) => {
                                    return (
                                        <Box key={index}>
                                            <AmenitiesCard
                                                title={x?.title}
                                                image={x?.image}
                                                is_image={x?.is_image}
                                                router={x?.router !== null ? () => handleAmenitiesRoute(x, x?.isId) : false}
                                                id={idChecked}
                                            />
                                        </Box>
                                    )
                                })}
                            </Box>

                            {/* <Box sx={VacantUnit_style?.endImg}>
                                <img src="/images/endfamImg.svg" alt="img" />
                                <Typography sx={VacantUnit_style?.vacanttext}>{t('Occupied Unit')}</Typography>
                            </Box> */}
                        </Box>
                    </Box>
                </Box>
            </Box>
            </Box>
            {/* top navbar */}
           
        </Container>
    )
}