import * as React from "react"

export const PhoneTwo = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={12.6} height={18} {...props}>
        <path
            data-name="icons8-phone (4)"
            d="m5.363 1.182.774 1.825a2.086 2.086 0 0 1-.463 2.312L4.229 6.691a.682.682 0 0 0-.211.6 7.347 7.347 0 0 0 .927 2.5 7.888 7.888 0 0 0 1.746 2.1.688.688 0 0 0 .659.117l1.686-.563a1.83 1.83 0 0 1 2.1.738l1.109 1.629a2.09 2.09 0 0 1-.233 2.624l-.736.739a2.718 2.718 0 0 1-2.747.7q-3.428-1.069-6.3-6.348T.193 2.558A2.934 2.934 0 0 1 2.127.394l.969-.31a1.857 1.857 0 0 1 2.267 1.098Z"
            fill="#f15a29"
        />
    </svg>
)

