import React from "react";
import { RequestView } from './requestView';
import { withTranslation } from 'react-i18next';

class RequestViewParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <RequestView {...this?.props} />;
  }
}

export default withTranslation('request')(RequestViewParent);

