import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });;

export const ContactProfesstionMasterAPI = createApi({
    reducerPath: "contactMasterAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["Contact"],
    endpoints: (builder) => ({
        // getProfessionMater API
        getProfessionMaster: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `profession-master/profession_master`,
                        method: "POST",
                        body: payload
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, type: response.type };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),
        // Get Contact Listing Private API
        getContactListingPrivate: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `community/contact/private`,
                        method: "POST",
                        body: payload
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

        // Get Contact Listing API
        getContactListing: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `community/contact/public`,
                        method: "POST",
                        body: payload
                    }
                },
                transformResponse: (response, meta, arg) => {

                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

        // Get Contact Create API
        getProfessionMater: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `profession-master/profession_master`,
                        method: "POST",
                        body: payload
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, type: response.type };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),
               // Get Contact Create API
        getContactsStatus: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `community/contact/status`,
                        method: "POST",
                        body: payload
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, type: response.type };
                    }
                    return { data: [], type: "error" };
                },
            }),

            // update contact
            getContactsUpdate: builder.query(
                {
                    query: (payload) => {
                        return {
                            url: `community/contact/update`,
                            method: "POST",
                            body: payload
                        }
                    },
    
                    transformResponse: (response, meta, arg) => {
                        if (response.type === "success") {
                            return { data: response.data, type: response.type };
                        }
                        return { data: [], type: "error" };
                    },
                }),

                    // update contact
            getContactsCreate: builder.query(
                {
                    query: (payload) => {
                        return {
                            url: `community/contact/create`,
                            method: "POST",
                            body: payload
                        }
                    },
    
                    transformResponse: (response, meta, arg) => {
                        if (response.type === "success") {
                            return { data: response.data, type: response.type };
                        }
                        return { data: [], type: "error" };
                    },
                }),
    }),
});

// Export hooks for usage in functional components
export const {
    useLazyGetProfessionMasterQuery,
    useLazyGetContactListingPrivateQuery,
    useLazyGetContactListingQuery,
    useLazyGetProfessionMaterQuery,
    useLazyGetContactsStatusQuery,
    useLazyGetContactsUpdateQuery,
    useLazyGetContactsCreateQuery

} = ContactProfesstionMasterAPI;
