import { Box } from "@mui/material"
import { LoderSimmer } from "../../../components"
export const InvoiceSimmmer_style = {
    favicon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    align: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    bg: {
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '12px'
    },
    alignTwo: {
        display: 'flex',
        alignItems: 'center'
    },
}
export const InvoiceSimmmer = () => {
    return (
        <Box>
            <Box sx={InvoiceSimmmer_style?.bg}>
                <Box sx={InvoiceSimmmer_style?.align}>
                    <LoderSimmer card count={1} width={90} size={15} variant={"rectangular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={80} size={20} variant={"rounded"} animation={"wave"} />
                </Box>
                <LoderSimmer card count={1} width={130} size={14} variant={"rectangular"} animation={"wave"} />
                <LoderSimmer card count={1} width={120} size={10} variant={"rectangular"} animation={"wave"} />
                <Box sx={InvoiceSimmmer_style?.align}>
                    <LoderSimmer card count={1} width={80} size={80} variant={"rounded"} animation={"wave"} />
                    <LoderSimmer card count={1} width={80} size={80} variant={"rounded"} animation={"wave"} />
                    <LoderSimmer card count={1} width={80} size={80} variant={"rounded"} animation={"wave"} />
                </Box>
            </Box>
        </Box>
    )
}

export const InvoiceSimmerCard = () => {
    const count = 5
    return (
        <Box>
       <Box sx={InvoiceSimmmer_style?.bg}>
                {Array.apply(null, Array(count)).map((value, index) => (
                    <Box sx={InvoiceSimmmer_style?.alignTwo}>
                <LoderSimmer card count={1} width={20} size={20} variant={"circular"} animation={"wave"} />
                <Box sx={{paddingLeft:'20px'}}>
                    <LoderSimmer card count={1} width={180} size={10} variant={"rectangular"} animation={"wave"} />
                    <LoderSimmer card count={1} width={160} size={10} variant={"rectangular"} animation={"wave"} />
                </Box>
            </Box>
                ))}
        </Box>
        </Box>
        
    )
}