import * as React from "react"

export const Communication = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17.759} height={16} {...props}>
    <path
      d="M10.363 0a1.4 1.4 0 0 0-1.4 1.4v6a.6.6 0 0 0 1.024.424L11.808 6h4.555a1.4 1.4 0 0 0 1.4-1.4V1.4a1.4 1.4 0 0 0-1.4-1.4ZM4.425 4.5A2.775 2.775 0 1 0 7.2 7.275 2.778 2.778 0 0 0 4.425 4.5Zm-3.032 6.3a1.352 1.352 0 0 0-1.392 1.3v1.233C0 14.855 1.903 16 4.425 16s4.425-1.146 4.425-2.664V12.1a1.351 1.351 0 0 0-1.387-1.3Z"
      fill="#f17360"
    />
  </svg>
)

