import { Box } from "@mui/material"
import { LoderSimmer } from "../../../components"
export const AddonServiceSimmer = {
    favicon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    align: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        m: "16px 0px 0px 16px"

    },
    bg: {
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '12px'
    }
}
export const AddOnsServiceSimmer = () => {
    const count = 1
    return (
        <>
            {Array.apply(null, Array(count)).map((value, index) => (
                <>
                    <Box sx={{ marginBottom: '12px', marginTop: "60px" }}>

                        <Box sx={AddonServiceSimmer?.bg}>
                            <LoderSimmer card count={1} width={439} size={72} variant={"rounded"} animation={"wave"} />
                        </Box>
                        <Box sx={AddonServiceSimmer?.align}>
                            <LoderSimmer card count={1} width={200} size={16} variant={"rectangular"} animation={"wave"} />
                        </Box>
                    </Box>
                    <Box sx={AddonServiceSimmer?.bg}>
                        <LoderSimmer card count={1} width={439} size={300} variant={"rounded"} animation={"wave"} />
                    </Box>
                </>

            ))}
        </>
    )
}