import { SemiBold } from "../../utils";

export const AmenitiesCard_style = {
  root: {
    padding: "16px",
    //borderBottom: "1px solid #E4E8EE",
    cursor: "pointer",
  },
  sx: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center'
  },
  sub: {
    display: 'flex',
    alignItems: 'center'
  },
  background: {
    width: "48px",
    height: "48px",
    backgroundColor: "backgroundcard.teritary",
    boxShadow: "0px 3px 15px #4354720A",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bottomText: {
    fontFamily: SemiBold,
    fontSize: "0.875rem",
    color: "text.main",
    textTransform: "capitalize",
    marginInlineStart: "8px"
  },
  imageAlign: {
    marginTop: "5px",
  },
  rightArrow: {
    width: '5px',
    height: '11px',
    transform: 'matrix(0, 1, -1, 0, 0, 0)',
    background: '#000000 0% 0% no-repeat padding-box',
    border: '0.75px solid #000000',
  },
  divider: {
    borderBottom: "1px solid #E4E8EE",
    paddingLeft: '20px',
    marginLeft: '60px',
    marginRight: '20px'
  },
  arabicDivider: {
    borderBottom: "1px solid #E4E8EE",
    paddingLeft: '20px',
    marginLeft: '20px',
    marginRight: '60px'
  }
};
