import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux';
import { authAPI, usersAPI, clientAPI, requestAPI, enum_value, projectAPI, refreshAPI, StatisticAPI, statisticsAPI, usersProfileAPI, utilitiesAPI, FileManagerAPI, kycAPI, CorrespondenceAPI, amenitiesAPI, VehicleAPI, announcementAPI, DashboardAPI, ParkingArea, contactAPI, assetsAPI, contractAPI, InvoicesAPI, ContactProfesstionMasterAPI, InspectionMoveinMoveOutAPI, InventoryAPI, initialRendererAPI } from './services';
import { authReducer, backdropReducer, dialogReducer, drawerReducer, utilsReducer, clientReducer, refreshReducer, StatisticReducer, alertReducer, inspectionReducer, CorrespondenceReducer, DashboardReducer, assetReducer, ContactReducer } from './slices';
import { termsAndConditionAPI } from './services/termsAndCondition';
import { SurveyAPI } from './services/survey';
const ReduxStore = configureStore({
    reducer: {
        [authAPI.reducerPath]: authAPI.reducer,
        [usersAPI.reducerPath]: usersAPI.reducer,
        [projectAPI.reducerPath]: projectAPI.reducer,
        [requestAPI.reducerPath]: requestAPI.reducer,
        [enum_value.reducerPath]: enum_value.reducer,
        [clientAPI.reducerPath]: clientAPI.reducer,
        [refreshAPI.reducerPath]: refreshAPI.reducer,
        [statisticsAPI.reducerPath]: statisticsAPI.reducer,
        [StatisticAPI.reducerPath]: StatisticAPI.reducer,
        [FileManagerAPI.reducerPath]: FileManagerAPI.reducer,
        [kycAPI.reducerPath]: kycAPI.reducer,
        [CorrespondenceAPI.reducerPath]: CorrespondenceAPI.reducer,
        [usersProfileAPI.reducerPath]: usersProfileAPI.reducer,
        [amenitiesAPI.reducerPath]: amenitiesAPI.reducer,
        [announcementAPI.reducerPath]: announcementAPI.reducer,
        [VehicleAPI.reducerPath]: VehicleAPI.reducer,
        [DashboardAPI.reducerPath]: DashboardAPI.reducer,
        [ParkingArea.reducerPath]: ParkingArea.reducer,
        [contactAPI.reducerPath]: contactAPI.reducer,
        [assetsAPI.reducerPath]: assetsAPI.reducer,
        [contractAPI.reducerPath]: contractAPI.reducer,
        [utilitiesAPI.reducerPath]: utilitiesAPI.reducer,
        [InvoicesAPI.reducerPath]: InvoicesAPI.reducer,
        [ContactProfesstionMasterAPI.reducerPath]: ContactProfesstionMasterAPI.reducer,
        [InspectionMoveinMoveOutAPI.reducerPath]: InspectionMoveinMoveOutAPI.reducer,
        [InventoryAPI.reducerPath]: InventoryAPI.reducer,
        [termsAndConditionAPI.reducerPath]: termsAndConditionAPI.reducer,
        [initialRendererAPI.reducerPath]: initialRendererAPI.reducer,
        [SurveyAPI.reducerPath]: SurveyAPI.reducer,

        auth: authReducer,
        backdrop: backdropReducer,
        alert: alertReducer,
        dialog: dialogReducer,
        utils: utilsReducer,
        drawer: drawerReducer,
        client: clientReducer,
        refresh: refreshReducer,
        Statistic: StatisticReducer,
        inspection: inspectionReducer,
        Correspondence: CorrespondenceReducer,
        Dashboard: DashboardReducer,
        Asset: assetReducer,
        Contact: ContactReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(authAPI.middleware).concat(projectAPI.middleware).concat(requestAPI.middleware).concat(enum_value.middleware).concat(refreshAPI.middleware).concat(clientAPI.middleware).concat(statisticsAPI.middleware).concat(StatisticAPI.middleware)
        .concat(usersProfileAPI.middleware).concat(utilitiesAPI.middleware).concat(usersAPI.middleware).concat(FileManagerAPI.middleware).concat(kycAPI.middleware).concat(CorrespondenceAPI.middleware).concat(amenitiesAPI.middleware).concat(announcementAPI.middleware).concat(VehicleAPI.middleware).concat(DashboardAPI.middleware).concat(ParkingArea.middleware).concat(contactAPI.middleware).concat(assetsAPI.middleware).concat(contractAPI.middleware).concat(InvoicesAPI.middleware).concat(ContactProfesstionMasterAPI.middleware).concat(InspectionMoveinMoveOutAPI.middleware).concat(InventoryAPI.middleware).concat(termsAndConditionAPI.middleware).concat(initialRendererAPI.middleware).concat(SurveyAPI.middleware),

});
export const AppRedux = ({ children }) => {
    return <Provider store={ReduxStore}>{children}</Provider>
}

