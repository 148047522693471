import { Bold, Regular, SemiBold } from "../../utils";

export const AddOnsPriceCardStyles = {
    root: {
        backgroundColor: "primary.contrastText",
        padding: "12px",
        // borderRadius: "4px",


    },
    ChangeUnitroot: {
        backgroundColor: "primary.contrastText",
        padding: "14px 16px",
        borderRadius: "8px",
        border: "1px solid",
        borderColor: "border.secondary"

    },
    headText: {
        color: "text.main",
        fontSize: "1rem",
        fontFamily: Bold
    },
    avatar: {
        borderRadius: "4px",
        width: "48px",
        height: "48px",
    },
    title: {
        color: "text.main",
        fontSize: "0.875rem",
        fontFamily: Bold,

    },
    subTitle: {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        marginTop: "6px"
    },
    subTitle1: {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: Regular,
    },
    subTitle2: {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: Regular,
        marginLeft: "8px"
    },
    valueText: {
        color: "primary.main",
        fontSize: "0.75rem",
        fontFamily: Bold,

    },
    tagTitle: {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: Bold,
        backgroundColor: "border.secondary",
        padding: "3px",
        borderRadius: "4px"
    },
    price: {
        color: "text.main",
        fontSize: "0.75rem",
        fontFamily: Bold,


    },
    available: {
        color: "primary.main",
        fontSize: "0.75rem",
        fontFamily: Bold,
        // backgroundColor: "background.primary1",
        padding: "3px",
        borderRadius: "4px",
        marginTop: "4px"
    },
    statusGood: {
        color: "primary.contrastText",
        fontSize: "0.75rem",
        fontFamily: Bold,
        backgroundColor: "status.success",
        padding: "3px",
        borderRadius: "4px"

    },
    statusDamage: {
        color: "primary.contrastText",
        fontSize: "0.75rem",
        fontFamily: Bold,
        backgroundColor: "error.Teritary",
        padding: "3px",
        borderRadius: "4px"

    },
    divider: {
        width: "95%",
        margin: "0px auto"
    },

    new: {
        backgroundColor: "#5AC782",
        color: "#fff",
        padding: "3px 6px",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        borderRadius: "4px"
    },
    used: {
        backgroundColor: "#5078E1",
        color: "#fff",
        padding: "3px 6px",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        borderRadius: "4px"
    },
    repaired: {
        backgroundColor: "#FF9340",
        color: "#fff",
        padding: "3px 6px",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        borderRadius: "4px"
    },
    damaged: {
        backgroundColor: "#FF4B4B",
        color: "#fff",
        padding: "3px 6px",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        borderRadius: "4px"
    },
    default: {
        // backgroundColor: "#CED3DD",
        color: "#4E5A6B",
        padding: "3px 6px",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        borderRadius: "4px"
    }


}