// import { Bold, ExtraBold, Regular } from "../../utils";
// import useTheme from "@mui/material/styles/useTheme";


export const CreateInvestmentStyle = {
    

    
        root: {
            padding: "16px",
            width: '100%',
            overflow: "auto",
            backgroundColor:"background.main",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            height: '100%',
            
          },
          container:{
            backgroundColor:"background.Teritary",
            paddingLeft:"0px !important",
            paddingRight:"0px !important",
            position:"relative",
            height:"100%"
          },
          profilePictureEditIcon:{
            marginBottom:"32px"

          },
          footer:{
            position:'sticky',
            width:"100%",
            bottom:"0"
      
          }

    

  
  
}

