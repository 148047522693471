import { Box, Typography, Grid, TextField, Popover } from "@mui/material";
import { SelectBox } from "../selectBox";
import { ChartBoxStyle } from "./style";
import { Piechart } from "../piechart";
import { ToggleBtn } from "../toggleBtn";
//import { BarChartLoader } from "../barChartLoader"
import { LineChartLoader } from "../linechartLoader";
import React from "react";
import { SelectPopover } from "./selectPopover";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const ChartBox = ({
  title = "",
  data = [],
  symbol = "",
  options = [],
  selectedYear = "",
  onChange = () => false,
  value = "",
  graphcomponent = null,
  dropcomponent = null,
  hidecomponent = null,
  togglebtn = false,
  chart_parent = {},
  hidecomponentFlex = {},
  toggles = false,
  year = [],
  month = [],
  loading1 = "",
  yearSelect = () => false,
  yearValue = "",
  monthValue = "",
  loader = false,
  handleChangePeriod = () => false,
  title_style={},
  values={}
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleIconClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const ObjectYear = {
    label: yearValue,
    value: yearValue
  }
  return (
    <Box sx={ChartBoxStyle?.chartParent}>
      <Box sx={ChartBoxStyle?.header}>
        <Grid container spacing={2} alignItems={"center"} justifyContent={"space-between"}>
          <Grid item xs={5.5}>
            <Typography sx={{...ChartBoxStyle?.title}}>{title}</Typography>
          </Grid>
          <Grid item xs={6.5}>
            {dropcomponent ?? (
              <>
                <Grid container spacing={2} alignItems={"center"} justifyContent={"end"}>
                  <Grid item xs={10} >
                  <ToggleBtn
                    options={options}
                    value={value}
                    onChange={(value) => onChange(value)}
                    padding={"7px 11px"}
                    borderRadius={"40px"}
                    divider={{ xs: 6, sm: 6 }}
                    border={"none"}
                    backgroundColorunselected={"#fff"}
                    backgroundColorselected={"#F15A29"}
                    // backgroundColorunparent={'transparent'}
                    width={"63px !important"}
                    backgroundColorunparent={'#fff'}

                  />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ ...ChartBoxStyle.body, ...chart_parent }}>
        <Box sx={{...ChartBoxStyle?.marginTop , ...title_style}}>
          <Grid container justifyContent="space-between" alignItems="center">
            {
              toggles ? <Grid item xs={6} sm={4}>
                <TextField
                  sx={ChartBoxStyle?.padding}
                  onClick={(e) => {
                    e.stopPropagation()
                    setAnchorEl(e.currentTarget);
                  }}
                  inputProps={{
                    readOnly: false
                  }}
                  InputProps={{
                    endAdornment: <KeyboardArrowDownIcon style={{ fontSize: "16px" }} />,
                    style: {
                      fontSize: "0.875rem",
                      height: "36px",
                      padding: "none",
                      width: "121px !important",
                      borderRadius: "8px !important", boxShadow: "none !important"
                    },
                  }}
                  value={`${monthValue} ${yearValue}`}
                  variant={"outlined"}
                  fullWidth
                  InputLabelProps={{
                    shrink: false,
                  }}
                  size="small"
                  autoFocus={false}
                  onWheel={(e) => e.target.blur()}

                />
                <Popover
                  id='simple-popover'
                  open={open}
                  anchorEl={anchorEl}
                  sx={ChartBoxStyle?.popOver}
                  onClose={handleIconClose}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}>
                  <SelectPopover
                    year={year}
                    yearValue={yearValue}
                    month={month}
                    monthValue={monthValue}
                    handleIconClose={handleIconClose}
                    handleChangePeriod={handleChangePeriod}
                    values={values}
                  />
                </Popover>
              </Grid> :
                <Grid item xs={6} sm={3}>
                  <Box sx={{ paddingInlineEnd: '10px' }}><SelectBox value={ObjectYear}
                    options={year ?? []} onChange={yearSelect} controls={{
                      borderRadius: "8px !important",
                      height: "32px !important",
                      width: "116px !important"
                    }}
                    radius={"8px !important"}
                    selectHeight={"32px !important"} /></Box>
                </Grid>
            }
            <Grid item xs={6} sm={5}>
              <Box>
                {togglebtn && (
                  <ToggleBtn
                    options={options}
                    value={value}
                    onChange={(value) => onChange(value)}
                    padding={"7px 11px"}
                    borderRadius={"40px"}
                    divider={{ xs: 6, sm: 6 }}
                    border={"none"}
                    backgroundColorunselected={"#F8FAFC"}
                    backgroundColorselected={"#F15A29"}
                    width={"63px !important"}
                    backgroundColorunparent={'#F8FAFC'}


                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ ...ChartBoxStyle?.hidecomponentFlex, ...hidecomponentFlex }}>
          {
            loader ?
              <LineChartLoader /> :
              hidecomponent ?? (
                <Grid container spacing={0}>
                  <Grid item xs={data?.length > 0 ? 7 : 12} sm={data?.length > 0 ? 7 : 12}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      sx={ChartBoxStyle?.marginLeft}
                    >
                      {graphcomponent ?? (
                        <Piechart
                          data={data}
                          width={200}
                          isTotal={true}
                          total={`$ 10`}
                        />
                      )}
                    </Box>
                  </Grid>

{
  data?.length > 0 &&
  <Grid item xs={5} sm={5}>
  <Box height={'13rem'} overflow={'auto'}>
    {data?.map((x, index) => {
      return (
        <Box mb={1}>
          <Box sx={ChartBoxStyle.typoSection} key={index}>
          <Box display={"flex"} alignItems={"center"} gap={'11px'}>
            <Box
              sx={{
                backgroundColor: x?.fill,
                padding: "4px",
                borderRadius: "50%",
              }}
            ></Box>
            <Typography sx={ChartBoxStyle?.value} noWrap>
              {x?.unit_no}
            </Typography>
          </Box>
          <Typography sx={ChartBoxStyle.typoValue}>
            {" "}
           {symbol}
           {x?.amount}
          </Typography>
        </Box>
        </Box>
      );
    })}
  </Box>
</Grid>
}
                 

                </Grid>
              )
          }

        </Box>
      </Box >
    </Box >
  );
};