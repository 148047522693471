import { Regular, Bold, SemiBold, ExtraBold } from "../../utils";
export const ActivityModal_style = {
    sx: {
        height: "200px",
        overflow: "auto",
        padding: "0px !important"
    },
    heading: {
        fontFamily: Bold,
        fontSize: "0.875rem",
        color: "color.primary"
    },
    footer: {
        position: 'sticky',
        width: "100%",
        bottom: "0",
        backgroundColor: '#fff'
    },
    year: {
        fontFamily: SemiBold,
        backgroundColor: "primary.main",
        color: "white",
        padding: "7px 11px",
        fontSize: "0.75rem",
        borderRadius: "15px"
    },
    month: {
        fontSize: "0.75rem",
        padding: "7px 5px 7px 0",
    },
    percentage: {
        backgroundColor: "#EE5188",
        color: "white",
        fontSize: "0.75rem",
        padding: "2px 9px",
        borderRadius: "4px"
    },
    tenantId: {
        fontFamily: SemiBold,
        color: "color.secondary",
        fontSize: "0.875rem"
    },
    price: {
        fontFamily: Bold,
        fontSize: "0.875rem",
        color: "color.primary"
    },
    divider: {
        borderColor: "#5C86CB2E",
        height: "1px",
        margin: 0,
    },
    activity: {
        backgroundColor: "#FFE5DC",
        color: "#F15A29",
        padding: "2px 8px",
        fontSize: "0.75rem",
        borderRadius: "4px"
    },
    work: {
        color: "color.secondary",
        fontFamily: Regular,
        fontSize: "0.75rem"
    },
    totalAmount: {
        fontFamily: Regular,
        color: "primary.Tertiary",
        fontSize: "0.875rem"
    },
    money: {
        color: "color.primary",
        fontSize: "0.78125",
        fontFamily: ExtraBold,
    },
    descirption: {
        color: "text.Teritary",
        fontFamily: Regular,
        fontSize: "0.75rem"
    },
    drawerActivity: {
        backgroundColor: "#FFE5DC",
        color: "primary.main",
        padding: "8px 12px",
        borderRadius: "8px",
        fontFamily: ExtraBold,
        fontSize: "0.75rem"
    },
    information: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: "color.tertiary"
    },
    assist: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        color: "color.tertiary"
    },
    label: {
        color: "#98A0AC",
        fontSize: "0.75rem",
        fontFamily: Bold,
    },
    textArea: {
        width: "100%",
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
    },
    primaryButton: {
        padding: "15px 60px",
        border: "1px solid #CED3DD",
        color: "color.primary",
        fontFamily: Bold,
        borderRadius: "8px"
    },
    secondaryButton: {
        padding: "15px 60px",
        backgroundColor: "primary.main",
        border: "none",
        color: "white",
        fontFamily: Bold,
        borderRadius: "8px"
    },
    uploadButton: {
        border: "2px dashed #E4E8EE",
        borderRadius: "4px",
        padding: "25px",
        backgroundColor: "white",
        color: "color.primary"
    },
    field: {
        [`& fieldset`]: {
            borderRadius: "4px",
            border: "1.5px solid  #E4E8EE",
            boxShadow: "none",
            backgroundColor: "white"
        },
    },
    dividerSec: {
        color: "#F2F4F7",
        borderBottomWidth: "4px",
        height: "12px"
    },
    configureList: {
        justifyContent: "space-between",
        direction: "row",

    }

}
