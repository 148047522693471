import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });;

export const contactAPI = createApi({
    reducerPath: "contactAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["Contact"],
    endpoints: (builder) => ({

        
    
        // Get Contact Status Toggle API
        getContactStatus: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `community/contact/status`,
                        method: "POST",
                        body: payload
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, type: response.type };
                    }
                    return { data: [], type: "error" };
                },
            }),


       

    }),
});

// Export hooks for usage in functional components
export const {
    useLazyGetContactStatusQuery
} = contactAPI;
