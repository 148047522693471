import { Bold } from "../../utils";

export const AddressEditStyle = {
  root: {
    padding: "16px",

    width: '100%',
    overflow: "auto",
    backgroundColor: "background.main",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    height: '100%',

  },
  container: {
    backgroundColor: "background.Teritary",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    position: "relative",
    height: "100%"
  },
  profilePictureEditIcon: {
    marginBottom: "32px"

  },
  title: {
    color: "text.main",
    fontSize: "1rem",
    fontFamily: Bold,
    marginBottom: "16px"
  },
  footer: {
    position: 'sticky',
    width: "100%",
    bottom: "0"

  }


}

