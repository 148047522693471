import * as React from "react"
const PurpleLocation = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 116869" transform="translate(-49 -896)">
      <rect
        width={24}
        height={24}
        fill="#fef4f4"
        data-name="Rectangle 58383"
        rx={4}
        transform="translate(49 896)"
      />
      <g data-name="icons8-location (8)" transform="translate(47 895.015)">
        <path
          fill="#b3776d"
          d="M19.046 16.883a7.6 7.6 0 1 0-10.894 0c.008.008.013.017.02.025l.025.025.06.06L13 21.738a.844.844 0 0 0 1.194 0l4.745-4.745.06-.06.025-.025c.01-.008.015-.017.022-.025Z"
          data-name="Path 101862"
        />
        <circle
          cx={3.377}
          cy={3.377}
          r={3.377}
          fill="#f9f9f9"
          data-name="Ellipse 130999"
          transform="translate(10.222 8.213)"
        />
      </g>
    </g>
  </svg>
)
export default PurpleLocation
