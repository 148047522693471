import { SemiBold } from "../../utils";

export const styles = {
    root: {
        // width: "100%",
        // padding:"0px"
    },
    fileroot: {
        width: "100%",
        color: "primary.main",
        cursor: "pointer",
        border: "2px dashed",
        borderColor: "border.secondary",
        borderRadius: "4px",
        padding: "28px",
        backgroundColor: "#FFEBE4",
        margin: "auto",
        textAlign: "center !important"
    },
    imgContainer: {
        width: "100%",
    },
    delete: {
        color: "red",
        position: "absolute",
        top: "4px",
        right: "8px",
        backgroundColor: "white",
    },
    uploadTittle: {
        fontSize: "0.875rem",
        color: "text.text",
        fontFamily: SemiBold,
        marginInlineStart: "16px"
    },
    uploadcount: {
        fontSize: "0.875rem",
        color: "text.text",
        fontFamily: SemiBold,
        paddingLeft: "16px",
    },
    overFlow: {
        overflow: "auto",
        overflowY: "hidden"
    },
    images:{
        // display:'flex',
        // flexWrap:'wrap',
        alignItems:"center",
        marginTop:'16px'
    },
    divider: {
        marginTop: "16px"
    }

}