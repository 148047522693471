import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export const DocumentViewer = ({ url = "" }) => {

    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const pdfVersion = "3.5.141"
    const pdfWorkerUrl = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfVersion}/pdf.worker.js`

    return (
        <>
            {
                url?.length > 0 &&
                <Worker workerUrl={pdfWorkerUrl}>
                    <Viewer
                        fileUrl={url}
                        plugins={[
                            // Register plugins
                            defaultLayoutPluginInstance,
                        ]}

                    />
                </Worker>
            }
        </>

    );
};
