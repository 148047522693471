export const ChargesIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="Group_117681" data-name="Group 117681" transform="translate(-3118 4469)">
                <g id="Rectangle_58541" data-name="Rectangle 58541" transform="translate(3118 -4469)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="14" height="14" stroke="none" />
                    <rect x="0.5" y="0.5" width="13" height="13" fill="none" />
                </g>
                <path id="icons8-cheque_3_" data-name="icons8-cheque (3)" d="M16.974,15.947v1.026H6.026V15.947a1.026,1.026,0,0,0,0,2.053H16.974a1.026,1.026,0,1,0,0-2.053Zm0-10.434a.513.513,0,0,0-.8-.427l-1.269.846-1.432-.859a.513.513,0,0,0-.528,0L11.5,5.941l-1.447-.868a.513.513,0,0,0-.528,0l-1.432.859L6.824,5.086a.513.513,0,0,0-.8.427V15.947H16.974Zm-5.987,9.066H8.592a.513.513,0,1,1,0-1.026h2.395a.513.513,0,1,1,0,1.026Zm.684-2.053H8.592a.513.513,0,1,1,0-1.026h3.079a.513.513,0,0,1,0,1.026Zm2.737,2.053h-.684a.513.513,0,0,1,0-1.026h.684a.513.513,0,1,1,0,1.026Zm0-2.053h-.684a.513.513,0,0,1,0-1.026h.684a.513.513,0,1,1,0,1.026Zm0-2.737H8.592a.513.513,0,0,1,0-1.026h5.816a.513.513,0,1,1,0,1.026Z" transform="translate(3113.5 -4473.5)" fill="#fff" />
            </g>
        </svg>

    )
}