import { Bold, Regular } from "../../utils";


export const ParkingCard_style = {

    dot: {
        width: "6px",
        height: "6px",
        borderRadius: "4px",
        margin: "0px 6px",
        backgroundColor: "rgb(206, 211, 221)"
    },
    card: {
        padding: '12px',
        height: 'auto',
        backgroundColor: 'primary.contrastText',
        borderRadius: '4px',
        boxShadow: '0px 3px 30px #5C86CB2E',
        marginBottom: '16px',
        marginTop: '6px',
        cursor: "pointer"
    },
    divider: {
        borderBottom: '1px solid #F2F4F7',
        margin: '12px 0px'
    },
    title: {
        fontSize: "0.875rem",
        fontFamily: Bold,
    },
    appartmentName: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: 'text.Teritary',
        marginTop: "6px"
    },
    doorno: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: 'text.Teritary',
        paddingLeft: '7px'
    },
    roundBg: {
        backgroundColor: 'background.secondary',
        padding: '6px',
        borderRadius: '50%',
        display: 'flex'
    },
    bgImg: {
        borderRadius: '8px',
        boxShadow: ' 0px 3px 6px #00000014',
        width: '50px',
        height: '50px',
        marginInlineEnd: '12px',
        background: " transparent url(https://images.examples.com/wp-content/uploads/2018/08/Official-Correspondence-Business-Letter-Format.jpg) 0% 0% no-repeat padding-box",
    },
    sx: {
        display: 'flex',
        alignItems: 'center'
    },
    align: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    doorNoalign: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

}