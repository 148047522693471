import * as React from "react"

export const Checkout = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 107153" transform="translate(-28 -273)">
      <rect
        data-name="Rectangle 55704"
        width={32}
        height={32}
        rx={4}
        transform="translate(28 273)"
        fill="#eef9ee"
      />
      <path
        data-name="icons8-verified-badge (1)"
        d="M51.461 289.418a1.884 1.884 0 0 0-.529-3.018l-.785-.37a.644.644 0 0 1-.364-.638l.076-.873a1.885 1.885 0 0 0-.654-1.6 1.843 1.843 0 0 0-1.67-.375l-.837.228a.634.634 0 0 1-.684-.252l-.5-.718a1.838 1.838 0 0 0-3.033 0l-.5.718a.634.634 0 0 1-.684.252l-.837-.228a1.839 1.839 0 0 0-1.669.375 1.885 1.885 0 0 0-.654 1.6l.076.873a.646.646 0 0 1-.364.638l-.785.37a1.884 1.884 0 0 0-.527 3.022l.613.62a.649.649 0 0 1 .127.725l-.367.795a1.894 1.894 0 0 0 .075 1.728 1.851 1.851 0 0 0 1.442.929l.863.076a.639.639 0 0 1 .558.473l.224.846a1.865 1.865 0 0 0 1.155 1.276 1.835 1.835 0 0 0 1.7-.226l.709-.5a.631.631 0 0 1 .728 0l.71.5a1.834 1.834 0 0 0 1.7.226 1.868 1.868 0 0 0 1.155-1.276l.224-.846a.637.637 0 0 1 .558-.473l.862-.076a1.85 1.85 0 0 0 1.442-.929 1.894 1.894 0 0 0 .074-1.729l-.367-.794a.649.649 0 0 1 .127-.725Zm-4.1-1.624-4.055 4.1a.6.6 0 0 1-.86 0l-2.026-2.048a.62.62 0 0 1 0-.87.6.6 0 0 1 .86 0l1.6 1.616 3.625-3.667a.6.6 0 0 1 .86 0 .621.621 0 0 1-.004.87Z"
        fill="#5ac782"
      />
    </g>
  </svg>
)

