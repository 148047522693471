import * as React from "react"
const ContractValue = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <g data-name="Group 113307">
      <path
        fill="#6dafb3"
        d="M14.476 2H1.524A1.523 1.523 0 0 0 0 3.524V11.9a1.523 1.523 0 0 0 1.524 1.524h12.952A1.523 1.523 0 0 0 16 11.9V3.524A1.523 1.523 0 0 0 14.476 2ZM1.143 4.1a.953.953 0 0 1 .952-.952h1.334a2.289 2.289 0 0 1-2.286 2.286Zm.952 8.19a.953.953 0 0 1-.952-.952V10a2.289 2.289 0 0 1 2.286 2.286Zm1.333-3.81a.762.762 0 1 1 .762-.762.764.764 0 0 1-.761.758Zm5.143 1.874v.221a.572.572 0 1 1-1.143 0v-.206a1.627 1.627 0 0 1-1.272-.872.571.571 0 0 1 1.021-.51.5.5 0 0 0 .457.255h.651a.476.476 0 0 0 0-.952h-.469A1.623 1.623 0 0 1 6.2 6.667a1.643 1.643 0 0 1 1.23-1.554v-.255a.572.572 0 1 1 1.143 0v.232a1.532 1.532 0 0 1 1.082.846.571.571 0 1 1-1.021.51.435.435 0 0 0-.457-.255h-.32a.508.508 0 0 0-.514.476.476.476 0 0 0 .476.476h.469a1.616 1.616 0 0 1 .286 3.208Zm3.238-2.636a.762.762 0 1 1 .762.762.764.764 0 0 1-.761-.766Zm3.048 3.619a.953.953 0 0 1-.952.952h-1.334A2.289 2.289 0 0 1 14.857 10Zm0-5.9a2.289 2.289 0 0 1-2.286-2.286H13.9a.953.953 0 0 1 .952.952Z"
        data-name="icons8-bonds (1)"
      />
    </g>
  </svg>
)
export default ContractValue
