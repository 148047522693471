import { Bold, Regular } from "../../utils";


export const InspectorProfie_style ={
    inspectorname:{
        fontSize:"0.875rem",
        fontFamily:Bold,
        marginBottom:'4px'
    },
    inspectorId:{
        fontFamily:Regular,
        color:'text.Teritary',
        fontSize:"0.75rem"
    },
    inspectedDate:{
        fontSize:"0.875rem",
        fontFamily:Bold,
        color:'text.Teritary',
        marginBottom:'4px'
    },
    align:{
        display:'flex',
        alignItems:'center',
        paddingBottom:'20px'
    },
    marginLeft:{
        marginLeft:'12px'
    },
    marginLeftsmall:{
        marginInlineStart:'8px'
    },
    line : {   
        position: 'absolute',
        zIndex: '1',
        top: '82%',
        bottom: '12%',
        left: '13%',
        borderLeft: '2px dashed #ccc',
}
    
}