import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold } from "../../utils";
import { nudges_card_constants } from "./constant";

export const useStyles = makeStyles(() => ({
  nudge_card: {
    cursor: "pointer",
    minWidth: ({ size }) => size?.width >= 599 ? "568px" : `${size?.width - 32}px`,
    maxWidth: "568px",
    minHeight: ({ list }) => (list?.length === 1 && list?.[0]?.type === nudges_card_constants?.weather?.type) ? "80px" : "136px",
    borderRadius: "12px",
    padding: "16px",
  },
  nudge_title_text: {
    fontSize: "0.75rem",
    fontFamily: Regular,
    color: "#FFFFFF",
    textShadow: "0px 0px 6px #00000029",
  },
  nudge_subject_text: {
    fontSize: "1rem",
    fontFamily: Bold,
    color: "#FFFFFF",
    textShadow: "0px 0px 6px #00000029",
  },
  nudge_CTA_text: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: "#FFFFFF",
    textShadow: "0px 0px 6px #00000029",
  },
  nudge_pagnation_style: {
    borderRadius: "1px",
    width: "20px",
    maxWidth: "20px",
    height: "2px",
  },
  nudge_icon: {
    objectFit: "contain",
    height: "40px",
    width: "40px",
  },
  nudge_image: {
    objectFit: "contain",
    height: "40px",
    width: "40px",
    borderRadius: "50%",
  },
}));