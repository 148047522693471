import { Bold, SemiBold } from "../../utils";

export const VehileViewStyle = {
    containerSm: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        position: "relative",
    },
    subRoot: {
        overflow: "auto",
        backgroundColor: 'primary.contrastText',
        height: "auto",
        padding: "16px",
        margin: "84px 16px 103px",
        boxShadow: "rgb(92 134 203 / 18%) 0px 3px 30px"
    },
    details: {
        color: "text.main",
        fontSize: "1rem",
        fontFamily: Bold,
        marginBottom: "12px"
    },
    UnitStack: {
        alignItems: "start",
        justifyContent: "space-between",
        backgroundColor: "#FDEEE9",
        padding: "13px",
        borderRadius: "4px",
        marginTop: "10px",
        height:"87px"
    },
    imgStack: {
        height: "105px",
        backgroundImage: "url(/images/parkingSlotCarBGSVG.svg)",
        backgroundRepeat: "no-repeat",
        alignItems: "start",
        justifyContent: "space-between",
        backgroundColor: "#FDEEE9",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "13px",
        borderRadius: "4px",
        marginTop: "10px"
    },
    imgStackArabic: {
        height: "120px",
        backgroundImage: "url(/images/parkingFourWheels.svg)",
        backgroundRepeat: "no-repeat",
        alignItems: "start",
        justifyContent: "space-between",
        backgroundColor: "#FDEEE9",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "13px",
        borderRadius: "4px",
        marginTop: "10px"
    },
    subText: {
        fontSize: "0.875rem",
        fontFamily: Bold,
        color: "text.main",
        whiteSpace:"pre-wrap !important",
    },
    headText: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        marginBottom: "10px",
        color: "text.main",
    },
    subBigText: {
        fontSize: "0.78125",
        fontFamily: Bold,
        color: "text.main",
        whiteSpace:"pre-wrap !important",
        width:"80%"

    },
    vehicleImg: {
        height: "180px"
    }

}