import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import React from "react";
import { SelectStyles } from './style'


const getValue = (value, options) => {
  if (value && options?.length > 0) {
    return options.filter((val) => val.value === value)?.[0];
  } else {
    return value;
  }
};

export const SimpleSelect = ({
  placeholder = "",
  id = "",
  options = [],
  disabled = false,
  handleChangeSelect = () => false,
  value = "",
}) => {


  const giveMeValueLabel = (option) => {
    return `${option?.value}`;
  };


  return (
    <Box sx={SelectStyles?.margin}>
      <Autocomplete
        id={id}
        sx={SelectStyles?.autocomplete}        
        placeholder={placeholder}
        options={options}
        getOptionLabel={(option) => giveMeValueLabel(option)}
        disabled={disabled}
        onChange={(event, value) =>
          handleChangeSelect(value?.value)
        }
        defaultValue={getValue(value, options)}
        value={{value:value}}
        disableClearable
        disablePortal

        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            variant="outlined"
            placeholder={placeholder}
            autoComplete={"true"}
          />
        )}
      />
    </Box>
  );
};
