
import { ContractsStyle } from './style'
import { Box, Container } from "@mui/material";
import React from "react";
import { DialogDrawer, UseDebounce, LoderSimmer, SearchBarFilter, TopNavBar } from '../../components';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../router/routes';
import { useLazyGetContractListingQuery, useLazyGetOwnerAccountsQuery } from '../../redux/services';
import Filter from '../../components/filter';
import NoData from '../../components/noData';
import { ContractCard } from './contractCard';
import { errorRoute } from '../../utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowDimensions from "../../useDimension";

export const Contracts = ({ t = () => false }) => {
    const size = useWindowDimensions();

    const [state, setState] = React.useState({
        lisitingItems: [],
        simmer: true,
        offset: 0,
        drawer: false
    })
    const [search, setSearch] = React.useState("")
    const [selectedFilter, setSelectedFilter] = React.useState({
        accounts: [],
        filterAccounts: []
    })
    const [offset , setOffSet]= React.useState(0)
    const [simmer, setSimmer] = React.useState(false)

    const filterDataJson = [
        {
            id: "1",
            title: t("Accounts"),
            key: "accounts",
            filterType: "CHECKBOX",
            values: selectedFilter?.accounts,
        }
    ];
    const navigate = useNavigate()
    const debounce = UseDebounce()

    const [contractsListingAPI, { error }] = useLazyGetContractListingQuery()
    const [ownerAccountsAPI, { error: isError }] = useLazyGetOwnerAccountsQuery()


    const contractsListing = async (payload, isFilter) => {
        if (!isFilter) {
            setSimmer(true)
        }
        const res = await contractsListingAPI(payload)
        setState({
            ...state,
            lisitingItems: isFilter ?
                state?.lisitingItems?.concat(res?.data?.data) : res?.data?.data,
            drawer: false
        })
        setSimmer(false)

    }

    const OwnerAccounts = async () => {
        const OwnerRes = await ownerAccountsAPI()

        const filterAccounts = OwnerRes?.data?.data?.map(i => {
            return {
                label: i?.account_no,
                value: i?.id
            }
        })

        const accounts = OwnerRes?.data?.data?.map((e) => {
            return {
                label: e?.account_no,
                value: e?.id
            }
        })

        setSelectedFilter({
            accounts: accounts,
            filterAccounts: filterAccounts
        })
    }

    const handleContractDetails = async (e) => {
        navigate(AppRoutes?.contractDetails, { state: e?.id })
    };
    const goBack = () => {
        navigate.goBack()
    }

    const handleFilterOpen = () => {
        setState({
            ...state, drawer: !state?.drawer
        });
    }

    const filterdrawerclose = () => {
        setState({
            ...state, drawer: false
        });
    };

    const applyFilter = async (data) => {
        if (data?.accounts?.length > 0) {
            await contractsListing({
                offset: offset,
                limit: 1,
                search: state?.search ?? "",
                account_id: data?.accounts,
            }, false)

            setSelectedFilter({
                ...selectedFilter,
                filterAccounts: data?.accounts?.map((x) => {
                    return {
                        value: x,
                        label: x
                    }
                })
            })
        }
    }

    const handleChange = (key, value) => {
        setSearch(value)
        debounce(
            async () => {
                await contractsListing({
                    "offset": offset,
                    "limit": 10,
                    search: value ?? ""
                })
            },
            800
        );
    }

    React.useLayoutEffect(() => {
        contractsListing({
            offset: offset,
            limit: 10,
            search: search ?? ""
        }, false)

        OwnerAccounts()

        // eslint-disable-next-line
    }, [])


    const fetchMoreData = () => {
        contractsListing({
            offset: 0,
            limit: 10,
            search: search ?? ""
        }, true)

        setOffSet(offset + 10);
    }

    React.useEffect(() => {
        if (error?.status === 423 || isError?.status === 423) return errorRoute()
    }, [error, isError])

    return (
        <Box sx={ContractsStyle?.parentBox}>
            <Container
                maxWidth="sm"
                sx={{ padding: "0px !important" }}
            >
                <Box sx={{
                    paddingTop: '6.6rem', // Default style
                    '@media screen and (min-width: 600px)': {
                        paddingTop: '7.7rem', // Media query style for `md` breakpoint
                    },
                }}>
                    <Box sx={ContractsStyle?.Topnav}>
                        <Container maxWidth="sm"
                            sx={{ padding: "0px !important" }}>
                            <TopNavBar isDirect title={t("Contracts")} position="absolute"
                                isBack backFuction={goBack} />
                            <Box sx={{ padding: "16px", backgroundColor: "#F6F6F6" }}>
                                <SearchBarFilter
                                    paddingRight
                                    placeholder={t("Search")}
                                    filterIcon
                                    value={search ?? ""}
                                    handleChange={(e) => handleChange("search", e?.target?.value)}
                                    handleFilterOpen={handleFilterOpen} />
                            </Box>
                        </Container>
                    </Box>

                    <Box sx={ContractsStyle?.root}>

                        <Box >
                            {
                                simmer ?
                                    <LoderSimmer card count={8} size={100} variant={"rectangular"} animation={"wave"} /> :
                                    <>
                                        {state?.lisitingItems?.length > 0 ?
                                        <Box sx={ContractsStyle?.entireBox}>
                                              <InfiniteScroll
                                              dataLength={state?.lisitingItems?.length ?? 0}
                                              next={fetchMoreData}
                                              hasMore={true}
                                              height={size?.height - 160}
                                              >
                                            
                                                {
                                                    state?.lisitingItems?.map((e, i) => {
                                                        return (
                                                            <ContractCard
                                                                t={t}
                                                                contract_no={e?.contract_no}
                                                                unitIdColor={e?.unitIdColor}
                                                                account_no={e?.account_no}
                                                                start_date={e?.start_date}
                                                                end_date={e?.end_date}
                                                                isImage={true}
                                                                handleContractDetails={() =>
                                                                    handleContractDetails(e)}
                                                                hidden={state?.lisitingItems?.length === i - 1 ? false : true}
                                                            />
                                                        )
                                                    })
                                                }
                                                  </InfiniteScroll>
                                            </Box>
                                             :
                                            <NoData style={{
                                                display: "flex", alignItems: "center",
                                                height: "calc(100vh - 220px)", backgroundColor: "#F6F6F6"
                                            }} />
                                        }
                                    </>
                            }
                        </Box>
                    </Box>
                </Box>
                {/* filter Drawer */}
                <DialogDrawer
                    maxWidth="sm"
                    open={state?.drawer}
                    header={t("Filter By")}
                    padding="0px"
                    component={
                        <Box>
                            <Filter
                                t={t}
                                filters={{
                                    accounts: selectedFilter?.filterAccounts?.map(e => {
                                        return e?.value
                                    })
                                }}
                                filterDataJson={filterDataJson}
                                onGoBack={filterdrawerclose}
                                selectedList={filterDataJson[0]}
                                onApply={applyFilter}
                                onClose={filterdrawerclose}
                            />
                        </Box>
                    }
                    onClose={() => filterdrawerclose()}
                />
            </Container>
        </Box>


    )
}

