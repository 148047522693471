export const AppRoutes = {
  home: "/",
  login: "/login",
  createInvestment: "/createInvestment",
  profileView: "/profileView",
  profileEdit: "/profileEdit",
  addressEdit: "/addressEdit",
  properties: "/properties",
  statistics: "/statistics",
  request: "/request",
  requestView: "/requestView",
  kycInformation: "/kycInformation",
  getSupport: "/getSupport",
  getSupportDetailed: "/getSupportDetailed",
  invoices: "/invoices", 
  createSpending: "/createSpending",
  kycAddIdProof: "/kycAddIdProof",
  vacantunit: "/vacantunit",
  maintanceRequest: "/maintanceRequest",
  inspection: "/inspection",
  inspectiondetails: "/inspectiondetails",
  assets: "/assets",
  companySelect: "/companySelect",
  contracts: "/contracts",
  contractDetails: "/contractDetails",
  generalRequest:"/generalRequest",
  amenities:"/amenities",
  utilities:"/utilities",
  utilitiesList:"/utilitiesList",  
  caseManagement:"/casemanagement",
  correspondence:"/correspondence",
  filemanager:"/filemanager",
  fileManagerDocs:"/fileManagerDocs",
  parkingSlot: "/parkingSlot",
  welcomepage: "/welcome",
  setup:"/setup",
  spending: "/spending", //
  investment:"/investment", //
  announcement:"/announcement",
  announcementView:"/announcement_details",
  vehicle:"/vehicle",
  vehicleView:"/vehicleView",
  addVehicle:"/addVehicle",
  contacts:"/contacts",
  createContacts:"/createContacts",
  payment:"/payment",
  addons:"/addons",
  addonsDetailed:"/addonsDetailed",
  restoreErrorHandling:"/restoreErrorHandling",
  landlordApproval: "/landlordApproval" ,
  chooseAppliance: "/chooseAppliance" ,
  contractHistory: "/contractHistory",
  viewImage: "/viewImage",
  qrCode: "/qrCode",
  explore: "/explore",
  BOMDetails:"/BOMDetails",
  pay_request_invoice_list: "/pay_request_invoice_list",
  surveyList: "/survey_list",
  surveyForm: "/survey_form",
};

export const BackendRoutes = {
  weather_get_current_weather: "/weather/get_current_weather",
  invoice_maintenance_invoice: "/invoice/maintenance_invoice",
  survey_list: "survey/list",
  survey_get: "survey/get",
  survey_upsert_values: "survey/upsert_values",
};