import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from './style';
import { Label } from "../label";
import { ImageCard } from '../imageCard';
import UploadIcon from "../../assets/uploadIcon";
import { formatSizeUnits, Regular, SemiBold } from '../../utils';
import { useLazyImageMutationQuery } from "../../redux/services";
import { useDispatch } from "react-redux";
import { openBackdrop, closeBackdrop } from "../../redux/slices/backdrop"
import { useSnackbar } from "notistack";
import Compressor from "compressorjs";

export const MultiImageUpload = (props) => {
    const { t = () => false } = props;
    const [imageMutation] = useLazyImageMutationQuery()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch();
    // updateState Function
    const updateState = async (e) => {
        dispatch(openBackdrop("...Loading"))
        const fileData = []
        let files = e.target.files;
        // let filesArray = {
        //     // priority: i + 1,
        //     url: image?.data,
        //     // asset_type: type,
        //     file_meta: {
        //         name: files[i]?.name?.split(".")?.[0],
        //         size: formatSizeUnits(files[i]?.size ?? 0),
        //         type: files[i]?.name?.split(".")?.[1],
        //     }
        // }

        let filesArray = Array.from(e.target.files).map((val, i) => {
            const formData = new FormData();
            formData.append("files", e?.target?.files[i]);

            const image = imageMutation(formData)
            return {
                url: image?.data,
                file_meta: {
                    name: files[i]?.name?.split(".")?.[0],
                    size: formatSizeUnits(files[i]?.size ?? 0),
                    type: files[i]?.name?.split(".")?.[1],
                }
            }
        });
        if (e?.target?.files && props?.value?.length < 5) {

            if (props?.onlyFiveFilesUpload) {
                if (filesArray?.length <= 5 && props?.value.concat(filesArray)?.length <= 5) {
                    for (let i = 0; i < files.length; i++) {
                        const res = await singleFileUpload(files[i])
                        if (res === true) {

                            const form_data = new FormData();
                            form_data.append("files", e?.target?.files[i]);

                            const img = await imageMutation(form_data)

                            const filesArray = {
                                // priority: i + 1,
                                url: img?.data,
                                // asset_type: type,
                                file_meta: {
                                    name: files[i]?.name?.split(".")?.[0],
                                    size: formatSizeUnits(files[i]?.size ?? 0),
                                    type: files[i]?.name?.split(".")?.[1],
                                }
                            }
                            fileData.push(filesArray)
                            dispatch(closeBackdrop(""))

                        }
                    }
                }
                else {
                    dispatch(closeBackdrop(""))
                    return enqueueSnackbar("You can't upload more than 5 images", {
                        variant: "error",
                        anchorOrigin: { horizontal: "center", vertical: "top" },
                        className: { fontFamily: SemiBold }
                    })                    
                }
            } else {
                for (let i = 0; i < files.length; i++) {
                    const res = await singleFileUpload(files[i])
                    if (res === true) {
                        const form_data = new FormData();
                        form_data.append("files", e?.target?.files[i]);

                        const img = await imageMutation(form_data)

                        const filesArray = {
                            // priority: i + 1,
                            url: img?.data,
                            // asset_type: type,
                            file_meta: {
                                name: files[i]?.name?.split(".")?.[0],
                                size: formatSizeUnits(files[i]?.size ?? 0),
                                type: files[i]?.name?.split(".")?.[1],
                            }
                        }

                        fileData.push(filesArray)
                        dispatch(closeBackdrop(""))

                    }
                }
            }
        }
        else {
            dispatch(closeBackdrop(""))
            return enqueueSnackbar("5 / 5 images are Uploaded , limit reached", {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
                className: { fontFamily: SemiBold }
            })
        }
        props.onChange([...props?.value ?? [], ...fileData])

    }
    // Delete Image function
    const deleteImg = async (i) => {
        // eslint-disable-next-line
        const deletedArray = await props?.value?.map((x, index) => {
            if (index !== i) {
                return x
            }
        }).filter(Boolean)

        props.onDelete(deletedArray ?? [])
    };

    const singleFileUpload = async (file) => {
        return new Promise(async (resolve, reject) => {
            try {
                const allowed_image_size = 5;
                let file_type = file?.type.split("/")?.[0]
                if (file_type === "image") {
                    new Compressor(file, {
                        quality: 0.8,
                        async success(result) {
                            let bytes = result?.size
                            let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
                            if (finalSize <= allowed_image_size) {
                                resolve(true)
                            } else {
                                return enqueueSnackbar("Please upload less then 5 MB file", {
                                    variant: "error",
                                    anchorOrigin: { horizontal: "center", vertical: "top" },
                                    className: { fontFamily: SemiBold }
                                })
                            }
                        },
                        error(err) {
                            return enqueueSnackbar("Something Went Wrong", {
                                variant: "error",
                                anchorOrigin: { horizontal: "center", vertical: "top" },
                                className: { fontFamily: SemiBold }
                            })
                        }
                    })
                } else {
                    let bytes = file?.size
                    let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
                    if (finalSize <= allowed_image_size) {
                        resolve(true)
                    } else {
                        return enqueueSnackbar("Please upload less then 5 MB file", {
                            variant: "error",
                            anchorOrigin: { horizontal: "center", vertical: "top" },
                            className: { fontFamily: SemiBold },
                        })
                    }
                }
            } catch (error) {
                return enqueueSnackbar("Something Went Wrong", {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                    className: { fontFamily: SemiBold },
                })
            }
        });
    };

    return (
        <Box sx={styles.root}>
            {props?.label && <Label label={props?.label} labelStyle={props?.labelStyle} isRequired={props?.isRequired} />}
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={props?.sizexs ?? 12}>
                        <label className="btn_label">
                            <Box sx={{ ...styles.fileroot, ...props?.customStyle }}>
                                <input
                                    accept={props?.accept}
                                    type="file"
                                    name="myImage"
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                        updateState(e);
                                    }}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                    multiple
                                />
                                <Stack spacing={2} direction={"column"} alignItems={"center"} justifyContent={"center"}>
                                    <UploadIcon />
                                    <Typography sx={styles.uploadTittle}>
                                        {props?.text ?? t("Upload image here")}
                                    </Typography>
                                </Stack>
                            </Box>
                        </label>
                    </Grid>
                </Grid>
                {props?.value?.length > 0 ?
                    <Stack direction={"column"}>
                        {props?.value?.map((val, index) => {
                            return (
                                <>
                                    <Box sx={styles.images}>
                                        <Box sx={styles.imgContainer} key={index}>
                                            <ImageCard
                                                showText={true}
                                                image={val?.url ?? ""}
                                                onClick={() => deleteImg(index)}
                                                imageName={val?.file_meta?.name ?? ""}
                                                storage={val?.file_meta?.size ?? ""}
                                                type={val?.file_meta?.type ?? ""}
                                            />
                                            {index < props.value.length - 1 && <Divider sx={styles.divider} />}
                                        </Box>
                                    </Box>
                                </>
                            );
                        })}
                    </Stack>
                    : ""
                }
            </Box>
            {props?.isError && (
                <Typography variant={"caption"} color={"error"} fontFamily={Regular}>
                    {props?.errorMessage}
                </Typography>
            )}
        </Box>
    );
};
