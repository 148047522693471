import * as React from "react"

export const HistoryBill = (props) => (
  <svg
    data-name="Group 96530"
    xmlns="http://www.w3.org/2000/svg"
    width={31.971}
    height={32}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 53019" fill="none" d="M0 0h31.971v32H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 96529" clipPath="url(#a)">
      <path
        data-name="Path 9110"
        d="M5.26.052a3.965 3.965 0 0 1 1.11-.02q9.2-.006 18.4 0a7.051 7.051 0 0 1 7.2 7.1v20.669a4.041 4.041 0 0 1-4.2 4.193q-7.319.013-14.638 0a4.047 4.047 0 0 1-4.149-3.428 5.229 5.229 0 0 1-.052-.9q-.005-5.263 0-10.526a1.038 1.038 0 0 1-.086-.61V4.83a4.72 4.72 0 0 0-.914-2.972A4.418 4.418 0 0 0 5.666.309C5.511.261 5.305.279 5.26.052"
        fill="#00a95b"
      />
      <path
        data-name="Path 9111"
        d="M5.26.052a7.415 7.415 0 0 1 1.677.73A4.414 4.414 0 0 1 8.883 3.97a16.868 16.868 0 0 1 .056 2.153q.009 5.28 0 10.561c0 .151-.008.3-.012.452l-4.737-.01a4.092 4.092 0 0 1-4.132-3.41 2.97 2.97 0 0 1-.048-.554c0-2.612-.028-5.225.011-7.837a5.506 5.506 0 0 1 5-5.273c.08-.007.162 0 .243 0"
        fill="#145638"
      />
      <path
        data-name="Path 9112"
        d="M20.447 13.418c1.487 0 2.974-.005 4.461 0a1.1 1.1 0 0 1 1.09.854 1.122 1.122 0 0 1-.535 1.22 1.254 1.254 0 0 1-.636.154h-8.748a1.128 1.128 0 0 1-1.149-.785 1.1 1.1 0 0 1 .4-1.236 1.246 1.246 0 0 1 .791-.21h4.322Z"
        fill="#fefefe"
      />
      <path
        data-name="Path 9113"
        d="M20.485 17.879c1.476 0 2.951-.005 4.426 0a1.1 1.1 0 0 1 1.088.857 1.125 1.125 0 0 1-.54 1.219 1.33 1.33 0 0 1-.671.152h-8.679a1.139 1.139 0 0 1-1.181-.789 1.1 1.1 0 0 1 .408-1.235 1.253 1.253 0 0 1 .791-.208h4.357Z"
        fill="#fefefe"
      />
      <path
        data-name="Path 9114"
        d="M20.454 22.341c1.487 0 2.974-.005 4.461 0a1.1 1.1 0 0 1 1.088.856 1.125 1.125 0 0 1-.539 1.219 1.328 1.328 0 0 1-.671.153h-8.679a1.139 1.139 0 0 1-1.181-.789 1.1 1.1 0 0 1 .408-1.235 1.252 1.252 0 0 1 .791-.208h4.322Z"
        fill="#fefefe"
      />
      <path
        data-name="Path 9115"
        d="M18.244 8.954h2.126a1.116 1.116 0 1 1 0 2.227q-2.161.013-4.321 0a1.122 1.122 0 0 1-1.176-1.119 1.107 1.107 0 0 1 1.176-1.108c.732-.007 1.464 0 2.2 0"
        fill="#fefefe"
      />
    </g>
  </svg>
)

