import { Box, Container, Grid, Typography, InputAdornment, IconButton, Stack, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { TextBox, Buttons } from "../../components";
import { LightTooltip, SetUp_style } from "./style"
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useLazyInitialRenderQuery, useLazyUpdatePasswordQuery } from "../../redux/services";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { AppRoutes } from "../../router/routes";
import PasswordInfo from "../../components/passwordInfo";
import { errorRoute } from "../../utils"
import { initital_screen_state } from "../../utils/login/utils";
import { TitleBarNew } from "../../components/titlebarNew";
import { Label } from "../../components/label";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { InfoIcon } from "./assets/info_icon";
import { getCurrentBuildNumber } from "../../utils/version";

const InitialState = {
    email: "",
    enterPassword: "",
    confirmPassword: "",
    error: {
        email: "",
        enterPassword: "",
        confirmPassword: "",
    },
};

export const Setup = ({ t = () => false }) => {
    const [app_version, set_app_version] = React.useState(null);
    const [account, setAccount] = React.useState({ ...InitialState });
    const search = useLocation().search;
    const { enqueueSnackbar } = useSnackbar()
    const [updatePassword, { isLoading, error }] = useLazyUpdatePasswordQuery()
    const navigate = useNavigate()
    const [initialRender] = useLazyInitialRenderQuery();
    const welcomePageAuthToken = new URLSearchParams(search).get(
        "welcomeAuthToken"
    );
    const [show, setShow] = React.useState({
        show1: false,
        show2: false
    })
    const [is_loading, set_is_loading] = useState(true);
    const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
    const [screen_state, set_screen_state] = React.useState(initital_screen_state);
    const [isInfoOpen, setIsInfoOpen] = React.useState(false)

    const initialLoad = () => {
        getInitialRender();
    }

    const getInitialRender = () => {
        initialRender({}).then((res) => {
            let is_custom_domain = res?.data?.data?.is_custom_domain;
            let _ = res?.data?.data?.render_data?.[0]
            if (_) {
                let temp_screen_state = {
                    ...screen_state,
                    [_?.id && "id"]: _?.id,
                    [_?.region && "region"]: _?.region,
                    [_?.login_logo && "logo"]: _?.login_logo,
                    [_?.web_background && "web_background"]: _?.web_background,
                    [_?.mobile_background && "mob_background"]: _?.mobile_background,
                    [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
                    [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
                    [_?.flag && "flag"]: _?.flag,
                    [_?.domain && "domain"]: _?.domain,
                    [is_custom_domain && "is_custom_domain"]: is_custom_domain,
                }
                set_screen_state(temp_screen_state);
            }
            set_is_loading(false);
        }).catch(() => {
            enqueueSnackbar(t('Some thing Went Wrong'), {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" }

            })
            set_is_loading(false);
        });
    }

    const updateShow = (key, value) => {
        setShow({ ...show, [key]: value })
    }
    // const decoded =jwt_decode(welcomePageAuthToken);
    const [decoded, setDecoded] = React.useState(null);
    React.useEffect(() => {
        getCurrentBuild();
        if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
            setDecoded(jwt_decode(welcomePageAuthToken));
            initialLoad();
        }
        // eslint-disable-next-line
    }, []);

    const getCurrentBuild = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const [temp_app_version] = await Promise.all([getCurrentBuildNumber()]);
                set_app_version(temp_app_version);
            } catch (error) {
                reject(error);
            }
        });
    }
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const updateState = (key, value) => {
        let error = account.error;
        error[key] = "";
        setAccount({ ...account, [key]: value, error });
    };
    const isIamValideToCreateAccount = () => {
        let isValid = true;
        let error = account.error;

        const is_password_strong_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
        const is_password_strong = is_password_strong_regex.test(account.enterPassword)

        //Checking enterPassword
        if (account.enterPassword.length === 0) {
            isValid = false;
            error.enterPassword = t("Please Enter Password");
        }

        //Checking confirmPassword
        if (account.confirmPassword.length === 0) {
            isValid = false;
            error.confirmPassword =
                t("Please Enter Confirm Password")
        }

        if (account.confirmPassword.length > 0 && account?.enterPassword.length > 0) {
            if (

                account?.enterPassword.trim() !== account?.confirmPassword.trim()
            ) {
                isValid = false;
                error.confirmPassword = enqueueSnackbar(`${t("Password and Confirm Password Must be same")}`, {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" }

                })
            }
        }
        if (
            account.email &&
            account.enterPassword !== "" &&
            account.confirmPassword !== ""
        ) {
            if (account.enterPassword === account.confirmPassword) {
                isValid = true;
            }
        }

        //Checking enterPassword
        if (account.enterPassword.length === 0) {
            isValid = false;
            error.enterPassword = t("Please Enter Password");
        } else if (!Boolean(is_password_strong)) {
            isValid = false;
            error.enterPassword = t("Password Must be strong");
        }

        setAccount({ ...account, error });
        return isValid;
    };


    const onSendAccountBtnClicked = async () => {
        if (isIamValideToCreateAccount()) {
            const data = await updatePassword({ password: account.confirmPassword.trim() })
            if (data?.data?.status) {
                enqueueSnackbar(data?.data?.message, {

                    variant: "success",
                    anchorOrigin: { horizontal: "center", vertical: "top" }

                })
                localStorage.clear();
                return navigate(AppRoutes.login + "?welcomeAuthToken=" + welcomePageAuthToken)
            }
            else {
                enqueueSnackbar(t('Some thing Went Wrong'), {

                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "top" }

                })
            }


        } else {
            return false;
        }
    };


    React.useEffect(() => {
        if (error?.status === 423) return errorRoute()
    }, [error])

    const handleGoBack = () => {
        navigate(-1);
    }

    const handleImageOnLoad = () => {
        setIsBackgroundImageLoading(false);
    }

    const handleImageOnError = () => {
        set_screen_state({ ...screen_state, mob_background: initital_screen_state?.mob_background });
    }

    return (
        is_loading
            ? <></>
            : <>
                <TitleBarNew is_back_button title={t("Set Up New Account")} goBack={handleGoBack} />
                <Container maxWidth="sm" style={{ padding: 0, height: "calc(100vh - 54px)", }}>
                    <Grid container>
                        <Grid
                            item
                            sx={SetUp_style?.content}>
                            <Grid item xs={12}>
                                <Box sx={SetUp_style?.mob_background}>
                                    {isBackgroundImageLoading && <Skeleton variant="rounded" className={SetUp_style?.mob_background_image} />}
                                    <img
                                        style={{
                                            ...SetUp_style?.mob_background_image,
                                            display: isBackgroundImageLoading ? "none" : "block",
                                        }}
                                        src={screen_state?.mob_background} alt=""
                                        onLoad={handleImageOnLoad}
                                        onError={handleImageOnError} />
                                </Box>
                            </Grid>
                            <Box height={"16px"} />
                            <Grid item xs={12}>
                                <Typography sx={SetUp_style?.text}>
                                    {t("Set up your resident account details below")}
                                </Typography>
                            </Grid>
                            <Box height={"16px"} />
                            {!Boolean(screen_state?.is_custom_domain) &&
                                <>
                                    <Label label={t("Region")} isRequired />
                                    <Stack sx={SetUp_style?.flag_dropdown}
                                        direction={"row"} columnGap={"8px"}
                                        justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack direction={"row"} columnGap={"8px"}
                                            justifyContent={"space-between"} alignItems={"center"}>
                                            <img style={SetUp_style?.region_flag} src={screen_state?.flag} alt="" />
                                            <Typography sx={SetUp_style?.region_text}>
                                                {screen_state?.region}
                                            </Typography>
                                        </Stack>
                                        <KeyboardArrowDownRoundedIcon fontSize="small" color="#4E5A6B" />
                                    </Stack>
                                    <Box height={"16px"} />
                                </>
                            }
                            <Grid item xs={12}>
                                <TextBox
                                    type="text"
                                    label={t("Email Id")}
                                    placeholder={t("Enter your Email Id")}
                                    value={decoded && decoded.email_id ? decoded.email_id : ""}
                                    isReadonly
                                    disabled
                                    isRequired
                                    isError={account.error.email.length > 0}
                                    errorMessage={account.error.email}
                                    sx={SetUp_style.textbox}
                                />
                            </Grid>
                            <Box height={"16px"} />
                            <Grid item xs={12}>
                                <TextBox
                                    //value={account.enterPassword}
                                    value={account?.enterPassword}
                                    onChange={(e) => updateState("enterPassword", e.target.value)}
                                    label={t("Password")}
                                    placeholder={t("Enter Password")}
                                    //helperText={account?.error?.password}
                                    isError={account.error.enterPassword.length > 0}
                                    errorMessage={account.error.enterPassword}
                                    isRequired
                                    sx={SetUp_style.textbox}
                                    type={show?.show1 ? "text" : "password"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={() => updateShow("show1", !show?.show1)}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {show?.show1 ? (
                                                    <RemoveRedEyeOutlinedIcon />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }

                                />
                            </Grid>
                            <Box height={"16px"} />
                            <Grid item xs={12}>
                                <TextBox
                                    value={account.confirmPassword}
                                    onChange={(e) =>
                                        updateState("confirmPassword", e.target.value)
                                    }
                                    label={t("Confirm Password")}
                                    placeholder={t("Confirm Password")}
                                    //helperText={account?.error?.confirmPassword}
                                    isError={account.error.confirmPassword.length > 0}
                                    errorMessage={account.error.confirmPassword}
                                    isRequired
                                    sx={SetUp_style.textbox}
                                    type={show?.show2 ? "text" : "password"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={() => updateShow("show2", !show?.show2)}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {show?.show2 ? (
                                                    <RemoveRedEyeOutlinedIcon />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }

                                />
                            </Grid>
                            <Box height={"10px"} />
                            <Box display={"flex"} justifyContent={"end"}>
                                <LightTooltip title={<PasswordInfo t={t} />} placement="left" open={isInfoOpen} arrow>
                                    <Box
                                        onClick={() => setIsInfoOpen(!isInfoOpen)}
                                        onMouseEnter={() => setIsInfoOpen(true)}
                                        onMouseLeave={() => setIsInfoOpen(false)}>
                                        <InfoIcon />
                                    </Box>
                                </LightTooltip>
                            </Box>
                            <Box height={"120px"} />
                        </Grid>
                    </Grid>
                    <Box sx={SetUp_style?.btnParent}>
                        <Container maxWidth="sm" style={{ padding: "0px !important" }}>
                            <Box sx={SetUp_style?.btnGrid} xs={12} >
                                <Buttons
                                    fullWidth
                                    variant="contained"
                                    sx={SetUp_style?.btn}
                                    onClick={onSendAccountBtnClicked}
                                    type="submit"
                                    loading={isLoading}
                                    btnName={t("Set Password")}
                                />
                            </Box>
                            <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                                justifyContent={"space-between"} alignItems={"center"}>
                                <img style={SetUp_style?.powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                                {app_version &&
                                    <Typography sx={SetUp_style?.version_no}>
                                        {`V ${app_version}`}
                                    </Typography>}
                            </Stack>
                            <Box height={"16px"} />
                        </Container>

                    </Box>
                </Container>
            </>
    );
};
