

export const Spendings_style = {
    container: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        position: "relative",
        height: "100vh",
        overflow: "hidden"
    },
    Topnav: {
        position: 'sticky',
        top: 0,
        zIndex: 999
    },
    padding: {
        padding: '16px',
        overflow: "auto",
        height: "calc(100vh - 75px)"
    },
    parentBox: {
        position: "fixed",
        right: "0",
        left: "0"

    }
}