import * as React from "react"

export const ListView = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.5}
    height={14}
    color={props?.color}
    {...props}
  >
    <path
      data-name="Path 96624"
      d="M1.969 0A1.969 1.969 0 0 0 0 1.969v2.187a1.969 1.969 0 0 0 1.969 1.969h2.187a1.969 1.969 0 0 0 1.969-1.969V1.969A1.969 1.969 0 0 0 4.156 0Zm-.656 1.969a.656.656 0 0 1 .656-.656h2.187a.656.656 0 0 1 .656.656v2.187a.656.656 0 0 1-.656.656H1.969a.656.656 0 0 1-.656-.656ZM8.094.875a.656.656 0 0 0 0 1.313h8.75a.656.656 0 0 0 0-1.312Zm0 2.625a.656.656 0 0 0 0 1.313h6.125a.656.656 0 0 0 0-1.312ZM1.969 7.875A1.969 1.969 0 0 0 0 9.844v2.188A1.969 1.969 0 0 0 1.969 14h2.187a1.969 1.969 0 0 0 1.969-1.969V9.844a1.969 1.969 0 0 0-1.969-1.969Zm-.656 1.969a.656.656 0 0 1 .656-.656h2.187a.656.656 0 0 1 .656.656v2.188a.656.656 0 0 1-.656.656H1.969a.656.656 0 0 1-.656-.656ZM8.094 8.75a.656.656 0 0 0 0 1.313h8.75a.656.656 0 1 0 0-1.312Zm0 2.625a.656.656 0 0 0 0 1.313h6.125a.656.656 0 0 0 0-1.312Z"
      fill={props?.color}
    />
  </svg>
)
