import { SemiBold } from "../../utils";

export const otherFeaturesCardStyles = {
    featureCard: {
        backgroundColor: "#FFFFFF",
        padding: "12px",
        borderRadius: "8px",
        cursor: "pointer"
    },
    title: {
        marginInlineStart: "12px",
        fontFamily: SemiBold,
        fontSize: "14px",
        color: "text.main"
    }
}