import { makeStyles } from "@mui/styles";
import { Bold, FontSizeCalc, Regular, SemiBold } from "../../../utils";
export const Announcement_style = {
  body: {
    width: "auto",
    height: "384px",
    backgroundColor: "error.contrastText",
    boxShadow: "0px 3px 30px #5C86CB2E",
    padding: "18px 16px",
  },
  title: {
    height: "19px",
    justifyContent: "end",
    fontFamily: Bold,
    fontSize: "0.875rem",
    color: "border.primary",
  },
  unopened: {
    textAlign: "left",
    fontFamily: Bold,
    fontSize: "0.75rem",
    color: "primary.main",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  categoryBg: {
    width: "65px",
    height: "22px",
    backgroundColor: "primary.main",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "6px",
  },
  category: {
    fontFamily: Regular,
    color: "error.contrastText",
    fontSize: "0.75rem",
  },
  complaint: {
    marginBlock: 1 / 2,
    fontFamily: Bold,
    fontSize: "0.875rem",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical",
    color: "error.contrastText",
  },
  postedOn: {
    textAlign: "-webkit-match-parent",
    fontFamily: Regular,
    fontSize: "0.75rem",
    letterSpacing: "0px",
    color: "error.contrastText",
  },
  mainBg: {
    flex: 1,
    marginTop: "auto",
    padding: "16px",
    backgroundColor: "rgba(28, 27, 27,0.400)",
    width: "50%",
    height: "90%",
    boxShadow: "0 0 100px rgba(0,0,0,0.9) inset",
    borderRadius: "12px",
    position: "relative"
  },
  absolute: {
    position: "absolute",
    bottom: '18px',
    left: '15px',
    width: "100%",
  },
  absoluteArabic: {
    position: "absolute",
    bottom: '18px',
    left: '-15px',
    width: "100%",
  }
};
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:"white",
    overflow: "hidden",
    borderRadius:"8px",
    padding:"16px"
  },
  first_name:{
    fontSize:FontSizeCalc(14),
    color:theme.typography.color.primary,
    fontFamily:SemiBold
  },
  posted_at:{
    fontSize:FontSizeCalc(12),
    color:theme.typography.color.secondary,
    fontFamily:Regular
  },
  title:{
    fontSize:FontSizeCalc(14),
    color:"#071741",
    fontFamily:SemiBold,
    textWrap:"noWrap",
    textOverflow:"ellipsis",
    overflow:"hidden"
  },
  description:{
    fontSize:FontSizeCalc(12),
    color:theme.typography.color.secondary,
    fontFamily:Regular,
    margin:"0px !important"
  },
  seeMoreLessTextStyle: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.palette.primary.main,
    cursor: "pointer"
  },
  attachFile:{
    fontSize:FontSizeCalc(10),
    color:theme.typography.color.secondary,
    fontFamily:SemiBold
  },
  attachBox:{
    backgroundColor:"#F2F4F7",
    borderRadius:"10px",
    padding:"4px 12px",
    width:"fit-content"
  },
  dot:{
    borderRadius:"50%",
    backgroundColor:"#E4E8EE",
    width:"6px",
    height:"6px"
  },
  likeIcon:{
    fontSize:FontSizeCalc(14),
    color:theme.typography.color.secondary,
    fontFamily:SemiBold,
    marginLeft:"5px"
  },
  bottomFix:{
    position:"absolute",
    bottom:"10px"
  },
  bottomImageFix:{
    position:"absolute",
    bottom:0,
    left:0,
    right:0,
    padding:"16px"
  }
}))
