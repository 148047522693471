import * as React from "react"
const BlackArrowLeft = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    data-name="Group 117187"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#031629" data-name="Ellipse 40415" />
    <path
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.5}
      d="M14.501 6.938a.428.428 0 0 1-.132.3L9.106 12.5l5.266 5.265a.429.429 0 1 1-.608.605L8.197 12.8a.428.428 0 0 1 0-.605l5.566-5.566a.428.428 0 0 1 .737.307Z"
      data-name="icons8-expand-arrow (2)"
    />
    <path
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.5}
      d="M14.502 6.938a.428.428 0 0 1-.132.3L9.107 12.5l5.266 5.265a.429.429 0 1 1-.608.605L8.198 12.8a.428.428 0 0 1 0-.605l5.566-5.566a.428.428 0 0 1 .737.307Z"
      data-name="icons8-expand-arrow (2)"
    />
  </svg>
)
export default BlackArrowLeft
