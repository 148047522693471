import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const DashboardSlice = createSlice({
    name: 'Dashboard',
    initialState,
    reducers: {
        setDashboard(state, { payload }) { state = payload; }
    },
})

export const { setDashboard } = DashboardSlice.actions;
export default DashboardSlice.reducer;