import { Bold, SemiBold } from '../../utils/constants';
export const ChartBoxStyle = {
  header: {
    width: "100%",
    height: "100%",
    backgroundColor: "background.Teritary1",
    borderRadius: "12px 12px 0px 0px",
    display: "flex",
    alignItems: "center",
    padding: "10px 10px 10px 16px",
    justifyContent: "space-between"
  },
  body: {
    width: "100%",
    height: "100%",
    backgroundColor: "error.contrastText",
    borderRadius: "0px 0px 12px 12px",
    padding: {
      xs: "19px 18px 33px 12px",
      sm: "19px 18px 33px 12px"
    }
  },
  typoSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between'
  },
  typoValue: {
    // padding: "11px",
    fontSize: "0.875rem",
    textAlign: "right",
    fontFamily: Bold,
    whiteSpace:"inherit !important",
    overflow:"inherit !important",
    textOverflow:"inherit !important",
    marginInlineStart: "11px"
  },
  value: {
    fontSize: "0.875rem",
    // margin: "11px",
    fontFamily: SemiBold
  },
  title: {
    textAlign: "-webkit-match-parent",
    fontSize: "0.875rem",
    fontFamily: Bold,
    color: "border.primary"

  },
  marginLeft: {
    paddingLeft: {
      xs: '0px',
      sm: "24px",
      md: "0"
    }
  },
  marginTop: {
    marginBottom: "20px",
    display: 'flex',
    justifyContent: 'space-between',
    sm: {
      marginBottom: "0px",
    }
  },
  chartParent: {
    boxShadow: "0px 3px 30px #5c86cb2e",
    borderRadius: "12px"
  },
  containerFlex: {
    display: "flex",
    justifyContent: "space-between"
  },
  hidecomponentFlex: {
    display: "flex",
    justifyContent: "center"
  },
  popOver: {
    border: "1px solid #E4E8EE",
    "& div": {
      marginTop: "6px",
      borderRadius: "8px",
    }
  },
  padding: {
    paddingRight: "14px",
    cursor: "pointer",
    borderRadius: "8px !important",
    height: "36px",
    fontFamily: SemiBold,
    fontSize: "0.875rem",
    width: "134px !important",
    "& div": {
      "& input": {
        cursor: "pointer"
      }
    },
    [`& fieldset`]: {
      borderRadius: "8px",
      color: "#98A0AC",
      border: `1.5px solid #E2E2E2`,
      "&:hover": {
        border: `1px solid #E2E2E2`,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 12px !important",
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: "#fff",
      fontFamily: SemiBold,
      '&:hover fieldset': {
        borderColor: "#F15A29",
      },

    },
  },
  subLabel:{
    color:"#4E5A6B",
    fontSize:"0.75rem",
    fontFamily:SemiBold
  }

};
