import { Grid, Typography, Container, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { Bold } from '../../../utils/constants';
import { Dashboard, Services } from '../../../assets'
import { AppRoutes } from "../../../router/routes";
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Properties from "../../../assets/bottomNavbar/properties";
import Statistics from "../../../assets/bottomNavbar/statistic";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  containers: {
    padding: "12px",
    boxShadow: "0px -1px 6px #00000021",
    backgroundColor:"#fff"
  },
  tabStyle: {
    cursor: "pointer",
  },
  selectedLabelTextStyle: {
    fontSize: "0.75rem",
    color: theme.palette.primary.main,
    fontFamily: Bold
  },
  unselectedLabelTextStyle: {
    fontSize: "0.75rem",
    fontFamily: Bold,
    color: theme.palette.text.secondary,
  },
}));


const BottomNavbar = ({ t = () => false }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const list = [
    {
      label: t("Dashboard"),
      select: window.location.pathname === AppRoutes.home,
      icon: (
        <Dashboard
          color={
            window?.location?.pathname === AppRoutes?.home ? "#F15A29" : "none"
          }
          colors={
            window?.location?.pathname === AppRoutes?.home
              ? "#F15A29"
              : "#98a0ac"
          }
        />
      ),
      onClick: () => navigate(AppRoutes.home),
    },
    {
      label: t("Properties"),
      select: window?.location?.pathname === AppRoutes.properties,
      icon: (
        <Properties
          color={
            window?.location?.pathname === AppRoutes?.properties
              ? "#F15A29"
              : "#98a0ac"
          }
        />
      ),
      onClick: () => navigate(AppRoutes.properties),
    },
    {
      label: t("Statistics"),
      select: window?.location?.pathname === AppRoutes?.statistics,
      icon: (
        <Statistics
          color={
            window?.location?.pathname === AppRoutes.statistics
              ? "#F15A29"
              : "#98a0ac"
          }
        />
      ),
      onClick: () => navigate(AppRoutes.statistics),
    },
    {
      label: t("Service"),
      select: window?.location?.pathname === AppRoutes?.request,
      icon: (
        <Services
          color={
            window?.location?.pathname === AppRoutes?.request
              ? "#F15A29"
              : "#98a0ac"
          }
        />
      ),
      onClick: () => navigate(AppRoutes.request),
    },
  ];

  return (

    <Container maxWidth="sm" sx={{ padding: '0px !important' }} >
      <Grid
        container
        justifyContent={"space-around"}
        alignItems={"center"}
        className={classes.containers}
      >
         {
          list?.map((val, index) => {
            return (
              <Grid item xs={2} className={classes.tabStyle} onClick={val.onClick} key={index}>
                <center>
                  {val?.icon}
                  <Box className={val?.select ? classes.selectedLabelTextStyle :
                    classes.unselectedLabelTextStyle}>
                    <Typography variant="span">{val?.label}</Typography>
                  </Box>
                </center>
              </Grid>
            )
          })
        }


      </Grid>
    </Container>


  );
};
export default withTranslation('common')(BottomNavbar);
