import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Carousel, { consts } from "react-elastic-carousel";
import CloseIcon from "@mui/icons-material/Close";
import { generateImageUrl, img_size } from "../../../utils";
import  {Track_style}  from "./style"
import { DocumentViewer } from "../../../components";


const ImageAndPdfViewer = (props) => {
  const { assets, onClose, title } = props;
  const [data, setData] = React.useState({});
  const breakPoints = [{ width: 1, itemsToShow: 1 }];

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />;
    return (
      <IconButton
        size="small"
        style={{ fontSize: "0.78125" }}
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </IconButton>
    );
  };
  const hasAssets = Array.isArray(assets) && assets.length > 0;
  React.useEffect(() => {
    if (!hasAssets) {
      if (assets) {
        const extension = assets.split(".").pop();
        setData({
          type: extension,
          file_url: assets,
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        p={1}
        sx={Track_style.titleBar}
      >
        <Box flexGrow={1}>
          <Typography sx={Track_style.detailTitle}>
            {title}
          </Typography>
        </Box>
        <Box>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon style={{ fontSize: "0.78125" }} />
          </IconButton>
        </Box>
      </Box>
      <div>
        {assets ? (
          <>
            {hasAssets ? (
              <Carousel
                renderArrow={myArrow}
                showArrows={true}
                breakPoints={breakPoints}
              >
                {assets?.map((asset) => (
                  <>
                    {["image","image/jpeg", "image/png", "image/svg"].includes(asset?.meta_data?.file_type)  && (
                      <img
                        src={asset?.file_url} // --- pending
                        alt="asset"
                        height="500px"
                        width="100%"
                        style={{
                          objectFit:"contain",
                          borderRadius:"8px"
                        }}
                      />
                    )}
                    {(["application","application/pdf", "pdf"].includes(asset?.meta_data?.file_type))  && (
                        <div
                          style={{
                            height: "500px",
                            marginLeft: "10px",
                            width: "100%",
                          }}
                        >
                          <DocumentViewer url={asset?.file_url} />
                        </div>
                      )}
                      {asset?.meta_data?.file_type === "mp4" &&
                         <video
                         style={ {
                          height: "350px",
                          width: "100%",
                          borderRadius: "4px",
                          objectFit: "contain"
                        }}
                       >
                         <source src={asset?.file_url} type="video/mp4" />
                       </video>
                      }
                  </>
                ))}
              </Carousel>
            ) : (
              <>
                {data?.type === "pdf" ? (
                  <>
                    <div
                      style={{
                        height: "500px",
                        marginLeft: "10px",
                        width: "100%",
                      }}
                    >
                      <DocumentViewer url={data?.file_url} />
                    </div>
                  </>
                ) : (
                  <img
                    src={generateImageUrl(data?.file_url,img_size.medium_rectangle)}
                    alt=""
                    height="500px"
                    width="100%"
                    style={{
                      objectFit:"contain",
                      borderRadius:"8px"
                    }}
                  />
                )}
              </>
            )}
          </>
        ) : (
            "No Assets Found!"
        )}
      </div>
    </>
  );
};

export default ImageAndPdfViewer;
