import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { LocalStorageKeys } from '../../../utils';
import { setAuth } from '../../slices/auth';

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_auth_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
        }
      
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const authAPI = createApi({
    reducerPath: "authAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ['Auth'],
    endpoints: (builder) => ({
        // Login API
        login: builder.mutation({
            query: (payload) => ({ url: `login`, method: "POST", body: { ...payload } }),
            transformResponse: (response, meta, arg) => {
                localStorage.setItem(LocalStorageKeys.authToken, response?.token);
                if (response?.status === 200) {
                    return response?.data
                }
                return ""
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    //Saving the response in Auth Slice
                    dispatch(setAuth({ ...data }));
                } catch (error) {
                    console.log("Error at authAPI :", error);
                }
            },
        }),
        //verify Otp
        verifyOtp: builder.mutation({
            query: (payload) => ({ url: `verifyOTP/?token=${localStorage.getItem(LocalStorageKeys.authToken)}`, method: "POST", body: { ...payload } }),
            transformResponse: (response, meta, arg) => {
                localStorage.setItem(LocalStorageKeys.authToken, response?.token);
                if (response?.status === 200) {
                    return response?.user?.clients
                }else if( response?.status === 403){
                    return "No Access"
                }
                return []
            },
        }),

        // Refresh API
        // refresh: builder.query({
        //     query: (payload) => ({
        //         url: `refresh`,
        //         method: "POST",
        //         body: { refresh: localStorage.getItem(LocalStorageKeys.authToken) }
        //     }),
        //     async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        //         try {
        //             const { data } = await queryFulfilled;
        //             //Saving the response in Auth Slice
        //             dispatch(setAuth({ ...data }));
        //         } catch (error) {
        //             console.log("Error at authAPI :", error);
        //             // Removing the refresh tokens in local storage
        //             localStorage.removeItem(LocalStorageKeys.authToken);
        //         }
        //     }
        // }),

        verifyUser: builder.query({
            query: (payload) => ({ url: `verifyUser/?token=${localStorage.getItem(LocalStorageKeys.welcomeToken)}`, method: "POST", body: { ...payload } }),
            transformResponse: (response, meta, arg) => {
                if (response?.status === 200) {
                    return response
                }
                return []
            },

        }),
        updatePassword: builder.query({
            query: (payload) => ({ url: `updatepassword/?token=${localStorage.getItem(LocalStorageKeys.welcomeToken)}`, method: "POST", body: { ...payload } }),
            transformResponse: (response, meta, arg) => {
                if (response?.status === 201) {
                    return response
                }
                return []
            },

        }),

        forgotPassword: builder.query({
            query: (payload) => ({ url: `forgotpassword`, method: "POST", body: { ...payload } }),
            transformResponse: (response, meta, arg) => {
                if (response?.status === 200) {
                    return response
                }
                return []
            },

        }),


    }),
})

// Export hooks for usage in functional components
export const { useLoginMutation, useRefreshQuery, useLogoutMutation, useResetPasswordMutation, useSetPasswordMutation, useChangePasswordMutation, useSignUpMutation, useLazyVerifyUserQuery, useVerifyOtpMutation ,useLazyUpdatePasswordQuery,useLazyForgotPasswordQuery } = authAPI
