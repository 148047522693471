import React from "react";
import { Link, Container , Box } from "@mui/material";
import animation from '../../assets/404.json'
import Lottie from "react-lottie";
import { Bold } from '../../utils/constants';
class NotFound extends React.Component {

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      // here is where we will declare lottie animation
      // "animation" is what we imported before animationData: animation,
      animationData: animation,

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const styles = {
      root: {
        height: "100vh",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F6F6F6",
        padding:'0px !important'

      },
      link:{
        fontFamily:Bold,
        backgroundColor: 'primary.main',
        borderRadius: "8px",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color:"#fff",
        width:"fit-content",
        padding:"10px",
        margin:"0 auto",
        cursor:"pointer",
        textDecoration:"none"


      }
    }
    return (
      <Container maxWidth="sm" sx={styles?.root}>
        <Box display={'block'} textAlign={'center'}>
          <Lottie options={defaultOptions} height={300} width={300} />
          <Link href="/" sx={styles?.link}>
            Go Home
          </Link>
        </Box>
      </Container>
    );
  }
}

export default NotFound;
