import { Regular, SemiBold, Bold } from "../../utils/constants";

export const ContractDetailsStyle = {
    entireBox: {
        padding: "12px 16px 16px 16px",
        boxShadow: "0px 3px 30px #5C86CB2E",
        borderRadius: "12px",
        backgroundColor: "#fff",
    },
    entire: {
        padding: "12px 16px 0 16px",
        height: {
            sm: `auto`,
            xs: `auto`
        },
        overflow: "auto",
        boxShadow: "0px 3px 30px #5C86CB2E",
        borderRadius: "12px",
        backgroundColor: "#fff",
        marginTop: "10px"
    },
    rootDetails: {
        padding: "16px",
        width: '100%',
        overflow: "auto",
        backgroundColor: '#F8F8F8',
    },
    root: {
        padding: "16px",
        width: '100%',
        overflow: "auto",
        backgroundColor: '#F8F8F8',
        height: '100%',
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    contractor: {
        color: "text.primary",
        fontFamily: Bold,
        fontSize: "0.875rem",
    },
    unitId: {
        fontSize: "0.75rem",
        color: "text.Teritary",
        marginTop: "5px",
        fontFamily: Regular
    },
    marginItem: {
        marginInlineStart: "12px"
    },
    firstStack: {
        borderBottom: "1px solid #E4E8EE",
        paddingBottom: "12px",
        "& hr": {
            height: "24px",
            width: "1px",
            backgroundColor: "#E4E8EE",
            marginRight: "12px"
        }
    },
    secondStack: {
        borderBottom: "1px solid #E4E8EE",
        padding: "12px",
        "& hr": {
            height: "24px",
            width: "1px",
            backgroundColor: "#E4E8EE",
            marginInlineEnd: {
                xs: "6px",
                sm: "12px"
            }
        }
    },
    stackItems: {
        width: {
            xs: "50%",
            sm: "49%"
        },
    },
    dataHead: {
        color: "text.secondary",
        fontFamily: Regular,
        marginBottom: "4px",
        whiteSpace: {
            xs: "nowrap"
        },
        fontSize: {
            xs: "0.75rem",
            sm: "0.75rem"
        },
    },
    dataValue: {
        width: "95%",
        color: "border.primary",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        marginBottom: "4px",
        whiteSpace: {
            xs: "nowrap"
        }
    },
    categoryHead: {
        fontFamily: Bold,
        color: "text.main",
        margin: "12px 0 4px 0",
        fontSize: {
            xs: "1rem",
            sm: "1rem"
        }
    },
    commonStack: {
        "& p": {
            color: "text.Teritary",
            fontSize: "0.75rem",
            fontFamily: Regular,
            marginTop: "12px "
        }
    },
    downloadBtn: {
        cursor: "pointer"
    },
    categoryBox: {
        paddingBottom: "12px",
        borderStyle: "none none dotted none"
    },
    unitCard: {
        cursor: "pointer",
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "0px 3px 30px #4354721F",
        margin: "11px 0",
        padding: {
            xs: "12px 8px 7px 8px",
            sm: "12px 13px 7px 13px"
        }
    },
    unitID: {
        color: "text.main",
        fontFamily: Bold,
        fontSize: "0.875rem",
    },
    unitAd: {
        color: "text.Teritary",
        fontFamily: Regular,
        fontSize: "0.75rem",
        margin: "6px 0",
        whiteSpace: {
            xs: "nowrap"
        }
    },
    sqMeters: {
        color: "text.Teritary",
        fontFamily: Regular,
        fontSize: "0.75rem",
        whiteSpace: {
            xs: "nowrap"
        }
    },
    stackOne: {
        paddingBottom: "10px ",
        borderBottom: "1px solid #E4E8EE"
    },
    unitAbt: {
        marginInlineStart: "10px"
    },
    buildingStack: {
        width: {
            xs: "35%",
            sm: "32%"
        },
        marginRight: {
            xs: "6px",
            sm: "10px"
        },

        "& svg": {
            marginInlineEnd: "4px"
        }
    },
    bathStack: {
        width: "32%",
        padding: {
            xs: "7px",
            sm: "0 10px"
        },
        borderRight: "1px solid #E4E8EE",
        borderLeft: "1px solid #E4E8EE",
        "& svg": {
            marginInlineEnd: "4px"
        }
    },
    bedStack: {
        width: {
            xs: "27%",
            sm: "32%"
        },
        marginLeft: {
            xs: "7px",
            sm: "10px"
        },
        "& svg": {
            marginInlineEnd: "4px"
        }
    },
    stackBotttom: {
        padding: "6px 7px"
    },
    topItem: {
        // borderBottom: "2px solid #E4E8EE",
        paddingBottom: "14px"
    },
    svgItem: {
        color: "text.main",
        fontFamily: SemiBold,
        marginInlineStart: "9px",
        fontSize: {
            xs: "0.8125rem",
            sm: "0.875rem"
        },
    },
    Sqft: {
        color: "text.secondary",
        fontFamily: SemiBold,
        fontSize: "0.875rem",
        marginInlineStart: "5px"
    },
    svgContainer: {
        padding: "10px 7px",
        borderBottom: "2px solid #E4E8EE",
        "& div": {
            margin: "4px 0"
        }
    },
    badgeOne: {
        margin: "6px",
        backgroundColor: "background.badge",
        padding: "3px 6px",
        color: 'text.badgeText1',
        fontSize: "0.75rem",
        fontFamily: Regular,
        borderRadius: "4px"
    },
    badgeTwo: {
        margin: "6px",
        backgroundColor: "background.badgeTwo",
        padding: "3px 6px",
        color: 'text.badgeText2',
        fontFamily: Regular,
        fontSize: "0.75rem",
        borderRadius: "4px"

    },
    KTown: {
        color: "text.Teritary",
        fontSize: "0.875rem",
        fontFamily: Regular,
        margin: " 0 0 6px 0"
    },
    category: {
        fontSize: "1rem",
        fontFamily: Bold,
        color: "text.main",
    },
    handBookDiv: {
        padding: "12px 0",
        borderBottom: "2px solid #E4E8EE",
        "& div": {
            "& p": {
                color: "text.main",
                fontSize: "0.875rem",
                marginInlineStart: "6px",
                fontFamily: SemiBold
            }
        },
        "& p": {
            color: "primary.main",
            fontSize: "0.875rem",
            fontFamily: SemiBold
        }
    },
    mapDiv: {
        padding: "14px 0",
        borderBottom: "2px solid #E4E8EE",
        alignItems: "center",
        justifyContent: "space-between",
        "& div": {
            "& p": {
                width: "74%",
                color: "text.main",
                fontSize: "0.8125rem",
                marginInlineStart: "11px",
                fontFamily: SemiBold
            }
        },
        "& p": {
            color: "primary.main",
            fontSize: "0.875rem",
            fontFamily: SemiBold
        }
    },
    mapText: {
        width: "87% !important"
    },
    accordionSection: {
        padding: "16px 0 0 0",
        margin: "14px  0px",
        backgroundColor: "background.accordion",
        borderRadius: "8px"
    },
    accordionDetailsParent: {
        display: "block",
        width: "92%",
        marginLeft: "auto",
        padding: "0 20px ",
        "& p": {
            color: "text.Teritary",
            fontSize: "0.8125rem",
            fontFamily: SemiBold
        }
    },
    accordionHead: {
        padding: "6px 0 10px 0",
        backgroundColor: "background.accordion",
        boxShadow: "none !important",
        marginTop: "0 !important",
        "& .MuiAccordionSummary-root": {
            minHeight: "45px",
            padding: "0 14px ",
            display: "flex",
            alignItems: "center",
            "& .MuiAccordionSummary-content": {
                margin: "6px 0 14px 0",
                alignItems: "end"
            }
        },
        "& .MuiAccordionDetails-root": {
            display: "block !important"
        }
    },
    ownerMail: {
        color: "text.main",
        fontSize: "0.75rem",
        fontFamily: SemiBold
    },
    owner: {
        color: "text.main",
        fontSize: "0.875rem",
        fontFamily: Bold,
        marginBottom: "3px"
    },
    address:{
        color: "text.primary",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
    },
    ownerHeading: {
        color: "text.secondary",
        fontFamily: Bold,
        fontSize: "0.75rem",
        padding: "0 25px 0 25px "
    },
    handbookIMg: {
        height: "17px"
    },
    unitImg: {
        width: "70px",
        height: "70px",
        position: "relative",
        "& div": {
            color: "text.badgeText1",
            backgroundColor: "background.badge",
            padding: "5px",
            fontSize: "0.75rem",
            fontFamily: SemiBold,
            position: "absolute",
            bottom: "0",
            width: "100%",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px"

        }
    },
    containerSm: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        height: "100%",
    },
    ownerAcc: {
        marginInlineStart: "8px"
    },
    sticky: {
        position: 'fixed',
        top: 0,
        zIndex: 999,
        left:0,
        right:0    },
    main: {
        overflow: "auto",
        height: '100%',
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    imgContainer: {
        width: "48px",
        height: "48px",
        "& img": {
            borderRadius: "50%",
            objectFit: "contain"
        }
    },
    simmer: {
        padding: "12px 16px 16px 16px",
        boxShadow: "0px 3px 30px #5C86CB2E",
        margin: "12px",
        borderRadius: "12px",
        backgroundColor: "#fff",
    }
}
