import * as React from "react"

export const SpendingImage = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18.595} height={16} {...props}>
    <path
      data-name="icons8-payment-history (1)"
      d="M1.514 0A1.515 1.515 0 0 0 0 1.514v7.351a1.515 1.515 0 0 0 1.514 1.514h6.768a5.658 5.658 0 0 1 .367-1.3H3.4a1.722 1.722 0 0 0-2.106-2.1V3.4A1.729 1.729 0 0 0 3.4 1.3h11.359a1.722 1.722 0 0 0 2.106 2.1v3.106a5.474 5.474 0 0 1 1.3 1.145v-6.14A1.515 1.515 0 0 0 16.649 0H1.514Zm7.568 2.595A2.41 2.41 0 0 0 6.92 5.19a2.41 2.41 0 0 0 2.162 2.595 1.66 1.66 0 0 0 .359-.039A5.483 5.483 0 0 1 11 6.4a3.034 3.034 0 0 0 .247-1.206 2.41 2.41 0 0 0-2.166-2.599Zm-4.757 1.73a.865.865 0 1 0 .865.865.865.865 0 0 0-.866-.866Zm9.514 0a.868.868 0 0 0-.722 1.345 5.535 5.535 0 0 1 1.444 0 .868.868 0 0 0-.722-1.345Zm0 2.162a4.757 4.757 0 1 0 4.757 4.757 4.757 4.757 0 0 0-4.758-4.758Zm-1.73 1.73h3.459a.432.432 0 1 1 0 .865v.865a1.719 1.719 0 0 1-.6 1.3 1.719 1.719 0 0 1 .6 1.3v.865a.432.432 0 1 1 0 .865h-3.46a.432.432 0 1 1 0-.865v-.865a1.719 1.719 0 0 1 .6-1.3 1.719 1.719 0 0 1-.6-1.3v-.865a.432.432 0 1 1 0-.865Zm.865.865v.865H14.7v-.865Zm.865 2.595a.866.866 0 0 0-.865.865v.7l.728-.242a.435.435 0 0 1 .274 0l.728.242v-.7a.866.866 0 0 0-.866-.866Z"
      fill="#ebebeb"
    />
  </svg>
)

