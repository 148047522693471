
import { Box, Grid, Stack, Switch, Typography } from "@mui/material"
import { PhoneTwo } from "../../assets"
import { ContactCardStyle } from "./style"


export const ContactCard = ({
    name,
    tag,
    sub_text,
    sub_text2,
    contact_name,
    hidden,
    isShare,
    hide,
    handleChange,
    item,
    t = () => false,
    call,
    onEditClick = () => false

}) => {
    const label = { inputProps: { 'aria-label': 'Size switch demo' } };
    const language = localStorage.getItem("i18nextLng")

    return (
        <>
            {call ?
                <>
                    <a
                        href={`tel:${contact_name}`}
                        style={{ textDecoration: "none", color: "#071741" }}
                        target="_blank" rel="noreferrer"
                    >
                        <Box sx={ContactCardStyle?.sx}>
                            <Grid container>
                                <Grid item xs={1.5} >
                                    <Box sx={ContactCardStyle?.bg}>
                                        <PhoneTwo /> 
                                    </Box>
                                </Grid>
                                <Grid item xs={8.5} >
                                    <Box sx={ContactCardStyle?.align}>
                                        <Box sx={ContactCardStyle?.flexItem}>
                                            <Typography sx={ContactCardStyle?.name}>{name}</Typography>
                                            <Box sx={ContactCardStyle?.tag}>
                                                <Typography sx={ContactCardStyle?.text}>{tag}</Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={ContactCardStyle?.alignfirst}>
                                            <Typography sx={ContactCardStyle?.text}>{sub_text}</Typography>
                                            <Stack direction="row" alignItems="center">
                                                <Box sx={ContactCardStyle?.dot}></Box>
                                                <Typography sx={ContactCardStyle?.text}>{sub_text2}</Typography>
                                            </Stack>
                                        </Box>
                                        <Typography sx={ContactCardStyle?.text}>{contact_name}</Typography>
                                    </Box>
                                </Grid>
                                {hide && hide ? "" :
                                    <Grid item xs={2}>
                                        <div style={{float:language === 'ar' ? "left" : "right"}}>
                                            <Typography sx={ContactCardStyle?.text}>
                                                {isShare ? t("Public") : t("Private")}
                                            </Typography>
                                            <Switch id="confidentialitySwitch" checked={isShare} {...label} size="small"
                                                onChange={(e) => {
                                                    if (e.target.id === "confidentialitySwitch") {
                                                        handleChange(e, item)
                                                    }
                                                }} />
                                        </div>
                                    </Grid>

                                }
                            </Grid>

                        </Box>
                    </a>
                    {hidden && <Box sx={ContactCardStyle?.divider} />}
                </>
                :
                <>
                    <Box sx={ContactCardStyle?.sx} onClick={(e) => {
                        if (e.target.id !== "confidentialitySwitch") {
                            onEditClick(item)
                        }
                    }}>
                        <Grid container>
                            <Grid item xs={1.5}>
                                <Box sx={ContactCardStyle?.bg}>
                                    <PhoneTwo />
                                </Box>
                            </Grid>
                            <Grid item xs={8.5} >
                                <Box sx={ContactCardStyle?.align}>
                                    <Box sx={ContactCardStyle?.flexItem}>
                                        <Typography sx={ContactCardStyle?.name}>{name}</Typography>
                                        <Box sx={ContactCardStyle?.tag}>
                                            <Typography sx={ContactCardStyle?.text}>{tag}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={ContactCardStyle?.alignfirst}>
                                        <Typography sx={ContactCardStyle?.text}>{sub_text}</Typography>
                                        <Stack direction="row" alignItems="center">
                                            <Box sx={ContactCardStyle?.dot}></Box>
                                            <Typography sx={ContactCardStyle?.text}>{sub_text2}</Typography>
                                        </Stack>
                                    </Box>
                                    <Typography sx={ContactCardStyle?.text}>{contact_name}</Typography>
                                </Box>
                            </Grid>
                            {hide && hide ? "" :
                                <Grid item xs={2}>
                                    <Box style={{float:language === 'ar' ? "left" : "right"}}>
                                        <Typography sx={ContactCardStyle?.text}>
                                            {isShare ? t("Public") : t("Private")}
                                        </Typography>
                                        <Switch id="confidentialitySwitch" checked={isShare} {...label} size="small" onChange={(e) => {
                                            if (e.target.id === "confidentialitySwitch") {
                                                handleChange(e, item)
                                            }
                                        }} />
                                    </Box>
                                </Grid>

                            }
                        </Grid>

                    </Box>
                    {hidden && <Box sx={ContactCardStyle?.divider} />}
                </>
            }
        </>
    )
}