import React from "react";
import { Setup } from "./setUp";
import { withTranslation } from 'react-i18next';

class SetupParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return <Setup  {...this?.props} />;
    }
}

export default withTranslation('WelcomePage')(SetupParent);