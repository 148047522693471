import { LoderSimmer } from "../../../components"
import { Box } from "@mui/material"

export const ProfilePicturesimmer = () => {
    return (
        <Box
        // sx={{ ...ProfileDetailsCardStyles?.boxStyles, ...box_style }}
        >
            <LoderSimmer card count={1} width={120} size={120} variant={"circular"} animation={"wave"} />
        </Box>
    )
}