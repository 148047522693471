import { ExtraBold, FontSizeCalc } from "../../utils";

export const style = ({ size, is_back_button }) => ({
    content: {
        padding: "12px 16px",
        boxShadow: "0px 2px 6px #00000014",
        backgroundColor: "white",
    },
    title: {
        textAlign: "center",
        fontSize: FontSizeCalc(16),
        fontFamily: ExtraBold,
        color: "#091B29",
        width: is_back_button ? (size?.width - 92) : (size?.width - 32),
    },
    arrow: {
        fontSize: "1.25rem",
        color: "#091B29",
    },
});