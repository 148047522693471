import useTheme from "@mui/material/styles/useTheme";
import { SemiBold } from "../../utils";

export const ToggleStyle = (props) => {
   const theme = useTheme();
   return {
      selected: {
         marginLeft: "5px",
         backgroundColor: theme?.palette?.primary?.main,
         fontFamily: props?.fontFamily ?? SemiBold,
         fontSize: props?.fontSize ?? "0.875rem",
         borderRadius: props?.borderRadius ?? theme?.palette?.borderRadius,
         border: props?.border ?? `1px solid ${theme?.palette?.primary.main}`,
         padding: props?.padding ?? "15px 12px",
         color: props?.color ?? theme?.palette?.error?.contrastText,
         boxShadow: "none !important",
         textTransform: 'capitalize',
         '& p': {
            color: props?.color ?? theme?.palette?.error?.contrastText,
            fontFamily: props?.fontFamily ?? SemiBold,
            fontSize: props?.fontSize ?? "0.875rem",
         },
         "&:hover": {
            color: props?.color ?? theme?.palette?.error?.contrastText,
            border: `1px solid ${theme?.palette?.primary?.main}`,
            backgroundColor: theme?.palette?.primary?.main,
            padding: props?.padding ?? "15px 12px",

         },
      },
      unselected: {
         backgroundColor: "#F8FAFC",
         marginLeft: "5px",
         fontFamily: props?.fontFamily ?? SemiBold,
         fontSize: props?.fontSize ?? "0.875rem",
         borderRadius: props?.borderRadius ?? theme?.palette?.borderRadius,
         border: props?.border ?? `1px solid ${theme?.palette?.border?.secondary}`,
         padding: props?.padding ?? "15px 12px",
         color: props?.color ?? theme?.palette?.text?.main,
         boxShadow: "none !important",
         textTransform: 'capitalize',
         '& p': {
            color: props?.color ?? theme?.palette?.text?.Teritary,
            fontFamily: props?.fontFamily ?? SemiBold,
            fontSize: props?.fontSize ?? "0.875rem",
         },
         "&:hover": {
            color: props?.color ?? theme?.palette?.text?.main,
            backgroundColor: props?.backgroundColor ?? theme?.palette?.error?.contrastText,

         },
      },
      parent: {
         backgroundColor: props?.backgroundColorunparent,
         padding: "5px",
         borderRadius: props?.borderRadius ?? theme?.palette?.borderRadius,
      },
      grid: {
         background: "#F8FAFC",
         borderRadius:props?.borderRadius ?? theme?.palette?.borderRadius,
         justifyContent: "space-between"
      }
   }
};
