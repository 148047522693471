import { SemiBold } from "../../utils";

export const PasswordInfoStyles = {
    unordered_list: {
        margin: "6px 0px 3px 18px",
        padding: "0px"
    },
    password_strength: {
        fontSize: "0.75rem",
        color: "white",
        fontFamily: SemiBold,
    }
};