import * as React from "react"

export const FurnitureDrawer = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 96776" transform="translate(.15 .15)">
            <path data-name="Rectangle 53056" fill="none" d="M-.15-.15h24v24h-24z" />
            <path
                data-name="Path 9174"
                d="m11.646 3.596 5.742 1.158 1.2 2.519 1.534 3.423-1.8 7.878-6.676.579Z"
                fill="#ced3dd"
            />
            <path
                data-name="Path 9175"
                d="M12.121 3.596 5.445 4.787l-.268 2.486-1.532 3.423 1.8 7.677 6.681.78Z"
                fill="#ced3dd"
            />
            <rect
                data-name="Rectangle 53057"
                width={8}
                height={3}
                rx={1}
                transform="translate(7.85 12.852)"
                fill="#fff"
            />
            <path
                d="M7.525 3.594a2.969 2.969 0 0 0-2.959 2.959v1.6A2.515 2.515 0 0 0 2.29 10.65a2.483 2.483 0 0 0 1.821 2.364v3.1a3.423 3.423 0 0 0 1.821 3.015v1.082a.683.683 0 1 0 1.366 0v-.694c.076.005.151.012.228.012h8.656c.075 0 .147-.007.221-.012v.694a.683.683 0 1 0 1.366 0v-1.08a3.421 3.421 0 0 0 1.828-3.01v-3.106a2.483 2.483 0 0 0 1.816-2.363 2.515 2.515 0 0 0-2.276-2.492v-1.6a2.969 2.969 0 0 0-2.963-2.966Zm0 1.366h8.649a1.583 1.583 0 0 1 1.593 1.593V8.23a2.971 2.971 0 0 0-2.277 2.875v1.417a2.473 2.473 0 0 0-1.138-.279H9.346a2.473 2.473 0 0 0-1.138.279v-1.417A2.971 2.971 0 0 0 5.932 8.23V6.553A1.583 1.583 0 0 1 7.525 4.96Zm2.731 3.187a.683.683 0 1 0 .683.683.683.683 0 0 0-.683-.684Zm3.187 0a.683.683 0 1 0 .683.683.683.683 0 0 0-.683-.684ZM4.79 9.512h.343a.683.683 0 0 0 .176.006 1.58 1.58 0 0 1 1.529 1.587v5.007a.683.683 0 0 0 .683.683h8.649a.683.683 0 0 0 .683-.683v-5.007a1.58 1.58 0 0 1 1.537-1.587.683.683 0 0 0 .173-.006h.346a1.138 1.138 0 0 1 0 2.276.683.683 0 0 0-.681.684v3.648a2.038 2.038 0 0 1-2.049 2.041H7.525a2.038 2.038 0 0 1-2.049-2.049v-3.641a.683.683 0 0 0-.683-.683 1.138 1.138 0 1 1 0-2.276Zm4.552 4.1h5.007a1.128 1.128 0 0 1 1.141 1.135v.683H8.208v-.683a1.128 1.128 0 0 1 1.138-1.138Z"
                fill="#4e5a6b"
            />
        </g>
    </svg>
)
