import {
  Box,
  Typography,
  Fab,
  Container,
} from "@mui/material";
import { Activity, DialogDrawer, SelectBox, ChartBox, BarCharts, ActivityModal } from "../../components"
import { Spending_style } from "./style";
import { Transporation } from "../../assets";
import React, { useState } from 'react';
import { month, currentYear, getYear, currentMonth, errorRoute, currencyFormatted } from '../../utils';
import { AppRoutes } from "../../router/routes";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { useLazySpendingGraphQuery, useLazySpendingListingQuery, useLazyStatisticQuery } from "../../redux/services";
import moment from "moment-timezone";
import InfiniteScroll from "react-infinite-scroll-component";
import { openBackdrop, closeBackdrop } from "../../redux/slices/backdrop"
import { useDispatch } from "react-redux";
import { ActivityCardsimmer } from "./component/activityCardsimmer";
import { useEffect } from "react";
import useWindowDimensions from "../../useDimension";

const initial = {
  yearSelect: {
    value: currentYear,
    label: currentYear
  },
  monthSelect: {
    value: currentMonth,
    label: currentMonth
  },
  categorySelect: {
    value: null,
    label: "All"
  }
}


export const Spending = ({
  t = () => false,
  bottomSx = {},
  height
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [GetSpending , {error}] = useLazySpendingListingQuery();
  const [GetGraph  , {error:isError}] = useLazySpendingGraphQuery()
  const [spendingListing, setSpendingListing] = useState({
    id: "",
    listingDrawer: [],
    listingGraph: [],
    listingType: [],
    boolGraph: true,
    listingDatas: [],
    bool: true,
    modelData: {},
    viewDrawerDetails: {},
    drawer: false
  });
  const [getGraph, setGraph] = useState({
    boolGraph: true,
    listingGraph: [],
  })
  const size = useWindowDimensions();

  const [offSet, setOffSet] = useState(0);
  const [toggle, setToggle] = useState("1")
  const [Statistic] = useLazyStatisticQuery()
  const [loading1, setLoading1] = React.useState(null);
  const [select, setSelect] = useState({ ...initial, bool: true })

  // drawer opening
  const handleDrawer = async (type, value) => {
    if (type === "open") {
      dispatch(openBackdrop("...Loading"))
      setSpendingListing({
        ...spendingListing, id: value?.id,
        drawer: true
      })
      dispatch(closeBackdrop(""))
    } else if (type === "close") {
      setSpendingListing({
        ...spendingListing,
        drawer: false
      })
    }
  };
  // drawer close
  const handleDrawerClose = () => {
    setSpendingListing({
      ...spendingListing,
      drawer: false
    })
  }

  // create icon
  const createIcon = () => {
    navigate(AppRoutes.createSpending)
  }

  // const getSpendingGraph = async (payload) => {
  //   setGraph({
  //     ...getGraph,
  //     boolGraph: true
  //   })
  //   const data = await GetGraph(payload)
  //   setGraph({
  //     listingGraph: data?.data?.data?.map((x) => {
  //       return {
  //         name: payload?.month ? x?.day : month[x?.month - 1],
  //         value: x?.amount ?? 0,
  //         fill: `#${Math.random().toString(16).substring(2, 8)}`,
  //       }
  //     }),
  //     boolGraph: false
  //   })

  // }
  // const getSpendingList = async (payload, isFilter) => {
  //   const data = await GetSpending(payload)
  //   setSpendingListing({
  //     ...spendingListing,
  //     listingDatas: isFilter ?
  //       spendingListing?.listingDatas.concat(data?.data?.data?.data) : data?.data?.data?.data,
  //     bool: false
  //   })
  // }
  const updateState = (k, v) => {
    setSelect({ ...select, [k]: v });
  };


  // offset data
  const fetchMoreData = () => {
    getGraphList({
      offset: offSet + 10,
      limit: 10,
      month: toggle === "1" ? undefined : month.indexOf(select?.monthSelect?.value) + 1,
      year: select?.yearSelect.value,
      type: "Spendings",
      category_id: select?.categorySelect?.value
    }, true)
    setOffSet(offSet + 10);
  }

  const loadOptions = async (search = "", array, type) => {
    setLoading1(type);
    let result,
      offset = 0;
    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }
    switch (type) {
      case "categorySelect":
        result = await Statistic({
          offset,
          limit: 10, search
        })
        setLoading1(null);
        return {
          options: offset === 0 ? [{ value: null, label: "All" }, ...result?.data?.data] : [...result?.data?.data],
          hasMore:
            array?.length + result?.data?.data?.length <
            result?.data?.count,
        };
      default:
        return { options: [] };
    }
  };
  const handleChange = async (e) => {
    updateState("categorySelect", e)
    initialData({
      month: toggle === "1" ? undefined : month.indexOf(select?.monthSelect?.value) + 1,
      year: select?.yearSelect.value,
      type: "Spendings",
      category: e?.value
    })
  }

  const handleChangePeriod = async (m, y) => {
    setSelect({
      ...select,
      yearSelect: y,
      monthSelect: m
    })

    initialData({
      month: toggle === "1" ? undefined : month.indexOf(m?.value) + 1,
      year: y.value,
      type: "Spendings",
      category: select?.categorySelect?.value
    })
  }

  const toggleChange = async (e) => {
    setToggle(e)
    if (e === "2") {
      initialData({
        month: month.indexOf(select?.monthSelect?.value) + 1,
        year: select?.yearSelect?.value,
        type: "Spendings",
        category: select?.categorySelect?.value
      })
    }
    else {
      initialData({
        year: select?.yearSelect?.value,
        type: "Spendings",
        category: select?.categorySelect?.value
      })
    }
  }


  const getGraphData=async(payload)=>{
    setGraph({
      listingGraph:[],
      boolGraph:true,
    })
    const data = await GetGraph(payload)
    setGraph({
      listingGraph: data?.data?.data?.map((x) => {
        return {
          name: payload?.month ? x?.day : month[x?.month - 1],
          value: x?.amount ?? 0,
          fill: `#${Math.random().toString(16).substring(2, 8)}`,
        }
      }),
      boolGraph: false
    })

  }

  const getGraphList=async(payload , isFilter)=>{
    const data = await GetSpending(payload)
    setSpendingListing({
      ...spendingListing,
      listingDatas: isFilter ?
        spendingListing?.listingDatas.concat(data?.data?.data?.data) : data?.data?.data?.data,
      bool: false
    })
  }

   const initialData = async (payload) => {
    await getGraphData({
      year: payload?.year,
      month:payload?.month,
      type: "Spendings",
      category: payload?.category ?? undefined
    })
    await getGraphList({
      offset: payload?.offset ?? 0,
      limit: payload?.limit ??  10,
      type: "Spendings",
      category_id: payload?.category ?? undefined,
      month:payload?.month ?? undefined,
      year: payload?.year ?? undefined,
    })

  }

  useEffect(() => {
    initialData({
      year: select?.yearSelect?.value,
      type: "Spendings",
      category: select?.categorySelect?.value ?? undefined
    })
    // eslint-disable-next-line
  }, [])

  React.useEffect(()=>{
    if(error?.status === 423|| isError?.status === 423) return errorRoute() 
 },[error , isError])
  return (
    <>
      <Box sx={{ ...Spending_style.root, height }}>
        <ChartBox
          onChange={toggleChange}
          handleChangePeriod={handleChangePeriod}
          hidecomponent={
            <BarCharts data={getGraph?.listingGraph ?? []}
            />
          }
          dropcomponent={<SelectBox
            onChange={handleChange}
            loading={loading1 === "categorySelect"}
            isPaginate={true}
            loadOptions={(search, array, handleLoading) =>
              loadOptions(search, array, "categorySelect")}
            value={select?.categorySelect}
            controls={{
              borderRadius: "8px !important",
              height: "32px !important"
            }}
            radius={"8px !important"}
            selectHeight={"32px !important"}

          />}
          yearSelect={(e) => {
            initialData({ year: e?.value, month:toggle === "1" ? undefined : month.indexOf(select?.monthSelect?.value) + 1, type: "Spendings", category: select?.categorySelect?.value })
            updateState("yearSelect", e)
          }}
          monthValue={select?.monthSelect?.value}
          yearValue={select?.yearSelect?.value}
          values={{
            month:select?.monthSelect,
            year:select?.yearSelect
          }}
          togglebtn={true}
          symbol={"$"}
          chart_parent={{ padding: "19px 18px 19px 12px !important" }}
          options={[
            { value: "1", label: t("Yearly") },
            { value: "2", label: t("Monthly") },
          ]}
          year={getYear()?.map((x) => {
            return {
              value: x,
              label: x
            }
          }) ?? []}
          month={month?.map((x) => {
            return {
              value: x,
              label: x
            }
          }) ?? []}
          value={toggle}
          isFull
          sx={{ padding: '10px' }}
          title={t("Spending Graph")}
          toggles={toggle === "2" ? true : false}
          loading1={loading1}
          loader={getGraph?.boolGraph}
        />

        {
          spendingListing?.listingDatas?.length > 0 ?
            <Box
              sx={Spending_style?.headingSec}
            >
              <Typography sx={Spending_style?.heading}>{t("Activity")}</Typography>
            </Box> : ""
        }
        <Box elevation={0}>
          {/* LOADER  */}
          {spendingListing?.bool ?
            <Box p={2}>
              <ActivityCardsimmer count={5} />
            </Box> :
            <Box sx={Spending_style?.paper}>
              {/* spending card  */}
              {/* {spendingListing?.listingDatas?.length > 0 ? */}
              <InfiniteScroll
                dataLength={spendingListing?.listingDatas?.length ?? 0}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - 291}
                
              >
                {spendingListing?.listingDatas?.map((val, index) => (
                  <Box
                    key={index}
                    
                    paddingBottom={spendingListing?.listingDatas?.length === index + 1 ? "0px" : "0px"} >
                    <Activity
                      price={`${val?.currency_id ?? ""} ${currencyFormatted(val?.amount)}`}
                      activity={val?.category_name}
                      repairs={val?.name}
                      business={val?.sub_category_name}
                      date={moment(val?.date).format(
                        "DD-MMM-YY"
                      )}
                      image={<Transporation />}
                      color={"#E6F9FF"}
                      route={() => handleDrawer("open", val)}
                      hidden={spendingListing?.listingDatas?.length === index + 1 ? false : true}
                    />
                  </Box>
                ))}
              </InfiniteScroll>
              {/* :
                <Box mt={4}>
                  <NoData style={{
                    display: "flex", alignItems: "center", backgroundColor: "#fff",
                    padding: "16px"
                  }} />
                </Box>

              } */}

            </Box>
          }

        </Box>

        {/* Drawer */}
        <DialogDrawer
          maxWidth="sm"
          open={spendingListing?.drawer}
          onClick={() => handleDrawer("close")}
          header={t("Spending Details")}
          height_style={
            { height: "auto", padding: '0px !important' }
          }
          component={
            <>
              <ActivityModal
                id={spendingListing?.id}
                onClose={() => handleDrawerClose()}
              />
            </>
          }

          onClose={() => handleDrawerClose()}
        />
      </Box>


      <Box sx={Spending_style?.AddIcon}>
        <Container maxWidth="sm" sx={Spending_style.AddIconContainer}>
          <Fab sx={{ ...Spending_style?.AddIconChild, ...bottomSx }} onClick={createIcon}>
            <AddIcon />
          </Fab>
        </Container>
      </Box>
    </>
  )
}