import { Box, Typography } from "@mui/material"
import { Checkinout_style } from "./style"
import moment from "moment-timezone";


export const Checkinout = ({
    image = "",
    date = "",
    purpose = "",
    line = false,
    progressDetails = [],
    titleBold = false,
    show = false,
    created_at = "",
    checkin="",
    chcekout=""
}) => {
    const language = localStorage.getItem("i18nextLng")
    return (
        <Box sx={Checkinout_style?.align}>
            {
                show === true ? show && line ? <Box sx={Checkinout_style?.lineBlue} /> :
                    <Box sx={language === "ar" ? Checkinout_style?.lineArabic : Checkinout_style?.line} /> : null
            }
            <Box sx={language === "ar" ? Checkinout_style?.imgArabic : Checkinout_style?.img}>{image}</Box>
            <Box sx={Checkinout_style?.marginLeft}>
                
                    <Typography sx={Checkinout_style?.inspectedDateBold}>
                    &#x202a;{
                        moment(checkin).format(
                                    "DD MMM YY hh:mm A"
                                )}&#x202c;</Typography>
                
                <Typography sx={Checkinout_style?.inspectorId}>{purpose}</Typography>
            </Box>
        </Box>

    )
}