import * as React from "react"

const CloseIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
    <path
      fill="#9297a5"
      d="m7.1 6 4.673-4.673a.778.778 0 0 0-1.1-1.1L6 4.901 1.327.228a.778.778 0 0 0-1.1 1.1L4.9 6 .228 10.673a.778.778 0 1 0 1.1 1.1L6 7.099l4.673 4.673a.778.778 0 1 0 1.1-1.1zm0 0"
    />
  </svg>
)

export default CloseIcon
