// import useTheme from '@mui/material/styles/useTheme';
import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import React from "react";
import { CarouselSliders } from "./carousel";

export const Announcement = ({
  list = "",
  loading = false,
  count = "",
  title = "",
  isCommunity = false,
  isAll = false,
  t
}) => {

  console.log(list, 'list')
  return (
    <Box>
      {loading ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          padding={2}
          gap={2}
          sx={{
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 3px 10px #5C86CB2E",
            borderRadius: "12px",
          }}
        >
          <Skeleton
            variant="rectangular"
            width={73}
            height={73}
            sx={{ borderRadius: "4px" }}
          />
          <div style={{ flex: 1 }}>
            <Skeleton
              variant="rectangular"
              width={50}
              height={10}
              sx={{ borderRadius: "4px" }}
            />
            <Skeleton
              variant="text"
              width={"100%"}
              height={20}
              sx={{ marginBlock: 1 }}
            />
            <Skeleton variant="text" width={150} height={20} />
          </div>
        </Stack>
      ) : (
        <CarouselSliders list={list?.list} isCommunity={isCommunity}
          t={t}
          isAll={isAll} />
      )}
    </Box>
  );
};
