import { Bold, Regular, SemiBold } from "../../../utils"
export const Track_style = {

    inspectorId: {
        fontFamily: Regular,
        color: 'text.Teritary',
        fontSize: '0.75rem',
        marginBottom:"6px"
    },
    img: {
        position: 'absolute',
        top: "0px",
        left: "0"
    },
    inspectedDate: {
        fontSize: '0.875rem',
        fontFamily: Bold,
        color: 'text.Teritary',
        marginBottom: '4px',

    },
    align: {
        display: 'flex',
        // alignItems: 'center',
        position: "relative"
    },
    marginLeft: {
        marginLeft: '48px',
        marginBottom: '10px'
    },
    line: {
        borderLeft: '2px dashed #ccc',
        position: 'relative',
        height: 'calc(100vh - 100px) !important',
        left: '15px',
    },
    lineBlue: {
        borderLeft: '2px dashed #F15A29',
        position: 'absolute',
        height: "100%",
        left: '19px',
    },
    progressDetails: {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        marginBottom: "6px"
    },
    inspectedDateBold: {
        fontSize: '1rem',
        fontFamily: Bold,
        color: 'text.main',
        marginBottom: '4px',

    },
    created_at: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: "backgroundcard.primary"
    },
    titleBar: {
        borderBottom: "1px solid #E4E8EE"
    },
    title: {
        fontFamily: SemiBold,
        color: "#091B29",
        fontSize: "1rem",
        //textAlign: "center"
    },
    imageStyle: {
        height: "75px",
        width: "75px",
        borderRadius: "4px",
        margin: "0px 16px 0px 0px",
        objectFit: "cover"
      },
}