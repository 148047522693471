import * as React from "react"
const PurpleUnit = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 112390" transform="translate(-55 -236)">
      <rect
        width={40}
        height={40}
        fill="#ebdfff"
        data-name="Rectangle 57272"
        rx={6}
        transform="translate(55 236)"
      />
      <g data-name="Group 112334">
        <path
          fill="#896db3"
          stroke="#896db3"
          strokeWidth={0.5}
          d="M75.692 247a.691.691 0 0 0-.424.145l-6.08 4.707a3.485 3.485 0 0 0-1.342 2.738v1.536l1.385-1.1v-.441a2.09 2.09 0 0 1 .8-1.642l5.657-4.379 5.657 4.379a2.091 2.091 0 0 1 .8 1.642v9.026h-5.53a2.022 2.022 0 0 1-.461 1.389h6.231a1.155 1.155 0 0 0 1.154-1.154v-9.257a3.485 3.485 0 0 0-1.339-2.737l-6.08-4.706a.691.691 0 0 0-.428-.146Zm-5.1 8.518a.692.692 0 0 0-.406.149l-3.4 2.675a2.079 2.079 0 0 0-.786 1.631v3.874A1.164 1.164 0 0 0 67.154 265h6.923a1.164 1.164 0 0 0 1.154-1.154v-3.873a2.077 2.077 0 0 0-.791-1.632l-3.4-2.675a.692.692 0 0 0-.451-.149Zm.023 1.574 2.968 2.337a.691.691 0 0 1 .263.544v3.643h-6.461v-3.643a.69.69 0 0 1 .264-.544Z"
          data-name="icons8-real-estate (4)"
        />
      </g>
    </g>
  </svg>
)
export default PurpleUnit
