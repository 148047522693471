import { VehicleStyle } from "./style";
import { Box, Typography, Stack } from "@mui/material";
import { BicycleAvatar } from "../../assets/vehicle";
import { generateImageUrl, img_size } from "../../utils";

export const VehicleCard = ({
    data = {},
    handleRoute = () => false
}) => {
    return (
        <Box sx={VehicleStyle?.card} display='flex' style={{gap:"10px"}} justifyContent={'start'} alignItems={'center'} onClick={() => handleRoute()}>
                <Box sx={VehicleStyle?.img}>
                    {
                        data?.vehicle_image ? <img alt='vehicleImage' style={{ borderRadius: "4px 0px 0px 4px" }} width="100%" height="100%" src={generateImageUrl(data?.vehicle_image, img_size.small_square)} /> :
                            <Box style={VehicleStyle?.avatar}>
                                <BicycleAvatar />
                            </Box>
                    }
                </Box>
            <Box>
                <Stack direction="row" alignItems="center">
                    <Typography noWrap sx={VehicleStyle?.parkingNo}>{data?.reference_id}</Typography>
                    <Typography noWrap sx={VehicleStyle?.type}>{data?.vehicle_type}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" sx={VehicleStyle?.bottomStack} >
                    <Typography noWrap sx={VehicleStyle?.commonText}>{data?.number_plate}</Typography>
                    <Box></Box>
                    <Typography noWrap sx={VehicleStyle?.commonText}>{data?.unit_no}</Typography>
                </Stack>
            </Box>
        </Box>
    )
}