import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { ProfileCard_style } from "./style";
import { AppRoutes } from "../../router/routes";
import { useNavigate } from "react-router-dom";
import { generateImageUrl, img_size } from "../../utils";
export const ProfileCard = ({
  name = "",
  number = "",
  mail = "",
  icon = "",
}) => {

  const navigate = useNavigate()
  return (
    <Box display="flex" alignItems="center">
      <Avatar src={generateImageUrl(icon, img_size.small_square)} sx={ProfileCard_style?.avatarimg}/>
      <Box
        p={1}
        // borderBottom={"1px solid #E4E8EE"}
        sx={ProfileCard_style?.flexBox}
      >
        <Box sx={{ marginInlineStart: "8px" }} width={"75%"}>
          <Box sx={ProfileCard_style?.main} noWrap>
            <Typography sx={ProfileCard_style?.contactName}>
              {name}
            </Typography>
            <Box sx={ProfileCard_style?.dot1} />
            <Typography sx={ProfileCard_style?.contactName1} noWrap>
              {number}
            </Typography>
          </Box>
          <Box sx={ProfileCard_style?.mailBox}>
            <Typography sx={ProfileCard_style?.contactEmail} noWrap>
              {mail}
            </Typography>
          </Box>
        </Box>
        <Avatar sx={ProfileCard_style?.avatar}>
          <a href={`tel:${number}`} target="_blank" rel="noreferrer"><img src="/images/phone.png" alt="phone" />
</a>
        </Avatar>
      </Box>
    </Box >
  );
};
