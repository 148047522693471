import React, { useState, useEffect } from "react";
import { KycDrawerStyles } from "./style"
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import { useLazyGetKycIdentificationQuery } from "../../redux/services";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { KycIdentificationsimmer } from "../../screens/kycInformation/component/kycIdentificationSimmer";



export const KycDrawer = () => {
    const navigate = useNavigate();
    const [GetKycIdentification] = useLazyGetKycIdentificationQuery();
    const language = localStorage.getItem("i18nextLng")
    const [datas, setDatas] = useState({
        kycIdentification: [],
        bool: true,
    })


    // Proof Type
    const proofType = (data) => {
        navigate(AppRoutes.kycAddIdProof, { state: data?.id })
    }

    // get Kyc Identification
    const getKycIdentification = async (payload) => {
        const data = await GetKycIdentification(payload)
        setDatas({
            ...datas,
            kycIdentification: data?.data?.data?.data,
            bool: false
        })

    }
    useEffect(() => {
        getKycIdentification({ limit: 10, offset: 0 })
        // eslint-disable-next-line
    }, [])

    return (
        <>{datas?.bool ?
            <KycIdentificationsimmer />
            :
            <>
                {datas?.kycIdentification?.map((item, index) => {
                    return (
                        <Box>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={KycDrawerStyles?.cardStack} onClick={() => proofType(item)}>
                                <Typography sx={KycDrawerStyles?.proofTypeText}>{item?.type}</Typography>
                                {language === "ar" ?
                                    <ArrowBackIosIcon sx={KycDrawerStyles?.arrowIcon} /> :
                                    <ArrowForwardIosIcon sx={KycDrawerStyles?.arrowIcon} />
                                }
                            </Stack>
                        </Box>
                    )
                })}
            </>
        }


        </>


    );
}