import * as React from "react"
const GeneralRequestIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 108759" transform="translate(4342 10930)">
      <rect
        width={40}
        height={40}
        fill="#fff2ee"
        data-name="Rectangle 55778"
        rx={8}
        transform="translate(-4342 -10930)"
      />
      <path
        fill="#bf360c"
        d="M-4328.765-10905.882h12.941v7.059h-12.941Z"
        data-name="Path 99638"
      />
      <path
        fill="#ffa726"
        d="M-4330.529-10910.588a2.353 2.353 0 1 0 2.353 2.353 2.353 2.353 0 0 0-2.353-2.353Zm16.471 0a2.353 2.353 0 1 0 2.353 2.353 2.353 2.353 0 0 0-2.353-2.353Z"
        data-name="Path 99639"
      />
      <path
        fill="#ffb74d"
        d="M-4313.471-10912.353c0-7.471-17.647-4.883-17.647 0v5.877a8.824 8.824 0 0 0 17.647 0Z"
        data-name="Path 99640"
      />
      <path
        fill="#784719"
        d="M-4318.765-10909.412a1.176 1.176 0 1 0 1.176 1.176 1.176 1.176 0 0 0-1.176-1.176Zm-7.059 0a1.176 1.176 0 1 0 1.176 1.176 1.176 1.176 0 0 0-1.176-1.176Z"
        data-name="Path 99641"
      />
      <path
        fill="#ff5722"
        d="M-4322.294-10922.353c-5 0-12.353 3.412-12.353 19.765l5.882 3.765v-10.589l9.882-5.765 3.059 4v12.353l5.882-4.824c0-3.294-.529-17.059-9.059-17.059l-.822-1.646Z"
        data-name="Path 99642"
      />
      <path
        fill="#757575"
        d="M-4309.941-10909.412a.556.556 0 0 0-.588.588v-4.117a9.439 9.439 0 0 0-9.412-9.412h-5.294a.556.556 0 0 0-.588.588.556.556 0 0 0 .588.588h5.294a8.26 8.26 0 0 1 8.235 8.235v5.882a.588.588 0 1 0 1.176 0v1.176a4.092 4.092 0 0 1-4.118 4.118h-7.646a.588.588 0 0 0 0 1.176h7.647a5.272 5.272 0 0 0 5.294-5.294v-2.94a.556.556 0 0 0-.588-.588Z"
        data-name="Path 99643"
      />
      <g data-name="Group 108763">
        <path
          fill="#37474f"
          d="M-4309.942-10910.588h-.588a1.18 1.18 0 0 0-1.176 1.176v2.353a1.18 1.18 0 0 0 1.176 1.176h.588a1.18 1.18 0 0 0 1.176-1.176v-2.353a1.18 1.18 0 0 0-1.176-1.176Zm-12.353 8.235a1.176 1.176 0 1 0 1.176 1.176 1.176 1.176 0 0 0-1.176-1.176Z"
          data-name="Path 99644"
        />
      </g>
    </g>
  </svg>
)
export default GeneralRequestIcon
