import React from "react";
import { CreateInvestment } from "./createInvestment";
import { withTranslation } from 'react-i18next';

class CreateInvestmentParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <CreateInvestment {...this?.props}/>;
  }
}

export default withTranslation('statistic')(CreateInvestmentParent);
