import { Avatar, AvatarGroup, Box, Container, Fab, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DialogDrawer, KycCard, KycDrawer, Slider, TopNavBar } from "../../components";
import { KycInformationStyle } from './style';
import AddIcon from '@mui/icons-material/Add';
import KycBadgeIcon from "../../assets/kycBadgeIcon";
import { useLazyGetKycListingQuery } from "../../redux/services";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment-timezone";
import { KycCardsimmer } from "./component/kycListingSimmer";
import NoData from "../../components/noData"
import { useNavigate } from "react-router";
import { AppRoutes } from "../../router/routes";
import { errorRoute, stringAvatar } from "../../utils";
import { ProfilePictureEditStyle } from "../../components/singleImageUpload/style";



export const KycInformation = ({ t = () => false }) => {

    const navigate = useNavigate()
    const [drawer, setDrawer] = useState(false);
    const [GetKycListing , {error}] = useLazyGetKycListingQuery();
    const [offSet, setOffSet] = useState(0);
    const [kyc, setKyc] = useState({
        kycListing: [],
        bool: false,
        viewDrawer: {},
        close: false,
        detail: false
    })

    // AddIcon
    const createIcon = () => {
        setDrawer(!drawer);

    }
    // drawerOpen
    const viewDrawerOpen = (data) => {
        setKyc({
            ...kyc,
            viewDrawer: data,
            close: true,
        })

    }

    // imagesOpen
    const imagesOpen = (data) => {
        setKyc({
            ...kyc,
            detail: true,
            close: false

        })
    }
    // imagesClose
    const imagesClose = (data) => {
        setKyc({
            ...kyc,
            viewDrawer: data,
            detail: false

        })
    }
    // drawer Close
    const drawerClose = () => {
        setDrawer(!drawer)

    }

    // view Drawer Close
    const viewDrawerClose = () => {
        setKyc({
            ...kyc,
            close: false
        })
    }

    // listing API Call
    const getKycList = async (payload, filter) => {
        if(!filter){
            setKyc({
                ...kyc,
                bool: true
            })
        }
        
        const data = await GetKycListing(payload)
        setKyc({
            ...kyc,
            kycListing: filter ? kyc?.kycListing.concat(data?.data?.data) : data?.data?.data,
            bool: false
        })
    }

    const fetchMoreData = () => {
        getKycList({
            offset: offSet + 10,
            limit: 10,
        }, true)
        setOffSet(offSet + 10);
    }
    useEffect(() => {
        getKycList({ limit: 30, offset: 0 })
        // eslint-disable-next-line
    }, [])

    React.useEffect(()=>{
        if(error?.status === 423) return errorRoute() 
     },[error])

    return (
        <Box >
            <Container
                sx={{padding:"0px !important"}}
                maxWidth="sm"
            >
             <Box sx={{
        paddingTop: '3.5rem', // Default style
        '@media screen and (min-width: 600px)': {
          paddingTop: '4rem', // Media query style for `md` breakpoint
        },
      }}>
                   {/* topNavbar */}
                   <Box sx={KycInformationStyle?.Topnav}>
                   <Container maxWidth="sm" sx={{padding:"0px !important"}}>

                    <TopNavBar title={t("KYC Information")} position={'absolute'}
                        route={() => navigate(AppRoutes?.home)} t={t} />
                        </Container>
                </Box>

                {/* Kyc View  */}
                <Box sx={KycInformationStyle.root}>
                    <Typography sx={KycInformationStyle.title}>{t("KYC")} ({kyc?.kycListing?.length})
                    </Typography>
                    {
                        <Box >
                            {kyc?.bool ?
                                <KycCardsimmer /> :
                                <Box sx={KycInformationStyle?.infiniteScroll}>
                                    {
                                        kyc?.kycListing?.length > 0 ?
                                            <InfiniteScroll
                                                dataLength={kyc?.kycListing?.length ?? 0}
                                                next={fetchMoreData}
                                                hasMore={true}
                                                height={"calc(100vh - 160px)"}
                                            >
                                                {kyc?.kycListing?.map((item, index) => {
                                                    return (
                                                        <Box>
                                                            <KycCard
                                                                dot={true}
                                                                text={item?.name}
                                                                subText1={item?.type}
                                                                subText2={item?.number}
                                                                status={item?.is_valid === true ? "Valid" : "Expired"}
                                                                divider={true}
                                                                assets={item?.kyc_assets?.[0]?.src}
                                                                type={item?.kyc_assets?.[0]?.type}
                                                                onClick={viewDrawerOpen}
                                                                data={item}
                                                                verified={item?.verified}
                                                                hidden={kyc?.kycListing?.length === index + 1 ? false : true}
                                                            />
                                                        </Box>
                                                    )
                                                })}

                                            </InfiniteScroll>
                                            :
                                            <NoData style={{ display: "flex", alignItems: "center", height: "calc(100vh - 220px)" }} />
                                    }
                                </Box>
                            }
                        </Box>
                    }
                    {/* Add icon */}
                    <Box sx={KycInformationStyle.AddIcon}>
                        <Container maxWidth="sm" sx={KycInformationStyle.AddIconContainer}>
                            <Fab sx={KycInformationStyle?.AddIconChild} onClick={createIcon}>
                                <AddIcon />
                            </Fab>
                        </Container>
                    </Box>
                </Box>
             </Box>


                {/* Add Drawer */}
                <DialogDrawer
                    maxWidth="sm"
                    open={drawer}
                    header={t("Add ID Proof")}
                    height_style={
                        { height: "350px !important" }
                    }
                    component={
                        <>
                            <Typography sx={KycInformationStyle?.chooseProofText}>{t("Choose the ID proof type to add")}</Typography>
                            <KycDrawer />
                        </>
                    }
                    onClose={() => drawerClose()}
                />
                {/* View Drawer */}
                <DialogDrawer
                    maxWidth="sm"
                    open={kyc?.close}
                    header={t("Add ID Proof")}
                    height_style={
                        { height: "120px !important" }
                    }
                    component={
                        <>
                            {kyc?.bool ?
                                <KycCardsimmer />
                                :
                                <Box>
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack>
                                            <Typography sx={KycInformationStyle?.heading}>{kyc?.viewDrawer?.name} {kyc?.viewDrawer?.verified && <KycBadgeIcon />}</Typography>
                                            <Typography sx={KycInformationStyle?.subText} noWrap> {kyc?.viewDrawer?.number} <span style={{ margin: "0px 4px" }} > ● </span> {kyc?.viewDrawer?.type}</Typography>

                                        </Stack>
                                        {
   kyc?.viewDrawer?.kyc_assets?.[0]?.type === "pdf" ?
   <Box onClick={() => imagesOpen()}>
    <img
                    height={48}
                    width={48}
                    sx={ProfilePictureEditStyle?.image}
                    src={"/images/PDFImage.svg"}
                    alt="profile_image"
                /></Box>:

<AvatarGroup max={2} onClick={() => imagesOpen()}>

{kyc?.viewDrawer?.kyc_assets?.map((x, index) => (

    <Avatar alt="Avatar"                     {...stringAvatar(kyc?.viewDrawer?.number)}
        src={x?.src?.length > 0 ? x?.src :
            <img alt="pdfimg" src="/images/PDFImage.svg" />} />
))}
</AvatarGroup>
}
                                        

                                    </Stack>
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={KycInformationStyle?.superSubTextBox}>
                                        <Stack>
                                            <Typography sx={KycInformationStyle?.superSubText} noWrap>&#x202a;{moment(kyc?.viewDrawer?.valid_from).format(
                                                "DD-MMM-YY"
                                            )}&#x202c; - &#x202a;{moment(kyc?.viewDrawer?.valid_to).format(
                                                "DD-MMM-YY"
                                            )}&#x202c; <span style={{ margin: "0px 4px" }} > ● </span> {kyc?.viewDrawer?.country_name}</Typography>

                                        </Stack>
                                        <Typography sx={kyc?.viewDrawer?.is_valid === true ? KycInformationStyle?.valid : KycInformationStyle?.expired}>{kyc?.viewDrawer?.is_valid === true ? "Valid" : "Expired"}</Typography>

                                    </Stack>

                                </Box>
                            }
                        </>
                    }
                    // onClose={() => setLogoutDrawer(!logOutDrawer)}
                    onClose={() => viewDrawerClose()}
                />
                <DialogDrawer
                    maxWidth="sm"
                    open={kyc?.detail}
                    isnotTitle
                    padding={"16px 16px 0px"}
                    height_style={
                        // { height: "350px !important" }
                        { height: "auto !important", padding: "16px !important", minHeight: "100px" }
                    }
                    component={
                        <>
                            <Slider
                                height={"100% !important"}
                                borderRadius={"4px"}
                                imageHeigth={"300px !important"}
                                assets={kyc?.viewDrawer?.kyc_assets?.map((x) => {
                                    return {

                                        meta_data: {
                                            file_type: "image",
                                        },
                                        url: x?.url ?? x?.src,
                                    }

                                })}
                                onClose={() => imagesClose()}
                            />
                        </>
                    }

                    onClose={() => imagesClose()}
                />
            </Container>
        </Box>


    );
};
