import React from "react";
import { withTranslation } from 'react-i18next';
import { Amenities } from "./amenitiesBooking";

class AmenitiesBookingParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <Amenities {...this?.props}/>;
  }
}

export default withTranslation('amenities')(AmenitiesBookingParent);
