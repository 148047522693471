import React from "react";
import { Avatar, Box, Button, Container, Divider, Stack, Typography } from "@mui/material";
import { landlordApprovalstyle } from "./style";
import { DialogDrawer, FooterComponent, FormGenerator, TopNavBar } from "../../components";
import Build from "../../assets/build";
import { LandlordApprovalRequest } from "../../components/landlordApproval";
import ContractValue from "../../assets/contractValue";
import ContractDetails from "../../assets/contractDetails";
import AgreementDetails from "../../assets/agreementDetails";
import SignaturePad from 'react-signature-canvas';

export const LandlordApproval = ({
    t = () => false
}) => {
    const [declineDrawer, setDeclineDrawer] = React.useState(false)
    const [acceptDrawer, setAcceptDrawer] = React.useState(false)
    
    // DECLINE DRAWER
    const outlinedonClick = () => {
        setDeclineDrawer(true)
    }

    const handleDeclineDrawerClose = () => {
        setDeclineDrawer(false)
    }

    // ACCEPT DRAWER
    const containedonClick = () => {
        setAcceptDrawer(true)
    }

    const handleAcceptDrawerClose = () => {
        setAcceptDrawer(false)
    }
    return (
        <>
        <Container
            maxWidth="sm"
            sx={landlordApprovalstyle.container}>
            {/* topNavbar */}
            <Box sx={{ position: "fixed", top: "0px", left: 0, right: 0, zIndex: 999 }}>
                <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
                    <TopNavBar isDirect title={"Landlord Approval"} isBack t={t} />
                </Container>
            </Box>
            {/* BODY */}
            <Box sx={landlordApprovalstyle.root}>
                <LandlordApprovalRequest
                    icon={<Build />}
                    unitName={"Lumpnin"}
                    address={"Chennai, Tamilnadu"} />
                <Box sx={landlordApprovalstyle.detailsBox}>
                    <Typography sx={landlordApprovalstyle.detialsBoxHeader}>{t("CONTRACT DETAILS")}</Typography>
                    <Box sx={landlordApprovalstyle.contractValueBox}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <ContractValue />
                                <Typography sx={landlordApprovalstyle.contractValueText}>{t("Contract Value")}</Typography>
                            </Stack>
                            <Typography sx={landlordApprovalstyle.contractValue}>$1,100 / Month</Typography>
                        </Stack>
                    </Box>
                    {/* CONTRACT DETAILS */}
                    <Stack mt={"12px"} direction={"row"} alignItems={"center"}>
                        <ContractDetails />
                        <Stack marginInlineStart={1} direction={"column"} spacing={"6px"}>
                            <Typography sx={landlordApprovalstyle.detailsText}>CNT-123456</Typography>
                            <Stack alignItems={"center"} direction={"row"} divider={<Divider sx={landlordApprovalstyle.divider} />}>
                                <Typography sx={landlordApprovalstyle.detailsSubtext}>ACC-12412412</Typography>
                                <Typography sx={landlordApprovalstyle.detailsSubtext}>22 Jan 22 - 22 Jan 23</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Divider sx={landlordApprovalstyle.lineDivider} />
                    {/* AGREEMENT DETAILS */}
                    <Typography sx={landlordApprovalstyle.detialsBoxHeader}>{t("AGREEMENT DETAILS")}</Typography>
                    <Stack mt={"12px"} direction={"row"} alignItems={"center"}>
                        <AgreementDetails />
                        <Stack marginInlineStart={1} direction={"column"} spacing={"6px"}>
                            <Typography sx={landlordApprovalstyle.detailsText}>CNT-123456</Typography>
                            <Stack alignItems={"center"} direction={"row"} divider={<Divider sx={landlordApprovalstyle.divider} />}>
                                <Typography sx={landlordApprovalstyle.detailsSubtext}>Rent</Typography>
                                <Typography sx={landlordApprovalstyle.detailsSubtext}>22 Jan 22 - 22 Jan 23</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Divider sx={landlordApprovalstyle.lineDivider} />
                    {/* TENANT DETAILS */}
                    <Typography sx={landlordApprovalstyle.detialsBoxHeader}>{t("TENANT DETAILS")}</Typography>
                    <Stack mt={"12px"} direction={"row"} alignItems={"center"}>
                        <Avatar sx={landlordApprovalstyle.avatar}>H</Avatar>
                        <Stack marginInlineStart={1} direction={"column"} spacing={"6px"}>
                            <Typography sx={landlordApprovalstyle.detailsText}>Sheriff</Typography>
                            <Stack alignItems={"center"} direction={"row"} divider={<Divider sx={landlordApprovalstyle.divider} />}>
                                <Typography sx={landlordApprovalstyle.detailsSubtext}>sheriff@gmail.com</Typography>
                                <Typography sx={landlordApprovalstyle.detailsSubtext}>+91 9998883322</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
            <Box sx={{ position: "fixed", bottom: "0px", left: 0, right: 0, zIndex: 999 }}>
                <Container maxWidth="sm" sx={{ padding: "0px !important" }}>
                    <FooterComponent
                        outlinedText={t("Decline")}
                        outlinedonClick={outlinedonClick}
                        containedText={t("Approve")}
                        containedonClick={containedonClick}
                    />
                </Container>
            </Box>
        </Container>
            <DialogDrawer
                maxWidth="sm"
                open={declineDrawer}
                onClick={handleDeclineDrawerClose}
                header={t("Decline Remarks")}
                height_style={
                    { height: "auto !important" }
                }
                component={
                    <>
                    <Typography sx={landlordApprovalstyle.drawerText}>{t("Remarks")}</Typography>
                    <FormGenerator
                    components={[
                        {
                            isActive: true,
                            component: "text",
                            // label: t("Problem Description"),
                            // value: data?.problem_Description,
                            isRequired: false,
                            placeholder: t("Remarks"),
                            // onChange: (e) => updateState("problem_Description", e?.target?.value),
                            // error: data?.error?.problem_Description,
                            multiline: true,
                            size: {
                                xs: 12,
                            },
                        },
                    ]} />
                    </>
                }
                footer={
                    <>
                    <FooterComponent
                        single={true}
                        containedText={t("Submit")}
                    />
                    </>
                }
                onClose={() => handleDeclineDrawerClose()}
            />
            <DialogDrawer
                maxWidth="sm"
                open={acceptDrawer}
                onClick={handleAcceptDrawerClose}
                header={t("Approval Signature")}
                height_style={
                    { height: "auto !important" }
                }
                component={
                    <>
                    <Typography sx={landlordApprovalstyle.drawerText}>{t("Signature")}</Typography>
                        <Box sx={landlordApprovalstyle.signatureBox} position="relative">
                            <SignaturePad
                                canvasProps={{ height: "330px",borderRadius: "8px", border: "1px solid #E4E8EE", width: "380%" }}
                                // ref={sigCanvas}
                                backgroundColor="white"
                                penColor="black"

                            />
                            <center>
                                <Box display="flex" justifyContent="center">
                                    <Button 
                                    // onClick={handleClick} 
                                    sx={landlordApprovalstyle.clearBtn}>{t("clear")}</Button>
                                </Box>
                            </center>
                        </Box>
                    </>
                }
                footer={
                    <>
                    <FooterComponent
                        single={true}
                        containedText={t("Submit")}
                    />
                    </>
                }
                onClose={() => handleAcceptDrawerClose()}
            />
        </>

    )
}