import { Avatar, Box, Button, Typography } from "@mui/material"
import { generateImageUrl, img_size } from "../../utils";
import { ProfilePictureCard } from "./style";

export const ProfilePicture = ({
  onClick = () => false,
  t = () => false,
  profilePicture = {}
}) => {

  return (
    <Box sx={ProfilePictureCard?.root}>
      <Avatar
        variant="circular"
        sx={ProfilePictureCard?.avatarOuter}
      >
        <Avatar
          variant="circular"
          sx={ProfilePictureCard?.avatarInner}

        >
          <img
            src={generateImageUrl(profilePicture, img_size.small_square)}
            alt="profile_image"
            style={ProfilePictureCard?.image}
          />
        </Avatar>
      </Avatar>
      <Box sx={ProfilePictureCard?.broot}>
        <Button
          variant="contained"
          sx={ProfilePictureCard?.editBtn}
          onClick={onClick && onClick ? onClick : false}
        >
          <Typography
            sx={ProfilePictureCard?.editBtnText}
          >
            <div>
              {t("edit")}
            </div>
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}