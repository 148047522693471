import React, { useState } from "react";
import { Box, IconButton, InputAdornment, Stack, Typography, Grid, Container } from "@mui/material";
import { DialogDrawer, LoderSimmer, Slider, TextBox, TopNavBar, NoData, UseDebounce } from "../../components";
import { FolderTab, FileComponent, FolderDrawer } from "../filemanager/components";
import { Gridview, SerachIMG, SortView, ListView } from "../../assets";
import { SemiBold, errorRoute } from "../../utils";
import moment from "moment-timezone";
import { saveAs } from 'file-saver';
import { useLazyGetViewDocumentsQuery } from "../../redux/services";
import { useLocation, useNavigate } from "react-router";
import { AppRoutes } from "../../router/routes";

export const DocsStyle = {
    root: {
        margin: "16px",
    },
    boxShadow: {
        boxShadow: "none !important"
    },
    girdTwo: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    },
    girdOne: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        "& p": {
            fontFamily: SemiBold,
            color: "text.Teritary"
        }
    },
    folderDiv: {
        margin: "16px",
        alignItems: "center",
    },
    gridItems: {
        margin: "10px",
    },
    gridView: {
        overflow: "auto",
        marginTop: "7px",
        height: '100%',
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    listContainer: {
        marginTop: "16px",
        overflow: "auto",
        height: "100%",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    child: {
        backgroundColor: "primary.contrastText",
        padding: " 0 16px",
    },
    container: {
        backgroundColor: "background.Teritary",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        position: "relative",
        height: "100vh"
    },
    Topnav: {
        position: 'fixed',
        top: 0,
        zIndex: 999,
        left:0,
        right:0
    },
    parentBox: {
        position: "fixed",
        right: "0",
        left: "0",
        top: "0",
        bottom: "0"
    },
}

export const FilemanagerDocs = ({ t = () => false }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const debounce = UseDebounce()
    const [fileManagerViewAPI , {error}] = useLazyGetViewDocumentsQuery()

    const [state, setState] = useState({
        offset: 0,
        search: "",
        moreIcon: false,
        data: [],
        drawer: false,
        listType: 2,
        details: {},
        file: {
            url: ""
        },
        pdfViewType: false,
        viewFile: false,
        bool: true,
        order_by: true
    })

    const documentDataRes = async (data, orderBy) => {
        const response = await fileManagerViewAPI(data)
        let dataResponse = response?.data?.data?.data
        setState(
            {
                ...state,
                search: data?.search,
                data: dataResponse,
                bool: !state?.bool,
                order_by: orderBy ? !state?.order_by : state?.order_by
            }
        )
    }
    React.useEffect(() => {
        documentDataRes({
            "directory_id": location?.state,
            "offset": state?.offset,
            "is_private": [false],
            "search": state?.search ?? "",
        })
        // eslint-disable-next-line
    }, [location?.state, state?.offset])

    const updateState = (key, value) => {
        if (key === "search") {
            setState({ ...state, [key]: value });
            debounce(
                async () => {
                    await documentDataRes({
                        "directory_id": location?.state,
                        "offset": state?.offset,
        
                        "is_private": [false],
                        "search": value ?? "",
                    })
                },
                800
            );
        } else {
            setState({ ...state, [key]: value });
        }

    };

    const viewDetails = (data) => {
        setState({
            ...state,
            moreIcon: true,
            drawer: true,
            details: data
        })
    }
    const drawerClose = () => {
        setState({
            ...state, drawer: !state?.drawer
        });
    }

    const viewPdf = (data) => {
        setState({
            ...state,
            drawer: true,
            pdfViewType: true,
            viewFile: true,
            details: {
                url: data?.url
            }
        })
    }
    const download = (data) => {
        var ext = data?.url.substring(data?.url?.lastIndexOf(".") + 1);
        saveAs(data?.url, `${data?.name}.${ext}`);
    }

    const orderBy = async () => {
        await documentDataRes({
            "directory_id": location?.state,
            "offset": state?.offset,
            order_by: state?.order_by ? "desc" : "asc",
        }, true)
    }

    const handleRoute = (data) => {
        navigate(AppRoutes?.fileManagerDocs, { state: data?.id })
    }

    React.useEffect(()=>{
        if(error?.status === 423) return errorRoute() 
     },[error])
     
    return (
        <Box sx={DocsStyle?.parentBox}>
            <Container maxWidth="sm" sx={DocsStyle?.container}>
            <Box sx={{
        paddingTop: '7.4rem', // Default style
        '@media screen and (min-width: 600px)': {
          paddingTop: '7.7rem', // Media query style for `md` breakpoint
        },
      }}>

<Box sx={DocsStyle?.Topnav}>
                    <Container maxWidth="sm" sx={{padding:"0px !important"}}>
                    <TopNavBar title={t("View Documents")} isDirect
                        position="absolute" isBack t={t} />
                         <Box sx={DocsStyle?.root}>
                    <Grid item xs={12}>
                        <TextBox
                            sx={DocsStyle?.boxShadow}
                            placeholder={t("Search")}
                            value={state?.search ?? ""}
                            onChange={(e) => updateState("search", e.target.value)}
                            startAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                        <SerachIMG />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                </Box>
                    </Container>
                </Box>
               
                <Box sx={DocsStyle?.folderDiv}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6} sx={DocsStyle?.girdOne}>
                                <Typography>{t("Files & Folders")}</Typography>
                            </Grid>
                            <Grid item xs={6} sx={DocsStyle?.girdTwo}>
                                <Stack spacing={2} direction="row">
                                    {[<SortView color={state?.order_by ? "#F15A29" : "#98A0AC"}
                                        onClick={() => orderBy()} />,
                                    <ListView color={state?.listType === 1 ? "#F15A29" : "#98A0AC"}
                                        onClick={() => updateState("listType", 1, state?.order_by)} />,
                                    <Gridview color={state?.listType === 2 ? "#F15A29" : "#98A0AC"}
                                        onClick={() => updateState("listType", 2, state?.order_by)} />]?.map((x) => {
                                            return (
                                                <Box onClick={x?.onClick}>{x}</Box>
                                            );
                                        })}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={DocsStyle?.gridView}>
                        {state?.listType === 0 && <Grid container>

                            {state?.data?.length > 0 ?
                                <>
                                    {
                                        state?.data?.map(val => {
                                            return <Grid item xs={6} sm={4} md={3} >
                                                <Box sx={DocsStyle?.gridItems}>
                                                    {
                                                        val?.is_dir ? <FolderTab
                                                            t={t}
                                                            state={val}
                                                            GridView
                                                            handleOpen={viewDetails}
                                                            handleRoute={handleRoute}
                                                        /> :
                                                            <FileComponent
                                                                t={t}
                                                                data={{
                                                                    fileNmae: val?.data?.data?.name,
                                                                    createdAt: moment(val?.created_at).tz(moment.tz.guess()).format("DD MMM YY"),
                                                                    data: val
                                                                }}
                                                                viewDetails={viewDetails}
                                                                type={state?.listType}
                                                                viewPdf={viewPdf}
                                                                download={download} />
                                                    }
                                                </Box>
                                            </Grid>
                                        })
                                    }
                                </>
                                :
                                <NoData style={{
                                    display: "flex", alignItems: "center",
                                    height: "calc(100vh - 220px)"
                                }} />
                            }
                        </Grid>}
                        {
                            state?.listType === 1 && <Box sx={DocsStyle?.listContainer}>
                                <Box sx={DocsStyle?.child}>
                                    {state?.data?.length > 0 ?
                                        <>
                                            {
                                                state?.data?.map((val, index) => {
                                                    return state?.bool ? <LoderSimmer card count={1} size={120}
                                                        animation={"wave"} />
                                                        : (
                                                            val?.is_dir ? <FolderTab
                                                                t={t}
                                                                state={val}
                                                                GridView={false}
                                                                handleOpen={viewDetails}
                                                                handleRoute={handleRoute}
                                                                isDivider={state?.data?.length !== index + 1 ? true : false}
                                                            /> :
                                                                <FileComponent
                                                                    t={t}
                                                                    data={{
                                                                        fileNmae: val?.data?.data?.name,
                                                                        createdAt:
                                                                            moment(val?.created_at).tz(moment.tz.guess()).format("DD MMM YY"),
                                                                        data: val
                                                                    }}
                                                                    viewDetails={viewDetails}
                                                                    viewPdf={viewPdf}
                                                                    download={download}
                                                                    type={state?.listType} />
                                                        )
                                                })
                                            }
                                        </> :
                                        <NoData style={{
                                            display: "flex", alignItems: "center",
                                            height: "calc(100vh - 220px)"
                                        }} />
                                    }
                                </Box>

                            </Box>
                        }
                        {state?.listType === 2 && <Box sx={DocsStyle?.gridView}>
                            <Grid container >
                                {state?.data?.length > 0 ?
                                    state?.data?.map(val => {
                                        return <Grid item xs={6} sm={4} md={4} >
                                            <Box sx={DocsStyle?.gridItems}>
                                                {
                                                    val?.is_dir ? <FolderTab
                                                        t={t}
                                                        state={val}
                                                        GridView
                                                        handleOpen={viewDetails}
                                                        handleRoute={handleRoute}
                                                    /> :
                                                        <FileComponent
                                                            t={t}
                                                            data={{
                                                                fileNmae: val?.data?.data?.name,
                                                                createdAt: moment(val?.created_at).tz(moment.tz.guess()).format("DD MMM YY"),
                                                                data: val
                                                            }}
                                                            viewDetails={viewDetails}
                                                            type={state?.listType}
                                                            viewPdf={viewPdf}
                                                            download={download} />
                                                }
                                            </Box>
                                        </Grid>
                                    })
                                    :
                                    <NoData style={{
                                        display: "flex", alignItems: "center",
                                        height: "calc(100vh - 220px)"
                                    }} />
                                }
                            </Grid>
                        </Box>}




                    </Box>
                </Box>
      </Box>
                <DialogDrawer
                    maxWidth="sm"
                    open={state?.drawer}
                    padding={"0"}
                    height_style={
                        { height: "auto !important", padding: "16px !important", minHeight: "100px" }
                    }
                    isnotTitle
                    component={state?.pdfViewType ?
                        <Slider
                            assets={[state?.details]}
                            height={"100% !important"}
                            objectFit={"cover"}
                            borderRadius={"4px"}
                            imageHeigth={"300px !important"}
                        /> : < FolderDrawer pdfViewType={state?.pdfViewType} data={state?.details} t={t} />}

                    onClose={() => drawerClose()}
                />

            </Container >
        </Box>

    )
}