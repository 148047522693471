import { Box, Stack, Typography } from "@mui/material"
import { UtilityIcon } from "../../assets"
import { UtilityCardstyle } from "./style"

export const UtilityCard = ({
    title = "",
    subTitle = "",
    status = "",
    hidden = true,
    router = () => false,
    is_card_show = true
}) => {
    return (
        <>
            <Box sx={UtilityCardstyle?.root} onClick={router && router}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"} spacing={2}>
                        <UtilityIcon />
                        <Stack>
                            <Typography sx={UtilityCardstyle?.title}>{title}</Typography>
                            <Typography sx={UtilityCardstyle?.subTitle}>{subTitle}</Typography>
                        </Stack>
                    </Stack>
                    {
                        is_card_show && <Typography sx={status === "OVER DUE" ? UtilityCardstyle?.overDueStatus : UtilityCardstyle?.status}>{status}</Typography>

                    }

                </Stack>


            </Box>

            {/* border bottom */}
            {hidden && <Box sx={UtilityCardstyle?.divider} />}

        </>


    )
}