import { Avatar, Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { generateImageUrl, img_size } from '../../utils';
import { AmmenityBookingCardStyles } from "./style"

export const AmmenityBookingCard = ({
    title = "",
    subTitle = "",
    value = "",
    image=""
}) => {
    return (
        <Box sx={AmmenityBookingCardStyles?.root}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <Avatar sx={AmmenityBookingCardStyles?.avatar} src={generateImageUrl(image, img_size.large_rectangle)} alt='No Image' />
                    <Stack>
                        <Typography sx={AmmenityBookingCardStyles?.title}>{title}</Typography>
                        <Typography sx={AmmenityBookingCardStyles?.subTitle}>{subTitle}</Typography>
                    </Stack>
                </Stack>
                <Stack sx={AmmenityBookingCardStyles?.valueText}>
                    {value}
                </Stack>
            </Stack>
        </Box>

    );
}