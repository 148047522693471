import { Bold, ExtraBold, Regular, SemiBold } from "../../utils";
export const InvoiceCard_Style = {
  sx: {
    border: "1px solid",
    borderColor: "error.contrastText",
    borderRadius: "12px",
    height: "auto",
    boxShadow: "5px 5px #fff3f3",
  },
  header: {
    height: "50px",
    backgroundColor: "background.Teritary1",
    borderRadius: "12px 12px 0px 0px",
    opacity: 1,
    display: "flex",
    alignItems: "center",
    padding: "16px",
  },
  body: {
    height: "auto",
    backgroundColor: "error.contrastText",
    borderRadius: "0px 0px 12px 12px",
    opacity: 1,
    padding: "16px",
  },
  title: {
    height: "19px",
    textAlign: "left",
    color: "border.primary",
    opacity: 1,
    fontSize: "0.875rem",
    fontFamily: Bold,
  },
  btn: {
    borderRadius: "30px",
    padding: "5px",
    marginRight: '0px',
    "& .MuiButton-endIcon": {
      marginLeft: 0,
    },
    "& p": {
      fontSize: "0.7em",
      textTransform: "capitalize",
      fontFamily: Bold,
    }
  },
  amt: {
    height: "27px",
    textAlign: "left",
    color: "text.primary",
    opacity: 1,
    fontSize: "0.78125",
    fontFamily: ExtraBold,
  },
  noteBg: {
    width: "fit-content",
    height: "auto !important",
    backgroundColor: "backgroundcard.dark",
    borderRadius: "9px",
    opacity: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "8px",
    padding: "2px"
  },
  overdue: {
    color: "primary.main",
    fontFamily: Regular,
    fontSize: "0.75rem",
    display: "flex",
    justifyContent: "center",
    padding: "0px 6px"
  },
  quickpay: {
    textAlign: "-webkit-match-parent",
    letterSpacing: "0px",
    color: "text.secondary",
    fontFamily: Bold,
    textTransform: "uppercase",
    fontSize: "0.75rem",
    marginTop: "20px",
  },
  outstandText: {
    fontSize: "0.75rem",
    marginTop: "4px",
    fontFamily: SemiBold,
    color: "text.primary"
  },
  actionBox: {
    display: "flex",
    alignItems: "center",
    overflowY: "none",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
};
