import React from "react";

export const TickIcon = (props) => {
    const width = props.width ? props.width : "18";
    const height = props.height ? props.height : "18";
    const color = props.color ? props.color : "#F15A29";
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 18 18"
        >
            <path id="icons8-ok_5_" data-name="icons8-ok (5)" d="M13,4a9,9,0,1,0,9,9A9.01,9.01,0,0,0,13,4Zm0,1.35A7.65,7.65,0,1,1,5.35,13,7.64,7.64,0,0,1,13,5.35Zm3.362,4.944a.675.675,0,0,0-.464.2l-4.023,4.023L10.1,12.748a.675.675,0,1,0-.954.954l2.25,2.25a.675.675,0,0,0,.954,0l4.5-4.5a.675.675,0,0,0-.49-1.158Z" transform="translate(-4 -4)"
                fill={color}
            />
        </svg>
    );
};
