import * as React from "react";

export const RightArrow = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={14} {...props}>
    <g data-name="Group 108918">
      <g data-name="Group 108800" transform="translate(20283 7457)">
        <rect
          data-name="Rectangle 55791"
          width={18}
          height={14}
          rx={7}
          transform="translate(-20283 -7457)"
          fill="#f15a29"
        />
        <path
          d="M-20278.998-7450a.593.593 0 0 0 .579.606h7.5l-1.82 2.007a.627.627 0 0 0 .021.857.56.56 0 0 0 .818-.022l2.742-3.031a.628.628 0 0 0 0-.835l-2.748-3.03a.56.56 0 0 0-.818-.022.628.628 0 0 0-.021.857l1.82 2.007h-7.5a.593.593 0 0 0-.573.606Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);


