import React from "react";
import { withTranslation } from "react-i18next";
import { Contracts } from "./contracts";

class ContractsParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return <Contracts {...this?.props} />;
    }
}

export default withTranslation('contract')(ContractsParent);