import * as React from "react"

const ChatIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 108907">
      <path data-name="Rectangle 55935" fill="none" d="M0 0h40v40H0z" />
      <path
        data-name="Path 99698"
        d="M30.316 6H13.368a3.685 3.685 0 0 0-3.684 3.684v19.895l5.132-5.158h15.5A3.685 3.685 0 0 0 34 20.737V9.684A3.685 3.685 0 0 0 30.316 6Z"
        fill="#7f48ce"
      />
      <path
        data-name="Path 99699"
        d="M26.632 10.421H9.684A3.685 3.685 0 0 0 6 14.105V34l5.132-5.158h15.5a3.685 3.685 0 0 0 3.684-3.684V14.105a3.685 3.685 0 0 0-3.684-3.684Z"
        fill="#be94f9"
      />
    </g>
  </svg>
)

export default ChatIcon
