import { Bold, Regular } from "../../utils";

export const AmmenityBookingCardStyles = {
    root:{
        backgroundColor:"primary.contrastText",
        boxShadow:"none",
        padding:"14px 16px",
        borderRadius:"12px",
        marginBottom:"8px"
    },
    headText:{
        color:"text.main",
        fontSize: "1rem",
        fontFamily:Bold
    },
    avatar:{
        borderRadius:"4px",
        width:"48px",
        height:"48px",
    },
    title:{
        color:"text.main",
        fontSize: "0.875rem",
        fontFamily:Bold,
        marginBottom:"6px"

    },
    subTitle:{
        color:"text.Teritary",
        fontSize: "0.75rem",
        fontFamily:Regular,
    },
    valueText:{
        color:"text.main",
        fontSize: "0.875rem",
        fontFamily:Bold,
        
    }

    

}