import { Typography } from "@mui/material"
import { useStyles } from "./style";

export const Label = (props) => {
    const classes = useStyles(props);
    return (
        <Typography className={props?.labelStyle ? null : classes.label} sx={props?.labelStyle} noWrap>
            {props?.label}
            {props?.isRequired ?
                <Typography variant="caption" className={classes.required}>*</Typography> :
                <Typography variant="caption" className={classes.required}></Typography>}
        </Typography>
    )
}