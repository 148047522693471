import React from 'react'
import { Box, Grid, Button, Typography } from "@mui/material";
import { ToggleStyle } from './style';
import { Label } from '../label'


export const ToggleButton = ({
    options = [],
    spacing = 0,
    divider = {
        xs: 2.5,
        sm: 2.5,
        md: 2.5,
        lg: 2.5,
    },
    onChange = () => false,
    value = "",
    padding = "15px 12px",
    borderRadius = null,
    border = null,
    backgroundColor = null,
    label = null,
    isrequired = false,
    backgroundColorunparent = null,
    fontSize = "14px",
    is_disable=false,
}) => {
    const { xs, sm, md, lg } = divider
    return (
        <div>
            {label && <Label label={label} isRequired={isrequired} />}
            <Box sx={ToggleStyle({ backgroundColorunparent })?.parent}>
                <Grid container sx={ToggleStyle?.grid}>
                    {
                        options?.map((val, index) => {
                            return (
                                <Grid item xs={xs} sm={sm} md={md} lg={lg} key={index}
                                    sx={{ paddingTop: '0px !important' }}>
                                    <Button onClick={() => is_disable ? false : onChange(val?.value)} variant={"contained"}
                                        sx={value === val?.value ? ToggleStyle({ padding, borderRadius, border, backgroundColor, fontSize })?.selected : ToggleStyle({ padding, borderRadius, border, backgroundColor, fontSize })?.unselected} fullWidth>
                                        <Typography noWrap>{val?.label}</Typography>
                                    </Button>
                                </Grid>
                            )
                        })
                    }

                </Grid>
            </Box>
        </div>
    )
}
