import {Bold, Regular, SemiBold} from "../../utils"

export const SummaryCard_style ={
    sx:{
        display:"flex",
        padding:"15px 16px",
        backgroundColor:"primary.contrastText",
        borderBottom: "1px solid #E4E8EE",
        cursor: "pointer",
        justifyContent:"space-between"
        
    },
    percentage:{
        color:"primary.contrastText",
        fontFamily:Regular,
        padding:"9px",
        fontSize: "0.75rem"
    },
    titlecode:{
        fontFamily:SemiBold,
        fontSize: "0.875rem",
        color: '#4E5A6B',
        marginInlineStart:'11px',
    },
    price:{
        fontFamily:Bold,
        fontSize: "0.875rem",
        color: '#091B29',
        textAlign:'right'
    },
    tittlesection:{
        display:'flex',
    }

}