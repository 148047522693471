import {  SemiBold } from "../../utils";

export const TownCards_Style = {
  sx: {
    width: "100%",
    height: "100%",
    backgroundColor:"white",
    boxShadow: "0px 3px 30px #4354721F",
    borderRadius: "12px",
    opacity: 1,
    padding: "12px",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
    minHeight:"78px",
    position:"relative"
  },
  title: {
    textAlign: "left",
    color: "border.primary",
    fontSize: "0.75rem",
    fontFamily: SemiBold,
   
  },
  img: {
      position:"absolute",
      bottom:'3px',
      left:"10px"
  },
  homeimg: {
    marginTop: "10px",
  },
};
