import * as React from "react"

export const TransparentChat = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
    <path
      d="M6 .002a6.007 6.007 0 0 0-6 6 5.945 5.945 0 0 0 .679 2.72l-.65 2.329a.766.766 0 0 0 .925.925l2.33-.65A5.943 5.943 0 0 0 6 12.002a6 6 0 1 0 0-12Zm0 .9a5.1 5.1 0 1 1-2.477 9.557.45.45 0 0 0-.34-.04l-2.216.618.619-2.215a.45.45 0 0 0-.04-.34A5.1 5.1 0 0 1 6 .902Zm-2.55 3.6a.45.45 0 1 0 0 .9h5.1a.45.45 0 1 0 0-.9Zm0 2.1a.45.45 0 1 0 0 .9h3.9a.45.45 0 1 0 0-.9Z"
      fill="#98a0ac"
    />
  </svg>
)

