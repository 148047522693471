import * as React from "react"
const SerialNumber = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 116869" transform="translate(-49 -896)">
      <rect
        width={24}
        height={24}
        fill="#eff5e6"
        data-name="Rectangle 58383"
        rx={4}
        transform="translate(49 896)"
      />
      <g fill="#81b138" data-name="icons8-qr-code (4)">
        <path
          d="M69.053 906.579a.947.947 0 0 0 .947-.947v-5.685a.947.947 0 0 0-.947-.947h-5.685a.947.947 0 0 0-.947.947v1.421H61v1.895h1.421v2.368a.947.947 0 0 0 .947.947h2.842v1.895h1.895v-1.894Zm-4.737-1.895v-3.789h3.789v3.789Z"
          data-name="Path 101483"
        />
        <path
          d="M66.211 913.211h1.895v1.895h-1.895z"
          data-name="Rectangle 58375"
        />
        <path
          d="M68.105 911.316H70v1.895h-1.895z"
          data-name="Rectangle 58376"
        />
        <path
          d="M69.052 917a.947.947 0 0 0 .947-.947v-.948h-1.894V917Z"
          data-name="Path 101484"
        />
        <path
          d="M64.316 911.316h1.895v1.895h-1.895z"
          data-name="Rectangle 58377"
        />
        <path
          d="M64.316 915.105h1.895V917h-1.895z"
          data-name="Rectangle 58378"
        />
        <path
          d="M54.842 912.263h1.895v1.895h-1.895z"
          data-name="Rectangle 58379"
        />
        <path
          d="M54.842 901.842h1.895v1.895h-1.895z"
          data-name="Rectangle 58380"
        />
        <path
          d="M65.263 901.842h1.895v1.895h-1.895z"
          data-name="Rectangle 58381"
        />
        <path
          d="M68.105 908.474H70v1.895h-1.895z"
          data-name="Rectangle 58382"
        />
        <path
          d="M62.421 908.474v-1.895h-2.842v1.895h-1.895v.947h-1.895v-.947h-1.894v.947h-.948a.947.947 0 0 0-.947.947v5.684a.947.947 0 0 0 .947.947h5.684a.947.947 0 0 0 .947-.947v-1.894h1.895v.947h1.895v-4.737h2.842v-1.894h-3.789Zm-4.737 6.632h-3.789v-3.79h3.789Zm3.789-2.842h-1.894v-1.896h1.895Z"
          data-name="Path 101485"
        />
        <path
          d="M57.684 906.579h.947a.947.947 0 0 0 .947-.947v-.474H61v-1.895h-1.421v-3.316a.947.947 0 0 0-.947-.947h-5.685a.947.947 0 0 0-.947.947v5.684a.947.947 0 0 0 .947.947h2.842v1.895h1.895Zm-3.789-1.895v-3.789h3.789v3.789Z"
          data-name="Path 101486"
        />
      </g>
    </g>
  </svg>
)
export default SerialNumber
