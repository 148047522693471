import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material"
import { landlordApprovalStyle } from "./style"

export const LandlordApprovalRequest = ({
    icon = "",
    unitName = "",
    address = "",
    onClick = () => false,
    button = false,
    approved = "",
    declined = "",
    t = () => false
}) => {
    return (
        <Box sx={landlordApprovalStyle.landlordBox}>
            <Stack justifyContent={"space-between"} direction={"row"}>
                <Avatar sx={landlordApprovalStyle.image} variant="rounded" src={icon} />
                <Typography sx={landlordApprovalStyle.waiting}>Waiting For Approval</Typography>
                {approved && <Typography sx={landlordApprovalStyle.approved}>Approved</Typography>}
                {declined && <Typography sx={landlordApprovalStyle.declined}>Declined</Typography>}
            </Stack>
            <Typography sx={landlordApprovalStyle.approval}>{t("Landlord Approval")}</Typography>
            <Typography sx={landlordApprovalStyle.unitName}>{unitName}</Typography>
            <Typography sx={landlordApprovalStyle.address}>{address}</Typography>
            {approved && <Typography sx={landlordApprovalStyle.approvalDate}>Landlord Approved on 20 Jan 22</Typography>}
            {declined && 
            <>
            <Typography sx={landlordApprovalStyle.declinedDate}>Landlord Declined on 20 Jan 22</Typography>
            <Box sx={landlordApprovalStyle.remarksBox}>
                <Typography sx={landlordApprovalStyle.remarksText}>Remarks</Typography>
                <Typography sx={landlordApprovalStyle.remarksSubtext}>I Dont Like Tenant</Typography>
            </Box>
            </>
            }
            {button &&
                <Grid container>
                    <Grid item xs={12}>
                        <Button onClick={() => onClick()} sx={landlordApprovalStyle.button}>{t("Update")}</Button>
                    </Grid>
                </Grid>
            }
        </Box>
    )
}