import { Typography, Box } from "@mui/material"
import { ChartBox, SummaryCard } from "../../components"
import { Piechart } from "../../components/piechart";
import { currencyFormatted, currentMonth, currentYear, getTotal, getTotalByunit, getYear, month } from "../../utils"
import { Revenur_style } from "./style"
import React, { useEffect, useState } from 'react'
import { useLazyGetRevenueQuery } from "../../redux/services";

const initial = {
  yearSelect: {
    value: currentYear,
    label: currentYear
  },
  monthSelect: {
    value: currentMonth,
    label: currentMonth
  },
  categorySelect: {
    value: null,
    label: "All"
  }
}
export const Revenue = ({
  t = () => false
}) => {
  const [GetRevenue] = useLazyGetRevenueQuery();

  const [toggle, setToggle] = useState("1")
  const [select, setSelect] = useState({ ...initial, bool: true })
  const [graph , setGraph] = useState({
    bool:true,
    data:[]
  })

  
  const toggleChange = async(e) => {
    setToggle(e)
    if (e === "2") {
       getGraph({
        month: month.indexOf(select?.monthSelect?.value) + 1,
        year: select?.yearSelect?.value,

     
      })
    }
    else {
      getGraph({
        year: select?.yearSelect?.value,
      })
    }
  }
  const updateState = (k, v) => {
    setSelect({ ...select, [k]: v });
  };


  const getGraph=async(payload)=>{
    setGraph({
      data:[],
      bool:true
    })
     const data = await GetRevenue({
      year:payload?.year,
      month:payload?.month
     })
     const result = getTotalByunit(data?.data?.data , true)

     setGraph({
      data: result?.map((x) => {
        return {
          name: payload?.contract_no,
          value: x?.amount ?? 0,
          symbol:x?.symbol,
          percentage:`${x?.percentage}%`,
          ...x
        }
      }),
      bool: false
     })
  }
  const handleChangePeriod = async (m, y) => {
    setSelect({
      ...select,
      yearSelect: y,
      monthSelect: m
    })
    await getGraph({
      month: toggle === "1" ? undefined : month.indexOf(m?.value) + 1,
      year: y.value,
    })
  
  }


  useEffect(()=>{
    getGraph({
      year: select?.yearSelect?.value,
    })
    // eslint-disable-next-line
  },[])
  return (
    <Box sx={Revenur_style?.overflow}>
      <Box>


        <ChartBox
          onChange={toggleChange}
          title={t("Revenue")}
          title_style={{
            marginBottom:"0px !important"
          }}
          handleChangePeriod={handleChangePeriod}
          symbol={graph?.data?.find((x)=>x?.symbol)?.symbol}
          options={[
            { value: "1", label: t("Yearly") },
            { value: "2", label: t("Monthly") },
          ]}
          chart_parent={{
            padding:"18px !important"
          }}
          
          year={getYear()?.map((x) => {
            return {
              value: x,
              label: x
            }
          }) ?? []}
          month={month?.map((x) => {
            return {
              value: x,
              label: x
            }
          }) ?? []}
          value={toggle}
          monthValue={select?.monthSelect?.value}
          yearValue={select?.yearSelect?.value}
          values={{
            month:select?.monthSelect,
            year:select?.yearSelect
          }}
          toggles={toggle === "2" ? true : false}
          isFull
          yearSelect={(e) => {
            updateState("yearSelect", e)
            getGraph({
              year: e?.value,
           
            })
          }}
          loader={graph?.bool}
          hidecomponent={
            <Piechart data={graph?.data}
              width={200}
              isTotal={true}
              totalText={t("Total")}
              total={`${graph?.data?.find((x)=>x?.symbol)?.symbol} ${currencyFormatted(getTotal(graph?.data , 'amount'))}`}
              />
          }
        />
      </Box>
      {/* summary */}
      {
        graph?.data?.length > 0 && 
        <Box>
        <Typography sx={Revenur_style?.summaryText}>{t("Summary")}</Typography>
        <Box sx={Revenur_style?.summarybg}>
        {graph?.data?.map((x, index) => {
            return (
              <SummaryCard key={index}
                bgcolor={x?.fill}
                percentage={`${Number(x?.percentage).toFixed(2)} %`}
                titlecode={x?.unit_no}
                price={`${graph?.data?.find((x)=>x?.symbol)?.symbol} ${currencyFormatted(x?.amount)}`}
              />
            )
          })}

        </Box>
      </Box>
      }
     

    </Box>
  )
}