import React from "react"
import { Box, Container, IconButton, InputAdornment, Grid } from "@mui/material"
import { ParkingSlot_style } from "./style"
import { TopNavBar, TextBox, ParkingCard, DialogDrawer, ParkingDetails, NoData, UseDebounce, LoderSimmer } from "../../components"
import { SerachIMG } from "../../assets";
import { useLazyGetParkingListingQuery, useLazyGetOwnerListingQuery } from "../../redux/services";
import { useNavigate , useLocation } from "react-router";
import { errorRoute } from "../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../useDimension";
export const ParkingSlot = ({ t = () => false }) => {
    const navigate = useNavigate()
    let location = useLocation();
    const debounce = UseDebounce()
    const [GetParkingListing , {error}] = useLazyGetParkingListingQuery();
    const [GetOwnerListing , {error:isError}] = useLazyGetOwnerListingQuery()

    const [state, setState] = React.useState({
        search: "",
        drawer: false,
        data: [],
        viewData: {},
        offset: 0,
        boolean: true,
        is_details: false
    })
    const [offSet, setOffSet] = React.useState(0);

    const GetOwnerListingAPI = async (data) => {
        const res = await GetOwnerListing({...data , agreement_id:location?.state , parking_unit:true})
        setState({
            ...state,
            search: data?.search,
            data: res?.data?.data,
            boolean: false,
        })
    }

    const GetParkingListingAPI = async (data) => {
        const response = await GetParkingListing(data)
        if(response?.data?.data?.[0]){
            setState({
                ...state,
                viewData: response?.data?.data?.[0],
                drawer: !state?.drawer,
                is_details: true
            })
        }
        else{
            setState({
                ...state,
                viewData: "",
                drawer: false,
                is_details: false
            })
        }
      
    }

    const handleDrawerOpen = (x) => {
        if (x?.unit_id) {
            GetParkingListingAPI({
                "id": x?.unit_id,
                "type": 8
            })
        } else {
            setState({
                ...state,
                drawer: false,
                viewData: ""
            })
        }

    };

    const updateState = (key, value) => {
        setState({
            ...state, [key]: value
        })
        debounce(
            async () => {
                await GetOwnerListingAPI({
                    offset: state?.offset,
                    limit: 10,
                    search: value ?? "",

                })
            },
            800
        );
    }

    const handleDrawerClose = () => {
        setState({
            ...state,
            drawer: false,
        })
    }

    React.useEffect(() => {
        GetOwnerListingAPI({
            offset: state?.offset,
            limit: 10,
            search: state?.search ?? "",
        })
        // eslint-disable-next-line
    }, [])

    React.useEffect(()=>{
        if(error?.status === 423|| isError?.status === 423) return errorRoute() 
     },[error , isError])

    const handleBack = () => {
        navigate(-1)
    }

    const fetchMoreData = () => {
        GetOwnerListingAPI({
            offset: state?.offset,
            limit: 10,
            search: state?.search ?? "",
        })
        setOffSet(offSet + 10);
      };
      const size = useWindowDimensions();

    return (
        <Container maxWidth={'sm'} sx={{padding:"0px !important"}}>
             <Box sx={{
        paddingTop: '3.4rem', // Default style
        '@media screen and (min-width: 600px)': {
          paddingTop: '3.8rem', // Media query style for `md` breakpoint
        },
      }}>
            {/* top navbar */}
            <Box sx={ParkingSlot_style?.Topnav}>
                <Container sx={{padding:"0px !important"}}>
                <TopNavBar title={t("Parking")}
                    isDirect isBack={false} route={() => handleBack()} t={t}
                />
                </Container>
            </Box>
            <Box sx={ParkingSlot_style?.sx}>
                <Box sx={ParkingSlot_style?.searchSection}>
                    <Grid container spacing={1} alignItems="start">
                        <Grid item xs={12}>
                            <TextBox
                                paddingRight
                                onChange={(e) => updateState("search", e.target.value)}
                                value={state?.search ?? ""}
                                placeholder={t("Search Parking")}
                                startAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                        >
                                            <SerachIMG />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
    
          
                {state?.boolean ?
          <Box>
                             <LoderSimmer card count={10} size={100} variant={"rectangular"} animation={"wave"} />
          </Box>
          :
          state?.data?.length > 0 ?
              <InfiniteScroll
                dataLength={state?.data?.length ?? 0}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - 120}

              >
                {state?.data?.map((x, index) => {
                  return (
                    <Box key={index}>
                      <ParkingCard
                                                handleDrawerOpen={() => handleDrawerOpen(x)}
                                                title={x?.unit_name}
                                                unit_no={x?.unit_no}
                                                apartment={x?.property_name}
                                                doorno={x?.parking_no ?? ""}
                                                image={x?.logo ? x?.logo : "/images/parkSLotAvatar.svg"}
                                                imagecomponent={{ background: x?.image }}
                                                t={t}
                                            />
                    </Box>
                  );

                })
                }
              </InfiniteScroll>
              : <NoData
                style={{ display: "flex", alignItems: "center", height: "calc(100vh - 220px)" }} />
            
        }
            </Box>
            </Box>
            <DialogDrawer
                maxWidth="sm"
                open={state?.drawer}
                onClose={handleDrawerClose}
                header={t("Parking")}
                height_style={
                    { height: "497px !important" }
                }
                component={
                    state?.is_details &&
                    <ParkingDetails
                        t={t}
                        data={state?.viewData}
                    />
                }
            />
        </Container>

    )
}
