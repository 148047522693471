import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    open: false,
    message: ""
}

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        openAlert(state, { payload }) {
            state.open = true;
            state.message = payload.message;
        },
        closeAlert(state, { payload }) {
            state.open = false;
            state.message = ""
        }
    },
})

export const { openAlert, closeAlert } = alertSlice.actions;
export default alertSlice.reducer;