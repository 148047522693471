
import { SemiBold , Bold} from "../../utils";

export const PieStyle ={
  middileCount:{
    width: '36px',
    height: '16px',
    font: 'normal normal bold 12px/16px Nunito Sans',
    letterSpacing: '0px',
    color: 'text.primary',
},
tooltip: {
    backgroundColor: 'background?.secondary',
    border: `1px solid`,
    borderColor:"border.secondary",
    display: "flex",
    alignItems: "center",
    padding: "4px 7px",
    zIndex: 999,
    borderRadius: "4px",
    position: "relative",
  },
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-20px",
    "& svg": {
      width: "100%",
      minWidth:  "165px !important",
      height:  "165px !important",
      position: "relative",

      cursor: "pointer",
      "& .recharts-wrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  main:{
    margin:"0 auto"
  },
  center: {
    textAlign: "center",
    position: "absolute",
    right: "0px",
    // zIndex: 1,
    width:"100%",
    padding: "12px",
    left:"0px",
    top:"39%"
  },
  title: {
    fontSize: "0.75rem",
    color: 'text?.secondary',
    fontFamily: SemiBold,
  },
  count: {
    fontSize: "1rem",
    color: 'text?.main',
    fontFamily: Bold,
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginRight: "8px",
  },
  name: {
    color: "backgroundcard.primary",
    fontSize: "0.75rem",
    fontFamily: SemiBold,
  },
  value: {  
    color: "text.Teritary",
    fontSize: "1rem",
    fontFamily: SemiBold,
    marginRight: "8px",
  },
  chartsm: {
    width: '100%',

    // height:{
    //   sm:300,
    //   // md:500
    // },
    position: "relative"
  }
 }