import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const assetsSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        setAssets(state, { payload }) { state = payload; }
    },
})

export const { setAssets } = assetsSlice.actions;
export default assetsSlice.reducer;