import axios from "axios";
import { LocalStorageKeys } from "./constants";

export const getCurrentBuildNumber = async () => {
     let oldVersion = localStorage.getItem(LocalStorageKeys?.version)
    let version = "";
    let endpoint = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/meta.json`;
    if (oldVersion) {
        return oldVersion;
    } else {
    await axios.get(endpoint)
    .then((_) => {
        version = _.data.version;
        localStorage.setItem(LocalStorageKeys?.version, version);
    }).catch(err => {
        console.log('err:', err);
    });

    return version;
    }
}
