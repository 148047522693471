import * as React from "react"

const SignOut = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.242}
    height={16.2}
    {...props}
  >
    <path
      d="M8.1.1a8 8 0 1 0 6.27 12.96.6.6 0 0 0-.941-.745 6.8 6.8 0 1 1 0-8.43.6.6 0 0 0 .941-.745A7.986 7.986 0 0 0 8.1.1Zm4.994 4.994a.6.6 0 0 0-.418 1.03L14.051 7.5 5.9 7.492a.6.6 0 1 0 0 1.2l8.153.007-1.377 1.377a.6.6 0 1 0 .848.848l2.4-2.4a.6.6 0 0 0 0-.848l-2.4-2.4a.6.6 0 0 0-.43-.182Z"
      fill="#f15a29"
      stroke="#f15a29"
      strokeLinecap="round"
      strokeWidth={0.2}
    />
  </svg>
)

export default SignOut
