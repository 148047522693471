import React from "react";
import { withTranslation } from 'react-i18next';
import { AnnouncementView } from "./announcementView";

class AnnouncementViewParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <AnnouncementView {...this?.props}/>;
  }
}

export default withTranslation('announcement')(AnnouncementViewParent);
