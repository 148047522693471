import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import ThumbsDown from "../../assets/thumbsDown";
import ThumbsUpIcon from "../../assets/thumbsUp";
import { AccordianCard, TopNavBar } from "../../components";
import { GetSupportDetailedStyle } from './style'




export const GetSupportDetailed = ({
    t = () => false
}) => {


    return (
        <Container
            sx={GetSupportDetailedStyle.container}
            maxWidth="sm"
        >
            {/* topNavbar */}
            <TopNavBar title={t("Get Support")} isDirect isBack t={t}/>

            {/* GetSupportDetailed */}
            <Box sx={GetSupportDetailedStyle.root}>

                <Box >
                    {/* AccordianCard */}
                    <AccordianCard
                        detailed={true}
                        title={t("contentTitle")}
                        bottomText={t("contentDescription")}
                        details={t("Price Book is collection of following pricing information used in Property Management Solution. Pricing Component - An important element in defining the pricing table. There are 5 different type of components, namely Primary, Secondary, One Time, Refundables and Item Based. Long Term Pricing Table - maintains Price configuration based on a Montly Rate. During the quote process, the system auto-calculates prices based on the Leasing Period in Quarterly, Monthly or Yearly. Short Term Rental Pricing Table - maintains Price configuration based on a daily Rates. During the quote process, the system auto-calculates prices based on short term rental period usually in days or weeks. Promotional or Override Pricing Table RERA Pricing Table Price Appraisals Delivery Milestones Milestone Templates Vacant Period Viewer")}
                    />
                </Box>
                <Box>
                    {/* Thumbs up and Down Text */}
                    <Typography sx={GetSupportDetailedStyle?.likeText}>
                        {t("Did this answer your question?")}
                    </Typography>
                    {/* Thumbs up and Down Icons */}
                    <Box sx={GetSupportDetailedStyle?.likeIconsBox}>
                        <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                            <Stack sx={GetSupportDetailedStyle?.likeIcon}>
                                <ThumbsUpIcon />
                            </Stack>
                            <Stack sx={GetSupportDetailedStyle?.likeIcon} >
                                <ThumbsDown />
                            </Stack>

                        </Stack>
                    </Box>
                </Box>



            </Box>


        </Container>

    );
};
