import { Bold ,Regular } from "../../utils";

export const TopDrawerTab_style ={
    sx:{
        backgroundColor:'primary.contrastText',
        borderRadius:'4px',
        opacity:1,
        width:'100%',
        height:'36px',
        boxShadow: '0px 3px 30px #5C86CB2E',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:'0px 15px'
    },
    drawerSx:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:'7px',
        marginBottom:'12px'
    },
    text:{
        fontFamily:Bold,
        fontSize: "0.75rem",
    },
        title:{
        fontFamily:Bold,
        fontSize: "1rem",
    },
    viewmore:{
        fontFamily:Regular,
        fontSize: "0.75rem",
        color:'primary.main'
    },
}