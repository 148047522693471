import { Bold } from "../../utils";
export const ServiceCard_Style = {
  rootSx: {
    width: "100%",
    height: "100%",
    backgroundColor:
      "secondary.contrastText",
    boxShadow: "0px 3px 30px #4354721F",
    borderRadius: "12px",
    opacity: 1,
    padding: "16px",
    position: "relative"
  },
  dot: {
    padding: "2px",
    backgroundColor: "primary.main",
    borderRadius: "50%",
    marginRight: "4px",
    marginLeft: "4px",
  },
  purpose: {
    textAlign: "left",
    color: "primary.main",
    fontSize: "0.75rem",
    fontFamily: Bold,
  },
  bottomtab: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    textAlign: "-webkit-match-parent",
    fontSize: "1rem",
    color: "text.main",
    marginTop: "8px",
    fontFamily: Bold,
    whiteSpace: "normal"
  },
  imgSection: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: '8px',
    left: "10px",
    width: "100%",
    padding: '0px 13px 0px 4px'
  },
  img: {
    marginTop: "30px",
    marginRight: "16px"
  },
};
