import * as React from "react"

export const Report = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <path
      d="M15.374 2.8h-1.6a2.02 2.02 0 0 0-2-1.8h-3.15a2.02 2.02 0 0 0-2 1.8h-1.6a2.027 2.027 0 0 0-2.025 2.025v12.15A2.027 2.027 0 0 0 5.024 19h10.35a2.027 2.027 0 0 0 2.025-2.025V4.825A2.027 2.027 0 0 0 15.374 2.8Zm-8.55 12.15a.675.675 0 1 1 .675-.675.675.675 0 0 1-.675.675Zm0-2.7a.675.675 0 1 1 .675-.675.675.675 0 0 1-.675.675Zm0-2.7a.675.675 0 1 1 .675-.675.675.675 0 0 1-.675.675Zm1.125-6.525a.676.676 0 0 1 .675-.675h3.15a.675.675 0 0 1 0 1.35h-3.15a.676.676 0 0 1-.675-.675Zm5.625 11.925h-4.05a.675.675 0 1 1 0-1.35h4.05a.675.675 0 0 1 0 1.35Zm0-2.7h-4.05a.675.675 0 1 1 0-1.35h4.05a.675.675 0 0 1 0 1.35Zm0-2.7h-4.05a.675.675 0 1 1 0-1.35h4.05a.675.675 0 0 1 0 1.35Z"
      fill="#F15A29"
      data-name="Group 106593"
    />
  </svg>
)
