import { Bold, Regular } from "../../utils";

export const contractHistoryStyle = {
    container: {
        backgroundColor: "background.Teritary",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        padding: "53px 0px 0px",
    },
    contractBox: {
        backgroundColor: "white",
        padding: "16px",
        borderRadius: "12px",
        margin: "16px"
    },
    contractText: {
        color: "text.main",
        fontSize: "0.875rem",
        fontFamily: Bold
    },
    contractSubtext: {
        color: "text.Teritary",
        fontFamily: Regular,
        fontSize: "0.75rem"
    },
    waiting: {
        padding: "4px 8px",
        backgroundColor: "#F1F7FF",
        color: "#78B1FE",
        fontSize: "0.625rem",
        fontFamily: Bold,
        height: "22px",
        borderRadius: "11px"
    },
    approved: {
        padding: "4px 8px",
        backgroundColor: "#EEF9EE",
        color: "#5AC782",
        fontSize: "0.625rem",
        fontFamily: Bold,
        height: "22px",
        borderRadius: "11px"
    },
    declined: {
        padding: "4px 8px",
        backgroundColor: "#FFECEC",
        color: "#FF4B4B",
        fontSize: "0.625rem",
        fontFamily: Bold,
        height: "22px",
        borderRadius: "11px"
    }
}