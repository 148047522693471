
import * as React from "react"

export const UtilityIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 52963"
          transform="translate(82.808 183.808)"
          fill="#fff"
          stroke="#707070"
          d="M0 0h22v22H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 96476" transform="translate(-1977.811 16211.189)">
      <rect
        data-name="Rectangle 52965"
        width={48}
        height={48}
        rx={4}
        transform="translate(1977.811 -16211.189)"
        fill="#f0f4ff"
      />
      <g
        data-name="Group 6430"
        transform="translate(1908.002 -16381.998)"
        clipPath="url(#a)"
      >
        <g data-name="Group 96479">
          <path
            data-name="Union 11"
            d="M92.242 204.429v-1.649a1.707 1.707 0 0 1-.786-.641h-.588a.458.458 0 0 1 0-.916h.188a1.82 1.82 0 0 1-1.788-1.436l-1.517-6.812h-.064a.915.915 0 0 1-.87-.627l-.506-1.517a.46.46 0 0 1 .435-.605h1.373c3.668-.459 4.81-3.207 4.81-4.352a.687.687 0 0 1 1.374 0c0 1.145 1.145 3.893 4.813 4.352h1.371a.46.46 0 0 1 .434.605l-.506 1.518a.917.917 0 0 1-.872.626h-.066l-1.509 6.812a1.823 1.823 0 0 1-1.788 1.436h.185a.458.458 0 1 1 0 .916h-.585a1.7 1.7 0 0 1-.789.642v1.647Zm1.832-4.122h2.106a.915.915 0 0 0 .9-.718l1.47-6.614h-4.476Zm-3.914-.72a.915.915 0 0 0 .9.718h2.1v-7.33h-4.473Z"
            fill="#5078e1"
          />
        </g>
      </g>
    </g>
  </svg>
)


