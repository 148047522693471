import { useTheme } from "@mui/material";
import { Regular, SemiBold } from "../../utils";

export const Property_Style = () => {
  const theme = useTheme();
  return {
    searchSection: {
      "& .MuiInputBase-root": {
        padding: 0,
        "& fieldset": {
          height: "35px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderColor: "",
        },
        "&.Mui-focused fieldset": {
          borderColor: "red",
        },
      },
      "& .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input": {
        paddingLeft: "18px",
        fontFamily: Regular,
        fontSize: "0.875rem",
      },
      "& .MuiOutlinedInput-root": {
        height: "30px",
      },
      "& .makeStyles-textbox-20 .MuiOutlinedInput-root": {
        backgroundColor: theme?.palette?.primary?.contrastText,
      },
      "& .makeStyles-textbox-20 .MuiOutlinedInput-input": {
        height: "12px",
        backgroundColor: theme?.palette?.primary?.contrastText,
      },
      "& .makeStyles-textbox-36 .MuiOutlinedInput-input": {
        height: "12px",
      }
    },
    propertyCardBox: {
      marginTop: "12px !important"
    },
    infiniteScrollBox: {
      overflow: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    noRecords: {
      color: theme?.palette?.primary?.Tertiary,
      fontFamily: Regular,
      fontSize: "0.75rem",
      display: "flex",
      justifyContent: "center",
      marginTop: "20px"
    },
    filterIcon: {
      cursor: "pointer",
      padding:"7px",
      display:"flex",
      justifyContent:"center",
      height:"38px",
      borderRadius:"4px",
      backgroundColor:"#fff"

    },
    textbox: {
      fontFamily: SemiBold,
      backgroundColor: theme?.palette?.error?.contrastText,
      borderRadius: theme?.palette?.borderRadius ?? 4,
      border: "none",
      [`& fieldset`]: {
        borderRadius: theme?.palette?.borderRadius ?? 4,
        color: theme?.palette?.text?.secondary,
        height: (props) => (props.multiline ? "unset" : 50),
        border: `1.5px solid ${theme?.palette?.border?.main}`,
        "&:hover": {
          border: `1px solid ${theme?.palette?.border?.main}`,
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: "13.5px 14px !important",
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: theme?.palette?.error?.contrastText,
        fontFamily: SemiBold,
        '&:hover fieldset': {
          borderColor: theme?.palette?.primary?.main,
        },
  
      },
      "& .css-gf036l-MuiInputBase-root-MuiInput-root:before": {
        borderBottom: "0px !important"
      },
      "& .css-odxm5i-MuiInputBase-root-MuiInput-root:before": {
        borderBottom: "0px !important"
  
      }
    },
  }
}
