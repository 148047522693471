import React from "react";
import { Inspection } from "./inspection";
import { withTranslation } from 'react-i18next';

class InspectionParent extends React.Component {
    constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <Inspection  {...this?.props}/>;
  }
}
export default withTranslation('inspection') (InspectionParent);
