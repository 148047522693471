import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";
import jwt_decode from "jwt-decode";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            let client = jwt_decode(token)?.selected_client
            let slug = client?.tenants?.slug

            headers.set("Authorization", "Bearer " + token);
            headers.set("x-build-code", config?.xbuildCode);
            headers.set("Slug", slug);
            headers.set("'Access-Control-Allow-Origin'", "*");

        }
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const contractAPI = createApi({
    reducerPath: "contactAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["contract"],
    endpoints: (builder) => ({


        // Get contract Listing API
        getContractListing: builder.query(
            {
                query: (payload) => {
                    return {
                        url: `owner/get_contract`,
                        method: "POST",
                        body: payload
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {
                        return { data: response.data, totalCount: response.totalRecords };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),

        // Get contract accounts API
        getOwnerAccounts: builder.query(
            {
                query: () => {
                    return {
                        url: `owner/get_owner_access_accounts`,
                        method: "POST"
                    }
                },

                transformResponse: (response, meta, arg) => {
                    if (response.type === "success") {

                        return { data: response?.data };
                    }
                    return { data: [], totalCount: 0 };
                },
            }),
        // Get contract details API
        getContractDetails: builder.query({
            query: (payload) => {
                return {
                    url: `owner/get_contract_details`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                if (response.type === "success") {
                    return { data: response.data, totalCount: response.totalRecords };
                }
                return { data: [], totalCount: 0 };
            },
        }),

        // get_contract_units API
        getContractUnits: builder.query({
            query: (payload) => {
                return {
                    url: `owner/get_contract_units`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                if (response.type === "success") {
                    return { data: response.data, totalCount: response.totalRecords };
                }
                return { data: [], totalCount: 0 };
            },
        }),
        // get_contract_units API
        getContractInvoices: builder.query({
            query: (payload) => {
                return {
                    url: `owner/get_owner_contract_invoice`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                if (response.type === "success") {
                    return { data: response.data, totalCount: response.totalRecords };
                }
                return { data: [], totalCount: 0 };
            },
        }),
    }),
});

// Export hooks for usage in functional components
export const {
    useLazyGetContractListingQuery,
    useLazyGetOwnerAccountsQuery,
    useLazyGetContractDetailsQuery,
    useLazyGetContractUnitsQuery,
    useLazyGetContractInvoicesQuery

} = contractAPI;
