import { Bold, Regular } from "../../utils";

export const AddOnsUnitCardStyles = {
    root: {
        backgroundColor: "primary.contrastText",
        boxShadow: "#4250682E 0px 3px 30px",
        padding: "12px",
        borderRadius: "4px",
        marginBottom: "8px"
    },
    ChangeUnitroot: {
        backgroundColor: "primary.contrastText",
        padding: "14px 16px",
        borderRadius: "8px",
        border: "1px solid",
        borderColor: "border.secondary"

    },
    ChangeUnitrootSelected: {
        backgroundColor: "#FFF4EB",
        padding: "14px 16px",
        borderRadius: "8px",
        border: "1px solid #F15A29",

    },
    headText: {
        color: "text.main",
        fontSize: "1rem",
        fontFamily: Bold
    },
    avatar: {
        borderRadius: "4px",
        width: "48px",
        height: "48px",
    },
    title: {
        color: "text.main",
        fontSize: "0.875rem",
        fontFamily: Bold,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        '@media screen and (min-width: 320px)': {
            width: "150px"
        },
        '@media screen and (min-width: 375px)': {
            width: "200px"
        },
        '@media screen and (min-width: 425px)': {
            width: "230px"
        },
    },

    subTitle: {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: Regular,
        marginTop: "6px"
    },
    valueText: {
        color: "primary.main",
        fontSize: "0.75rem",
        fontFamily: Bold,

    },
    tagTitle: {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: Bold,
        backgroundColor: "border.secondary",
        padding: "3px",
        borderRadius: "4px"
    }



}