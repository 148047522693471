import { Box } from "@mui/material"
import { LoderSimmer } from "../../../components"
export const PropetySimmmer_style = {
    favicon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    align: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    bg: {
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius:'12px'
    }
}
export const ProprtySimmmer = () => {
    const count = 3
    return (
        <>
            {Array.apply(null, Array(count)).map((value, index) => (

                <Box sx={{ marginBottom: '12px' }}>

                    <Box sx={PropetySimmmer_style?.bg}>
                        <LoderSimmer card count={1} width={'auto'} size={162} variant={"rounded"} animation={"wave"} />
                        <Box sx={PropetySimmmer_style?.align}>
                            <LoderSimmer card count={1} width={200} size={10} variant={"rectangular"} animation={"wave"} />
                        </Box>
                        <Box sx={PropetySimmmer_style?.align}>
                            <LoderSimmer card count={1} width={260} size={10} variant={"rectangular"} animation={"wave"} />
                        </Box>
                    </Box>

                </Box>
            ))}
        </>
    )
}