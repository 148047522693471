import { Investment_Activity_Style } from "./style";
import { Stack, Box, Avatar, Typography, Grid } from "@mui/material";
import { InflationUp, InflationDown, } from "../../assets";
import { LocalStorageKeys } from "../../utils";
export const InvestmentActivityCard = ({
  image = "",
  price = "",
  isBadge = false,
  activity = "",
  business = "",
  date,
  currValue = "",
  rightSec = false,
  InflationcolorUp = "#00B542",
  InflationcolorDown = "#F60000",
  t = () => false,
  route,
  handleDrawerOpen,
  hidden = false,
  gainLoss = ""
}) => {
  const currency = localStorage.getItem(LocalStorageKeys?.currency) ? JSON.parse(localStorage.getItem(LocalStorageKeys?.currency))?.symbol : ''
  return (
    <Box
      sx={{ ...Investment_Activity_Style?.contractSepBox, borderBottom: !hidden ? '1px solid #fff' : "1px solid #5c86cb2e" }}>
      <Box sx={Investment_Activity_Style?.parentStack} onClick={route}>
        <Grid container>
          <Grid item xs={1}>
            <Box display={'flex'} alignItems={'center'}>
              <Avatar variant="rounded" sx={Investment_Activity_Style?.imgDiv}>
                {image}
              </Avatar>
            </Box></Grid>
          <Grid item xs={7} >
            <Box sx={{ marginInlineStart: '29px' }}>
              <Stack direction="row">
                <span>
                  <Typography sx={Investment_Activity_Style?.price} noWrap>{currency} {price}</Typography>
                </span>
                {
                  isBadge && <span>
                    <Typography sx={Investment_Activity_Style?.activity} noWrap>
                      {activity}
                    </Typography>
                  </span>
                }
              </Stack>
              <Box
                sx={Investment_Activity_Style?.marginTop}
                spacing={1}>
                <Typography sx={Investment_Activity_Style?.work} noWrap>{business}</Typography>
                <Box sx={Investment_Activity_Style?.dotTwo}></Box>
                <Typography sx={Investment_Activity_Style?.work} noWrap>{date}</Typography>
              </Box>
            </Box></Grid>
          <Grid item xs={4}>
            {
              rightSec && <Box sx={Investment_Activity_Style?.rightSection}>
                <Box justifyContent={'end'}>
                  <Typography sx={{ color: (gainLoss.charAt(0) === '-' ) ? InflationcolorDown : InflationcolorUp }}>{gainLoss}
                  </Typography>
                  { (gainLoss.charAt(0) === '-' ) ? <InflationDown /> : <InflationUp />}

                </Box>
                <Typography sx={Investment_Activity_Style?.activityCard} noWrap>{t("Cur. Value")} {currency}<span style={Investment_Activity_Style.curval}> {currValue}</span> </Typography>
              </Box>
            }
          </Grid>
        </Grid>

      </Box>
    </Box>

  );
};