import { Grid,Box } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { generateImageUrl, img_size } from "../../utils";
import {SliderWrapper} from './style'

export const CarouselSliders = ({
  data = [],
  autoScroll = false,
  onClick =()=> false,
}) => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: autoScroll,
    speed: 1000,
    cssEase: "linear",
    arrows: false,
    appendDots: dots => <ul>{dots}</ul>,
    customPaging: i => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    )

  };


  return (
    <Box sx={{ cursor: "pointer" }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} alignItems={"center"}>
          <SliderWrapper>
            <Slider {...settings}>
              {data?.map((val, index) => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={onClick ? onClick : false}
                    key={index}
                    className="testimoni--wrapper"
                  >
                    <a href={val?.action_url} target="_blank" rel="noreferrer">
                      <img
                        src={generateImageUrl(val?.url, img_size.small_square)}
                        alt="img"
                        style={{
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: "12px",
                          height:"100%",

                        }}
                      />
                    </a>
                  </div>
                );
              })}
            </Slider>
          </SliderWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};
