import React from "react";
import { SurveyForm } from "./surveyForm";
import { withTranslation } from "react-i18next";

class SurveyFormParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return <SurveyForm {...this?.props} />;
    }
}
export default withTranslation('survey')(SurveyFormParent);