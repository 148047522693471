import { LoderSimmer } from "../../../components"
import { Box } from "@mui/material"
export const CaseSimmer_style = {
    favicon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    align: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    alignTwo: {
        display: 'flex',
        alignItems: 'center'
    },
    bg: {
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '12px',
        marginBottom: '12px'
    }
}

export const CaseManagementSimmer = () => {
    const count = 7
    return (
        <Box>
            {Array.apply(null, Array(count)).map((value, index) => (
                <Box sx={CaseSimmer_style?.bg}>
                    <Box sx={CaseSimmer_style?.align}>
                        <Box ><LoderSimmer card count={1} width={40} size={18} variant={"rounded"} animation={"wave"} /></Box>
                        <Box><LoderSimmer card count={1} width={40} size={18} variant={"rounded"} animation={"wave"} /></Box>
                    </Box>
                    <Box  >
                        <Box><LoderSimmer card count={1} width={80} size={20} variant={"rectangular"} animation={"wave"} /></Box>
                    </Box>
                    <Box>
                        <Box><LoderSimmer card count={1} width={150} size={12} variant={"rectangular"} animation={"wave"} /></Box>
                    </Box>
                </Box>
            ))}

        </Box>
    )
}