import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const KycSlice = createSlice({
    name: 'kyc',
    initialState,
    reducers: {
        setKyc(state, { payload }) { state = payload; }
    },
})

export const { setKyc } = KycSlice.actions;
export default KycSlice.reducer;