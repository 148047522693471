import * as React from "react"
const ProductImage = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 116861">
      <g fill="#f5f7fa" stroke="#e4e8ee" data-name="Rectangle 58372">
        <rect width={40} height={40} stroke="none" rx={6} />
        <rect width={39} height={39} x={0.5} y={0.5} fill="none" rx={5.5} />
      </g>
      <g data-name="Group 116859">
        <g data-name="Group 98409">
          <path
            fill="#ced3dd"
            d="m10.313 7.095-2.335-.594-.559-2.335h-.056l-.528 2.335-2.335.594v.071l2.335.475.523 2.335h.066l.553-2.335 2.335-.47ZM35.5 33.607l-1.944-.495-.467-1.946h-.047l-.44 1.946-1.946.495v.059l1.946.4.436 1.946h.055l.463-1.946 1.946-.391Z"
            data-name="icons8-plus-math (1)"
          />
          <path
            fill="#717b89"
            d="M14.974 27.182h-1.439a.242.242 0 0 1-.233-.309l.833-2.906a.243.243 0 0 1 .235-.176h1.434a.242.242 0 0 1 .233.309l-.83 2.906a.243.243 0 0 1-.233.176Z"
            data-name="Path 93484"
          />
          <path
            fill="#717b89"
            d="M25.027 27.182h1.434a.242.242 0 0 0 .233-.309l-.83-2.906a.243.243 0 0 0-.233-.176h-1.439a.242.242 0 0 0-.233.309l.833 2.906a.243.243 0 0 0 .235.176Z"
            data-name="Path 93485"
          />
          <path
            fill="#959ca6"
            d="M27.266 13.619a1.453 1.453 0 0 0-1.453-1.453h-4.36A1.453 1.453 0 0 0 20 13.619V20.4h7.266Z"
            data-name="Path 93486"
          />
          <path
            fill="#959ca6"
            d="M20.001 13.619a1.453 1.453 0 0 0-1.453-1.453h-4.36a1.453 1.453 0 0 0-1.453 1.453V20.4h7.266Z"
            data-name="Path 93487"
          />
          <path
            fill="#465160"
            d="M27.266 21.853H12.735v-1.937a.969.969 0 0 1 .969-.969h12.593a.969.969 0 0 1 .969.969Z"
            data-name="Path 93488"
          />
          <path
            fill="#b8bdc4"
            d="M12.735 24.76h-.484a1.938 1.938 0 0 1-1.938-1.938v-6.3a.485.485 0 0 1 .484-.481h1.454a.485.485 0 0 1 .484.484Z"
            data-name="Path 93489"
          />
          <path
            d="M25.864 23.967a.243.243 0 0 0-.233-.176h-1.439a.242.242 0 0 0-.233.309l.327 1.145h1.938Z"
            data-name="Path 93490"
            opacity={0.05}
          />
          <path
            d="M25.864 23.967a.243.243 0 0 0-.233-.176h-1.439a.242.242 0 0 0-.233.309l.258.9h1.938Z"
            data-name="Path 93491"
            opacity={0.07}
          />
          <path
            d="M14.148 23.967a.242.242 0 0 1 .233-.176h1.434a.242.242 0 0 1 .233.309l-.328 1.144h-1.937Z"
            data-name="Path 93492"
            opacity={0.05}
          />
          <path
            d="M14.146 23.967a.242.242 0 0 1 .234-.176h1.434a.242.242 0 0 1 .233.309l-.258.9h-1.938Z"
            data-name="Path 93493"
            opacity={0.07}
          />
          <path
            fill="#b8bdc4"
            d="M29.204 16.041H27.75a.485.485 0 0 0-.484.484v5.328H10.313v.969a1.938 1.938 0 0 0 1.938 1.937h15.5a1.938 1.938 0 0 0 1.938-1.937v-6.3a.485.485 0 0 0-.485-.481Z"
            data-name="Path 93494"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default ProductImage
